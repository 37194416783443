import moment from "moment";
import {useContext, useEffect} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useTimer} from "react-timer-hook";
import {useGetUserType} from "../../../hooks/useGetUserType";
import BalanceService from "../../../services/balance";
import {getAllTokenBalance} from "../../../store/actions/balance";
import {getTradeStatusByIDAction} from "../../../store/actions/getTradeStatus";
import {CreateTradeContext} from "../../Home/CreateTrade";
import {GET_USER_TRADES_SUCCESS, PENDING_AND_DISPUTED_TRADE} from "../../../store/types";
import UserTradeService from "../../../services/getusertrades";

const CreateTradeTop = () => {
  const {expire, setExpire} = useContext(CreateTradeContext);
  const dispatch = useDispatch();
  const {id} = useParams();
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const {response: cancelResponse} = useSelector((state) => state.cancelTradeReducer);
  const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);

  let dtime = moment(response?.closingTime);

  const {seconds, minutes, pause} = useTimer({
    expiryTimestamp: dtime,
    autoStart: true,
    onExpire: () => {
      pause();
      setExpire(true);
      // if (statusResponse?.status === "pending") {
      //   dispatch(cancelTradeByIDAction({trade_id: id}));
      // }
    },
  });

  useEffect(() => {
    if (cancelResponse?.message === "success") {
      dispatch(getTradeStatusByIDAction({id}));

      setExpire(true);

      UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
        dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
      });

      BalanceService.localBalance()
        .then((data) => {
          dispatch(getAllTokenBalance(data));
        })
        .catch(() => {
          // toast("Something went wrong");
        });
    }

    if (statusResponse?.status === "cancelled") {
      setExpire(true);

      UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
        dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
      });

      BalanceService.localBalance()
        .then((data) => {
          dispatch(getAllTokenBalance(data));
        })
        .catch(() => {
          // toast("Something went wrong");
        });
    }

    if (statusResponse?.status === "pending") {
      setExpire(false);

      UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
        dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
      });
    }

    if (statusResponse?.status?.toLowerCase() === "paid") {
      UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
        dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
      });
    }

    if (statusResponse?.status === "completed") {
      setExpire(true);

      UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
        dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
      });

      BalanceService.localBalance()
        .then((data) => {
          dispatch(getAllTokenBalance(data));
        })
        .catch(() => {
          // toast("Something went wrong");
        });
    }

    // return () => {
    //   clearInterval(interId);
    //   dispatch({type: CANCEL_TRADE_DEFAULT, payload: ""});
    // };
  }, [cancelResponse, statusResponse]);

  const userType = useGetUserType(response ?? {});

  return (
    <>
      <div className="__createTrade__topWrapper">
        <div className="__button__order__wrapper">
          <div className="__order__details__wrapper">
            <p className="__order__type">
              {userType === "user" && response?.type === "buy"
                ? "Buy"
                : userType === "user" && response?.type === "sell"
                ? "Sell"
                : userType === "trader" && response?.type === "buy"
                ? "Sell"
                : userType === "trader" && response?.type === "sell" && "Buy"}{" "}
              Order
              {statusResponse?.status === "cancelled"
                ? " is Cancelled"
                : statusResponse?.status === "completed"
                ? " is Completed"
                : statusResponse?.status === "disputed"
                ? " in Dispute"
                : " in Progress"}
            </p>

            <p className="__order__details">
              {userType === "user" ? (
                response?.type === "buy" ? (
                  statusResponse?.status === "completed" ? (
                    <>
                      You Successfully Purchased{" "}
                      <CurrencyFormat
                        value={response?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={5}
                        fixedDecimalScale
                        style={{fontWeight: "bold"}}
                        // prefix={"$"}
                        suffix={` ${response?.tokenSymbol}`}
                      />
                    </>
                  ) : statusResponse?.status === "cancelled" ? (
                    <>
                      <>You Successfully Cancelled the Trade</>
                    </>
                  ) : (
                    <>Your order has been created</>
                  )
                ) : statusResponse?.status === "completed" ? (
                  <>
                    Your Successfully Sold{" "}
                    <CurrencyFormat
                      value={response?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={5}
                      fixedDecimalScale
                      style={{fontWeight: "bold"}}
                      // prefix={"$"}
                      suffix={` ${response?.tokenSymbol}`}
                    />
                  </>
                ) : (
                  <>
                    You have placed an order for{" "}
                    <span className="__txn__coin">
                      <CurrencyFormat
                        value={response?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={5}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${response?.tokenSymbol}`}
                      />
                    </span>
                  </>
                )
              ) : userType === "trader" && response.type === "sell" ? (
                statusResponse?.status === "completed" ? (
                  <>
                    You Successfully Purchased{" "}
                    <CurrencyFormat
                      value={response?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={5}
                      fixedDecimalScale
                      // prefix={"$"}
                      suffix={` ${response?.tokenSymbol}`}
                    />
                  </>
                ) : statusResponse?.status === "cancelled" ? (
                  <>You Successfully Cancelled the Trade</>
                ) : (
                  <>Your order has been created</>
                )
              ) : statusResponse?.status === "completed" ? (
                <>
                  Your Successfully Sold{" "}
                  <CurrencyFormat
                    value={response?.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={5}
                    fixedDecimalScale
                    style={{fontWeight: "bold"}}
                    // prefix={"$"}
                    suffix={` ${response?.tokenSymbol}`}
                  />
                </>
              ) : (
                <>
                  An order has been placed for
                  <span className="__txn__coin">
                    {" "}
                    <CurrencyFormat
                      value={response?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={5}
                      fixedDecimalScale
                      // prefix={"$"}
                      suffix={` ${response?.tokenSymbol}`}
                    />
                  </span>{" "}
                </>
              )}
            </p>

            <div className="__order__time__wrapper">
              {!expire && (
                <>
                  <p className="__order__time__text">
                    {userType === "user"
                      ? response?.type === "buy"
                        ? "Please make payment immediately to seller"
                        : "Waiting  buyer's payment."
                      : userType === "trader" && response.type === "sell"
                      ? "Waiting  buyer's payment."
                      : "Please make payment immediately to seller"}
                  </p>

                  <div className="__ck_wrap">
                    <span className="__waiting" style={{marginTop: "8px"}}>
                      Wait time remaining
                    </span>
                    <div className="__clock__wrapper">
                      <div className="__clock__wrapper__inner">
                        <div className="flex">
                          <div className={`__clock__time ${expire && "__expire"}`}>
                            {minutes.toString().split("")[1] ? minutes.toString().split("")[0] : 0}
                          </div>
                          <div className={`__clock__time ${expire && "__expire"}`}>
                            {minutes.toString().split("")[1]
                              ? minutes.toString().split("")[1]
                              : minutes.toString().split("")[0]}
                          </div>
                        </div>
                      </div>
                      <span className="__clock__colon">:</span>
                      <div className="__clock__wrapper__inner">
                        <div className="flex">
                          <div className={`__clock__time ${expire && "__expire"}`}>
                            {seconds.toString().split("")[1] ? seconds.toString().split("")[0] : 0}
                          </div>
                          <div className={`__clock__time ${expire && "__expire"}`}>
                            {seconds.toString().split("")[1]
                              ? seconds.toString().split("")[1]
                              : seconds.toString().split("")[0]}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="__order__id__Wrapper">
          <div className="flex __id" style={{marginRight: "5px"}}>
            <span className="__order__id__text">Trade ID : </span>
            <span className="__order__id">{response?.trade_id}</span>
          </div>

          <div className="flex __time">
            <p className="__order__time__text">Time created : </p>
            <span className="__order__time">{moment(response?.timeStamp).format("LLL")}</span>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default CreateTradeTop;
