import {TOKENSINGLELOCALCURRENCY, USER_ORDER, CHECK_COUNTRY_DETAILS, SKELETON_LOADER, ORDERSTATUS} from "../types";

export const getSingleUserOrder = (param) => (dispatch) => {
  dispatch({
    type: USER_ORDER,
    payload: param,
  });
};

export const getSingleUserCurrencyDetails = (param) => (dispatch) => {
  dispatch({
    type: TOKENSINGLELOCALCURRENCY,
    payload: param,
  });
  dispatch({type: SKELETON_LOADER, payload: false});
};

export const getOrderStatus = (param) => (dispatch) => {
  dispatch({
    type: ORDERSTATUS,
    payload: param,
  });
};
