import React, {memo} from "react";
import "../../styles/overviewStyles/overviewFundAccount.scss";
import OverviewCards from "./overviewCards";
import FundCrypto from "../../assets/icons/fundCrypto.svg";
import FundCrypto1 from "../../assets/icons/fundCrypto1.svg";
import SectionModal from "../Common/sectionModal";
import DepositeSelectAnyModal from "../Wallet/walletDepositMultipleModal";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {WALLETCOINDETAILS, WALLETNETWORKDATA} from "../../store/types";
import {getCoinNetworkSelection, getWalletModalDeposit} from "../../store/actions/walletAction";
import {Skeleton} from "@mui/material";

const OverviewFundAccount = () => {
  const dispatch = useDispatch();

  const geoLocation = useSelector((state) => state.generalReducer.locationData);

  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const userData = useSelector((state) => state.generalReducer.userData);
  const setModalDeposit = useSelector((state) => state.walletReducer.setModalDeposit);
  const [selectTab, setSelectTab] = useState({
    title: "Deposite",
    checker: false,
  });

  const fundAccountArray = [
    {
      name: "link",
      diagram: FundCrypto,
      title: "Buy crypto",
      details: "Buy crypto with your local currency here.",
      link: `/app/marketplace/${geoLocation?.data?.geoData?.countryName}/BTC/buy/Bank Transfer`,
      linkData: "Buy",
    },

    // {
    //   name: "link",
    //   diagram: FundCrypto,
    //   title: "Sell crypto",
    //   details: "Sell crypto with your local currency here.",
    //   link: `/app/marketplace/${geoLocation?.data?.geoData?.countryName}/BTC/sell/Bank Transfer`,
    //   linkData: "Sell",
    // },

    {
      name: "button",
      diagram: FundCrypto1,
      title: "Deposit crypto",
      details: "Send crypto from other crypto wallets to your oyola account. ",
      buttonContent: "Deposit",
    },
  ];

  const fundAccountArrayVSell = [
    {
      name: "link",
      diagram: FundCrypto,
      title: "Sell crypto",
      details: "Sell crypto with your local currency here.",
      link: `/app/marketplace/${geoLocation?.data?.geoData?.countryName}/BTC/sell/Bank Transfer`,
      linkData: "Sell",
    },
  ];

  const HandleClickDepositeCrypto = (e) => {
    e.preventDefault();

    dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});

    if (tokenDetails.length > 0) {
      const findBtcDetails = tokenDetails.find((item) => item?.tokenSymbol?.toLowerCase() === "btc");
      dispatch({type: WALLETCOINDETAILS, payload: findBtcDetails});
      dispatch(getCoinNetworkSelection({token: findBtcDetails.tokenSymbol}));
    }

    dispatch(getWalletModalDeposit({name: "multiple", checker: true}));
  };

  return (
    <div className="OverviewFundAccount">
      <div className="OverviewFundAccountHeader">
        <h3>Fund your Oyola account </h3>
        <p>Find the method that suits you to fund your account.</p>
      </div>
      <div className="OverviewFundAccountHeaderCards">
        <OverviewCards DetailedArray={fundAccountArray} onclick={HandleClickDepositeCrypto} />

        {Object.keys(userData).length === 0 ? (
          <Skeleton animation="wave" variant="rectangular" width={410} style={SkeletonStyle} />
        ) : userData?.data?.userData?.isVerified ? (
          <OverviewCards DetailedArray={fundAccountArrayVSell} />
        ) : (
          <OverviewCards DetailedArray={fundAccountArrayVerification} />
        )}
      </div>

      {/* <div className="OverviewVerifyAccount"> */}
      {/* <OverviewCards DetailedArray={fundAccountArrayVerification} /> */}
      {/* </div> */}

      <SectionModal open={setModalDeposit.name === "multiple" ? setModalDeposit.checker : false}>
        <DepositeSelectAnyModal />
      </SectionModal>
    </div>
  );
};

const fundAccountArrayVerification = [
  {
    name: "link",
    diagram: FundCrypto,
    title: "Get verified on Oyola",
    details: "Increase your trading limits when you verify your account.",
    linkData: "Verify",
    link: "/app/settings",
  },
];

const SkeletonStyle = {
  fontSize: "2.5rem",
  fontWeight: "600",
  marginBottom: "2rem",
  background: "rgba(255,255,255,1)",
  width: "200px",
};

export default memo(OverviewFundAccount);
