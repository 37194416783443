import {useEffect} from "react";
import {createContext, useState} from "react";
import {useDispatch} from "react-redux";
import {useParams} from "react-router-dom";
import Layout from "../components/LandingComponents/layout";
import MarketBottomDescription from "../components/LandingComponents/marketplace/bottomArea";
import {MarketTable} from "../components/LandingComponents/marketplace/marketTable";
import {MarketPlaceTopArea} from "../components/LandingComponents/marketplace/topArea";
import LandingServices from "../services/landingServices";
import {LANDING_LOADER, MARKET_DATA} from "../store/types";
import styles from "../styles/landing-page-styles/marketplace.scss";

export const MarketPlaceContext = createContext();

const Marketplace = () => {
  const dispatch = useDispatch();

  const query = useParams();

  const [toggleSwitch, setToggleSwitch] = useState();

  const [countryStateValue, setCountryStateValue] = useState();

  const fetchData = async (queryData) => {
    if (Object.keys(queryData).length > 0) {
      const newQueryData = {
        ...queryData,
        paymentMethod: queryData?.paymentMethod === "null" ? null : queryData?.paymentMethod,
      };

      dispatch({type: LANDING_LOADER, payload: true});

      await LandingServices.getMarketPlaceBuyAndSell({
        ...newQueryData,
        skip: 0,
        limit: 10,
      })
        .then((data) => {
          dispatch({type: MARKET_DATA, payload: data?.marketTrades});
          dispatch({type: LANDING_LOADER, payload: false});
        })
        .catch((err) => {
          dispatch({type: LANDING_LOADER, payload: false});
          throw err;
        });
    }
  };

  useEffect(() => {
    setToggleSwitch(query?.type ?? "buy");
    fetchData(query);
  }, [query]);

  return (
    <MarketPlaceContext.Provider
      value={{
        toggleSwitch,
        setToggleSwitch,
        countryStateValue,
        setCountryStateValue,
      }}
    >
      <div className={styles.__parentWrapper}>
        <Layout>
          <div className="__parentWrapper">
            <MarketPlaceTopArea />
            <MarketTable />
            <MarketBottomDescription />
          </div>
        </Layout>
      </div>
    </MarketPlaceContext.Provider>
  );
};

export default Marketplace;
