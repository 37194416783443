// Import necessary styles, components, and assets
import "../../styles/convert/ConvertCardComponent.scss";
import ConvertCardInputComponent from "./ConvertCardInputComponent";
import nairaIcon from "../../assets/icons/naira.svg";
import {useRef} from "react";
import ConvertDropDown from "./ConvertDropDown";
// Import Redux hooks and actions for state management
import {useDispatch, useSelector} from "react-redux";

// Import various actions from convertAction
import {
  getConvertConvertionNormal,
  getConvertConvertionRate,
  getConvertDropdownContent,
  getConvertDropdownState,
  getConvertInsufficientFunds,
  getConvertLevel,
  getConvertMinLoader,
  getConvertTokenInput,
  getConvertTokenMaxMinError,
} from "../../store/actions/convertAction";

// Import additional components and utilities
import CurrencyFormat from "react-currency-format";
import ConvertServices from "../../services/Convert";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {toast} from "react-toastify";

// Define the ConvertCardComponent functional component
const ConvertCardComponent = () => {
  // Ref for the dropdown element
  const dropRef = useRef();

  // Access Redux dispatch function
  const dispatch = useDispatch();

  // Fetch user data from the Redux store
  const userData = useSelector((state) => state.generalReducer.userData);

  // Extract token details from user data
  const tokenDetails = userData?.data?.userData?.tokens;

  // Extract various state values from the Redux store
  const {
    convertDropdownState,
    convertDropdownContent,
    convertConvertionRate,
    convertConvertionNormal,
    convertTokenInput,
    convertTokenMaxMinError,
    convertInsufficientFunds,
  } = useSelector((state) => state.convertReducer);

  // Attach click event to close dropdown when clicked outside
  useOnClickOutside(dropRef, () => dispatch(getConvertDropdownState(false)));

  // Handle showing/hiding the dropdown
  const handleShowDropdown = () => {
    dispatch(getConvertDropdownState(!convertDropdownState));
  };

  // Handle selecting an item from the dropdown
  const handleSelecctItem = (item) => {
    // Dispatch actions to update various states
    dispatch(getConvertMinLoader(true));
    dispatch(getConvertDropdownContent(item));
    dispatch(getConvertDropdownState(!convertDropdownState));

    // Fetch conversion rates and update related states
    ConvertServices?.getConversionRates(1, item?.tokenSymbol)
      .then((data) => {
        dispatch(getConvertConvertionRate(data));
        dispatch(getConvertTokenInput(0));
        dispatch(getConvertInsufficientFunds(""));
        dispatch(getConvertTokenMaxMinError(""));
        dispatch(getConvertConvertionNormal({}));
        dispatch(getConvertMinLoader(false));
      })
      .catch((err) => {
        toast(err?.message);
      });
  };

  // Handle changes in the input token value
  const handleChangeToken = (value) => {
    // Dispatch actions to update token input value and related states
    dispatch(getConvertTokenInput(value.replace(/,/g, "")));

    // Perform calculations and dispatch relevant actions
    // clear error in case it comes up
    if (value.replace(/,/g, "") === "") {
      dispatch(getConvertTokenMaxMinError(""));
      dispatch(getConvertInsufficientFunds(""));
    }

    if (Number(value.replace(/,/g, "")) > 0) {
      // clearTimeout(inputRef.current);

      if (Number(value.replace(/,/g, "")) > Number(convertDropdownContent?.balance)) {
        dispatch(getConvertInsufficientFunds("Insufficient balance"));
      } else {
        dispatch(getConvertInsufficientFunds(""));
      }

      dispatch(getConvertMinLoader(true));

      // Convert the coins here
      if (
        Number(value.replace(/,/g, "")) >= convertConvertionRate.minAmount &&
        Number(value.replace(/,/g, "") <= convertConvertionRate.maxAmount)
      ) {
        dispatch(getConvertTokenMaxMinError(""));
        // setHandleError(false);
      } else {
        dispatch(
          getConvertTokenMaxMinError("The limit per transaction is between $10.00 - $1000.00. Please adjust the amount")
        );
        // setHandleError(false);
      }

      const convertedRateData = Number(convertConvertionRate?.rate) * Number(value.replace(/,/g, ""));

      dispatch(getConvertConvertionNormal({finalAmount: convertedRateData}));
      dispatch(getConvertMinLoader(false));
      // setHandleError(true);
    } else {
      dispatch(getConvertConvertionNormal({finalAmount: 0}));
    }
  };

  return (
    <div className="rightSideConvertComponentCard">
      <div className="ConvertComponentCardHeader">
        <p className="ConvertComponentCardHeaderTitleStyle">Sell Crypto</p>
      </div>
      <div className="ConvertComponentCardBody">
        <div className="ConvertComponentCardItem">
          <ConvertCardInputComponent
            setDropDown={() => handleShowDropdown()}
            token={true}
            value={convertTokenInput}
            handleChageSystem={(e) => handleChangeToken(e.target.value)}
            convertTokenError={convertTokenMaxMinError}
            coinTitle={convertDropdownContent?.tokenSymbol}
            icon={convertDropdownContent?.tokenSymbol}
          />
          {/* Available balance */}
          {convertTokenMaxMinError || convertInsufficientFunds ? (
            <>
              {convertInsufficientFunds ? (
                <p className="ConvertComponentCardBodyTitleError">{convertInsufficientFunds}</p>
              ) : (
                <p className="ConvertComponentCardBodyTitleError">{convertTokenMaxMinError}</p>
              )}
            </>
          ) : (
            <p className="ConvertComponentCardBodyTitle">
              Available ={" "}
              <CurrencyFormat
                value={convertDropdownContent?.balance}
                thousandSeparator={true}
                suffix={` ${convertDropdownContent?.tokenSymbol}`}
                decimalScale={convertDropdownContent?.tokenSymbol === "BTC" ? 7 : 2}
                displayType={"text"}
              />{" "}
            </p>
          )}

          {/* DropDown */}
          <div ref={dropRef}>
            {convertDropdownState && (
              <ConvertDropDown tokenDetails={tokenDetails} handleSelecctItem={handleSelecctItem} />
            )}
          </div>
        </div>

        <div className="ConvertComponentCardItemTwo">
          <ConvertCardInputComponent
            label="You Get"
            value={convertConvertionNormal?.finalAmount}
            icon={nairaIcon}
            coinTitle="NGN"
            localCurrencyIcon={true}
            dropdown={true}
          />

          <p className="ConvertComponentCardBodyTitle">
            {convertDropdownContent?.tokenSymbol} ={" "}
            <CurrencyFormat
              value={convertConvertionRate?.rate}
              thousandSeparator={true}
              suffix={` NGN`}
              decimalScale={2}
              displayType={"text"}
            />
          </p>
        </div>
        <button
          className="ConvertComponrntCardBodyButton"
          onClick={() => dispatch(getConvertLevel(1))}
          disabled={
            !convertConvertionNormal?.finalAmount ||
            convertTokenMaxMinError ||
            convertInsufficientFunds ||
            convertTokenInput <= 0
              ? true
              : false
          }
        >
          Convert {convertDropdownContent?.tokenSymbol}
        </button>
      </div>
    </div>
  );
};

export default ConvertCardComponent;
