import {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCoinNetworkSelection, getWithdrawMainDetail} from "../../store/actions/walletAction";
import {
  TOKENNETWORKFEE,
  WALLETADDRESSCHECKER,
  WALLETADDRESSERROR,
  WALLETCOINDETAILS,
  WALLETNETWORKDATA,
  WITHDRAWALERROR,
} from "../../store/types";
import {CoinIcons} from "../Common/coinSwitcher";
import Dropdown from "../Common/Dropdown";

const CoinDresser = ({edited, setShowBarcode, showBarCode}) => {
  const dispatch = useDispatch();
  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const walletCoinDetails = useSelector((state) => state.walletReducer.walletCoinDetials);

  const HandleClickCoin = (item) => {
    dispatch({type: WALLETCOINDETAILS, payload: item});
    dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});
    dispatch({type: WALLETADDRESSCHECKER, payload: false});
    dispatch({type: WALLETADDRESSERROR, payload: ""});
    dispatch({type: WITHDRAWALERROR, payload: ""});
    dispatch({type: TOKENNETWORKFEE, payload: {}});

    if (showBarCode) {
      setShowBarcode(false);
    }

    dispatch(getCoinNetworkSelection({token: item.tokenSymbol}));
    if (edited === true) {
      dispatch(getWithdrawMainDetail({}));
    }
  };

  return (
    <div>
      <Dropdown
        CoinIcons={CoinIcons}
        list={tokenDetails}
        iconLead={true}
        select={walletCoinDetails?.tokenSymbol?.toUpperCase()}
        HandleClickCoin={HandleClickCoin}
      />
    </div>
  );
};

export default memo(CoinDresser);
