import React, {memo} from "react";
import BuyDresser from "./BuyDresser";
import CoinDresser from "./CoinDresser";
import SellDresser from "./SellDresser";
import "../../styles/postTradeStyles/postTradeFirstSection.scss";

const PostTradeFirstSection = () => {
  return (
    <div className="postTrade__FirstLayout">
      <div className="postTrade__firstLayoutHeader">
        <h4>Trade Type</h4>
      </div>
      <div className="postTrade__firstLayoutChecker">
        <div className="postTrade__firstLayoutCheckerBuy">
          <div className="postTrade__firstLayoutCheckerBuyChecker">
            <BuyDresser />
          </div>
          <div className="postTrade__firstLayoutCheckerBuyBody">
            <h5>Buy Crypto</h5>
            <p>Select this option to buy crypto</p>
          </div>
        </div>
        <div className="postTrade__firstLayoutCheckerSell">
          <div className="postTrade__firstLayoutCheckerSellChecker">
            <SellDresser />
          </div>
          <div className="postTrade__firstLayoutCheckerSellBody">
            <h5>Sell Crypto</h5>
            <p>Select this option to sell crypto</p>
          </div>
        </div>
      </div>
      <div className="postTrade__firstLayoutSelectCrypto">
        <div className="postTrade__firstLayoutSelectCryptoBody">
          <label>What coin do you want to buy?</label>
          <div className="postTrade__firstLayoutSelectCryptoBodyContent">
            <CoinDresser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PostTradeFirstSection);
