import {createContext} from "react";
import {useSelector} from "react-redux";
import {DeskTopTable} from "./desktopTable";
import {MobileMarketTable} from "./mobileTable";
export const ActionModalContext = createContext();
const MarketTable = () => {
  const {marketData, landingLoader} = useSelector((state) => state.LandingReducer);
  // const {width} = useWindowDimensions();
  return (
    <>
      <MobileMarketTable loading={landingLoader} data={marketData} />
      <DeskTopTable loading={landingLoader} data={marketData} />
    </>
  );
};

export {MarketTable};
