import React, {memo, useState} from "react";
import PhoneSearchable from "./phoneSearchable";
import "../../styles/settings/phoneverification.scss";
import {settingVerifyModal} from "../../store/actions/settingAction";
import {useDispatch, useSelector} from "react-redux";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
import SettingsService from "../../services/settingsServices";
import {Loader4} from "../Common/Loader";

const VerifyPhoneNumber = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);
  const phoneSearchableDetails = useSelector((state) => state.settingsReducer.phoneSearchableDetails);
  const upDatedPhoneNumberDetails = useSelector((state) => state.settingsReducer.upDatedPhoneNumberDetails);

  const nextButton = async () => {
    // const payload = {
    //   email: userData?.data?.userData?.email,
    //   username: "",
    //   isLogin: false,
    // };

    const phonePayload = {
      phoneNumber: `${
        phoneSearchableDetails.areaCode.startsWith("+")
          ? phoneSearchableDetails.areaCode
          : `${phoneSearchableDetails.areaCode}`
        // : `+${phoneSearchableDetails.areaCode}`
      }${
        upDatedPhoneNumberDetails.startsWith("0")
          ? upDatedPhoneNumberDetails.slice(1, upDatedPhoneNumberDetails.length)
          : upDatedPhoneNumberDetails
      }`,

      email: userData?.data?.userData?.email,
    };

    dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: phonePayload});

    setIsLoading(true);

    SettingsService.PhoneOtp(phonePayload).then((data) => {
      dispatch(settingVerifyModal(12));
    });
  };

  const HandleChange = (e) => {
    dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: e.target.value});
  };

  return (
    <div className="verificationWrapper">
      <div className="verificationWrapper__codeDetails">
        <PhoneSearchable decoratorWidthWrapper={"100%"} DropdownWidth={"400%"} />
      </div>
      <div className="verificationWrapper__input">
        <input
          type="text"
          value={upDatedPhoneNumberDetails || ""}
          placeholder="XXX XXXX XXXX"
          onChange={HandleChange}
        />
        <button
          style={buttonStyle}
          className="base-button"
          disabled={
            upDatedPhoneNumberDetails.length > 8 ||
            `${
              phoneSearchableDetails.areaCode.startsWith("+")
                ? phoneSearchableDetails.areaCode
                : `${phoneSearchableDetails.areaCode}`
              // : `+${phoneSearchableDetails.areaCode}`
            }${
              upDatedPhoneNumberDetails.startsWith("0")
                ? upDatedPhoneNumberDetails.slice(1, upDatedPhoneNumberDetails.length)
                : upDatedPhoneNumberDetails
            }` === userData?.data?.userData.phoneNumber
              ? false
              : true
          }
          onClick={nextButton}
        >
          {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Verify</p>}
        </button>
      </div>
    </div>
  );
};

const buttonStyle = {
  textAlign: "center",
  margin: "auto",
  boxShadow: "0px 0.959618px 1.91924px rgba(16, 24, 40, 0.05)",
  borderRadius: "7.67695px",
  fontSize: "1.4rem",
  height: "35px",
  width: "100px",
};

export default memo(VerifyPhoneNumber);
