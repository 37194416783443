import React from "react";
import "./payment-modal.scss";

const ShowPaymentMethodModal = React.forwardRef(({data, setData}, ref) => {
  return (
    <div className="navDropContainer" ref={ref}>
      {data?.map(({name}, index) => (
        <li key={index} className="listMenuIcon" onClick={() => setData(name)}>
          <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
            <p style={{color: "rgba(0, 0, 0, 0.7)"}}>{name}</p>
          </div>
        </li>
      ))}
    </div>
  );
});

export default ShowPaymentMethodModal;
