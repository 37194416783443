import {useContext} from "react";
import {RiErrorWarningLine} from "react-icons/ri";
import {ActionModalContext} from "../../marketTable";

const CanNotCallMobileModal = () => {
  const {setModalAction} = useContext(ActionModalContext);

  return (
    <div className="__mobile__action__modal">
      <div className="__innerWarning__wrapper">
        <div className="__the__inner">
          <RiErrorWarningLine color="#FBCB5C" className="__icon" />
          <p className="__the__p">Not Possible To Trade With Same Account</p>
          <button className="__warning__button" onClick={() => setModalAction(false)}>
            Ok
          </button>
        </div>
      </div>
    </div>
  );
};

export default CanNotCallMobileModal;
