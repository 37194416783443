import {useContext, memo} from "react";
import {useDispatch} from "react-redux";
import {
  filterBuySellByDateAction,
  filterBuySellByStatusAction,
  filterBuySellByTypeAction,
} from "../../../store/actions/buySellTransactionHistory";
import {
  filterDepositWithdrawByDateAction,
  filterDepositWithdrawByStatusAction,
  filterDepositWithdrawByTypeAction,
} from "../../../store/actions/depositWithdrawTransactionHistory";
import "../../../styles/filterModalComponent.scss";
import DataRangeComponent from "../../Common/dateRange";
import {TransactionStaticDropDown} from "../../Common/transactionStaticDropdown";
import {TransactionContext} from "../../Home/Transaction";

const FilterMobileComponent = ({status, setStatus, type, setType}) => {
  const dispatch = useDispatch();
  const {transactionType} = useContext(TransactionContext);
  const handleTypeChange = (val) => {
    if (transactionType === "buysell") {
      dispatch(filterBuySellByTypeAction(val));
      setType((prev) => ({...prev, value: val?.status, label: val?.status}));
    } else {
      if (val === null) {
        dispatch(filterDepositWithdrawByTypeAction({type: null}));
      } else {
        dispatch(filterDepositWithdrawByTypeAction(val));
      }

      setType((prev) => ({...prev, value: val?.status, label: val?.status}));
    }
  };

  const handleStatusChange = (val) => {
    if (transactionType === "buysell") {
      dispatch(filterBuySellByStatusAction(val));
      setStatus((prev) => ({...prev, value: val?.status, label: val?.status}));
    } else {
      if (val === null) {
        dispatch(filterDepositWithdrawByStatusAction({status: null}));
      } else {
        dispatch(filterDepositWithdrawByStatusAction(val));
      }

      setStatus((prev) => ({...prev, value: val?.status, label: val?.status}));
    }
  };

  const handleDateChange = (val) => {
    if (transactionType === "buysell") {
      dispatch(filterBuySellByDateAction(val));
    } else {
      dispatch(filterDepositWithdrawByDateAction(val));
    }
  };

  return (
    <div className="__filter__Modal__Mobile">
      {/* <h3 className="__filterTitle">Filter</h3> */}

      <div className="___ggd">
        <div className="__the__options">
          <span className="__filter__optionLabel">Coin Type</span>
          {transactionType === "buysell" ? (
            <TransactionStaticDropDown
              options={[
                {
                  value: "buy",
                  attr: "type",
                  label: <span style={{fontSize: 15, color: "#101828"}}>Buy</span>,
                },
                {
                  value: "sell",
                  attr: "type",
                  label: <span style={{fontSize: 15, color: "#101828"}}>Sell</span>,
                },
              ]}
              name={"Filter by Type"}
              initialValue={type}
              dropdownOnChangeFunction={handleTypeChange}
              useLabel={true}
            />
          ) : (
            <TransactionStaticDropDown
              options={[
                {
                  value: "deposit",
                  attr: "type",
                  label: <span style={{fontSize: 15, color: "#101828"}}>Deposit</span>,
                },
                {
                  value: "withdraw",
                  attr: "type",
                  label: <span style={{fontSize: 15, color: "#101828"}}>Withdraw</span>,
                },
                {
                  value: "convert",
                  attr: "type",
                  label: <span style={{fontSize: 15, color: "#101828"}}>Convert</span>,
                },
              ]}
              name={"Filter by Type"}
              initialValue={type}
              dropdownOnChangeFunction={handleTypeChange}
              useLabel={true}
            />
          )}
        </div>
        <div className="__the__options">
          <span className="__filter__optionLabel">Status</span>
          {transactionType === "buysell" ? (
            <TransactionStaticDropDown
              options={[
                {
                  value: "completed",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Completed
                    </span>
                  ),
                },
                {
                  value: "cancelled",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#B42318",
                        backgroundColor: "#FEF3F2",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Cancelled
                    </span>
                  ),
                },
                {
                  value: "pending",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Pending
                    </span>
                  ),
                },
              ]}
              initialValue={status}
              dropdownOnChangeFunction={handleStatusChange}
              name={"Status"}
            />
          ) : (
            <TransactionStaticDropDown
              options={[
                {
                  value: "completed",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Completed
                    </span>
                  ),
                },
                {
                  value: "cancelled",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#B42318",
                        backgroundColor: "#FEF3F2",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Cancelled
                    </span>
                  ),
                },
                {
                  value: "pending",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Pending
                    </span>
                  ),
                },
                {
                  value: "aborted",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Aborted
                    </span>
                  ),
                },
                {
                  value: "reverted",
                  attr: "status",
                  label: (
                    <span
                      style={{
                        fontSize: 12,
                        color: "#027A48",
                        backgroundColor: "#ECFDF3",
                        padding: "3px 8px",
                        borderRadius: "4px",
                      }}
                    >
                      Reverted
                    </span>
                  ),
                },
              ]}
              initialValue={status}
              dropdownOnChangeFunction={handleStatusChange}
              name={"Status"}
              useLabel={true}
            />
          )}
        </div>
        <div className="__the__options">
          <span className="__filter__optionLabel">Date</span>
          <div className="___datee">
            <DataRangeComponent onClickOkay={handleDateChange} style={{width: "330px"}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(FilterMobileComponent);
