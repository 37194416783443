import React from "react";
import "../../styles/convert/convert_confirm.scss";
import {useDispatch, useSelector} from "react-redux";
import CurrencyFormat from "react-currency-format";
import {
  // getConvertBankAccChecker,
  // getConvertBankDetails,
  // getConvertConfirmAccDetails,
  // getConvertConvertionNormal,
  // getConvertConvertionRate,
  // getConvertDropdownContent,
  getConvertLevel,
  getConvertModalChecker,
  // getConvertTokenInput,
} from "../../store/actions/convertAction";
import AuthService from "../../services/auth";
import WalletService from "../../services/walletService";

import convertConfirmBackIcon from "../../assets/icons/convertConfirmBackIcon.png";

const ConvertConfirm = () => {
  const dispatch = useDispatch();

  const userData = useSelector((state) => state.generalReducer.userData);

  const {
    convertConfirmAccDetails,
    convertBankDetails,
    convertTokenInput,
    convertDropdownContent,
    convertConvertionRate,
    convertConvertionNormal,
    convertTransferDetails,
  } = useSelector((state) => state.convertReducer);

  const handleSubmit = (e) => {
    e.preventDefault();

    // dispatch(getConvertModalChecker({num: 0}));

    // userData?.data?.userData?.tokens?.map((item) => {
    //   if (item?.tokenSymbol?.toLowerCase() === "btc") {
    //     dispatch(getConvertDropdownContent(item));
    //   }
    // });

    // dispatch(getConvertConvertionRate({}));
    // dispatch(getConvertTokenInput(0));
    // dispatch(getConvertConvertionNormal({}));
    // dispatch(getConvertBankDetails({}));
    // dispatch(getConvertConfirmAccDetails({}));
    // dispatch(getConvertBankAccChecker(""));

    // dispatch(getConvertLevel(0));

    // dispatch(getConvertModalChecker({num: 3}));

    if (userData?.data?.userData?.isVerified === false) {
      dispatch(getConvertModalChecker({num: 3}));

      return;
    }

    if (userData?.data?.userData?.authPreference === "google auth") {
      dispatch(getConvertModalChecker({num: 1}));
    } else if (userData?.data?.userData?.authPreference === "sms") {
      WalletService.SendOtp().then((data) => {
        dispatch(getConvertModalChecker({num: 1}));
      });
    } else {
      AuthService.verifyEmailSecurely({email: userData?.data?.userData?.email, isLogin: false}).then((data) => {
        dispatch(getConvertModalChecker({num: 1}));
      });
    }
  };

  const handlePrevious = () => {
    dispatch(getConvertLevel(1));
  };

  return (
    <div className="convertConfirm">
      <div className="BackButtonConfirmContainer" onClick={handlePrevious}>
        <img src={convertConfirmBackIcon} alt="back button" className="BackButtonConfirmContainerIcon" />
        <p className="BackButtonConfirmContainerTitle">Back</p>
      </div>
      <div className="convertConfirm_firm">
        <p className="convertConfirm_firm_title">Confirm Order</p>
      </div>
      <div className="convertConfirm_amount">
        <p className="convertConfirm_amount_text">
          <CurrencyFormat
            value={convertConvertionNormal?.finalAmount || ""}
            thousandSeparator={true}
            displayType="text"
            suffix={` NGN`}
            decimalScale={2}
          />
        </p>
      </div>
      <div className="convertConfirm_details">
        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Pay with</p>
          <p className="convertConfirm_details_content_two">{convertDropdownContent?.tokenSymbol}</p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Price</p>
          <p className="convertConfirm_details_content_two">
            {`1 ${convertDropdownContent?.tokenSymbol} = `}
            <span>
              <CurrencyFormat
                value={convertConvertionRate?.rate}
                thousandSeparator={true}
                suffix={` NGN`}
                decimalScale={2}
                displayType={"text"}
              />
            </span>
          </p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Fee</p>
          <p className="convertConfirm_details_content_two">0.00 NGN</p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Amount</p>
          <p className="convertConfirm_details_content_two">{`${convertTokenInput} ${convertDropdownContent?.tokenSymbol}`}</p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Receiver name</p>
          <p className="convertConfirm_details_content_two">{convertConfirmAccDetails?.account_name}</p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Receiver bank number</p>
          <p className="convertConfirm_details_content_two">{convertTransferDetails?.account_number}</p>
        </div>

        <div className="convertConfirm_details_content">
          <p className="convertConfirm_details_content_one">Receiver bank name</p>
          <p className="convertConfirm_details_content_two">{convertBankDetails?.name}</p>
        </div>
      </div>

      <div className="convertConfirm_button">
        <button className="convertConfirm_content" onClick={handleSubmit}>
          Confirm
        </button>
      </div>
    </div>
  );
};

export default ConvertConfirm;
