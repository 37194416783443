import Layout from "../components/LandingComponents/layout";
import DownloadComponent from "../components/LandingComponents/download-component";

const DownloadPage = () => {
  return (
    <Layout>
      <DownloadComponent />
    </Layout>
  );
};

export default DownloadPage;
