import {ALLL_TRADE_ORDERS, ALL_TRADE_ORDERS_INITIAL} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const tradeOrdersReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ALLL_TRADE_ORDERS: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case ALL_TRADE_ORDERS_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default tradeOrdersReducer;
