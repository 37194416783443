import React from "react";
import "../../styles/button.scss";
import "../../styles/new/settings.scss";
import {Skeleton} from "@mui/material";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";

import {toast} from "react-toastify";

import GeneralService from "../../services/general";
import UploadImageService from "../../services/uploadImage";

import {getUser} from "../../store/actions/general";
import {Avatar} from "../../hooks/imagePath";

const UserImageButton = () => {
  const dispatch = useDispatch();
  const {userData} = useSelector((state) => state.generalReducer);

  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);

  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleFileChange = (e) => {
    let image = e.target.files[0];
    let formdata = new FormData();
    formdata.append("image", image, "profile_" + image?.name);

    handleUpload(formdata);
  };

  const handleUpload = (uploadFile) => {
    setLoading(true);
    setLoad(true);
    UploadImageService.upload(uploadFile)
      .then((res) => {
        toast.success("Image uploaded successfully");
        setLoading(false);
      })
      .then(() => {
        GeneralService.getUserData().then((res) => {
          dispatch(getUser(res));
          setLoad(false);
        });
      })
      .catch((err) => {
        toast.error("There is an issue uploading profile image");
        setLoading(false);
      });
  };

  let profileImage = userData?.data?.userData?.profileImageUrl
    ? `https://oyola.s3.amazonaws.com/${userData?.data?.userData?.profileImageUrl}`
    : Avatar;

  return (
    <React.Fragment>
      <article className="left-container">
        <div className="profile-container">
          {loading && load ? (
            <Skeleton
              animation="wave"
              variant="circular"
              width={120}
              height={120}
              style={{
                color: "#F6F9FA",
                borderRadius: "50%",
                objectFit: "cover",
                width: 120,
                height: 120,
                background: "rgba(248,248,248,70)",
                boxShadow:
                  "0px 11.5154px 15.3539px -3.83847px rgba(16, 24, 40, 0.08), 0px 3.83847px 5.75771px -1.91924px rgba(16, 24, 40, 0.03)",
              }}
            />
          ) : (
            <>
              {userData?.data?.userData ? (
                <img
                  alt="theimage"
                  src={profileImage}
                  style={{
                    borderRadius: "50%",
                    objectFit: "cover",
                    width: 120,
                    height: 120,
                    boxShadow:
                      "0px 11.5154px 15.3539px -3.83847px rgba(16, 24, 40, 0.08), 0px 3.83847px 5.75771px -1.91924px rgba(16, 24, 40, 0.03)",
                  }}
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="circular"
                  width={120}
                  height={120}
                  style={{
                    color: "#F6F9FA",
                    borderRadius: "50%",
                    objectFit: "cover",
                    width: 120,
                    height: 120,
                    background: "rgba(248,248,248,70)",
                    boxShadow:
                      "0px 11.5154px 15.3539px -3.83847px rgba(16, 24, 40, 0.08), 0px 3.83847px 5.75771px -1.91924px rgba(16, 24, 40, 0.03)",
                  }}
                />
              )}
            </>
          )}
        </div>
        <div className="upload-container">
          <input ref={hiddenFileInput} type={"file"} onChange={handleFileChange} style={{display: "none"}} />

          <div>
            {!userData?.data?.userData?.email ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "600",
                  marginBottom: "2rem",
                  background: "rgba(248,248,248,70)",
                  width: "300px",
                }}
              />
            ) : (
              <p style={{fontSize: "2.5rem", fontWeight: "600", marginBottom: "2rem", width: "400px"}}>
                {`${
                  userData?.data?.userData?.firstName === null || userData?.data?.userData?.firstName === undefined
                    ? ""
                    : userData?.data?.userData?.firstName
                } 
                
                ${
                  userData?.data?.userData?.lastName === null || userData?.data?.userData?.firstName === undefined
                    ? ""
                    : userData?.data?.userData?.lastName
                }`}
              </p>
            )}

            <button className="baseButton primaryButton" style={buttonStyle} onClick={handleClick}>
              Change profile image
            </button>
          </div>
        </div>
      </article>
    </React.Fragment>
  );
};

const buttonStyle = {
  boxShadow: "0px 0.959618px 1.91924px rgba(16, 24, 40, 0.05)",
  borderRadius: "7.67695px",
  fontSize: "1.4rem",
  padding: "0.5rem 1.5rem",
  width: "200px",
};

export default UserImageButton;
