import React, {memo, useState} from "react";
// import {useHistory} from "react-router-dom";
import {
  displayUsernameNotAvailableAction,
  loginDetailsSecond,
  registerErrorDetailsForEmail,
  registerLoader,
} from "../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/auth";
import "../../styles/login.scss";
import LoginOTPInput from "../Common/loginOtpInput";
import {useHistory} from "react-router-dom";

const RegisterOTP = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);

  const loginDetails = useSelector((state) => state.authReducer.loginDetails);
  const loginDetailDataSecond = useSelector((state) => state.authReducer.loginDetailsSecond);

  const goBack = () => {
    dispatch(registerLoader(0));
    dispatch(loginDetailsSecond(""));
    dispatch(registerErrorDetailsForEmail(""));
    dispatch(displayUsernameNotAvailableAction(""));
  };

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const goToHome = () => {
    const userEntered = loginDetails.oneTimeCode;
    if (otp === String(userEntered)) {
      const payload = {
        email: loginDetails.email,
        username: loginDetails.username,
        password: loginDetails.password,
      };

      dispatch(registerLoader(1));
      AuthService.register(payload)
        .then((data) => {
          dispatch(registerLoader(2));
          // dispatch(registerLoader(1));
          if (data.data.message === "success") {
            // window.location = "/app/overview";
            history.push("/app/overview");
            setError(false);
          } else {
            setError(true);
            dispatch(registerLoader(2));
            dispatch(loginDetailsSecond("Sorry we could not sign up user at the moment.. Please try again later"));
          }
        })
        .catch((error) => {
          dispatch(registerLoader(2));
          dispatch(dispatch(loginDetailsSecond(error)));
        });
    } else {
      setError(true);
      dispatch(registerLoader(2));
      dispatch(loginDetailsSecond("Invalid code or code expired"));
    }
  };

  const emailFirstFive = loginDetails.hasOwnProperty("email") ? loginDetails.email.slice(0, 5) : "";
  const emailLastNine = loginDetails.hasOwnProperty("email") ? loginDetails.email.slice(-9) : "";

  return (
    <>
      <div className="login-container">
        <div className="login-content">
          <div className="form-contents">
            <p className="title" style={{textAlign: "left", margin: "3rem 0 0"}}>
              Security verification
            </p>
            <p className="tag" style={{textAlign: "left"}}>
              Please type in the code received in your email
            </p>

            <div style={{...otpContainer, margin: "3rem 0 0.5rem"}}>
              <p className="title" style={{fontSize: "1.6rem", margin: "0", padding: "0"}}>
                Email verification code
              </p>

              {error === true ? (
                <p style={{color: "#F04438", textAlign: "left", marginTop: "0px", width: "100%"}}>
                  {loginDetailDataSecond}
                </p>
              ) : null}

              <LoginOTPInput value={otp} onChange={handleChange} error={loginDetailDataSecond} />

              <p className="tag" style={{marginTop: "0px", fontSize: "13px", textAlign: "left"}}>
                We sent a code to {`${emailFirstFive}****${emailLastNine}`}
              </p>
            </div>

            <button className="buttonStyle" onClick={() => goToHome()} style={{marginTop: "2rem"}}>
              Confirm
            </button>
            <button className="buttonStyle" style={goBackStyle} onClick={goBack}>
              back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const otpContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
};

const goBackStyle = {
  width: "100%",
  background: "#fff",
  color: "black",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
};

export default memo(RegisterOTP);
