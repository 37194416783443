import {useSelector} from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import "../../../styles/table.scss";
import ConvertMobileTable from "./mobileTable";
import ConvertDesktopTable from "./desktopTable";
const TransactionConvertTableSection = () => {
  const {width} = useWindowDimensions();
  const {response, loading} = useSelector((state) => state.buySellTransactionReducer);
  return width <= 600 ? (
    <ConvertMobileTable data={response} loading={loading} />
  ) : (
    <ConvertDesktopTable data={response} loading={loading} />
  );
};

export {TransactionConvertTableSection};
