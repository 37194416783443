import APIs from "../../services/api";

class ValidationHelper {
  constructor(username, email) {
    this.username = username;
    this.email = email;
  }

  async validateUsername() {
    let value = await APIs.post("/api/v1/open/username-check", {username: this.username}).then((data) => {
      return data;
    });
    return value;
  }

  async validateEmail() {
    let value = await APIs.post("/api/v1/user/verify-email", {
      email: this.email,
      username: this.username,
    }).then((data) => {
      return data;
    });

    return value;
  }
}

export const Validators = {
  getValidationService: (username, email) => {
    var validations = new ValidationHelper(username, email);
    return validations;
  },
};
