import {
  CONVERT_DROPDOWN_STATE,
  CONVERT_DROPDOWN_CONTENT,
  CONVERT_TOKEN_ERROR,
  CONVERT_BANK_DROPDOWN,
  CONVERT_BANK_ACC_CHECKER,
  CONVERT_LEVEL,
  CONVERT_LOADER,
  CONVERT_CONVERTION_RATE,
  CONVERT_BANK_LIST,
  CONVERT_CONVERTION_NORMAL,
  CONVERT_TOKEN_INPUT,
  CONVERT_TOKEN_MAX_MIN_ERROR,
  CONVERT_MIN_LOADER,
  CONVERT_BANK_DETAILS,
  CONVERT_CONFIRM_ACC_DETAILS,
  CONVERT_TRANSFER_DETAILS,
  CONVERT_MODAL_CHECKER,
  CONVERT_INSUFFICIENT_FUNDS,
} from "../types";

const initialState = {
  convertDropdownState: false,
  convertDropdownContent: {},
  convertTokenError: "",
  convertBankDropdown: false,
  convertBackAccChecker: false,
  convertLevel: 0,
  convertLoader: true,
  convertConvertionRate: {},
  convertConvertionNormal: {},
  convertTokenInput: 0,
  convertBankList: [],
  convertTokenMaxMinError: "",
  convertMinLoader: false,
  convertBankDetails: {},
  convertConfirmAccDetails: {},
  convertTransferDetails: {},
  convertModalChecker: {},
  convertInsufficientFunds: "",
};

const convertReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CONVERT_DROPDOWN_STATE: {
      return {
        ...state,
        convertDropdownState: payload,
      };
    }

    case CONVERT_DROPDOWN_CONTENT: {
      return {
        ...state,
        convertDropdownContent: payload,
      };
    }

    case CONVERT_TOKEN_ERROR: {
      return {
        ...state,
        convertTokenError: payload,
      };
    }

    case CONVERT_BANK_DROPDOWN: {
      return {
        ...state,
        convertBankDropdown: payload,
      };
    }

    case CONVERT_BANK_ACC_CHECKER: {
      return {
        ...state,
        convertBackAccChecker: payload,
      };
    }

    case CONVERT_LEVEL: {
      return {
        ...state,
        convertLevel: payload,
      };
    }

    case CONVERT_LOADER: {
      return {
        ...state,
        convertLoader: payload,
      };
    }

    case CONVERT_CONVERTION_RATE: {
      return {
        ...state,
        convertConvertionRate: payload,
      };
    }

    case CONVERT_BANK_LIST: {
      return {
        ...state,
        convertBankList: payload,
      };
    }

    case CONVERT_CONVERTION_NORMAL: {
      return {
        ...state,
        convertConvertionNormal: payload,
      };
    }

    case CONVERT_TOKEN_INPUT: {
      return {
        ...state,
        convertTokenInput: payload,
      };
    }

    case CONVERT_TOKEN_MAX_MIN_ERROR: {
      return {
        ...state,
        convertTokenMaxMinError: payload,
      };
    }

    case CONVERT_MIN_LOADER: {
      return {
        ...state,
        convertMinLoader: payload,
      };
    }

    case CONVERT_BANK_DETAILS: {
      return {
        ...state,
        convertBankDetails: payload,
      };
    }

    case CONVERT_CONFIRM_ACC_DETAILS: {
      return {
        ...state,
        convertConfirmAccDetails: payload,
      };
    }

    case CONVERT_TRANSFER_DETAILS: {
      return {
        ...state,
        convertTransferDetails: payload,
      };
    }

    case CONVERT_MODAL_CHECKER: {
      return {
        ...state,
        convertModalChecker: payload,
      };
    }

    case CONVERT_INSUFFICIENT_FUNDS: {
      return {
        ...state,
        convertInsufficientFunds: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default convertReducer;
