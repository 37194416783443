import React from "react";
import TheTradeOrderTable from "./overviewTrade/table";

const OverviewTrade = () => {
  return (
    <React.Fragment>
      <TheTradeOrderTable />
    </React.Fragment>
  );
};

export default OverviewTrade;
