import btc from "../../assets/tokenIcons/btc.svg";
import bnb from "../../assets/tokenIcons/bnb.svg";
import eth from "../../assets/tokenIcons/eth.svg";
import usdt from "../../assets/tokenIcons/usdt.svg";
import "../../styles/coinWrapper.scss";

const CoinIcons = ({intialValue, mainWrapper, className}) => {
  switch (intialValue?.toLowerCase()) {
    case "btc":
      return (
        // <div className="image__body">
        <img src={btc} alt="" className="images" style={{...mainWrapper}} />
        // </div>
      );
    case "bnb":
      return (
        // <div className="image__body">
        <img src={bnb} alt="" className="images" style={{...mainWrapper}} />
        // </div>
      );
    case "eth":
      return (
        // <div className="image__body">
        <img src={eth} alt="" className="images" style={{...mainWrapper}} />
        // </div>
      );
    case "usdt":
      return (
        // <div className="image__body">
        <img src={usdt} alt="" className="images" style={{...mainWrapper}} />
        // </div>
      );

    default:
      return (
        // <div className="image__body">
        <img src={btc} alt="" className="images" style={{...mainWrapper}} />
        // </div>
      );
  }
};

export {CoinIcons};
