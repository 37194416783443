import React, {useState} from "react";
import OverviewDash from "../Overview/OverViewDash";
import OverviewTrade from "../Overview/OverviewTrade";
import OverviewMarketPlace from "../Overview/OverviewMarket";
import "../../styles/overview.scss";
import "./tabbed.scss";
const Overview = () => {
  const [tab, setTab] = useState(0);

  const overview = (data) => {
    switch (data) {
      case "overview": {
        setTab(0);
        break;
      }

      case "marketplace": {
        setTab(1);
        break;
      }

      case "mytrade": {
        setTab(2);
        break;
      }

      default: {
        setTab(0);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="overview-container">
        <div className="tabs">
          <li onClick={() => overview("overview")} className={0 === tab ? "current" : ""}>
            Overview
          </li>

          <li onClick={() => overview("marketplace")} className={1 === tab ? "current" : ""}>
            Marketplace
          </li>

          <li onClick={() => overview("mytrade")} className={2 === tab ? "current" : ""}>
            My Trade
          </li>
        </div>

        <div className="main-content">
          {tab === 0 && <OverviewDash />}
          {tab === 1 && <OverviewMarketPlace />}
          {tab === 2 && <OverviewTrade />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Overview;
