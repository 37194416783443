import React, {memo, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import ArrowDown from "../../assets/icons/arrow-drop-down.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const SellCrypto = ({isOpen}) => {
  const [open, setOpen] = useState(false);
  const thref = useRef();
  const [showSelectCondition, setShowSelectCondition] = useState(false);
  const locationDatas = useSelector((state) => state.generalReducer.locationData);
  const handleClick = () => {
    if (showSelectCondition === false) {
      setOpen(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpen(false);
      setShowSelectCondition(false);
    }
  };

  useOnClickOutside(thref, () => setOpen(false));

  const menuItemData = [
    {
      id: 0,
      name: "Find offers to sell BNB",
      page: "Sell BNB",
      image: "bnb",
      path: `/app/marketplace/${locationDatas?.data?.geoData?.countryName}/BNB/sell`,
    },
    {
      id: 1,
      name: "Find offers to sell BTC",
      page: "Sell BTC",
      image: "btc",
      path: `/app/marketplace/${locationDatas?.data?.geoData?.countryName}/BTC/sell`,
    },
    {
      id: 2,
      name: "Find offers to sell ETH",
      page: "Sell ETH",
      image: "eth",
      path: `/app/marketplace/${locationDatas?.data?.geoData?.countryName}/ETH/sell`,
    },
    {
      id: 3,
      name: "Find offers to sell USDT",
      page: "Sell USDT",
      image: "usdt",
      path: `/app/marketplace/${locationDatas?.data?.geoData?.countryName}/USDT/sell`,
    },
  ];

  return (
    <React.Fragment>
      <div
        className="__buy__crptoMennuWrapper"
        style={{padding: isOpen && "0 15px", marginRight: isOpen ? "14px" : "6px"}}
      >
        <p className="__buy__cryptoWrapper" onClick={() => handleClick()}>
          Sell Crypto <img src={ArrowDown} alt="trailing-icon" style={{marginLeft: "10px"}} />
        </p>

        {open && (
          <div className="__buy__crypto__dropdown" data-aos="zoom-in" ref={thref} onClick={() => setOpen(false)}>
            {menuItemData.map((item, index) => {
              return (
                <Link className="__the__buycrypto__link" to={item.path} key={index}>
                  <div style={{display: "flex"}}>
                    <img src={require(`../../assets/icons/${item.image}.svg`)} alt="" height="35px" />
                  </div>
                  <div style={{display: "flex", flexDirection: "column"}}>
                    <p className="__dd__title" to={item.path}>
                      {item.page}
                    </p>
                    <p className="__dd__description" to={item.path}>
                      {item.name}
                    </p>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(SellCrypto);
