import {RELEASE_TOKEN, RELEASE_TOKEN_DEFAULT, RELEASE_TOKEN_ERROR, RELEASE_TOKEN_INITIAL} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const releaseTokenReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case RELEASE_TOKEN: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case RELEASE_TOKEN_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case RELEASE_TOKEN_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case RELEASE_TOKEN_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default releaseTokenReducer;
