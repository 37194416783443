import OyolaWallet from "../components/LandingComponents/oyola-wallet.component";
import DiscoverOyolaBenefit from "../components/LandingComponents/discover-oyola-benefit.component";
import CardList from "../components/LandingComponents/card-list/card-list.component.jsx";

import instantIcon from "../assets/icons/instant-feature-icon.svg";
import supportIcon from "../assets/icons/support-feature-icon.svg";
import easyIcon from "../assets/icons/easy-feature-icon.svg";
import secureIcon from "../assets/icons/secure-feature-icon.svg";
import referralIcon from "../assets/icons/referral-feature-icon.svg";
import freeTransaction from "../assets/icons/free-feature-icon.svg";
import "../styles/landing-page-styles/features.scss";

import "../styles/landing-page-styles/text.scss";
import {Fragment} from "react";
import Layout from "../components/LandingComponents/layout";

const Features = () => {
  return (
    <Layout>
      <Fragment>
        <DiscoverOyolaBenefit />
        <div className="textContainerStyle">
          <h1 className="title"> The P2P platform with a difference</h1>
        </div>
        <OyolaWallet />

        <main className="featuresContainer">
          <div className="innerContainer">
            <div className="card" style={{margin: "6rem 0 8rem"}}>
              <CardList
                icon={freeTransaction}
                title="Free transactions"
                subTitle="Convert your digital assets to traditional currencies or exchange them for other cryptocurrencies at no cost."
              />
              <CardList
                icon={referralIcon}
                title="Referral bonus"
                subTitle="Receive $10 as a referral bonus when you invite your friends to join Oyola"
              />
              <CardList
                icon={secureIcon}
                title="Secure transactions"
                subTitle="Ensure safe and reliable transactions through the use of our escrow services."
              />
              <CardList
                icon={easyIcon}
                title="Easy-to-use"
                subTitle="Experience smooth navigation with our user-friendly interface for convenient transactions."
              />
              <CardList
                icon={supportIcon}
                title="24/7 customer support"
                subTitle="Enjoy access to 24/7 customer service and support whenever you need it."
              />
              <CardList
                icon={instantIcon}
                title="Instant transactions"
                subTitle="Make fast cryptocurrency transactions in just a few minutes using Oyola."
              />
            </div>
          </div>
        </main>
      </Fragment>
    </Layout>
  );
};

export default Features;
