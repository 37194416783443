import {useState} from "react";
import "./style.scss";
import selectIcon from "../../../assets/icons/aboutus-menu-icon.svg";
import selectedIcon from "../../../assets/icons/aboutus-menu-selected-icon.svg.svg";

const AboutUsTabMenu = ({tab}) => {
  const [active, setActive] = useState(0);

  const selectedTab = (switcher) => {
    switch (switcher) {
      case 0: {
        setActive(0);
        break;
      }
      case 1: {
        setActive(1);
        break;
      }
      case 2: {
        setActive(2);
        break;
      }

      default: {
        setActive(0);
      }
    }
  };

  return (
    <div className="outterSelectedContainer" data-aos="fade-left">
      {dataMenus.map((data, index) => (
        <div
          key={index}
          className="selectedContainer"
          onClick={() => {
            selectedTab(data.id);
            tab(data.id);
          }}
          style={{
            backgroundColor: active === data.id ? "#7f56d9" : "white",
            color: active === data.id ? "white" : "#34495E",
          }}
        >
          <div className="rowFourButtonContainerLeft">
            <img src={active === data.id ? selectedIcon : selectIcon} alt={data.icon} className="SelecticonStyle" />
          </div>
          <div className="rowFourButtonContainerRight">
            <p className="rowFourTitleButtonContainerRight" style={{color: active === data.id ? "white" : "#34495E"}}>
              {data.title}
            </p>
            <p className="rowFourTagButtonContainerRight" style={{color: active === data.id ? "white" : "#34495E"}}>
              {data.body}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

const dataMenus = [
  {
    id: 0,
    icon: "",
    title: "P2P Trading",
    body: "Oyola is utterly peer-to-peer. This means your trades, be it buying or selling, is done with real people.",
  },
  {
    id: 1,
    icon: "2",
    title: "Oyola Wallet",
    body: "Securely store your assets in a zero fee wallet for as long as you’d like. Easily transact with other Oyola wallets at no added cost.",
  },
  {
    id: 2,
    icon: "3",
    title: "Escrow Security",
    body: "Take advantage of our escrow security to avoid problems with undelivered assets. Your crypto assets are held in a third-party account and released only when the transaction is complete.",
  },
];

export default AboutUsTabMenu;
