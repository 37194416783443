import OtpInput from "react-otp-input";
import "../../styles/new/optStyles.scss";

const LoginOTPInput = ({value, onChange, error}) => {
  return (
    <OtpInput
      value={value}
      onChange={onChange}
      className="optInputField"
      numInputs={6}
      placeholder="000000"
      isInputNum={true}
      containerStyle="optInputContainer"
      hasErrored={error ? true : false}
      errorStyle={{
        border: "1px solid #F04438",
      }}
    />
  );
};

export default LoginOTPInput;
