import CurrencyFormat from "react-currency-format";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import {Avatar, NOTFOUND} from "../../../../../utils/imagePath";
import moment from "moment";
import {capitalizeFirstLetter} from "../../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../../utils/getTimeDiff";
import {Loader} from "../../../loader";
import "../../../../../styles/landing-page-styles/table.scss";
import {useContext} from "react";
import {MarketPlaceContext} from "../../../../../screens/marketplace";
import {Link} from "react-router-dom";

const DeskTopTable = ({data, loading}) => {
  const token = localStorage.getItem("token");
  const {toggleSwitch, countryStateValue} = useContext(MarketPlaceContext);

  return (
    <div className="table__wrapper">
      {loading ? (
        <Loader />
      ) : (
        <table className="tablew" style={{height: data.length === 0 && "auto"}}>
          <thead className="newTable__thead">
            <tr className="newTable__tr">
              <th className="newTable__thh">Seller</th>
              {/* <th className={styles.newTable__th}>Coin</th> */}
              <th className="newTable__thh">Ratings</th>
              <th className="newTable__thh">Limit Order</th>
              <th className="newTable__thh">Unit Price</th>
              <th className="newTable__thh">PaymentMethod</th>
              <th className="newTable__thh"></th>
            </tr>
          </thead>
          <tbody className="newTable__tbody">
            {data &&
              data.length > 0 &&
              data.map((item) => {
                return (
                  <tr className="myTable" style={{height: "40px"}}>
                    <td className="tableItemm">
                      <div className="__seller__wrapper">
                        <img src={Avatar} className="__seller__image" alt="the_avatar" />

                        <div
                          className={`__seller__notifier ${
                            getTimeDifference(moment(item?.tradeData?.timeLastSeen)) < 5
                              ? "online"
                              : getTimeDifference(moment(item?.tradeData?.timeLastSeen)) > 5 &&
                                getTimeDifference(moment(item?.tradeData?.timeLastSeen)) < 15
                              ? "away"
                              : "offline"
                          }`}
                        ></div>

                        <div className="__seller__details">
                          <div className="__sellerName">{item?.username}</div>
                          <div className="__sellerDetails">
                            {item?.tradeData?.totalNumberOfTrades} Orders,{" "}
                            {item?.tradeData?.totalNumberOfTrades === 0 ? (
                              0
                            ) : (
                              <CurrencyFormat
                                value={
                                  (item?.tradeData?.numberCompletedTrades / item?.tradeData?.totalNumberOfTrades) * 100
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                // prefix={"$"}
                                // suffix={` ${tradeResponse?.tokenSymbol}`}
                              />
                            )}
                            %
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="tableItemm">
                      <div className="__market__ratings">
                        <div className="__ratings__like">
                          <span className="__rating">{item?.tradeData?.trusts}</span>
                          <AiOutlineLike size={14} className="__icon" />
                        </div>
                        <div className="__ratings__dislike">
                          <span className="__rating">{item?.tradeData?.distrusts}</span>
                          <AiOutlineDislike size={14} className="__icon" />
                        </div>
                      </div>
                    </td>
                    <td className="tableItemm">
                      <div className="__market__limit" style={{minWidth: "180px"}}>
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.minAmount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            // prefix={"$"}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                        {" - "}
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.maxAmount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            // prefix={"$"}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                      </div>
                    </td>
                    <td className="tableItemm">
                      <div className="__market__unitprice" style={{width: "max-content"}}>
                        <CurrencyFormat
                          value={Math.abs(item.unitPrice) ?? 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          className="__theUnitPrice"
                          // prefix={"$"}
                          suffix={` ${item?.currency}`}
                        />
                      </div>
                    </td>
                    <td className="tableItemm">
                      <div className="__market__paymentMethod">{item.paymentMethod}</div>
                    </td>
                    <td className="tableItemm">
                      <div className="transactionTableArea__viewMoreWrappers">
                        {token === null || token === undefined ? (
                          <Link to={"/login"} className="transactionTableArea__viewMore">
                            {capitalizeFirstLetter(toggleSwitch)} {capitalizeFirstLetter(item?.tokenSymbol)}
                          </Link>
                        ) : (
                          <a
                            href={`/app/marketplace/${countryStateValue?.name}/${capitalizeFirstLetter(
                              item?.tokenSymbol
                            )}/${toggleSwitch}/${item.paymentMethod}`}
                            target={"_blank"}
                            className="transactionTableArea__viewMore"
                            rel="noreferrer"
                          >
                            {capitalizeFirstLetter(toggleSwitch)} {capitalizeFirstLetter(item?.tokenSymbol)}
                          </a>
                        )}
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}
      {data && data.length === 0 && !loading && (
        <div className="__notFound__wrapperr">
          <div className="__theNotFound">
            <img src={NOTFOUND} alt="not found" />
            <span>No Result</span>
          </div>
        </div>
      )}

      {/* <TablePaginationComponent onNext={onNext} onPrev={onPrev} skip={skip} /> */}
    </div>
  );
};

export {DeskTopTable};
