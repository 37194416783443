import {BsChat, BsFacebook, BsInstagram, BsMedium, BsSearch, BsTwitter, BsYoutube} from "react-icons/bs";
import {HiOutlineMail} from "react-icons/hi";
import SupportFaqs from "../components/LandingComponents/support/faqs";
import "../styles/landing-page-styles/support.scss";
import {supportdata} from "../data/support.data";
import {useState} from "react";
import Layout from "../components/LandingComponents/layout";

const Support = () => {
  const [searchValue, setSearchValue] = useState("");
  const [dataArray, setDataArray] = useState();
  return (
    <Layout>
      <div className="__support__wrapper">
        <div className="__hero">
          <div className="gfg">
            <div className="__titleWraper">
              <h4 className="__howcan">How can we help you?</h4>
              <p className="__howcan__desc">
                Whatever you need help with, we're here for you. Get answers to your questions from our frequently asked
                questions.
              </p>
            </div>

            <div className="__searchWrapper">
              <div className="__searhBorder">
                <BsSearch className="__icon" />
                <input
                  value={searchValue}
                  className="__input"
                  placeholder="Search help article"
                  onChange={(e) => {
                    setSearchValue(e.target.value);
                    const ff = supportdata.filter((item) => {
                      return (
                        item?.title?.toLowerCase()?.includes(e?.target?.value?.toLowerCase()) ||
                        item?.description?.text?.toLowerCase()?.includes(e?.target?.value?.toLowerCase())
                      );
                    });
                    setDataArray(ff);
                  }}
                />
              </div>

              <button className="__searchbutton">Search</button>
            </div>
          </div>
        </div>

        <div className="__bb">
          <div className="__faqcontainer">
            <h4>FAQ</h4>
            <p className="__faqdesc">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
          </div>

          {searchValue !== "" && searchValue !== null ? (
            <SupportFaqs
              data={dataArray && dataArray.length > 0 ? dataArray?.sort(() => 0.5 - Math.random()).slice(0, 5) : []}
            />
          ) : (
            <SupportFaqs data={supportdata?.slice(0, 5)} />
          )}

          <div className="__faqcontainer" style={{marginTop: "10vh"}}>
            <h4>Customer support</h4>
            <p className="__faqdesc">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
          </div>

          <div className="__contact__wrapper">
            <div className="__medium">
              <HiOutlineMail color="#7F56D9" size={22} />
              <p className="__type">Email</p>
              <p className="__desc">Our friendly team is here to help.</p>
              <p className="__link">support@oyola.io</p>
            </div>
            <div className="__medium">
              <BsChat color="#7F56D9" size={20} />
              <p className="__type">Live Chat</p>
              <p className="__desc">Our friendly team is here to help.</p>
              <p className="__link">Start new chat</p>
            </div>
          </div>

          <div className="__faqcontainer" style={{marginTop: "10vh"}}>
            <h4>Social Communities</h4>
            <p className="__faqdesc">We’d love to hear from you. Please fill out this form or shoot us an email.</p>
          </div>

          <div className="__social__wrapper">
            <a
              href={"https://twitter.com/oyola_io?s=21&t=mgH6p6bffDRcjxZ_vxhL3w"}
              target={"_blank"}
              className="__item"
              rel="noreferrer"
            >
              <BsTwitter size={24} />

              <p>Twitter</p>
            </a>
            <a
              href={"https://instagram.com/oyola.io?igshid=YmMyMTA2M2Y="}
              target={"_blank"}
              className="__item"
              rel="noreferrer"
            >
              <BsInstagram size={24} />

              <p>Instagram</p>
            </a>
            <a
              href={"https://www.facebook.com/oyola.io?mibextid=LQQJ4d"}
              target={"_blank"}
              className="__item"
              rel="noreferrer"
            >
              <BsFacebook size={24} />

              <p>Facebook</p>
            </a>
            <a href={"https://medium.com/@oyola"} target={"_blank"} className="__item" rel="noreferrer">
              <BsMedium size={24} />

              <p>Medium</p>
            </a>
            <div href={"https://www.youtube.com/channel/UCBsaaatpNhMNcWmbXjPtfdA"} target={"_blank"} className="__item">
              <BsYoutube size={24} />

              <p>Youtube</p>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Support;
