import "../../styles/new/settings.scss";

const ListInfo = (props) => {
  return (
    <span className="listStyles">
      <p style={textStyle}>{props.text}</p>
      <i>{props.icon}</i>
    </span>
  );
};

const textStyle = {
  fontSize: "1.4rem",
  color: "#344054",
};

export default ListInfo;
