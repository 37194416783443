import {BNB, BTC, ETH, NGN, NOTFOUND, USDT} from "../../../../hooks/imagePath";
import {TablePaginationComponent} from "../../../Common/pagination";
import "../../../../styles/table.scss";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {Loader} from "../../../Common/Loader";
import CurrencyFormat from "react-currency-format";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {useHistory} from "react-router-dom";
import {TheUserTradeContext} from "../../../Home/UserTrade";
import {
  getBuySellDisputeTransactionAction,
  getBuySellOnNextAction,
  getBuySellOnPrevAction,
  getBuySellPendingTransactionAction,
  getBuySellTransactionAction,
} from "../../../../store/actions/buySellTransactionHistory";
import {
  CHANGE_PAGINATION,
  GET_LOADER,
  GET_PENDING_TRADE,
  TRADE_CHECK_FOR_EMPTY,
  TRADE_CHECK_FOR_EXTRA_DATA,
  TRADE_SKIP,
} from "../../../../store/types";
import {DOMTablePaginationComponent2} from "../../../Common/DOM_Pagination/index2";
import UserTradeService from "../../../../services/getusertrades";
const DisputedMobileTable = ({data, loading}) => {
  const {tradeOrder} = useContext(TheUserTradeContext);
  const {push} = useHistory();
  const dispatch = useDispatch();
  const {pageSize, currentPage} = useSelector((state) => state.domPaginationReducer);

  const {skip, fSkip, limit, checkForExtraData, checkForEmpty, tradeDetials} = useSelector(
    (state) => state.getUserTradeReducer
  );

  // useEffect(() => {
  //   dispatch({type: CHANGE_PAGINATION, payload: 1});

  //   return () => {
  //     dispatch({type: CHANGE_PAGINATION, payload: 1});
  //   };
  // }, [data]);
  //Get current page
  const onNext = () => {
    const skipDetails = (skip + 1) * limit;
    if (checkForEmpty?.length !== 0) {
      dispatch({type: GET_LOADER, payload: true});

      UserTradeService.OnlyPendingTradeService(skipDetails, limit).then((data) => {
        dispatch({type: GET_PENDING_TRADE, payload: []});

        if (data?.message === "success") {
          if (data?.trades?.length > 0) {
            dispatch({type: GET_PENDING_TRADE, payload: data?.pendingTrades});
            dispatch({type: TRADE_SKIP, payload: skip + 1});
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.pendingTrades});
            dispatch({type: GET_LOADER, payload: false});
            if (data?.pendingTrades?.length === limit) {
              dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: true});
              const futureSkipDetails = skipDetails + limit;

              UserTradeService.OnlyPendingTradeService(futureSkipDetails, limit).then((data) => {
                if (data?.pendingTrades?.length === 0) {
                  dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
                  dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
                } else {
                  dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
                }
              });
            }
          } else {
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
            dispatch({type: GET_LOADER, payload: false});
          }
        }
      });
    }
  };

  const onPrev = () => {
    dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: null});
    const skipDetails = (skip - 1) * limit;
    if (data?.length > 0 && skip + 1 > 1) {
      dispatch({type: GET_LOADER, payload: true});
      UserTradeService.OnlyPendingTradeService(skipDetails, limit).then((data) => {
        dispatch({type: GET_PENDING_TRADE, payload: []});
        if (data.message === "success") {
          dispatch({type: GET_PENDING_TRADE, payload: data?.pendingTrades});
          dispatch({type: TRADE_SKIP, payload: skip - 1});
          dispatch({type: GET_LOADER, payload: false});
        }
      });
    }
  };

  return (
    <>
      <div className="__transaction__mobile__wrapper">
        {loading ? (
          // Array.from(new Array(5)).map((item, i) => (
          //   <Stack spacing={1} key={i} sx={{margin: "10px 10px 10px 10px"}}>
          //     <Skeleton animation="wave" variant="rectangular" height={200} />
          //   </Stack>
          // ))
          <Loader />
        ) : data && data.length > 0 ? (
          data.map((item, key) => (
            <div className="__mobile__content__wrapper" style={{backgroundColor: "#FFF9DF"}} key={key}>
              <span className="__trade__title">
                {capitalizeFirstLetter(item?.type)} {item?.tokenSymbol}
              </span>
              <div style={{marginTop: "15px"}}>
                <div className="__details">
                  <p className="__title">Unit Price</p>
                  <span className="__value">
                    <CurrencyFormat
                      value={item?.unitPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                      suffix={` ${item?.currency}`}
                    />
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Crypto amount</p>
                  <span className="__value">
                    <CurrencyFormat
                      value={item?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={5}
                      fixedDecimalScale
                      suffix={` ${item?.tokenSymbol}`}
                    />
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Counteparty</p>
                  <span className="__value __counte">Shibetoshi</span>
                </div>
                <div className="__details">
                  <p className="__title">Status</p>
                  <span
                    className={`__value ${
                      item?.status === "pending"
                        ? "__pending"
                        : item?.status === "cancelled"
                        ? "__cancelled"
                        : "__complete"
                    }`}
                  >
                    {item?.status}
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Action</p>
                  <span className="__value __view" onClick={() => push(`/app/trade/${item?.trade_id}`)}>
                    View Trade
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="__notFound__wrapper">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}
      </div>
      <div>
        {data && data?.length !== 0 && data !== null && data !== undefined && (
          <TablePaginationComponent
            onNext={onNext}
            onPrev={onPrev}
            skip={skip}
            lengthChecker={checkForEmpty}
            dataCheck={data}
            limit={limit}
            loading={checkForExtraData}
          />
        )}
      </div>
    </>
  );
};

export default DisputedMobileTable;
