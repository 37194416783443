import {useState, useRef, useEffect} from "react";
import {SlRefresh} from "react-icons/sl";
import {BNB, BTC, ETH, USDT} from "../../../hooks/imagePath";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {BottomSheet} from "react-spring-bottom-sheet";
import {TransactionStaticDropDown} from "../../Common/transactionStaticDropdown";
import MobileFilterModal from "./mobileFilterModal";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {getMarketPlaceBuyAndSellAction, marketPlaceDefaultPageAction} from "../../../store/actions/market";
import {useHistory} from "react-router-dom";
import {CHANGE_MARKET_PLACE_STATE} from "../../../store/types";

const OverViewMarketFilterArea = () => {
  const {width} = useWindowDimensions();
  const {push} = useHistory();
  const dispatch = useDispatch();
  const sheetRef = useRef();
  const [mobileFilterModal, setMobileFilterModal] = useState(false);
  const {countryData, paymentMethod} = useSelector((state) => state.generalReducer);
  const {locationData} = useSelector((state) => state.generalReducer);
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  let dd = countryData?.data?.countriesData.filter((item) => item.countryName === marketPlaceData.country)[0];
  // const {limit, fskip} = useSelector((state) => state.marketReducer);
  const [change, setChange] = useState(false);

  let theLocation = locationData?.data?.geoData?.countryName ?? "worldwide";

  const thePaymentMethod = paymentMethod?.data?.paymentsData?.paymentMethod?.map((item) => ({
    attr: "paymentMethod",
    value: item.name,
    label: item.name,
  }));

  const refreshData = {...marketPlaceData, type: "buy", country: `Worldwide`, paymentMethod: null};

  const [countryStateValue, setCountryStateValue] = useState({...dd, name: dd?.countryName, nameAcr: dd?.alpha3});

  useEffect(() => {
    setCountryStateValue({...dd, name: dd?.countryName, nameAcr: dd?.alpha3});
  }, [dd]);

  const onUpdate = () => {
    dispatch({
      type: CHANGE_MARKET_PLACE_STATE,
      payload: {...marketPlaceData, type: "buy", country: `Worldwide`, paymentMethod: null},
    });
    setChange(!change);
    dispatch(getMarketPlaceBuyAndSellAction({...refreshData, limit: 20, skip: 0}));
    dispatch(marketPlaceDefaultPageAction());
  };

  return (
    <>
      <div className="__marketPlaceSwitchWrapper"></div>

      {width >= 700 ? (
        <div className="__filterAreaWrapper">
          <div className="__theSwitch" style={{marginRight: "20px"}}>
            <div
              className={`__marketBuy ${marketPlaceData.type}`}
              onClick={() => {
                if (width <= 700) {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "buy"}});
                  dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "buy"}));
                } else {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "buy"}});
                  dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "buy"}));
                }
              }}
            >
              Buy
            </div>
            <div
              className={`__marketSell ${marketPlaceData.type}`}
              onClick={() => {
                if (width <= 700) {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "sell"}});
                  dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "sell"}));
                } else {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "sell"}});
                  dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "sell"}));
                }
              }}
            >
              Sell
            </div>
          </div>

          <div className="__selectcoinwrapper" style={{width: "240px"}}>
            {/* <p className="__label">Coin Type</p> */}
            <TransactionStaticDropDown
              options={[
                {
                  attr: "token",
                  value: "BTC",
                  label: (
                    <span className="flex items__center ">
                      <img src={BTC} width="25" alt="btc" className="mr-1" />
                      <p>BTC</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "ETH",
                  label: (
                    <span className="flex items__center __theicons">
                      <img src={ETH} width="25" alt="btc" className="mr-1" />
                      <p>ETH</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "USDT",
                  label: (
                    <span className="flex items__center ">
                      <img src={USDT} width="25" alt="btc" className="mr-1" />
                      <p>USDT</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "BNB",
                  label: (
                    <span className="flex items__center ">
                      <img src={BNB} width="25" alt="btc" className="mr-1" />
                      <p>BNB</p>
                    </span>
                  ),
                },
              ]}
              initialValue={{
                attr: "token",
                value: "BTC",
                label: (
                  <span className="flex items__center ">
                    <img src={BTC} width="25" alt="btc" className="mr-1" />

                    <p>BTC</p>
                  </span>
                ),
              }}
              dropdownOnChangeFunction={(item) => {
                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, token: item.token}});
              }}
              name={"Coin Type"}
              useLabel={true}
              showName={true}
              style={{margin: 0, width: "100%"}}
            />
          </div>

          <div className="__updateButtonWrapper" style={{margin: "0px"}}>
            <div className="__marketFilterUpdateButton" onClick={onUpdate}>
              <SlRefresh style={{fontSize: "18px"}} />
              <span>Refresh</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="__selectcoinwrapper" style={{marginTop: "30px", padding: "0 10px"}}>
          <div className="__filterAreaWrapper">
            <div className="__theSwitch" style={{marginRight: "20px"}}>
              <div
                className={`__marketBuy ${marketPlaceData.type}`}
                onClick={() => {
                  if (width <= 700) {
                    dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {type: "buy"}});
                    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "buy"}));
                  } else {
                    dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {type: "buy"}});
                    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "buy"}));
                  }
                }}
              >
                Buy
              </div>
              <div
                className={`__marketSell ${marketPlaceData.type}`}
                onClick={() => {
                  if (width <= 700) {
                    dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {type: "sell"}});
                    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "sell"}));
                  } else {
                    dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {type: "sell"}});
                    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0, type: "sell"}));
                  }
                }}
              >
                Sell
              </div>
            </div>

            {/* <div className="__updateButtonWrapper">
              <div className="__marketFilterUpdateButton" onClick={onUpdate}>
                <SlRefresh style={{fontSize: "18px"}} />
                <span>Refresh</span>
              </div>
            </div> */}
          </div>

          <TransactionStaticDropDown
            options={[
              {
                attr: "token",
                value: "BTC",
                label: (
                  <span className="flex items__center ">
                    <img src={BTC} width="25" alt="btc" className="mr-1" />
                    <p>BTC</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "ETH",
                label: (
                  <span className="flex items__center __theicons">
                    <img src={ETH} width="25" alt="btc" className="mr-1" />
                    <p>ETH</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "USDT",
                label: (
                  <span className="flex items__center ">
                    <img src={USDT} width="25" alt="btc" className="mr-1" />
                    <p>USDT</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "BNB",
                label: (
                  <span className="flex items__center ">
                    <img src={BNB} width="25" alt="btc" className="mr-1" />
                    <p>BNB</p>
                  </span>
                ),
              },
            ]}
            initialValue={{
              attr: "token",
              value: "BTC",
              label: (
                <span className="flex items__center ">
                  <img src={BTC} width="25" alt="btc" className="mr-1" />

                  <p>BTC</p>
                </span>
              ),
            }}
            dropdownOnChangeFunction={(item) => {
              dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {token: item.token}});
              dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: 20, skip: 0}));
            }}
            name={"Coin Type"}
            useLabel={true}
            showName={true}
            style={{margin: 0, width: "100%"}}
          />

          <div className="__filterAreaWrapperStatus">
            <div className="__updateButtonWrapper">
              <div className="__marketFilterUpdateButton" onClick={onUpdate}>
                <SlRefresh style={{fontSize: "15px"}} />
                <span>Refresh</span>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <WhiteMobileModal2 open={mobileFilterModal} setOpen={setMobileFilterModal}>
        <MobileFilterModal setOpen={setMobileFilterModal} />
      </WhiteMobileModal2> */}

      <BottomSheet
        ref={sheetRef}
        open={mobileFilterModal}
        onDismiss={() => setMobileFilterModal(false)}
        // defaultSnap={({maxHeight}) => maxHeight * 0.6}
        // snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
        header={
          <h3
            style={{
              textAlign: "center",
              fontSize: "23px",
              fontWeight: 600,
              padding: "10px 0 10px 0",
            }}
          >
            Filter
          </h3>
        }
        footer={
          <div className="__button__wrapper">
            <button className="__cancel__button" onClick={() => setMobileFilterModal(false)}>
              Cancel
            </button>

            <button
              className="__buy__buttonn"
              onClick={() => {
                onUpdate();
                setMobileFilterModal(false);
              }}
            >
              Confirm
            </button>
          </div>
        }
      >
        <MobileFilterModal countryStateValue={countryStateValue} setCountryStateValue={setCountryStateValue} />
      </BottomSheet>
    </>
  );
};

export {OverViewMarketFilterArea};
