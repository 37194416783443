import {
  PROFILE_DATA,
  LIST_OF_BLOCKED_USERS,
  LIST_OF_REVIEWS,
  ALL_PAGE_REVIEWS,
  BLOCK_A_USER_MODAL,
  PROFILE_HEADER_OTHER_DATA,
  CHECK_IF_A_USER_IS_BLOCKED,
  PAGINATION_ARRAY_LIST,
  PAGE_NUM,
  PROFILE_PAGINATION_TOTAL,
  PAGINATION_ARRAY_LIST_NEGATIVE,
  PAGE_NUM_NEGATIVE,
  PROFILE_PAGINATION_TOTAL_NEGATIVE,
  PAGINATION_ARRAY_LIST_POSITIVE,
  PAGE_NUM_POSITIVE,
  PROFILE_PAGINATION_TOTAL_POSITIVE,
  ARRAYFORPAGINATION,
  ALL_REVIEW_PAGE_ACTION,
  SAVE_OTHER_USERNAME_ACTION,
} from "../types";

const initialState = {
  profileData: {},
  listBlockedUsers: 0,
  listOfReviewsState: [],
  allPagesReviewsData: [],
  blockAUserModaldata: null,
  profileHeaderOtherDataReducer: [],
  checkIfAUserIsBlockedState: "",
  paginationArrayList: [],
  pageNum: 0,
  profilePaginationTotal: 0,

  paginationArrayListNegative: [],
  pageNumNegative: 0,
  profilePaginationTotalNegative: 0,

  paginationArrayListPositive: [],
  pageNumPositive: 0,
  profilePaginationTotalPositive: 0,

  arrayForPagination: [],
  allReviewPageState: [],
  saveOtherUsernameState: "",
};

const profileReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ARRAYFORPAGINATION: {
      return {
        ...state,
        arrayForPagination: payload,
      };
    }

    case PAGINATION_ARRAY_LIST: {
      return {
        ...state,
        paginationArrayList: payload,
      };
    }

    case PAGE_NUM: {
      return {
        ...state,
        pageNum: payload,
      };
    }

    case PROFILE_PAGINATION_TOTAL: {
      return {
        ...state,
        profilePaginationTotal: payload,
      };
    }

    case PAGINATION_ARRAY_LIST_NEGATIVE: {
      return {
        ...state,
        paginationArrayListNegative: payload,
      };
    }

    case PAGE_NUM_NEGATIVE: {
      return {
        ...state,
        pageNumNegative: payload,
      };
    }

    case PROFILE_PAGINATION_TOTAL_NEGATIVE: {
      return {
        ...state,
        profilePaginationTotalNegative: payload,
      };
    }

    case PAGINATION_ARRAY_LIST_POSITIVE: {
      return {
        ...state,
        paginationArrayListPositive: payload,
      };
    }

    case PAGE_NUM_POSITIVE: {
      return {
        ...state,
        pageNumPositive: payload,
      };
    }

    case PROFILE_PAGINATION_TOTAL_POSITIVE: {
      return {
        ...state,
        profilePaginationTotalPositive: payload,
      };
    }

    case PROFILE_DATA: {
      return {
        ...state,
        profileData: payload,
      };
    }
    case LIST_OF_BLOCKED_USERS: {
      return {
        ...state,
        listBlockedUsers: payload,
      };
    }
    case LIST_OF_REVIEWS: {
      return {
        ...state,
        listOfReviewsState: payload,
      };
    }
    case ALL_PAGE_REVIEWS: {
      return {
        ...state,
        allPagesReviewsData: payload,
      };
    }

    case BLOCK_A_USER_MODAL: {
      return {
        ...state,
        blockAUserModaldata: payload,
      };
    }

    case PROFILE_HEADER_OTHER_DATA: {
      return {
        ...state,
        profileHeaderOtherDataReducer: payload,
      };
    }
    case CHECK_IF_A_USER_IS_BLOCKED: {
      return {
        ...state,
        checkIfAUserIsBlockedState: payload,
      };
    }

    case ALL_REVIEW_PAGE_ACTION: {
      return {
        ...state,
        allReviewPageState: payload,
      };
    }

    case SAVE_OTHER_USERNAME_ACTION: {
      return {
        ...state,
        saveOtherUsernameState: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default profileReducer;
