import {
  GET_MARKET_PLACE_BUY_SELL,
  GET_MARKET_PLACE_DEFAULT_PAGE_STATE,
  GET_MARKET_PLACE_INITIAL,
  GET_MARKET_PLACE_INITIAL_STATE,
  GET_MARKET_PLACE_ON_NEXT,
  GET_MARKET_PLACE_ON_PREV,
  MARKET_LIMIT_SYSTEM,
  MARKET_SKIP_SYSTEM,
  CURRENT_TOTAL_VALUE,
} from "../types";

const initialState = {
  response: null,
  loading: false,
  error: null,
  skip: 0,
  fskip: 0,
  limit: 20,
  currentTotalValue: null,
};

const marketReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_MARKET_PLACE_INITIAL: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_MARKET_PLACE_INITIAL_STATE: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_MARKET_PLACE_BUY_SELL: {
      return {
        ...state,
        response: payload,
      };
    }

    case MARKET_SKIP_SYSTEM: {
      return {
        ...state,
        skip: payload,
      };
    }

    case MARKET_LIMIT_SYSTEM: {
      return {
        ...state,
        limit: payload,
      };
    }

    case GET_MARKET_PLACE_ON_NEXT: {
      return {
        ...state,
        skip: state.skip + 1,
        fskip: state.limit * (state.skip + 1),
        limit: state.limit,
      };
    }
    case GET_MARKET_PLACE_ON_PREV: {
      return {
        ...state,
        skip: state.skip - 1,
        fskip: state.limit * (state.skip - 1),
        limit: state.limit,
      };
    }
    case GET_MARKET_PLACE_DEFAULT_PAGE_STATE: {
      return {
        ...state,
        skip: 0,
        fskip: 0,
      };
    }

    case CURRENT_TOTAL_VALUE: {
      return {
        ...state,
        currentTotalValue: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default marketReducer;
