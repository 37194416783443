import {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {checkTradeReviewAction, rateTradeAction} from "../../../store/actions/createTradeAction";
import {TRADE_REVIEW_DEFAULT} from "../../../store/types";
import "../../../styles/createTrade/reviewComment.scss";
import {Loader4} from "../../Common/Loader";

const ReviewComments = ({review, setReview}) => {
  const dispatch = useDispatch();
  const textareaRef = useRef();
  const {response, loading} = useSelector((state) => state.rateTradeReducer);
  const {id} = useParams();
  const [reviewText, setReviewText] = useState();

  const handleReview = () => {
    dispatch(rateTradeAction({trade_id: id, isTrust: review === "pos" ? true : false, text: reviewText}));
  };

  useEffect(() => {
    if (response?.message === "success") {
      dispatch({type: TRADE_REVIEW_DEFAULT});
      dispatch(checkTradeReviewAction(id));
    }
  }, [response]);

  useEffect(() => {
    setReviewText("");
  }, [review]);

  return (
    review &&
    response?.message !== "success" && (
      <div className="__review__comment__wrapper">
        <div className="__reviewCommentWrapper">
          <div className={`__reviewComment__inputWrapper`}>
            <textarea
              rows={6}
              ref={textareaRef}
              className="__reviewComment__input"
              value={reviewText || ""}
              onChange={(e) => setReviewText(e.target.value)}
              placeholder={
                review === "pos"
                  ? "Let others know about your positive experience"
                  : "Let others know about your Negative experience"
              }
            />
          </div>
        </div>
        <div className="__cmt__wrapper">
          <button
            className="__comment__button __small__button"
            disabled={reviewText === null || reviewText === undefined || reviewText === "" ? true : false}
            onClick={handleReview}
          >
            {loading ? <Loader4 /> : "Post a review"}
          </button>
        </div>
      </div>
    )
  );
};

export default ReviewComments;
