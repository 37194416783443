import React, {memo} from "react";
import RegisterForm from "../components/Register/RegisterForm";
import RegisterOTP from "../components/Register/RegisterOTP";
import {Loader} from "../components/Common/Loader";
import {useSelector} from "react-redux";
import NavigationBar from "../components/LandingComponents/nav-bar/nav-bar.component";

const Login = () => {
  const showOTP = useSelector((state) => state.authReducer.registerLoadingState);

  return (
    <React.Fragment>
      {showOTP === 0 && (
        <>
          <NavigationBar />
          <RegisterForm />
        </>
      )}

      {showOTP === 1 && (
        <>
          <NavigationBar />
          <Loader />
        </>
      )}

      {showOTP === 2 && <RegisterOTP />}
    </React.Fragment>
  );
};

export default memo(Login);
