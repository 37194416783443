import {useState} from "react";
import {BsPlus} from "react-icons/bs";
import {HiOutlineMinusSm} from "react-icons/hi";
import {Link} from "react-router-dom";
import "../../../../styles/landing-page-styles/support.scss";

const SupportFaqs = ({data}) => {
  const [toggleShow, setToggleShow] = useState();
  return (
    <>
      {data &&
        data.length > 0 &&
        data.map((item) => {
          return (
            <div className="__theFaqs">
              <div className="__faqTops">
                <p>{item.title}</p>
                <div
                  className="__toggle"
                  onClick={() =>
                    setToggleShow((prev) => {
                      if (prev === item.id) {
                        return null;
                      } else {
                        return item.id;
                      }
                    })
                  }
                >
                  {toggleShow !== item.id ? <BsPlus size={16} /> : <HiOutlineMinusSm size={16} />}
                </div>
              </div>

              <div className={`__content ${toggleShow === item.id ? "__open" : "__close"}`}>
                {item.description &&
                  item.description.map((inneer, key) => {
                    return (
                      <div>
                        {inneer?.details === "text" && (
                          <p className="__cc" key={key}>
                            {inneer?.text}
                            {item.title === "How much is the fee?" && (
                              <span>
                                <Link
                                  style={{marginLeft: "5px", textDecoration: "underline", color: "darkblue"}}
                                  to={"/oyolafees"}
                                >
                                  click more
                                </Link>{" "}
                                for more information
                              </span>
                            )}
                          </p>
                        )}

                        {inneer?.details === "array" &&
                          inneer?.questionArray?.map((dev, i) => (
                            <p className="__cc" key={key}>
                              {dev?.text}
                              {item.title === "Oyola Fees" && (
                                <span>
                                  <Link style={{textDecoration: "underline", color: "darkblue"}} to={"/oyolafees"}>
                                    click more
                                  </Link>{" "}
                                  for more information
                                </span>
                              )}
                            </p>
                          ))}
                        {inneer?.details === "subSection" && (
                          <p className="__cc" key={key}>
                            {inneer?.text}
                            {item.title === "Oyola Fees" && (
                              <span>
                                <Link style={{textDecoration: "underline", color: "darkblue"}} to={"/oyolafees"}>
                                  click more
                                </Link>{" "}
                                for more information
                              </span>
                            )}
                          </p>
                        )}
                      </div>
                    );
                  })}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SupportFaqs;
