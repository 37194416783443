import {useState} from "react";
import {RiSendPlaneFill} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {toast} from "react-toastify";
import {useGetUserType} from "../../../hooks/useGetUserType";
import ChatService from "../../../services/chat";
import {getChatMessagesNoLoaderAction} from "../../../store/actions/chatMessage";
import {Loader5, Loader6} from "../../Common/Loader";
import SendUploadMessageImage from "./uploadImage";

const SendMessageArea = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const [text, setText] = useState("");
  const [uploadLoading, setUploadLoading] = useState(false);
  // const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(response ?? {});
  const [loader, setLoader] = useState(false);

  const profileData = () => {
    if (userType === "user") {
      return response?.profileData?.userData;
    } else if (userType === "trader") {
      return response?.profileData?.traderData;
    }
  };

  const [formData, setFormData] = useState();
  // const [fileName, setFileName] = useState();
  const handleUpload = (formData) => {
    setUploadLoading(true);
    ChatService.postImageMessages(formData)
      .then((res) => {
        setUploadLoading(false);
        setFormData(null);
        return res;
      })
      .then((res) => {
        if (res?.message === "success") {
          dispatch(getChatMessagesNoLoaderAction(id));
        }
      })
      .catch(() => {
        // toast("Something went wrong");
        setFormData(null);
        setUploadLoading(false);
      });
  };

  const handleMessageSend = () => {
    setLoader(true);
    if (formData) {
      handleUpload(formData);
      setText("");
      setLoader(false);
    } else if (text.length > 0) {
      ChatService.postMessages({
        text,
        chatID: response?.chatID,
        senderID: userType === "user" ? response?.userID : userType === "trader" && response?.traderID,
        sentBy: userType,
        username: userType === "user" ? profileData()?.username : userType === "trader" && profileData()?.username,
        isImage: false,
      })
        .then((res) => {
          if (res?.message === "success") {
            dispatch(getChatMessagesNoLoaderAction(id));
            setText("");
            setLoader(false);
          }
        })
        .catch((err) => {
          setLoader(false);
          toast(err.message);
        });
    }
  };

  return (
    <div className="__send__input__wrapper">
      <div className="__sendInput__wrapper">
        <div className="__sendInput__inwrapper">
          <input
            type={"text"}
            value={text}
            //disabled={statusResponse?.status === "completed" || statusResponse?.status === "cancelled"}
            onChange={(e) => {
              let {value} = e.target;

              let str = value
                .split(" ")
                .filter(
                  (el) =>
                    !el.startsWith("https://") &&
                    !el.startsWith("http://") &&
                    !el.startsWith("www.") &&
                    !el.endsWith(".com") &&
                    !el.endsWith("</script>") &&
                    !el.startsWith("<script>")
                );

              value = str.join(" ");

              setText(value);
            }}
            className="__send__input"
            placeholder="Type message...."
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                handleMessageSend();
              }
            }}
          />
          <SendUploadMessageImage
            setFileName={setText}
            setFormData={setFormData}
            loading={uploadLoading}
            setLoading={setUploadLoading}
          />
        </div>
        {loader ? (
          <Loader6 />
        ) : (
          <button
            className="__sendmessage__button"
            onClick={handleMessageSend}
            //disabled={statusResponse?.status === "completed" || statusResponse?.status === "cancelled"}
          >
            <RiSendPlaneFill size={20} color="#fff" />
          </button>
        )}
      </div>
    </div>
  );
};

export default SendMessageArea;
