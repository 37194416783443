import React, {memo} from "react";
import MainComponent from "./mainComponent";

const NotFound = () => {
  return (
    <div>
      <MainComponent />
    </div>
  );
};

export default memo(NotFound);
