import moment from "moment";

export const getTimeDifference = (time, tye) => {
  if (tye) {
    // const gg = moment(time).format("L");

    // let unixtime = today.diff(moment(time, "DD-MM-YYYY HH:mm"), "minutes");
    // return Math.floor(unixtime / 60);

    // let hh = moment(moment(time, "DD MM YYYY").diff(moment(moment(), "DD MM YYYY"))).format("mm");
    // var ms = moment(time,).diff(moment(then,"DD/MM/YYYY HH:mm:ss"));
    // var d = moment.duration(ms);
    // var s = d.format("hh:mm:ss");
    // let dd = moment.duration(gg.diff(moment())).asMinutes() % 60;

    const a = moment(time);
    const b = moment();
    const minutes = a.diff(b, "minutes");

    return -minutes;
  } else {
    // const gg = moment(time);

    // let hh = moment(moment().diff(moment(time, "HH:mm:ss"))).format("mm")
    // return hh;
    const a = moment(time);
    const b = moment();
    const minutes = a.diff(b, "minutes");

    return -minutes;
  }
};
