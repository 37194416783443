import React, {memo} from "react";
import SelectCountryDetails from "./SelectCountryDetails";
import TimeDresser from "./TimeDresser";

import "../../styles/postTradeStyles/postTradeSecondSection.scss";

const PostTradeSecondSection = () => {
  return (
    <div className="postTrade__secondLayout">
      <div className="postTrade__secondLayoutHeader">
        <h4>Location</h4>
      </div>
      <div className="postTrade__secondLayoutSearchCountry">
        <div className="postTrade__secondLayoutSearchCountryBody">
          <SelectCountryDetails />
        </div>
      </div>
      <div className="postTrade__secondLayoutTimer">
        {/* <div className="postTrade__secondLayoutTimerFree"></div> */}
        <div className="postTrade__secondLayoutTimerBodyMain">
          <div className="postTrade__secondLayoutTimerBody">
            <p>Time limit</p>
          </div>
          <div className="postTrade__secondLayoutTimerDropDown">
            <TimeDresser />
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PostTradeSecondSection);
