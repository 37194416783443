import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {BTC, NGN} from "../../../../hooks/imagePath";
import "../../../../styles/table.scss";
import {CoinName} from "../../../Common/coinNameSwitcher";
import {CoinIcons} from "../../../Common/coinSwitcher";
import {CountryIcons} from "../../../Common/CountryIcons";
import {DOMTablePaginationComponent} from "../../../Common/DOM_Pagination";
import {Loader} from "../../../Common/Loader";
import {TablePaginationComponent} from "../../../Common/pagination";
import NotFound from "../../../../assets/images/notfound.svg";
import {DOMTablePaginationComponent2} from "../../../Common/DOM_Pagination/index2";
import {CHANGE_PAGINATION} from "../../../../store/types";
import {useEffect} from "react";

const AllTradeMobileTable = ({data, loading}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {pageSize, currentPage} = useSelector((state) => state.domPaginationReducer);

  useEffect(() => {
    dispatch({type: CHANGE_PAGINATION, payload: 1});

    return () => {
      dispatch({type: CHANGE_PAGINATION, payload: 1});
    };
  }, [data]);

  //Get current page
  const indexOfLastPost = currentPage * pageSize;
  const indexOfFirstPost = indexOfLastPost - pageSize;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const textSlicer = (data) => {
    const newText = data ? `${data.slice(0, 10)}...` : "";
    return newText;
  };

  return (
    <>
      <div className="__alltradeorder__mobile__table">
        {loading ? (
          <Loader />
        ) : (
          data &&
          data?.length > 0 &&
          currentPosts?.map((item) => (
            <div key={item._id} className="__alltradeorder__content">
              <div className="__alltradeorder__top">
                <div className="__thecoinWrapper">
                  <div className="__thecoinImage">
                    <CoinIcons intialValue={item.tokenSymbol} />
                  </div>
                  <div className="__coinNames">
                    <p>{item.tokenSymbol}</p>
                    <span>
                      <CoinName intialValue={item.tokenSymbol} />
                    </span>
                  </div>
                </div>

                <div
                  className="__allTradeStatus __active"
                  style={{color: `${item.status === "inactive" && "#b42318"}`}}
                >
                  {item.status}
                </div>
              </div>

              <div className="__tradeOrder__details">
                <div className="__unitTitle">
                  <span className="__unitText">Trade ID</span>
                  <span className="__unitValue">{item._id}</span>
                </div>
                <div className="__unitTitle">
                  <span className="__unitText">Unit</span>
                  <span className="__unitValue">30,021.23</span>
                </div>
                <div className="__unitTitle">
                  <span className="__unitText">Limit</span>
                  <p className="__unitValue">
                    <span>
                      <CurrencyFormat
                        value={item.minAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${item.currency}`}
                      />
                    </span>{" "}
                    {" - "}{" "}
                    <span>
                      <CurrencyFormat
                        value={item.maxAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${item.currency}`}
                      />
                    </span>
                  </p>
                </div>
              </div>

              <div className="__alltradeOrder__bottom">
                <div className="__tradePayment__country">
                  <div className="__thePayment__method">{item.paymentMethod}</div>
                  <div className="__theLoaction">
                    <div className="__location__icon">
                      <CountryIcons intialValue={item.country} />
                    </div>
                    <span className="__location__name">{item.country}</span>
                  </div>
                </div>

                <span
                  className="__theAction"
                  onClick={() => history.push(`/app/edit-trade/${item._id}`)}
                  style={{color: "#fff"}}
                >
                  Edit
                </span>
              </div>
            </div>
          ))
        )}

        {(data?.length === 0 || data === undefined) && loading === false && (
          <div
            className="not_found_item"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "60vh",
            }}
          >
            <img src={NotFound} alt="not found" />
          </div>
        )}

        {data?.length !== 0 && (
          <div>
            <DOMTablePaginationComponent2 datalength={data?.length} />
          </div>
        )}
      </div>
    </>
  );
};

export default AllTradeMobileTable;
