import {forwardRef, useState} from "react";
import {BiFilter} from "react-icons/bi";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export const MultipleFilterDropdownBuySell = forwardRef(({onApply}, ref) => {
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [showDropdown, setShowDropDown] = useState(false);
  const [show, setShow] = useState(true);
  const [checker, setChecker] = useState([]);

  // const handClickSystem = () => {
  //   if (checker.length > 0) {
  //     checker.forEach((item) => {});
  //   }
  // };

  const toggleSubMenu = (e) => {
    if (show) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
    setShow(!show);
  };

  useOnClickOutside(ref, () => setShowDropDown(false));

  return (
    <>
      <div className="staticDropDown__wrapper" onClick={toggleSubMenu}>
        <span style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
          <BiFilter size={18} className="mr-1" /> {"filter"}
        </span>
      </div>
      {showDropdown && (
        <div
          className="staticDropDown__content__wrapper filterWrapperStyle"
          ref={ref}
          data-aos="zoom-in"
          data-aos-duration="300"
        >
          <div className="staticDropDown__select__text">Select Filter to Search</div>
          <div className="">
            <div
              onClick={() => {
                if (type.includes("buy")) {
                  let arr = [...type];
                  arr.splice(
                    arr.findIndex((i) => i === "buy"),
                    1
                  );
                  setType([...arr]);
                } else {
                  setType((prev) => [...prev, "buy"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={type.includes("buy")} />
              Buy
            </div>
            <div
              onClick={() => {
                if (type.includes("sell")) {
                  let arr = [...type];
                  arr.splice(
                    arr.findIndex((i) => i === "sell"),
                    1
                  );
                  setType([...arr]);
                } else {
                  setType((prev) => [...prev, "sell"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={type.includes("sell")} />
              Sell
            </div>
            <div
              onClick={() => {
                if (status.includes("pending")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "pending"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "pending"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("pending")} />
              Pending
            </div>
            <div
              onClick={() => {
                if (status.includes("completed")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "completed"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "completed"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("completed")} />
              Completed
            </div>
            <div
              onClick={() => {
                if (status.includes("cancelled")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "cancelled"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "cancelled"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("cancelled")} />
              Cancelled
            </div>
          </div>
          <div className="__multiple__button__wrapper">
            <div className="filterStaticDropDown__cancel__button" onClick={() => setShowDropDown(false)}>
              Cancel
            </div>
            <button
              disabled={status.length === 0 && type.length === 0}
              className="filterStaticDropDown__apply__button"
              onClick={() => onApply({type, status})}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
});
export const MultipleFilterDropdownDeposit = forwardRef(({onApply}, ref) => {
  const [type, setType] = useState([]);
  const [status, setStatus] = useState([]);
  const [showDropdown, setShowDropDown] = useState(false);
  const [show, setShow] = useState(true);
  const toggleSubMenu = (e) => {
    if (show) {
      setShowDropDown(true);
    } else {
      setShowDropDown(false);
    }
    setShow(!show);
  };

  useOnClickOutside(ref, () => setShowDropDown(false));

  return (
    <>
      <div className="staticDropDown__wrapper" onClick={toggleSubMenu}>
        <span style={{marginLeft: "10px", display: "flex", alignItems: "center"}}>
          <BiFilter size={18} className="mr-1" /> {"filter"}
        </span>
      </div>
      {showDropdown && (
        <div
          className="staticDropDown__content__wrapper filterWrapperStyle"
          ref={ref}
          data-aos="zoom-in"
          data-aos-duration="300"
        >
          <div className="staticDropDown__select__text">Select Filter to Search</div>
          <div className="">
            <div
              onClick={() => {
                if (type.includes("deposit")) {
                  let arr = [...type];
                  arr.splice(
                    arr.findIndex((i) => i === "deposit"),
                    1
                  );
                  setType([...arr]);
                } else {
                  setType((prev) => [...prev, "deposit"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={type.includes("deposit")} />
              Deposit
            </div>
            <div
              onClick={() => {
                if (type.includes("withdraw")) {
                  let arr = [...type];
                  arr.splice(
                    arr.findIndex((i) => i === "withdraw"),
                    1
                  );
                  setType([...arr]);
                } else {
                  setType((prev) => [...prev, "withdraw"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={type.includes("withdraw")} />
              Withdraw
            </div>
            <div
              onClick={() => {
                if (status.includes("pending")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "pending"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "pending"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("pending")} />
              Pending
            </div>
            <div
              onClick={() => {
                if (status.includes("completed")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "completed"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "completed"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("completed")} />
              Completed
            </div>
            <div
              onClick={() => {
                if (status.includes("cancelled")) {
                  let arr = [...status];
                  arr.splice(
                    arr.findIndex((i) => i === "cancelled"),
                    1
                  );
                  setStatus([...arr]);
                } else {
                  setStatus((prev) => [...prev, "cancelled"]);
                }
              }}
              className="staticDropDown__contentOptions staticDropDown__contentOptionsCheckbox"
            >
              <input type={"checkbox"} checked={status.includes("cancelled")} />
              Cancelled
            </div>
          </div>
          <div className="__multiple__button__wrapper">
            <div className="filterStaticDropDown__cancel__button" onClick={() => setShowDropDown(false)}>
              Cancel
            </div>
            <button
              disabled={status.length === 0 && type.length === 0}
              className="filterStaticDropDown__apply__button"
              onClick={() => onApply({type, status})}
            >
              Apply
            </button>
          </div>
        </div>
      )}
    </>
  );
});
