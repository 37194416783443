import {Link} from "react-router-dom";
import {useRef, useState} from "react";

import TradeDropdownMenu from "../trade-drop-down/trade-drop-down.component";
import ReactLinkButton from "../ReactLinkButton";
import LearnDropDownMenu from "../learn-drop-down/learn-drop-down.component";
import MobileSideMenu from "../mobile-side-menu/mobile-side-menu.component";

import useOnClickOutside from "../../../hooks/useOnClickOutside";

import {HiMenuAlt1} from "react-icons/hi";
import {MdKeyboardArrowDown} from "react-icons/md";
import mobileLogo from "../../../assets/images/content.png";
import desktopLogo from "../../../assets/images/newlogo.png";
import "./navbar.scss";
import LinkButton from "../linkButton";

function NavigationBar() {
  const ref = useRef();

  const token = localStorage.getItem("token");

  useOnClickOutside(ref, () => setShowTrade(false));
  useOnClickOutside(ref, () => setShowLearn(false));
  useOnClickOutside(ref, () => setShowMobileSideMenu(false));

  const [showTrade, setShowTrade] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);

  return (
    <nav className="navContainer">
      <main className="innerContainer">
        <ul className="innerContainerLeft">
          <Link to="/" className="logoLink">
            <img src={desktopLogo} alt="desktop logo" width={100} />
          </Link>
          <Link to="/" className="mobileNav">
            <img src={mobileLogo} alt="mobile logo" width={35} />
          </Link>

          <button className="mobileMenu" onClick={() => setShowMobileSideMenu(!showMobileSideMenu)}>
            <HiMenuAlt1 className="handburger" />
          </button>
          {showMobileSideMenu && <MobileSideMenu setHide={setShowMobileSideMenu} ref={ref} />}
        </ul>

        <ul className="innerContainerRight">
          <div className="navListMenuRight">
            <Link to="/features" className="link">
              Features
            </Link>

            <button className="linkIcon" onClick={() => setShowTrade(!showTrade)}>
              <p>Trade</p>
              <MdKeyboardArrowDown />
            </button>

            <button className="linkIcon" onClick={() => setShowLearn(!showLearn)}>
              <p>Learn</p>
              <MdKeyboardArrowDown />
            </button>

            {showTrade && <TradeDropdownMenu ref={ref} setShowTrade={setShowTrade} />}
            {showLearn && <LearnDropDownMenu ref={ref} setShowLearn={setShowLearn} />}

            <Link to="/about-us" className="link">
              About
            </Link>
            <Link to="/careers" className="link">
              Careers
            </Link>
          </div>
          {token ? (
            <div className="navListLinkRight" style={{marginLeft: "30px"}}>
              <LinkButton
                title="Dashboard"
                link={"/app/overview"}
                style={{width: "124px", height: "auto", padding: "8px 20px", fontSize: "1.4rem"}}
              />
            </div>
          ) : (
            <div className="navListLinkRight">
              <ReactLinkButton
                title="Sign In"
                link={"/login"}
                style={{
                  width: "124px",
                  height: "auto",
                  padding: "8px 0px 10px 50px",
                  background: "transparent",
                  color: "#1E2329",
                  fontSize: "1.4rem",
                }}
              />
              {/* <ReactLinkButton
                title="Sign up"
                link={"/signup"}
                style={{width: "124px", height: "auto", padding: "8px 20px", fontSize: "1.4rem"}}
              /> */}
              <ReactLinkButton
                title="Download App"
                link={"/download"}
                style={{width: "144px", height: "auto", padding: "8px 20px", fontSize: "1.4rem"}}
              />
            </div>
          )}
        </ul>
      </main>
    </nav>
  );
}

export default NavigationBar;
