import {
  GET_TRADE_BY_ID_ERROR,
  GET_TRADE_BY_ID_SUCCESS,
  GET_TRADE_INITIAL,
  GET_TRADE_STATUS_ERROR,
  GET_TRADE_STATUS_INITIAL,
  GET_TRADE_STATUS_SUCCESS,
} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const getTradeStatusbyIdReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_TRADE_STATUS_SUCCESS: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case GET_TRADE_STATUS_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_TRADE_STATUS_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    // case GET_TRADE_STATUS_INITIAL_: {
    //   return {
    //     ...state,
    //     loading: true,
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default getTradeStatusbyIdReducer;
