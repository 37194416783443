export const LOGIN = "LOGIN";
export const LOGIN_ERROR_STATE = "LOGIN_ERROR_STATE";
export const LOG_OUTSTATE = "LOG_OUTSTATE";
export const REGISTER = "REGISTER";
export const LOGOUT = "LOGOUT";
export const LOADING_STATE = "LOADING_STATE";
export const REGISTER_LOADING_STATE = "REGISTER_LOADING_STATE";
export const FORGOT_PASSWORD_LOADING_STATE = "FORGOT_PASSWORD_LOADING_STATE";
export const LOGIN_ERRORS = "LOGIN_ERRORS";
export const ACTIVE_INPUT = "ACTIVE_INPUT";
export const LOGIN_DETAILS = "LOGIN_DETAILS";
export const LOGIN_DETAILS_SECOND = "LOGIN_DETAILS_SECOND";
export const GET_BUY_SELL_TRANSACTION_SUCCESS = "GET_BUY_SELL_TRANSACTION_SUCCESS";
export const GET_BUY_SELL_TRANSACTION_ERROR = "GET_BUY_SELL_TRANSACTION_ERROR";
export const GET_BUY_SELL_TRANSACTION_INITIAL = "GET_BUY_SELL_TRANSACTION_INITIAL";
export const GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS = "GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS";
export const GET_DEPOSIT_WITHDRAW_TRANSACTION_ERROR = "GET_DEPOSIT_WITHDRAW_TRANSACTION_ERROR";
export const GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL = "GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL";
export const GET_BUY_SELL_NEXT = "GET_BUY_SELL_NEXT";
export const GET_BUY_SELL_PREV = "GET_BUY_SELL_PREV";
export const GET_BUY_SELL_REFRESH = "GET_BUY_SELL_REFRESH";
export const FILTER_BUY_SELL_DEFAULT = "FILTER_BUY_SELL_DEFAULT";
export const GET_DEPOSIT_WITHDRAW_REFRESH = "GET_DEPOSIT_WITHDRAW_REFRESH";
export const FILTER_BUY_SELL_BY_COIN = "FILTER_BUY_SELL_BY_COIN";
export const FILTER_BUY_SELL_BY_TYPE = "FILTER_BUY_SELL_BY_TYPE";
export const FILTER_BUY_SELL_BY_STATUS = "FILTER_BUY_SELL_BY_STATUS";
export const FILTER_BUY_SELL_BY_DATE = "FILTER_BUY_SELL_BY_DATE";
export const FILTER_BUY_SELL_BY_MULTIPLE = "FILTER_BUY_SELL_BY_MULTIPLE";
export const GET_DEPOSIT_NEXT = "GET_DEPOSIT_NEXT";
export const GET_DEPOSIT_PREV = "GET_DEPOSIT_PREV";
export const FILTER_DEPOSIT_WITHDRAW_BY_COIN = "FILTER_DEPOSIT_WITHDRAW_BY_COIN";
export const FILTER_DEPOSIT_WITHDRAW_BY_TYPE = "FILTER_DEPOSIT_WITHDRAW_BY_TYPE";
export const FILTER_DEPOSIT_WITHDRAW_BY_STATUS = "FILTER_DEPOSIT_WITHDRAW_BY_STATUS";
export const FILTER_DEPOSIT_WITHDRAW_BY_DATE = "FILTER_DEPOSIT_WITHDRAW_BY_DATE";
export const FILTER_DEPOSIT_WITHDRAW_MULTIPLE = "FILTER_DEPOSIT_WITHDRAW_MULTIPLE";
export const GET_MARKET_PLACE_BUY_SELL = "GET_MARKET_PLACE_BUY_SELL";
export const GET_MARKET_PLACE_INITIAL = "GET_MARKET_PLACE_INITIAL";
export const GET_MARKET_PLACE_ON_NEXT = "GET_MARKET_PLACE_ON_NEXT";
export const GET_MARKET_PLACE_ON_PREV = "GET_MARKET_PLACE_ON_PREV";
export const CHANGE_MARKET_PLACE_STATE = "CHANGE_MARKET_PLACE_STATE";
export const CHANGE_MARKET_PLACE_DEFAULT = "CHANGE_MARKET_PLACE_DEFAULT";
export const GET_MARKET_PLACE_DEFAULT_PAGE_STATE = "GET_MARKET_PLACE_DEFAULT_PAGE_STATE";
export const CHECK_OUT_DATA_INITIAL = "CHECK_OUT_DATA_INITIAL";
export const GET_CHECK_OUT_DATA = "GET_CHECK_OUT_DATA";
export const ON_CHANGE_CURRENCY = "ON_CHANGE_CURRENCY";
export const ON_CHANGE_TOKEN = "ON_CHANGE_TOKEN";
export const ON_CHANGE_TOKEN_MAX = "ON_CHANGE_TOKEN_MAX";
export const SET_UNIT_PRICE = "SET_UNIT_PRICE";
export const CHANGE_CREATE_TRADE_INITIAL = "CHANGE_CREATE_TRADE_INITIAL";
export const CREATE_TRADE_SUCCESS = "CREATE_TRADE_SUCCESS";
export const GET_TRADE_BY_ID_SUCCESS = "GET_TRADE_BY_ID_SUCCESS";
export const GET_TRADE_BY_ID_ERROR = "GET_TRADE_BY_ID_ERROR";
export const GET_TRADE_INITIAL = "GET_TRADE_INITIAL";
export const CREATE_TRADE_ERROR = "CREATE_TRADE_ERROR";
export const DEFAULT_CHECKOUT_STATE = "DEFAULT_CHECKOUT_STATE";
export const ALLOWED_ACCESS = "ALLOWED_ACCESS";
export const USER_DATA = "USER_DATA";
export const GET_USER_DATA_INITIAL = "GET_USER_DATA_INITIAL";
export const LOCATION_DATA = "LOCATION_DATA";
export const PAYMENT_METHOD = "PAYMENT_METHOD";
export const COUNTRY_DATA = "COUNTRY_DATA";
export const ALL_TRADE_ORDER = "ALL_TRADE_ORDER";
export const LOCAL_TOKEN_BALANCE = "LOCAL_TOKEN_BALANCE";
export const SIGNLE_LOCAL_TOKEN_BALANCE = "SINGLE_LOCAL_TOKEN_BALANCE";
export const TOKEN_DETAILS = "TOKEN_DETAILS";
export const TOKENLOCALCURRENCY = "TOKENLOCALCURRENCY";
export const COINDATA = "COINDATA";
export const BUYDATA = "BUYDATA";
export const SELLDATA = "SELLDATA";
export const COUNTRYDATA = "COUNTRYDATA";
export const TIMEDATA = "TIMEDATA";
export const PAYMENTDATA = "PAYMENTDATA";
export const TOKENMARGIN = "TOKENMARGIN";
export const SKELETON_LOADER = "SKELETON_LOADER";
export const IS_LOADING = "IS_LOADING";
export const USER_ORDER = "USER_ORDER";
export const TOKENSINGLELOCALCURRENCY = "TOKENSINGLELOCALCURRENCY";
export const PROFITMARGIN = "PROFITMARGIN";
export const ORDERSTATUS = "ORDERSTATUS";
export const ALL_NOTIFICATIONS = "ALL_NOTIFICATIONS";
export const GET_TRADE_STATUS_INITIAL = "GET_TRADE_STATUS_INITIAL";
export const GET_TRADE_STATUS_ERROR = "GET_TRADE_STATUS_ERROR";
export const GET_TRADE_STATUS_SUCCESS = "GET_TRADE_STATUS_SUCCESS";
export const CREATE_TRADE_DEFAULT = "CREATE_TRADE_DEFAULT";
export const MODALLOADER = "MODALLOADER";
export const WALLETBALANCE = "WALLETBALANCE";
export const WALLETNETWORKDATA = "WALLETNETWORKDATA";
export const WALLETCOINDETAILS = "WALLETCOINDETAILS";
export const TOKENNETWORKFEE = "TOKENNETWORKFEE";
export const MODALCHECKER = "MODALCHECKER";
export const WITHDRAWALDETAILS = "WITHDRAWALDETAILS";
export const WALLETOTPERROR = "WALLETOTPERROR";
export const DETAILSOFWITHDRAWAL = "DETAILSOFWITHDRAWAL";
export const USER_COIN_LOCAL_VALUE = "USER_COIN_LOCAL_VALUE";
export const ALL_TRADE_ORDERS = "ALL_TRADE_ORDERS";
export const ALLL_TRADE_ORDERS = "ALLL_TRADE_ORDERS";
export const ALL_TRADE_ORDERS_INITIAL = "ALL_TRADE_ORDERS_INITIAL";
export const FILTERED_TRADE_ORDERS = "FILTERED_TRADE_ORDERS";
export const PAGINATION_INDEX = "PAGINATION_INDEX";
export const PAGINATION_TOTAL = "PAGINATION_TOTAL";
export const IS_LOADING_TRADE = "IS_LOADING_TRADE";
export const PHONE_SEARCHABLE_DETAILS = "PHONE_SEARCHABLE_DETAILS";
export const UPDATED_PHONE_NUMBER = "UPDATED_PHONE_NUMBER";
export const DEFAULT_CURRENCY_COUNTRY = "DEFAULT_CURRENCY_COUNTRY";
export const NOTIFICATION_PAGINATION = "NOTIFICATION_PAGINATION";
export const NOTIFICATION_PAGINATION_DEFAULT = "NOTIFICATION_PAGINATION_DEFAULT";
export const NOTIFICATION_PAGINATION_PREV = "NOTIFICATION_PAGINATION_PREV";
export const NOTIFICATION_PAGINATION_NEXT = "NOTIFICATION_PAGINATION_NEXT";
export const PROFILE_DATA = "PROFILE_DATA";
export const GET_MESSAGES = "GET_MESSAGES";
export const GET_MESSAGES_INITIAL = "GET_MESSAGES_INITIAL";
export const GET_MESSAGES_WITHOUT_LOADING = "GET_MESSAGES_WITHOUT_LOADING";
export const EDITTRADEDISABLE = "EDITTRADEDISABLE";
export const REGISTER_ERROR_DETAILS_FOR_EMAIL = "REGISTER_ERROR_DETAILS_FOR_EMAIL";
export const REGISTER_ERROR_DETAILS_FOR_USERNAME = "REGISTER_ERROR_DETAILS_FOR_USERNAME";
export const REGISTER_ERROR_FOR_USERNAME = "REGISTER_ERROR_FOR_USERNAME";
export const WITHDRAWALERROR = "WITHDRAWALERROR";
export const COIN_NETWORK_SELECTION = "COIN_NETWORK_SELECTION";
export const CLEAR_USER = "CLEAR_USER";
export const GET_CHECK_OUT_ERROR = "GET_CHECK_OUT_ERROR";
export const WALLETADDRESSCHECKER = "WALLETADDRESSCHECKER";
export const WALLETADDRESSERROR = "WALLETADDRESSERROR";
export const TRANSACTIONHISTORY = "TRANSACTIONHISTORY";
export const WALLETLOADER = "WALLETLOADER";
export const CONFIRM_PAYMENT = "CONFIRM_PAYMENT";
export const CONFIRM_PAYMENT_INITIAL = "CONFIRM_PAYMENT_INITIAL";
export const CONFIRM_PAYMENT_ERROR = "CONFIRM_PAYMENT_ERROR";
export const CONFIRM_PAYMENT_DEFAULT = "CONFIRM_PAYMENT_DEFAULT";
export const CANCEL_TRADE = "CANCEL_TRADE";
export const CANCEL_TRADE_INITIAL = "CANCEL_TRADE_INITIAL";
export const CANCEL_TRADE_ERROR = "CANCEL_TRADE_ERROR";
export const CANCEL_TRADE_DEFAULT = "CANCEL_TRADE_DEFAULT";
export const RELEASE_TOKEN = "RELEASE_TOKEN";
export const RELEASE_TOKEN_INITIAL = "RELEASE_TOKEN_INITIAL";
export const RELEASE_TOKEN_ERROR = "RELEASE_TOKEN_ERROR";
export const RELEASE_TOKEN_DEFAULT = "RELEASE_TOKEN_DEFAULT";
export const DISPUTE_TRADE = "DISPUTE_TRADE";
export const DISPUTE_TRADE_INITIAL = "DISPUTE_TRADE_INITIAL";
export const DISPUTE_TRADE_ERROR = "DISPUTE_TRADE_ERROR";
export const RATE_TRADE = "RATE_TRADE";
export const RATE_TRADE_INITIAL = "RATE_TRADE_INITIAL";
export const RATE_TRADE_ERROR = "RATE_TRADE_ERROR";
export const RATE_TRADE_DEFAULT = "RATE_TRADE_DEFAULT";
export const SETTINGS_VERIFY_MODAL = "SETTINGS_VERIFY_MODAL";
export const SETTINGS_MODAL_INFORMATION_DETAILS = "SETTINGS_MODAL_INFORMATION_DETAILS";
export const SETTINGS_MODAL_MORE_INFORMATION_DETAILS = "SETTINGS_MODAL_MORE_INFORMATION_DETAILS";
export const VALID_ADDRESS_FIRST_NAME = "VALID_ADDRESS_FIRST_NAME";
export const VALID_ADDRESS_LAST_NAME = "VALID_ADDRESS_LAST_NAME";
export const VALID_ADDRESS_MIDDLE_NAME = " VALID_ADDRESS_MIDDLE_NAME,";
export const VALID_ADDRESS_RESIDENCE = "VALID_ADDRESS_RESIDENCE";
export const VALID_ADDRESS_CITY = "VALID_ADDRESS_CITY";
export const VALID_ADDRESS_STATE = " VALID_ADDRESS_STATE";
export const VALID_ADDRESS_COUNTRY = " VALID_ADDRESS_COUNTRY";
export const GET_USER_TRADES_SUCCESS = " GET_USER_TRADES_SUCCESS";
export const GET_USER_TRADES_ERROR = " GET_USER_TRADES_ERROR";
export const GET_USER_TRADES_INITIAL = " GET_USER_TRADES_INITIAL";
export const FILTER_USER_TRADES_BY_COIN = " FILTER_USER_TRADES_BY_COIN";
export const FILTER_USER_TRADES_BY_TYPE = " FILTER_USER_TRADES_BY_TYPE";
export const STORE_VERIFY_LINK_URL = "STORE_VERIFY_LINK_URL";
export const GET_ADDRESS_COUNTRY_SELECTED = "GET_ADDRESS_COUNTRY_SELECTED";
export const GOOGLEAUTHQRCODE = "GOOGLEAUTHQRCODE";
export const GOOGLEAUTHPROCESSMODALS = "GOOGLEAUTHPROCESSMODALS";
export const USEGOOGLEAUTH = "USEGOOGLEAUTH";
export const USEPHONENUMBER = "USEPHONENUMBER";
export const SETTING_LOADER_SKELETON = "SETTING_LOADER_SKELETON";
export const CLAIM_LOADER_SKELETON = "CLAIM_LOADER_SKELETON";
export const TRADE_REVIEW_INIITIAL = "TRADE_REVIEW_INIITIAL";
export const TRADE_REVIEW = "TRADE_REVIEW";
export const TRADE_REVIEW_ERROR = "TRADE_REVIEW_ERROR";
export const TRADE_REVIEW_DEFAULT = "TRADE_REVIEW_DEFAULT";
export const LIST_OF_BLOCKED_USERS = "LIST_OF_BLOCKED_USERS";
export const UPDATED_PHONE_NUMBER_DETAILS = "UPDATED_PHONE_NUMBER_DETAILS";
export const USERDETAILSFORUPDATINGPHONENUMBER = "USERDETAILSFORUPDATINGPHONENUMBER";
export const MESSAGE_NOTIFICATION = "MESSAGE_NOTIFICATION";
export const TRADE_NOTIFICATION = "TRADE_NOTIFICATION";
export const TRANSFER_NOTIFICATION = "TRANSFER_NOTIFICATION";
export const MESSAGE_NOTIFICATION_LENGTH = "MESSAGE_NOTIFICATION_LENGTH";
export const TRADE_NOTIFICATION_LENGTH = "TRADE_NOTIFICATION_LENGTH";
export const TRANSFER_NOTIFICATION_LENGTH = "TRANSFER_NOTIFICATION_LENGTH";
export const ALL_NOTIFICATION_LENGTH = "ALL_NOTIFICATION_LENGTH";
export const ALL_NOTIFICATION_LIST = "ALL_NOTIFICATION_LIST";

export const SETWALLETWITHDRAWALMODAL = "SETWALLETWITHDRAWALMODAL";
export const SETWALLETDEPOSITEMODAL = "SETWALLETDEPOSITEMODAL";

export const USER_DETAILS_FOR_UPDATING_PHONE_NUMBER = "USER_DETAILS_FOR_UPDATING_PHONE_NUMBER";
export const LIST_OF_REVIEWS = "LIST_OF_REVIEWS";
export const ALL_PAGE_REVIEWS = "ALL_PAGE_REVIEWS";
export const BLOCK_A_USER_PROFILE = "BLOCK_A_USER_PROFILE";
export const UNBLOCK_A_USER_PROFILE = "UNBLOCK_A_USER_PROFILE";
export const BLOCK_A_USER_MODAL = "BLOCK_A_USER_MODAL";
export const PROFILE_HEADER_OTHER_DATA = "PROFILE_HEADER_OTHER_DATA";
export const CHECK_IF_A_USER_IS_BLOCKED = "CHECK_IF_A_USER_IS_BLOCKED";

export const WITHDRAWALMAINDETAILS = "WITHDRAWALMAINDETAILS";
export const LOADIFCOMPLETE = "LOADIFCOMPLETE";

export const ALLSKIP = "ALLSKIP";
export const MESSAGESKIP = "MESSAGESKIP";
export const TRADESKIP = "TRADESKIP";
export const TRANSFERSKIP = "TRANSFERSKIP";
export const ALLPAGES = "ALLPAGES";
export const MESSAGEPAGES = "MESSAGEPAGES";
export const TRADEPAGES = "TRADEPAGES";
export const TRANSFERPAGES = "TRANSFERPAGES";
export const NOTIFICATION_LIMIT = "NOTIFICATION_LIMIT";
export const LIST_OF_BLOCK_USERS = "LIST_OF_BLOCK_USERS";
export const REFERRAL_CLAIM = "REFERRAL_CLAIM";
export const NOTIFICATIONNUMCHECKER = "NOTIFICATIONNUMCHECKER";
export const SWITCH_TO_GOOGLE_CHANGE_NUMBER = "SWITCH_TO_GOOGLE_CHANGE_NUMBER";
export const SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO = "SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO";
export const ERROR_UPDATING_PHONE_NUMBER = "ERROR_UPDATING_PHONE_NUMBER";
export const PAGINATION_ARRAY_LIST = "PAGINATION_ARRAY_LIST";
export const PAGE_NUM = "PAGE_NUM";
export const PROFILE_PAGINATION_TOTAL = "PROFILE_PAGINATION_TOTAL";
export const CURRENT_VALUE_BANLANCE_STATE = "CURRENT_VALUE_BANLANCE_STATE";
export const PAGINATION_ARRAY_LIST_NEGATIVE = "PAGINATION_ARRAY_LIST_NEGATIVE";
export const PAGE_NUM_NEGATIVE = "PAGE_NUM_NEGATIVE";
export const PROFILE_PAGINATION_TOTAL_NEGATIVE = "PROFILE_PAGINATION_TOTAL_NEGATIVE";
export const PAGINATION_ARRAY_LIST_POSITIVE = "PAGINATION_ARRAY_LIST_POSITIVE";
export const PAGE_NUM_POSITIVE = "PAGE_NUM_POSITIVE";
export const PROFILE_PAGINATION_TOTAL_POSITIVE = "PROFILE_PAGINATION_TOTAL_POSITIVE";
export const ARRAYFORPAGINATION = "ARRAYFORPAGINATION";
export const AUTH_LOADER = "AUTH_LOADER";
export const CHANGE_PAGINATION = "CHANGE_PAGINATION";

export const ALL_REVIEW_PAGE_ACTION = "ALL_REVIEW_PAGE_ACTION";
export const SAVE_OTHER_USERNAME_ACTION = "SAVE_OTHER_USERNAME_ACTION";

export const SCALLING_VALUES = "SCALLING_VALUES";
// LOGIN
export const STORE_LOGIN_DETAILS_ACTION = "STORE_LOGIN_DETAILS_ACTION";
export const PASSWORD_DETECT_ERROR_STATE = "PASSWORD_DETECT_ERROR_STATE";
export const STORE_LOGIN_GOOGLE_OTP_ACTION = "STORE_LOGIN_GOOGLE_OTP_ACTION";
export const STORE_LOGIN_OTP_ACTION = "STORE_LOGIN_OTP_ACTION";
export const LOGIN_ERROR_ACTION = "LOGIN_ERROR_ACTION";
export const HOLD_USER_DETAILS = "HOLD_USER_DETAILS";
export const HOLD_USER_DETAILS_REGISTER = "HOLD_USER_DETAILS_REGISTER";
//SETTINGS
export const STORE_REJECTED_ADDRESS_MODAL = "STORE_REJECTED_ADDRESS_MODAL";

export const OTP_FOR_GOOGLE_AUTH = "OTP_FOR_GOOGLE_AUTH";
export const FORGOT_GOOGLE_AUTH = "FORGOT_GOOGLE_AUTH";
export const SELECT_SKIP = "SELECT_SKIP";
export const SELECT_FSKIP = "SELECT_FSKIP";
export const SELECT_LIMIT = "SELECT_LIMIT";

export const GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE = "GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE";
export const MARKET_SKIP_SYSTEM = "MARKET_SKIP_SYSTEM";
export const MARKET_LIMIT_SYSTEM = "MARKET_LIMIT_SYSTEM";

export const GET_MARKET_PLACE_INITIAL_STATE = "GET_MARKET_PLACE_INITIAL_STATE";

export const DEPOSITE_SKIP = "DEPOSITE_SKIP";
export const DEPOSITE_FSKIP = "DEPOSITE_FSKIP";
export const DEPOSITE_LIMIT = "DEPOSITE_LIMIT";

export const GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE = "GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE";

export const CANCEL_TRADE_INITIAL_CLOSE = "CANCEL_TRADE_INITIAL_CLOSE";

export const REFERRAL_TRANSACTION = "REFERRAL_TRANSACTION";

// Settings
export const GET_SELECTED_COUNTRY_SETTINGS = "GET_SELECTED_COUNTRY_SETTINGS";

export const GET_SELECTED_COIN = "GET_SELECTED_COIN";
export const GET_SELECTED_PAYMENT = "GET_SELECTED_PAYMENT";
export const GET_SELECTED_COUNTRY = "GET_SELECTED_COUNTRY";
export const GET_SELECTED_GEO_LOCATION = "GET_SELECTED_GEO_LOCATION";

export const MARKET_DATA = "MARKET_DATA";
export const LANDING_LOADER = "LANDING_LOADER";
export const REVIEW_RESPONSE = "REVIEW_RESPONSE";
export const PAGE_EMPTY_CHECKER = "PAGE_EMPTY_CHECKER";
export const CHECK_FOR_EXTRA_DATA = "CHECK_FOR_EXTRA_DATA";
export const CURRENT_TOTAL_VALUE = "CURRENT_TOTAL_VALUE";
export const POST_TRADE_SELL_ERROR = "POST_TRADE_SELL_ERROR";
export const POST_TRADE_BUY_ERROR = "POST_TRADE_BUY_ERROR";
export const POST_TRADE_DETAILED_FORM = "POST_TRADE_DETAILED_FORM";
export const EDIT_POST_TRADE_SELL_ERROR = "EDIT_POST_TRADE_SELL_ERROR";
export const EDIT_POST_TRADE_BUY_ERROR = "EDIT_POST_TRADE_BUY_ERROR";
export const EDIT_POST_TRADE_DETAILED_FORM = "EDIT_POST_TRADE_DETAILED_FORM";
export const GET_LAST_TIME_SEEN = "GET_LAST_TIME_SEEN";
export const GET_PENDING_TRADE = "GET_PENDING_TRADE";
export const GET_DISPUTED_TRADE = "GET_DISPUTED_TRADE";
export const GET_COMPLETED_TRADE = "GET_COMPLETED_TRADE";
export const GET_LOADER = "GET_LOADER";
export const TRADE_SKIP = "TRADE_SKIP";
export const TRADE_FSKIP = "TRADE_FSKIP";
export const TRADE_LIMIT = "TRADE_LIMIT";
export const TRADE_CHECK_FOR_EMPTY = "TRADE_CHECK_FOR_EMPTY";
export const TRADE_CHECK_FOR_EXTRA_DATA = "TRADE_CHECK_FOR_EXTRA_DATA";
export const TRADE_DETAILS = "TRADE_DETAILS";
export const PENDING_AND_DISPUTED_TRADE = "PENDING_AND_DISPUTED_TRADE";
export const BUY_SELL_CHECK_FOR_EXTRA_DATA = "BUY_SELL_CHECK_FOR_EXTRA_DATA";
export const BUY_SELL_CHECK_FOR_EMPTY = "BUY_SELL_CHECK_FOR_EMPTY";
export const GET_BUY_SELL_TRANSACTION_INITIAL_FUTURE = "GET_BUY_SELL_TRANSACTION_INITIAL_FUTURE";
export const BUY_SELL_TRADE_DETAILS = "BUY_SELL_TRADE_DETAILS";

export const CONVERT_DROPDOWN_STATE = "CONVERT_DROPDOWN_STATE";

export const CONVERT_DROPDOWN_CONTENT = "CONVERT_DROPDOWN_CONTENT";

export const CONVERT_TOKEN_ERROR = "CONVERT_TOKEN_ERROR";

export const CONVERT_BANK_DROPDOWN = "CONVERT_BANK_DROPDOWN";

export const CONVERT_BANK_ACC_CHECKER = "CONVERT_BANK_ACC_CHECKER";

export const CONVERT_LEVEL = "CONVERT_LEVEL";
export const CONVERT_LOADER = "CONVERT_LOADER";
export const CONVERT_CONVERTION_RATE = "CONVERT_CONVERTION_RATE";
export const CONVERT_BANK_LIST = "CONVERT_BANK_LIST";
export const CONVERT_CONVERTION_NORMAL = "CONVERT_CONVERTION_NORMAL";
export const CONVERT_TOKEN_INPUT = "CONVERT_TOKEN_INPUT";
export const CONVERT_TOKEN_MAX_MIN_ERROR = "CONVERT_TOKEN_MAX_MIN_ERROR";
export const CONVERT_MIN_LOADER = "CONVERT_MIN_LOADER";
export const CONVERT_BANK_DETAILS = "CONVERT_BANK_DETAILS";
export const CONVERT_CONFIRM_ACC_DETAILS = "CONVERT_CONFIRM_ACC_DETAILS";
export const CONVERT_TRANSFER_DETAILS = "CONVERT_TRANSFER_DETAILS";
export const CONVERT_MODAL_CHECKER = "CONVERT_MODAL_CHECKER";
export const CONVERT_INSUFFICIENT_FUNDS = "CONVERT_INSUFFICIENT_FUNDS";

export const TRANSACTION_WITHDRAWALSTATED = "TRANSACTION_WITHDRAWALSTATED";
