import {Skeleton} from "@mui/material";
import React, {memo} from "react";
import {useSelector} from "react-redux";
import avatarProfile from "../../assets/icons/avatar-profile-photo.svg";
import "../../styles/common/profileImage.scss";

const ProfileImage = (props) => {
  // Check userdata & locationData in redux store
  const {userData} = useSelector((state) => state.generalReducer);

  const profileImage =
    "data" in userData ?? ""
      ? userData?.data?.userData?.profileImageUrl
        ? `https://oyola.s3.amazonaws.com/${userData?.data?.userData?.profileImageUrl}`
        : avatarProfile
      : avatarProfile;

  return (
    <React.Fragment>
      {!("data" in userData) ? (
        <Skeleton animation="wave" variant="circular" width={70} height={70} style={{backgroundColor: "white"}} />
      ) : (
        <img
          src={profileImage ?? avatarProfile}
          alt="profle"
          width={70}
          height={70}
          className="profileImageStyle"
          style={{...props.style}}
          onClick={() => props.callBack}
        />
      )}
    </React.Fragment>
  );
};

export default memo(ProfileImage);
