import {
  WALLETBALANCE,
  WALLETNETWORKDATA,
  WALLETCOINDETAILS,
  TOKENNETWORKFEE,
  MODALCHECKER,
  WITHDRAWALDETAILS,
  WALLETOTPERROR,
  DETAILSOFWITHDRAWAL,
  WALLETADDRESSCHECKER,
  WALLETADDRESSERROR,
  TRANSACTIONHISTORY,
  WALLETLOADER,
  WITHDRAWALERROR,
  COIN_NETWORK_SELECTION,
  SETWALLETWITHDRAWALMODAL,
  SETWALLETDEPOSITEMODAL,
  WITHDRAWALMAINDETAILS,
  LOADIFCOMPLETE,
  CURRENT_VALUE_BANLANCE_STATE,
  SCALLING_VALUES,
} from "../types";

const initialState = {
  userWalletBalance: {},
  networkData: "",
  walletCoinDetials: {},
  tokenNetworkFee: {},
  modalChecker: {},
  withDrawalDetails: {},
  walletOtpError: "",
  detailsOfWithdrawal: {},
  walletAddressChecker: false,
  walletAddressError: "",
  transactionHistory: [],
  walletLoader: false,
  withdrawError: "",
  coinNetworkSelection: {},
  setModalWithdrawal: {},
  setModalDeposit: {},
  withDrawalMainDetails: {},
  loadIfCompleted: false,
  currentValueBanlanceState: 0,
  ScallingValue: {},
};

const walletReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case SCALLING_VALUES: {
      return {
        ...state,
        ScallingValue: payload,
      };
    }

    case CURRENT_VALUE_BANLANCE_STATE: {
      return {
        ...state,
        currentValueBanlanceState: payload,
      };
    }

    case WALLETBALANCE: {
      return {
        ...state,
        userWalletBalance: payload,
      };
    }

    case WALLETNETWORKDATA: {
      return {
        ...state,
        networkData: payload,
      };
    }

    case WALLETCOINDETAILS: {
      return {
        ...state,
        walletCoinDetials: payload,
      };
    }

    case TOKENNETWORKFEE: {
      return {
        ...state,
        tokenNetworkFee: payload,
      };
    }

    case MODALCHECKER: {
      return {
        ...state,
        modalChecker: payload,
      };
    }

    case WITHDRAWALDETAILS: {
      return {
        ...state,
        withDrawalDetails: payload,
      };
    }

    case WALLETOTPERROR: {
      return {
        ...state,
        walletOtpError: payload,
      };
    }

    case DETAILSOFWITHDRAWAL: {
      return {
        ...state,
        detailsOfWithdrawal: payload,
      };
    }

    case WITHDRAWALERROR: {
      return {
        ...state,
        withdrawError: payload,
      };
    }

    case COIN_NETWORK_SELECTION: {
      return {
        ...state,
        coinNetworkSelection: payload,
      };
    }

    case TRANSACTIONHISTORY: {
      return {
        ...state,
        transactionHistory: payload,
      };
    }

    case WALLETADDRESSCHECKER: {
      return {
        ...state,
        walletAddressChecker: payload,
      };
    }

    case WALLETADDRESSERROR: {
      return {
        ...state,
        walletAddressError: payload,
      };
    }

    case WALLETLOADER: {
      return {
        ...state,
        walletLoader: payload,
      };
    }

    case SETWALLETWITHDRAWALMODAL: {
      return {
        ...state,
        setModalWithdrawal: payload,
      };
    }

    case SETWALLETDEPOSITEMODAL: {
      return {
        ...state,
        setModalDeposit: payload,
      };
    }

    case WITHDRAWALMAINDETAILS: {
      return {
        ...state,
        withDrawalMainDetails: payload,
      };
    }

    case LOADIFCOMPLETE: {
      return {
        ...state,
        loadIfCompleted: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default walletReducer;
