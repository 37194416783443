import {toast} from "react-toastify";
import store from "../store";
import {getModalChecker} from "../store/actions/walletAction";
import {MODALLOADER, SKELETON_LOADER, WITHDRAWALERROR} from "../store/types";
import APIs from "./api";

const WalletService = {
  TokenLocalCurrency: (tokenSymbol, currency) => {
    const token = tokenSymbol?.toUpperCase();
    const cur = currency?.toUpperCase();
    return APIs.get(`/api/v1/open/get-token-price/${token}/${cur}`)
      .then((data) => {
        return {[token]: data?.data.currencyValue};
      })
      .catch((err) => {
        throw err;
      });
  },

  UserTotalBalanceChecker: () => {
    return APIs.get(`/api/v1/profile/get-user-token-balance`)
      .then((res) => {
        if (res?.data?.message === "success") {
          return APIs.get(`/api/v1/open/get-token-price/BTC/${res?.data?.currency}`)
            .then((data) => {
              if (data?.data?.message === "success") {
                const unitBTCInCurrencyValue = data.data.currencyValue;
                const userTotalBalance = res.data.totalValue;
                // let currentBalances;
                // let currentBalancePercentage;
                // let numChecker;

                // if (currentUserBalance !== 0) {
                //   currentBalances = res.data.totalValue - currentUserBalance;
                //   currentBalancePercentage = `${(currentBalances * 100) / currentUserBalance}`;
                //   numChecker = Math.sign(currentBalances);
                // } else {
                //   currentBalances = 0;
                //   currentBalancePercentage = `${0}`;
                //   numChecker = Math.sign(currentBalances);
                // }

                const userValueInBTC = userTotalBalance / unitBTCInCurrencyValue;

                return {
                  ...res.data,
                  currentUserBalances: res.data.totalValue,
                  userValueInBTC: userValueInBTC,
                  unitBTCInCurrencyValue: unitBTCInCurrencyValue,
                  unitBTC: data?.data?.currencyValue,
                };
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  UserTotalBalanceScalling: (currentUserBalance, currency, valueInBTC) => {
    return APIs.get(`/api/v1/open/get-token-price/BTC/${currency}`)
      .then((data) => {
        if (data?.data?.message === "success") {
          const unitBTCInCurrencyValue = data.data.currencyValue;

          let currentBalancePercentage;
          let numChecker;
          let differenceInBTC;

          // const userValueInBTC = currentUserBalance / unitBTCInCurrencyValue;

          const userValueInBTCValue = valueInBTC * unitBTCInCurrencyValue;

          if (currentUserBalance !== 0) {
            differenceInBTC = userValueInBTCValue - currentUserBalance;

            // differenceInLocalCurrency = differenceInBTC * unitBTCInCurrencyValue;

            currentBalancePercentage = `${(differenceInBTC * 100) / currentUserBalance}`;

            numChecker = Math.sign(differenceInBTC);
          } else {
            differenceInBTC = 0;
            currentBalancePercentage = `${0}`;
            numChecker = Math.sign(differenceInBTC);
          }

          return {
            currency: currency,
            curentUserBalancesDifferences: differenceInBTC,
            currentUserBalancesPercentage: currentBalancePercentage,
            currentUserBalanceNumChecker: numChecker,
            unitBTCInCurrencyValue: unitBTCInCurrencyValue,
            unitBTC: data?.data?.currencyValue,
          };
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  UserTotalBalance: () => {
    return APIs.get(`/api/v1/profile/get-user-token-balance`)
      .then((res) => {
        if (res?.data?.message === "success") {
          return APIs.get(`/api/v1/open/get-token-price/BTC/${res?.data?.currency}`)
            .then((data) => {
              if (data?.data?.message === "success") {
                const unitBTCInCurrencyValue = data.data.currencyValue;
                const userTotalBalance = res.data.totalValue;

                const userValueInBTC = userTotalBalance / unitBTCInCurrencyValue;

                return {
                  ...res.data,
                  currentUserBalances: res.data.totalValue,
                  userValueInBTC: userValueInBTC,
                  unitBTCInCurrencyValue: unitBTCInCurrencyValue,
                  unitBTC: data?.data?.currencyValue,
                };
              }
            })
            .catch((err) => {
              throw err;
            });
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  GetLocalCurrencyValue: (token, cur) => {
    return APIs.get(`/api/v1/open/get-token-price/${token}/${cur}`).then((data) => {
      if (data?.data?.message === "success") {
        return data.data;
      }
    });
  },

  GetLocalCurrencyValueInBTC: (cur) => {
    return APIs.get(`/api/v1/open/get-token-price/BTC/${cur}`).then((data) => {
      return data.data;
    });
  },

  TokenNetworkFee: (token, amountValue, unitPrice) => {
    const tokenSymbol = token?.toUpperCase();
    const amount = Number(amountValue);
    return APIs.get(`/api/v1/open/get-withdrawal-fees/${tokenSymbol}/${amount}`)
      .then((res) => {
        if (res?.data?.message === "success") {
          const fee = res.data.fees;
          const withdrawalAmount = amount - fee;
          const amountInLocalCurrency = unitPrice * withdrawalAmount;

          if (Math.sign(withdrawalAmount) === 1) {
            store.dispatch({type: SKELETON_LOADER, payload: false});
            if (fee > amount) {
              store.dispatch({type: WITHDRAWALERROR, payload: `minimum withdrawal is ${fee}`});
            }
            return {
              fee: fee,
              amount: `${withdrawalAmount}`,
              amountInLocalCurrency: `${amountInLocalCurrency}`,
              total: amount,
            };
          } else {
            store.dispatch({type: SKELETON_LOADER, payload: false});
            return {};
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  TokenNetworkFeeSelected: (token, totalBalance, amountValue, unitPrice) => {
    const tokenSymbol = token?.toUpperCase();
    const amount = Number(amountValue);
    const userTotalBalance = Number(totalBalance);

    return APIs.get(`/api/v1/open/get-withdrawal-fees/${tokenSymbol}/${amount}`)
      .then((res) => {
        if (amountValue.startsWith("00")) {
          store.dispatch({type: WITHDRAWALERROR, payload: "wrong inputed value"});
          store.dispatch({type: SKELETON_LOADER, payload: false});
          return {};
        }

        if (res?.data?.message === "success") {
          const fee = res.data.fees;
          const sendingAmount = amount + fee;
          const withdrawalAmount = userTotalBalance - sendingAmount;
          const amountInLocalCurrency = unitPrice * sendingAmount;

          if (withdrawalAmount && amount >= 0 && sendingAmount > 0) {
            store.dispatch({type: SKELETON_LOADER, payload: false});

            if (fee > amount) {
              store.dispatch({type: WITHDRAWALERROR, payload: `minimum withdrawal is ${fee}`});
            }

            store.dispatch({type: WITHDRAWALERROR, payload: ""});

            return {
              fee: fee,
              amount: `${amount}`,
              amountInLocalCurrency: `${amountInLocalCurrency}`,
              total: sendingAmount,
            };
          } else if (amountValue > totalBalance) {
            store.dispatch({type: WITHDRAWALERROR, payload: "Insufficient fund"});
            store.dispatch({type: SKELETON_LOADER, payload: false});
            return {};
          } else {
            store.dispatch({type: WITHDRAWALERROR, payload: "Amount not enough"});
            store.dispatch({type: SKELETON_LOADER, payload: false});
            return {};
          }
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  SendOtp: () => {
    return APIs.post(`/api/v1/transfer/secure-auth`)
      .then((res) => {
        if (res?.data?.message === "success") {
          // store.dispatch(getUserDetails());
          return true;
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  getDepositWithdraw: async ({skip, limit}) => {
    return APIs.get(
      `/api/v1/metrics/get-all-transactions?skip=${skip}&limit=${limit}&type=null&tokenSymbol=null&status=null`
    )
      .then((data) => {
        if (data?.data?.message === "success") {
          return data.data;
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  walletAddressCheck: (address, chain) => {
    return APIs.get(`/api/v1/open/validate-crypto-address/${address}/${chain}`)
      .then((data) => {
        return data.data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  SendWithdrawal: (payload) => {
    return APIs.post(`/api/v1/transfer/withdraw`, payload)
      .then((res) => {
        if (res?.data?.message !== "success") {
          store.dispatch({type: MODALLOADER, payload: false});
        }

        if (res.data.message === "success") {
          return {
            data: res.data.data,
            status: true,
          };
        }
      })
      .catch((err) => {
        toast(err);
        throw err;
      });
  },
};

export default WalletService;
