import {memo} from "react";
import "../../styles/editTradeStyles/editTradeStryles.scss";
import EditTradeFirstSection from "./editTradeFirstSection";
import EditTradeSecondSection from "./editTradeSecondSection";
import EditTradeThirdSection from "./editTradeThirdSection";
import EditTradeFourthSection from "./editTradeFourthSection";
import EditTradeButton from "./editTradeButton";
import CustomCheckbox from "../Common/CustomCheckbox";
import {useDispatch, useSelector} from "react-redux";
import {getOrderStatus} from "../../store/actions/editPostTradeAction";
import {EDITTRADEDISABLE} from "../../store/types";

const EditTradeComponent = () => {
  const dispatch = useDispatch();
  const orderStatus = useSelector((state) => state.editTradeReducer.orderStatus);

  const handleChangeStatus = (e) => {
    dispatch({type: EDITTRADEDISABLE, payload: false});
    const {checked} = e.target;
    if (checked === false) {
      dispatch(getOrderStatus({name: "inactive", checker: checked}));
      return;
    }

    if (checked === true) {
      dispatch(getOrderStatus({name: "active", checker: checked}));
      return;
    }
  };

  return (
    <>
      <div className="editTradeWrapper">
        <div className="ParentButton">
          <EditTradeButton title={"Edit Post Trade"} />
          <div className="activateTrade">
            <CustomCheckbox handleChange={handleChangeStatus} state={orderStatus} />
            <div className="activateTrade__text">
              <p>Activate Trade</p>
            </div>
          </div>
        </div>
        <div className="postTrade__Container">
          <EditTradeFirstSection />
          <EditTradeSecondSection />
          <div className="postTrade__Increment">
            <EditTradeButton title={"More Information"} />
          </div>
          <div>
            <EditTradeThirdSection />

            <EditTradeFourthSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(EditTradeComponent);
