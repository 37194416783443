import {NOTFOUND} from "../../../../../hooks/imagePath";
import {TablePaginationComponent} from "../../../../Common/pagination";
import "../../../../../styles/table.scss";
import {useDispatch, useSelector} from "react-redux";
import {useContext, useEffect, useState} from "react";
import {Loader} from "../../../../Common/Loader";
import CurrencyFormat from "react-currency-format";
import {capitalizeFirstLetter} from "../../../../../utils/capitalizeFirstLetter";
import {Link, useHistory} from "react-router-dom";
import {
  getBuySellOnNextAction,
  getBuySellOnPrevAction,
  getBuySellTransactionAction,
} from "../../../../../store/actions/buySellTransactionHistory";
import TransactionService from "../../../../../services/transactionHistory";
import {
  BUY_SELL_CHECK_FOR_EMPTY,
  BUY_SELL_CHECK_FOR_EXTRA_DATA,
  GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE,
  GET_BUY_SELL_TRANSACTION_SUCCESS,
  SELECT_SKIP,
} from "../../../../../store/types";
import {getUserType} from "../../../../../hooks/getUserType";

const DisputedMobileTable = ({data, loading}) => {
  const {push} = useHistory();
  const dispatch = useDispatch();

  const {userData} = useSelector((state) => state.generalReducer);

  const {skip, fskip, limit, checkForExtraData, checkForEmpty} = useSelector(
    (state) => state.buySellTransactionReducer
  );

  const onNext = () => {
    const skipDetails = (skip + 1) * limit;
    if (checkForEmpty?.length !== 0) {
      TransactionService.getBuySell({
        skip: skipDetails,
        limit: limit,
        tokenSymbol: null,
        type: null,
        status: null,
      }).then((data) => {
        dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: []});

        if (data?.message === "success") {
          if (data?.trades?.length > 0) {
            dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data?.trades});
            dispatch({type: SELECT_SKIP, payload: skip + 1});
            dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: data?.trades});

            if (data?.trades?.length === limit) {
              dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: true});
              const futureSkipDetails = skipDetails + limit;

              TransactionService.getBuySellFuture({
                skip: futureSkipDetails,
                limit: limit,
                tokenSymbol: null,
                type: null,
                status: null,
              }).then((data) => {
                if (data?.trades?.length === 0) {
                  dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
                  dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
                } else {
                  dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
                }
              });
            }
          } else {
            dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
          }
        }
      });
    }
  };

  const onPrev = () => {
    dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: null});
    const skipDetails = (skip - 1) * limit;
    if (data?.length > 0 && skip + 1 > 1) {
      TransactionService.getBuySell({
        skip: skipDetails,
        limit: limit,
        tokenSymbol: null,
        type: null,
        status: null,
      }).then((data) => {
        dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: []});
        if (data?.message === "success") {
          dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data?.trades});
          dispatch({type: SELECT_SKIP, payload: skip - 1});
        }
      });
    }
  };

  return (
    <>
      <div className="__transaction__mobile__wrapper">
        {loading ? (
          // Array.from(new Array(5)).map((item, i) => (
          //   <Stack spacing={1} key={i} sx={{margin: "10px 10px 10px 10px"}}>
          //     <Skeleton animation="wave" variant="rectangular" height={200} />
          //   </Stack>
          // ))
          <Loader />
        ) : data && data.length > 0 ? (
          data.map((item, key) => (
            <div className="__mobile__content__wrapper" key={key}>
              <span className="__trade__title">
                {capitalizeFirstLetter(item?.type)} {item?.tokenSymbol}
              </span>
              <div style={{marginTop: "15px"}}>
                <div className="__details">
                  <p className="__title">Unit Price</p>
                  <span className="__value">
                    <CurrencyFormat
                      value={item?.unitPrice}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={2}
                      fixedDecimalScale
                      suffix={` ${item?.currency}`}
                    />
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Crypto amount</p>
                  <span className="__value">
                    <CurrencyFormat
                      value={item?.amount}
                      displayType={"text"}
                      thousandSeparator={true}
                      decimalScale={5}
                      fixedDecimalScale
                      suffix={` ${item?.tokenSymbol}`}
                    />
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Counteparty</p>
                  <span className="__value __counte">
                    {/* <Link
                      to={`profile/${item?.profileData?.userData?.username}`}
                      target={"_blank"}
                      className="transactionTableArea__wallet __countParty"
                      style={{color: "#9381ff", textDecoration: "underline", fontSize: "13px", cursor: "pointer"}}
                    >
                      @{item?.profileData?.userData?.username}
                    </Link> */}

                    {getUserType(item, userData) === "trader" ? (
                      <Link
                        to={"/app/profile/" + item?.profileData?.userData?.username}
                        target={"_blank"}
                        className="transactionTableArea__wallet __countParty"
                        style={{color: "#9381ff", textDecoration: "underline", fontSize: "13px", cursor: "pointer"}}
                      >
                        @{item?.profileData?.userData?.username}
                      </Link>
                    ) : (
                      <Link
                        to={"/app/profile/" + item?.profileData?.traderData?.username}
                        target={"_blank"}
                        className="transactionTableArea__wallet __countParty"
                        style={{color: "#9381ff", textDecoration: "underline", fontSize: "13px", cursor: "pointer"}}
                      >
                        @{item?.profileData?.traderData?.username}
                      </Link>
                    )}
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Status</p>
                  <span
                    className={`__value ${
                      item?.status === "pending"
                        ? "__pending"
                        : item?.status === "cancelled"
                        ? "__cancelled"
                        : "__complete"
                    }`}
                  >
                    {item?.status}
                  </span>
                </div>
                <div className="__details">
                  <p className="__title">Action</p>
                  <span className="__value __view" onClick={() => push(`/app/trade/${item?.trade_id}`)}>
                    View Trade
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="__notFound__wrapper">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}
      </div>
      <div>
        <TablePaginationComponent
          onNext={onNext}
          onPrev={onPrev}
          skip={skip}
          lengthChecker={checkForEmpty}
          dataCheck={data}
          limit={limit}
          loading={checkForExtraData}
        />
      </div>
    </>
  );
};

export default DisputedMobileTable;
