import React from "react";
import {memo} from "react";
import {useSelector} from "react-redux";
import {Loader} from "../Common/Loader";
import SectionModalCentered from "../Common/sectionModalCentered";
import GoogleAuthVerification from "./googleAuthVerification";
import GoogleConfirmationModal from "./googleConfirmationModal";

const GoogleProcessModalSection = () => {
  const googleAuthProcessModals = useSelector((state) => state.settingsReducer.googleAuthProcessModals);

  switch (googleAuthProcessModals.num) {
    case 0:
      return (
        <SectionModalCentered open={true}>
          <Loader />
        </SectionModalCentered>
      );
    case 1:
      return (
        <SectionModalCentered open={true}>
          <GoogleAuthVerification />
        </SectionModalCentered>
      );
    case 2:
      return (
        <SectionModalCentered open={true}>
          <GoogleConfirmationModal />
        </SectionModalCentered>
      );

    default:
      return "";
  }
};

export default memo(GoogleProcessModalSection);
