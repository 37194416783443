import moment from "moment";
import {
  GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_ERROR,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL,
  GET_DEPOSIT_NEXT,
  GET_DEPOSIT_PREV,
  FILTER_DEPOSIT_WITHDRAW_BY_COIN,
  FILTER_DEPOSIT_WITHDRAW_BY_TYPE,
  FILTER_DEPOSIT_WITHDRAW_BY_STATUS,
  FILTER_DEPOSIT_WITHDRAW_BY_DATE,
  GET_DEPOSIT_WITHDRAW_REFRESH,
  FILTER_DEPOSIT_WITHDRAW_MULTIPLE,
  DEPOSITE_SKIP,
  DEPOSITE_FSKIP,
  DEPOSITE_LIMIT,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE,
  TRANSACTION_WITHDRAWALSTATED,
} from "../types";

const initialState = {
  response: null,
  response2: null,
  error: [],
  loading: false,
  skip: 0,
  fskip: 0,
  limit: 10,
  transactionWithdrawalStated: {type: "", tokenSymbol: "", status: ""},
};

const depositWithdrawaltransactionReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS: {
      return {
        ...state,
        response: payload,
        response2: payload,
        loading: false,
      };
    }
    case GET_DEPOSIT_WITHDRAW_TRANSACTION_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE: {
      return {
        ...state,
        loading: false,
      };
    }

    case GET_DEPOSIT_NEXT: {
      return {
        ...state,
        skip: state.skip + 1,
        fskip: state.limit * (state.skip + 1),
        limit: state.limit,
      };
    }

    case DEPOSITE_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case DEPOSITE_FSKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case DEPOSITE_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case GET_DEPOSIT_WITHDRAW_REFRESH: {
      return {
        ...state,
        skip: 0,
        fskip: 0,
        limit: 5,
      };
    }

    case TRANSACTION_WITHDRAWALSTATED: {
      return {
        ...state,
        transactionWithdrawalStated: payload,
      };
    }

    case FILTER_DEPOSIT_WITHDRAW_BY_COIN: {
      return {
        ...state,
        transactionWithdrawalStated: {...state.transactionWithdrawalStated, tokenSymbol: payload?.token},
      };
    }

    case FILTER_DEPOSIT_WITHDRAW_BY_TYPE: {
      return {
        ...state,
        transactionWithdrawalStated: {...state.transactionWithdrawalStated, ...payload},
      };
    }

    case FILTER_DEPOSIT_WITHDRAW_BY_STATUS: {
      return {
        ...state,
        transactionWithdrawalStated: {...state.transactionWithdrawalStated, ...payload},
      };
    }

    case FILTER_DEPOSIT_WITHDRAW_BY_DATE: {
      if (payload === null) {
        return {
          ...state,
          response: state.response2,
        };
      } else {
        let dd = state.response2.filter((item) => {
          return (
            moment(item.timeStamp).format("YYYY-M-D") >= moment(payload[0]?.startDate).format("YYYY-M-D") &&
            moment(item.timeStamp).format("YYYY-M-D") <= moment(payload[0]?.endDate).format("YYYY-M-D")
          );
        });
        return {
          ...state,
          response: dd,
        };
      }
    }
    case FILTER_DEPOSIT_WITHDRAW_MULTIPLE: {
      const {type, status} = payload;
      let setMultipleShadowArrayData = [];
      if (type.length > 0 && status.length === 0) {
        type.forEach((str) => {
          const dataF = state.response2.filter((item) => item.type === str);
          setMultipleShadowArrayData.push(...dataF);
        });
      } else if (type.length > 0 && status.length > 0) {
        var tempList = [];
        type.forEach((str) => {
          const dataF = state.response2.filter((item) => item.type === str);
          setMultipleShadowArrayData.push(...dataF);
        });
        status.forEach((str) => {
          const dataF = tempList.filter((item) => item.status === str);
          tempList.push(...dataF);
        });
        setMultipleShadowArrayData = tempList;
      } else if (type.length === 0 && status.length > 0) {
        status.forEach((str) => {
          const dataF = state.response2.filter((item) => item.status === str);
          setMultipleShadowArrayData.push(...dataF);
        });
      }
      return {
        ...state,
        response: setMultipleShadowArrayData,
      };
    }

    default: {
      return state;
    }
  }
};

export default depositWithdrawaltransactionReducer;
