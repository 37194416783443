import React, {memo} from "react";
import BlockAUser from "./blockAUser";
import UnBlockAUser from "./unblockAUser";
import {useSelector} from "react-redux";
import "./modalInfo.scss";

const ModalInfo = () => {
  const showOTP = useSelector((state) => state.profileReducer.blockAUserModaldata);

  return (
    <React.Fragment>
      {showOTP === 0 && <BlockAUser />}
      {showOTP === 1 && <UnBlockAUser />}
    </React.Fragment>
  );
};

export default memo(ModalInfo);
