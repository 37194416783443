import {useContext, useState} from "react";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import {Warning} from "../../../hooks/imagePath";
import {useGetUserType} from "../../../hooks/useGetUserType";
import AuthService from "../../../services/auth";
import createTradeService from "../../../services/createTrade";
import {Loader} from "../../Common/Loader";
import {CreateTradeContext} from "../../Home/CreateTrade";
import NotVerificationPage from "../verificationOtpModal/notVerified";

const ConfirmSellModal = ({setOpenModal, setStep}) => {
  const {setPhoneEmailOtp} = useContext(CreateTradeContext);
  const {userData} = useSelector((state) => state.generalReducer);
  const [verified, setVerified] = useState();
  const [loading, setLoading] = useState(false);
  const {response: tradeResponse} = useSelector((state) => state.getTradeByIdReducer);
  const [checkValue, setCheckValue] = useState(false);
  const userType = useGetUserType(tradeResponse ?? {});

  const something = () => {
    if (userType === "user" && tradeResponse?.type === "sell") {
      if (userData?.data?.userData.authPreference === "email") {
        setLoading(true);
        AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false})
          .then((err) => {
            if (err) {
              setLoading(false);
              setPhoneEmailOtp("email");
              setStep(1);
            }
          })
          .catch(() => toast("Something went wrong"));
      } else if (userData?.data?.userData?.authPreference === "sms") {
        setLoading(true);
        createTradeService
          .tradePhoneAuth()
          .then((res) => {
            if (res.message === "success") {
              setLoading(false);
              setPhoneEmailOtp("phone");
              setStep(1);
            }
          })
          .catch((err) => {
            AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false})
              .then((err) => {
                if (err) {
                  setLoading(false);
                  setPhoneEmailOtp("email");
                  setStep(1);
                }
              })
              .catch(() => toast("Something went wrong"));
          });
      } else if (userData?.data?.userData?.authPreference === "google auth") {
        setPhoneEmailOtp("google auth");
        setStep(1);
      }
    } else if (userType === "user" && tradeResponse?.type === "buy") {
      setOpenModal(false);
    } else if (userType === "trader" && tradeResponse?.type === "buy") {
      if (userData?.data?.userData?.authPreference === "email") {
        setLoading(true);
        AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false}).then(
          (err) => {
            if (err) {
              setLoading(false);
              setPhoneEmailOtp("email");
              setStep(1);
            }
          }
        );
      } else if (userData?.data?.userData?.authPreference === "sms") {
        setLoading(true);
        createTradeService
          .tradePhoneAuth()
          .then((res) => {
            if (res.message === "success") {
              setLoading(false);
              setPhoneEmailOtp("phone");
              setStep(1);
            }
          })
          .catch(() => {
            AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false})
              .then((err) => {
                if (err) {
                  setLoading(false);
                  setPhoneEmailOtp("email");
                  setStep(1);
                }
              })
              .catch(() => toast("Something went wrong"));
          });
      } else if (userData?.data?.userData?.authPreference === "google auth") {
        setPhoneEmailOtp("google auth");
        setStep(1);
      }
    } else if (userType === "trader" && tradeResponse?.type === "sell") {
      setOpenModal(false);
    }
  };

  return (
    <div className="__createTrade__sell__modal__wrapper">
      {loading ? (
        <Loader style={{height: "280px"}} />
      ) : verified ? (
        <NotVerificationPage setVerified={setVerified} />
      ) : (
        <>
          <div className="__warning__holder">
            <img src={Warning} alt="warining" className="__warning__image" />
          </div>

          <p className="__are__you__sure">Are you sure you have received payment?</p>

          <span className="__click_to__accept">Click on box to accept</span>
          <div className="check__wrapper">
            <input type={"checkbox"} onChange={(e) => setCheckValue(e.target.checked)} />
            <p className="__terms">
              I accept that i have received the right amount. I agree to release the crypto asset to sender whose
              information matches the buyer’s verified profile on Oyola.{" "}
            </p>
          </div>
          <div className="__modal__button__wrapper">
            <button className="__cancel" onClick={() => setOpenModal(false)}>
              Cancel
            </button>
            <button className={`__confirm ${checkValue && "accept"}`} disabled={!checkValue} onClick={something}>
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export {ConfirmSellModal};
