import {CHANGE_PAGINATION} from "../types";

const initialState = {
  currentPage: 1,
  pageSize: 10,
};

const domPaginationReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CHANGE_PAGINATION: {
      return {
        ...state,
        currentPage: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default domPaginationReducer;
