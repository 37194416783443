import {
  TOKENLOCALCURRENCY,
  TOKENMARGIN,
  COINDATA,
  BUYDATA,
  SELLDATA,
  COUNTRYDATA,
  TIMEDATA,
  PAYMENTDATA,
  SKELETON_LOADER,
  IS_LOADING,
  POST_TRADE_SELL_ERROR,
  POST_TRADE_BUY_ERROR,
  POST_TRADE_DETAILED_FORM,
} from "../types";

const initialState = {
  isLoading: false,
  skeletonLoader: false,
  tokenLocalCurrencyData: {},
  tokenMarginData: 0,
  coinData: "",
  buyData: {},
  sellData: {},
  countryData: {},
  timeData: "15 Mins",
  paymentDataState: {},
  postTradeBuyError: "",
  postTradeSellError: "",
  postTradeDetailedForm: {},
};

const postTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case TOKENLOCALCURRENCY: {
      return {
        ...state,
        tokenLocalCurrencyData: payload,
      };
    }

    case POST_TRADE_SELL_ERROR: {
      return {
        ...state,
        postTradeSellError: payload,
      };
    }

    case POST_TRADE_BUY_ERROR: {
      return {
        ...state,
        postTradeBuyError: payload,
      };
    }

    case POST_TRADE_DETAILED_FORM: {
      return {
        ...state,
        postTradeDetailedForm: payload,
      };
    }

    case TOKENMARGIN: {
      return {
        ...state,
        tokenMarginData: payload,
      };
    }

    case COINDATA: {
      return {
        ...state,
        coinData: payload,
      };
    }

    case BUYDATA: {
      return {
        ...state,
        buyData: payload,
      };
    }

    case SELLDATA: {
      return {
        ...state,
        sellData: payload,
      };
    }

    case COUNTRYDATA: {
      return {
        ...state,
        countryData: payload,
      };
    }

    case TIMEDATA: {
      return {
        ...state,
        timeData: payload,
      };
    }

    case PAYMENTDATA: {
      return {
        ...state,
        paymentDataState: payload,
      };
    }

    case SKELETON_LOADER: {
      return {
        ...state,
        skeletonLoader: payload,
      };
    }

    case IS_LOADING: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default postTradeReducer;
