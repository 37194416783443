import moment from "moment";
import store from "../store";
import {allowAccess, loginAction} from "../store/actions/auth";
import APIs from "./api";

const AuthService = {
  verifyEmailSecurely: async (param) => {
    const result = APIs.post("api/v1/user/verify-email-securely", param)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },

  verifyPhoneNumber: async (param) => {
    const result = APIs.post("api/v1/user/verify-phone-number ", param).then((data) => {
      return data.data;
    });
    return result;
  },

  login: async (param) => {
    return APIs.post("api/v1/user/login", param).then((data) => {
      if (data?.data?.message === "success") {
        setHeaders(data);
        return data;
      }
    });
  },

  validateLogin: async (param) => {
    return APIs.post("api/v1/user/login", param).then((data) => {
      if (data?.data?.message === "success") {
        setHeaders(data);
        return data;
      }
    });
  },

  register: async (param) => {
    return APIs.post("api/v1/user/register", param).then((data) => {
      if (data?.data?.message === "success") {
        setHeaders(data);
        return data;
      }
    });
  },

  changePassword: async (param) => {
    return APIs.post("/api/v1/user/change-password", param).then((data) => {
      return data;
    });
  },

  userDataForgotPassword: async (param) => {
    return APIs.post("api/v1/user/user-details", param).then((data) => {
      return data.data;
    });
  },

  profileData: async (param) => {
    return APIs.post("api/v1/profile/profile-data", param).then((data) => {
      return data.data;
    });
  },
};

const setHeaders = (param) => {
  const date = moment();
  APIs.defaults.headers["Authorization"] = `Bearer ${param.data.token}`;
  store.dispatch(loginAction(param.data.token));
  localStorage.setItem("token", param.data.token);
  localStorage.setItem("notification_increase", 0);

  localStorage.setItem("userDataBalance", JSON.stringify({date: date}));
  localStorage.setItem("isLoggedIn", true);
  store.dispatch(allowAccess(true));
};

export default AuthService;
// module.exports = AuthService;
