import {useEffect} from "react";
import {useCallback} from "react";
import {useState} from "react";
import {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import EditPostTradeService from "../../services/editPostTradeService";
import {
  getCountryDetails,
  getOrderStatus,
  getSingleUserCurrencyDetails,
  getSingleUserOrder,
} from "../../store/actions/editPostTradeAction";
import {EDITTRADEDISABLE, PROFITMARGIN} from "../../store/types";
import {Loader} from "../Common/Loader";
import EditTradeComponents from "./editTradeComponents";

function EditTradePage() {
  const dispatch = useDispatch();
  const {tradeId} = useParams();
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const isLoading = useSelector((state) => state.postTradeReducer.isLoading);
  const postTradeDetailedForm = useSelector((state) => state.postTradeReducer.postTradeDetailedForm);
  const [userOrder, setUserOrder] = useState({});

  const getSingleTradePost = useCallback(
    (data, countryData) => {
      if (Object.keys(countryData).length > 0) {
        EditPostTradeService.SinglePostTrade({_id: data}).then((data) => {
          dispatch(getSingleUserOrder(data.userData));
          dispatch(getSingleUserCurrencyDetails(data.currencyDetails));

          dispatch({type: PROFITMARGIN, payload: data.userData.profitMargin});

          dispatch(
            getOrderStatus(
              data.userData.status === "active" ? {name: "active", checker: true} : {name: "inactive", checker: false}
            )
          );

          setUserOrder(data);
        });
        dispatch({type: EDITTRADEDISABLE, payload: true});
      }
    },
    [dispatch]
  );

  useEffect(() => {
    getSingleTradePost(tradeId, countryData);
  }, [getSingleTradePost, tradeId, countryData]);

  return (
    <>
      {Object.keys(userOrder).length <= 1 && <Loader />}
      {isLoading && <Loader />}
      {Object.keys(userOrder).length > 1 && !isLoading && <EditTradeComponents />}
    </>
  );
}

export default memo(EditTradePage);
