import {useContext} from "react";
import {useSelector} from "react-redux";
import {TradeOrderContext} from "../../Home/TradeOrders";
import AllTradeOrders from "./allTrades";

const TheTradeOrderTable = () => {
  const {loading, response} = useSelector((state) => state.tradeOrdersReducer);
  return <AllTradeOrders data={response} loading={loading} />;
};

export default TheTradeOrderTable;
