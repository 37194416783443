import React, {memo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {getUpdatePhoneNumber, settingVerifyModal} from "../../store/actions/settingAction";

import iconBack from "../../assets/icons/icon-back.svg";
import iconCancel from "../../assets/icons/icon-cancel.svg";
// import OtpInput from "react-otp-input";
// import SettingsService from "../../services/settingsServices";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
// import GeneralService from "../../services/general";
// import {getUser} from "../../store/actions/general";
import OptInputField from "./optInputField";
import {Loader4} from "../Common/Loader";
import {toast} from "react-toastify";
import SettingsService from "../../services/settingsServices";
import GeneralService from "../../services/general";
import {getUser} from "../../store/actions/general";

const SmsSecondModal = () => {
  // toggle to next modal
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.generalReducer.userData);

  const userDetailsForUpdatingPhoneNumber = useSelector(
    (state) => state.settingsReducer.userDetailsForUpdatingPhoneNumber
  );

  const [errorMessage, setErrorMessage] = useState("");

  const errorUpdatingPhoneNumber = useSelector((state) => state.settingsReducer.errorUpdatingPhoneNumber);

  const [emailOtp, setEmailOtp] = useState("");

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
  }

  function goBackButton() {
    dispatch(settingVerifyModal(12));
  }

  const handleGoogleChange = (otp) => {
    setEmailOtp(otp);
  };

  function nextButton() {
    setIsLoading(true);

    const updateDetails = {
      phoneNumber: userDetailsForUpdatingPhoneNumber?.phoneNumber,
      emailCode: emailOtp,
      authType: "email",
    };

    SettingsService.UpdatePhone(updateDetails)
      .then((data) => {
        if (data.message === "success") {
          GeneralService.getUserData().then((data) => {
            dispatch(getUser(data ?? {}));
            if (!data?.data?.userData?.phoneNumber) {
              dispatch(getUpdatePhoneNumber(""));
            } else {
              dispatch(getUpdatePhoneNumber(data?.data?.userData?.phoneNumber));
            }

            userData?.data?.userData?.phoneNumberVerified === true
              ? toast("Phone number has been changed", {theme: "colored"})
              : toast("Phone number has been added", {theme: "colored"});
            dispatch(settingVerifyModal(null));
            dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
            dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
            setIsLoading(false);
          });
          setIsLoading(false);
        } else {
          setErrorMessage(data.message);
          toast.error(data.message, {theme: "colored"});
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error, {theme: "colored"});
        setIsLoading(false);
      });
  }

  const formatPhoneNumber = (data) => {
    const stringFormat = data ? `${data?.slice(0, 6)}***${data?.slice(-3, data?.length)}` : "";
    return stringFormat;
  };

  return (
    <main className="modal-container" data-aos="zoom-in-down" style={{height: "340px", width: "400px"}}>
      <div className="body">
        <div className="top-section-modal-body" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <div className="cancel-container">
            <img src={iconBack} alt="back-icon" onClick={goBackButton} />
            <img
              src={iconCancel}
              alt="back-cancel"
              onClick={() => {
                dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                cancelButton();
              }}
            />
          </div>
        </div>

        <div className="second-container">
          <div className="TT">
            <p style={{fontSize: "1.8rem", color: "#101828", marginBottom: "1.5rem", fontWeight: "600"}}>
              Security Verification
            </p>
            <OptInputField
              // tagStyle={{marginBottom: "3rem"}}
              titleStyle={{marginBottom: "0.5rem"}}
              tagStyle={{margin: "0rem 0rem 1rem"}}
              tagDescStyle={{margin: "0rem"}}
              value={emailOtp}
              onChange={handleGoogleChange}
              tag={formatPhoneNumber(userDetailsForUpdatingPhoneNumber?.email)}
              title="Please Verify your Email Code"
              // marginTop="3rem"
              marginBottom="1.5rem"
              error={errorMessage ? errorMessage : errorUpdatingPhoneNumber ? errorUpdatingPhoneNumber : ""}
              hasErrored={errorMessage ? true : false}
            />
          </div>
        </div>
      </div>

      <div className="footer-modal" style={{margin: "0rem 2.5rem 3rem"}}>
        <div className="button-container">
          <button style={{...buttonStyle, ...cancelButtonStyle}} className="base-button" onClick={cancelButton}>
            Cancel
          </button>
          <button
            className="base-button"
            style={buttonStyle}
            onClick={nextButton}
            disabled={emailOtp.length >= 6 ? false : true}
          >
            {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Confirm</p>}
          </button>
        </div>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  width: "100%",
  height: "40px",
};

const cancelButtonStyle = {
  background: "transparent",
  border: "1px solid gray",
  color: "black",
};

export default memo(SmsSecondModal);
