import moment from "moment";
import {useRef, useState} from "react";
import {DateRange} from "react-date-range";
import {AiOutlineCalendar} from "react-icons/ai";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import "../../styles/dateRangeComponent.scss";
const DataRangeComponent = ({onClickOkay, style}) => {
  const calenderRef = useRef();
  const [showDateRange, setShowDateRange] = useState(false);
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  const [dateState, setDateState] = useState([
    {
      startDate: new Date(),
      endDate: null,
      key: "selection",
    },
  ]);
  const handleClick = () => {
    if (showSelectCondition === false) {
      setShowDateRange(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setShowDateRange(false);
      setShowSelectCondition(false);
    }
  };

  useOnClickOutside(calenderRef, () => setShowDateRange(false));

  return (
    <div className="date__range" style={style}>
      <div className="dateRange__button " onClick={() => handleClick()}>
        <span size={18} color="black" className="dateRange__button__icon">
          Date:{" "}
        </span>{" "}
        Select date
      </div>
      {showDateRange && (
        <div className="__theDateComponent" ref={calenderRef} data-aos="zoom-in" data-aos-duration="200">
          <DateRange
            editableDateInputs={false}
            onChange={(item) => setDateState([item.selection])}
            moveRangeOnFirstSelection={true}
            ranges={dateState}
            color="#7f56d9"
            rangeColors={["#7f56d9"]}
            startDatePlaceholder="Start Date"
            endDatePlaceholder="End Date"
            maxDate={new Date()}
          />
          <div className="dateRange__ButtonWrapper" style={{position: "absolute", zIndex: 500, bottom: "-14%"}}>
            <button
              className="dateRange__Button __cancel"
              onClick={() => {
                onClickOkay(null);
                setShowDateRange(false);
                setDateState([new Date()]);
              }}
            >
              cancel
            </button>
            <button
              className="dateRange__Button __okay"
              onClick={() => {
                onClickOkay(dateState);
                setShowDateRange(false);
              }}
            >
              okay
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DataRangeComponent;
