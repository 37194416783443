import React, {memo} from "react";
import action from "../../assets/icons/action.svg";
import BuyCrypto from "../HomeTopBar/BuyCrypto";
import SellCrypto from "../HomeTopBar/SellCrypto";
import {useHistory} from "react-router-dom";
import ProfileAvatar from "../HomeTopBar/ProfileAvatar";
import Balance from "../HomeTopBar/Balance";
import NotificationIcon from "../HomeTopBar/NotificationIcon";
import {useSelector} from "react-redux";
import IconButton from "../HomeTopBar/IconButton";

import {Skeleton} from "@mui/material";
import "../../styles/home.scss";
import "../../styles/claimStyle.scss";
import ClaimUsdt from "../HomeTopBar/ClaimUsdt";

const HomeTopBar = ({isOpen}) => {
  const history = useHistory();
  const locationDatas = useSelector((state) => state.generalReducer.locationData);
  const balanceUserData = useSelector((state) => state.generalReducer.userData);

  const balance = balanceUserData?.data?.hasOwnProperty("userData") ? balanceUserData.data.userData : {};

  const route = (link) => {
    history.push(link);
  };

  const verifyButton = () => {
    if (balance.isVerified) {
      return null;
    } else {
      return (
        <button className="buttonStyleOnRightSideOn" onClick={() => route("/app/settings")}>
          <img style={{width: "12px", height: "14px"}} src={action} alt="icon" />
          <span className="buttonStyleOnRightSideOnTitleStyle">Verify your account</span>
        </button>
      );
    }
  };

  return (
    <React.Fragment>
      <div
        className="top-bar"
        style={{backgroundColor: process.env.REACT_APP_ENV === "PRODUCTION" ? "#d0d5dd26" : "#FFF5DD"}}
      >
        <div className="child-parent">
          <div className="left-container">
            <BuyCrypto isOpen={isOpen} />
            <SellCrypto isOpen={isOpen} />
            <IconButton
              buttonTitle="Post Order"
              // bgColor="#FFFDEC"
              bgColor="#FFFFFF"
              onClick={() => route(`/app/post-trade/buy/btc/${locationDatas?.data?.geoData?.countryName}`)}
              isOpen={isOpen}
            />
            <IconButton
              buttonTitle="My Orders"
              // bgColor="#F0FFEF"
              bgColor="#FFFFFF"
              onClick={() => route(`/app/trade-orders`)}
              isOpen={isOpen}
            />
          </div>

          <div className="right-container">
            {/* <ClaimUsdt /> */}
            {!balanceUserData?.data?.userData ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                className="buttonStyleOnRightSide"
                // style={{
                //   ...postTrade,
                //   width: "200px",
                //   marginRight: "10px",
                // }}
              />
            ) : (
              <Balance />
            )}
            {!balanceUserData?.data?.userData ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                className="buttonStyleOnRightSide"
                // style={{
                //   ...postTrade,
                //   width: "200px",
                //   marginRight: "10px",
                // }}
              />
            ) : (
              verifyButton()
            )}

            <NotificationIcon />

            <ProfileAvatar />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

const postTrade = {
  width: "130px",
  height: "40px",
  fontSize: "12px",
  background: "#fff",
  marginLeft: "10px",
  color: "#000",
  boxShadow: "none",
  borderRadius: "15px",
  textTransform: "capitalize",
  border: "1px solid #f8f9fa",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "&:hover": {
    background: "#fff",
    color: "#000",
    boxShadow: "none",
  },
};

// onClick={() => route(`/profile/${balanceUserData?.data?.userData?.username}`)}

export default memo(HomeTopBar);
