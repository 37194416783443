import React, {memo} from "react";
import {useDispatch} from "react-redux";

import {settingVerifyModal} from "../../store/actions/settingAction";
import VerifyPhoneNumber from "./VerifyPhoneNumber";
import "../../styles/new/settings.scss";

import iconCancel from "../../assets/icons/icon-cancel.svg";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
const FirstTimePhoneNumberModal = () => {
  // toggle to next modal

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(15));
  }

  return (
    <main
      className="modal-container"
      data-aos="zoom-in-down"
      style={{height: "250px", width: "400px", padding: "0rem 0.5rem"}}
    >
      <div className="body">
        <div className="top-section-modal-body" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <div className="cancel-container">
            <img
              src={iconCancel}
              alt="back-cancel"
              onClick={() => {
                dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                cancelButton();
              }}
            />
          </div>
          <p className="title" style={{textAlign: "center", marginBottom: "1rem"}}>
            Add Phone Number
          </p>
          <p className="tag">Phone Number Verification</p>
        </div>
        <div className="first-container" style={{marginBottom: "2rem"}}>
          <VerifyPhoneNumber />
        </div>
      </div>
    </main>
  );
};

export default memo(FirstTimePhoneNumberModal);
