import {RATE_TRADE, RATE_TRADE_DEFAULT, RATE_TRADE_ERROR, RATE_TRADE_INITIAL} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const rateTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case RATE_TRADE: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case RATE_TRADE_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case RATE_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case RATE_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default rateTradeReducer;
