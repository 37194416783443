import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Warning} from "../../../hooks/imagePath";
import {cancelTradeByIDAction} from "../../../store/actions/createTradeAction";
import {getTradeStatusByIDAction} from "../../../store/actions/getTradeStatus";
import {Loader} from "../../Common/Loader";

const CancelModal = ({setOpenModal}) => {
  const dispatch = useDispatch();
  const {loading, response} = useSelector((state) => state.cancelTradeReducer);
  const {id} = useParams();
  const [checkValue, setCheckValue] = useState(false);

  const handleCancel = () => {
    dispatch(cancelTradeByIDAction({trade_id: id}));
    // dispatch(getTradeStatusByIDAction({id}));
    // dispatch(getBuySellTradeByIDActionAdvanced({id}));
  };

  useEffect(() => {
    if (response?.message === "success") {
      setOpenModal(false);
      dispatch(getTradeStatusByIDAction({id}));
    }
  }, [response]);

  return (
    <div className="__createTrade__sell__modal__wrapper">
      {loading ? (
        <Loader style={{height: "280px"}} />
      ) : (
        <>
          <div className="__warning__holder">
            <img src={Warning} alt="warining" className="__warning__image" />
          </div>

          <p className="__are__you__sure">Are you sure you want to cancel</p>

          <span className="__click_to__accept">Click on box to accept</span>
          <div className="check__wrapper">
            <input type={"checkbox"} onChange={(e) => setCheckValue(e.target.checked)} />
            <p className="__terms">Cancelling Transaction removes the transaction completely. </p>
          </div>
          <div className="__modal__button__wrapper">
            <button className="__cancel" onClick={() => setOpenModal(false)}>
              Cancel
            </button>
            <button className={`__confirm ${checkValue && "accept"}`} disabled={!checkValue} onClick={handleCancel}>
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export {CancelModal};
