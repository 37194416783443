import React, {memo} from "react";
import ListInfo from "./ListInfo";
import VerificationButton from "./VerificationButton";
import {MdVerified} from "react-icons/md";
import "../../styles/new/settings.scss";
import {useSelector} from "react-redux";
import {Skeleton} from "@mui/material";

const PersonalInformation = () => {
  const modalInfo = useSelector((state) => state.generalReducer.userData);
  const verifyme = modalInfo ?? "";

  return (
    <section className="personal-information-container">
      <div className="top-section">
        <h1 className="settingsTitle title">Personal Verification</h1>
        <p className="settingsTitle tag">verify your account</p>
      </div>

      <main className="container">
        <div className="left-container">
          <article>
            <div className="inner-container">
              {!modalInfo?.data?.userData ? (
                <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
              ) : (
                <ListInfo
                  text="Personal Information"
                  icon={
                    verifyme?.data?.userData.hasOwnProperty("firstName") &&
                    verifyme?.data?.userData?.firstName !== null &&
                    verifyme?.data?.userData?.firstName !== "" ? (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                    ) : (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                    )
                  }
                />
              )}

              {!modalInfo?.data?.userData ? (
                <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
              ) : (
                <ListInfo
                  text="Address Verification"
                  icon={
                    verifyme?.data?.userData.hasOwnProperty("address") &&
                    verifyme?.data?.userData?.address !== null &&
                    verifyme?.data?.userData?.address !== "" ? (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                    ) : (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                    )
                  }
                />
              )}

              {!modalInfo?.data?.userData ? (
                <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
              ) : (
                <ListInfo
                  text="Email Verification"
                  icon={
                    verifyme?.data?.userData.email !== "" ? (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                    ) : (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                    )
                  }
                />
              )}

              {!modalInfo?.data?.userData ? (
                <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
              ) : (
                <ListInfo
                  text="Phone Number"
                  icon={
                    verifyme?.data?.userData.hasOwnProperty("phoneNumber") ? (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                    ) : (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                    )
                  }
                />
              )}

              {!modalInfo?.data?.userData ? (
                <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
              ) : (
                <ListInfo
                  text="ID document upload"
                  icon={
                    verifyme?.data?.userData.hasOwnProperty("isVerified") &&
                    verifyme?.data?.userData?.isVerified === true ? (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                    ) : (
                      <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                    )
                  }
                />
              )}
            </div>

            <div className="inner-container button-container">
              <VerificationButton />
            </div>
          </article>
        </div>
      </main>
    </section>
  );
};

const SkeletonStyle = {
  fontSize: "2.5rem",
  fontWeight: "600",
  marginBottom: "2rem",
  background: "rgba(255,255,255,1)",
  width: "200px",
};

export default memo(PersonalInformation);
