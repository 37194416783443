import React, {memo} from "react";
import {useDispatch} from "react-redux";
import {settingVerifyModal} from "../../store/actions/settingAction";
// import {getGoogleAuthProcessModals, getGoogleAuthQRCode} from "../../store/actions/settingAction";
// import SettingsService from "../../services/settingsServices";

import iconCancel from "../../assets/icons/icon-cancel.svg";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
import "../../styles/new/settings.scss";
import "../../styles/new/firstTimeUserVerification.scss";
const FirstTimeUserModal = () => {
  // toggle to next modal

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
  }
  function phoneButton() {
    dispatch(settingVerifyModal(16));
  }

  return (
    <main
      className="modal-container"
      data-aos="zoom-in-down"
      style={{height: "250px", width: "400px", padding: "0rem 0.5rem"}}
    >
      <div className="body">
        <div className="top-section-modal-body" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <div className="cancel-container">
            {/* <img
              src={iconCancel}
              alt="back-cancel"
              onClick={() => {
                dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                cancelButton();
              }}
            /> */}
          </div>
          <p className="title" style={{textAlign: "center", marginBottom: "1rem"}}>
            Set up Two-Factor Authentication (2FA)
          </p>
          <p className="tag">Please add a valid phone number to start your account verification process</p>
        </div>
        <div
          style={{
            marginBottom: "1rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
        <div
          className="first-container"
          style={{
            marginTop: "3.5rem",
            marginBottom: "0rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <button className="button__styles_cancel" onClick={cancelButton}>
            Cancel
          </button>

          <button className="button__styles" onClick={phoneButton}>
            Phone Number
          </button>
        </div>
      </div>
    </main>
  );
};

export default memo(FirstTimeUserModal);
