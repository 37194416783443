import React, {memo, useState} from "react";
import {useSelector} from "react-redux";
import CustomCheckbox from "../Common/CustomCheckbox";

const BuyDresser = () => {
  const singleUserOrder = useSelector((state) => state.editTradeReducer.userOrders);

  return (
    <div>
      <CustomCheckbox edited={true} state={singleUserOrder.type === "buy" ? {checker: true} : {checker: false}} />
    </div>
  );
};

export default memo(BuyDresser);
