import {Skeleton} from "@mui/material";
import React, {useMemo} from "react";
import {MdVerified} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import SettingsService from "../../services/settingsServices";
import {getGoogleAuthProcessModals, getGoogleAuthQRCode} from "../../store/actions/settingAction";
import "../../styles/button.scss";
import "../../styles/new/settings.scss";
import GoogleProcessModalSection from "./openingModalSection";
import UseGoogleAuth from "./useGoogleAuth";
import UsePhoneNumber from "./usePhoneNumber";
import PhoneNumberButton from "./phoneNumberButton";
import CustomSelectedCheckbox from "../Common/CustomSelectedCheckbox";

const Security = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);

  // const modalInfo = useSelector((state) => state.generalReducer.userData);

  const verifyme = userData ?? "";
  const userDetails = userData?.data?.userData ?? "";
  const settingLoaderSkeleton = useSelector((state) => state.settingsReducer.settingLoaderSkeleton);

  const GetQRCodeFromGoogle = () => {
    dispatch(getGoogleAuthProcessModals({num: 0}));
    SettingsService.GoogleAuthGetQRCode().then((data) => {
      dispatch(getGoogleAuthQRCode(data));
      dispatch(getGoogleAuthProcessModals({num: 1}));
    });
  };

  const storePhoneNumber = verifyme?.data?.userData?.phoneNumber ?? "";

  const sliceFirst = storePhoneNumber.slice(-4);

  useMemo(() => userData, [userData]);

  return (
    <section className="security-container">
      <div className="top-section">
        <h1 className="settingsTitle title">Security </h1>
        <p className="settingsTitle tag">Two-Factor Authentication (2FA)</p>
      </div>

      <main className="container">
        <div className="left-container">
          {Object.keys(userData).length === 0 || settingLoaderSkeleton ? (
            <Skeleton animation="wave" variant="rectangular" width={310} style={SkeletonStyle2} />
          ) : (
            <div className="first-inner-container">
              <i style={{marginRight: "1rem"}}>
                <UsePhoneNumber />
              </i>
              <div>
                <p className="title">Phone Number Verfication</p>
                <p className="tag">Protect your account and transactions</p>
              </div>
            </div>
          )}
          <div className="not-verified">
            {!userDetails ? (
              <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
            ) : verifyme?.data?.userData?.phoneNumber ? (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                </i>
                <p style={{fontSize: "1.2rem"}}>***{sliceFirst}</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                </i>
                <p style={{fontSize: "1.2rem"}}>No Number</p>
              </React.Fragment>
            )}
          </div>
          {!userDetails ? (
            <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
          ) : (
            <PhoneNumberButton />
          )}
        </div>

        <div className="left-container">
          {Object.keys(userData).length === 0 || settingLoaderSkeleton ? (
            <Skeleton animation="wave" variant="rectangular" width={310} style={SkeletonStyle2} />
          ) : (
            <div className="first-inner-container">
              <i style={{marginRight: "1rem"}}>
                <UseGoogleAuth />
              </i>
              <div>
                <p className="title">Google Authenticator</p>
                <p className="tag">Protect your account and transactions</p>
              </div>
            </div>
          )}
          <div className="not-verified">
            {!userDetails ? (
              <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
            ) : userDetails.googleAuthSetup ? (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                </i>
                <p style={{fontSize: "1.2rem"}}>Linked</p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                </i>
                <p style={{fontSize: "1.2rem"}}>Not linked</p>
              </React.Fragment>
            )}
          </div>

          {!userDetails ? (
            <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
          ) : (
            <button
              disabled={userDetails?.googleAuthSetup ? true : false}
              onClick={GetQRCodeFromGoogle}
              className="styleSecurityButton"
            >
              Set up
            </button>
          )}
        </div>

        <div className="left-container">
          <div className="first-inner-container">
            {!userDetails ? (
              <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
            ) : (
              <>
                <i style={{marginRight: "1rem"}}>
                  <CustomSelectedCheckbox state={{checker: true}} />
                </i>
                <div>
                  <p className="title">Email Address Verification</p>
                  <p className="tag">Protect your account and transactions</p>
                </div>
              </>
            )}
          </div>
          <div className="not-verified">
            {!userDetails ? (
              <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
            ) : userDetails.hasOwnProperty("email") ? (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#00A478" />
                </i>
                <p style={{fontSize: "1.2rem"}}>
                  {userDetails.email.slice(0, 2)}***{userDetails.email.slice(-8)}
                </p>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <i style={{marginRight: "1rem"}}>
                  <MdVerified fontSize={17} style={{marginTop: 3}} fill="#B7BABE" />
                </i>
                <p style={{fontSize: "1.2rem"}}>Not Linked</p>
              </React.Fragment>
            )}
          </div>

          {!userDetails ? (
            <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
          ) : (
            <button
              className="styleSecurityButton"
              style={{...buttonStyle, border: "none", boxShadow: "none", background: "white"}}
            ></button>
          )}
        </div>
      </main>
      <GoogleProcessModalSection />
    </section>
  );
};
const buttonStyle = {
  // padding: "1rem 2rem",
  borderRadius: "8px",
  // background: "white",
  // color: "gray",
  width: "7.5rem",
  height: "auto",
};

const SkeletonStyle = {
  fontSize: "2.5rem",
  fontWeight: "600",
  marginBottom: "2rem",
  background: "rgba(255,255,255,1)",
  width: "200px",
};

const SkeletonStyle2 = {
  fontWeight: "600",
  marginBottom: "2.8rem",
  background: "rgba(255,255,255,1)",
};
export default Security;
