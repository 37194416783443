import React, {memo, useMemo, useState} from "react";
import "../../styles/login.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  loginDetails,
  registerLoader,
  registerErrorDetailsForEmail,
  displayUsernameNotAvailableAction,
} from "../../store/actions/auth";
import {Link, useHistory} from "react-router-dom";
import {Validators} from "./helper";
import {USERNAME_REGEX, EMAIL_REGEX, PWD_REGEX} from "../../utils/Validators";
import {NewFormInput} from "../Common/NewFormInput";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {HOLD_USER_DETAILS_REGISTER} from "../../store/types";
import ShowErrorMessageLogin from "../Common/ShowErrorMessageLogin";
import OyolaPuzzleSecurityComponent from "../Login/OyolaPuzzleSecurityComponent";
import axios from "axios";
// import CheckBox from "../Common/Checkbox";
const RegisterForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validUsername, setValidUsername] = useState(false);
  const [validEmail, setValidEmail] = useState(false);
  const [validPassword, setValidPassword] = useState(false);
  const [hidden, setHidden] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [showUsernameError, setShowUsernameError] = useState("");
  const [showEmailError, setShowEmailError] = useState("");
  const [showPasswordError, setShowPasswordError] = useState("");
  const [showCheckError, setShowCheckError] = useState("");
  const errorEmail = useSelector((state) => state.authReducer.registerErrorDetailForEmail);
  const errorUsername = useSelector((state) => state.authReducer.displayUsernameNotAvailable);
  const {holdUserDetailsRegister} = useSelector((state) => state.authReducer);

  const route = () => {
    history.push("/");
  };

  useMemo(() => {
    if (isChecked) {
      setShowCheckError("");
    }
  }, [isChecked]);

  const validateCredentials = (e) => {
    if (
      holdUserDetailsRegister.hasOwnProperty("username") ||
      holdUserDetailsRegister.hasOwnProperty("email") ||
      holdUserDetailsRegister.hasOwnProperty("password")
    ) {
      setValidUsername(USERNAME_REGEX.test(holdUserDetailsRegister?.username));
      setValidEmail(EMAIL_REGEX.test(holdUserDetailsRegister?.email));
      setValidPassword(PWD_REGEX.test(holdUserDetailsRegister?.password));
    }

    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "username": {
        dispatch({type: HOLD_USER_DETAILS_REGISTER, payload: {...holdUserDetailsRegister, username: e.target.value}});

        if (e.target.value) {
          if (e.target.value.length >= 5) {
            setValidUsername(USERNAME_REGEX.test(e.target.value));
            setShowUsernameError("");
          } else {
            setValidUsername(false);
            setShowUsernameError("username must be greater than 5 characters");
          }
        } else {
          setShowUsernameError("Please enter your username");
        }

        break;
      }

      case "email": {
        dispatch({type: HOLD_USER_DETAILS_REGISTER, payload: {...holdUserDetailsRegister, email: e.target.value}});

        if (e.target.value) {
          if (EMAIL_REGEX.test(e.target.value)) {
            setValidEmail(EMAIL_REGEX.test(e.target.value));
            setShowEmailError("");
          } else {
            setValidEmail(false);
            setShowEmailError("Invalid email address. Only use numbers, letters, or +-_ characters.");
          }
        } else {
          setShowEmailError("Please enter your email");
        }

        break;
      }

      case "password": {
        dispatch({type: HOLD_USER_DETAILS_REGISTER, payload: {...holdUserDetailsRegister, password: e.target.value}});

        if (e.target.value) {
          if (PWD_REGEX.test(e.target.value)) {
            setValidPassword(PWD_REGEX.test(e.target.value));
            setShowPasswordError("");
          } else {
            setValidPassword(false);
            setShowPasswordError(
              "Password must be at least 8 characters with 1 upper case letter, 1 number and a special character."
            );
          }
        } else {
          setShowPasswordError("Please enter your password");
        }

        break;
      }

      default: {
        break;
      }
    }
  };

  const captchaConfig = {
    config: {
      captchaId: "149c020945a96804c4e50acd59aabf8b",
      language: "eng",
      product: "bind",
      protocol: "https://",
    },
    handler: (captchaObj) => {
      window.captchaObj = captchaObj;
      captchaObj
        .appendTo("#captcha")
        .onReady(function () {})
        .onNextReady(function () {})
        .onBoxShow(function () {})
        .onError(function (e) {})
        .onSuccess(() => {
          setShowCheckError(false);
          dispatch(registerLoader(1));

          async function validate() {
            let validationInstance = Validators.getValidationService(
              captchaObj?.accountDetails?.username,
              captchaObj?.accountDetails?.email
            );
            let usernameData = await validationInstance.validateUsername();
            let emailData = await validationInstance.validateEmail();

            usernameData.status === 200 || dispatch(displayUsernameNotAvailableAction("username is already taken"));
            emailData.status === 200 || dispatch(registerErrorDetailsForEmail("email is already taken"));

            if (usernameData.status === 200) {
              // dispatch(displayUsernameNotAvailableAction("available"));
              dispatch(displayUsernameNotAvailableAction(""));
              if (emailData.status === 200) {
                dispatch(displayUsernameNotAvailableAction(""));
                dispatch(registerErrorDetailsForEmail(""));
                // dispatch(registerErrorDetailsForEmail("email is available"));
                const payload = {
                  oneTimeCode: emailData.data.oneTimeCode,
                  username: captchaObj?.accountDetails?.username,
                  email: captchaObj?.accountDetails?.email,
                  password: captchaObj?.accountDetails?.password,
                };

                dispatch(registerLoader(2));
                dispatch(loginDetails(payload));
                dispatch(registerErrorDetailsForEmail(""));
              } else {
                dispatch(registerLoader(0));
                // dispatch(displayUsernameNotAvailableAction(""));
                // dispatch(registerErrorDetailsForEmail(""));
              }
            } else {
              dispatch(registerLoader(0));
              // dispatch(displayUsernameNotAvailableAction(""));
            }
          }
          validate();
        });
    },
  };

  const Geelogin = () => {
    if (captchaConfig.config.product === "bind") {
      if (window.captchaObj) {
        window.captchaObj.accountDetails = {...holdUserDetailsRegister};
        window.captchaObj.showCaptcha();
      } else {
        alert("Please wait for the verification initialization to complete");
        return false;
      }
    } else {
      Geevalidate();
    }
  };

  const Geevalidate = () => {
    var result = window.captchaObj.getValidate();
    if (!result) {
      alert("Please complete verification first!");
      return;
    }

    axios({
      method: "get",
      url: "/login",
      params: Object.assign(result, {
        captcha_id: "149c020945a96804c4e50acd59aabf8b",
      }),
    }).then((res) => {
      if (res.data.result) {
        alert(res.data.result);
      }
    });
  };

  const verifyAndGoToOTP = () => {
    if (validUsername) {
      setShowUsernameError(false);
      if (validEmail) {
        setShowEmailError(false);
        if (validPassword === true) {
          setShowPasswordError(false);
          if (isChecked) {
            setShowCheckError(false);
            // dispatch(registerLoader(1));
            // validate();
            Geelogin();
          } else {
            setShowCheckError("You need to accept our terms and privacy policy to create an account.");
          }
        } else {
          setShowPasswordError("Please enter your password");
          //failed password
        }
      } else {
        setShowEmailError("Please enter your email");
        //failed email
      }
    } else {
      setShowUsernameError("Please enter your username");
      //failed username
    }
  };

  async function validate() {
    let validationInstance = Validators.getValidationService(
      holdUserDetailsRegister?.username,
      holdUserDetailsRegister?.email
    );
    let usernameData = await validationInstance.validateUsername();
    let emailData = await validationInstance.validateEmail();

    usernameData.status === 200 || dispatch(displayUsernameNotAvailableAction("username is already taken"));
    emailData.status === 200 || dispatch(registerErrorDetailsForEmail("email is already taken"));

    if (usernameData.status === 200) {
      // dispatch(displayUsernameNotAvailableAction("available"));
      dispatch(displayUsernameNotAvailableAction(""));
      if (emailData.status === 200) {
        dispatch(displayUsernameNotAvailableAction(""));
        dispatch(registerErrorDetailsForEmail(""));
        // dispatch(registerErrorDetailsForEmail("email is available"));
        const payload = {
          oneTimeCode: emailData.data.oneTimeCode,
          username: holdUserDetailsRegister.username,
          email: holdUserDetailsRegister.email,
          password: holdUserDetailsRegister.password,
        };
        Geelogin();
        dispatch(registerLoader(2));
        dispatch(loginDetails(payload));
        dispatch(registerErrorDetailsForEmail(""));
      } else {
        dispatch(registerLoader(0));
        // dispatch(displayUsernameNotAvailableAction(""));
        // dispatch(registerErrorDetailsForEmail(""));
      }
    } else {
      dispatch(registerLoader(0));
      // dispatch(displayUsernameNotAvailableAction(""));
    }
  }

  return (
    <React.Fragment>
      <div className="login-container">
        <div className="login-content">
          <div className="form-contents">
            <p className="title">Create an account</p>
            <div style={{width: "100%"}}>
              <NewFormInput
                data-testid="username"
                margin={"30px"}
                id={"username"}
                type={"text"}
                label={"Username"}
                value={holdUserDetailsRegister?.username || ""}
                onChange={validateCredentials}
                displayError={showUsernameError || errorUsername}
              />

              <ShowErrorMessageLogin id="showUsernameError" value={showUsernameError} />
              <ShowErrorMessageLogin id="errorUsername" value={errorUsername} />
            </div>

            <div style={{width: "100%"}}>
              <NewFormInput
                data-testid="email"
                margin={"10px"}
                id={"email"}
                label={"Email"}
                type={"email"}
                value={holdUserDetailsRegister?.email || ""}
                onChange={validateCredentials}
                displayError={showEmailError || errorEmail}
              />

              <ShowErrorMessageLogin id="showEmailError" value={showEmailError} />
              <ShowErrorMessageLogin id="errorEmail" value={errorEmail} />
            </div>

            <div style={{width: "100%"}}>
              <NewFormInput
                data-testid="password"
                margin={"10px"}
                id={"password"}
                label={"Password"}
                type={hidden ? "text" : "password"}
                value={holdUserDetailsRegister?.password || ""}
                onChange={validateCredentials}
                displayError={showPasswordError}
                icon={
                  hidden ? (
                    <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                  ) : (
                    <AiOutlineEye fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                  )
                }
              />

              <ShowErrorMessageLogin id="showPasswordError" value={showPasswordError} />
            </div>

            <div className="checkBoxStyle" style={{display: "flex", flexDirection: "column"}}>
              {/* <CheckBox isChecked={isChecked} setIsChecked={setIsChecked} /> */}

              <div className="innerContainer">
                <input
                  data-testid="checkbox"
                  type="checkbox"
                  onChange={() => setIsChecked(!isChecked)}
                  className="checkedInput"
                />

                {/* <label className="agreeToOyola">
                  I have read and agree to Oyola’s <a href="/terms">Terms & Conditions</a> and
                  <a href="/privacy"> Privacy Policy.</a>
                </label> */}
                <p style={{fontSize: "1.2rem"}}>
                  I have read and agree to Oyola’s <Link to="/terms">Terms & Conditions</Link> and
                  <Link to="/privacy"> Privacy Policy.</Link>
                </p>
              </div>

              <ShowErrorMessageLogin
                id="showCheckError"
                fontSize="1.2rem"
                marginTop="0px"
                textAlign="cemter"
                value={showCheckError}
              />
            </div>

            <div id="captcha" className={` ${captchaConfig.config.product === "bind" ? "hideHeight" : null}`}>
              <OyolaPuzzleSecurityComponent captcha-config={captchaConfig} />
            </div>

            <button data-testid="signup" className="buttonStyle" onClick={verifyAndGoToOTP}>
              Sign up
            </button>

            <div className="footerLogin">
              <div className="innerContainer">
                <p className="already">Already have an account?</p>
                <p data-testid="signin" className="signup" onClick={route}>
                  Sign in
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(RegisterForm);
