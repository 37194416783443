import {BUYDATA, COINDATA, COUNTRYDATA, SELLDATA, SKELETON_LOADER, TOKENLOCALCURRENCY} from "../types";

export const getBuyData = (param) => (dispatch) => {
  dispatch({type: BUYDATA, payload: param === "buy" ? {name: "buy", checker: true} : {name: "buy", checker: false}});
};

export const getSellData = (param) => (dispatch) => {
  dispatch({
    type: SELLDATA,
    payload: param === "sell" ? {name: "sell", checker: true} : {name: "sell", checker: false},
  });
};

export const getCountryData = (param) => (dispatch) => {
  dispatch({
    type: COUNTRYDATA,
    payload: param,
  });
};

export const getCoinData = (param) => (dispatch) => {
  dispatch({
    type: COINDATA,
    payload: param,
  });
};

export const getTotalCurrencyData = (param) => (dispatch) => {
  dispatch({
    type: TOKENLOCALCURRENCY,
    payload: param,
  });
  dispatch({type: SKELETON_LOADER, payload: false});
};
