import React, {createContext, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  FILTER_BUY_SELL_DEFAULT,
  GET_COMPLETED_TRADE,
  TRADE_CHECK_FOR_EMPTY,
  TRADE_CHECK_FOR_EXTRA_DATA,
  TRADE_DETAILS,
} from "../../store/types";
import TradeOrderTop from "../UserTrades/topArea";
import TradeOrderTable from "../UserTrades/tradeOrderTable";
import UserTradeService from "../../services/getusertrades";

export const TheUserTradeContext = createContext();

const UserTrade = () => {
  const dispatch = useDispatch();
  const [tradeOrder, setTradeOrder] = useState("all");
  const {fSkip, limit} = useSelector((state) => state.getUserTradeReducer);

  const fetchAllData = () => {
    dispatch({type: TRADE_DETAILS, payload: {type: null, tokenSymbol: null, status: null}});

    UserTradeService.AllKindOfStutusUserTradeService(fSkip, limit, null, null, null).then((data) => {
      dispatch({type: GET_COMPLETED_TRADE, payload: data?.trades});
      dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.trades});

      const futureSkipDetails = fSkip + limit;
      UserTradeService.AllKindOfStutusUserTradeService(futureSkipDetails, limit, null, null, null).then((data) => {
        if (data.trades.length === 0) {
          dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
          dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
        } else {
          dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
        }
      });
    });
  };

  useEffect(() => {
    fetchAllData();
  }, []);

  return (
    <TheUserTradeContext.Provider value={{tradeOrder, setTradeOrder}}>
      <React.Fragment>
        <TradeOrderTop />
        <TradeOrderTable />
      </React.Fragment>
    </TheUserTradeContext.Provider>
  );
};

export default UserTrade;
