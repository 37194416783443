import APIs from "./api";

export const ProfileService = {
  blockAUser: (param) => {
    return APIs.post("/api/v1/profile/block", param).then((data) => {
      return data;
    });
  },

  unblockAUser: (param) => {
    return APIs.post("api/v1/profile/unblock", param).then((data) => {
      return data;
    });
  },

  checkIfUserBlocked: (param) => {
    return APIs.post("api/v1/profile/check-if-user-blocked", param).then((data) => {
      return data;
    });
  },

  miniMarketplace: (username) => {
    return APIs.get(`api/v1/open/profile-market/${username}`).then((data) => {
      return data;
    });
  },

  claimReward: () => {
    return APIs.get(`api/v1/profile/claim-reward`).then((data) => {
      return data;
    });
  },
};
