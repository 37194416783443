import axios from "axios";
import store from "../store";
import {loader, loginError, loginErrorAction, logout, passwordDetectErrorStateAction} from "../store/actions/auth";
import {CREATE_TRADE_ERROR, ERROR_UPDATING_PHONE_NUMBER, GET_CHECK_OUT_ERROR, IS_LOADING} from "../store/types";
import {getModalChecker, getWalletOtpError} from "../store/actions/walletAction";
import {toast} from "react-toastify";
import {getConvertModalChecker} from "../store/actions/convertAction";

export const IMAGE_BASE_URL = `https://oyola.s3.amazonaws.com/`;

const APIs = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    apiKey: process.env.REACT_APP_API_KEY,

    Authorization: `Bearer ${localStorage.getItem("token") ?? ""}`,
  },
});

//Checks for internet connection
APIs.interceptors.request.use(function (config) {
  if (navigator.onLine) {
    return config;
  } else {
    toast("No internet connection", {
      toastId: "network error",
    });
  }
});

APIs.interceptors.response.use(
  (res) => {
    return res;
  },
  (err) => {
    if (err.response.status === 500) {
      if (err.response.data.message === "jwt validation error") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt malformed") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "Account is blocked!") {
        toast("Account is blocked!");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "TokenExpiredError: jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      throw err.response.data.message;
    }

    if (err.response.status === 401) {
      if (err.response.data.message === "auth code not valid") {
        toast(err.response.data.message);
        throw err.response.data.message;
      }

      if (err.response.data.message === "jwt validation error") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt malformed") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "Account is blocked!") {
        toast("Account is blocked!");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err?.response?.data?.message.message === `jwt expired`) {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "TokenExpiredError: jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "failed updating phone record") {
        store.dispatch({type: ERROR_UPDATING_PHONE_NUMBER, payload: false});
      }

      if (err.response.data.message === "fail updating default currency") {
        store.dispatch({type: IS_LOADING, payload: false});
      }

      if (err.response.data.message === "wrong login details!") {
        store.dispatch(loginError(err.response.data.message));
        store.dispatch(loginErrorAction(err.response.data.message));
        store.dispatch(passwordDetectErrorStateAction(err.response.data.message));
        store.dispatch(loader(0));
      }

      if (err.response.data.message === "price not in range") {
        store.dispatch({type: CREATE_TRADE_ERROR, payload: err.response.data.message});
      }

      if (err.response.data.message === "security code fail") {
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getModalChecker({num: 2}));
      }

      if (err.response.data.message === "not reviewed") {
        throw err.response.data.message;
      }

      if (err.response.data.message === "Fee computing error, try again") {
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getModalChecker({num: 2}));
      }

      if (err.response.data.message === "not possible to trade with same account") {
        store.dispatch({type: GET_CHECK_OUT_ERROR, payload: err.response.data.message});
      }

      if (err.response.data.message === "insufficient balance") {
        store.dispatch({type: GET_CHECK_OUT_ERROR, payload: err.response.data.message});
      }

      if (err.response.data.message === "User need a verified phone number") {
        throw err.response;
      }

      if (err.response.data.message === "Invalid or code expired!") {
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getModalChecker({num: 2}));
        throw err.response.data.message;
      }

      if (err.response.data.message === "Invalid google auth code") {
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getModalChecker({num: 2}));
      }
      // if (err.response.data.message === "error compputing value") {
      //   window.location.href = "/oop";
      // }

      if (err.response.data.message === "The minimum amount is 10 USD and Max 1000") {
        toast(err.response.data.message);
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getConvertModalChecker({num: 1}));
      }

      throw err.response.data.message;
    }

    if (err?.response?.status === 501) {
      if (err.response.data.message === "preventDuplicate - call failed") {
        toast("preventDuplicate - call failed");
        store.dispatch(getModalChecker({}));
      }

      if (err.response.data.message === "network error - withdrawing crypto") {
        toast("preventDuplicate - call failed");
        store.dispatch(getModalChecker({}));
      }

      if (err.response.data.message === "jwt validation error") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt malformed") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "Account is blocked!") {
        toast("Account is blocked!");
        localStorage.clear();
        window.location = "/";
      }

      if (err?.response?.data?.message === `jwt expired`) {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err?.response?.data?.message.message === `jwt expired`) {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "TokenExpiredError: jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "TokenExpiredError: jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "Network error!") {
        store.dispatch(loginError(err.response.data.message));
        store.dispatch(loginErrorAction(err.response.data.message));
        store.dispatch(loader(0));
        store.dispatch(logout());
      }

      if (err.response.data.message === "network error!Cannot read properties of undefined (reading 'balance')") {
        toast(err.response.data.message);
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getConvertModalChecker({num: 1}));
      }

      // if (err.response.data.message === "network error!") {
      //   window.location.href = "/oop";
      // }
      throw err.response.data.message;
    }

    if (err.response?.status === 400) {
      if (err.response.data.message === "wrong login details!") {
        store.dispatch(loginError(err.response.data.message));
        store.dispatch(loginErrorAction(err.response.data.message));
        store.dispatch(passwordDetectErrorStateAction(err.response.data.message));
        store.dispatch(loader(0));
      }

      if (err.response.data.message === "This account is blocked. Contact support") {
        store.dispatch(loginError(err.response.data.message));
        store.dispatch(loginErrorAction(err.response.data.message));
        store.dispatch(passwordDetectErrorStateAction(err.response.data.message));
        store.dispatch(loader(0));
      }

      if (err.response.data.message === "jwt validation error") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt malformed") {
        toast("Token validation error");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "Account is blocked!") {
        toast("Account is blocked!");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err?.response?.data?.message.message === `jwt expired`) {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "TokenExpiredError: jwt expired") {
        toast("Token expired");
        localStorage.clear();
        window.location = "/";
      }

      if (err.response.data.message === "getting user details failed.") {
        toast(err.response.data.message);
        store.dispatch(loginError(err.response.data.message));
        store.dispatch(loginErrorAction(err.response.data.message));
        store.dispatch(passwordDetectErrorStateAction(err.response.data.message));
        store.dispatch(loader(0));

        throw err.response.data.message;
      }

      if (err.response.data.message === "duplicated transaction") {
        store.dispatch(getWalletOtpError(err.response.data.message));
        store.dispatch(getModalChecker({num: 2}));
      }

      if (err.response.data.message === "you blocked this trader") {
        store.dispatch({type: GET_CHECK_OUT_ERROR, payload: err.response.data.message});
      }

      throw err.response.data.message;
    }

    throw err.response.data;
  }
);

export default APIs;
