import {MenuItem, MenuList} from "@mui/material";
import React, {forwardRef, memo} from "react";
import {BsCheckLg} from "react-icons/bs";
import {FiSearch} from "react-icons/fi";

const SearchablePhoneDropDownItem = forwardRef(
  (
    {
      closeMenu,
      setQuery,
      setSearch,
      setSearchDetails,
      List,
      HandleSelect,
      DetailsIcons,
      name,
      nameAcr,
      iconsLead,
      search,
      query,
      searchDetails,
      placeholder,
      openSearch,
      DropdownWidth,
      code,
    },
    ref
  ) => {
    const handleChange = (e) => {
      const {name, value} = e.target;
      setSearch({...search, [name]: value});
      setQuery(value);
      const searchData = List.filter((item) => item?.countryName?.toLowerCase()?.includes(value?.toLowerCase()));

      setSearchDetails(searchData);
    };

    return (
      <>
        {openSearch && (
          <div
            style={{width: DropdownWidth, marginTop: `${DropdownWidth && "15px"}`}}
            className="search-containerWrapper"
            ref={ref}
          >
            <div className="search-container">
              <div className="searchMetoInputBody">
                <div className="searchMetoInputLogo">
                  <FiSearch className="searchMetoInputLogoIcons" />
                </div>

                <input
                  type="text"
                  className="searchMetoInput"
                  name="name"
                  placeholder={placeholder}
                  autocomplete="off"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div style={{width: "100%"}}>
              <div>
                <div id="basic-menu" className="dropdownItem" onClick={closeMenu}>
                  {query !== "" && query !== null
                    ? searchDetails?.map((item, index) => (
                        <>
                          {item.name !== "Worldwide" && (
                            <div
                              className="ItemAlign"
                              key={index}
                              onClick={() => {
                                HandleSelect(item);
                              }}
                            >
                              <div className="textContainer">
                                {iconsLead && (
                                  <div className="iconWrapper">
                                    <DetailsIcons intialValue={item[name]} />
                                  </div>
                                )}
                                <p className={"text"}>{item[name]}</p>
                              </div>

                              <div className="dropDownCodeBody">
                                <p className="dropDownCode">{item[code]}</p>
                              </div>
                            </div>
                          )}
                        </>
                      ))
                    : List?.length !== 0
                    ? List?.map((item, index) => {
                        return (
                          <>
                            {item.name !== "Worldwide" && (
                              <div
                                className="ItemAlign"
                                key={index}
                                onClick={() => {
                                  HandleSelect(item);
                                }}
                              >
                                <div className="textContainer">
                                  {iconsLead && (
                                    <div className="iconWrapper">
                                      <DetailsIcons intialValue={item[name]} />
                                    </div>
                                  )}
                                  <p className={"text"}>{item[name]}</p>
                                </div>

                                <div className="dropDownCodeBody">
                                  <p className="dropDownCode">{item[code]}</p>
                                </div>
                              </div>
                            )}
                          </>
                        );
                      })
                    : List?.length === 0 && (
                        <div className="ItemAlign">
                          <p className="text">No available coin</p>
                        </div>
                      )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
);

export default memo(SearchablePhoneDropDownItem);
