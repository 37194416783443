import {ALL_TRADE_ORDERS_INITIAL} from "../store/types";
import APIs from "./api";
import store from "../store";

const TradeOrderService = {
  GetAllTradeOrder: (data) => {
    store.dispatch({type: ALL_TRADE_ORDERS_INITIAL, payload: true});
    return APIs.get(`/api/v1/order/get-orders/${data}`)
      .then((res) => {
        if (res.data.message === "success") {
          return res.data.orders;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
};

export default TradeOrderService;
