import {FormInput} from "../Common/FormInput";
import iconBack from "../../assets/icons/icon-back.svg";
import iconCancel from "../../assets/icons/icon-cancel.svg";
import {memo, useState} from "react";
import AddressCountry from "./AddressCountry";
import {
  modalInformationDetails,
  modalMoreInformationDetails,
  settingVerifyModal,
  storeVerifyLinkURL,
  validAddressCityAction,
  validAddressResidenceAction,
} from "../../store/actions/settingAction";
import {useDispatch, useSelector} from "react-redux";
import {NAME_REGEX} from "../../utils/Validators";
// import {DisplayNameError} from "../../utils/displayErrors";
import {Loader4} from "../Common/Loader";
import SettingsService from "../../services/settingsServices";
import {toast} from "react-toastify";

const AddressModal = () => {
  const userData = useSelector((state) => state.generalReducer.userData);
  const dispatch = useDispatch();
  const modalInfo = useSelector((state) => state.settingsReducer.settingsModalInfo);
  const getSelectedCountrySettingsData = useSelector((state) => state.settingsReducer.getSelectedCountrySettingsData);

  // const {
  //   validAddressResidence,
  //   //  validAddressCity, validAddressState
  // } = useSelector((state) => state.settingsReducer);

  const {residenceAddress, cityAddress, stateAddress} = modalInfo ?? "";

  // const [residenceAddressFocus, setResidenceAddressFocus] = useState(false);
  const [isLoading, setIsloading] = useState(false);

  const validateCredentials = (e) => {
    // setReturnUser(true);
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "residenceAddress": {
        dispatch(validAddressResidenceAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, residenceAddress: e.target.value}));
        break;
      }

      case "cityAddress": {
        dispatch(validAddressCityAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, cityAddress: e.target.value}));
        break;
      }

      case "stateAddress": {
        dispatch(validAddressCityAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, stateAddress: e.target.value}));
        break;
      }

      default: {
        break;
      }
    }
  };

  function backButton() {
    dispatch(settingVerifyModal(0));
  }

  function cancelButton() {
    dispatch(settingVerifyModal(null));
    dispatch(modalMoreInformationDetails({}));
  }

  const Continue = () => {
    const payload = {
      firstName: modalInfo?.firstName,
      lastName: modalInfo?.lastName,
      middleName: modalInfo.middleName ? modalInfo.middleName : "",
      address: `${modalInfo.residenceAddress + ","} ${modalInfo.cityAddress + ","} ${modalInfo.stateAddress + ","}`,
      userCountry: getSelectedCountrySettingsData ?? "",
      isRejected: userData?.data?.userData?.verificationStatus === "rejected" ? true : false,
    };

    setIsloading(true);

    // if (
    //   modalInfo?.data?.userData?.phoneNumberVerified === false ||
    //   modalInfo?.data?.userData?.googleAuthSetup === false
    // ) {
    //   setIsloading(false);
    //   dispatch(settingVerifyModal(15));
    // } else {
    //   SettingsService.UserVerification(payload)
    //     .then((data) => {
    //       if (data.data.message === "success") {
    //         setIsloading(false);
    //         dispatch(settingVerifyModal(2));
    //         dispatch(storeVerifyLinkURL(data.data.verifyLinkURL));
    //       }
    //     })
    //     .catch((error) => {
    //       setIsloading(false);
    //       toast.error("Something went wrong please try again after 30 seconds.");
    //     });
    // }

    SettingsService.UserVerification(payload)
      .then((data) => {
        if (data.data.message === "success") {
          setIsloading(false);
          dispatch(settingVerifyModal(2));
          dispatch(storeVerifyLinkURL(data.data.verifyLinkURL));
        }
      })
      .catch((error) => {
        setIsloading(false);
        toast.error(error);
      });
  };

  return (
    <main className="modal-container" data-aos="fade-left">
      <div className="body" style={{paddingBottom: "0rem"}}>
        <div className="top-section-modal-body">
          <div className="cancel-container">
            <img src={iconBack} alt="back-icon" onClick={backButton} />
            <img src={iconCancel} alt="back-cancel" onClick={cancelButton} />
          </div>
          <p className="title">Personal Information</p>
          <p className="tag">Please make sure information provided here matches your valid ID.</p>
          <p className="tag" style={{fontSize: "1.4rem", marginTop: "2rem", fontWeight: 600}}>
            Additional Information
          </p>
        </div>

        <div className="second-container">
          <div className="TT">
            <FormInput
              id="residenceAddress"
              label={"Residential Address (street)"}
              place={"Enter residencetial address"}
              type={"text"}
              value={modalInfo?.residenceAddress || ""}
              handler={validateCredentials}
              // onFocus={() => setResidenceAddressFocus(true)}
              // onBlur={() => setResidenceAddressFocus(false)}
              disabled={isLoading && true}
            />
            {/* <DisplayNameError
              emailFocus={residenceAddressFocus}
              email={modalInfo.residenceAddress}
              validEmail={validAddressResidence}
              message="Enter a valid residence address"
            /> */}
          </div>
        </div>

        <div className="first-container">
          <FormInput
            className="input-style"
            id="cityAddress"
            label={"City"}
            place={"Enter city"}
            type={"text"}
            value={modalInfo?.cityAddress || ""}
            handler={validateCredentials}
            disabled={isLoading && true}
          />
          <FormInput
            className="input-style"
            id="stateAddress"
            label={"State"}
            place={"Enter state"}
            type={"text"}
            value={modalInfo?.stateAddress || ""}
            handler={validateCredentials}
            disabled={isLoading && true}
          />
        </div>

        <div className="second-container">
          <div className="TT">
            <AddressCountry />
          </div>
        </div>
      </div>

      <div className="footer-modal">
        <div className="button-container">
          <button
            style={{
              ...buttonStyle,
              background: "transparent",
              border: "1px solid gray",
              color: "black",
              width: "210px",
            }}
            className="base-button"
            onClick={cancelButton}
          >
            Cancel
          </button>
          {userData?.data?.userData?.address ? (
            <button
              className="base-button"
              style={{
                ...buttonStyle,
                width: "210px",
              }}
              disabled={
                !residenceAddress || !cityAddress || !stateAddress || getSelectedCountrySettingsData === "" || isLoading
                  ? true
                  : false
              }
              onClick={Continue}
            >
              {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Continue</p>}
            </button>
          ) : (
            <button
              className="base-button"
              style={{
                ...buttonStyle,
                width: "210px",
              }}
              disabled={
                !residenceAddress || !cityAddress || !stateAddress || getSelectedCountrySettingsData === "" || isLoading
                  ? true
                  : false
              }
              onClick={Continue}
            >
              {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Continue</p>}
            </button>
          )}
        </div>
        <div>
          <p className="title">
            This information is used for personal verification only and its is kept confidental by Oyola.
          </p>
        </div>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  height: "40px",

  "&:disabled": {
    background: "gray",
  },
};

export default memo(AddressModal);
