import React from "react";
import {memo} from "react";
import "../../styles/customCheckbox.css";

const CustomCheckbox = ({handleChange, state, edited}) => {
  return (
    <div className="CustomeCheckBox" style={{backgroundColor: `${state.checker === false ? "#e3d0ff" : ""}`}}>
      <input
        type={"checkbox"}
        className="checkbox"
        checked={state.checker || false}
        onChange={!edited ? handleChange : undefined}
        readOnly={edited ? true : false}
      />
      <span className="checker"></span>
    </div>
  );
};

export default memo(CustomCheckbox);
