import React, {memo, useCallback, useEffect, useState} from "react";
import ProfileImageOther from "../Common/ProfileImageOther";
import SmallNumber from "small-number";
import {useDispatch, useSelector} from "react-redux";
import {profileHeaderOtherAction} from "../../store/actions/profile";
import AuthService from "../../services/auth";
import moment from "moment";
import PersonalInfoOther from "./PersonalInfoOther";
import VerifyBlockUserButton from "./verifyBlockUserButton";
import "../../styles/profile.scss";
import {CountryIcons} from "../Common/CountryIcons";
import {toast} from "react-toastify";
// import {ProfileService} from "../../services/profileService";

const ProfileHeaderOther = ({username}) => {
  const {profileHeaderOtherDataReducer} = useSelector((state) => state.profileReducer);

  const trusts = profileHeaderOtherDataReducer?.trusts ?? 0;
  const distrust = profileHeaderOtherDataReducer?.distrusts ?? 0;

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const fetchProfileData = useCallback(() => {
    const payload = {
      username,
    };

    setIsLoading(true);

    if (username !== "") {
      AuthService.profileData(payload)
        .then((data) => {
          // if (data.messsage === "success") {
          dispatch(profileHeaderOtherAction(data?.userData));
          setIsLoading(false);
          // }
        })
        .catch(() => {
          toast("Something went wrong");
          setIsLoading(false);
        });
    }
  }, [username, dispatch]);

  useEffect(() => {
    fetchProfileData();
    // check();
  }, [fetchProfileData]);

  return (
    <React.Fragment>
      <section className="profile-header-container" style={{border: "none"}}>
        <div className="profile-left-container">
          <div className="box-one">
            <ProfileImageOther />
            <div style={{marginLeft: "10px"}}>
              <p className="textStyle" style={{margin: "0 0 8px"}}>
                @{username}
              </p>
              <p className="textStyle" style={{fontWeight: 400, fontSize: "14px", margin: "0 0 8px"}}>
                Joined {moment(profileHeaderOtherDataReducer?.timeStamp).format("MMMM YYYY")}
              </p>
              <p
                className="textStyle"
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: "0",
                }}
              >
                {profileHeaderOtherDataReducer && (
                  <CountryIcons intialValue={profileHeaderOtherDataReducer?.userCountry} />
                )}
                {profileHeaderOtherDataReducer?.userCountry}
              </p>
            </div>
          </div>

          <div className="box-two">
            <div className="box-child">
              <p style={{fontSize: "1.2rem"}}>Review </p>

              <div style={{display: "flex", marginTop: "10px"}}>
                <p style={{fontWeight: "600", fontSize: "1.2rem"}}>
                  {trusts > 0 || distrust > 0 ? Math.round((trusts / (trusts + distrust)) * 100).toFixed(2) : 0} %
                </p>
                <p style={{marginLeft: "5px", fontWeight: "600", fontSize: "1.2rem"}}>({trusts + distrust})</p>
              </div>
            </div>

            <div className="box-child">
              <p>&nbsp;</p>
              <div style={{display: "flex", marginTop: "10px", fontSize: "1.2rem"}}>
                <p style={{marginRight: "0.2rem"}}>Positive</p>
                <p className="positive-child">{SmallNumber(Number(trusts))}</p>
              </div>
            </div>

            <div className="box-child">
              <p>&nbsp;</p>
              <div style={{display: "flex", marginTop: "10px", fontSize: "1.2rem"}}>
                <p style={{marginRight: "0.2rem"}}>Negative</p>
                <p className="negative-child">{SmallNumber(Number(distrust))}</p>
              </div>
            </div>
          </div>
        </div>

        <VerifyBlockUserButton username={username} />
      </section>

      <PersonalInfoOther />
    </React.Fragment>
  );
};

export default memo(ProfileHeaderOther);
