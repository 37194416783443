import {USER_DATA, LOCATION_DATA, PAYMENT_METHOD, COUNTRY_DATA, TOKEN_DETAILS, NETWORKERROR} from "../types";

export const getUser = (param) => (dispatch) => {
  dispatch({type: USER_DATA, payload: param});
};

export const getLocation = (param) => (dispatch) => {
  dispatch({type: LOCATION_DATA, payload: param});
};

export const getPaymentMethods = (param) => (dispatch) => {
  dispatch({type: PAYMENT_METHOD, payload: param});
};

export const getCountry = (param) => (dispatch) => {
  dispatch({type: COUNTRY_DATA, payload: param});
};

export const getTokenDetails = (param) => (dispatch) => {
  dispatch({type: TOKEN_DETAILS, payload: param});
};
