import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import Success from "../../assets/images/success-oyola.gif";
import GeneralService from "../../services/general";
import WalletService from "../../services/walletService";
import {getTokenDetails, getUser} from "../../store/actions/general";
import {getUserCoinLocalValue} from "../../store/actions/overviewAction";
import {getWalletBalance} from "../../store/actions/walletAction";
import "../../styles/walletStyles/confirmationModal.scss";
import {getConvertLevel, getConvertModalChecker} from "../../store/actions/convertAction";
import {formatBigNumber} from "../../utils/bigNumberFormatter";

import {
  getConvertBankAccChecker,
  getConvertBankDetails,
  getConvertConfirmAccDetails,
  getConvertConvertionNormal,
  getConvertConvertionRate,
  getConvertDropdownContent,
  getConvertTokenInput,
} from "../../store/actions/convertAction";

const ConvertConfirmation = () => {
  const userData = useSelector((state) => state.generalReducer.userData);

  const history = useHistory();
  const dispatch = useDispatch();

  const getConstants = async (walletchecker) => {
    let constants = await Promise.all([
      GeneralService.getUserData(),
      GeneralService.getTokenServices(),
      WalletService.UserTotalBalance(),
    ]).then((data) => {
      return data;
    });

    const WalletServices = async () => {
      const [userData, tokens] = constants;

      const tokenData = [...tokens.tokens];

      for (let item of tokenData) {
        userData.data.userData.tokens.forEach((data) => {
          if (item?.tokenSymbol?.toLowerCase() === data?.tokenSymbol?.toLowerCase()) {
            item["tokenBalance"] = formatBigNumber(data.balance.toString);
          }
        });

        for (let x in constants[0].data.userData) {
          if (x === "walletAddressBTC" && item?.tokenSymbol?.toLowerCase() === "btc") {
            item["tokenId"] = constants[0].data.userData[x];
          }

          if (
            x === "walletAddressETH" &&
            (item?.tokenSymbol?.toLowerCase() === "eth" || item?.tokenSymbol?.toLowerCase() === "bnb")
          ) {
            item["tokenId"] = constants[0].data.userData[x];
          }

          if (x === "walletAddressTRON" && item?.tokenSymbol?.toLowerCase() === "usdt") {
            item["tokenId"] = constants[0].data.userData[x];
          }
        }

        for (let x in constants[2]?.tokensValueData[0]) {
          if (item.tokenSymbol?.toUpperCase() === x.slice(0, -5)?.toUpperCase()) {
            item["quantityInBTC"] =
              formatBigNumber(constants[2]?.tokensValueData[0][x]) / formatBigNumber(constants[2]?.unitBTC);
            item["quantityInLocalCurrency"] = formatBigNumber(constants[2]?.tokensValueData[0][x]);
          }
        }

        const unitSystem = await WalletService.GetLocalCurrencyValue(
          item.tokenSymbol,
          constants[0]?.data?.userData?.defaultCurrency.currency
        );

        const index = tokenData.findIndex((data) => item.tokenSymbol === data.tokenSymbol);

        tokenData[index] = {...tokenData[index], unitPrice: formatBigNumber(unitSystem.currencyValue)};
      }

      return tokenData;
    };

    const handle = await WalletServices();

    const coinValueArray = [];
    for (let x in constants[2]?.tokensValueData[0]) {
      coinValueArray.push({value: constants[2]?.tokensValueData[0][x], coin: x.slice(0, -5)?.toUpperCase()});
    }

    const filteredCoinValue = coinValueArray.filter((item) => item.value !== null ?? item.value !== 0);

    if (filteredCoinValue.length !== 0) {
      dispatch(getUserCoinLocalValue({checker: 1, value: coinValueArray}));
    } else {
      dispatch(getUserCoinLocalValue({checker: 0, value: coinValueArray}));
    }

    dispatch(getUser(constants[0]));
    dispatch(getTokenDetails(handle));
    dispatch(getWalletBalance(constants[2]));

    if (walletchecker === "walletBack") {
      dispatch(getConvertModalChecker({}));
      history.push("/app/wallet");
    }
  };

  const HandleViewWalletHistory = () => {
    // selected btc as a default coin in convert first modal
    userData?.data?.userData?.tokens?.map((item) => {
      if (item?.tokenSymbol?.toLowerCase() === "btc") {
        dispatch(getConvertDropdownContent(item));
      }
    });

    // clearing all the data
    dispatch(getConvertConvertionRate({}));
    dispatch(getConvertTokenInput(0));
    dispatch(getConvertConvertionNormal({}));
    dispatch(getConvertBankDetails({}));
    dispatch(getConvertConfirmAccDetails({}));
    dispatch(getConvertBankAccChecker(""));
    dispatch(getConvertModalChecker({num: 0}));
    // dispatch(getConvertLevel(0));
    getConstants("walletBack");
  };

  return (
    <div className="comfirmationWrapper">
      <div className="comfirmation__firstLayout">
        <div className="comfirmation__firstLayoutImageBody">
          <img src={Success} alt={"success"} className="comfirmation__firstLayoutImage" />
        </div>
      </div>
      <div className="comfirmation__secondLayout">
        <h2>12,000 NGN</h2>

        <p>Purchase complete</p>
      </div>
      <div className="comfirmation__thirdLayout_center">
        <button onClick={HandleViewWalletHistory} className="comfirmation__thirdLayoutHistory">
          View Wallet
        </button>
      </div>
    </div>
  );
};

export default memo(ConvertConfirmation);
