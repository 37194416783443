import {GET_USER_TRADES_INITIAL} from "../store/types";
import store from "../store";
import APIs from "./api";

const UserTradeService = {
  getUserTrades: async () => {
    store.dispatch({type: GET_USER_TRADES_INITIAL, payload: true});
    const result = APIs.get("/api/v1/trade/get-user-trades")
      .then(({data}) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },

  PendingAndDisputedUserTradeService: async () => {
    // store.dispatch({type: GET_USER_TRADES_INITIAL, payload: true});
    const result = APIs.get(`/api/v1/metrics/get-pending-disputed-trade-counts`)
      .then(({data}) => {
        if (data?.message === "success") {
          return data;
        }
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },

  AllKindOfStutusUserTradeService: async (skip, limit, tokenSymbol, type, status) => {
    // store.dispatch({type: GET_USER_TRADES_INITIAL, payload: true});
    const result = APIs.get(`/api/v1/metrics/get-all-trades/${skip}/${limit}/${tokenSymbol}/${type}/${status}`)
      .then(({data}) => {
        if (data?.message === "success") {
          return data;
        }
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },

  OnlyPendingTradeService: async (skip, limit) => {
    const result = APIs.get(`/api/v1/metrics/get-pending-trades/${skip}/${limit}`)
      .then(({data}) => {
        if (data?.message === "success") {
          return data;
        }
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },
};

export default UserTradeService;
// module.exports = AuthService;
