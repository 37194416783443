import {useContext} from "react";
import {MarketPlaceContext} from "../../../../screens/marketplace";
import "../../../../styles/landing-page-styles/marketplace.scss";

const MarketBottomDescription = () => {
  const {toggleSwitch} = useContext(MarketPlaceContext);
  return (
    <div className="__markeplace__bottom">
      <div className="__markeplace__content__wrapper">
        {toggleSwitch === "buy" ? (
          <>
            <h4 className="__the__title">How to buy crypto on Oyola</h4>

            <div className="__desc__wrapper">
              <p className="__top__text">
                With Oyola, you can exchange crypto for your native currencies and vice versa, also referred to as
                buying and selling. You can buy and sell crypto at zero fees. Oyola gives you full control of your
                finances as there are no third parties involved. Oyola is highly secured and with our escrow services,
                scams or fraudulent practices are reduced.
              </p>

              <h5 className="__subtitle">Start buying crypto in this simple steps</h5>

              <div className="__theOptionWrapper">
                <span>1.</span>
                <p className="__theOption">
                  The first thing you want to do when you want to buy crypto on Oyola is to sign up. To sign up you need
                  a phone number and email address. You will also need a valid ID card to verify your Identity to start
                  trading on Oyola.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>2.</span>
                <p className="__theOption">
                  On our marketplace, you have various vendors to choose from. Click Buy and Select different criteria
                  based on the coin you want to buy, amount, country and payment method.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>3.</span>
                <p className="__theOption">
                  Select your preferred merchant and click on Buy. We recommend you check the vendors completed trades
                  and reviews for extra assurance. Input amount you’re willing to pay in your local currency, it will
                  automatically populate the amount you will receive in crypto and click Buy.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>4.</span>
                <p className="__theOption">
                  It brings you to the buy order page - this is where you will see your order info, the account you’re
                  supposed to pay and the time limit. You can also chat with the seller here. Transfer the amount to the
                  seller and click ‘I have made payment’.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>5.</span>
                <p className="__theOption">
                  Wait for seller to confirm and release asset. Once the seller has confirmed payments, you will get
                  your preferred coin in your wallet.
                </p>
              </div>
            </div>
          </>
        ) : (
          <>
            <h4 className="__the__title">How to sell crypto on Oyola</h4>

            <div className="__desc__wrapper">
              <h5 className="__subtitle">How to sell crypto on Oyola</h5>
              <p className="__top__text">
                You’ve already bought crypto on Oyola or you have crypto in your wallet and you want to sell it. Here
                are the simple steps to effectively sell your crypto coin:
              </p>

              <div className="__theOptionWrapper">
                <span>1.</span>
                <p className="__theOption">
                  Click on Sell in the market place. Input preferred coin, amount, country and payment method.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>2.</span>
                <p className="__theOption">
                  Select your preferred merchant and click Sell. Input the amount of crypto assets you’re selling.
                  Automatically, it will populate the amount you will receive in your local currency and click sell.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>3.</span>
                <p className="__theOption">
                  At this point, you’re waiting for the buyer’s payment. You can also chat with them on this page. When
                  you receive the payment, you can confirm this by clicking “I have received payment” (make sure to
                  check that you have received the money).
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>4.</span>
                <p className="__theOption">
                  You will get a verification pop up, accept the condition and press confirm. An OTP verification screen
                  will pop out to verify your transaction. Input the 6 digit number sent to your phone number and click
                  submit.
                </p>
              </div>
              <div className="__theOptionWrapper">
                <span>5.</span>
                <p className="__theOption">You have successfully sold your crypto currency.</p>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default MarketBottomDescription;
