import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_MARKET_PLACE_STATE} from "../../store/types";
import {OverViewMarketFilterArea} from "./filterArea/theFilterArea";
import {OverViewMarketTable} from "./marketTable";
import {getMarketPlaceBuyAndSellAction} from "../../store/actions/market";

const OverviewMarketPlace = () => {
  const dispatch = useDispatch();
  const {locationData} = useSelector((state) => state.generalReducer);

  let theLocation = locationData?.data?.geoData?.countryName ?? "Worldwide";

  useEffect(() => {
    dispatch({
      type: CHANGE_MARKET_PLACE_STATE,
      payload: {type: "buy", token: "BTC", country: `Worldwide`, paymentMethod: null},
    });

    dispatch(
      getMarketPlaceBuyAndSellAction({
        type: "buy",
        token: "BTC",
        country: `Worldwide`,
        paymentMethod: null,
        limit: 20,
        skip: 0,
      })
    );
  }, []);

  return (
    <React.Fragment>
      <OverViewMarketFilterArea />
      <OverViewMarketTable />
    </React.Fragment>
  );
};

export default OverviewMarketPlace;
