import React, {memo} from "react";
import {useHistory} from "react-router-dom";
import "../../styles/oop/oopButtonStyles.scss";

const ButtonSection = () => {
  const history = useHistory();

  const RouteBack = () => {
    history.goBack();
  };
  return (
    <div className="buttonOopSection">
      <button onClick={RouteBack}>Refresh page</button>
    </div>
  );
};

export default memo(ButtonSection);
