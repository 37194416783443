import React, {memo} from "react";

import ListItem from "./ListItem";
import {MdVerified} from "react-icons/md";
import {useSelector} from "react-redux";

import {Skeleton} from "@mui/material";

import "../../styles/profile.scss";

const PersonInfoOther = () => {
  const {profileHeaderOtherDataReducer} = useSelector((state) => state.profileReducer);

  return (
    <div className="personal-info-boxs-container">
      {!profileHeaderOtherDataReducer.hasOwnProperty("phoneNumberVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Phone Number"
          verifyStatus={profileHeaderOtherDataReducer?.phoneNumberVerified === true ? "verified" : "unverified"}
          trailingIcon={
            profileHeaderOtherDataReducer?.phoneNumberVerified === true ? (
              <MdVerified fill={"green"} />
            ) : (
              <MdVerified fill={"gray"} />
            )
          }
        />
      )}

      {!profileHeaderOtherDataReducer.hasOwnProperty("email") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Email"
          verifyStatus={profileHeaderOtherDataReducer?.hasOwnProperty("email") ? "verified" : "unverified"}
          trailingIcon={
            profileHeaderOtherDataReducer?.hasOwnProperty("email") ? (
              <MdVerified fill={"green"} />
            ) : (
              <MdVerified fill={"gray"} />
            )
          }
        />
      )}

      {!profileHeaderOtherDataReducer.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="ID"
          verifyStatus={profileHeaderOtherDataReducer.isVerified === true ? "verified" : "unverified"}
          trailingIcon={
            profileHeaderOtherDataReducer.isVerified === true ? (
              <MdVerified fill={"green"} />
            ) : (
              <MdVerified fill={"gray"} />
            )
          }
        />
      )}

      {!profileHeaderOtherDataReducer.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem title="Number of Trade" verifyStatus={`${profileHeaderOtherDataReducer.numberOfTrades}`} />
      )}

      {!profileHeaderOtherDataReducer.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Completed Trades"
          verifyStatus={`${profileHeaderOtherDataReducer.numberOfCompletedTrades} / ${profileHeaderOtherDataReducer.numberOfTrades}`}
        />
      )}

      {!profileHeaderOtherDataReducer.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Trade Percentage"
          verifyStatus={
            profileHeaderOtherDataReducer.numberOfTrades === 0
              ? `${0} %`
              : Math.round(
                  (profileHeaderOtherDataReducer.numberOfCompletedTrades /
                    profileHeaderOtherDataReducer.numberOfTrades) *
                    100
                ) + " %"
          }
        />
      )}
    </div>
  );
};

export default memo(PersonInfoOther);
