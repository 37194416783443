import {
  ALL_NOTIFICATIONS,
  NOTIFICATION_PAGINATION_PREV,
  NOTIFICATION_PAGINATION_NEXT,
  NOTIFICATION_PAGINATION_DEFAULT,
  MESSAGE_NOTIFICATION,
  MESSAGE_NOTIFICATION_LENGTH,
  TRADE_NOTIFICATION_LENGTH,
  TRANSFER_NOTIFICATION_LENGTH,
  TRADE_NOTIFICATION,
  TRANSFER_NOTIFICATION,
  ALL_NOTIFICATION_LENGTH,
  ALL_NOTIFICATION_LIST,
  ALLSKIP,
  MESSAGESKIP,
  TRADESKIP,
  TRANSFERSKIP,
  ALLPAGES,
  MESSAGEPAGES,
  TRADEPAGES,
  TRANSFERPAGES,
  NOTIFICATION_LIMIT,
  NOTIFICATIONNUMCHECKER,
} from "../types";

const initialState = {
  allNotificationList: [],
  allNotification: [],
  allSkip: 0,
  messageSkip: 0,
  tradeSkip: 0,
  transferSkip: 0,
  allPages: 1,
  messagePages: 1,
  tradePages: 1,
  transferPages: 1,
  messageNotification: [],
  tradeNotification: [],
  transfersNotification: [],
  notificationLimit: 10,
  notificationNumChecker: {},
};

const notificationReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ALL_NOTIFICATIONS: {
      return {
        ...state,
        allNotification: payload,
      };
    }

    case NOTIFICATION_PAGINATION_NEXT: {
      return {
        ...state,
        skip: state.skip + 1,
        fskip: state.limit * (state.skip + 1),
        limit: state.limit,
      };
    }
    case NOTIFICATION_PAGINATION_PREV: {
      return {
        ...state,
        skip: state.skip - 1,
        fskip: state.limit * (state.skip - 1),
        limit: state.limit,
      };
    }
    case NOTIFICATION_PAGINATION_DEFAULT: {
      return {
        ...state,
        skip: 0,
        fskip: 0,
      };
    }

    case MESSAGE_NOTIFICATION: {
      return {
        ...state,
        messageNotification: payload,
      };
    }

    case TRADE_NOTIFICATION: {
      return {
        ...state,
        tradeNotification: payload,
      };
    }

    case TRANSFER_NOTIFICATION: {
      return {
        ...state,
        transfersNotification: payload,
      };
    }

    case MESSAGE_NOTIFICATION_LENGTH: {
      return {
        ...state,
        messageNotificationLength: payload,
      };
    }

    case TRADE_NOTIFICATION_LENGTH: {
      return {
        ...state,
        tradeNotificationLength: payload,
      };
    }

    case TRANSFER_NOTIFICATION_LENGTH: {
      return {
        ...state,
        transferNotificationLength: payload,
      };
    }

    case ALL_NOTIFICATION_LENGTH: {
      return {
        ...state,
        allNotificationLength: payload,
      };
    }

    case ALL_NOTIFICATION_LIST: {
      return {
        ...state,
        allNotificationList: payload,
      };
    }

    case ALLSKIP: {
      return {
        ...state,
        allSkip: payload,
      };
    }

    case MESSAGESKIP: {
      return {
        ...state,
        messageSkip: payload,
      };
    }

    case TRADESKIP: {
      return {
        ...state,
        tradeSkip: payload,
      };
    }

    case TRANSFERSKIP: {
      return {
        ...state,
        transferSkip: payload,
      };
    }

    case ALLPAGES: {
      return {
        ...state,
        allPages: payload,
      };
    }

    case MESSAGEPAGES: {
      return {
        ...state,
        messsagePages: payload,
      };
    }

    case TRADEPAGES: {
      return {
        ...state,
        tradePages: payload,
      };
    }

    case TRANSFERPAGES: {
      return {
        ...state,
        transferPages: payload,
      };
    }

    case NOTIFICATION_LIMIT: {
      return {
        ...state,
        notificationLimit: payload,
      };
    }

    case NOTIFICATIONNUMCHECKER: {
      return {
        ...state,
        notificationNumChecker: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default notificationReducer;
