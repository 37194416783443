import {useSelector} from "react-redux";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import UserTradeDesktopBuySellTable from "./desktopTable";
import DisputedMobileTable from "./mobileTable";

const TheTradeOrders = () => {
  const {width} = useWindowDimensions();
  const {all, loading} = useSelector((state) => state.getUserTradeReducer);

  return width <= 700 ? (
    <DisputedMobileTable data={all} loading={loading} />
  ) : (
    <UserTradeDesktopBuySellTable data={all} loading={loading} />
  );
};

export default TheTradeOrders;
