import React, {memo} from "react";
import {useHistory} from "react-router-dom";
import "../../styles/404/404ButtonStyles.scss";

const ButtonSection = () => {
  const history = useHistory();

  const RouteBack = () => {
    history.goBack();
  };

  return (
    <div className="buttonOopSection">
      <button onClick={RouteBack}>Go Home</button>
    </div>
  );
};

export default memo(ButtonSection);
