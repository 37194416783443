import React, {memo} from "react";
import {FiUser, FiSettings, FiBarChart2, FiHome} from "react-icons/fi";
import {BiTime} from "react-icons/bi";
import {IoWalletOutline} from "react-icons/io5";
import {AiOutlineShop} from "react-icons/ai";
import {IoMdNotificationsOutline} from "react-icons/io";
import dashboardLogo from "../../assets/icons/dashboardLogo.svg";
import {useSelector} from "react-redux";
import {NavLink} from "react-router-dom";
import {HiBars3BottomRight} from "react-icons/hi2";
import AvatarSideBar from "../LargeSideBar/AvatarSideBar";
import "../../styles/home.scss";
import {BsBarChart} from "react-icons/bs";

import newOne from "../../assets/icons/convert-card.svg";

const LargeSideBar = ({isOpen, setIsOpen}) => {
  const {userData, locationData, pendingAndDisputedTrade} = useSelector((state) => state.generalReducer);
  let theLocation = locationData?.data?.geoData?.countryName ?? "Nigeria";
  const toggle = () => setIsOpen(!isOpen);

  // Check userdata in redux store
  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const menuItem = [
    {
      icon: <FiHome style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" aria-hidden />,
      page: "Overview",
      path: "/app/overview",
      index: 1,
    },
    {
      icon: <FiBarChart2 style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Trade",
      path: "/app/user-trades",
      index: 2,
    },
    {
      icon: <AiOutlineShop style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Marketplace",
      path: `/app/marketplace/${theLocation}/BTC/sell`,
      index: 3,
    },

    {
      icon: <BsBarChart style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Sell Crypto",
      path: `/app/sell-crypto`,
      index: 4,
    },

    {
      icon: <IoWalletOutline style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Wallet",
      path: "/app/wallet",
      index: 5,
    },
    {
      icon: <IoMdNotificationsOutline style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: <div>Notification</div>,
      path: "/app/notification",
      index: 6,
    },
    {
      icon: <BiTime style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Transaction History",
      path: "/app/transaction",
      index: 7,
    },
    {
      icon: <FiUser style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Profile",
      path: `/app/profile/${username}`,
      index: 8,
    },
    {
      icon: <FiSettings style={{marginLeft: "10px"}} fontSize={25} color={"#D6BBFB"} stroke="#D6BBFB" />,
      page: "Settings",
      path: "/app/settings",
      index: 9,
    },
  ];

  return (
    <div style={{width: !isOpen ? "280px" : "70px"}} className="side-bar">
      <aside className="top-section">
        <img src={dashboardLogo} alt="logo" onClick={toggle} style={{marginLeft: "1rem", width: !isOpen ? 35 : 35}} />
        <span style={{display: !isOpen ? "block" : "none", fontSize: "1.8rem", marginLeft: "0.5rem"}} className="logo">
          Oyola
        </span>

        <div className="bars" style={{...styleBars, width: "100%", marginLeft: !isOpen ? "70px" : "0px"}}>
          <HiBars3BottomRight onClick={toggle} style={{display: isOpen && "none", textAlign: "right"}} />
        </div>
      </aside>

      <div className="middle-section">
        {menuItem.map((item) => {
          return (
            <NavLink to={item.path} key={item.index} className="link" activeClassName="active">
              {item.page === "Sell Crypto" ? (
                <div className="icon">
                  <img src={newOne} alt="" style={{marginLeft: 10}} />
                </div>
              ) : (
                <div className="icon">{item.icon}</div>
              )}
              <div className="link_text" style={{display: !isOpen ? "block" : "none"}}>
                {item.page}
              </div>

              {isOpen && <div className="link_text tooltip">{item.page}</div>}

              {item?.page === "Trade" && pendingAndDisputedTrade?.pendingTradesCount > 0 && (
                <div style={!isOpen ? pendingStyle : pendingStyleClosed}>
                  <p style={!isOpen ? pendingStyleTitle : pendingStyleTitleClosed}>
                    {pendingAndDisputedTrade?.pendingTradesCount}
                  </p>
                </div>
              )}

              {item?.page === "Sell Crypto" && (
                // <div style={newConvertStyle}>
                <div style={!isOpen ? newConvertStyle : newConvertStyleClosed}>
                  <p style={!isOpen ? textImage : textImageClosed}>New</p>
                </div>
              )}
            </NavLink>
          );
        })}
      </div>

      <div className="bottom">
        <AvatarSideBar isOpen={isOpen} />
      </div>
    </div>
  );
};

const styleBars = {
  display: "flex",
  justifyContent: "flex-end",
  marginRight: "1rem",
};

const pendingStyle = {
  marginLeft: "10px",
  width: "23.83px",
  height: "23.83px",
  background: "#FBCB5C",
  color: "#101828",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15.31px",
  fontSize: "16px",
};
const pendingStyleClosed = {
  marginLeft: "-10px",
  width: "18px",
  height: "18px",
  background: "#FBCB5C",

  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "12.31px",
};

const pendingStyleTitle = {
  color: "#101828",
  fontSize: "16px",
};
const pendingStyleTitleClosed = {
  color: "#101828",
  fontSize: "10px",
  padding: "2px",
};

const newConvertStyle = {
  marginLeft: "10px",
  width: "45.83px",
  height: "25.83px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15.31px",
  fontSize: "16px",
  position: "relative",
  background: "#34D07F",
};

const newConvertStyleClosed = {
  marginLeft: "-10px",
  width: "18px",
  height: "18px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "20px",
  fontSize: "10px",
  position: "relative",
  background: "#34D07F",
  marginTop: "-20px",
};

const textImage = {
  color: "white",
  fontSize: "14px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20",
};

const textImageClosed = {
  color: "white",
  fontSize: "6px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  padding: "3px",
  // lineHeight: "20",
};

export default memo(LargeSideBar);
