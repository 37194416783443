import {memo, useState} from "react";
import LinkButton from "../linkButton";
import {playCircle, faqs, canelButton, blog, helpSupport} from "../../../utils/imagePath";
import "./mobile-side-menu.scss";
import {Link} from "react-router-dom";
import {useSelector} from "react-redux";
import {MdKeyboardArrowDown} from "react-icons/md";

const MobileSideMenu = ({setHide}) => {
  const [show, setShow] = useState(false);

  const token = localStorage.getItem("token");

  const {getSelecteGeoLocationData} = useSelector((state) => state.LandingReducer);

  const data = [
    {
      title: "Features",
      path: "/features",
    },
    {
      title: "Trade",
      path: `/marketplace/${getSelecteGeoLocationData?.countryName}/BTC/sell/Bank%20Transfer`,
    },
  ];

  return (
    <div className="mobileSideMenuContainer" style={{padding: 0}}>
      <div className="cancelContainer">
        <img
          src={canelButton}
          alt="cancel-button"
          width={30}
          onClick={() => {
            setHide(false);
          }}
          style={{marginTop: "2rem", marginRight: "2rem"}}
        />
      </div>
      {token ? (
        <div style={styleButtonContainer}>
          <LinkButton
            title="Dashboard"
            link="/app/overview"
            style={{width: "90%", height: "auto", padding: "12px 0px"}}
          />
        </div>
      ) : (
        <div style={styleButtonContainer}>
          <LinkButton title="Sign In" link="/login" style={signStyle} />
          <LinkButton title="Sign up" link="/signup" style={{width: "90%", height: "auto", padding: "12px 0px"}} />
        </div>
      )}

      {data.map((list, index) => (
        <a
          key={index}
          href={list.path}
          className="listMenuIcon"
          without={0}
          rel="noreferrer"
          style={{marginLeft: "2rem"}}
        >
          <div style={firstDropDownStyle}>
            {list.icon}
            {list.title}
          </div>
          <p className="tag">{list.tag}</p>
        </a>
      ))}

      <div className="listMenuIcon" style={{marginLeft: "2rem"}}>
        <div style={firstDropDownStyle} onClick={() => setShow(!show)}>
          <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "flex-end"}}>
            <p>Learn</p>
            <MdKeyboardArrowDown />
          </div>
        </div>
        {show &&
          innerdata.map((list, index) =>
            list.path === "https://blog.oyola.io" ? (
              <a target="_blank" key={index} href={list.path} className="listMenuIcon" rel="noopener noreferrer">
                <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
                  {list.icon}
                  {list.title}
                </div>
                <p className="tag">{list.tag}</p>
              </a>
            ) : (
              <Link key={index} to={list.path} className="listMenuIcon" without={0} rel="noreferrer">
                <div style={firstDropDownStyle}>
                  {list.icon}
                  {list.title}
                </div>
                <p className="tag">{list.tag}</p>
              </Link>
            )
          )}
      </div>
      <Link to="/about-us" className="listMenuIcon" without={0} rel="noreferrer" style={{marginLeft: "2rem"}}>
        <div style={firstDropDownStyle}>About</div>
      </Link>
      <Link to="/careers" className="listMenuIcon" without={0} rel="noreferrer" style={{marginLeft: "2rem"}}>
        <div style={firstDropDownStyle}>Careers</div>
      </Link>
    </div>
  );
};

const styleButtonContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  marginBottom: "2rem",
};

const signStyle = {
  width: "100%",
  height: "auto",
  padding: "12px 0px",
  background: "transparent",
  color: "#1E2329",
};

const firstDropDownStyle = {display: "flex", gap: "1rem", marginBottom: "0.5rem"};

const innerdata = [
  {
    icon: <img src={playCircle} alt="" />,
    title: "Video tutorials",
    tag: "Get up and running on new features and techniques.",
    path: "/videos",
  },
  {
    icon: <img src={faqs} alt="" width="20px" height="auto" />,
    title: "Frequently asked questions",
    tag: "All the boring stuff that you (hopefully won’t) need.",
    path: "/faq",
  },
  {
    icon: <img src={blog} alt="" width="20px" height="auto" />,
    title: "Blog",
    tag: "Get up and running on new features and techniques.",
    path: "https://blog.oyola.io",
  },
  {
    icon: <img src={helpSupport} alt="" width="20px" height="auto" />,
    title: "Help and support",
    tag: "Learn, fix a problem, and get answers to your questions.",
    path: "/support",
  },
];

export default memo(MobileSideMenu);
