import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../hooks/useWindowDimension";
import {
  getCoinNetworkSelection,
  getWalletModalDeposit,
  getWalletModalWithdrawal,
  getWithdrawMainDetail,
} from "../../store/actions/walletAction";
import {
  TOKENNETWORKFEE,
  WALLETADDRESSCHECKER,
  WALLETADDRESSERROR,
  WALLETCOINDETAILS,
  WALLETNETWORKDATA,
  WITHDRAWALERROR,
} from "../../store/types";
import "../../styles/walletStyles/walletTab.scss";
import SectionModal from "../Common/sectionModal";
import OpeningModalSection from "./openingModalSection";

import DepositeSelectAnyModal from "./walletDepositMultipleModal";

import WithdrawSelectAnyModal from "./walletWithdrawMultipleModal";

const WalletTab = () => {
  const dispatch = useDispatch();
  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const setModalWithdrawal = useSelector((state) => state.walletReducer.setModalWithdrawal);
  const setModalDeposit = useSelector((state) => state.walletReducer.setModalDeposit);

  const [selectTab, setSelectTab] = useState({
    title: "Deposit",
    checker: false,
  });

  const {width} = useWindowDimensions();

  return (
    <>
      <div className="wallet__firstLayoutWrapperButton">
        {tabList?.map((item, key) => (
          <div
            key={key}
            className="keywallet__firstLayoutWrapperButton"
            style={{display: `${width <= 500 && item.title === "Transaction History" ? "none" : ""}`}}
          >
            <button
              onClick={() => {
                dispatch(getWithdrawMainDetail({}));
                if (item.title === "Deposit") {
                  dispatch(getWalletModalDeposit({name: "multiple", checker: true}));
                }

                if (item.title === "Withdraw") {
                  dispatch(getWalletModalWithdrawal({name: "multiple", checker: true}));
                }

                dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});
                dispatch({type: WALLETADDRESSCHECKER, payload: false});
                dispatch({type: WALLETADDRESSERROR, payload: ""});
                dispatch({type: WITHDRAWALERROR, payload: ""});
                dispatch({type: TOKENNETWORKFEE, payload: {}});

                if (tokenDetails.length > 0) {
                  const findBtcDetails = tokenDetails.find((item) => item?.tokenSymbol?.toLowerCase() === "btc");
                  dispatch({type: WALLETCOINDETAILS, payload: findBtcDetails});
                  dispatch(getCoinNetworkSelection({token: findBtcDetails.tokenSymbol}));
                }

                setSelectTab({title: item.title});
              }}
              className={`button ${key === 0 && "deposit"} ${key === 1 && "withdraw"} ${
                item.title === selectTab.title && "selected"
              }`}
            >
              {item.title}
            </button>
          </div>
        ))}
      </div>

      <SectionModal open={setModalWithdrawal.name === "multiple" ? setModalWithdrawal.checker : false}>
        <WithdrawSelectAnyModal />
      </SectionModal>

      <SectionModal open={setModalDeposit.name === "multiple" ? setModalDeposit.checker : false}>
        <DepositeSelectAnyModal />
      </SectionModal>

      <OpeningModalSection />
    </>
  );
};

const tabList = [{title: "Deposit"}, {title: "Withdraw"}];

export default memo(WalletTab);
