import React, {useState} from "react";
import "../../styles/button.scss";
import "../../styles/new/settings.scss";
import {BsApple, BsFacebook, BsLinkedin, BsTwitter} from "react-icons/bs";
import {useSelector} from "react-redux";
import copyImage from "../../assets/icons/copyIcon.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import SettingsService from "../../services/settingsServices";
import {Skeleton} from "@mui/material";
import {useDispatch} from "react-redux";
import {getClaimLoaderSkeleton} from "../../store/actions/settingAction";
import {REFERRAL_CLAIM} from "../../store/types";
import {FaAward} from "react-icons/fa";

const ReferralCenter = () => {
  const dispatch = useDispatch();
  const claim_loader_skeleton = useSelector((state) => state.settingsReducer.claim_loader_skeleton);
  const referralClaim = useSelector((state) => state.settingsReducer.referralClaim);
  const referralTransaction = useSelector((state) => state.settingsReducer.referralTransaction);
  const userData = useSelector((state) => state.generalReducer.userData);

  const [userName, setUserName] = useState("");

  const handleChange = (e) => {
    setUserName(e.target.value);
  };

  const getClaimBonus = () => {
    dispatch(getClaimLoaderSkeleton(true));

    SettingsService.ClaimBonus({username: userName}).then((data) => {
      dispatch({type: REFERRAL_CLAIM, payload: data});
      dispatch(getClaimLoaderSkeleton(false));

      setInterval(() => {
        dispatch({type: REFERRAL_CLAIM, payload: ""});
      }, 10000);
    });
  };

  return (
    <section className="referral-center-container">
      <div className="top-section">
        <h1 className="settingsTitle title">Referral Center</h1>
        <div className="referral_stage">
          {referralTransaction < 10 ? (
            <div className="referral_stage_Icon_body">
              <div className="referral_stage_Icon_body_wrapper">
                <FaAward className="referral_stage_Icon_body_icon" style={{color: "#f04438"}} />
              </div>
              <p className="referral_stage_Icon_body_text" style={{color: "#f04438"}}>
                Not Qualified Yet to Claim Bonus
              </p>
            </div>
          ) : (
            <>
              {userData?.data?.userData?.isRefBonusClaimed === true ? (
                <div className="referral_stage_Icon_body">
                  <div className="referral_stage_Icon_body_wrapper">
                    <FaAward className="referral_stage_Icon_body_icon" />
                  </div>
                  <p className="referral_stage_Icon_body_text">$10 referral bonus claimed</p>
                </div>
              ) : (
                <div className="referral_stage_Icon_body">
                  <div className="referral_stage_Icon_body_wrapper">
                    <FaAward className="referral_stage_Icon_body_icon" />
                  </div>
                  <p className="referral_stage_Icon_body_text">Qualified, But Has not Claimed it Yet</p>
                </div>
              )}
            </>
          )}
          <div className="referral_stage_indicator">
            <p>{`${referralTransaction === null ? 0 : referralTransaction >= 10 ? 10 : referralTransaction}/10`}</p>
          </div>
        </div>
      </div>

      <main className="container">
        <div className="left-container">
          <article>
            <div className="inner-container">
              <p className="title">Refer your friends and get $10 each</p>
            </div>

            <div className="inner-container">
              <p className="detail">
                Type in a referal Code to claim 10 USDT or your referral link to friends to claim their bonus.
              </p>
            </div>
          </article>
        </div>
        <div className="right-container">
          <div className="inner-container">
            <div className="inner-container__wrapperTextDetails">
              <div className="inner-container__wrapperText">
                <p>Referrer's username</p>
              </div>

              <div className="inner-container__wrapper addMargin">
                <input
                  className="inp"
                  type={"text"}
                  placeholder={"Enter referrer's username"}
                  autocomplete="off"
                  disabled={
                    referralTransaction < 10 || userData?.data?.userData?.isRefBonusClaimed === true ? true : false
                  }
                  onChange={handleChange}
                />
                {claim_loader_skeleton ? (
                  <Skeleton animation="wave" variant="rectangular" width={100} height={40} />
                ) : userData?.data?.userData?.isRefBonusClaimed === true ? (
                  <button
                    onClick={() => dispatch({type: REFERRAL_CLAIM, payload: "Already claimed bonus"})}
                    // className="base-button"
                    disabled={true}
                    style={{cursor: "not-allowed"}}
                  >
                    Claimed
                  </button>
                ) : (
                  <button
                    onClick={getClaimBonus}
                    // className="base-button"
                    disabled={userName.length >= 5 ? false : true}
                  >
                    Claim Bonus
                  </button>
                )}
              </div>

              {referralClaim && <span style={{padding: "10px 0px"}}>{referralClaim}</span>}
            </div>

            <div className="inner-container__wrapperTextDetails">
              <div className="inner-container__wrapperText">
                <p>My Referral Code</p>
              </div>
              <div className="inner-container__wrapper">
                <input type={"text"} className={"addCap"} value={`${userData?.data?.userData?.username}`} disabled />

                <CopyToClipboard
                  text={`${userData?.data?.userData?.username}`}
                  onCopy={() => toast("copied to clipboard", {position: "top-right"})}
                >
                  <div className="inner-container__wrapperIconBody">
                    <img src={copyImage} alt="system" />
                  </div>
                </CopyToClipboard>
              </div>
            </div>
            <div style={{marginTop: "2.0rem"}}>
              <p>Share to friend via</p>
              <div style={{display: "flex", fontSize: "2.5rem", gap: "10px", margin: "12px 0 0"}}>
                <BsApple color="#98A2B3" /> <BsTwitter color="#98A2B3" /> <BsLinkedin color="#98A2B3" />
                <BsFacebook color="#98A2B3" />
              </div>
            </div>
          </div>
        </div>
      </main>
    </section>
  );
};

export default ReferralCenter;
