import {toast} from "react-toastify";
import store from "../store";
import {GET_MESSAGES_INITIAL} from "../store/types";
import APIs from "./api";

const ChatService = {
  getMessages: async (trade_id) => {
    store.dispatch({type: GET_MESSAGES_INITIAL, payload: true});

    const result = APIs.get(`/api/v1/trade/get-trade-chats/${trade_id}`).then(({data}) => {
      if (data?.message === "success") {
        return data?.chats;
      }
    });
    return result;
  },
  getMessages2: async (trade_id) => {
    const result = APIs.get(`/api/v1/trade/get-trade-chats/${trade_id}`).then(({data}) => {
      if (data?.message === "success") {
        return data?.chats;
      }
    });
    return result;
  },

  postMessages: async (data) => {
    const result = APIs.post(`/api/v1/trade/send-chat-message`, data)
      .then(({data}) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
    return result;
  },

  postImageMessages: async (data) => {
    const result = APIs.post(`/api/v1/upload/chat-image-upload`, data).then(({data}) => {
      return data;
    });
    return result;
  },
};

export default ChatService;
