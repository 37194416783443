export const supportdata = [
  {
    id: 1,
    title: "What is Oyola",
    description: [
      {
        details: "text",
        text: "Oyola is a peer-to-peer platform where you can exchange fiat (native currencies) for crypto and vice versa. It enables you to exchange crypto for crypto, fiat for fiat, or crypto for fiat. Oyola also provides a wallet where you can store your crypto assets. You can see all your crypto assets and track how they are currently doing in the market",
      },
    ],
  },
  {
    id: 2,
    title: "What country is Oyola available in?",
    description: [
      {
        details: "text",
        text: `No matter where you are, location is not a barrier. You can sign up fr om anywhere you are around the world`,
      },
    ],
  },
  {
    id: 3,
    title: "What are the payment methods available?",
    description: [
      {
        details: "text",
        text: "There are different payment methods available for sending and receiving crypto assets. They include:",
      },
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Bank transfers",
          },
          {
            text: "⦁  Credit/debit cards",
          },
          {
            text: "⦁  Online wallets and lots more",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    title: "How much is the fee?",
    description: [
      {
        details: "text",
        text: "Currently, P2P transactions are free. This means that you can exchange your crypto assets and fiat for free.",
      },
    ],
  },
  {
    id: 5,
    title: "Can I trust Oyola?",
    description: [
      {
        details: "text",
        text: "We take adequate measures to make sure our platform is safe and secure for trading. Our strict verification process enables us to scrutinize people that come on the platform to prevent scams or fraudulent practices.",
      },
      {
        details: "text",
        text: "We also implement escrow security which also helps reduces scams, lost funds, or cryptocurrencies.",
      },
    ],
  },
  {
    id: 6,
    title: "How does the escrow security work?",
    description: [
      {
        details: "text",
        text: "Think of escrow security as a third-party intervention. Here, your crypto assets are kept in a third-party account called escrow and are only released when the transaction is complete. This helps to reduce cases of unreceived assets and for better dispute resolution when the need arises",
      },
    ],
  },
  {
    id: 7,
    title: "What are the monthly or daily limits?",
    description: [
      {
        details: "text",
        text: "There are no limits to your transactions. With Oyola, you can buy and sell without limits.",
      },
    ],
  },
  {
    id: 8,
    title: "What cryptocurrencies are available on Oyola?",
    description: [
      {
        details: "text",
        text: "Currently, you can trade with BTC, ETH, USDT and BNB. We are looking to add more as time goes on",
      },
    ],
  },
  {
    id: 9,
    title: "Does Oyola provide a wallet?",
    description: [
      {
        details: "text",
        text: "Oyola provides a safe, free, and secure wallet where you can store all your cryptocurrency. You can also see and track all your cryptocurrencies in real-time",
      },
    ],
  },
  {
    id: 10,
    title: "What do I do if I send funds to the wrong address?",
    description: [
      {
        details: "text",
        text: "Mistakes do happen. Some are reversible and others, irreversible. In this situation, transactions like this cannot be resolved. Cryptocurrency transactions can neither be canceled nor reversed. Unless you know who owns the wallet address, there is little or nothing that can be done.",
      },
      {
        details: "text",
        text: "Note: always confirm wallet address before making a transaction",
      },
    ],
  },
  {
    id: 11,
    title: "What’s the reward system?",
    description: [
      {
        details: "text",
        text: "When you sign up on the Oyola app, you get a reward of 10$ to trade with. Also, when you refer people to the platform you get 10$ to trade on the app.",
      },
    ],
  },
  {
    id: 12,
    title: "What is a dispute?",
    description: [
      {
        details: "text",
        text: "A dispute refers to a breach of an agreement between two parties (either on the buyer or seller’s part) who are involved in a transaction.",
      },
    ],
  },
  {
    id: 13,
    title: "When should I file a dispute?",
    description: [
      {
        details: "text",
        text: "You can file a dispute when you notice that:",
      },
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  You’ve completed a transaction, the time has run out but yet to receive the cryptocurrency.",
          },
          {
            text: "⦁  The seller is not responding to you even after reaching out",
          },
        ],
      },
    ],
  },
  {
    id: 14,
    title: "How is a dispute settled?",
    description: [
      {
        details: "text",
        text: "You would be asked to bring some documents depending on the transaction and the method of payment used. Please be reminded that how prompt you submit these details determines how fast the issue will be resolved.",
      },
    ],
  },
  {
    id: 15,
    title: "What country is Oyola available in?",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Connect with other Oyola traders to buy and sell Bitcoin (BTC), Ethereum (ETH), and Tether (USDT).",
          },
          {
            text: "⦁  When selling cryptocurrency, you decide the price and the payment methods you'll accept. You control your financial future on Oyola",
          },

          {
            text: "⦁  Store your cryptocurrency in our free Wallet and transfer coins to other oyola wallets for free!",
          },
          {
            text: "⦁  No bank? No problem. With Oyola, you can use over 400 payment methods, including PayPal and other ewallets, prepaid debit cards, and gift cards",
          },
          {
            text: "⦁  With our escrow system and dedicated Customer support team, Oyola is very secure, and we’re constantly working to keep it that way.",
          },
        ],
      },
    ],
  },
  {
    id: 16,
    title: "Common scams",
    description: [
      {
        details: "text",
        text: "Falsely claiming funds are held by a third-party",
      },
      {
        details: "text",
        text: "Oyola does not hold onto or process any payments and we do not authorize any third-party escrow providers to hold onto funds.",
      },
      {
        details: "subSection",
        title: "1  Fake receipt",
        paragraph:
          "Never rely solely on payment proof provided by buyers. They might send a fake receipt. Please check that you have received full payment in your payment method of choice before releasing the crypto.",
      },
      {
        details: "subSection",
        title: "2  Fake Oyola customer support",
        paragraph:
          "The buyer might pretend to be an Oyola customer support representative and ask you to chat via a third-party app (e.g. Telegram). We will never do that",
      },
    ],
  },
  {
    id: 17,
    title: "How do i buy crypto on the Oyola app",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Go to the market place where you see merchants order and click buy",
          },
          {
            text: "⦁  Select a criteria based on the coin you want to buy, amount, country and payment method",
          },
          {
            text: "⦁  Select your preferred merchant and click on buy.",
          },
          {
            text: "⦁  Input amount you’re willing to pay in your local currency, it will automatically populate the amount you will receive in crypto and click buy.",
          },
          {
            text: "⦁  It brings you to the buy order page. This is where you will see your order info, the account you’re supposed to pay in and the time limit. You can also chat with the seller here.",
          },

          {
            text: "⦁  Transfer the amount to the seller and click I have made payment.",
          },
          {
            text: "⦁  Wait for seller to confirm and release asset.",
          },
          {
            text: "⦁  Once the seller has confirmed payment, you will get your preferred cryptocurrency in your wallet.",
          },
        ],
      },
    ],
  },

  {
    id: 18,
    title: "How do i buy crypto on the Oyola app",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Click on sell in the market place.",
          },
          {
            text: "⦁  Input the amount of crypto assets you’re selling. Automatically, it will populate the amount you will receive in your local currency and then click sell.",
          },
          {
            text: "⦁  At this point, you're waiting for the buyer to order and make payment for the asset. You can join the chat with the buyer at this process.",
          },
          {
            text: "⦁  When you receive the payment and the buyer clicks “I have made payment” you can now confirm and then click “ I have received payment”",
          },
          {
            text: "⦁  An OTP verification screen will pop up to verify your transaction. Input the verification code sent to your phone number and then click submit.",
          },

          {
            text: "⦁  You have successfully sold your crypto currency.",
          },
        ],
      },
    ],
  },

  {
    id: 19,
    title: "How do i become a merchant?",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Click on trade.",
          },
          {
            text: "⦁  Select the type of trade you want to do (either sell or buy).",
          },
          {
            text: "⦁  The kind of asset (coin) you want to sell or buy",
          },
          {
            text: "⦁  Select country and preferred time limit you want your transaction to occur.",
          },
          {
            text: "⦁  Select your trade margin percentage. ( this refers to the profit percentage you would like to make on your trade).",
          },

          {
            text: "⦁  Input your minimum and maximum transaction limit.",
          },

          {
            text: "⦁  Select your preferred payment method.",
          },
          {
            text: "⦁  Write a description of your order and choose a merchant name.",
          },

          {
            text: "⦁  Click on publish trade order",
          },
        ],
      },
    ],
  },

  {
    id: 20,
    title: "Case that one can file a dispute",
    description: [
      {
        details: "text",
        text: "you can file a dispute when you notice that:",
      },
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Unresponsive Buyer – the Buyer has marked the transaction as fully paid but is unresponsive and inactive.",
          },
          {
            text: "⦁  Payment issue – the Buyer is active and has attempted to pay, but there are issues with the payment.",
          },
          {
            text: "⦁  Other – an open option where you can describe what issue gave rise to the dispute. The Buyer will be able to view your description.",
          },
          {
            text: "⦁  Unresponsive vendor – you have paid, but the Seller is unresponsive and inactive.",
          },
          {
            text: "⦁  Payment issue – you have made the payment, but the Seller claims there are issues with the payment and refuses to release the Digital Assets",
          },

          {
            text: "⦁  Other – an open option where you can describe what issue gave rise to the dispute. The Seller will be able to view your description.",
          },
        ],
      },
    ],
  },

  {
    id: 21,
    title: "How do I sell crypto on Oyola ",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Click on sell in the market place.",
          },
          {
            text: "⦁  Input preferred merchant and click sell.",
          },
          {
            text: "⦁  Input the amount of crypto assets you’re selling. Automatically, it will populate the amount you will receive in your local currency and then click sell.",
          },
          {
            text: "⦁  At this point, you're waiting for the buyer to order and make payment for the asset. You can join the chat with the buyer at this process.",
          },
          {
            text: "⦁  When you receive the payment and the buyer clicks “I have made payment” you can now confirm and then click “ I have received payment”",
          },

          {
            text: "⦁  An OTP verification screen will pop up to verify your transaction. Input the verification code sent to your phone number and then click submit.",
          },

          {
            text: "⦁  You have successfully sold your crypto currency.",
          },
        ],
      },
    ],
  },

  {
    id: 22,
    title: "How do I make a deposit",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Click on wallet",
          },
          {
            text: "⦁  It will take you to the wallet page where you to the wallet page where you see your asset",
          },
          {
            text: "⦁  Click on deposit",
          },
          {
            text: "⦁  Select coin and network",
          },
          {
            text: "⦁  You can either copy your automatically generated wallet address or scan bar code.",
          },
        ],
      },
    ],
  },

  {
    id: 22,
    title: "How do I make a withdrawal",
    description: [
      {
        details: "array",
        questionArray: [
          {
            text: "⦁  Click on wallet",
          },
          {
            text: "⦁  It will take you to the wallet page where you see your asset",
          },
          {
            text: "⦁  Click on withdraw",
          },
          {
            text: "⦁  Input wallet address select your network and input amount of crypto you want to withdraw",
          },
          {
            text: "⦁  Click send",
          },
          {
            text: "⦁  Input in the verification code send to your phone number and click submit.",
          },
        ],
      },
    ],
  },
];
