import {Fragment} from "react";
import "../styles/landing-page-styles/terms.scss";
import "../styles/landing-page-styles/button.scss";
import Layout from "../components/LandingComponents/layout";

const Features = () => {
  const token = localStorage.getItem("isLoggedIn");
  return (
    <Layout>
      <Fragment>
        <main className="featuresContainer">
          <div className="innerContainer">
            <div className="titleContainer">
              <h1 className="title">Privacy Policy</h1>
              <p className="subtitle">
                Your privacy is important to us at Oyola. We respect your privacy regarding any information we may
                collect from you across our website.
              </p>

              <div className="cardContainer">
                <div>
                  <p className="title" style={{textAlign: "left", textAlignLast: "left"}}>
                    Oyola (also referred to as <b>“Oyola,” “we,” “us,” or “our”</b>) takes steps to protect your
                    privacy. In this Privacy Notice (“Notice”), we describe the types of personal information we may
                    collect from you in connection with your use of our websites including, but not limited to{" "}
                    <a href="https://oyola.io/">https://oyola.io/,{""}</a>
                    {""}
                    the Oyola Wallet, our online bitcoin p2p platform, mobile application, social media pages, or other
                    online properties (collectively, the “Website”), or when you use any of the products, services,
                    content, features, technologies, or functions we offer (collectively, the “Services”). This Notice
                    is designed to help you obtain information about our privacy practices and to help you understand
                    your privacy choices when you use our Website and Services. Please note that our Service offerings
                    may vary by region. For all purposes, the English language version of this privacy notice shall be
                    the original, governing instrument. In the event of any conflict between the English language
                    version of this privacy notice and any subsequent translation into any other language, the English
                    language version shall govern and control.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">Personal information we collect</h1>
                  <p className="subtitle">
                    We collect information that relates to you (“Personal Data”) in connection with your use of the
                    Website, our Services. or otherwise in the context of our relationship with you. The types of
                    Personal Data that we may obtain from you may include:
                  </p>
                  <p className="subtitleBig">Biographical Data, including:</p>
                  <ul className="ulList">
                    <li className="list">Name</li>
                    <li className="list">Email</li>
                    <li className="list">Address</li>
                    <li className="list">Phone Number</li>
                    <li className="list">Country</li>
                    <li className="list">Full Address</li>
                    <li className="list">Date of Birth</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <p className="subtitleBig">Oyola Account Details, including::</p>
                  <ul className="ulList">
                    <li className="list">Username</li>
                    <li className="list">Profile Picture</li>
                    <li className="list">Joined Date</li>
                    <li className="list">Default Currency</li>
                    <li className="list">Country</li>
                    <li className="list">Full Address</li>
                    <li className="list">Date of Birth</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">Oyola Account Activity, including</h1>
                  <ul className="ulList">
                    <li className="list">
                      Trade Chat Messages (which may contain financial information if you provide it to sellers)
                    </li>
                    <li className="list">Trade Chat Attachments</li>
                    <li className="list">Trade Activity </li>
                    <li className="list">Transaction History</li>
                    <li className="list">Offers Created</li>
                    <li className="list">Offer Terms</li>
                    <li className="list">Trade Instructions</li>
                    <li className="list">Account Notifications</li>
                    <li className="list">Account Status</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">Data relating to your Digital asset wallet, including: </h1>
                  <ul className="ulList">
                    <li className="list">Private Keys</li>
                    <li className="list">Public Keys</li>
                    <li className="list">Wallet Balance</li>
                    <li className="list">Offers Created</li>
                    <li className="list">Transactions received </li>
                    <li className="list">Transactions sent</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">
                    Data Collected in connection with “Know Your Customer” (KYC) Compliance, including:
                  </h1>
                  <ul className="ulList">
                    <li className="list">Government-issued ID</li>
                    <li className="list">Proof of Address</li>
                    <li className="list">Photographs, if you elect to provide them to us </li>
                    <li className="list">Video, if you elect to provide them to us</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Device and Website Usage Data, including: </h1>
                  <ul className="ulList">
                    <li className="list"> IP Addresses </li>
                    <li className="list">Cookie ID and/or other device identifiers</li>
                    <li className="list">
                      {" "}
                      Information relating to your access to the Website, such as device characteristics, date & time
                    </li>
                    <li className="list"> Language preferences</li>
                    <li className="list">Information on actions taken while using the Website</li>
                    <li className="list">Transactions sent</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Mobile application usage data, including: </h1>
                  <ul className="ulList">
                    <li className="list">
                      Session data: IP address, version of the operating system, brand and model of the device, unique
                      identifiers of the device, browser used, information about the time the Application was accessed,
                      name and parameters of the network connection.{" "}
                    </li>
                    <br />
                    <li className="list">Cookie ID and/or other device identifiers</li>
                    <br />
                    <li className="list">
                      Information relating to your access to the Website, such as device characteristics, date & time
                    </li>
                    <br />
                    <li className="list">
                      Information about applications installed on the User’s device (metadata from applications):
                      application name, application identifier and version, device identifier and checksum. Detecting
                      malicious apps and protecting users from fraud are the main reasons for collecting information
                      about installed apps.
                    </li>
                    <br />
                    <li className="list">
                      Information on actions taken while using the mobile application. Crash and application errors
                      diagnostics data.
                    </li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> How we use your data </h1>
                  <p className="subtitleBig">
                    The business purposes for which we collect, use, retain, and share your Personal Data may include:
                  </p>
                  <ul className="ulList" style={{listStyle: "none"}}>
                    <li className="list" style={{marginBottom: "1rem"}}>
                      {" "}
                      To provide Services through operating the Website, including to:{" "}
                    </li>
                    <li className="list">1. Register, create, and maintain your account;</li>
                    <li className="list">
                      2. Authenticate your identity and/or your access to an account, or help sellers verify your
                      identity;
                    </li>
                    <li className="list">3. Initiate, facilitate, process, and/or execute transactions; </li>
                    <li className="list">4. Communicate with you regarding your account or any Services you use;</li>
                    <li className="list">5. Perform creditworthiness, KYC, or other similar reviews; </li>
                    <li className="list">6. Evaluate applications; or </li>
                    <li className="list">7. Compare information for accuracy and verification purposes.</li>
                    <li className="list">
                      8. To manage risk and protect you, other persons, and the Website and Services.
                    </li>
                    <li className="list">9. To provide a personalized experience and implement your preferences. </li>
                    <li className="list">
                      10. To better understand customers and how they use and interact with the Website and Services.{" "}
                    </li>
                    <li className="list">11. To market to you.</li>
                    <li className="list">
                      12. To provide personalized Services, offers, and promotions on our Website and third-party
                      websites.
                    </li>
                    <li className="list">
                      13. To provide you with location-specific options, functionalities, and offers.
                    </li>
                    <li className="list">
                      14. To comply with our policies and obligations, including, but not limited to, disclosures and
                      responses in response to any requests from law enforcement authorities and/or regulators in
                      accordance with any applicable law, rule, regulation, judicial or governmental order, regulatory
                      authority of competent jurisdiction, discovery request or similar legal process.{" "}
                    </li>
                    <li className="list">15. To resolve disputes, collect fees, or troubleshoot problems. </li>
                    <li className="list">16. To provide customer service to you or otherwise communicate with you. </li>
                    <li className="list">17. To manage our business. </li>
                    <li className="list">
                      18. We may also process Personal Data for other purposes based upon your consent when required by
                      applicable law.
                    </li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Sources from which we collect personal data </h1>
                  <p className="subtitleBig">
                    Sources from which we collect personal data We collect Personal Data from a number of sources,
                    including
                  </p>
                  <ul className="ulList">
                    <li className="list">
                      Directly from you: We collect Personal Data directly from you when you use our Website or
                      Services, communicate with us, or interact directly with us.
                    </li>
                    <li className="list">
                      From service providers and/or data processors who assist us in providing the Website or the
                      Services: We may engage service providers to assist us in facilitating the Website or the Services
                      to you, at our direction and on our behalf. These service providers may collect information about
                      you and provide it to us.
                    </li>
                    <li className="list">
                      From other users on the Oyola Website or from third party integrated with the Oyola Website or
                      Services: Other users may provide us with information about you in connection with transactions or
                      chats. Affiliates may also provide information to us about you related to your interactions or
                      transactions with such affiliates.
                    </li>
                    <li className="list">
                      From third-parties who may help us verify identity, prevent fraud, and protect the security of
                      transactions.
                    </li>
                    <li className="list">
                      From third-parties who may help us evaluate your credit worthiness or financial standing.
                    </li>
                    <li className="list">
                      From third-parties who may help us analyze Personal Data, improve the Website or the Services or
                      your experience on it, market products or services, or provide promotions and offers to you.
                    </li>
                    <li className="list">From social media platforms, if you interact with us through social media.</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> How we share data </h1>
                  <p className="subtitleBig">
                    Under certain circumstances, we may disclose certain Personal Data with other persons. The
                    categories of persons with whom we may share Personal Data include:
                  </p>
                  <ul className="ulList">
                    <li className="list">
                      <b>Service providers and/or data processors:</b> : We may share Personal Data with third-party
                      service providers that perform services and functions at our direction and on our behalf. These
                      third-party service providers may, for example, provide you with Services, verify your identity,
                      assist in processing transactions, send you advertisements for our products and Services, or
                      provide customer support.
                    </li>
                    <li className="list">
                      <b>Other parties to transactions, such as sellers:</b> We may share information with the other
                      participants to your transactions, including other users from whom you are purchasing the digital
                      asset.
                    </li>
                    <li className="list">
                      Financial institutions and other companies involved in helping you make payments in connection
                      with transactions
                    </li>
                    <li className="list">Affiliates that receive referrals from our Website</li>
                    <li className="list">
                      Other third-parties for our business purposes or as permitted or required by law, including:
                    </li>
                    <li className="list">
                      To comply with any legal, regulatory or contractual obligation, or with any legal or regulatory
                      process (such as a valid court order or subpoena);
                    </li>
                    <li className="list">To establish, exercise, or defend legal claims;</li>
                    <li className="list">
                      In response to a request by a government agency, such as law enforcement authorities or a judicial
                      order
                    </li>
                    <li className="list">To enforce our Website Terms of Service or our internal policies; </li>
                    <li className="list">
                      To prevent physical harm or financial loss, in connection with an investigation of suspected or
                      actual illegal activity, or to otherwise protect our or others’ rights, property, or safety;{" "}
                    </li>
                    <li className="list">
                      To facilitate a purchase or sale of all or part of Oyola’s business. For example, by sharing data
                      with a company we plan to merge with or be acquired by; or
                    </li>
                    <li className="list">To support our audit, compliance, and corporate governance functions.</li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> International transfers of data </h1>
                  <p className="subtitleBig">
                    Please note that we may transfer Personal Data we collect about you to countries other than the
                    country in which the information was originally collected. Those countries may not have the same
                    data protection laws as the country in which you initially provided the information. When we
                    transfer your Personal Data to other countries, we take steps designed to ensure that the transfer
                    is in accordance with applicable law.
                  </p>
                  <h1 className="bigTitle"> Cookies and online advertising </h1>
                  <ul className="ulList">
                    <li className="list">
                      A cookie is a small text file that a website saves on your computer or mobile device when you
                      visit the website.
                    </li>
                    <li className="list">
                      Our Website uses cookies and tracking technologies to operate, and to target advertising that may
                      be of interest to you. For further information, please refer to our Cookie Policy.
                    </li>
                    <li className="list">
                      Oyola may partner with third-party ad networks to either display advertising on the Oyola Website
                      or on third-party websites. These websites and third-party ad networks are not controlled by
                      Oyola. Ad network partners use data technologies to collect information about your online
                      activities to provide you targeted advertising based upon your interests. If you wish not to have
                      this information used for the purpose of serving you targeted ads, you may be able to opt-out by
                      visiting:{" "}
                      <a
                        href="http://optout.aboutads.info/"
                        style={{marginRight: "1rem", color: "#7F56D9", textDecorationLine: "underline"}}
                      >
                        http://optout.aboutads.info/
                      </a>
                      <br />
                      <a
                        href="http://optout.networkadvertising.org/"
                        style={{color: "#7F56D9", textDecorationLine: "underline"}}
                      >
                        http://optout.networkadvertising.org/
                      </a>
                    </li>
                    <li className="list">
                      Please note this does not opt you out from being served advertising; you will continue to receive
                      generic ads that are not based on your specific interests. You can control the use of cookies at
                      the individual browser level. If you reject cookies, you may still use our Website, but your
                      ability to use some features or areas of our Website may be limited.
                    </li>
                  </ul>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Data retention </h1>
                  <p className="subtitleBig">
                    We retain Personal Data for the period necessary for the purposes for which it was collected, or for
                    such periods as required by applicable law. This may involve retaining Personal Data for periods
                    following a transaction. We make efforts to delete your Personal Data once it is no longer required
                    for any of the business purposes described above.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Data security </h1>
                  <p className="subtitleBig">
                    Oyola has implemented safeguards designed to protect your Personal Data, including measures designed
                    to prevent Personal Data against loss, misuse, and unauthorized access and disclosure. Still, Oyola
                    cannot ensure or warrant the security or confidentiality of information you transmit to us or
                    receive from us by Internet or wireless connection. Transferring data through the Internet always
                    carries some risk, even if Oyola makes efforts to protect data once it has received it.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">Children under 18 years of age</h1>
                  <p className="subtitleBig">
                    Oyola’s Website is not intended for children under the age of 18. We do not knowingly collect data
                    from children under the age of 18 without verified parental consent. If we learn that we have
                    collected information, including Personal Data, from an individual under 18 years of age without
                    parental consent, we will delete that information immediately.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Modifications to the privacy notice </h1>
                  <p className="subtitleBig">
                    Oyola reserves the right to change this Notice from time to time. We will notify you of
                    modifications to this Notice by posting a revised version of this Notice here, by email, or by means
                    of a prominent notice on the Oyola Website home page. We recommend that you periodically check the
                    Website for any changes.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle">Contact us</h1>
                  <p className="subtitleBig">
                    If you have any questions about this Notice, or wish to make an inquiry with us regarding Personal
                    Data or privacy, please contact us at:
                    <a
                      href="https://web.oyola.io"
                      style={{marginLeft: "0.5rem", textDecorationLine: "underline", color: "#7F56D9"}}
                    >
                      web@oyola.io
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>

        <div className="wideInner">
          <p className="titleContainerSignup">Sign up on Oyola</p>
          <div className="buttonContainer">
            {/* <button
            style={{cursor: "pointer"}}
            className="baseButton primaryButton btnLearn btnMore"
            onClick={() => {
              window.location = "/signup";
            }}
          >
            Learn more
          </button> */}
            <button
              style={{cursor: "pointer"}}
              className="baseButton primaryButton btnLearn"
              onClick={() => {
                token === null || token === undefined
                  ? (window.location = "/signup")
                  : (window.location = "/app/overview");
              }}
            >
              Get Started
            </button>
          </div>
        </div>
      </Fragment>
    </Layout>
  );
};

export default Features;
