import moment from "moment";
import {useState} from "react";
import {MdVerified, MdCancel} from "react-icons/md";
import avatarProfile from "../../assets/icons/avatar-profile-photo.png";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import "./commentStyle.scss";

const ReviewRow = ({
  username = "username",
  isVerified = false,
  transactionType = "Bank Transfer",
  userFeeback = "Very Honest Trader, swift transaction",
  transactionId,
}) => {
  const verified = isVerified ? (
    <span className="userFeedback__verified userFeedback__positive">
      Positive <MdVerified fill="#00a378cc" />
    </span>
  ) : (
    <span className="userFeedback__verified userFeedback__negative">
      Negative <MdCancel fill="#e03844cc" />
    </span>
  );

  const [more, setMore] = useState(false);
  const checkLengthOf = userFeeback.length > 40 ? `${userFeeback.slice(0, 40)}...` : userFeeback;

  return (
    <section className="comment-container">
      <div className="userFeedback__userBio">
        <img src={avatarProfile} alt="profle" width={"40px"} />
        <div>
          <p style={usernameStyle}>{username}</p>
          {verified}
        </div>
      </div>

      <div className="inner-middle-container">
        <p className="userFeedback__title">{transactionType}</p>

        {!more ? (
          <div className="more-detail-container">
            <p className="userFeedback__text">{checkLengthOf}</p>
          </div>
        ) : (
          <div className="more-detail-container">
            <p className="userFeedback__text">{userFeeback}</p>
          </div>
        )}
      </div>

      <div className="inner-right-container">
        <button
          style={{...buttonStyle, ...buttonPrimary, padding: "8px", borderRadius: "8px", background: "transparent"}}
        >
          {moment(transactionId).format("DD-MM-YYYY ")}
        </button>

        {checkLengthOf.length > 40 && (
          <button style={moreDetailsStyle} onClick={() => setMore(!more)}>
            {!more ? <IoIosArrowDown fontSize={"1.8rem"} /> : <IoIosArrowUp fontSize={"1.8rem"} />}
          </button>
        )}
      </div>
    </section>
  );
};

const moreDetailsStyle = {
  background: "transparent",
  border: "none",
  textDecoration: "underline",
  textAlign: "right",
};

const usernameStyle = {
  fontWeight: "400",
  fontSize: "12px",
  marginBottom: "2px",
};

const buttonStyle = {
  boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
  borderRadius: "8px",
  padding: "10px 30px",
};

const buttonPrimary = {
  background: "tranparent",
  border: "none",
  color: "black",
};

export default ReviewRow;
