import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccordionAllNotifications from "./AccordionAllNotifications";
// import {Pagination} from "./Pagination";
import "../../styles/Notification/notificationStyle.scss";
import {NotificationPaginationComponent} from "../Common/notificationPagination";
import NotificationService from "../../services/notification";
import {getAllNotifications} from "../../store/actions/notification";
import {ALLPAGES, ALLSKIP} from "../../store/types";

const AllNotifications = (props) => {
  const dispatch = useDispatch();
  const notificationDataRedux = useSelector((state) => state.notificationReducer.allNotification);
  const {allSkip, notificationLimit, allPages} = useSelector((state) => state.notificationReducer);
  const notificationNumChecker = useSelector((state) => state.notificationReducer.notificationNumChecker);
  const [loadPage, setLoadPage] = useState(false);

  const IncreamentPage = async () => {
    setLoadPage(true);
    const skip = (allSkip + 1) * 10;
    NotificationService.AllNotifications(skip, notificationLimit)
      .then((data) => {
        if (data?.data?.notifications.length > 0) {
          dispatch({type: ALLPAGES, payload: allPages + 1});
          dispatch(getAllNotifications(data?.data?.notifications));
          dispatch({type: ALLSKIP, payload: allSkip + 1});
          setLoadPage(false);
        } else {
          setLoadPage(false);
        }
      })
      .catch((err) => {
        setLoadPage(false);
      });
  };

  const DecreamentPage = () => {
    setLoadPage(true);
    const skip = (allSkip - 1) * 10;
    NotificationService.AllNotifications(skip, notificationLimit)
      .then((data) => {
        if (data?.data?.notifications.length > 0 && allPages > 1) {
          dispatch({type: ALLPAGES, payload: allPages - 1});
          dispatch(getAllNotifications(data?.data?.notifications));
          dispatch({type: ALLSKIP, payload: allSkip - 1});
          setLoadPage(false);
        } else {
          setLoadPage(false);
        }
      })
      .catch((err) => {
        setLoadPage(false);
      });
  };

  return (
    <React.Fragment>
      <div className="ReadUnReadWrapper">
        <p className="titleWrapper">Unread messages</p>
        <p className="lengthWrapper">{notificationNumChecker?.unReadCount}</p>
      </div>

      <div className="messageWrapper">
        <AccordionAllNotifications notyData={notificationDataRedux.length ? notificationDataRedux : []} />
        {notificationDataRedux.length > 0 && (
          <NotificationPaginationComponent onNext={IncreamentPage} onPrev={DecreamentPage} skip={allPages} />
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(AllNotifications);
