import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingVerifyModal} from "../../store/actions/settingAction";
import iconBack from "../../assets/icons/icon-back.svg";
import iconCancel from "../../assets/icons/icon-cancel.svg";
import SettingsService from "../../services/settingsServices";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
import OptInputField from "./optInputField";
import {Loader4} from "../Common/Loader";

import AuthService from "../../services/auth";

const SmsFirstModal = () => {
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetailsForUpdatingPhoneNumber = useSelector(
    (state) => state.settingsReducer.userDetailsForUpdatingPhoneNumber
  );

  const [errorMessage, setErrorMessage] = useState("");

  const [phoneOtp, setPhoneOtp] = useState("");

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
  }

  function goBackButton() {
    dispatch(settingVerifyModal(3));
  }

  const handlePhoneChange = (otp) => {
    setPhoneOtp(otp);
  };

  const nextButton = () => {
    setIsLoading(true);

    const payload = {
      email: userData?.data?.userData?.email,
      username: "",
      isLogin: false,
    };

    SettingsService.PhoneOtp({
      ...userDetailsForUpdatingPhoneNumber,
      verifyPhoneNumber: true,
      phoneCode: phoneOtp,
      authType: "sms",
    }).then((data) => {
      if (data.message === "verified") {
        if (userData?.data?.userData?.authPreference === "sms") {
          AuthService.verifyEmailSecurely(payload).then((data) => {
            if (data?.data?.message === "success") {
              setIsLoading(false);
              dispatch(settingVerifyModal(13));
              return;
            }
          });
          return setIsLoading(false);
        } else if (userData?.data?.userData?.authPreference === "google auth") {
          dispatch(settingVerifyModal(11));
          return setIsLoading(false);
        }
      }

      if (data.message === "network error - secondlayer! Cannot read property 'length' of null") {
        setErrorMessage("check your network!");
        setIsLoading(false);
        return;
      }

      setErrorMessage(data.message);
      setIsLoading(false);
    });
  };

  const formatPhoneNumber = (data) => {
    const stringFormat = data ? `${data?.slice(0, 6)}***${data?.slice(-3, data?.length)}` : "";
    return stringFormat;
  };

  return (
    <main className="modal-container" data-aos="zoom-in-down" style={{height: "340px", width: "400px"}}>
      <div className="body">
        <div className="top-section-modal-body" style={{marginTop: "0rem", marginBottom: "0rem"}}>
          <div className="cancel-container">
            <img src={iconBack} alt="back-icon" onClick={goBackButton} />
            <img
              src={iconCancel}
              alt="back-cancel"
              onClick={() => {
                dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                cancelButton();
              }}
            />
          </div>
        </div>

        <div className="second-container">
          <div className="TT">
            <p style={{fontSize: "1.8rem", color: "#101828", marginBottom: "1.5rem", fontWeight: "600"}}>
              Security Verification
            </p>
            <OptInputField
              titleStyle={{marginBottom: "0.5rem"}}
              tagStyle={{margin: "0rem 0rem 1rem"}}
              tagDescStyle={{margin: "0rem"}}
              value={phoneOtp}
              onChange={handlePhoneChange}
              tag={formatPhoneNumber(userDetailsForUpdatingPhoneNumber?.phoneNumber)}
              title="Please Verify your Phone Number"
              marginTop="1rem"
              error={errorMessage ? errorMessage : ""}
              hasErrored={errorMessage ? true : false}
            />
          </div>
        </div>
      </div>

      {/* user "errorMessage" for error message/ */}
      <div className="footer-modal" style={{margin: "0rem 2.5rem 3rem"}}>
        <div className="button-container">
          <button style={{...buttonStyle, ...cancelButtonStyle}} className="base-button" onClick={cancelButton}>
            Cancel
          </button>
          <button
            className="base-button"
            style={buttonStyle}
            onClick={nextButton}
            disabled={phoneOtp.length >= 6 ? false : true}
          >
            {isLoading === true ? <Loader4 /> : <p style={{color: "white"}}>Confirm</p>}
          </button>
        </div>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  width: "100%",
  height: "40px",
};

const cancelButtonStyle = {
  background: "transparent",
  border: "1px solid gray",
  color: "black",
};

export default memo(SmsFirstModal);
