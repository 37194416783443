import {toast} from "react-toastify";
import store from "..";
import BalanceService from "../../services/balance";
import createTradeService from "../../services/createTrade";
import {
  CANCEL_TRADE,
  CONFIRM_PAYMENT,
  CREATE_TRADE_DEFAULT,
  CREATE_TRADE_SUCCESS,
  DISPUTE_TRADE,
  GET_LAST_TIME_SEEN,
  GET_TRADE_BY_ID_SUCCESS,
  RATE_TRADE,
  RELEASE_TOKEN,
  REVIEW_RESPONSE,
  TRADE_REVIEW,
} from "../types";
import {getAllTokenBalance} from "./balance";
import {getTradeStatusByIDAction, getTradeStatusByIDActionAdvanced} from "./getTradeStatus";

export const createBuySellTradeAction = (args) => (dispatch) => {
  return createTradeService.createBuySellTrade(args).then((data) => {
    dispatch({type: CREATE_TRADE_SUCCESS, payload: data});
  });
};
export const getBuySellTradeByIDAction = (args) => (dispatch) => {
  return createTradeService.getBuySellTradeByID(args).then((data) => {
    let user_name = data?.tradeData?.profileData?.traderData?.username;

    createTradeService.GetLastTimeSeen(user_name).then((itemData) => {
      dispatch({type: GET_LAST_TIME_SEEN, payload: itemData?.timeLastSeen});
      dispatch({type: GET_TRADE_BY_ID_SUCCESS, payload: data.tradeData});
    });
  });
};

export const getBuySellTradeByIDActionAdvanced = (args) => (dispatch) => {
  return createTradeService.getBuySellTradeByIDAdvanced(args).then((data) => {
    dispatch({type: GET_TRADE_BY_ID_SUCCESS, payload: data.tradeData});
  });
};

export const getBuySellTradeDefaultAction = (args) => (dispatch) => {
  dispatch({type: CREATE_TRADE_DEFAULT, payload: true});
};

export const confirmTradeByIDAction = (args) => (dispatch) => {
  return createTradeService.confirmTradePaymentByID(args).then((data) => {
    dispatch(getTradeStatusByIDActionAdvanced({id: args.trade_id}));

    dispatch(getBuySellTradeByIDActionAdvanced({id: args.trade_id}));
    dispatch({type: CONFIRM_PAYMENT, payload: data});
  });
};

export const cancelTradeByIDAction = (args) => (dispatch) => {
  return createTradeService.cancelTradeByID(args).then((data) => {
    dispatch(getTradeStatusByIDActionAdvanced({id: args.trade_id}));

    dispatch(getBuySellTradeByIDActionAdvanced({id: args.trade_id}));
    dispatch({type: CANCEL_TRADE, payload: data});
  });
};

export const releaseTokenByIDAction = (args) => (dispatch) => {
  // Invalid or code expired!
  return createTradeService.releaseTokenByID(args).then((data) => {
    // dispatch(getTradeStatusByIDActionAdvanced({id: args.trade_id}));

    // dispatch(getBuySellTradeByIDActionAdvanced({id: args.trade_id}));

    BalanceService.localBalance()
      .then((data) => {
        dispatch(getAllTokenBalance(data));
      })
      .catch(() => {
        // toast("Something went wrong");
      });

    dispatch({type: RELEASE_TOKEN, payload: data});
  });
};

export const disputeTradeByIDAction = (args) => (dispatch) => {
  return createTradeService.disputeTradeByID(args).then((data) => {
    dispatch({type: DISPUTE_TRADE, payload: data});
  });
};

export const rateTradeAction = (args) => (dispatch) => {
  return createTradeService.rateTradeByID(args).then((data) => {
    dispatch({type: RATE_TRADE, payload: data});
  });
};

export const checkTradeReviewAction = (args) => (dispatch) => {
  return createTradeService.checkTradeReviewByID(args).then((data) => {
    createTradeService
      .getTraderReviewDetails(args)
      .then((data) => {
        if (data?.message === "success") {
          dispatch({type: REVIEW_RESPONSE, payload: data?.reviews});
        }
      })
      .catch((err) => {});

    dispatch({type: TRADE_REVIEW, payload: data});
  });
};
