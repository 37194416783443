import {useState} from "react";

import tv from "../assets/images/tv.jpg";

import TradeCard from "../components/LandingComponents/trade-card.component";
import ZeroFees from "../components/LandingComponents/zero-fees.component";
import AssetsControl from "../components/LandingComponents/assets-control.component";
import EarnRewards from "../components/LandingComponents/earn-rewards.component";
import MoreFeatures from "../components/LandingComponents/home-components/more-features-component";
import BeginNew from "../components/LandingComponents/begin-new.component";

import "../styles/landing-page-styles/homepage.scss";
import "../styles/landing-page-styles/button.scss";
import "../styles/landing-page-styles/text.scss";

import Layout from "../components/LandingComponents/layout";

const LandingPage = () => {
  const [tab, setTab] = useState(0);

  const token = localStorage.getItem("isLoggedIn");

  const overview = (data) => {
    switch (data) {
      case "buy": {
        setTab(0);
        break;
      }

      case "sell": {
        setTab(1);
        break;
      }

      default: {
        setTab(0);
      }
    }
  };

  return (
    <Layout>
      <>
        <main className="homepageContainer">
          <div className="innerContainer">
            <div className="titleContainer">
              <h1 className="title">Explore the freedom of crypto</h1>
              <p className="subtitle">
                Join other users to trade crypto on an easy, fast and secure platform. Trade BTC, USDT, BNB, and ETH
                anytime based on real time market updates.
              </p>

              <div className="signUpContainer">
                {/* <button
                  style={{cursor: "pointer"}}
                  className="baseButton primaryButtonLarge joinButton"
                  onClick={() => {
                    token === null || token === undefined
                      ? (window.location = "/signup")
                      : (window.location = "/app/overview");
                  }}
                >
                  Join Oyola
                </button> */}

                <button
                  style={{cursor: "pointer"}}
                  className="baseButton primaryButtonLarge joinButton"
                  onClick={() => {
                    window.location = "/download";
                  }}
                >
                  Download App
                </button>
              </div>

              <div className="heroImageContainer">
                <img src={tv} alt="dashboard Oyola - Buy, Spend or Hold Crypto on Oyola" className="imge" />
              </div>
            </div>
          </div>
        </main>

        <main className="homepageContainer" style={{background: "#EDF0FF"}}>
          <div className="innerContainer">
            <div className="marketplaceContainer">
              <div className="titleContainer">
                <h1 className="title">Oyola Market Place</h1>
                <p className="description" style={{fontSize: "1.6rem", width: "100%"}}>
                  Buy and sell crypto in our market place with verified buyers and sellers all over the globe at zero
                  fees.
                </p>
              </div>

              <div className="marketplaceCard">
                <div className="tradeButtonContainer">
                  <li className={`buttonBuy ${0 === tab ? "current" : ""}`} onClick={() => overview("buy")}>
                    Buy
                  </li>
                  <li className={`buttonBuy ${1 === tab ? "currentSell" : ""}`} onClick={() => overview("sell")}>
                    Sell
                  </li>
                </div>

                <TradeCard type={tab} />
              </div>
            </div>
          </div>
        </main>

        <div className="textContainerStyle">
          <h1 className="title">Join the crypto platform with endless possibilities</h1>
          <p className="tag">
            Buy and sell crypto in our market place with verified buyers and sellers all over the globe at zero fees.
          </p>
        </div>
        <ZeroFees />
        <AssetsControl />
        <EarnRewards />
        <MoreFeatures />
        <BeginNew />
      </>
    </Layout>
  );
};

export default LandingPage;
