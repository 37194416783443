import store from "../store";
import {IS_LOADING} from "../store/types";
import APIs from "./api";

const PostTradeService = {
  TokenLocalCurrency: ({tokenSymbol, currency, margin}) => {
    const token = tokenSymbol?.toUpperCase();
    const cur = currency?.toUpperCase();

    return Promise.all([getLocalCurrencyValue(token, cur), getMarginValue(token, cur, margin), getUserData()])
      .then((data) => {
        if (data[0].message === "success" && data[1].message === "success" && data[2].data.message === "success") {
          const userData = data[2].data.userData;
          let coinBalance;

          let marginCoinBalance;

          if (margin === 0) {
            const currencyValue = data[0].currencyValue;
            const marginDifference = currencyValue - currencyValue;
            const numChecker = Math.sign(marginDifference);

            userData.tokens.forEach((item) => {
              if (item?.tokenSymbol?.toLowerCase() === token?.toLowerCase()) {
                coinBalance = Number(item?.balance) * Number(currencyValue);

                marginCoinBalance = Number(item?.balance) * Number(currencyValue);
              }
            });

            return {
              userBalance: coinBalance,
              currency: currencyValue,
              margin: currencyValue,
              difference: marginDifference,
              sign: numChecker,
              marginCoinBalance: marginCoinBalance,
            };
          }

          const currencyValue = data[0].currencyValue;
          const marginValue = data[1].result;

          const marginDifference = marginValue - currencyValue;
          const numChecker = Math.sign(marginDifference);

          userData.tokens.forEach((item) => {
            if (item.tokenSymbol?.toLowerCase() === token?.toLowerCase()) {
              coinBalance = Number(item?.balance) * Number(currencyValue);

              marginCoinBalance = Number(item?.balance) * Number(marginValue);
            }
          });

          return {
            userBalance: coinBalance,
            currency: currencyValue,
            margin: marginValue,
            difference: marginDifference,
            sign: numChecker,
            marginCoinBalance: marginCoinBalance,
          };
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  postTradeService: (payload, history) => {
    return APIs.post("/api/v1/order/post-order", payload)
      .then((data) => {
        store.dispatch({type: IS_LOADING, payload: false});
        history.push("/app/trade-orders");
        return data.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getLocalCurrencyValueForTrade: (token, currency) => {
    return APIs.get(`/api/v1/open/get-token-price/${token}/${currency}`).then((data) => {
      return data.data;
    });
  },

  getTradeFees: async (currency) => {
    try {
      const response = await APIs.get(`/api/v1/open/get-trading-fees/${Number(currency)}`);

      if (response?.data?.message === "success") {
        return response.data;
      }
    } catch (error) {
      throw error;
    }
  },
};

const getLocalCurrencyValue = (token, cur) => {
  return APIs.get(`/api/v1/open/get-token-price/${token}/${cur}`).then((data) => {
    return data.data;
  });
};

const getMarginValue = (token, cur, margin) => {
  return APIs.get(`/api/v1/open/calculate-margin-price/${token}/${cur}/${margin}`).then((data) => {
    return data.data;
  });
};

const getUserData = async () => {
  const data = await APIs.get("/api/v1/profile/get-user-data");
  return data;
};

export default PostTradeService;
