import {useRef} from "react";
import {useEffect} from "react";
import {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BottomSheet} from "react-spring-bottom-sheet";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {checkoutDefaultStateAction} from "../../../store/actions/checkoutData";
import {getMarketCheckOutDataAction} from "../../../store/actions/market";
import "../../../styles/tradeAction.scss";
import {OurModal2} from "../../Common/modal";
import {TradeActionContext} from "../../Home/Profile";

import DeskTopActionModal from "./desktop";
import ModalActionModal from "./mobile";
// import CanNotCallProfileModal from "./desktop/canNotCallProfileModal";
// import CanNotCallMobileProfileModal from "./mobile/canNotCallMobileProfileModal";

const TradeActionModalProfile = () => {
  const {width} = useWindowDimensions();
  const sheetRef = useRef();
  const dispatch = useDispatch();
  const {modalID, modalAction, setModalAction} = useContext(TradeActionContext);

  useEffect(() => {
    if (modalID?.id) {
      dispatch(getMarketCheckOutDataAction({id: modalID?.id, traderID: modalID?.traderID}));
    }

    return () => {
      dispatch(checkoutDefaultStateAction());
    };
  }, [dispatch, modalID]);

  return width <= 600 ? (
    <>
      {/* {modalID?.notAllow === true ? (
        <BottomSheet
          ref={sheetRef}
          open={modalAction}
          onDismiss={() => setModalAction(false)}
          snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight * 0.85]}
          header={
            <h3
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
                padding: "5px 0 5px 0",
              }}
            >
              {}
            </h3>
          }
        >
          <CanNotCallMobileProfileModal />
        </BottomSheet>
      ) : ( */}
      <BottomSheet
        ref={sheetRef}
        open={modalAction}
        onDismiss={() => setModalAction(false)}
        snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight * 0.85]}
        header={
          <h3
            style={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: 600,
              padding: "5px 0 5px 0",
            }}
          >
            {}
          </h3>
        }
      >
        <ModalActionModal />
      </BottomSheet>
      {/* )} */}
    </>
  ) : (
    <>
      {/* {modalID?.notAllow === true ? (
        <OurModal2 open={modalAction} setOpen={setModalAction}>
          <CanNotCallProfileModal />
        </OurModal2>
      ) : ( */}
      <OurModal2 open={modalAction} setOpen={setModalAction}>
        <DeskTopActionModal />
      </OurModal2>
      {/* )} */}
    </>
  );
};

export {TradeActionModalProfile};
