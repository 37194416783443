import logo from "../../../assets/images/logo-footer.svg";
import {BsFacebook, BsTelegram, BsTwitter, BsYoutube, BsInstagram} from "react-icons/bs";
import {Link} from "react-router-dom";
import "./footer.scss";

import apple from "../../../assets/images/appstore.png";
import google from "../../../assets/images/googleplay.png";

const List = ({
  title,
  firstItem,
  firstItemLink,
  secondItem,
  secondItemLink,
  thirdItem,
  thirdItemLink,
  fourthItem,
  fourthItemLink,
  fiveItem,
  fiveItemLink,
  onClick,
}) => {
  return (
    <div className="additionalStyle">
      <h1 className="title">{title}</h1>
      <Link to={`${firstItemLink}`}>
        <p className="text">{firstItem}</p>
      </Link>
      <Link to={`${secondItemLink}`} onClick={onClick}>
        <p className="text">{secondItem}</p>
      </Link>
      <Link to={`${thirdItemLink}`}>
        <p className="text">{thirdItem}</p>
      </Link>
      <Link to={`${fourthItemLink}`}>
        <p className="text">{fourthItem}</p>
      </Link>
      <Link to={`${fiveItemLink}`}>
        <p className="text">{fiveItem}</p>
      </Link>
    </div>
  );
};

const SocialMediaLink = ({
  title,
  firstItem,
  firstItemLink,
  secondItem,
  secondItemLink,
  thirdItem,
  thirdItemLink,
  fourthItem,
  fourthItemLink,
  fiveItem,
  fiveItemLink,
}) => {
  return (
    <div className="additionalStyle">
      <h1 className="title">{title}</h1>
      <a target="_blank" className="title" href={firstItemLink} style={{cursor: "pointer"}} rel="noreferrer">
        <p className="title stitle">{firstItem}</p>
      </a>
      <a className="title" target="_blank" href={secondItemLink} style={{cursor: "pointer"}} rel="noreferrer">
        <p className="title stitle">{secondItem}</p>
      </a>
      <a target="_blank" href={thirdItemLink} style={{cursor: "pointer"}} rel="noreferrer">
        <p className="title stitle">{thirdItem}</p>
      </a>
      <a target="_blank" href={fourthItemLink} style={{cursor: "pointer"}} rel="noreferrer">
        <p className="title stitle">{fourthItem}</p>
      </a>
      <a target="_blank" href={fiveItemLink} style={{cursor: "pointer"}} rel="noreferrer">
        <p className="title stitle">{fiveItem}</p>
      </a>
    </div>
  );
};

function Footer() {
  const token = localStorage.getItem("isLoggedIn");
  return (
    <div className="footerContainer">
      <div className="innerFooterContainer">
        <div className="firstRow">
          <div className="logoContainer">
            <Link to="/">
              <img src={logo} className="logo" alt="Oyola Logo - Buy, Spend or Hold Crypto on Oyola" />
            </Link>
            <p className="text">Build your crypto portfolio with Oyola</p>
            {/* <p className="text" style={{fontSize: 16, margin: "40px 0 10px", color: "#98a2b3"}}>
              Our Address
            </p> */}
            <p className="text" style={{fontSize: 16, margin: "40px 0 10px", color: "white", fontWeight: 800}}>
              United Arab Emirates
            </p>
            <p className="text" style={{margin: "10px 0", fontSize: 14}}>
              Dubai Silicon Oasis, DDP, Building A2, Dubai - UAE
            </p>
            <p className="text" style={{fontSize: 16, margin: "30px 0 10px", color: "white", fontWeight: 800}}>
              Nigeria
            </p>
            <p className="text" style={{fontSize: 14, marginBottom: "20px"}}>
              Plot 15, Stephen Orasanya Street, 4th Avenue,
              <br /> Gwarinpa, Abuja
            </p>
          </div>

          <div className="rightContainerContent">
            <List
              title="Product"
              firstItem="Features"
              firstItemLink="/features"
              secondItem="Dashboard"
              secondItemLink={token === null || token === undefined ? "/login" : "/app/overview"}
              thirdItem="Learn crypto"
              thirdItemLink="/faq"
            />

            <List
              title="Company"
              firstItem="About us"
              firstItemLink="/about-us"
              secondItem="Careers"
              secondItemLink="/careers"
            />

            <SocialMediaLink
              title="Social"
              firstItem="Facebook"
              firstItemLink="https://www.facebook.com/oyola.io?mibextid=LQQJ4d"
              secondItem="Instagram"
              secondItemLink="https://instagram.com/oyola.io?igshid=YmMyMTA2M2Y="
              thirdItem="Telegram"
              thirdItemLink="https://t.me/+8RoLpaNK1hc1ZGFk"
              fourthItem="Twitter"
              fourthItemLink="https://twitter.com/oyola_io?s=21&t=mgH6p6bffDRcjxZ_vxhL3w"
              fiveItem="Youtube"
              fiveItemLink="https://www.youtube.com/channel/UCBsaaatpNhMNcWmbXjPtfdA"
            />

            <List
              title="Legal"
              firstItem="Terms"
              firstItemLink="/terms"
              secondItem="Privacy"
              secondItemLink="/privacy"
              thirdItem="Anti Money Laundering"
              thirdItemLink="/anti-money-laundering"
              fourthItem="Risk Disclosure"
              fourthItemLink="/risk-disclosure"
            />
          </div>
        </div>
      </div>

      <div className="innerSecondFooterContainer">
        <div className="secondRow">
          <div className="leftContainer">
            {/* <p className="text">© {new Date().getFullYear()} Oyola.io. All rights reserved.</p> */}
          </div>
          <div className="rightInnerContainer">
            {/* <a
              href="https://play.google.com/store/apps/details?id=com.myoyolamobile.app"
              target="_blank"
              rel="noreferrer"
            >
              <img src={apple} alt="apple" className="imageStyle" height={40} />
            </a> */}
            <a href="https://apps.apple.com/ng/app/oyola/id6470178672?platform=iphone" target="_blank" rel="noreferrer">
              <img src={apple} alt="apple" className="imageStyle" height={30} />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.myoyolamobile.app"
              target="_blank"
              rel="noreferrer"
            >
              <img src={google} alt="google play store" className="imageStyle" height={30.9} />
            </a>
          </div>
        </div>
      </div>

      {/* third */}
      <div className="innerSecondFooterContainer">
        <div className="secondRow">
          <div className="leftContainer">
            <p className="text">© {new Date().getFullYear()} Oyola.io. All rights reserved.</p>
          </div>
          <div className="rightInnerContainer">
            <a href="https://www.facebook.com/oyola.io?mibextid=LQQJ4d" target="_blank" rel="noreferrer">
              <BsFacebook fontSize={"2.5rem"} className="imageStyle" />
            </a>
            <a href="https://instagram.com/oyola.io?igshid=YmMyMTA2M2Y=" target="_blank" rel="noreferrer">
              <BsInstagram fontSize={"2.5rem"} className="imageStyle" />
            </a>
            <a href="https://t.me/+8RoLpaNK1hc1ZGFk" target="_blank" rel="noreferrer">
              <BsTelegram fontSize={"2.5rem"} className="imageStyle" />
            </a>
            <a href="https://twitter.com/oyola_io?s=21&t=mgH6p6bffDRcjxZ_vxhL3w" target="_blank" rel="noreferrer">
              <BsTwitter fontSize={"2.5rem"} className="imageStyle" />
            </a>
            <a href="https://www.youtube.com/channel/UCBsaaatpNhMNcWmbXjPtfdA" target="_blank" rel="noreferrer">
              <BsYoutube fontSize={"2.5rem"} className="imageStyle" />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
