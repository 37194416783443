import React, {useEffect, useLayoutEffect} from "react";
import PersonalInformation from "../Settings/personalInformation";
import ReferralCenter from "../Settings/referralCenter";
import ListBlockUsers from "../Settings/listBlockUsers";
import UserUpload from "../Settings/userUpload";
import Security from "../Settings/security";
import "../../styles/new/settings.scss";
import ModalInfo from "../Settings/modalInfo";
import SettingsService from "../../services/settingsServices";
import {useDispatch} from "react-redux";
import {REFERRAL_TRANSACTION} from "../../store/types";
import {useLocation} from "react-router-dom";

const Settings = () => {
  const dispatch = useDispatch();

  const fetchData = () => {
    const payload = {
      getNumberOfTrades: true,
    };

    SettingsService.getReferralScheme(payload)
      .then((data) => {
        dispatch({type: REFERRAL_TRANSACTION, payload: data});
      })
      .catch((err) => {});
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="settings-container">
      <UserUpload />
      <ModalInfo />
      <PersonalInformation />
      <Security />
      <ReferralCenter />
      <ListBlockUsers />
    </div>
  );
};

export default Settings;
