import React from "react";
import "../../styles/common/new-form-input.scss";

const FormInput = (props) => {
  return (
    <div className="form-input-container">
      <div style={{marginTop: props.margin}} className="form-input">
        <p className="label error">{props.displayError}</p>
        <p className="label" style={{marginBottom: "10px", textAlign: "left", color: "#344054", fontSize: "1.4rem"}}>
          {props.label}
        </p>

        <div className="other-input-container">
          <input
            autoComplete="off"
            autoCorrect="off"
            id={props.id}
            value={props.value}
            type={props.type}
            placeholder={props.place}
            onChange={(e) => props.handler(e)}
            error={props.error}
            onFocus={props.onFocus}
            onBlur={props.onBlur}
            className="input"
            style={{width: props.width}}
          />
        </div>
      </div>
    </div>
  );
};

export {FormInput};
