import "../../styles/coinNameWrapper.scss";

const CoinName = ({intialValue, mainWrapper}) => {
  switch (intialValue?.toLowerCase()) {
    case "btc":
      return (
        // <div className="image__body">
        <p className="__item">Bitcoin</p>
        // </div>
      );
    case "bnb":
      return (
        // <div className="image__body">
        <p className="__item">Binance coin</p>
        // </div>
      );
    case "eth":
      return (
        // <div className="image__body">
        <p className="__item">Ethereum</p>
        // </div>
      );
    case "usdt":
      return (
        // <div className="image__body">
        <p className="__item">Usd Tether</p>
        // </div>
      );

    default:
      return (
        // <div className="image__body">
        <p>Bitcoin</p>
        // </div>
      );
  }
};

export {CoinName};
