import {Button, Skeleton} from "@mui/material";
import {memo, useRef, useState} from "react";
import CurrencyFormat from "react-currency-format";
import SelectPayment from "./SelectPayment";
import "../../styles/postTradeStyles/postTradeFourthSection.scss";
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import {IS_LOADING, POST_TRADE_BUY_ERROR, POST_TRADE_DETAILED_FORM, POST_TRADE_SELL_ERROR} from "../../store/types";
import {useHistory} from "react-router-dom";
import PostTradeService from "../../services/postTradeService";
import Editor from "./Editor";

const PostTradeFourthSection = () => {
  const checkFeesRef = useRef();
  const history = useHistory();
  const dispatch = useDispatch();
  const allCurrencyDetails = useSelector((state) => state.postTradeReducer.tokenLocalCurrencyData);
  const countryDataState = useSelector((state) => state.postTradeReducer.countryData);
  const sellData = useSelector((state) => state.postTradeReducer.sellData);
  const buyData = useSelector((state) => state.postTradeReducer.buyData);
  const coinData = useSelector((state) => state.postTradeReducer.coinData);
  const paymentData = useSelector((state) => state.postTradeReducer.paymentDataState);
  const timeData = useSelector((state) => state.postTradeReducer.timeData);
  const tokenMarginData = useSelector((state) => state.postTradeReducer.tokenMarginData);
  const userData = useSelector((state) => state.generalReducer.userData);
  const detailedForm = useSelector((state) => state.postTradeReducer.postTradeDetailedForm);
  const buyError = useSelector((state) => state.postTradeReducer.postTradeBuyError);
  const sellError = useSelector((state) => state.postTradeReducer.postTradeSellError);

  const [descriptionError, setDiscriptionError] = useState("");
  const [tagLineError, setTagLineError] = useState("");

  const [feesPrice, setFeesPrice] = useState(0);
  const [totalPrices, setTotalPrices] = useState(0);

  const [feesChecker, setFeesChecker] = useState(false);

  const handleChageSystem = (e) => {
    let {name, value} = e.target;

    let str = value
      .split(" ")
      .filter(
        (el) =>
          !el.startsWith("https://") &&
          !el.startsWith("http://") &&
          !el.startsWith("www.") &&
          !el.endsWith(".com") &&
          !el.endsWith("</script>") &&
          !el.startsWith("<script>")
      );

    value = str.join(" ");

    dispatch({type: POST_TRADE_DETAILED_FORM, payload: {...detailedForm, [name]: value}});

    const newValues = value?.split(",");
    const ourValue = newValues?.join("");

    const newMinValue = detailedForm?.minValue?.match(/\d/g);
    const minAmounts = newMinValue?.join("");

    const newMaxValue = detailedForm?.maxValue?.match(/\d/g);
    const maxAmounts = newMaxValue?.join("");

    if (name === "maxValue") {
      if (buyData.checker === true) {
        if (Number(ourValue) < Number(minAmounts)) {
          dispatch({
            type: POST_TRADE_BUY_ERROR,
            payload: "maximum limit must be greater than minimum limit with a margin of 1000",
          });
        } else {
          dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
        }
      }

      if (sellData.checker === true) {
        // const fees = (0.5 * Number(ourValue)) / 100;
        // setFeesPrice(fees);
        // setTotalPrices(Number(ourValue) + fees);

        clearTimeout(checkFeesRef.current);

        setFeesChecker(true);

        checkFeesRef.current = setTimeout(async () => {
          try {
            const res = await PostTradeService.getTradeFees(Number(ourValue));

            setFeesPrice(res.fees);
            setTotalPrices(Number(ourValue) + res.fees);

            if (
              allCurrencyDetails.marginCoinBalance < Number(ourValue) ||
              allCurrencyDetails.marginCoinBalance < Number(Number(ourValue) + res.fees)
            ) {
              dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
            } else {
              dispatch({type: POST_TRADE_SELL_ERROR, payload: ""});
            }

            if (Number(ourValue) < Number(minAmounts)) {
              dispatch({
                type: POST_TRADE_BUY_ERROR,
                payload: "maximum limit must be greater than minimum limit with a margin of 1000",
              });
            } else {
              dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
            }

            setFeesChecker(false);
          } catch (err) {
            setFeesChecker(false);
          }
        }, 300);
      }
    }

    if (name === "minValue") {
      if (buyData.checker === true) {
        if (Number(ourValue) > Number(maxAmounts)) {
          dispatch({
            type: POST_TRADE_BUY_ERROR,
            payload: "maximum limit must be greater than minimum limit with a margin of 1000",
          });
        } else {
          dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
        }
      }

      if (sellData.checker === true) {
        if (
          allCurrencyDetails.marginCoinBalance < Number(ourValue) ||
          allCurrencyDetails.marginCoinBalance < Number(totalPrices)
        ) {
          dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
        } else {
          dispatch({type: POST_TRADE_SELL_ERROR, payload: ""});
        }

        if (Number(ourValue) > Number(maxAmounts)) {
          dispatch({
            type: POST_TRADE_BUY_ERROR,
            payload: "maximum limit must be greater than minimum limit with a margin of 1000",
          });
        } else {
          dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
        }
      }
    }

    if (name === "tagLine") {
      if (value.length < 20) {
        setTagLineError("length of words must be greater than 20");
      } else {
        setTagLineError("");
      }
    }

    if (name === "description") {
      if (value.length < 50) {
        setDiscriptionError("length of words must be greater than 50");
      } else {
        setDiscriptionError("");
      }
    }
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    detailedForm["tokenSymbol"] = coinData?.toUpperCase();
    // detailedForm["username"] = userData?.data?.userData?.username;
    detailedForm["currency"] = countryDataState.currencyCode;
    detailedForm["country"] = countryDataState.name;
    detailedForm["paymentMethod"] = paymentData.name;
    detailedForm["type"] = buyData.checker === true ? "buy" : "sell";
    detailedForm["profitMargin"] = Number(tokenMarginData);
    detailedForm["closingTime"] = timeData.split(" ")[0];

    if (detailedForm.minValue) {
      const newMinValue = detailedForm.minValue.match(/\d/g);
      detailedForm["minAmount"] = newMinValue.join("");
      detailedForm.minAmount = Number(detailedForm.minAmount);
    }

    if (detailedForm.minValue === "") {
      detailedForm.minAmount = 0;
    }

    if (detailedForm.maxValue) {
      const newMaxValue = detailedForm.maxValue.match(/\d/g);
      detailedForm["maxAmount"] = newMaxValue.join("");
      detailedForm.maxAmount = Number(detailedForm.maxAmount);
    }

    if (detailedForm.maxValue === "") {
      detailedForm.maxAmount = 0;
    }

    delete detailedForm.minValue;
    delete detailedForm.maxValue;

    if (
      sellData.checker === true &&
      (allCurrencyDetails.marginCoinBalance < detailedForm.maxAmount ||
        allCurrencyDetails.marginCoinBalance < detailedForm.minAmount)
    ) {
      return dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient banlance"});
    } else {
      postTradeData(detailedForm);
    }
  };

  const postTradeData = (data) => {
    dispatch({type: IS_LOADING, payload: true});
    PostTradeService.postTradeService(data, history);
  };

  return (
    <div>
      <div className="postTrade__fourthLayout">
        <div className="postTrade__fourthLayoutContainer">
          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Min. Transaction Limit</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label>This is the minimum transaction limit</label>
              <div className={`postTrade__fourthLayoutOneInputContainer`}>
                <CurrencyFormat
                  name="minValue"
                  value={detailedForm.minValue || ""}
                  thousandSeparator={true}
                  onChange={handleChageSystem}
                  autocomplete="off"
                  placeholder={"5.00"}
                />
                <div className="postTrade__fourthLayoutOneInputContainerLabel">
                  <p>{`${countryDataState.currencyCode}`}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="postTrade__fourthLayoutOne"
            style={{alignItems: `${sellData.checker === true ? "flex-start" : ""}`}}
          >
            <div
              className="postTrade__fourthLayoutOneTitle"
              style={{marginTop: `${sellData.checker === true ? "30px" : ""}`}}
            >
              <p>Max. Transaction Limit</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label>Your maximum transaction</label>
              <div className={`postTrade__fourthLayoutOneInputContainertwo`}>
                <CurrencyFormat
                  value={detailedForm.maxValue || ""}
                  placeholder="1,000.00"
                  name="maxValue"
                  thousandSeparator={true}
                  autocomplete="off"
                  onChange={handleChageSystem}
                />
                <div className="postTrade__fourthLayoutOneInputContainerLabeltwo">
                  <div className="postTrade__fourthLayoutOneInputContainerLabelContenttwo">
                    <p>{`${countryDataState.currencyCode}`}</p>
                  </div>
                </div>
              </div>
              <div className="postTrade__fourthLayoutOneTotal">
                {sellData.checker === true && (
                  <p
                    className="postTrade__fourthLayoutOneTotalMax"
                    onClick={async () => {
                      clearTimeout(checkFeesRef.current);

                      setFeesChecker(true);

                      checkFeesRef.current = setTimeout(async () => {
                        try {
                          const stateValue =
                            Object.keys(allCurrencyDetails).length > 0
                              ? allCurrencyDetails?.marginCoinBalance?.toString().split(".")[0]
                              : 0;

                          const res = await PostTradeService.getTradeFees(Number(stateValue));
                          // const fees = (0.5 * Number(stateValue)) / 100;
                          setFeesPrice(res.fees);
                          setTotalPrices(stateValue);

                          const currentValue = Number(stateValue) - res.fees;

                          dispatch({
                            type: POST_TRADE_DETAILED_FORM,
                            payload: {
                              ...detailedForm,
                              maxValue: currentValue?.toString(),
                            },
                          });

                          const newMinValue = detailedForm?.minValue?.match(/\d/g);
                          const minAmounts = newMinValue?.join("");

                          const newMaxValue = stateValue?.maxValue?.match(/\d/g);
                          const maxAmounts = newMaxValue?.join("");

                          if (sellData.checker === true) {
                            if (allCurrencyDetails.marginCoinBalance < Number(stateValue)) {
                              dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
                            } else {
                              dispatch({type: POST_TRADE_SELL_ERROR, payload: ""});
                            }

                            if (Number(stateValue) < Number(minAmounts)) {
                              dispatch({
                                type: POST_TRADE_BUY_ERROR,
                                payload: "maximum limit must be greater than minimum limit",
                              });
                            } else {
                              dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
                            }
                          }

                          setFeesChecker(false);
                        } catch (err) {
                          setFeesChecker(false);
                        }
                      }, 300);
                    }}
                  >
                    Max
                  </p>
                )}

                {buyData.checker === true && buyError && <span className="error">{buyError}</span>}

                {sellData.checker === true &&
                  (sellError ? (
                    <span className="error">{sellError}</span>
                  ) : buyError ? (
                    <span className="error">{buyError}</span>
                  ) : (
                    <span>
                      Avaliable Balance:{""}
                      <CurrencyFormat
                        value={`${
                          Object.keys(allCurrencyDetails).length > 0 ? allCurrencyDetails?.marginCoinBalance : 0
                        }`}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={` ${countryDataState.currencyCode}`}
                      />
                    </span>
                  ))}
              </div>

              <div className="postTrade__fourthLayoutOneTotal">
                {sellData.checker === true && (
                  <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Fees Percentage</p>
                )}

                {sellData.checker === true && <span>0.5%</span>}
              </div>

              <div className="postTrade__fourthLayoutOneTotal">
                {sellData.checker === true && (
                  <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Fees Selling Prices</p>
                )}

                {sellData.checker === true && (
                  <>
                    {feesChecker ? (
                      <div>
                        <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                      </div>
                    ) : (
                      <span>
                        <CurrencyFormat
                          value={`${feesPrice > 0 ? feesPrice : 0}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${countryDataState.currencyCode}`}
                        />
                      </span>
                    )}
                  </>
                )}
              </div>

              <div className="postTrade__fourthLayoutOneTotal">
                {sellData.checker === true && (
                  <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Total Selling Max Price</p>
                )}

                {sellData.checker === true && (
                  <>
                    {feesChecker ? (
                      <div>
                        <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                      </div>
                    ) : (
                      <span>
                        <CurrencyFormat
                          value={`${totalPrices > 0 ? totalPrices : 0}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${countryDataState.currencyCode}`}
                        />
                      </span>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="postTrade__fourthLayoutContainer">
          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Payment Method</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <SelectPayment />
            </div>
          </div>

          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Tagline</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label className="postTrade__fourthLayoutOneInputContainerthreeLabel">Write a sales tagline</label>
              <div className="postTrade__fourthLayoutOneInputContainerthree">
                <input
                  type="text"
                  name="tagLine"
                  value={detailedForm.tagLine || ""}
                  onChange={handleChageSystem}
                  autocomplete="off"
                  minLength={10}
                  maxLength={50}
                />
              </div>
              {tagLineError ? (
                <span className={`postTrade__fourthLayoutOneInputContainerthreeCharacter error`}>{tagLineError}</span>
              ) : (
                <span className="postTrade__fourthLayoutOneInputContainerthreeCharacter">minimum character is 10</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="postTrade__fivethLayout">
        <div className="postTrade__fivethLayoutOne">
          <div className="postTrade__fivethLayoutOneInput">
            <label>Description</label>

            <textarea
              maxLength={500}
              minLength={50}
              name="description"
              autocomplete="off"
              value={detailedForm.description || ""}
              onChange={handleChageSystem}
              placeholder="Enter a description..."
            ></textarea>

            {/* <Editor /> */}

            {descriptionError ? <p className="error">{descriptionError}</p> : <p>minimum character is 50</p>}
            <p>You can write more information about your order here.</p>
          </div>
        </div>
        <div className="postTrade__fivethLayoutTwo">
          <div className="postTrade__fivethLayoutTwoBody">
            <div className="postTrade__fivethLayoutTwoBodyOne">
              <button className="postTrade__fivethLayoutTwoBodyOneCancel">Cancel</button>

              {userData?.data?.userData?.isVerified === false &&
              sellData?.name === "sell" &&
              sellData?.checker === true ? (
                <button
                  className="postTrade__fivethLayoutTwoBodyOneConfirm"
                  onClick={() => {
                    history.push("/app/settings");
                  }}
                  style={{background: "#21B0EA", padding: "0px 40px"}}
                >
                  Verify
                </button>
              ) : (
                <button
                  disabled={
                    Object.keys(detailedForm).length < 4 ||
                    !detailedForm.description ||
                    !detailedForm.tagLine ||
                    !detailedForm.maxValue ||
                    !detailedForm.minValue ||
                    !coinData ||
                    !buyData ||
                    !sellData ||
                    !countryDataState?.countryName ||
                    !countryDataState?.currencyCode ||
                    buyError ||
                    feesChecker ||
                    tagLineError ||
                    descriptionError ||
                    !paymentData.name ||
                    tokenMarginData === null ||
                    tokenMarginData === undefined ||
                    !timeData
                      ? true
                      : sellData.checker === true
                      ? sellError
                        ? true
                        : false
                      : false
                  }
                  onClick={HandleSubmit}
                  className="postTrade__fivethLayoutTwoBodyOneConfirm"
                >
                  Publish Trade Order
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PostTradeFourthSection);
