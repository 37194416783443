import {useState, useCallback, useRef, useEffect} from "react";
import {HiOutlineChevronDown, HiOutlineChevronUp} from "react-icons/hi";
import {BiCheck} from "react-icons/bi";
import "../../styles/landing-page-styles/staticdropdown.scss";
import {useMemo} from "react";
import useOnClickOutside from "../../hooks/useOnClickOutside";

export const IconDropDown = ({
  name,
  options,
  initialValue,
  style,
  color = "#000",
  dropdownOnChangeFunction,
  useLabel = false,
}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [showSelectCondition, setShowSelectCondition] = useState(false);
  const thref = useRef();
  const [value, setValue] = useState({});

  useEffect(() => {
    setValue(initialValue);
  }, []);

  const handleClick = () => {
    if (showSelectCondition === false) {
      setShowDropDown(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setShowDropDown(false);
      setShowSelectCondition(false);
    }
  };

  useOnClickOutside(thref, () => setShowDropDown(false));

  return (
    <div className={`__dd__wrapper`} style={style}>
      <div className={`staticDropDown__wrapper justify__between`} onClick={handleClick}>
        <span className={`__thelabelitem`} style={{marginLeft: "10px", color: color, fontSize: "13px"}}>
          {value ? (useLabel ? value.label : value?.value) : name}
        </span>
        {showDropdown ? (
          <HiOutlineChevronDown size={16} className={`staticDropDown__icon`} color={"#98a2b3"} />
        ) : (
          <HiOutlineChevronUp size={16} className={`staticDropDown__icon`} color={"#98a2b3"} />
        )}
      </div>
      {showDropdown && (
        <div
          className={`staticDropDown__content__wrapper`}
          ref={thref}
          // data-aos="zoom-in"
          // data-aos-duration="300"
        >
          {options &&
            options.map((item, i) => {
              return (
                <div
                  onClick={() => {
                    setValue(item);
                    if (dropdownOnChangeFunction) {
                      dropdownOnChangeFunction({[item.attr]: item.value});
                    }
                    handleClick();
                  }}
                  className={`staticDropDown__contentOptions`}
                  key={i}
                >
                  <span style={{fontSize: 15}}>{item.label}</span>
                  {value && value.value === item.value && <BiCheck size={22} />}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
