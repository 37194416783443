import {TransactionStaticDropDown} from "../../../Common/transactionStaticDropdown";
import "../../../../styles/filterModalComponent.scss";
import SearchableDropDown from "../../../Common/SearchableDropDown/SearchableDropDown";
import {CountryIcons} from "../../../Common/CountryIcons";
import {useDispatch, useSelector} from "react-redux";
import {CHANGE_MARKET_PLACE_STATE} from "../../../../store/types";

const MobileFilterModal = ({countryStateValue, setCountryStateValue}) => {
  const dispatch = useDispatch();
  const {countryData, paymentMethod} = useSelector((state) => state.generalReducer);
  const {limit, fskip} = useSelector((state) => state.marketReducer);
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  const thePaymentMethod = paymentMethod?.data?.paymentsData?.paymentMethod?.map((item) => ({
    attr: "paymentMethod",
    value: item.name,
    label: item.name,
  }));

  return (
    <div className="__filter__Modal__Mobile">
      {/* <h3 className="__filterTitle">Filter</h3> */}

      <div className="___ggd">
        <div className="__the__options">
          <p className="__filter__optionLabel">Payment Method</p>
          <TransactionStaticDropDown
            options={thePaymentMethod}
            initialValue={{
              attr: "paymentMethod",
              value:
                marketPlaceData?.paymentMethod === null || marketPlaceData?.paymentMethod === undefined
                  ? "Select Method"
                  : marketPlaceData?.paymentMethod,
              label:
                marketPlaceData?.paymentMethod === null || marketPlaceData?.paymentMethod === undefined
                  ? "Select Method"
                  : marketPlaceData?.paymentMethod,
            }}
            dropdownOnChangeFunction={(item) =>
              dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {paymentMethod: item.paymentMethod}})
            }
            name={"paymentMethod"}
            useLabel={true}
            style={{margin: 0}}
          />
        </div>
        <div className="__the__options">
          <p className="__filter__optionLabel">Select Country</p>
          <div className="">
            <SearchableDropDown
              name={"countryName"}
              iconsLead={true}
              nameAcr={"alpha3"}
              DetailsIcons={CountryIcons}
              search={countryStateValue && countryStateValue}
              setSearch={setCountryStateValue}
              placeholder={"Please Search for a country"}
              HandleSelect={(val) => {
                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {country: val.countryName}});
                setCountryStateValue({...val, name: val.countryName, nameAcr: val.alpha3});
              }}
              apiList={countryData?.data?.countriesData}
              style={{
                // margin: 0,
                borderRadius: "20px",
                border: "1px solid #eff0f6",
                boxShadow: "none",
                padding: "0 15px",
              }}
            />
          </div>
        </div>
        <div className="__the__options">
          <p className="__filter__optionLabel">Amount</p>
          <div
            className="__the__amountType __gg"
            style={{
              margin: 0,
              borderRadius: "20px",
              border: "1px solid #eff0f6",
              boxShadow: "none",
              padding: "0 15px",
              height: "40px",
            }}
          >
            <input
              className="__amount__input"
              placeholder="Enter Amount"
              onChange={(e) => {
                if (e.target.value) {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {amount: e.target.value}});
                } else {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {amount: null}});
                }
              }}
              value={marketPlaceData?.amount}
            />
            <span className="__currency">NGN</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileFilterModal;
