import React, {useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import SettingsService from "../../services/settingsServices";
import {
  getSelectedCountryAction,
  modalInformationDetails,
  settingVerifyModal,
  storeVerifyLinkURL,
} from "../../store/actions/settingAction";
import {Loader4} from "../Common/Loader";
import {Skeleton} from "@mui/material";
import "../../styles/new/settings.scss";

const VerificationButton = () => {
  const modalInfo = useSelector((state) => state.generalReducer.userData);

  const verifyme = modalInfo ?? "";

  const [isLoading, setIsloading] = useState(false);

  const dispatch = useDispatch();
  const address = modalInfo?.data?.userData?.address?.split(",") ?? "";

  const returnUser = () => {
    dispatch(
      modalInformationDetails({
        firstName: modalInfo?.data?.userData?.firstName ?? "",
        lastName: modalInfo?.data?.userData?.lastName ?? "",
        middleName: modalInfo?.data?.userData?.middleName ?? "",
        residenceAddress: address[0].trim() ?? "",
        cityAddress: address[1].trim() ?? "",
        stateAddress: address[2].trim() ?? "",
        userCountry: modalInfo?.data?.userData?.userCountry,
        // address: address ?? "",
      })
    );

    dispatch(getSelectedCountryAction(modalInfo?.data?.userData?.userCountry ?? ""));
    dispatch(settingVerifyModal(0));
  };

  const toggle = () => {
    dispatch(getSelectedCountryAction(modalInfo?.data?.userData?.userCountry ?? ""));

    if (modalInfo?.data?.userData?.phoneNumberVerified === false) {
      dispatch(settingVerifyModal(15));
    } else {
      dispatch(settingVerifyModal(0));
    }
  };

  useMemo(() => modalInfo, [modalInfo]);
  useMemo(() => verifyme, [verifyme]);
  useMemo(() => isLoading, [isLoading]);

  const Continue = () => {
    const payload = {
      firstName: verifyme.data?.userData?.firstName,
      lastName: verifyme.data?.userData?.lastName,
      middleName: verifyme.data?.userData?.middleName,
      address: verifyme.data?.userData?.address,
      userCountry: verifyme.data?.userData?.userCountry,
    };

    setIsloading(true);
    SettingsService.UserVerification(payload)
      .then((data) => {
        if (data.data.message === "success") {
          // if (modalInfo?.data?.userData?.phoneNumberVerified === true) {
          setIsloading(false);
          dispatch(settingVerifyModal(2));
          dispatch(storeVerifyLinkURL(data?.data?.verifyLinkURL));
          // } else {
          //   setIsloading(false);
          //   dispatch(settingVerifyModal(15));
          // }
        }
      })
      .catch((error) => {
        setIsloading(false);
        toast.error(error);
      });
  };

  const VerifyNow = () => {
    if (
      verifyme?.data?.userData?.verificationStatus === null ||
      verifyme?.data?.userData?.verificationStatus === undefined
    ) {
      return (
        <React.Fragment>
          <button className="verificationBtn" onClick={toggle}>
            Verify Now
          </button>
        </React.Fragment>
      );
    } else if (
      verifyme?.data?.userData?.verificationStatus === "initiated" ||
      verifyme?.data?.userData?.verificationStatus === null
    ) {
      return (
        <React.Fragment>
          <button className="verificationBtn" disabled={isLoading ? true : false} onClick={Continue}>
            {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Continue verification</p>}
          </button>
        </React.Fragment>
      );
    } else if (verifyme?.data?.userData?.verificationStatus === "processing") {
      return (
        <React.Fragment>
          <div style={boxStyle}>Processing</div>
        </React.Fragment>
      );
    } else if (verifyme?.data?.userData?.verificationStatus === "complete") {
      return (
        <React.Fragment>
          <div style={boxStyle}>In Review</div>
        </React.Fragment>
      );
    } else if (verifyme?.data?.userData?.verificationStatus === "rejected") {
      return (
        <React.Fragment>
          <div style={{display: "flex", flexDirection: "column", width: "100%", gap: "2rem"}}>
            <button className="verificationBtn" disabled={isLoading ? true : false} onClick={returnUser}>
              {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Try again</p>}
            </button>
            <p style={{fontSize: "1.4rem", color: "#cf304a", fontWeight: 400}}>
              Verification failed. Please try again.
            </p>
          </div>
        </React.Fragment>
      );
    } else if (verifyme?.data?.userData?.isVerified === true) {
      return (
        <React.Fragment>
          <div style={{...boxStyle, background: "rgba(0, 164, 120, 0.2)", color: "#00A478"}}>Verified</div>
        </React.Fragment>
      );
    }
  };

  return !verifyme?.data?.userData ? (
    <Skeleton
      animation="wave"
      variant="rectangular"
      style={{
        ...SkeletonStyle,
        width: "200px",
      }}
    />
  ) : (
    <VerifyNow />
  );
};

const SkeletonStyle = {
  fontSize: "2.5rem",
  fontWeight: "600",
  marginBottom: "2rem",
  background: "rgba(255,255,255,1)",
  width: "300px",
};

const boxStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "110px",
  height: "29px",
  background: "rgba(243, 186, 47, 0.2)",
  fontSize: "1.2rem",
  fontWeight: "500",
  lineHeight: "19px",
  textAlign: "center",
  color: "#F3BA2F",
  borderRadius: "16px",
};

export default VerificationButton;
