import {useContext, useEffect} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {getUserType} from "../../../../hooks/getUserType";
import {BNB, BTC, ETH, NOTFOUND, USDT} from "../../../../hooks/imagePath";
import {
  getBuySellDisputeTransactionAction,
  getBuySellOnNextAction,
  getBuySellOnPrevAction,
  getBuySellPendingTransactionAction,
  getBuySellTransactionAction,
} from "../../../../store/actions/buySellTransactionHistory";
import {
  CHANGE_PAGINATION,
  GET_LOADER,
  GET_PENDING_TRADE,
  TRADE_CHECK_FOR_EMPTY,
  TRADE_CHECK_FOR_EXTRA_DATA,
  TRADE_SKIP,
} from "../../../../store/types";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {DOMTablePaginationComponent2} from "../../../Common/DOM_Pagination/index2";
import {Loader} from "../../../Common/Loader";
import {TablePaginationComponent} from "../../../Common/pagination";
import {TheUserTradeContext} from "../../../Home/UserTrade";
import UserTradeService from "../../../../services/getusertrades";

const UserTradeDesktopBuySellTable = ({data, loading}) => {
  const {tradeOrder} = useContext(TheUserTradeContext);
  const {push} = useHistory();
  const dispatch = useDispatch();
  const {userData} = useSelector((state) => state.generalReducer);
  const {pageSize, currentPage} = useSelector((state) => state.domPaginationReducer);

  const {skip, fSkip, limit, checkForExtraData, checkForEmpty, tradeDetials} = useSelector(
    (state) => state.getUserTradeReducer
  );

  // useEffect(() => {
  //   dispatch({type: CHANGE_PAGINATION, payload: 1});

  //   return () => {
  //     dispatch({type: CHANGE_PAGINATION, payload: 1});
  //   };
  // }, [data]);

  const onNext = () => {
    const skipDetails = (skip + 1) * limit;
    if (checkForEmpty?.length !== 0) {
      dispatch({type: GET_LOADER, payload: true});

      UserTradeService.OnlyPendingTradeService(skipDetails, limit).then((data) => {
        dispatch({type: GET_PENDING_TRADE, payload: []});

        if (data?.message === "success") {
          if (data?.trades?.length > 0) {
            dispatch({type: GET_PENDING_TRADE, payload: data?.pendingTrades});
            dispatch({type: TRADE_SKIP, payload: skip + 1});
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.pendingTrades});
            dispatch({type: GET_LOADER, payload: false});
            if (data?.pendingTrades?.length === limit) {
              dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: true});
              const futureSkipDetails = skipDetails + limit;

              UserTradeService.OnlyPendingTradeService(futureSkipDetails, limit).then((data) => {
                if (data?.pendingTrades?.length === 0) {
                  dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
                  dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
                } else {
                  dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
                }
              });
            }
          } else {
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
            dispatch({type: GET_LOADER, payload: false});
          }
        }
      });
    }
  };

  const onPrev = () => {
    dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: null});
    const skipDetails = (skip - 1) * limit;
    if (data?.length > 0 && skip + 1 > 1) {
      dispatch({type: GET_LOADER, payload: true});
      UserTradeService.OnlyPendingTradeService(skipDetails, limit).then((data) => {
        dispatch({type: GET_PENDING_TRADE, payload: []});
        if (data.message === "success") {
          dispatch({type: GET_PENDING_TRADE, payload: data?.pendingTrades});
          dispatch({type: TRADE_SKIP, payload: skip - 1});
          dispatch({type: GET_LOADER, payload: false});
        }
      });
    }
  };

  return (
    <>
      <div className="table__wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="tablew">
              <thead className="newTable__thead">
                <tr className="newTable__tr">
                  <th className="newTable__thh">Coin Type</th>
                  <th className="newTable__thh">Type</th>
                  <th className="newTable__thh">Method</th>
                  <th className="newTable__thh">Unit Price</th>
                  <th className="newTable__thh">Amount</th>
                  <th className="newTable__thh">Counter Party</th>
                  <th className="newTable__thh">Country</th>

                  <th className="newTable__thh">Status</th>
                  <th className="newTable__thh">View More</th>
                </tr>
              </thead>

              <tbody className="newTable__tbody">
                {data &&
                  data.length > 0 &&
                  data.map((item, i) => {
                    return (
                      <tr className="myTable" style={{height: "40px", backgroundColor: "#FFF9DF"}} key={i}>
                        <td className="tableItemm">
                          <div className="transactionTableArea__coinWrapper">
                            <img
                              src={
                                item?.tokenSymbol === "USDT"
                                  ? USDT
                                  : item?.tokenSymbol === "BTC"
                                  ? BTC
                                  : item?.tokenSymbol === "ETH"
                                  ? ETH
                                  : BNB
                              }
                              alt="usdt icon"
                              className="transactionTableArea__coinImage"
                            />
                            <span className="transactionTableArea__coin">{item?.tokenSymbol}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__typeWrapper">
                            <span className="transactionTableArea__type">{item?.type}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">{item?.paymentMethod}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">
                              <CurrencyFormat
                                value={item?.unitPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                suffix={` ${item?.currency}`}
                              />
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__amountWrappers">
                            <span className="transactionTableArea__amount">
                              <CurrencyFormat
                                value={item?.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                suffix={` ${item?.currency}`}
                              />
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            {getUserType(item, userData) === "trader" ? (
                              <Link
                                to={"/app/profile/" + item?.profileData?.userData?.username}
                                target={"_blank"}
                                className="transactionTableArea__wallet __countParty"
                              >
                                @{item?.profileData?.userData?.username}
                              </Link>
                            ) : (
                              <Link
                                to={"/app/profile/" + item?.profileData?.traderData?.username}
                                target={"_blank"}
                                className="transactionTableArea__wallet __countParty"
                              >
                                @{item?.profileData?.traderData?.username}
                              </Link>
                            )}
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">{item?.country}</span>
                          </div>
                        </td>

                        <td className="tableItemm">
                          <div className="transactionTableArea__statusWrappers">
                            <span
                              className={`transactionTableArea__status ${
                                item?.status === "cancelled"
                                  ? "__cancelled"
                                  : item?.status === "pending"
                                  ? "__pending"
                                  : "__complete"
                              }`}
                            >
                              {capitalizeFirstLetter(item?.status)}
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__viewMoreWrappers">
                            <span
                              className="transactionTableArea__viewMore"
                              onClick={() => {
                                // setModalData(value);
                                push(`/app/trade/${item?.trade_id}`);
                              }}
                            >
                              view trade
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}

        {!loading && data && data.length === 0 && (
          <div className="__notFound__wrapperr">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}

        {data && data?.length !== 0 && data !== null && data !== undefined && (
          <TablePaginationComponent
            onNext={onNext}
            onPrev={onPrev}
            skip={skip}
            lengthChecker={checkForEmpty}
            dataCheck={data}
            limit={limit}
            loading={checkForExtraData}
          />
        )}
      </div>
    </>
  );
};

export default UserTradeDesktopBuySellTable;
