import React, {memo} from "react";
import {useSelector} from "react-redux";
import {Link} from "react-router-dom";
import "../../styles/overviewStyles/overviewCards.scss";

const OverviewCards = ({DetailedArray, onclick}) => {
  const userData = useSelector((state) => state.generalReducer.userData);

  return (
    <>
      {DetailedArray.map((item, index) => (
        <>
          {item?.linkData === "Sell" ? (
            <>
              {userData?.data?.userData?.isVerified && (
                <div key={index} className={"OverviewCards"}>
                  <div className={"OverviewCardsFirstSection"}>
                    {/* <div className={"OverviewCardsFirstSectionImageBody"}>
                  <img src={item?.diagram} alt="diagram details" className={"OverviewCardsFirstSectionImage"} />
                  </div> */}
                    <div className={"OverviewCardsFirstSectionDetails"}>
                      <h4>{item?.title}</h4>
                      <p>{item?.details}</p>
                    </div>
                  </div>

                  <div className={"OverviewCardsSecondSection"}>
                    <div className={"OverviewCardsSecondSectionDetails"}>
                      {item.name === "button" && <button onClick={onclick}>{item?.buttonContent}</button>}

                      {item.name === "link" && (
                        <Link to={item.link} className={"OverviewCardsSecondSectionDetailslinks"}>
                          <button>{item.linkData}</button>
                        </Link>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div key={index} className={"OverviewCards"}>
              <div className={"OverviewCardsFirstSection"}>
                {/* <div className={"OverviewCardsFirstSectionImageBody"}>
              <img src={item?.diagram} alt="diagram details" className={"OverviewCardsFirstSectionImage"} />
            </div> */}
                <div className={"OverviewCardsFirstSectionDetails"}>
                  <h4>{item?.title}</h4>
                  <p>{item?.details}</p>
                </div>
              </div>

              <div className={"OverviewCardsSecondSection"}>
                <div className={"OverviewCardsSecondSectionDetails"}>
                  {item.name === "button" && <button onClick={onclick}>{item?.buttonContent}</button>}

                  {item.name === "link" && (
                    <Link to={item.link} className={"OverviewCardsSecondSectionDetailslinks"}>
                      <button>{item.linkData}</button>
                    </Link>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  );
};

export default memo(OverviewCards);
