import React, {memo} from "react";
import {useHistory} from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import logoMobile from "../../assets/icons/logoMobile.png";
import ProfileAvatarMobile from "../HomeTopBar/ProfileAvatarMobile";
import MobileSideBar from "../HomeTopBar/MobileSideBar";
import "../../styles/navbar.scss";

import "../../styles/common/InstantSell.scss";

const HomeMobileTopBar = () => {
  const history = useHistory();

  const route = (path) => {
    history.push(path);
  };

  return (
    <React.Fragment>
      <div className="mobile-bar">
        <AppBar
          sx={AppBarStyle}
          position="absolute"
          style={{backgroundColor: process.env.REACT_APP_ENV === "PRODUCTION" ? "#53389e" : "#FFF5DD"}}
          elevation={0}
        >
          <Toolbar sx={toolbar}>
            <div className="side-app-bar" onClick={() => route("/app/overview")}>
              <img style={logoMobileStyle} src={logoMobile} alt="icon" />
            </div>
            <div style={RightSideStyle}>
              <p className="instantSellButton" onClick={() => route("/app/sell-crypto")}>
                Instant sell
              </p>
              <ProfileAvatarMobile />
              <MobileSideBar />
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

const logoMobileStyle = {
  width: "30px",
  height: "30px",
  marginLeft: "10px",
};

const RightSideStyle = {
  display: "flex",
  alignItems: "center",
  gap: "30px",
};

const toolbar = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const AppBarStyle = {
  // fontSize: "0.9rem",
  background: "#5d3cb2",
  zIndex: "10",
};

export default memo(HomeMobileTopBar);
