import React, {useState} from "react";
import "../styles/landing-page-styles/faq.scss";
import AboutOyolaUniqueSection from "../components/LandingComponents/about-oyola-unique-section/about-oyola-unique-section.component";
import {supportdata} from "../data/support.data";
import Layout from "../components/LandingComponents/layout";
const FAQ = () => {
  const [searchList, setSearchList] = useState([]);
  const [query, setQuery] = useState("");

  const handleChange = (e) => {
    const {value} = e.target;
    setQuery(value);
    const searchArray = supportdata.filter((item) => item?.title?.toLowerCase().includes(value?.toLowerCase()));

    setSearchList(searchArray);
  };

  return (
    <Layout>
      <div className="faqWrapper" id="video-tutorials">
        <div className="faqWrapperHeader" style={{backgroundColor: "#FFFCF5"}}>
          <div className="faqWrapperHeaderWrapper">
            <p className="faqWrapperHeaderTitle">Frequently asked questions</p>
            <p className="faqWrapperHeaderText">
              Get answers and explore everything you need to know about your question at your fingertips.
            </p>

            {/* <div>
            <AboutTextDetails
              header={"Learn about Oyola"}
              bodyText={"Watch our short explainer videos to understand the world of crypto on Oyola"}
              headerStyle={{color: "#101828"}}
              bodyStyle={{color: "#667085"}}
            />
          </div> */}

            <div className="faqWrapperHeaderSearch">
              <input
                value={query}
                onChange={handleChange}
                placeholder="Search through our frequently asked questions"
                type={"text"}
                className="faqWrapperHeaderSearchInput"
              />
              {/* <button onClick={HandleSearchSystem} className={styles.faqWrapperHeaderButton}>
              Search
            </button> */}
            </div>
          </div>
        </div>

        <div className="faqWrapperFaq">
          {/* <p className={styles.faqWrapperFaqHeader}>FAQs</p> */}
          <div className="faqWrapperFaqHeaderDetails">
            {/* <AboutTextDetails
            header={"Frequently asked questions"}
            bodyText={"Have questions? We’re here to help."}
            headerStyle={{color: "#101828"}}
            bodyStyle={{color: "#667085"}}
          /> */}
          </div>
          {/* <div className={styles.faqWrapperFaqSearch}>
          <div className={styles.faqWrapperFaqSearchInput}>
            <div className={styles.faqWrapperFaqSearchInputIconBody}>
              <FiSearch className={styles.faqWrapperFaqSearchInputIcon} />
            </div>
            <input className={styles.faqWrapperFaqSearchInputField} placeholder={"Search"} onChange={handleChange} />
          </div>
        </div> */}
          {/* <div style={{display: "flex"}}>
            <Accordion />
            <Accordion />
            <Accordion />
          </div> */}
          <div className="faqWrapperFaqList">
            {query !== "" && query !== null
              ? searchList?.map((item, key) => (
                  <div key={key} className="faqWrapperFaqListWrapper">
                    <AboutOyolaUniqueSection header={item.title} description={item.description} id={item.id} />
                  </div>
                ))
              : supportdata.map((item, key) => (
                  <div key={key} className="faqWrapperFaqListWrapper">
                    <AboutOyolaUniqueSection header={item.title} description={item.description} id={item.id} />
                  </div>
                ))}
          </div>
        </div>
      </div>
    </Layout>
  );
};

// const faqAnswer = [
//   {
//     title: "What is Oyola?",
//     description:
//       "Oyola is a peer-to-peer platform where you can exchange fiat (native currencies) for crypto and vice versa.",
//   },
//   {
//     title: "How am I protected from being scammed?",
//     description:
//       "With the escrow security, your money or crypto is kept in a third-party account called escrow and is only released when the transaction is complete.",
//   },
//   {
//     title: "Can I send bitcoin with Oyola?",
//     description: "Yes, you can send bitcoin with Oyola but a gas fee will be charged.",
//   },
//   {
//     title: "How can I protect my account?",
//     description: "How can I protect my account?",
//   },
//   {
//     title: "Can I trust Oyola?",
//     description:
//       "We take adequate measures to make sure our platform is safe and secure for trading. Our strict verification process enables us to scrutinize people that come on the platform to prevent scam or fraudulent practices. We also implement escrow security which helps reduce lost funds, or cryptocurrencies.",
//   },
//   {
//     title: "Can I receive bitcoin in my wallet?",
//     description: "Yes, you can receive bitcoin with Oyola. Although, a gas fee will be charged.",
//   },
//   {
//     title: "What are the monthly or daily limits?",
//     description: "There are no limits to your transactions. With Oyola, you can buy and sell without limits. ",
//   },
//   {
//     title: "What cryptocurrencies are available on Oyola?",
//     description:
//       "Currently, you can trade with BTC, USDT, BUSD, ETH and BTCB. We are looking to add more as time goes on.",
//   },
//   {
//     title: "Does Oyola provide a wallet?",
//     description:
//       "Oyola provides a safe, free, and secure wallet where you can store all your cryptocurrency. You can also see and track all your cryptocurrencies in real-time.",
//   },
//   {
//     title: "What do I do if I send funds to the wrong address?",
//     description:
//       "Mistakes do happen. Some are reversible and others, irreversible. In this situation, transactions like this cannot be resolved. Cryptocurrency transactions can neither be canceled nor reversed. Unless you know who owns the wallet address, there is little or nothing that can be done. Note: always confirm wallet address before making a transaction.",
//   },
//   {
//     title: "What’s the reward system?",
//     description:
//       "When you refer people to the platform you get 10$ to trade on the app. The person referred also gets $10.",
//   },
//   {
//     title: "What is a dispute?",
//     description:
//       "A dispute refers to a breach of an agreement between two parties (either on the buyer or seller’s part) who are involved in a transaction.",
//   },
//   {
//     title: "When should I file a dispute?",
//     description:
//       "You can file a dispute when you notice that: The time has run out but yet to receive the cryptocurrency or the seller is not responding to you even after reaching out.",
//   },
//   {
//     title: "How is a dispute settled?",
//     description:
//       "You would be asked to bring some documents depending on the transaction and the method of payment used. Please be reminded that how prompt you submit these details determines how fast the issue will be resolved.",
//   },
//   {
//     title: "What country is Oyola available in?",
//     description: "What country is Oyola available in?",
//   },
//   {
//     title: "What are the payment methods available?",
//     description:
//       "There are different payment methods available for sending and receiving crypto assets. They include: Bank transfers, Credit/debit cards, Online wallets, M-PESA and lots more. ",
//   },
//   {
//     title: "How much is the fee?",
//     description:
//       "Currently, P2P transactions are free. This means that you can exchange your crypto assets and fiat for free.",
//   },
//   {
//     title: "How can I claim my bonus?",
//     description: "You can claim your bonus through your referral code or link.",
//   },
// ];

export default FAQ;
