import React from "react";
import ConvertIndex from "../convert";

const Convert = () => {
  return (
    <div>
      <ConvertIndex />
    </div>
  );
};

export default Convert;
