import React, {memo} from "react";
import ButtonSection from "./buttonSection";
import ImageSection from "./imageSection";
import TextSection from "./textSection";
import "../../styles/oop/oopStyles.scss";

const MainComponent = () => {
  return (
    <div className="oopWrapper">
      <div className="oopWrapper__ImageSection">
        <ImageSection />
      </div>
      <div className="oopWrapper__TextSection">
        <TextSection />
      </div>
      <div className="oopWrapper__ButtonSection">
        <ButtonSection />
      </div>
    </div>
  );
};

export default memo(MainComponent);
