import {useContext, useEffect, useRef} from "react";
import CurrencyFormat from "react-currency-format";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import {BiInfoCircle} from "react-icons/bi";
import {RiErrorWarningLine} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import {Avatar} from "../../../../hooks/imagePath";
import {useGetTokenBalance} from "../../../../hooks/useGetTokenBalance";
import {
  modalOnChangeCurrencyPriceAction,
  modalOnChangeTokenPriceAction,
  modalOnChangeTokenPriceMaxAction,
  setCreateTradeDefaultAction,
} from "../../../../store/actions/checkoutData";
import {createBuySellTradeAction} from "../../../../store/actions/createTradeAction";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../utils/getTimeDiff";
import {Loader, Loader4, LoaderWhite} from "../../../Common/Loader";
import {BsCheck, BsFillCheckCircleFill} from "react-icons/bs";
import {TradeActionContext} from "../../../Home/Profile";
import "../../../../styles/marketplacestyles/marketplacestyles.scss";
import "../../../../styles/marketplacestyles/marketplace_loader.scss";
import {useState} from "react";
import ConvertCardInputComponentMarketPlace from "../../../MarketPlace/tradeActionModal/desktop/ConvertCardInputComponentmarketplace";
import {formatBigNumber} from "../../../../utils/bigNumberFormatter";
import {FiX} from "react-icons/fi";
import PostTradeService from "../../../../services/postTradeService";
import {Skeleton} from "@mui/material";

const DeskTopActionModal = ({}) => {
  const checkFeesRef = useRef();
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {setModalAction} = useContext(TradeActionContext);
  const [showCondition, setShowCondition] = useState(false);

  const [feesPrice, setFeesPrice] = useState(0);
  const [totalCoinPrices, setTotalPrices] = useState(0);

  const [feesChecker, setFeesChecker] = useState(false);

  const {
    unitPrice,
    loading,
    response,
    currencyPrice,
    tokenPrice,
    error: checkOutError,
  } = useSelector((state) => state.checkOutDataReducer);

  const userData = useSelector((state) => state.generalReducer.userData);

  const {
    response: createResponse,
    error,
    loading: createTradeLoading,
  } = useSelector((state) => state.createTradeReducer);

  const [tokenBalance] = useGetTokenBalance(response?.checkOutData?.tokenSymbol?.toUpperCase());

  const currentTotalValue = useSelector((state) => state.marketReducer?.currentTotalValue);

  const handleChangeCurrency = (e) => {
    e.persist();
    dispatch(modalOnChangeCurrencyPriceAction(e.target.value));

    if (response?.checkOutData?.type?.toLowerCase() === "buy") {
      clearTimeout(checkFeesRef.current);

      setFeesChecker(true);
      checkFeesRef.current = setTimeout(async () => {
        try {
          const getPayload = e.target.value.split(",").join("");

          const price = Number(getPayload) / Number(unitPrice ?? 0);

          const res = await PostTradeService.getTradeFees(Number(price));

          setFeesPrice(res.fees);
          setTotalPrices(Number(price) + res.fees);

          setFeesChecker(false);
        } catch (err) {
          setFeesChecker(false);
        }
      }, 300);
    }
  };

  const handleChangeToken = (e) => {
    e.persist();
    dispatch(modalOnChangeTokenPriceAction(e.target.value));

    if (response?.checkOutData?.type?.toLowerCase() === "buy") {
      clearTimeout(checkFeesRef.current);

      setFeesChecker(true);
      checkFeesRef.current = setTimeout(async () => {
        try {
          const getPayload = e.target.value.split(",").join("");

          const res = await PostTradeService.getTradeFees(Number(getPayload));

          setFeesPrice(res.fees);
          setTotalPrices(Number(getPayload) + res.fees);

          setFeesChecker(false);
        } catch (err) {
          setFeesChecker(false);
        }
      }, 300);
    }
  };

  const onAction = async () => {
    dispatch(createBuySellTradeAction({orderID: response?.checkOutData?._id, amount: tokenPrice}));
  };

  useEffect(() => {
    if (createResponse) {
      push(`/app/trade/${createResponse.trade_id}`);
    }
  }, [createResponse]);

  useEffect(() => {
    return () => {
      dispatch(setCreateTradeDefaultAction());
      // dispatch(getBuySellTradeDefaultAction());
    };
  }, []);

  const HandleMaxAmount = () => {
    if (response?.checkOutData?.type?.toLowerCase() === "buy") {
      clearTimeout(checkFeesRef.current);

      setFeesChecker(true);
      checkFeesRef.current = setTimeout(async () => {
        try {
          const res = await PostTradeService.getTradeFees(Number(currentTotalValue));

          setFeesPrice(res.fees);
          setTotalPrices(Number(currentTotalValue));

          const valueReached = Number(currentTotalValue) - res.fees;

          dispatch(modalOnChangeTokenPriceMaxAction(valueReached.toString()));
          setFeesChecker(false);
        } catch (error) {
          setFeesChecker(false);
        }
      }, 300);
    }
  };

  return (
    <>
      {error || checkOutError ? (
        <div className="__desktop__wrapper">
          <div className="__innerWarning__wrapper">
            <div className="__the__inner">
              <RiErrorWarningLine color="#FBCB5C" className="__icon" />
              <p className="__the__p">
                {checkOutError && `${checkOutError}`}
                {error && `${error}`}
              </p>
              <button className="__warning__button" onClick={() => setModalAction(false)}>
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : loading ? (
        <div className="marketplaceLoader">
          <LoaderWhite style={{height: "70vh"}} />
        </div>
      ) : (
        <>
          <div className="marketplaceWrapperModal">
            <div className="marketplace_modalhalf_one">
              <div className="marketplace_modalhalf_one_container">
                <div className="__modal__container__top">
                  <div className="__modal__container__top__name">
                    {/* {console.log(response, "lest toadkj")} */}
                    <a
                      href={`/app/profile/${response?.checkOutData?.username}`}
                      rel="noreferrer"
                      target="_blank"
                      style={{cursor: "pointer"}}
                    >
                      <div className="__top__imageWrapper" style={{backgroundImage: `url(${Avatar})`}}>
                        <div
                          className="__top__imageNotification"
                          style={{
                            backgroundColor:
                              getTimeDifference(response?.traderData?.timeLastSeen) < 5
                                ? "#59C14C"
                                : getTimeDifference(response?.traderData?.timeLastSeen) > 5 &&
                                  getTimeDifference(response?.traderData?.timeLastSeen) < 30
                                ? "#F8E3B2"
                                : "#DBDBDB",
                          }}
                        ></div>
                      </div>
                    </a>

                    <div className="__modal__container_items__end_text">
                      <a
                        href={`/app/profile/${response?.checkOutData?.username}`}
                        rel="noreferrer"
                        target="_blank"
                        style={{cursor: "pointer"}}
                      >
                        <div className="__modal__container_items__end_text__time__name">
                          <div className="__the__name_container">
                            <p className="__the__name">{response?.checkOutData?.username}</p>

                            <div className="__modal__container_items__end_text_icon_body">
                              <BsCheck className="__modal__container_items__end_text_icon" />
                            </div>
                          </div>
                          <p className="__timeAgo">
                            {getTimeDifference(response?.traderData?.timeLastSeen) < 5 ? (
                              "online"
                            ) : (
                              <ReactTimeAgo date={response?.traderData?.timeLastSeen} style={{fontSize: "10px"}} />
                            )}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                </div>

                <div className="__modal__container__top_bottom">
                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Completed orders</p>
                    <p className="__modal__container__top_bottom_tap_content">
                      {response?.traderData?.totalNumberOfTrades}
                    </p>
                  </div>
                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Completion rate</p>
                    <p className="__modal__container__top_bottom_tap_content">
                      <CurrencyFormat
                        value={
                          (response?.traderData?.numberCompletedTrades / response?.traderData?.totalNumberOfTrades) *
                          100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={1}
                        suffix={`%`}
                        fixedDecimalScale
                      />
                    </p>
                  </div>
                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Payment time limit</p>
                    <p className="__modal__container__top_bottom_tap_content">
                      {response?.checkOutData?.closingTime} Mins
                    </p>
                  </div>
                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Payment method</p>
                    <p className="__modal__container__top_bottom_tap_content">
                      {response?.checkOutData?.paymentMethod}
                    </p>
                  </div>
                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Country</p>
                    <p className="__modal__container__top_bottom_tap_content">{response?.checkOutData?.country}</p>
                  </div>

                  <div className="__modal__container__top_bottom_tap">
                    <p className="__modal__container__top_bottom_tap_discription">Order size</p>
                    <p className="__modal__container__top_bottom_tap_content">
                      <CurrencyFormat
                        value={Math.abs(response?.checkOutData?.minAmount)}
                        // value={Math.abs(data?.unitPrice)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${response?.checkOutData?.currency}`}
                      />
                      {" - "}
                      <CurrencyFormat
                        value={Math.abs(response?.checkOutData?.maxAmount)}
                        // value={Math.abs(data?.unitPrice)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${response?.checkOutData?.currency}`}
                      />
                    </p>
                  </div>

                  {response?.checkOutData?.type?.toLowerCase() === "buy" && (
                    <div className="__modal__container__top_bottom_tap">
                      <p className="__modal__container__top_bottom_tap_discription">Fees Selling Percentage</p>
                      <p className="__modal__container__top_bottom_tap_content">0.5%</p>
                    </div>
                  )}
                </div>
              </div>

              <div className="marketplace_modalhalf_one_down_container">
                <div className="marketplace_modalhalf_one_down_container_wrapper">
                  <p className="marketplace_modalhalf_one_down_container_wrapper_text">Terms & Condition</p>
                </div>
                <div
                  className={`marketplace_modalhalf_one_down_container_wrapper_conditions ${
                    showCondition ? "seasonWrapper" : ""
                  }`}
                >
                  <p className="marketplace_modalhalf_one_down_container_wrapper_conditions_content">
                    <span
                      className={
                        !showCondition ? `marketplace_modalhalf_one_down_container_wrapper_conditions_content_span` : ""
                      }
                    >
                      {response?.checkOutData?.description}
                    </span>
                    {!showCondition && (
                      <span
                        className="marketplace_modalhalf_one_down_container_wrapper_conditions_content_action"
                        onClick={() => setShowCondition(true)}
                      >
                        Show more
                      </span>
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="marketplace_modalhalf_two">
              <div className="marketplace_modalhalf_two_close">
                <div
                  className="marketplace_modalhalf_two_close_body_icon"
                  onClick={() => {
                    setModalAction(false);
                  }}
                >
                  <FiX className="marketplace_modalhalf_two_close_icon" />
                </div>
              </div>

              <div className="rightSideConvertComponentCardss">
                <div className="ConvertComponentCardHeader">
                  <p className="ConvertComponentCardHeaderTitleStyle">{`Enter ${capitalizeFirstLetter(
                    response?.checkOutData?.type
                  )} Order Details`}</p>
                </div>

                <div className="ConvertComponentCardHeaderUnit">
                  <p className="ConvertComponentCardHeaderUnitPrice">
                    Unit Price:{" "}
                    <span className="ConvertComponentCardHeaderUnitPriceDetails">
                      <CurrencyFormat
                        value={response?.checkOutData?.unitPrice ?? 0}
                        // value={Math.abs(data?.unitPrice)}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${response?.checkOutData?.currency}`}
                      />
                    </span>
                  </p>
                </div>

                <div className="ConvertComponentCardBody">
                  {response?.checkOutData?.type?.toLowerCase() === "sell" ? (
                    <>
                      <div className="ConvertComponentCardItemTwo">
                        <ConvertCardInputComponentMarketPlace
                          label="I will pay"
                          value={currencyPrice}
                          handleChageSystem={handleChangeCurrency}
                          countryTitle={response?.checkOutData?.country}
                          coinTitle={response?.checkOutData?.currency?.toUpperCase()}
                          convertTokenError={
                            parseFloat(
                              typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                            ) > Math.abs(response?.checkOutData?.maxAmount)
                              ? `Max Amount`
                              : parseFloat(
                                  typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                                ) < Math.abs(response?.checkOutData?.minAmount)
                              ? `Min Amount`
                              : ""
                          }
                        />

                        {parseFloat(
                          typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                        ) > Math.abs(response?.checkOutData?.maxAmount) ? (
                          <p className="ConvertComponentCardBodyTitleError">{`Max Amount ${Math.abs(
                            response?.checkOutData?.maxAmount
                          )}`}</p>
                        ) : parseFloat(
                            typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                          ) < Math.abs(response?.checkOutData?.minAmount) ? (
                          <p className="ConvertComponentCardBodyTitleError">{`Min Amount ${Math.abs(
                            response?.checkOutData?.minAmount
                          )}`}</p>
                        ) : (
                          <p className="ConvertComponentCardBodyTitle">
                            Order size ={" "}
                            <CurrencyFormat
                              value={Math.abs(response?.checkOutData?.minAmount)}
                              // value={Math.abs(data?.unitPrice)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              // prefix={"$"}
                              suffix={` ${response?.checkOutData?.currency}`}
                            />
                            {" - "}
                            <CurrencyFormat
                              value={Math.abs(response?.checkOutData?.maxAmount)}
                              // value={Math.abs(data?.unitPrice)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              // prefix={"$"}
                              suffix={` ${response?.checkOutData?.currency}`}
                            />
                          </p>
                        )}
                      </div>

                      <div className="ConvertComponentCardItem">
                        <ConvertCardInputComponentMarketPlace
                          token={true}
                          label={"I will receive"}
                          value={formatBigNumber(
                            typeof tokenPrice === "string" ? tokenPrice?.split(",").join("") : tokenPrice
                          )}
                          handleChageSystem={handleChangeToken}
                          coinTitle={response?.checkOutData?.tokenSymbol}
                          // convertTokenError={convertTokenMaxMinError}
                        />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="ConvertComponentCardItem">
                        <ConvertCardInputComponentMarketPlace
                          token={true}
                          label={"I want to sell"}
                          value={formatBigNumber(
                            typeof tokenPrice === "string" ? tokenPrice?.split(",").join("") : tokenPrice
                          )}
                          handleChageSystem={handleChangeToken}
                          checkforsell={true}
                          handleMax={HandleMaxAmount}
                          convertTokenError={
                            Number(typeof tokenPrice === "string" ? tokenPrice?.split(",").join("") : tokenPrice) >
                              Number(currentTotalValue) || Number(totalCoinPrices) > Number(currentTotalValue)
                              ? `insufficient funds`
                              : ""
                          }
                          coinTitle={response?.checkOutData?.tokenSymbol}
                        />

                        {Number(typeof tokenPrice === "string" ? tokenPrice?.split(",").join("") : tokenPrice) >
                          Number(currentTotalValue) || Number(totalCoinPrices) > Number(currentTotalValue) ? (
                          <>
                            <p className="ConvertComponentCardBodyTitleError">{`insufficient funds`}</p>
                          </>
                        ) : (
                          <p className="ConvertComponentCardBodyTitle">
                            Available ={" "}
                            <CurrencyFormat
                              value={currentTotalValue}
                              thousandSeparator={true}
                              suffix={` ${response?.checkOutData?.tokenSymbol}`}
                              decimalScale={7}
                              displayType={"text"}
                            />
                          </p>
                        )}

                        {feesChecker ? (
                          <div style={{marginBottom: "6px", marginLeft: "20px"}}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={200}
                              height={20}
                              sx={{borderRadius: "8px"}}
                            />
                          </div>
                        ) : (
                          <p className="ConvertComponentCardBodyTitle">
                            Token Fees ={" "}
                            <CurrencyFormat
                              value={feesPrice}
                              thousandSeparator={true}
                              suffix={` ${response?.checkOutData?.tokenSymbol}`}
                              decimalScale={7}
                              displayType={"text"}
                            />
                          </p>
                        )}

                        {feesChecker ? (
                          <div style={{marginBottom: "6px", marginLeft: "20px"}}>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={200}
                              height={20}
                              sx={{borderRadius: "8px"}}
                            />
                          </div>
                        ) : (
                          <p className="ConvertComponentCardBodyTitle">
                            Total Token ={" "}
                            <CurrencyFormat
                              value={totalCoinPrices}
                              thousandSeparator={true}
                              suffix={` ${response?.checkOutData?.tokenSymbol}`}
                              decimalScale={7}
                              displayType={"text"}
                            />
                          </p>
                        )}
                      </div>

                      <div className="ConvertComponentCardItemTwo">
                        <ConvertCardInputComponentMarketPlace
                          label="I will receive"
                          value={currencyPrice}
                          handleChageSystem={handleChangeCurrency}
                          countryTitle={response?.checkOutData?.country}
                          coinTitle={response?.checkOutData?.currency?.toUpperCase()}
                          convertTokenError={
                            parseFloat(
                              typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                            ) > Math.abs(response?.checkOutData?.maxAmount)
                              ? `Max Amount`
                              : parseFloat(
                                  typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                                ) < Math.abs(response?.checkOutData?.minAmount)
                              ? `Min Amount`
                              : ""
                          }
                        />

                        {parseFloat(
                          typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                        ) > Math.abs(response?.checkOutData?.maxAmount) ? (
                          <p className="ConvertComponentCardBodyTitleError">{`Max Amount ${Math.abs(
                            response?.checkOutData?.maxAmount
                          )}`}</p>
                        ) : parseFloat(
                            typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                          ) < Math.abs(response?.checkOutData?.minAmount) ? (
                          <p className="ConvertComponentCardBodyTitleError">{`Min Amount ${Math.abs(
                            response?.checkOutData?.minAmount
                          )}`}</p>
                        ) : (
                          <p className="ConvertComponentCardBodyTitle">
                            Order size ={" "}
                            <CurrencyFormat
                              value={Math.abs(response?.checkOutData?.minAmount)}
                              // value={Math.abs(data?.unitPrice)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              // prefix={"$"}
                              suffix={` ${response?.checkOutData?.currency}`}
                            />
                            {" - "}
                            <CurrencyFormat
                              value={Math.abs(response?.checkOutData?.maxAmount)}
                              // value={Math.abs(data?.unitPrice)}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              // prefix={"$"}
                              suffix={` ${response?.checkOutData?.currency}`}
                            />
                          </p>
                        )}
                      </div>
                    </>
                  )}

                  {userData?.data?.userData?.isVerified === false &&
                  response?.checkOutData?.type?.toLowerCase() === "buy" ? (
                    <button
                      className="ConvertComponrntCardBodyButton"
                      onClick={() => {
                        push("/app/settings");
                      }}
                      style={{background: "#21B0EA"}}
                    >
                      {createTradeLoading ? (
                        <Loader4 />
                      ) : (
                        `Verify`
                      )}
                    </button>
                  ) : (
                    <button
                      className="ConvertComponrntCardBodyButton"
                      disabled={
                        parseFloat(
                          typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                        ) > Math.abs(response?.checkOutData?.maxAmount) ||
                        parseFloat(
                          typeof currencyPrice === "string" ? currencyPrice?.split(",").join("") : currencyPrice
                        ) < Math.abs(response?.checkOutData?.minAmount) ||
                        tokenPrice === 0 ||
                        feesChecker ||
                        !currencyPrice ||
                        createTradeLoading === true
                          ? true
                          : false ||
                            (response?.checkOutData?.type?.toLowerCase() === "sell" &&
                              tokenPrice > currentTotalValue) ||
                            Number(totalCoinPrices) > Number(currentTotalValue)
                      }
                      onClick={() => onAction()}
                    >
                      {createTradeLoading ? (
                        <Loader4 />
                      ) : (
                        `${capitalizeFirstLetter(
                          response?.checkOutData?.type?.toLowerCase() === "sell" ? "Buy" : "Sell"
                        )} ${response?.checkOutData?.tokenSymbol}`
                      )}
                    </button>
                  )}
                </div>
              </div>
            </div>

            {/* <div className="__modal__bottom__right">
              {response?.checkOutData?.type?.toLowerCase() === "sell" ? (
                <div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I want to pay</label>
                    <div>
                      <div
                        className={`__txnInputWrapper ${
                          parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                          parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount)
                            ? "__redborder"
                            : ""
                        }`}
                      >
                        <CurrencyFormat
                          value={currencyPrice}
                          decimalScale={2}
                          placeholder="currency amount"
                          onChange={handleChangeCurrency}
                          className="__txnInput"
                          name="currency"
                        />
                        <span className="__theCurrency">{response?.checkOutData?.currency?.toUpperCase()}</span>
                      </div>
                      <span className="__warning__text">
                        {parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount)
                          ? `Max Amount ${Math.abs(response?.checkOutData?.maxAmount)}`
                          : parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) &&
                            `Min Amount ${Math.abs(response?.checkOutData?.minAmount)}`}
                      </span>
                    </div>
                  </div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I will receive</label>
                    <div className="__txnInputWrapper">
                      <CurrencyFormat
                        value={tokenPrice}
                        onChange={handleChangeToken}
                        className="__txnInput"
                        placeholder="token amount"
                        decimalScale={7}
                        name="token"
                      />
                      <span className="__theCurrency">{response?.checkOutData?.tokenSymbol?.toUpperCase()}</span>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I want to sell</label>
                    <div>
                      <div className={`__txnInputWrapper`}>
                        <CurrencyFormat
                          // value={data?.unitPrice}
                          value={tokenPrice}
                          // decimalScale={3}
                          onChange={handleChangeToken}
                          decimalScale={7}
                          placeholder="token amount"
                          className="__txnInput"
                          name="token"

                          // prefix={"$"}
                        />
                        <span className="__theCurrency">{response?.checkOutData?.tokenSymbol?.toUpperCase()}</span>
                      </div>
                      <div className="__tokencheck">
                        <span className="__warning">{tokenPrice >= tokenBalance && "insufficient"}</span>
                        <span className="__info">
                          Available Bal{" "}
                          <CurrencyFormat
                            // value={data?.unitPrice}
                            value={tokenBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            suffix={` ${response?.checkOutData?.tokenSymbol}`}
                          />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I will receive</label>
                    <div>
                      <div
                        className={`__txnInputWrapper ${
                          parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                          parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount)
                            ? "__redborder"
                            : ""
                        }`}
                      >
                        <CurrencyFormat
                          value={currencyPrice}
                          onChange={handleChangeCurrency}
                          className="__txnInput"
                          placeholder="currency amount"
                          decimalScale={2}
                          name="currency"
                          //disabled={true}
                        />
                        <span className="__theCurrency">{response?.checkOutData?.currency?.toUpperCase()}</span>
                      </div>
                      <span className="__warning__text">
                        {parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount)
                          ? `Max Amount ${Math.abs(response?.checkOutData?.maxAmount)}`
                          : parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) &&
                            `Min Amount ${Math.abs(response?.checkOutData?.minAmount)}`}
                      </span>
                    </div>
                  </div>
                </div>
              )}

              {capitalizeFirstLetter(response?.checkOutData?.type?.toLowerCase())?.toLowerCase() === "buy" &&
                userData?.data?.userData?.isVerified === false && (
                  <div className="verify_indicator">
                    <div className="verify_indicator_icon_body">
                      <BiInfoCircle className="verify_indicator_icon" />
                    </div>

                    <p className="verify_indicator_text">Verify account to sell crypto</p>
                  </div>
                )}

              <div className="__action__buttonWrapper">
                <button className="__cancel__button" onClick={() => setModalAction(false)}>
                  Cancel
                </button>

                {userData?.data?.userData?.isVerified === false &&
                response?.checkOutData?.type?.toLowerCase() === "buy" ? (
                  <button
                    className="__buy__button"
                    onClick={() => {
                      push("/app/settings");
                    }}
                    style={{background: "#21B0EA"}}
                  >
                    Verify
                  </button>
                ) : (
                  <button
                    className="__buy__button"
                    disabled={
                      parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                      parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) ||
                      tokenPrice === 0 ||
                      !currencyPrice ||
                      createTradeLoading === true
                        ? true
                        : false || (response?.checkOutData?.type?.toLowerCase() === "buy" && tokenPrice >= tokenBalance)
                    }
                    onClick={() => onAction()}
                  >
                    {createTradeLoading ? (
                      <Loader4 />
                    ) : (
                      `${capitalizeFirstLetter(
                        response?.checkOutData?.type?.toLowerCase() === "sell" ? "Buy" : "Sell"
                      )} ${response?.checkOutData?.tokenSymbol}`
                    )}
                  </button>
                )}
              </div>
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

export default DeskTopActionModal;
