import {createContext, useState} from "react";
import {useSelector} from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {TradeActionModal} from "../tradeActionModal";
import {DeskTopTable} from "./desktopTable";
import MobileMarketTable from "./mobileTable";

export const ActionModalContext = createContext();
const MarketTable = () => {
  const {width} = useWindowDimensions();
  const [modalAction, setModalAction] = useState(false);
  const [modalID, setModalID] = useState();
  const {loading, response} = useSelector((state) => state.marketReducer);
  return (
    <ActionModalContext.Provider value={{modalAction, setModalAction, modalID, setModalID}}>
      {width <= 700 ? (
        <MobileMarketTable loading={loading} data={response} />
      ) : (
        <DeskTopTable loading={loading} data={response} />
      )}
      {/* <OurModal2 open={modalAction} setOpen={setModalAction}>
      </OurModal2> */}
      <TradeActionModal />
    </ActionModalContext.Provider>
  );
};

export {MarketTable};
