import React, {memo} from "react";
import "../../styles/postTradeButtonWrapper.scss";

const EditTradeButton = ({title}) => {
  return (
    <>
      <div className="postradeButtonWrapper">
        <button style={ButtonStyle}>{title}</button>
      </div>
    </>
  );
};

const ButtonStyle = {
  cursor: "pointer",
  border: "none",
  outline: "none",
  backgroundColor: "#53389e",
  color: "#fff",
  height: "35px",
  padding: "0px 30px",
  borderRadius: "7.31px",
  textTransform: "capitalize",
  fontStyle: "normal",
  fontSize: "14px",
  lineHeight: "18px",
  boxShadow: "1px 2px 5px 3px rgba(0, 0, 0, 0.026)",
};

export default memo(EditTradeButton);
