import {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {ActionModalContext} from "../../../MarketPlace/marketTable";
import {Avatar} from "../../../../hooks/imagePath";

import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../utils/getTimeDiff";
import {Loader} from "../../../Common/Loader";

import {useHistory} from "react-router-dom";
import {textSlicer20} from "../../../../utils/trimID";
import {CURRENT_TOTAL_VALUE} from "../../../../store/types";

const DeskTopTable = ({data, loading}) => {
  const {push} = useHistory();
  const dispatch = useDispatch();
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  const {userData, locationData} = useSelector((state) => state.generalReducer);

  let theLocation = locationData?.data?.geoData?.countryName ?? "worldwide";

  const {setModalAction, setModalID} = useContext(ActionModalContext);

  return (
    <div className="table__wrapper">
      {loading ? (
        <Loader />
      ) : (
        <table className="tablew">
          <thead className="newTable__thead">
            <tr className="newTable__tr">
              <th className="newTable__th">Seller</th>
              {/* <th className="newTable__th">Coin</th> */}
              <th className="newTable__th">Ratings</th>
              <th className="newTable__th">Limit Order</th>
              <th className="newTable__th">Unit Price</th>
              <th className="newTable__th __min__100">Country</th>
              <th className="newTable__th">PaymentMethod</th>
              <th className="newTable__th"></th>
            </tr>
          </thead>

          <tbody className="newTable__tbody">
            {data &&
              data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr className="myTable" style={{height: "40px"}} key={i}>
                    <td className="tableItem">
                      <div className="__seller__wrapper">
                        <a
                          href={`/app/profile/${item?.username}`}
                          rel="noreferrer"
                          target="_blank"
                          className="market_link"
                          style={{cursor: "pointer"}}
                        >
                          <div style={{backgroundImage: `url(${Avatar})`}} className="__seller__image" alt="the_avatar">
                            <div
                              className={`__seller__notifier ${
                                getTimeDifference(item?.tradeData?.timeLastSeen) < 5
                                  ? "online"
                                  : getTimeDifference(item?.traderData?.timeLastSeen) > 5 &&
                                    getTimeDifference(item?.traderData?.timeLastSeen) < 15
                                  ? "away"
                                  : "offline"
                              }`}
                            ></div>
                          </div>
                        </a>

                        <div className="__seller__details">
                          <a
                            href={`/app/profile/${item?.username}`}
                            rel="noreferrer"
                            target="_blank"
                            className="username_link"
                            style={{cursor: "pointer"}}
                          >
                            <div className="__sellerName">{item?.username}</div>
                          </a>

                          <div className="__sellerDetails">
                            {item?.tradeData?.totalNumberOfTrades} Orders,{" "}
                            {item?.tradeData?.totalNumberOfTrades === 0 ? (
                              0
                            ) : (
                              <CurrencyFormat
                                value={
                                  (item?.tradeData?.numberCompletedTrades / item?.tradeData?.totalNumberOfTrades) * 100
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                              />
                            )}
                            %
                          </div>
                        </div>
                      </div>
                    </td>

                    <td className="tableItem">
                      <div className="__market__ratings">
                        <div className="__ratings__like">
                          <span className="__rating">{item?.tradeData?.trusts}</span>
                          <AiOutlineLike size={14} className="__icon" />
                        </div>
                        <div className="__ratings__dislike">
                          <span className="__rating">{item?.tradeData?.distrusts}</span>
                          <AiOutlineDislike size={14} className="__icon" />
                        </div>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__limit">
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.minAmount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                        {" - "}
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.maxAmount)}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__unitprice">
                        <CurrencyFormat
                          value={Math.abs(item.unitPrice)}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          className="__theUnitPrice"
                          suffix={` ${item?.currency}`}
                        />
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__paymentMethod">{item.country}</div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__paymentMethod">{item.paymentMethod}</div>
                      <span style={{fontSize: "12px", color: "#a09e9eb7", paddingLeft: "10px"}}>
                        {textSlicer20(item?.tagLine, 20)}
                      </span>
                    </td>

                    <td className="tableItem">
                      <div className="transactionTableArea__viewMoreWrappers">
                        <span
                          className="transactionTableArea__viewMore"
                          onClick={() => {
                            setModalAction(true);

                            if (item?.traderID === userData?.data?.userData?._id) {
                              setModalID({
                                notAllow: true,
                              });

                              return;
                            }

                            setModalID({
                              id: item?._id,
                              traderID: item.traderID,
                            });

                            const presentToken = userData?.data?.userData?.tokens?.find(
                              (data) =>
                                data?.tokenSymbol?.toLowerCase() ===
                                `${capitalizeFirstLetter(item?.tokenSymbol)}`.toLowerCase()
                            );

                            dispatch({type: CURRENT_TOTAL_VALUE, payload: presentToken?.balance});
                          }}
                        >
                          {capitalizeFirstLetter(marketPlaceData.type)} {capitalizeFirstLetter(item?.tokenSymbol)}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {!loading && (data?.length === 0 || data === null || data === undefined) ? (
        <div className="__notFound__wrapperr">
          <div className="__theNotFound">
            <button
              style={{
                background: "#53389e",
                color: "white",
                height: "40px",
                width: "126px",
                border: "none",
                borderRadius: "8px",
              }}
              className="__view__allMarket"
              onClick={() => push(`/app/marketplace/${theLocation}/BTC/sell/Bank Transfer`)}
            >
              Go to Market
            </button>
          </div>
        </div>
      ) : (
        <div
          className="__notFound__wrapperr__sub"
          style={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div className="__theNotFound__sub">
            <button
              style={{
                background: "#21B0EA",
                color: "white",
                height: "40px",
                width: "126px",
                border: "none",
                borderRadius: "8px",
              }}
              className="__view__allMarket__sub"
              onClick={() => push(`/app/marketplace/${theLocation}/BTC/sell/Bank Transfer`)}
            >
              Go to Market
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export {DeskTopTable};
