import {Skeleton, Stack} from "@mui/material";
import {useEffect, useState} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CurrencyFormat from "react-currency-format";
import {RiFileCopyFill} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {NOTFOUND} from "../../../../hooks/imagePath";
import TransactionService from "../../../../services/transactionHistory";
import {getDepositWithdrawTransactionAction} from "../../../../store/actions/depositWithdrawTransactionHistory";
import {
  DEPOSITE_SKIP,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS,
} from "../../../../store/types";
import {trimId} from "../../../../utils/trimID";
import {OurModal2} from "../../../Common/modal";
import {TablePaginationComponent} from "../../../Common/pagination";
import ViewMoreModal from "../moreModal";

const TransactionMobileDepositWithdrawTable = ({data, loading}) => {
  const [moreModal, setMoreModal] = useState(false);
  const [modalData, setModalData] = useState();

  const [checkForEmpty, setCheckForEmpty] = useState();

  const dispatch = useDispatch();
  const {skip, fskip, limit, transactionWithdrawalStated} = useSelector(
    (state) => state.depositWithdrawaltransactionReducer
  );

  useEffect(() => {
    dispatch({type: DEPOSITE_SKIP, payload: 0});
    dispatch(
      getDepositWithdrawTransactionAction({
        skip: fskip,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      })
    );
  }, [transactionWithdrawalStated]);

  const onNext = () => {
    // dispatch(getDepositWithdrawOnNextAction());

    const skipDetails = (skip + 1) * 10;

    if (checkForEmpty?.length !== 0) {
      TransactionService.getDepositWithdraw({
        skip: skipDetails,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      }).then((data) => {
        if (data.message === "success") {
          if (data.transactions?.length !== 0) {
            dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS, payload: data.transactions});
            dispatch({type: DEPOSITE_SKIP, payload: skip + 1});
            setCheckForEmpty(data?.trades);
          } else {
            setCheckForEmpty([]);
            dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE, payload: false});
          }
        }
      });
    }
  };

  const onPrev = () => {
    // if (skip > 0) {
    //   dispatch(getDepositWithdrawOnPrevAction());
    // }

    setCheckForEmpty();

    const skipDetails = (skip - 1) * 10;

    if (data?.length > 0 && skip + 1 > 1) {
      TransactionService.getDepositWithdraw({
        skip: skipDetails,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      }).then((data) => {
        if (data.message === "success") {
          dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS, payload: data.transactions});
          dispatch({type: DEPOSITE_SKIP, payload: skip - 1});
        }
      });
    }
  };

  return (
    <>
      <div className="__transaction__mobile__wrapper">
        {loading ? (
          Array.from(new Array(limit)).map((item, i) => (
            <Stack spacing={1} key={i}>
              <Skeleton animation="wave" variant="rectangular" height={200} />
              {/* <Skeleton animation="wave" variant="rectangular" height={20} />
                        <Skeleton animation="wave" variant="rectangular" height={10} /> */}
            </Stack>
          ))
        ) : data && data?.length > 0 ? (
          data?.map((item, i) => {
            return (
              <div className="__mobile__content__wrapper" key={i}>
                <span className="__trade__title">{`${item?.type} ${item.tokenSymbol}`}</span>
                <div style={{marginTop: "15px"}}>
                  <div className="__details">
                    <p className="__title">Amount</p>
                    <span className="__value">
                      <CurrencyFormat
                        value={item?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={` ${item?.tokenSymbol}`}
                      />
                    </span>
                  </div>
                  <div className="__details">
                    <p className="__title">ID</p>
                    <CopyToClipboard text={item?.txID} onCopy={() => toast.success("copied successfully")}>
                      <span className="__value" style={{display: "flex"}}>
                        <span style={{color: "gray", marginRight: "10px"}}>{trimId(item?.txID)}</span>
                        <RiFileCopyFill />
                      </span>
                    </CopyToClipboard>
                  </div>
                  <div className="__details">
                    <p className="__title">Status</p>
                    <span
                      className={`__value ${
                        item?.status?.toLowerCase() === "cancelled"
                          ? "__cancelled"
                          : item?.status?.toLowerCase() === "pending"
                          ? "__pending"
                          : "__complete"
                      }`}
                      style={{
                        color: `${
                          item?.status?.toLowerCase() === "failed"
                            ? "#F04438"
                            : item?.status?.toLowerCase() === "pending"
                            ? "#F2B339"
                            : "#027a48"
                        }`,
                      }}
                    >
                      {item.status}
                    </span>
                  </div>
                  <div className="__details">
                    <p className="__title">Action</p>
                    <span
                      className="__value __view"
                      onClick={() => {
                        setModalData(item);
                        setMoreModal(true);
                      }}
                    >
                      View More
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="__notFound__wrapper">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}
      </div>

      {data?.length > 0 && (
        <TablePaginationComponent
          onNext={onNext}
          onPrev={onPrev}
          skip={skip}
          lengthChecker={checkForEmpty}
          dataCheck={data}
        />
      )}

      <OurModal2 open={moreModal} setOpen={setMoreModal} style={{display: "flex", alignItems: "center"}}>
        <ViewMoreModal setOpen={setMoreModal} data={modalData} />
      </OurModal2>
    </>
  );
};

export default TransactionMobileDepositWithdrawTable;
