import {Skeleton} from "@mui/material";
import React, {memo} from "react";
import {useSelector} from "react-redux";
import avatarProfile from "../../assets/icons/avatar-profile-photo.svg";

const ProfileImageOther = (props) => {
  // Check userdata & locationData in redux store
  const {profileHeaderOtherDataReducer} = useSelector((state) => state.profileReducer);

  const profileImage = profileHeaderOtherDataReducer?.profileImageUrl
    ? `https://oyola.s3.amazonaws.com/${profileHeaderOtherDataReducer?.profileImageUrl}`
    : avatarProfile;

  return (
    <React.Fragment>
      {!profileHeaderOtherDataReducer?.profileImageUrl ? (
        // profileHeaderOtherDataReducer?.profileImageUrl === undefined ? (
        //   <Skeleton animation="wave" variant="circular" width={80} height={80} />
        // ) : (
        <img
          src={profileImage ?? avatarProfile}
          alt="profle"
          width={80}
          height={80}
          style={{...props.style, borderRadius: "50%", objectFit: "cover"}}
          onClick={() => props.callBack}
        />
      ) : (
        // )
        <img
          src={profileImage ?? avatarProfile}
          alt="profle"
          width={80}
          height={80}
          style={{...props.style, borderRadius: "50%", objectFit: "cover"}}
          onClick={() => props.callBack}
        />
      )}
    </React.Fragment>
  );
};

export default memo(ProfileImageOther);
