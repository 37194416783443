import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccordionMessage from "./AccordionMessage";
// import {Pagination} from "./Pagination";
import "../../styles/Notification/notificationStyle.scss";
import {NotificationPaginationComponent} from "../Common/notificationPagination";
import NotificationService from "../../services/notification";
import {MESSAGEPAGES, MESSAGESKIP} from "../../store/types";
import {getMessageNotification} from "../../store/actions/notification";

const NotificationMessages = (props) => {
  const dispatch = useDispatch();
  const notificationDataRedux = useSelector((state) => state.notificationReducer.messageNotification);
  const {messageSkip, notificationLimit, messagePages} = useSelector((state) => state.notificationReducer);
  const notyData = notificationDataRedux ?? [];

  const IncreamentPage = () => {
    const skip = (messageSkip + 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "message").then((data) => {
      if (data?.data?.notifications.length > 0) {
        dispatch({type: MESSAGEPAGES, payload: messagePages + 1});
        dispatch(getMessageNotification(data?.data?.notifications));
        dispatch({type: MESSAGESKIP, payload: messageSkip + 1});
      }
    });
  };

  const DecreamentPage = () => {
    const skip = (messageSkip - 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "message").then((data) => {
      if (data?.data?.notifications.length > 0 && messagePages > 1) {
        dispatch({type: MESSAGEPAGES, payload: messagePages - 1});
        dispatch(getMessageNotification(data?.data?.notifications));
        dispatch({type: MESSAGESKIP, payload: messageSkip - 1});
      }
    });
  };

  const filterUnReadMessages = notyData.length > 0 ? notyData.filter((item) => item.unRead === true) : [];

  return (
    <React.Fragment>
      <div className="ReadUnReadWrapper">
        <p className="titleWrapper">Unread messages</p>
        <p className="lengthWrapper">{filterUnReadMessages.length}</p>
      </div>
      <div className="messageWrapper">
        <AccordionMessage filterMessages={notyData.length > 0 ? notyData : 0} />
        {notificationDataRedux.length > 0 && (
          <NotificationPaginationComponent onNext={IncreamentPage} onPrev={DecreamentPage} skip={messagePages} />
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(NotificationMessages);
