import "../../../../styles/marketplacestyles/ConvertCardInputComponent.scss";
import CurrencyFormat from "react-currency-format";
import {useState} from "react";
import {CoinIcons} from "../../../Common/coinSwitcher";
import {CountryIcons} from "../../../Common/CountryIcons";

// will change border color to purple if is focus
const ConvertCardInputComponentMarketPlace = ({
  label,
  countryTitle,
  coinTitle = "BTC",
  setDropDown,
  token,
  handleChageSystem,
  value,
  convertTokenError,
  handleMax,
  checkforsell,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const containerStyle = convertTokenError
    ? "ParentInputButtonContainerErrorss"
    : isFocus
    ? "ParentInputButtonContainerActivess"
    : "ParentInputButtonContainerss";

  return (
    <>
      <div className={containerStyle}>
        <div className="TextAndInputContainerStyle">
          <div className="TextAndInputContainerStyleMax">
            <p className="TextAndInputTitleStyle">{label}</p>
            {checkforsell && (
              <p className="TextAndInputTitleStyleMax" onClick={handleMax}>
                max
              </p>
            )}
          </div>

          <CurrencyFormat
            className={"TextAndInputStyle"}
            placeholder={"0.000000"}
            value={value || ""}
            onFocus={() => setIsFocus(true)}
            onBlur={() => setIsFocus(false)}
            autocomplete="off"
            decimalScale={token ? 7 : 2}
            onChange={handleChageSystem}
            thousandSeparator={true}
            // displayType="text"
          />
          {/* )}  */}
        </div>

        <div className="ButtonContainerButtonStyle" onClick={setDropDown}>
          {token ? (
            <CoinIcons intialValue={coinTitle} mainWrapper={{marginLeft: "4px", width: "34px", height: "34px"}} />
          ) : (
            <CountryIcons intialValue={countryTitle} mainStyle={{marginLeft: "4px", width: "34px", height: "34px"}} />
          )}
          <p className="ButtonContainerButtonStyleTitle">{coinTitle}</p>
        </div>
      </div>
    </>
  );
};

export default ConvertCardInputComponentMarketPlace;
