import {IconButton, Menu, MenuItem} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import React, {memo, useState} from "react";
import {useHistory} from "react-router-dom";
import {useSelector} from "react-redux";
import {IoWalletOutline} from "react-icons/io5";

const MobileSideBar = () => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();
  const {locationData} = useSelector((state) => state.generalReducer);
  let theLocation = locationData?.data?.geoData?.countryName ?? "Worldwide";
  const menuItemData = [
    {id: 0, page: "Overview", image: "overview-mobile", path: "/app/overview"},
    {id: 1, page: "Trade", image: "trade-mobile", path: "/app/user-trades"},
    {
      id: 2,
      page: "Marketplace",
      image: "marketplace-mobile",
      path: `/app/marketplace/${theLocation}/BTC/sell`,
    },
    {id: 3, page: "Wallet", image: "usdt", path: "/app/wallet"},
    {id: 4, page: "Convert", image: "convert", path: "/app/convert"},
    {id: 5, page: "Notification", image: "notification-mobile", path: "/app/notification"},
    {id: 6, page: "Transaction", image: "transaction-mobile", path: "/app/transaction"},
  ];

  const handleClose = (page, path) => {
    setOpen(false);
    setAnchorEl(null);
    switch (page) {
      case "Overview": {
        history.push(path);
        break;
      }

      case "Trade": {
        history.push(path);
        break;
      }

      case "Marketplace": {
        history.push(path);
        break;
      }

      case "Wallet": {
        history.push(path);
        break;
      }

      case "Convert": {
        history.push(path);
        break;
      }

      case "Notification": {
        history.push(path);
        break;
      }

      case "Transaction": {
        history.push(path);
        break;
      }

      default: {
        break;
      }
    }
  };

  const openMenu = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  return (
    <React.Fragment>
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={{marginTop: "10px"}}
        elevation={0.1}
      >
        {menuItemData.map((item) => {
          return (
            <MenuItem
              key={item.id}
              sx={menuStyle}
              onClick={() => {
                handleClose(item.page, item.path);
              }}
            >
              {item.id !== 3 ? (
                <img src={require(`../../assets/icons/${item.image}.svg`)} alt={item.image} style={imageStyle} />
              ) : (
                <IoWalletOutline style={{marginRight: "10px"}} fontSize={25} color={"black"} stroke="black" />
              )}
              <div>
                <p style={{fontSize: "14px"}}>{item.page}</p>
              </div>

              {item?.page === "Convert" && (
                // <div style={newConvertStyle}>
                <div style={newConvertStyle}>
                  <p style={textImage}>New</p>
                </div>
              )}
            </MenuItem>
          );
        })}
      </Menu>
      <IconButton size="large" edge="start" aria-label="menu" sx={{mr: 1}} onClick={openMenu}>
        <MenuIcon style={{width: 24, height: 24}} />
      </IconButton>
    </React.Fragment>
  );
};

const menuStyle = {
  width: "300px",
  borderBottom: "1px solid #F2F4F7",
  marginTop: "10px",
};

const imageStyle = {
  width: "25px",
  height: "25px",
  marginRight: "10px",
};

const newConvertStyle = {
  marginLeft: "50%",
  width: "45.83px",
  height: "25.83px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15.31px",
  fontSize: "16px",
  position: "relative",
  background: "#34D07F",
};

const textImage = {
  color: "white",
  fontSize: "14px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20",
};

export default memo(MobileSideBar);
