import CurrencyFormat from "react-currency-format";
import {capitalizeFirstLetter} from "../../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../../utils/getTimeDiff";
import moment from "moment";
import "../../../../../styles/landing-page-styles/table.scss";

import {Avatar, NOTFOUND} from "../../../../../utils/imagePath";
import {MarketPlaceContext} from "../../../../../screens/marketplace";
import {useContext} from "react";
import {Loader} from "../../../loader";
import {Link} from "react-router-dom";

const MobileMarketTable = ({data, loading}) => {
  const token = localStorage.getItem("token");
  const {toggleSwitch, countryStateValue} = useContext(MarketPlaceContext);

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div className="__mobile__market__table">
          {data &&
            data.length > 0 &&
            data.map((item) => {
              return (
                <div className="__mobile__market__content">
                  <div className="__mobile__market__top">
                    <div className="__userUser">
                      <img className="__userImage" src={Avatar} alt="user" />

                      <div
                        className={`__presence ${
                          getTimeDifference(moment(item?.tradeData?.timeLastSeen)) < 5
                            ? "__online"
                            : getTimeDifference(moment(item?.tradeData?.timeLastSeen)) > 5 &&
                              getTimeDifference(moment(item?.tradeData?.timeLastSeen)) < 15
                            ? "__away"
                            : "__offline"
                        }`}
                      ></div>
                      <p className={"__userName"}>{item?.username}</p>
                    </div>

                    <div className={"__userOrderDetails"}>
                      <p className={"__orderdetails"}>
                        {item?.tradeData?.totalNumberOfTrades} Orders,{" "}
                        {item?.tradeData?.totalNumberOfTrades === 0 ? (
                          0
                        ) : (
                          <CurrencyFormat
                            value={
                              (item?.tradeData?.numberCompletedTrades / item?.tradeData?.totalNumberOfTrades) * 100
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                          />
                        )}
                        %
                      </p>
                    </div>
                  </div>

                  <div className={"__unit__limit"}>
                    <div className={"__unitTitle"}>
                      <p className={"__unitText"}>Unit Price</p>
                      <span className={"__unitValue"}>
                        <CurrencyFormat
                          value={item?.unitPrice ?? 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${item?.currency}`}
                        />
                      </span>
                    </div>
                    <div className="__unitTitle">
                      <p className="__unitText">Limit</p>
                      <span className="__unitValue">
                        <CurrencyFormat
                          value={item?.minAmount ?? 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${item?.currency}`}
                        />{" "}
                        -{" "}
                        <CurrencyFormat
                          value={item?.maxAmount ?? 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          // prefix={"$"}
                          suffix={` ${item?.currency}`}
                        />
                      </span>
                    </div>
                  </div>

                  <div className="__action__wrapper">
                    <div className="__paymentMethod">
                      <span>{item?.paymentMethod}</span>
                    </div>
                    {token === null || token === undefined ? (
                      <Link to={"/login"} className="transactionTableArea__viewMore">
                        {capitalizeFirstLetter(toggleSwitch)} {capitalizeFirstLetter(item?.tokenSymbol)}
                      </Link>
                    ) : (
                      <a
                        href={`/app/marketplace/${countryStateValue?.name}/${capitalizeFirstLetter(
                          item?.tokenSymbol
                        )}/${toggleSwitch}/${item.paymentMethod}`}
                        target={"_blank"}
                        className="__buySellAction"
                        rel="noreferrer"
                      >
                        {capitalizeFirstLetter(toggleSwitch)} {capitalizeFirstLetter(item?.tokenSymbol)}
                      </a>
                    )}
                  </div>
                </div>
              );
            })}

          {loading && <Loader />}
          {!loading && data && data.length === 0 && (
            <div className="__notFound__wrapper">
              <div className="__notFound__wrapper">
                <img src={NOTFOUND} alt="not found" className="__img" />
                <span>No Result</span>
              </div>
            </div>
          )}
        </div>
      )}
      {/* <TablePaginationComponent onNext={onNext} onPrev={onPrev} skip={skip} /> */}
    </>
  );
};

export {MobileMarketTable};
