import React from "react";

const ConvertMobileTable = () => {
  return (
    <>
      {/* <div className="__transaction__mobile__wrapper">
        {loading ? (
          Array.from(new Array(limit)).map((item, i) => (
            <Stack spacing={1} key={i}>
              <Skeleton animation="wave" variant="rectangular" height={200} />
                         </Stack>
          ))
        ) : data && data?.length > 0 ? (
          data.map((item, i) => {
            return (
              <div className="__mobile__content__wrapper" key={i}>
                <span className="__trade__title">
                  {capitalizeFirstLetter(item?.type)} {item?.tokenSymbol}
                </span>
                <div style={{marginTop: "15px"}}>
                  <div className="__details">
                    <p className="__title">Unit Price</p>
                    <span className="__value">
                      <CurrencyFormat
                        value={item?.price}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={5}
                        fixedDecimalScale
                        suffix={` ${item?.currency}`}
                      />
                    </span>
                  </div>
                  <div className="__details">
                    <p className="__title">Crypto amount</p>
                    <span className="__value">
                      <CurrencyFormat
                        value={item?.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={` ${item?.tokenSymbol}`}
                      />
                    </span>
                  </div>
                  <div className="__details">
                    <p className="__title">Counteparty</p>
                    <span className="__value __counte">Shibetoshi</span>
                  </div>
                  <div className="__details">
                    <p className="__title">Status</p>
                    <span
                      className={`__value ${
                        item?.status === "pending"
                          ? "__pending"
                          : item?.status === "cancelled"
                          ? "__cancelled"
                          : "__complete"
                      }`}
                    >
                      {item?.status}
                    </span>
                  </div>
                  <div className="__details">
                    <p className="__title">Action</p>
                    <span className="__value __view" onClick={() => push(`/app/trade/${item?.trade_id}`)}>
                      View Trade
                    </span>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="__notFound__wrapper">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}
      </div>
      <TablePaginationComponent
        onNext={onNext}
        onPrev={onPrev}
        skip={skip}
        lengthChecker={checkForEmpty}
        dataCheck={data}
        limit={limit}
        loading={checkForExtraData}
      /> */}
    </>
  );
};

export default ConvertMobileTable;
