import {memo, useEffect} from "react";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {ProfileService} from "../../services/profileService";
import {blockAUserModal, checkIfAUserIsBlocked} from "../../store/actions/profile";
import ModalInfo from "./modalInfo";
import cancelBlocked from "../../assets/icons/cancelBlocked.svg";
import {toast} from "react-toastify";
import {Skeleton} from "@mui/material";

const VerifyBlockUserButton = ({username}) => {
  const dispatch = useDispatch();
  const checkIfAUserIsBlockedState = useSelector((state) => state.profileReducer.checkIfAUserIsBlockedState);

  useEffect(() => {
    const payload = {
      username: username,
    };
    ProfileService.checkIfUserBlocked(payload)
      .then((data) => {
        if (data.data.message === "blocked") {
          dispatch(checkIfAUserIsBlocked(false));
        } else {
          dispatch(checkIfAUserIsBlocked(true));
        }
      })
      .catch(() => {
        dispatch(checkIfAUserIsBlocked(false));
        toast("Something went wrong");
      });
  }, [checkIfAUserIsBlockedState, dispatch, username]);

  const renderButton = () => {
    if (checkIfAUserIsBlockedState) {
      return (
        <button
          className="buttonStyle topRightButtonEditStyle"
          onClick={() => {
            dispatch(blockAUserModal(0));
          }}
          style={{
            width: "140px",
            background: "white",
            color: "#344054",
            border: "1px solid #D0D5DD",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <img src={cancelBlocked} alt="cancel button" />
          Block this user
        </button>
      );
    } else {
      return (
        <button
          className="buttonStyle topRightButtonEditStyle"
          onClick={() => {
            dispatch(blockAUserModal(1));
          }}
          style={{background: "#D92D20", color: "#FFFFFF", width: "130px"}}
        >
          Unblock this user
        </button>
      );
    }
  };

  return (
    <>
      {checkIfAUserIsBlockedState === "" ? (
        <Skeleton animation="wave" variant="rectangular" style={SkeletonStyle} />
      ) : (
        <>
          <ModalInfo />
          {renderButton()}
        </>
      )}
    </>
  );
};

const SkeletonStyle = {
  fontWeight: "600",
  background: "rgba(255,255,255,1)",
  width: "130px",
  height: "35px",
  borderRadius: "20px",
};
export default memo(VerifyBlockUserButton);
