import React from "react";
import {memo} from "react";
import "../../styles/CustomSelectedCheckbox.css";

const CustomSelectedCheckbox = ({handleChange, state, edited}) => {
  return (
    <div className="CustomSelectedCheckbox" style={{backgroundColor: `${state.checker === false ? "#e3d0ff" : ""}`}}>
      <input
        type={"checkbox"}
        className="checkbox"
        checked={state.checker || false}
        onChange={!edited ? handleChange : undefined}
        readOnly={true}
      />
      <span className="checker"></span>
    </div>
  );
};

export default memo(CustomSelectedCheckbox);
