import ReviewRow from "../Profile/Comment";
import {useDispatch, useSelector} from "react-redux";
import EmptyStateOtherProfileView from "./emptyState";
import {DomPaginationState} from "../Common/DOM_Pagination_State";
import {PAGE_NUM_NEGATIVE} from "../../store/types";

const NegativeComments = () => {
  const dispatch = useDispatch();
  const {paginationArrayListNegative, pageNumNegative, profilePaginationTotalNegative} = useSelector(
    (state) => state.profileReducer
  );

  const IncreamentPage = () => {
    if (pageNumNegative + 1 < profilePaginationTotalNegative) {
      dispatch({type: PAGE_NUM_NEGATIVE, payload: pageNumNegative + 1});
    }
  };

  const DecreamentPage = () => {
    if (pageNumNegative > 0) {
      dispatch({type: PAGE_NUM_NEGATIVE, payload: pageNumNegative - 1});
    }
  };

  if (paginationArrayListNegative?.length === 0) {
    return <EmptyStateOtherProfileView />;
  }

  if (paginationArrayListNegative?.length > 0) {
    return (
      <>
        {paginationArrayListNegative[pageNumNegative].map((item) => (
          <ReviewRow
            key={Math.random()}
            avatar={"avatar"}
            username={item.reviewerUsername}
            userFeeback={item.text}
            isVerified={item.isTrust}
            transactionId={item.date_added}
          />
        ))}

        <DomPaginationState onNext={IncreamentPage} onPrev={DecreamentPage} skip={pageNumNegative + 1} />
      </>
    );
  }
};

export default NegativeComments;
