import React from "react";
import {useDispatch, useSelector} from "react-redux";
import GeneralService from "../../services/general";
import SettingsService from "../../services/settingsServices";
import {getUser} from "../../store/actions/general";
import {getSettingLoaderSkeleton, getUseGoogleAuth, getUsePhoneNumber} from "../../store/actions/settingAction";
import CustomCheckbox from "../Common/CustomCheckbox";

const UsePhoneNumber = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetails = userData?.data?.userData;

  const usePhoneNumber = useSelector((state) => state.settingsReducer.usePhoneNumber);

  const handleChangeBuy = (e) => {
    const {checked} = e.target;

    if (userDetails?.phoneNumberVerified) {
      dispatch(getSettingLoaderSkeleton(true));

      if (checked === false) {
        dispatch(getUsePhoneNumber({name: "sms", checker: checked}));
        dispatch(getUseGoogleAuth({name: "google auth", checker: true}));

        SettingsService.AuthPreference({type: "google auth"}).then((data) => {
          if (data === "success") {
            GeneralService.getUserData().then((data) => {
              dispatch(getUser(data));
              dispatch(getSettingLoaderSkeleton(false));
            });
          }
        });

        return;
      }

      if (checked === true) {
        dispatch(getUsePhoneNumber({name: "sms", checker: checked}));
        dispatch(getUseGoogleAuth({name: "google auth", checker: false}));

        SettingsService.AuthPreference({type: "sms"}).then((data) => {
          if (data === "success") {
            GeneralService.getUserData().then((data) => {
              dispatch(getUser(data));
              dispatch(getSettingLoaderSkeleton(false));
            });
          }
        });

        return;
      }
    }
  };

  return (
    <div>
      <CustomCheckbox
        handleChange={handleChangeBuy}
        state={userDetails?.phoneNumberVerified ? usePhoneNumber : {name: "sms", checker: false}}
      />
    </div>
  );
};

export default UsePhoneNumber;
