import "../../styles/showloginErrorMessage.scss";

const ShowErrorMessageLogin = ({id, value, optionalValue, fontSize, marginTop, textAlign}) => {
  return (
    <div
      data-testid={id}
      className="errorContainerLogin"
      style={{
        fontSize: fontSize,
        marginTop: marginTop,
        textAlign: textAlign,
        visibility: value ? "visible" : "hidden",
      }}
    >
      {value}
      {optionalValue}
    </div>
  );
};

export default ShowErrorMessageLogin;
