import {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {RiErrorWarningLine} from "react-icons/ri";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useGetUserType} from "../../../hooks/useGetUserType";
import "../../../styles/createTrade/bottomArea.scss";
import {CreateTradeContext} from "../../Home/CreateTrade";

const CreateTradeBottomArea = ({setOpenModal, setCancelModal, setDisputeModal}) => {
  const {id} = useParams();
  const {expire} = useContext(CreateTradeContext);

  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  const userType = useGetUserType(response ?? {});

  return (
    <div className="__createTrade__bottom__Area">
      <div className="__accept__transaction__area">
        {statusResponse?.status === "cancelled" || statusResponse?.status === "completed" ? (
          <p
            className="__make__Sure"
            style={{marginBottom: "30px", fontSize: "15px", color: "#27125f", fontWeight: "bold"}}
          >
            This trade has been successfully {statusResponse?.status}
          </p>
        ) : (userType === "user" && response?.type === "sell") ||
          (userType === "trader" && response?.type === "buy") ? (
          <div className="__the__warningmsg">
            <div className="__the__title">
              <RiErrorWarningLine size={18} className={`${statusResponse?.isPaid && "__paid"}`} />
              <p className={`geg ${statusResponse?.isPaid && "__paid"}`}>
                {statusResponse?.isPaid ? "The buyer has paid" : "The buyer has not paid yet"}
              </p>
            </div>
            <p className="">
              Before releasing the <b>{response?.tokenSymbol?.toUpperCase()}</b>, make sure you've received your money
              via <b>{response?.paymentMethod}</b>. Once you've released your fund, the transaction is final.
            </p>
          </div>
        ) : statusResponse?.status === "paid" ? (
          <p className="__make__Sure">
            Waiting for Seller to Release{" "}
            <span className="__recieve__payment">
              <CurrencyFormat
                value={response?.amount}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={5}
                fixedDecimalScale
                style={{fontWeight: "bold"}}
                // prefix={"$"}
                suffix={`${response?.tokenSymbol}`}
              />
            </span>
          </p>
        ) : (
          <p className="__make__Sure">
            Make sure to click{" "}
            <span className="__recieve__payment">
              {(userType === "user" && response?.type === "sell") || (userType === "trader" && response?.type === "buy")
                ? "“Release Token” "
                : "“I have made payment” "}
            </span>
            when you have confirmed your payment
          </p>
        )}
        {!(statusResponse?.status === "cancelled" || statusResponse?.status === "completed") && (
          <div className="flex ">
            {((userType === "user" && response?.type === "sell") ||
              (userType === "trader" && response?.type === "buy")) && (
              <button
                className="__have__received __sell"
                onClick={() => setOpenModal((prev) => !prev)}
                disabled={
                  (userType === "user" && response?.type === "sell" && statusResponse?.status === "pending") ||
                  (userType === "user" && response?.type === "sell" && statusResponse?.status === "completed") ||
                  (statusResponse?.isPaid === undefined || statusResponse?.isPaid === false ? true : false) ||
                  (userType === "user" && response?.type === "sell" && statusResponse?.status === "cancelled")
                }
              >
                Release Token
              </button>
            )}

            {((userType === "user" && response?.type === "buy") ||
              (userType === "trader" && response?.type === "sell")) &&
              !statusResponse?.isPaid && (
                <button
                  className="__have__received __sell"
                  onClick={() => setOpenModal((prev) => !prev)}
                  disabled={statusResponse?.isPaid}
                >
                  I have made payment
                </button>
              )}
            {userType === "user" && response?.type === "buy" && (
              <button
                className="__have__received __cancel__tx"
                disabled={statusResponse?.status === "cancelled" || statusResponse?.status === "completed"}
                onClick={() => setCancelModal(true)}
              >
                Cancel Transaction
              </button>
            )}
            {userType === "trader" && response?.type === "sell" && (
              <button
                className="__have__received __cancel__tx"
                disabled={statusResponse?.status === "cancelled" || statusResponse?.status === "completed"}
                onClick={() => setCancelModal(true)}
              >
                Cancel Transaction
              </button>
            )}
          </div>
        )}
      </div>

      <div className="__dispute__area">
        {!(
          statusResponse?.status === "disputed" ||
          statusResponse?.status === "cancelled" ||
          statusResponse?.status === "completed"
        ) && (
          <>
            <p className="__do__you__want__to">Do you have transaction problem?</p>
            <div>
              <button
                className={`__create__dispute ${
                  expire && (statusResponse?.status === "pending" || statusResponse?.status === "paid") && "__active"
                }`}
                disabled={
                  !expire ||
                  statusResponse?.status === "disputed" ||
                  statusResponse?.status === "completed" ||
                  statusResponse?.status === "cancelled"
                }
                onClick={() => setDisputeModal(true)}
              >
                Dispute
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CreateTradeBottomArea;
