import TradeOrderService from "../../services/tradeOrdersService";
import {ALLL_TRADE_ORDERS, ALL_TRADE_ORDERS} from "../types";

export const getAllTradeOrders = (param) => (dispatch) => {
  TradeOrderService.GetAllTradeOrder(param).then((data) => {
    dispatch({
      type: ALL_TRADE_ORDERS,
      payload: data,
    });
  });
};
export const getAllTradeOrders2 = (param) => (dispatch) => {
  TradeOrderService.GetAllTradeOrder(param).then((data) => {
    dispatch({
      type: ALLL_TRADE_ORDERS,
      payload: data,
    });
  });
};
