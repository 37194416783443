import {useSelector} from "react-redux";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {ConfirmBuyModal} from "../confirmModal/confirmModalBuy";
import {ConfirmSellModal} from "../confirmModal/confirmModalSell";
import {SuccessModal} from "../successModal";
import VerificationOTP from "../verificationOtpModal";
const SwitchModalComponent = ({setOpenModal, step, setStep, prevStep}) => {
  const {loading, response} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(response ?? {});
  switch (step) {
    case 0:
      return (
        <>
          {userType === "user" && response?.type === "buy" ? (
            <ConfirmBuyModal setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "user" &&
            response?.type === "sell" && <ConfirmSellModal setOpenModal={setOpenModal} setStep={setStep} />
          )}
          {userType === "trader" && response?.type === "sell" ? (
            <ConfirmBuyModal setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "trader" &&
            response?.type === "buy" && <ConfirmSellModal setOpenModal={setOpenModal} setStep={setStep} />
          )}
        </>
      );
    case 1:
      return (
        <>
          {userType === "user" && response?.type === "buy" ? (
            <VerificationOTP setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "user" &&
            response?.type === "sell" && <VerificationOTP setOpenModal={setOpenModal} setStep={setStep} />
          )}
          {userType === "trader" && response?.type === "sell" ? (
            <VerificationOTP setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "trader" &&
            response?.type === "buy" && <VerificationOTP setOpenModal={setOpenModal} setStep={setStep} />
          )}
        </>
      );
    case 2:
      return (
        <>
          {userType === "user" && response?.type === "buy" ? (
            <SuccessModal setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "user" &&
            response?.type === "sell" && <SuccessModal setOpenModal={setOpenModal} setStep={setStep} />
          )}
          {userType === "trader" && response?.type === "sell" ? (
            <SuccessModal setOpenModal={setOpenModal} setStep={setStep} />
          ) : (
            userType === "trader" &&
            response?.type === "buy" && <SuccessModal setOpenModal={setOpenModal} setStep={setStep} />
          )}
        </>
      );

    default:
  }
};

export default SwitchModalComponent;
