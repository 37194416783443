import {
  FILTER_USER_TRADES_BY_COIN,
  FILTER_USER_TRADES_BY_TYPE,
  GET_USER_TRADES_ERROR,
  GET_USER_TRADES_INITIAL,
  GET_USER_TRADES_SUCCESS,
  GET_PENDING_TRADE,
  GET_DISPUTED_TRADE,
  GET_COMPLETED_TRADE,
  GET_LOADER,
  TRADE_SKIP,
  TRADE_FSKIP,
  TRADE_LIMIT,
  TRADE_CHECK_FOR_EMPTY,
  TRADE_CHECK_FOR_EXTRA_DATA,
  TRADE_DETAILS,
} from "../types";

const initialState = {
  all: null,
  all2: null,
  pending: null,
  pending2: null,
  completed: null,
  completed2: null,
  disputed: null,
  disputed2: null,
  error: null,
  loading: false,
  skip: 0,
  fSkip: 0,
  limit: 20,
  checkForEmpty: null,
  checkForExtraData: false,
  tradeDetials: {},
};

const getUserTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_USER_TRADES_SUCCESS: {
      return {
        ...state,
        pending2: payload.pendingTradesCount,
        disputed2: payload.disputedTradesCount,
        loading: false,
      };
    }

    case TRADE_DETAILS: {
      return {
        ...state,
        tradeDetials: payload,
      };
    }

    case TRADE_CHECK_FOR_EMPTY: {
      return {
        ...state,
        checkForEmpty: payload,
      };
    }

    case TRADE_CHECK_FOR_EXTRA_DATA: {
      return {
        ...state,
        checkForExtraData: payload,
      };
    }

    case TRADE_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case TRADE_FSKIP: {
      return {
        ...state,
        fSkip: payload,
      };
    }

    case TRADE_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case GET_PENDING_TRADE: {
      return {
        ...state,
        pending: payload,
      };
    }

    case GET_LOADER: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_DISPUTED_TRADE: {
      return {
        ...state,
        disputed: payload,
      };
    }

    case GET_COMPLETED_TRADE: {
      return {
        ...state,
        all: payload,
      };
    }

    case GET_USER_TRADES_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_USER_TRADES_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case FILTER_USER_TRADES_BY_COIN: {
      if (payload.data === null) {
        return {
          ...state,
          [`${payload.ordertype}`]: state[`${payload.ordertype}2`],
        };
      } else {
        let dd = state[`${payload.ordertype}2`].filter((item) => item.tokenSymbol === payload.data.token);
        return {
          ...state,
          [`${payload.ordertype}`]: dd,
        };
      }
    }
    case FILTER_USER_TRADES_BY_TYPE: {
      if (payload.data === null) {
        return {
          ...state,
          [`${payload.ordertype}`]: state[`${payload.ordertype}2`],
        };
      } else {
        let dd = state[`${payload.ordertype}2`].filter((item) => item.type === payload.data.type);
        return {
          ...state,
          [`${payload.ordertype}`]: dd,
        };
      }
    }

    default: {
      return state;
    }
  }
};

export default getUserTradeReducer;
