import "../../styles/landing-page-styles/assets-control.scss";
import assetsControlImage from "../../assets/images/new-default-transition.png";
import "../../styles/landing-page-styles/button.scss";

const AssetsControl = () => {
  return (
    <main className="homepageContainer">
      <div className="innerContainer">
        <div className="assetsControlStyle">
          <div className="containerRight">
            <h1 className="title">Control over your assets</h1>
            <p className="tag">
              Have control over your assets with the Oyola wallet which provides you with robust security measures to
              ensure that your assets remain safe while you’re transacting.
            </p>
            <div className="signUpContainer">
              <button
                style={{cursor: "pointer"}}
                className="baseButton primaryButtonLarge buttonAdditionalStyle"
                onClick={() => {
                  window.location = "/download";
                }}
              >
                Download App
              </button>
            </div>
          </div>

          <div className="containerLeft">
            <img src={assetsControlImage} alt="control over your assets" className="imageContainer" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default AssetsControl;
