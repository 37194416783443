import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {BUYDATA, SELLDATA} from "../../store/types";
import CustomCheckbox from "../Common/CustomCheckbox";

const BuyDresser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const buyData = useSelector((state) => state.postTradeReducer.buyData);
  const coinData = useSelector((state) => state.postTradeReducer.coinData);
  const countryDataState = useSelector((state) => state.postTradeReducer.countryData);

  const handleChangeBuy = (e) => {
    const {checked} = e.target;

    if (checked === false) {
      dispatch({type: BUYDATA, payload: {name: "buy", checker: checked}});
      dispatch({type: SELLDATA, payload: {name: "sell", checker: true}});

      history.push(`/app/post-trade/sell/${coinData}/${countryDataState.countryName}`);
      return;
    }

    if (checked === true) {
      dispatch({type: BUYDATA, payload: {name: "buy", checker: checked}});
      dispatch({type: SELLDATA, payload: {name: "sell", checker: false}});

      history.push(`/app/post-trade/buy/${coinData}/${countryDataState.countryName}`);
      return;
    }
  };

  return (
    <div>
      <CustomCheckbox handleChange={handleChangeBuy} state={buyData} />
    </div>
  );
};

export default memo(BuyDresser);
