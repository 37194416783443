import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/auth";
import {Stack} from "@mui/system";
import {Skeleton} from "@mui/material";
import {listOfReviews} from "../../store/actions/profile";
import AllComments from "./allComments";
import PositiveComments from "./positiveComments";
import NegativeComments from "./negativeComments";
import "./tabStyle.scss";
import {ARRAYFORPAGINATION} from "../../store/types";

function Tabs({username}) {
  const [toggleState, setToggleState] = useState(1);

  const dispatch = useDispatch();

  // Check userdata & locationData in redux store
  const {userData} = useSelector((state) => state.generalReducer);
  const [isLoading, setIsLoading] = useState(false);
  // const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const fetchProfileData = useCallback(() => {
    const payload = {
      username,
    };

    setIsLoading(true);

    if (username !== "") {
      AuthService.profileData(payload)
        .then((data) => {
          // if (data.messsage === "success") {
          dispatch(listOfReviews(data?.userData?.reviews));
          dispatch({type: ARRAYFORPAGINATION, payload: data?.userData?.reviews});
          setIsLoading(false);
          // }
        })
        .catch(() => {
          // toast("Something went wrong");
          setIsLoading(false);
        });
    }
  }, [username, dispatch]);

  useEffect(() => {
    fetchProfileData();
  }, [userData, fetchProfileData]);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  return (
    <main className="feedbackTab">
      <section className="feedbackTab__blocTabs">
        <div>
          <button
            className={toggleState === 1 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(1)}
          >
            All
          </button>
          <button
            className={toggleState === 2 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(2)}
          >
            Positive
          </button>
          <button
            className={toggleState === 3 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(3)}
          >
            Negative
          </button>
        </div>
      </section>

      <section className="feedbackTab__contentTabs">
        <div className={toggleState === 1 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <AllComments />
          )}
        </div>

        <div className={toggleState === 2 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <PositiveComments />
          )}
        </div>

        <div className={toggleState === 3 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <NegativeComments />
          )}
        </div>
      </section>
    </main>
  );
}

export default Tabs;
