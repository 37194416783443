import CreateTradeChat from "../CreateTrade/chatArea";
import CreateTradeDetailsArea from "../CreateTrade/detailArea";
import CreateTradeTop from "../CreateTrade/topArea";
import "../../styles/createTrade/createTrade.scss";
import CreateTradeBottomArea from "../CreateTrade/bottomArea";
import OurModal from "../Common/modal";
import {useState} from "react";
import useWindowDimensions from "../../hooks/useWindowDimension";
import {useHistory, useParams} from "react-router-dom";
import {checkTradeReviewAction, getBuySellTradeByIDAction} from "../../store/actions/createTradeAction";
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {Loader} from "../Common/Loader";
import {getTradeStatusByIDAction} from "../../store/actions/getTradeStatus";
import {createContext} from "react";
import {useGetUserType} from "../../hooks/useGetUserType";
import SwitchModalComponent from "../CreateTrade/switchModal";
import {CancelModal} from "../CreateTrade/confirmModal/cancelModal";
import Oops from "../oops";
import {CANCEL_TRADE_DEFAULT, CURRENT_TOTAL_VALUE, REVIEW_RESPONSE} from "../../store/types";
import {RiChat3Line} from "react-icons/ri";
import {BiBookmark} from "react-icons/bi";
import {DisputeModal} from "../CreateTrade/confirmModal/disputeModal";
import createTradeService from "../../services/createTrade";

export const CreateTradeContext = createContext();

const CreateTrade = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [step, setStep] = useState(0);
  const [expire, setExpire] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [cancelModal, setCancelModal] = useState(false);
  const [disputeModal, setDisputeModal] = useState(false);
  const [phoneEmailOtp, setPhoneEmailOtp] = useState();
  const {width} = useWindowDimensions();
  const {id} = useParams();
  const {loading: userDataLoading} = useSelector((state) => state.generalReducer);

  const {loading, response} = useSelector((state) => state.getTradeByIdReducer);
  const {loading: disputLoading} = useSelector((state) => state.disputeTradeReducer);
  const [mobileStateView, setMobileStateView] = useState("trade");

  useEffect(() => {
    dispatch({type: CURRENT_TOTAL_VALUE, payload: null});

    dispatch(getBuySellTradeByIDAction({id: id}));
    dispatch(getTradeStatusByIDAction({id}));
    createTradeService
      .getTraderReviewDetails(id)
      .then((data) => {
        if (data?.message === "success") {
          dispatch({type: REVIEW_RESPONSE, payload: data?.reviews});
        }
      })
      .catch((err) => {});
  }, [id]);

  const userType = useGetUserType(response ?? {});
  const prevStep = () => {
    setStep((prev) => prev - 1);
  };

  useEffect(() => {
    const interId = setInterval(() => {
      dispatch(getTradeStatusByIDAction({id}));
    }, 10000);
    const reviewTimeout = setInterval(() => {
      dispatch(checkTradeReviewAction(id));
    }, 6000);
    return () => {
      setExpire(true);
      clearInterval(interId);
      clearInterval(reviewTimeout);
      dispatch({type: CANCEL_TRADE_DEFAULT, payload: ""});
    };
  }, [id]);

  return (
    <>
      <CreateTradeContext.Provider value={{expire, setExpire, phoneEmailOtp, setPhoneEmailOtp}}>
        <main className="__whole__createTrade__wrapper">
          {loading || disputLoading || userDataLoading ? (
            <Loader />
          ) : userType !== null ? (
            <>
              {width <= 730 && (
                <div className="__mobile__chat__c">
                  <div className="__theSwitch">
                    <div className={`__marketBuy ${mobileStateView}`} onClick={() => setMobileStateView("trade")}>
                      <BiBookmark size={16} style={{marginRight: "10px"}} /> Trade
                    </div>
                    <div className={`__marketSell ${mobileStateView}`} onClick={() => setMobileStateView("chat")}>
                      <RiChat3Line size={16} style={{marginRight: "10px"}} /> Chat
                    </div>
                  </div>
                </div>
              )}

              {mobileStateView === "trade" ? (
                <>
                  <CreateTradeTop />
                  <div className="__chat__detail__area">
                    <CreateTradeDetailsArea />
                    {width >= 730 && <CreateTradeChat style={{marginTop: "-160px"}} />}
                  </div>

                  <CreateTradeBottomArea
                    setOpenModal={setOpenModal}
                    setCancelModal={setCancelModal}
                    setDisputeModal={setDisputeModal}
                  />
                </>
              ) : (
                <CreateTradeChat style={{width: "100%", maxHeight: "100%"}} />
              )}
            </>
          ) : (
            <Oops />
          )}
        </main>

        <OurModal
          open={openModal}
          setOpen={(v) => {
            setStep(0);
            setPhoneEmailOtp();
            return setOpenModal(v);
          }}
        >
          <SwitchModalComponent setOpenModal={setOpenModal} step={step} setStep={setStep} prevStep={prevStep} />
        </OurModal>

        <OurModal open={cancelModal} setOpen={setCancelModal}>
          <CancelModal setOpenModal={setCancelModal} />
        </OurModal>

        <OurModal open={disputeModal} setOpen={setDisputeModal}>
          <DisputeModal setOpenModal={setDisputeModal} />
        </OurModal>
      </CreateTradeContext.Provider>
    </>
  );
};

export default CreateTrade;
