import {useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getUserTradeAction} from "../../../store/actions/getUserTrade";
import {TheUserTradeContext} from "../../Home/UserTrade";
import DisputeTradeOrders from "./disputetable";
import PendingTradeOrders from "./pending";
import TheTradeOrders from "./thetable";
import UserTradeService from "../../../services/getusertrades";
import {GET_LOADER, GET_USER_TRADES_SUCCESS} from "../../../store/types";

const TheTradeOrderTable = () => {
  const dispatch = useDispatch();
  const {tradeOrder} = useContext(TheUserTradeContext);

  useEffect(() => {
    dispatch({type: GET_LOADER, payload: true});
    UserTradeService.PendingAndDisputedUserTradeService().then((data) => {
      dispatch({type: GET_USER_TRADES_SUCCESS, payload: data});
    });
  }, []);

  // return tradeOrder === "all" ? (
  //   <AllTradeOrders />
  // ) : tradeOrder === "pending" ? (
  //   <PendingTradeOrders />
  // ) : (
  //   tradeOrder === "disputed" && <DisputedTradeOrders />
  // );

  return tradeOrder === "all" ? (
    <TheTradeOrders />
  ) : tradeOrder === "pending" ? (
    <PendingTradeOrders />
  ) : (
    <DisputeTradeOrders />
  );
};

export default TheTradeOrderTable;
