import React, {memo} from "react";

import "./IconButton.scss";

const IconButton = (props) => {
  return (
    <button
      className="icon-button"
      style={{marginRight: props.isOpen ? "14px" : "6px", backgroundColor: props.bgColor}}
      onClick={props.onClick}
    >
      {props.leadingIcon}
      {props.buttonTitle}
      {props.trailingIcon}
    </button>
  );
};

export default memo(IconButton);
