import React, {memo} from "react";
import {Bar} from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend} from "chart.js";
import useWindowDimensions from "../../hooks/useWindowDimension";
import {useSelector} from "react-redux";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const BarChart = () => {
  const userCoinLocalValue = useSelector((state) => state.overviewReducer.userCoinLocalValue);

  const labels = userCoinLocalValue?.value?.map((item) => item.coin);
  const coinValues = userCoinLocalValue?.value?.map((item) => item.value);

  const {width} = useWindowDimensions();
  return (
    <div>
      <Bar
        width={width <= "1024px" ? 400 : 350}
        options={{
          responsive: true,

          plugins: {
            cornerRadius: 2,
            legend: {
              position: "left",
              display: false,
            },
            title: {
              //   position: "left",
              display: true,
              text: "Assest Allocation",
            },
          },
          scales: {
            x: {
              ticks: {
                display: true,
              },
              grid: {
                display: false,
                drawBorder: false,
                drawOnChartArea: false,
                drawTicks: false,
              },
            },
            y: {
              ticks: {
                display: false,
              },
              grid: {
                display: false,
                drawBorder: false,
              },
              pointLabels: false,
            },
          },
        }}
        data={{
          labels,
          datasets: [
            {
              label: "Dataset 1",
              data: userCoinLocalValue.checker !== 0 ? coinValues : [10, 10, 10, 10],
              backgroundColor:
                userCoinLocalValue.checker !== 0
                  ? ["#D96100", "#627EEB", "#EFB227", "#5BC693"]
                  : ["#E9EDF7", "#E9EDF7", "#E9EDF7", "#E9EDF7"],
              //   borderWidth: 2,
              borderRadius: 15,
              borderSkipped: false,
            },
          ],
        }}
      />
    </div>
  );
};

export default memo(BarChart);
