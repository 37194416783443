import {
  ALL_NOTIFICATIONS,
  MESSAGE_NOTIFICATION,
  NOTIFICATION_PAGINATION_DEFAULT,
  NOTIFICATION_PAGINATION_NEXT,
  NOTIFICATION_PAGINATION_PREV,
  TRADE_NOTIFICATION,
  TRANSFER_NOTIFICATION,
} from "../types";

export const getAllNotifications = (param) => (dispatch) => {
  dispatch({type: ALL_NOTIFICATIONS, payload: param});
};

export const notificationOnDefault = (param) => (dispatch) => {
  dispatch({type: NOTIFICATION_PAGINATION_DEFAULT, payload: param});
};

export const notificationOnNextAction = (param) => (dispatch) => {
  dispatch({type: NOTIFICATION_PAGINATION_NEXT, payload: true});
};

export const notificationOnPrevAction = (param) => (dispatch) => {
  dispatch({type: NOTIFICATION_PAGINATION_PREV, payload: true});
};

export const getMessageNotification = (param) => (dispatch) => {
  dispatch({type: MESSAGE_NOTIFICATION, payload: param});
};

export const getTradeNotification = (param) => (dispatch) => {
  dispatch({type: TRADE_NOTIFICATION, payload: param});
};

export const getTransferNotification = (param) => (dispatch) => {
  dispatch({type: TRANSFER_NOTIFICATION, payload: param});
};
