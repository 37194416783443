import React, {memo, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import WalletService from "../../services/walletService";
import {getUserCoinLocalValue} from "../../store/actions/overviewAction";
import {getTransactionHistory, getWalletBalance} from "../../store/actions/walletAction";
import {Loader} from "../Common/Loader";
import OverviewComponent from "./overviewComponent";

const OverviewPage = () => {
  const dispatch = useDispatch();
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);

  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const userData = useSelector((state) => state.generalReducer.userData);

  const LunchUserDetails = async () => {
    await WalletService.getDepositWithdraw(0, 5).then((data) => {
      dispatch(getTransactionHistory(data?.transactions));
    });

    await WalletService.UserTotalBalance().then((data) => {
      const coinValueArray = [];
      for (let x in data?.tokensValueData[0]) {
        coinValueArray.push({value: data?.tokensValueData[0][x], coin: x.slice(0, -5)?.toUpperCase()});
      }

      const filteredCoinValue = coinValueArray.filter((item) => item.value !== null ?? item.value !== 0);

      if (filteredCoinValue.length !== 0) {
        dispatch(getUserCoinLocalValue({checker: 1, value: coinValueArray}));
      } else {
        dispatch(getUserCoinLocalValue({checker: 0, value: coinValueArray}));
      }

      dispatch(getWalletBalance(data));
    });

    for (let item of tokenDetails) {
      for (let x in userData.data.userData) {
        if (x === "walletAddressBTC" && item?.tokenSymbol?.toLowerCase() === "btc") {
          item["tokenId"] = userData.data.userData[x];
        }

        if (
          x === "walletAddressETH" &&
          (item?.tokenSymbol?.toLowerCase() === "eth" || item?.tokenSymbol?.toLowerCase() === "bnb")
        ) {
          item["tokenId"] = userData.data.userData[x];
        }

        if (x === "walletAddressTRON" && item?.tokenSymbol?.toLowerCase() === "usdt") {
          item["tokenId"] = userData?.data?.userData[x];
        }
      }
    }
  };

  useEffect(() => {
    LunchUserDetails();
  }, [tokenDetails]);

  return (
    <div style={{width: "100%"}}>
      {Object.keys(userWalletBalance).length === 0 && <Loader />}
      {Object.keys(userWalletBalance).length > 0 && <OverviewComponent />}
    </div>
  );
};

export default memo(OverviewPage);
