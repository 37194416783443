import "../../styles/convert/ConvertCardInputComponent.scss";
import BtcIcon from "../../assets/icons/convertBitcoin.png";
import UsdtIcon from "../../assets/icons/convertUsdt.png";
import arrowDown from "../../assets/icons/convertArrowDown.png";
import localCurrency from "../../assets/icons/convertNairaIcon.svg";
import CurrencyFormat from "react-currency-format";
import {useState} from "react";

// will change border color to purple if is focus
const ConvertCardInputComponent = ({
  icon = "",
  dropdown,
  label = "You Send",
  coinTitle = "BTC",
  setDropDown,
  token,
  handleChageSystem,
  value,
  convertTokenError,
  localCurrencyIcon,
}) => {
  const [isFocus, setIsFocus] = useState(false);

  const containerStyle =
    convertTokenError && token
      ? "ParentInputButtonContainerError"
      : isFocus
      ? "ParentInputButtonContainerActive"
      : "ParentInputButtonContainer";

  return (
    <>
      <div className={containerStyle}>
        <div className="TextAndInputContainerStyle">
          <p className="TextAndInputTitleStyle">{label}</p>
          {localCurrencyIcon ? (
            <div style={{display: "flex", alignItems: "center"}}>
              <img src={localCurrency} alt="btc-icon" className="ButtonContainerButtonStyleIcon" style={{width: 20}} />
              {value ? (
                <CurrencyFormat
                  value={value || ""}
                  placeholder={"0.0034"}
                  name="maxValue"
                  thousandSeparator={true}
                  decimalScale={2}
                  displayType={"text"}
                  className="TextAndInputStyle"
                  style={{height: 26, marginTop: 10, marginBottom: 10}}
                />
              ) : (
                <p className="TextAndInputStylePlaceholder" style={{height: 26, marginTop: 10, marginBottom: 10}}>
                  0.00
                </p>
              )}
            </div>
          ) : (
            // <input
            //   type="number"
            //   pattern="[0-9]+"
            //   className={"TextAndInputStyle"}
            //   placeholder={coinTitle === "BTC" ? "0.0002 - 0.050" : "10 - 1000"}
            //   value={value || ""}
            //   maxLength={coinTitle === "BTC" ? 7 : 4}
            //   onFocus={() => setIsFocus(true)}
            //   onBlur={() => setIsFocus(false)}
            //   disabled={token ? false : true}
            //   autocomplete="off"
            //   onChange={handleChageSystem}
            // />
            <CurrencyFormat
              className={"TextAndInputStyle"}
              placeholder={coinTitle === "BTC" ? "0.0002 - 0.050" : "10 - 1000"}
              value={value || ""}
              maxLength={coinTitle === "BTC" ? 7 : 4}
              onFocus={() => setIsFocus(true)}
              onBlur={() => setIsFocus(false)}
              disabled={token ? false : true}
              autocomplete="off"
              onChange={handleChageSystem}
              thousandSeparator={true}
              // displayType="text"
            />
          )}
        </div>

        <div className="ButtonContainerButtonStyle" onClick={setDropDown}>
          {dropdown ? (
            <img src={icon ? icon : UsdtIcon} alt="naira-icon" className="ButtonContainerButtonStyleIcon" />
          ) : icon?.toLowerCase() === "btc" ? (
            <img src={BtcIcon} alt="btc-icon" className="ButtonContainerButtonStyleIcon" />
          ) : (
            <img src={UsdtIcon} alt="ustd-icon" className="ButtonContainerButtonStyleIcon" />
          )}
          <p className="ButtonContainerButtonStyleTitle">{coinTitle}</p>
          <img src={arrowDown} alt="arrow-icon" className="ButtonContainerButtonStyleArrowIcon" />
        </div>
      </div>
    </>
  );
};

export default ConvertCardInputComponent;
