import {useState, useCallback, useRef} from "react";
import {HiOutlineChevronDown, HiOutlineChevronUp} from "react-icons/hi";
import {BiCheck} from "react-icons/bi";
import "../../styles/staticdropdown.scss";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {useMemo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getMarketPlaceBuyAndSellAction} from "../../store/actions/market";
import {MARKET_SKIP_SYSTEM} from "../../store/types";

export const ReCreatedTransactionStaticDropDown = ({
  name,
  options,
  initialValue,
  style,
  showName,
  color = "#000",
  dropdownOnChangeFunction,
  useLabel = false,
  setContent,
  value,
}) => {
  const dispatch = useDispatch();
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  const {limit, skip, fskip} = useSelector((state) => state.marketReducer);
  const [showDropdown, setShowDropDown] = useState(false);
  const [showSelectCondition, setShowSelectCondition] = useState(false);
  const thref = useRef();

  // const [value, setValue] = useState(initialValue);

  const handleClick = () => {
    if (showSelectCondition === false) {
      setShowDropDown(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setShowDropDown(false);
      setShowSelectCondition(false);
    }
  };

  useOnClickOutside(thref, () => setShowDropDown(false));

  return (
    <div className="__dd__wrapper" style={style}>
      <div className="staticDropDown__wrapper justify__between" onClick={handleClick}>
        <p
          className="__thelabelitem items__center"
          style={{marginLeft: "10px", color: color, fontSize: "13px", display: "flex"}}
        >
          {showName && (
            <p className="flex" style={{marginRight: "10px", color: "#667085"}}>
              {name} :
            </p>
          )}
          <p>{value ? (useLabel ? value.label : value?.value) : name}</p>
        </p>

        {showDropdown ? (
          <HiOutlineChevronDown size={16} className="staticDropDown__icon mr-1" color={"#98a2b3"} />
        ) : (
          <HiOutlineChevronUp size={16} className="staticDropDown__icon mr-1" color={"#98a2b3"} />
        )}
      </div>

      {showDropdown && (
        <div
          className="staticDropDown__content__wrapper"
          ref={thref}
          // data-aos="zoom-in"
          // data-aos-duration="300"
        >
          {options &&
            options.map((item, i) => {
              return (
                <div
                  onClick={() => {
                    // setValue(item);
                    setContent(item);
                    if (dropdownOnChangeFunction) {
                      dropdownOnChangeFunction({[item.attr]: item.value});
                    }

                    handleClick();

                    dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                    dispatch(
                      getMarketPlaceBuyAndSellAction({
                        ...marketPlaceData,
                        limit: limit,
                        skip: fskip,
                        [item.attr]: item.value,
                      })
                    );
                  }}
                  className="staticDropDown__contentOptions"
                  key={i}
                >
                  <span style={{fontSize: 15}}>{item.label}</span>
                  {value && value.value === item.value && <BiCheck size={22} />}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
};
