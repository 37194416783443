import React from "react";
import {AiFillInfoCircle} from "react-icons/ai";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {MODALCHECKER} from "../../store/types";
import "../../styles/walletStyles/notVerifiedPage.scss";

const ConvertVerificationPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div className="verifiedWrapper">
      <div className="verification__firstLayout">
        <div className="verification__firstLayoutIconBody">
          <AiFillInfoCircle className="verification__firstLayoutIcon" />
        </div>
      </div>
      <div className="verification__secondLayout">
        <h3>Verify your identity</h3>
        <p>Complete your identity verification to withdraw. It will just take few seconds</p>
      </div>
      <div className="verification__thirdLayout">
        <button
          onClick={() => {
            dispatch({type: MODALCHECKER, payload: {}});
            history.push("/app/settings");
          }}
        >
          Verify Now
        </button>
      </div>
    </div>
  );
};

export default ConvertVerificationPage;
