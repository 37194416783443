import React, {memo} from "react";
import {FiX} from "react-icons/fi";
import {useDispatch} from "react-redux";
import {getWalletModalDeposit, getWalletModalWithdrawal} from "../../store/actions/walletAction";
import "../../styles/walletStyles/walletDropDown.scss";

const WalletDropDown = ({token, setWalletModal, setModal, setWithdrawModal}) => {
  const dispatch = useDispatch();
  return (
    <div className="walletDropdownWrapper">
      <div className="dropDown__wrapper">
        <div className="dropDown__wrapperSectionOne">
          <div className="dropDown__wrapperSectionOneBody">
            <div className="dropDown__wrapperSectionOneBodyLayoutOne">
              <p>BTC</p>
            </div>
            <div className="dropDown__wrapperSectionOneLayoutTwo" onClick={() => setWalletModal(false)}>
              <FiX className="dropDown__wrapperSectionOneLayoutTwoIcon" />
            </div>
          </div>
        </div>
        <div className="dropDown__wrapperSectionTwo">
          <button
            onClick={() => {
              setWalletModal(false);
              dispatch(getWalletModalDeposit({name: "single", checker: true}));
            }}
          >
            Deposit
          </button>
        </div>
        <div className="dropDown__wrapperSectionTwo">
          <button
            onClick={() => {
              setWalletModal(false);
              dispatch(getWalletModalWithdrawal({name: "single", checker: true}));
            }}
          >
            Withdraw
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(WalletDropDown);
