import React, {memo, useRef, useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import "../../../styles/searchableCountryDropDown.scss";
import SearchablePhoneDropDownItem from "./SearchablePhoneDropDownItem";

const SearchableCountryDropDown = ({
  setSearch,
  search,
  apiList,
  HandleSelect,
  placeholder,
  DetailsIcons,
  name,
  nameAcr,
  code,
  iconsLead,
  stated,
  text,
}) => {
  const ref = useRef();
  const [openSearch, setOpenSearch] = useState(false);
  const [dataCode, setDataCode] = useState([]);
  const [searchDetails, setSearchDetails] = useState();
  const [query, setQuery] = useState("");
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  const openMenu = (e) => {
    if (showSelectCondition === false) {
      setOpenSearch(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpenSearch(false);
      setShowSelectCondition(false);
    }

    setQuery("");
    setDataCode(apiList);
  };

  const closeMenu = () => {
    setOpenSearch(false);
  };

  useOnClickOutside(ref, () => setOpenSearch(false));

  return (
    <React.Fragment>
      <div className="searchDropDownWrapper__countrySystem">
        {/* <p className="title">{`Default Currency ${search?.currencyCode}`}</p> */}
        <p className="title">{`${text ? "Country of Residence" : `Default Currency ${search?.currencyCode}`}`}</p>
        <div className="countryDropDownWrapperSectionDecorator" onClick={openMenu}>
          <div className="countryDropDownWrapperSectionDecoratorDetails">
            <div className="countryMetoInputLogoDetails" style={{marginRight: "10px"}}>
              <DetailsIcons intialValue={search?.countryName !== "" ? search?.countryName : ""} />
            </div>
            <p>{`${stated ? search?.countryName : `${search?.countryName} (${search?.currencyCode})`}`}</p>
          </div>

          <div className="searchAbleIconDecorator">
            {openSearch ? (
              <div className="searchIconBody">
                <IoIosArrowUp className="searchIcon" />
              </div>
            ) : (
              <div className="searchIconBody">
                <IoIosArrowDown className="searchIcon" />
              </div>
            )}
          </div>
        </div>

        <div style={{width: "100%"}}>
          <SearchablePhoneDropDownItem
            closeMenu={closeMenu}
            ref={ref}
            HandleSelect={HandleSelect}
            List={dataCode}
            query={query}
            setQuery={setQuery}
            searchDetails={searchDetails}
            DetailsIcons={DetailsIcons}
            name={name}
            nameAcr={nameAcr}
            code={code}
            iconsLead={iconsLead}
            search={search}
            setSearch={setSearch}
            setSearchDetails={setSearchDetails}
            openSearch={openSearch}
            placeholder={placeholder}
            DropdownWidth={"100%"}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(SearchableCountryDropDown);
