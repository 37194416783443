import React, {memo, useState} from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import logoMobile from "../../assets/icons/logoMobile.png";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {useHistory} from "react-router-dom";

import "../../styles/navbar.scss";

const MobileNavBar = () => {
  const history = useHistory();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = (page) => {
    setOpen(false);
    setAnchorEl(null);

    switch (page) {
      case "signin": {
        history.push("/login");
        break;
      }

      case "signup": {
        history.push("/signup");
        break;
      }

      case "forgot": {
        history.push("/forgot-password");
        break;
      }

      default: {
        history.push("/");
      }
    }
  };

  const openMenu = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  return (
    <React.Fragment>
      <div className="mobile-bar">
        <AppBar sx={{background: "#5d3cb2", zIndex: "1"}} position="absolute">
          <Toolbar sx={toolbar}>
            <div className="side-app-bar">
              <img style={{width: "30px", height: "30px", marginLeft: "10px"}} src={logoMobile} alt="icon" />
            </div>
            <Menu id="basic-menu" open={open} onClose={handleClose} anchorEl={anchorEl}>
              <MenuItem
                sx={menu}
                onClick={() => {
                  handleClose("signin");
                }}
              >
                Signin
              </MenuItem>
              <MenuItem
                sx={menu}
                onClick={() => {
                  handleClose("signup");
                }}
              >
                Signup
              </MenuItem>
              <MenuItem
                sx={menu}
                onClick={() => {
                  handleClose("forgot");
                }}
              >
                Forgot password
              </MenuItem>
            </Menu>
            <IconButton size="large" edge="start" color="inherit" aria-label="menu" sx={{mr: 1}} onClick={openMenu}>
              <MenuIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      </div>
    </React.Fragment>
  );
};

const toolbar = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
};

const menu = {
  fontSize: "0.9rem",
};

export default memo(MobileNavBar);
