import {
  CHANGE_CREATE_TRADE_INITIAL,
  CREATE_TRADE_DEFAULT,
  CREATE_TRADE_ERROR,
  CREATE_TRADE_SUCCESS,
  GET_LAST_TIME_SEEN,
} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
  getLastTimeSeen: "",
};

const createTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CREATE_TRADE_SUCCESS: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }

    case GET_LAST_TIME_SEEN: {
      return {
        ...state,
        getLastTimeSeen: payload,
      };
    }

    case CREATE_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case CREATE_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    case CHANGE_CREATE_TRADE_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default createTradeReducer;
