import {Skeleton} from "@mui/material";
import React, {memo} from "react";
import {FiUser} from "react-icons/fi";
import {useSelector} from "react-redux";

const AvatarSideBar = (props) => {
  const generalData = useSelector((state) => state.generalReducer.userData);

  const {isOpen} = props;

  return (
    <div style={containerAvatar}>
      <div style={avatar}>
        <FiUser style={{...innerAvatarStyle, objectFit: "cover"}} />
      </div>

      {!isOpen && (
        <div style={Row}>
          <div>
            {generalData?.data?.userData?.username === undefined || generalData?.data?.userData?.username === null ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                height={25}
                style={{background: "rgba(248,248,248)", opacity: "0.3"}}
              />
            ) : (
              <p style={usernameStyle}>
                {generalData?.data?.userData?.username.slice(0, 1)?.toUpperCase() +
                  "" +
                  generalData?.data?.userData?.username.slice(1, 8)}
              </p>
            )}

            {generalData?.data?.userData?.email === undefined ||
            generalData?.data?.userData?.email === null ||
            generalData?.data?.userData?.email === "" ? (
              <Skeleton
                animation="wave"
                variant="rectangular"
                width={100}
                style={{background: "rgba(248,248,248)", opacity: "0.3"}}
              />
            ) : (
              <p style={emailStyle}>
                {generalData?.data?.userData?.email.slice(0, 1)?.toUpperCase() +
                  "" +
                  generalData?.data?.userData?.email.slice(1, 10) +
                  "****"}
              </p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const containerAvatar = {
  width: "100%",
  display: "flex",
  height: "45px",
};

const avatar = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  background: "#f9f5f5",
  margin: "0rem 1rem 0 1.5rem",
};

const innerAvatarStyle = {
  width: "45px",
  height: "25px",
  color: "#7f56d9",
};
const usernameStyle = {
  fontSize: "14px",
  color: "#FFF",
};
const emailStyle = {
  fontSize: "12px",
  marginTop: "2px",
  color: "#E9D7FE",
};

const Row = {
  width: "90%",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export default memo(AvatarSideBar);
