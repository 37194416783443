import React from "react";
import OverviewPage from "../OverviewPage";

const OverviewDash = () => {
  return (
    <React.Fragment>
      <div style={overview}>
        <OverviewPage />
      </div>
    </React.Fragment>
  );
};

const overview = {
  width: "100%",
  height: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default OverviewDash;
