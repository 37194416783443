import {toast} from "react-toastify";
import store from "../store";
import {EDITTRADEDISABLE, IS_LOADING} from "../store/types";
import APIs from "./api";

const EditPostTradeService = {
  SinglePostTrade: ({_id}) => {
    return APIs.get(`/api/v1/order/get-order-details/${_id}`)
      .then((data) => {
        if (data.data.message === "success") {
          return data.data.order;
        }
      })
      .then((res) => {
        return Promise.all([
          getLocalCurrencyValue(res.tokenSymbol, res.currency),
          getMarginValue(res.tokenSymbol, res.currency, res.profitMargin),
          getUserData(),
        ]).then((data) => {
          const userData = data[2].data.userData;
          let coinBalance = 0;
          let marginCoinBalance = 0;

          const currencyValue = data[0].currencyValue;
          const marginValue = data[1].result;
          const marginDifference = marginValue - currencyValue;
          const numChecker = Math.sign(marginDifference);

          userData?.tokens.forEach((item) => {
            if (item?.tokenSymbol?.toLowerCase() === res?.tokenSymbol?.toLowerCase()) {
              coinBalance = Number(item.balance) * Number(currencyValue);

              marginCoinBalance = Number(item?.balance) * Number(marginValue);
            }
          });

          return {
            userData: res,
            currencyDetails: {
              userBalance: coinBalance,
              currency: currencyValue,
              margin: marginValue,
              difference: marginDifference,
              sign: numChecker,
              marginCoinBalance: marginCoinBalance,
            },
          };
        });
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  SinglePostTradeMargin: ({tokenSymbol, currency, margin}) => {
    const token = tokenSymbol?.toUpperCase();
    const cur = currency?.toUpperCase();
    return Promise.all([getLocalCurrencyValue(token, cur), getMarginValue(token, cur, margin), getUserData()])
      .then((res) => {
        const userData = res[2].data.userData;
        let coinBalance = 0;

        let marginCoinBalance = 0;

        if (margin === 0) {
          const currencyValue = res[0].currencyValue;
          const marginDifference = currencyValue - currencyValue;
          const numChecker = Math.sign(marginDifference);

          userData.tokens.forEach((item) => {
            if (item?.tokenSymbol?.toLowerCase() === token?.toLowerCase()) {
              coinBalance = Number(item?.balance) * Number(currencyValue);

              marginCoinBalance = Number(item?.balance) * Number(currencyValue);
            }
          });

          return {
            userBalance: coinBalance,
            currency: currencyValue,
            margin: currencyValue,
            difference: marginDifference,
            sign: numChecker,
            marginCoinBalance: marginCoinBalance,
          };
        }

        const currencyValue = res[0].currencyValue;
        const marginValue = res[1].result;

        const marginDifference = marginValue - currencyValue;
        const numChecker = Math.sign(marginDifference);

        userData.tokens.forEach((item) => {
          if (item?.tokenSymbol?.toLowerCase() === token?.toLowerCase()) {
            coinBalance = Number(item?.balance) * Number(currencyValue);

            marginCoinBalance = Number(item?.balance) * Number(marginValue);
          }
        });

        return {
          userBalance: coinBalance,
          currency: currencyValue,
          margin: marginValue,
          difference: marginDifference,
          sign: numChecker,
          marginCoinBalance: marginCoinBalance,
        };
      })
      .catch((err) => {
        toast.error("Something went wrong");
      });
  },

  deletePostTrade: (_id, navigate) => {
    return APIs.delete(`/api/v1/order/delete-order/${_id}`).then((data) => {
      if (data.data.message === "success") {
        store.dispatch({type: IS_LOADING, payload: false});
        store.dispatch({type: EDITTRADEDISABLE, payload: false});
        navigate.push("/app/trade-orders");
        return data.data;
      }
    });
  },

  editPostTrade: (payload, navigate) => {
    return APIs.post(`/api/v1/order/edit-order`, payload).then((data) => {
      if (data.data.message === "success") {
        store.dispatch({type: IS_LOADING, payload: false});
        store.dispatch({type: EDITTRADEDISABLE, payload: false});
        navigate.push("/app/trade-orders");
        return data.data;
      }
    });
  },
};

const getLocalCurrencyValue = (token, cur) => {
  return APIs.get(`/api/v1/open/get-token-price/${token}/${cur}`).then((data) => {
    return data.data;
  });
};

const getMarginValue = (token, cur, margin) => {
  return APIs.get(`/api/v1/open/calculate-margin-price/${token}/${cur}/${margin}`).then((data) => {
    return data.data;
  });
};

const getUserData = async () => {
  const data = await APIs.get("/api/v1/profile/get-user-data");
  return data;
};

export default EditPostTradeService;
