import {useState} from "react";
import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {CHANGE_PAGINATION} from "../../../store/types";
import "../../../styles/pagination.scss";

const DOMTablePaginationComponent2 = ({datalength}) => {
  const {width} = useWindowDimensions();
  const dispatch = useDispatch();

  const {currentPage, pageSize} = useSelector((state) => state.domPaginationReducer);

  const gg = Math.ceil(datalength / pageSize);
  const paginate = (pageNumber) => dispatch({type: CHANGE_PAGINATION, payload: pageNumber});

  return width <= 650 ? (
    <div className="__mobile__pagination">
      <button
        className="__left"
        style={{border: "none", backgroundColor: "transparent"}}
        onClick={() => {
          currentPage === 1 && paginate(currentPage - 1);
        }}
        disabled={currentPage === 1 ? true : false}
      >
        <AiOutlineArrowLeft size={20} />
      </button>
      <span className="__current">
        Page {datalength === 0 ? 0 : currentPage} of {gg}
      </span>
      <button
        className="__right"
        style={{border: "none", backgroundColor: "transparent"}}
        onClick={() => paginate(currentPage + 1)}
        disabled={currentPage === gg || datalength === 0}
      >
        <AiOutlineArrowRight size={20} />
      </button>
    </div>
  ) : (
    <div className="newTable__paginationWrapper">
      <div className="newTable__paginationNumber">
        Page {datalength === 0 ? 0 : currentPage} of {gg}
      </div>
      <div className="newTable__paginationNavigation">
        <button
          className="newTable__paginationNavigation__button"
          onClick={() => currentPage > 1 && paginate(currentPage - 1)}
          disabled={currentPage === 1}
        >
          previous
        </button>
        <button
          className="newTable__paginationNavigation__button"
          onClick={() => {
            paginate(currentPage + 1);
          }}
          disabled={currentPage === gg || datalength === 0}
        >
          next
        </button>
      </div>
    </div>
  );
};

export {DOMTablePaginationComponent2};
