import {useState} from "react";
import Layout from "../components/LandingComponents/layout";
import LinkButton from "../components/LandingComponents/linkButton";

import aboutHeaderImage from "../assets/images/about-us-header-img1.webp";
import oyolaTeam from "../assets/images/team.webp";
import aboutusIconOne from "../assets/icons/aboutus-icon-one.svg";
import aboutusIconTwo from "../assets/icons/aboutus-icon-two.svg";
import aboutusIconThree from "../assets/icons/aboutus-icon-three.svg";
import aboutusIconFour from "../assets/icons/aboutus-icon-four.svg";
import aboutusIconFive from "../assets/icons/aboutus-icon-five.svg";
import aboutusIconSix from "../assets/icons/aboutus-icon-six.svg";
import aboutusIconSeven from "../assets/icons/aboutus-icon-seven.svg";
import AboutUsTabMenu from "../components/Common/aboutus-tab-menu/AboutUseTabMenu";
import aboutUsWallet from "../assets/images/walletp2p.svg";
import aboutUsP2P from "../assets/images/p2p.svg";
import aboutUsEscrow from "../assets/images/escrowp2p.svg";

import "../styles/landing-page-styles/aboutpage.scss";
const About = () => {
  const token = localStorage.getItem("token");

  const [active, setActive] = useState(0);

  return (
    <Layout>
      <div className="aboutWrapper">
        {/* One */}
        <div className="rowOneContainer">
          <div className="heroSection">
            <div className="textContainer">
              <h4 className="titleAboutUs">Oyola - the future of P2P</h4>
              <p className="tagAboutUs">
                Oyola is transforming the way people buy, sell and hold digital currencies. From inception we’ve
                maintained our goal of eliminating hidden charges, thereby making it irresistible for people around the
                world to trade various digital currencies.
              </p>
            </div>
            <div className="ImageContainer" data-aos="fade-left">
              <img src={aboutHeaderImage} alt="header" className="imageElement" />
            </div>
          </div>
        </div>

        {/* Two */}
        <div className="rowTwoContainer" data-aos="fade-up">
          <h4 className="rowTwoContainerTitle">Our mission</h4>
          <h4 className="rowTwoContainerTag">
            Our goal is to set free the billions of individuals around the world who have had to rely on exchanges to
            facilitate cryptocurrency transactions by creating a peer-to-peer trading platform that eliminates the need
            for third-party interference and makes it possible for everyone to buy crypto on the Oyola Marketplace
            without the need for a traditional bank account.
          </h4>
          <h4 className="rowTwoContainerTitleTwo">Our values</h4>
          <h4 className="rowTwoContainerTagTwo">
            We thrive and work by three simple values in our effort to satisfy our users globally
          </h4>
        </div>

        {/* Three */}
        <div className="rowThreeContainer">
          <div className="heroSection">
            <div className="rowThreetextContainer">
              <img src={aboutusIconFive} alt="aboutusIconFive logo" className="rowThreeImageStyle" />
              <p className="rowThretextContainerOneTitleAboutUs">Transparency</p>
              <p className="rowThreetagAboutUs">
                Oyola values transparency and is dedicated to providing users with a trustworthy platform. We operate
                with clarity, honesty, and openness in our communication and business practices. We provide users with
                tools and information to make informed decisions about their crypto trading.
              </p>
            </div>
            <div className="rowThreetextContainer">
              <img src={aboutusIconSix} alt="aboutusIconSix logo" className="rowThreeImageStyle" />
              <p className="rowThretextContainerOneTitleAboutUs">Hardwork</p>
              <p className="rowThreetagAboutUs">
                At Oyola, we believe hard work is the foundation of success. Our commitment to building a platform that
                reflects our dedication, perseverance, and hard work includes a relentless focus on quality, attention
                to detail, and exceptional service delivery to our users. We understand that our success depends on our
                users' success, and we work hard every day to help them achieve their financial dreams.
              </p>
            </div>
            <div className="rowThreetextContainer ">
              <img src={aboutusIconSeven} alt="aboutusIconSeven logo" className="rowThreeImageStyle" />
              <p className="rowThretextContainerOneTitleAboutUs">User-centric</p>
              <p className="rowThreetagAboutUs">
                Oyola is a user-centric platform committed to providing exceptional trading experiences that prioritize
                our users' needs and interests. We continuously improve our platform to meet their evolving needs and
                provide resources and support for different levels of experience and knowledge.
              </p>
            </div>
          </div>
        </div>

        {/*  four */}
        <div className="rowFourContainer">
          <h4 className="rowFourContainerTitle">Our Product</h4>
          <h4 className="rowFourContainerTag">
            Oyola is transforming the way people buy, sell and hold digital currencies. No bank account? No worries. We
            have multiple payment methods to select from, making it easy for you to transact digital currencies the way
            you like.
          </h4>

          <div className="rowFourSection">
            <AboutUsTabMenu tab={setActive} />

            <div className="ImageContainer" data-aos="fade-left">
              {active === 0 && <img src={aboutUsP2P} alt="wallet 1" className="imageElement" data-aos="fade-left" />}
              {active === 1 && <img src={aboutUsWallet} alt="wallet 2" className="imageElement" data-aos="fade-left" />}
              {active === 2 && <img src={aboutUsEscrow} alt="wallet 3" className="imageElement" data-aos="fade-left" />}
            </div>
          </div>
        </div>

        {/* Row five */}
        <div className="rowFiveContainer">
          <h4 className="rowSixTitleAboutUs">What makes Oyola unique?</h4>
          <h4 className="rowSixTagAboutUs">Some of the benefits we provide our users are</h4>
          <div className="heroSection">
            <div className="textContainer">
              <img src={aboutusIconOne} alt="aboutusIconOne logo" className="sixRowImgStyle" />
              <p className="textContainerOneTitleAboutUs">Easy to use</p>
              <p className="tagAboutUs">
                We’ve spent countless hours working on Oyola. The goal, to create a platform that combines ease of use
                and a user-friendly interface, whilst being exciting to both new and existing crypto users.
              </p>
            </div>
            <div className="textContainer">
              <img src={aboutusIconTwo} alt="aboutusIconTwo logo" className="sixRowImgStyle" />

              <p className="textContainerOneTitleAboutUs">Free transactions</p>
              <p className="tagAboutUs">
                Exchange your digital assets at no cost on Oyola. Pay zero fees when you convert your digital assets to
                either your local currency or other cryptocurrency.
              </p>
            </div>
            <div className="textContainer">
              <img src={aboutusIconThree} alt="aboutusIconThree logo" className="sixRowImgStyle" />
              <p className="textContainerOneTitleAboutUs">Secure transactions</p>
              <p className="tagAboutUs">
                We stand by safe and secure transactions. We make this pledge due to our use of escrow security, which
                holds digital assets until transactions have been completed.
              </p>
            </div>
            <div className="textContainer">
              <img src={aboutusIconFour} alt="aboutusIconFour logo" className="sixRowImgStyle" />
              <p className="textContainerOneTitleAboutUs">Rewards</p>
              <p className="tagAboutUs">
                With Oyola, you can earn up to $10 for a successful referral. This means that both parties get $10 after
                successfully completing a specified number of transactions.
              </p>
            </div>
          </div>
        </div>

        {/* Row last two the last */}
        <div className="rowSixContainer" data-aos="fade-right">
          <h4 className="rowSixtitleAboutUs">Meet the team</h4>
          <div className="heroSection">
            <div className="textContainer">
              <p className="rowSixtagAboutUs">
                At Oyola, we cherish each other like family regardless of our diverse backgrounds, and this relationship
                allows us to better understand how we can serve our users. Well, It’s time to meet the family.
              </p>
            </div>
            <div className="ImageContainer">
              <img src={oyolaTeam} alt="meet the team" className="imageElement" />
            </div>
          </div>
        </div>

        {/* Row last */}
        <div className="rowSevenContainer" data-aos="fade-up" data-aos-anchor-placement="center-bottom">
          <div className="heroSection">
            <div className="textContainer">
              <p className="tagLastRowTitle">Are you ready to join the P2P trading community?</p>
              <p className="tagLastRowTag">Signup on Oyola now.</p>
            </div>
            <div className=" btnToTheLastContainer">
              {token ? (
                <LinkButton changeStyle={"signupButton"} title="Sign up" link={"/app/overview"} />
              ) : (
                <LinkButton changeStyle={"signupButton"} title="Sign up" link={"/signup"} />
              )}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default About;
