import MarketService from "../../services/market";
import {
  CHECK_FOR_EXTRA_DATA,
  GET_CHECK_OUT_DATA,
  GET_MARKET_PLACE_BUY_SELL,
  GET_MARKET_PLACE_DEFAULT_PAGE_STATE,
  GET_MARKET_PLACE_INITIAL,
  GET_MARKET_PLACE_ON_NEXT,
  GET_MARKET_PLACE_ON_PREV,
  PAGE_EMPTY_CHECKER,
} from "../types";

export const getMarketPlaceBuyAndSellAction = (args) => (dispatch) => {
  return MarketService?.getMarketPlaceBuyAndSell(args).then((data) => {
    dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: data?.marketTrades});

    dispatch({type: PAGE_EMPTY_CHECKER, payload: data?.marketTrades});

    dispatch({type: GET_MARKET_PLACE_INITIAL, payload: false});

    if (data?.marketTrades?.length === args?.limit) {
      dispatch({type: CHECK_FOR_EXTRA_DATA, payload: true});

      const futureSkipDetails = args?.skip + args?.limit;
      MarketService?.getMarketPlaceBuyAndSellFuture({...args, skip: futureSkipDetails, limit: args?.limit}).then(
        (data) => {
          if (data?.marketTrades?.length === 0) {
            dispatch({type: PAGE_EMPTY_CHECKER, payload: []});
            dispatch({type: CHECK_FOR_EXTRA_DATA, payload: false});
          } else {
            dispatch({type: CHECK_FOR_EXTRA_DATA, payload: false});
          }
        }
      );
    }
  });
};

export const marketPlaceOnNextAction = (args) => (dispatch) => {
  dispatch({type: GET_MARKET_PLACE_ON_NEXT, payload: true});
};

export const marketPlaceOnPrevAction = (args) => (dispatch) => {
  dispatch({type: GET_MARKET_PLACE_ON_PREV, payload: true});
};

export const marketPlaceDefaultPageAction = (args) => (dispatch) => {
  dispatch({type: GET_MARKET_PLACE_DEFAULT_PAGE_STATE, payload: true});
};

export const getMarketCheckOutDataAction = (args) => (dispatch) => {
  return MarketService.getMarketModalData(args).then((data) => {
    dispatch({type: GET_CHECK_OUT_DATA, payload: data});
  });
};
