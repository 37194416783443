import React from "react";
import "../../styles/sectionModalCentered.scss";

const SectionModalCentered = ({children, styleModel, open}) => {
  return (
    <div className="modalWrapper" style={open ? showModal : removeModal}>
      <div className="modalContainer">
        <div style={{...styleModel}} className={"modalContent"}>
          {children}
        </div>
      </div>
    </div>
  );
};

const showModal = {
  display: "block",
};

const removeModal = {
  display: "none",
};

export default SectionModalCentered;
