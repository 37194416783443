import {CONFIRM_PAYMENT, CONFIRM_PAYMENT_DEFAULT, CONFIRM_PAYMENT_ERROR, CONFIRM_PAYMENT_INITIAL} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const confirmTraderReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CONFIRM_PAYMENT: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case CONFIRM_PAYMENT_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case CONFIRM_PAYMENT_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case CONFIRM_PAYMENT_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default confirmTraderReducer;
