import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingVerifyModal} from "../../store/actions/settingAction";

const PhoneNumberButton = () => {
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetails = userData?.data?.userData;

  const dispatch = useDispatch();
  const toggle = () => {
    dispatch(settingVerifyModal(3));
  };

  if (userDetails?.hasOwnProperty("phoneNumber")) {
    return (
      <React.Fragment>
        <button className="styleSecurityButton" style={buttonStyle} onClick={toggle}>
          Change
        </button>
      </React.Fragment>
    );
  } else {
    return (
      <React.Fragment>
        <button className="styleSecurityButton" style={buttonStyle} onClick={toggle}>
          Add
        </button>
      </React.Fragment>
    );
  }
};

const buttonStyle = {
  borderRadius: "8px",
  background: "white",
  color: "gray",
  border: "1px solid #5d3cb2",
};

export default memo(PhoneNumberButton);
