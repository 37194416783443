// import iconCancel from "../../assets/icons/icon-cancel.svg";
import React, {memo, useState} from "react";
import SectionModalCentered from "../Common/sectionModalCentered";
import {useDispatch, useSelector} from "react-redux";
import {blockAUserModal, checkIfAUserIsBlocked} from "../../store/actions/profile";
import errorIcon from "../../assets/icons/error-top-icon-modal.svg";
import {ProfileService} from "../../services/profileService";
// import "../../styles/profile.scss";
import "./modalInfo.scss";
import {Loader4} from "../Common/Loader";
import {toast} from "react-toastify";

const UnblockAUser = () => {
  // toggle to next modal

  const dispatch = useDispatch();
  const {profileHeaderOtherDataReducer} = useSelector((state) => state.profileReducer);
  const checkIfAUserIsBlockedState = useSelector((state) => state.profileReducer.checkIfAUserIsBlockedState);

  const [isLoading, setLoading] = useState(false);

  function cancelButton() {
    dispatch(blockAUserModal(null));
  }

  const unblockAUser = async () => {
    setLoading(true);
    const payload = {
      username: profileHeaderOtherDataReducer.username,
    };
    try {
      const data = await ProfileService.unblockAUser(payload);

      if (data.data.message === "success") {
        dispatch(checkIfAUserIsBlocked(true));
        setLoading(false);
        cancelButton();
      } else {
        toast.error("User has already been", {theme: "colored"});

        setLoading(false);
        cancelButton();
        dispatch(checkIfAUserIsBlocked(false));
      }
    } catch (error) {
      toast.error("Something went wrong.", {theme: "colored"});
      setLoading(false);
      cancelButton();
    }
  };

  return (
    <SectionModalCentered open={true}>
      <main className="modal-container-profile-block">
        <div className="body" style={{padding: "2rem"}}>
          <div className="top-section-modal-body">
            <div className="img-error">
              <img src={errorIcon} alt="Error Icon" />
            </div>
            <p className="title">Do you want to unblock this user</p>
            <p className="tag">Are you sure you want to unblock this account? </p>
          </div>

          <div className="footer">
            <button className="button-footer cancel-button" onClick={cancelButton}>
              Cancel
            </button>
            <button className="button-footer confirm-secondary" onClick={unblockAUser}>
              {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Unblock</p>}
            </button>
          </div>
        </div>
      </main>
    </SectionModalCentered>
  );
};

export default memo(UnblockAUser);
