import {useEffect, useState} from "react";

// function getWindowDimensions() {
//   if (typeof window !== "undefined") {
//     const {innerWidth: width, innerHeight: height} = window;
//     return {
//       width,
//       height,
//     };
//   }
// }

export default function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState({width: "", height: ""});

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({width: window.innerWidth, height: window.innerHeight});
    }

    window.addEventListener("resize", handleResize);

    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
}
