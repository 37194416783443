import {Skeleton, Stack} from "@mui/material";
import {useEffect} from "react";
import {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {ActionModalContext} from "../../../MarketPlace/marketTable";
import {Avatar, NOTFOUND} from "../../../../hooks/imagePath";
import {
  getMarketPlaceBuyAndSellAction,
  marketPlaceOnNextAction,
  marketPlaceOnPrevAction,
} from "../../../../store/actions/market";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../utils/getTimeDiff";
import {useHistory} from "react-router-dom";
import {CURRENT_TOTAL_VALUE} from "../../../../store/types";

const MobileMarketTable = ({data, loading}) => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {setModalAction, setModalID} = useContext(ActionModalContext);
  const {userData, locationData} = useSelector((state) => state.generalReducer);
  let theLocation = locationData?.data?.geoData?.countryName ?? "Nigeria";
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  const {limit, skip, fskip} = useSelector((state) => state.marketReducer);
  useEffect(() => {
    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: fskip}));
  }, [limit, skip]);

  const onNext = () => {
    dispatch(marketPlaceOnNextAction());
  };

  const onPrev = () => {
    if (skip > 0) {
      dispatch(marketPlaceOnPrevAction());
    }
  };

  return (
    <>
      <div className="__mobile__market__table">
        {loading ? (
          Array.from(new Array(limit)).map((item, i) => (
            <Stack spacing={1} key={i} sx={{margin: "10px 10px 10px 10px"}}>
              <Skeleton animation="wave" variant="rectangular" height={200} />
            </Stack>
          ))
        ) : data && data.length > 0 ? (
          data.map((item, i) => {
            return (
              <div className="__mobile__market__content">
                <div className="__mobile__market__top">
                  <div className="__userUser">
                    <div className="__userImage" style={{backgroundImage: `url(${Avatar})`}}>
                      <div
                        className={`__presence ${
                          getTimeDifference(item?.tradeData?.timeLastSeen) < 5
                            ? "__online"
                            : getTimeDifference(item?.traderData?.timeLastSeen) > 5 &&
                              getTimeDifference(item?.traderData?.timeLastSeen) < 15
                            ? "__away"
                            : "__offline"
                        }`}
                      ></div>
                    </div>
                    <p className="__userName">{item?.username}</p>
                  </div>

                  <div className="__userOrderDetails">
                    <p className="__orderdetails">
                      {item?.tradeData?.totalNumberOfTrades} Orders,{" "}
                      {item?.tradeData?.totalNumberOfTrades === 0 ? (
                        0
                      ) : (
                        <CurrencyFormat
                          value={(item?.tradeData?.numberCompletedTrades / item?.tradeData?.totalNumberOfTrades) * 100}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          // prefix={"$"}
                          // suffix={` ${tradeResponse?.tokenSymbol}`}
                        />
                      )}
                      %
                    </p>
                  </div>
                </div>

                <div className="__unit__limit">
                  <div className="__unitTitle">
                    <p className="__unitText">Unit</p>
                    <span className="__unitValue">
                      <CurrencyFormat
                        value={item?.unitPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${item?.currency}`}
                      />
                    </span>
                  </div>
                  <div className="__unitTitle">
                    <p className="__unitText">Limit</p>
                    <span className="__unitValue">
                      <CurrencyFormat
                        value={item?.minAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${item?.currency}`}
                      />{" "}
                      -{" "}
                      <CurrencyFormat
                        value={item?.maxAmount}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        // prefix={"$"}
                        suffix={` ${item?.currency}`}
                      />
                    </span>
                  </div>
                </div>

                <div className="__action__wrapper">
                  <div className="__paymentMethod">
                    <span>{item?.paymentMethod}</span>
                  </div>

                  <div
                    className="__buySellAction"
                    onClick={() => {
                      setModalAction(true);

                      if (item?.traderID === userData?.data?.userData?._id) {
                        setModalID({
                          notAllow: true,
                        });

                        return;
                      }

                      setModalID({
                        id: item?._id,
                        traderID: item.traderID,
                        unitPrice: item?.unitPrice,
                        data: item,
                      });

                      const presentToken = userData?.data?.userData?.tokens?.find(
                        (data) =>
                          data?.tokenSymbol?.toLowerCase() ===
                          `${capitalizeFirstLetter(item?.tokenSymbol)}`.toLowerCase()
                      );

                      dispatch({type: CURRENT_TOTAL_VALUE, payload: presentToken?.balance});
                    }}
                  >
                    {capitalizeFirstLetter(marketPlaceData.type)} {item?.tokenSymbol?.toUpperCase()}
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="__notFound__wrapper">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}
        <div className="__view__market__wrapper">
          <button
            className="__view__allMarket"
            onClick={() => push(`/app/marketplace/${theLocation}/BTC/sell/Bank Transfer`)}
          >
            View All Market
          </button>
        </div>
      </div>
    </>
  );
};

export default MobileMarketTable;
