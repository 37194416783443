import React, {memo} from "react";
import {Doughnut} from "react-chartjs-2";
import CurrencyFormat from "react-currency-format";
import {Chart as ChartJS, ArcElement, Tooltip, Legend} from "chart.js";
import "../../styles/overviewStyles/pieChart.scss";
import useWindowDimensions from "../../hooks/useWindowDimension";
import {useSelector} from "react-redux";

ChartJS.register(ArcElement, Tooltip, Legend, Tooltip, Legend);

const PieChart = () => {
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);
  const userCoinLocalValue = useSelector((state) => state.overviewReducer.userCoinLocalValue);

  const label = userCoinLocalValue?.value?.map((item) => item.coin);
  const coinValues = userCoinLocalValue?.value?.map((item) => item.value);

  const {width} = useWindowDimensions();

  return (
    <div className="pieWrapper">
      <Doughnut
        width={250}
        height={250}
        options={{
          responsive: true,

          maintainAspectRatio: false,
          cutout: 60,

          layout: {padding: 20},
          plugins: {
            legend: {
              // position: width > 500 ? "bottom" : "right",
              position: "bottom",

              labels: {
                boxPadding: 100,
                padding: 30,
                usePointStyle: true,
                boxWidth: 10,
                boxHeight: 10,
                font: {
                  size: 12,
                  family: "Inter",
                  style: "normal",
                  weight: 500,
                },
              },
            },
          },
        }}
        data={{
          labels: label ?? [],
          datasets: [
            {
              label: "No of Stocks",
              data: userCoinLocalValue.checker !== 0 ? coinValues : [10, 10, 10, 10],
              backgroundColor:
                userCoinLocalValue.checker !== 0
                  ? ["#D96100", "#627EEB", "#EFB227", "#5BC693"]
                  : ["#E9EDF7", "#E9EDF7", "#E9EDF7", "#E9EDF7"],

              borderWidth: 3,
            },
          ],
        }}
      />
    </div>
  );
};

export default memo(PieChart);
