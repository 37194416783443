import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {getBuySellTransactionAction} from "../../../../store/actions/buySellTransactionHistory";
import TheTradeOrders from "./thetable";

const TheTradeOrderTable = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getBuySellTransactionAction({skip: 0, fskip: 0, limit: 10, tokenSymbol: null, type: null, status: null}));
  }, []);

  // return tradeOrder === "all" ? (
  //   <AllTradeOrders />
  // ) : tradeOrder === "pending" ? (
  //   <PendingTradeOrders />
  // ) : (
  //   tradeOrder === "disputed" && <DisputedTradeOrders />
  // );

  return <TheTradeOrders />;
};

export default TheTradeOrderTable;
