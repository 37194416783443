import React, {memo, useRef} from "react";
import CurrencyFormat from "react-currency-format";
import {MdOutlineClose} from "react-icons/md";
import {FiInfo} from "react-icons/fi";
import CoinDresser from "./CoinDresser";
import NetworkDresser from "./NetworkDresser";
import "../../styles/walletStyles/walletWithdrawalSingleModal.scss";
import {CoinIcons} from "../Common/coinSwitcher";
import {useDispatch, useSelector} from "react-redux";
import WalletService from "../../services/walletService";
import {
  getCoinNetworkSelection,
  getModalChecker,
  getNetworkFee,
  getWalletModalWithdrawal,
  getWithdrawalDetails,
  getWithdrawMainDetail,
} from "../../store/actions/walletAction";
import {Skeleton} from "@mui/material";
import {
  SKELETON_LOADER,
  TOKENNETWORKFEE,
  WALLETADDRESSCHECKER,
  WALLETADDRESSERROR,
  WITHDRAWALERROR,
} from "../../store/types";
import {formatBigNumber} from "../../utils/bigNumberFormatter";

const WithdrawSelectAnyModal = () => {
  const dispatch = useDispatch();
  const amountInput = useRef();
  const inputRef = useRef();
  const timeout = useRef();
  const coinNetworkSelection = useSelector((state) => state.walletReducer.coinNetworkSelection);
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);
  const networkData = useSelector((state) => state.walletReducer.networkData);
  const showSkeleton = useSelector((state) => state.postTradeReducer.skeletonLoader);
  const walletCoinDetails = useSelector((state) => state.walletReducer.walletCoinDetials);
  const tokenNetworkFee = useSelector((state) => state.walletReducer.tokenNetworkFee);
  const withDrawData = useSelector((state) => state.walletReducer.withDrawalMainDetails);
  const withdrawError = useSelector((state) => state.walletReducer.withdrawError);
  const walletAddressChecker = useSelector((state) => state.walletReducer.walletAddressChecker);
  const walletAddressError = useSelector((state) => state.walletReducer.walletAddressError);

  const handleChageSystem = (e) => {
    const {name, value} = e.target;
    dispatch(getWithdrawMainDetail({...withDrawData, [name]: value}));
    clearTimeout(timeout.current);

    if (name === "coinAmount") {
      if (Number(value) > walletCoinDetails.tokenBalance) {
        dispatch({type: WITHDRAWALERROR, payload: "Insufficient fund"});
      } else {
        dispatch({type: WITHDRAWALERROR, payload: ""});
        timeout.current = setTimeout(() => {
          TokenNetworkFeeSelected(
            walletCoinDetails?.tokenSymbol,
            walletCoinDetails?.tokenBalance,
            value,
            walletCoinDetails?.unitPrice
          );
        }, 600);
      }
    }

    if (name === "to") {
      if (!inputRef.current.value.trim()) {
        return;
      }

      if (walletCoinDetails?.tokenSymbol?.toLowerCase() === "usdt") {
        timeout.current = setTimeout(() => {
          WalletService.walletAddressCheck(value, "tron").then((data) => {
            if (data.message === "success") {
              dispatch({type: WALLETADDRESSCHECKER, payload: true});
              dispatch({type: WALLETADDRESSERROR, payload: ""});
            } else {
              dispatch({type: WALLETADDRESSCHECKER, payload: false});
              dispatch({type: WALLETADDRESSERROR, payload: "Wrong Wallet Address"});
            }
          });
        }, 600);

        return;
      }

      if (walletCoinDetails?.tokenSymbol?.toLowerCase() === "btc") {
        timeout.current = setTimeout(() => {
          WalletService.walletAddressCheck(value, "bitcoin").then((data) => {
            if (data.message === "success") {
              dispatch({type: WALLETADDRESSCHECKER, payload: true});
              dispatch({type: WALLETADDRESSERROR, payload: ""});
            } else {
              dispatch({type: WALLETADDRESSCHECKER, payload: false});
              dispatch({type: WALLETADDRESSERROR, payload: "Wrong Wallet Address"});
            }
          });
        }, 600);

        return;
      }

      if (walletCoinDetails?.tokenSymbol?.toLowerCase() === "bnb") {
        timeout.current = setTimeout(() => {
          WalletService.walletAddressCheck(value, "ethereum").then((data) => {
            if (data.message === "success") {
              dispatch({type: WALLETADDRESSCHECKER, payload: true});
              dispatch({type: WALLETADDRESSERROR, payload: ""});
            } else {
              dispatch({type: WALLETADDRESSCHECKER, payload: false});
              dispatch({type: WALLETADDRESSERROR, payload: "Wrong Wallet Address"});
            }
          });
        }, 600);

        return;
      }

      if (walletCoinDetails?.tokenSymbol?.toLowerCase() === "eth") {
        timeout.current = setTimeout(() => {
          WalletService.walletAddressCheck(value, "ethereum").then((data) => {
            if (data.message === "success") {
              dispatch({type: WALLETADDRESSCHECKER, payload: true});
              dispatch({type: WALLETADDRESSERROR, payload: ""});
            } else {
              dispatch({type: WALLETADDRESSCHECKER, payload: false});
              dispatch({type: WALLETADDRESSERROR, payload: "Wrong Wallet Address"});
            }
          });
        }, 300);

        return;
      }
    }
  };

  const TokenNetworkFee = (token, amount, unitPrice) => {
    if (token && amount) {
      dispatch({type: SKELETON_LOADER, payload: true});
      WalletService.TokenNetworkFee(token, amount, unitPrice).then((data) => {
        dispatch(getNetworkFee(data));
      });
    }
  };

  const TokenNetworkFeeSelected = (token, totalbalance, amount, unitPrice) => {
    if (token && amount) {
      dispatch({type: SKELETON_LOADER, payload: true});
      WalletService.TokenNetworkFeeSelected(token, totalbalance, amount, unitPrice).then((data) => {
        dispatch(getNetworkFee(data));
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (walletCoinDetails.tokenBalance >= withDrawData.coinAmount) {
      const withdrawalDetails = {
        to: withDrawData.to,
        amount: tokenNetworkFee?.amount,
        cryptoNetwork: networkData,
        chain: walletCoinDetails?.chain,
        tokenSymbol: walletCoinDetails?.tokenSymbol,
        total: tokenNetworkFee?.total,
        network: tokenNetworkFee?.fee,
        amountInLocalCurrency: tokenNetworkFee?.amountInLocalCurrency,
      };

      dispatch(getWithdrawalDetails(withdrawalDetails));
      dispatch(getWalletModalWithdrawal({}));

      dispatch(getModalChecker({num: 0}));
      dispatch(getModalChecker({num: 5}));
    } else {
      dispatch({type: WITHDRAWALERROR, payload: "Insufficient fund"});
    }
  };

  return (
    <div className="wallet__wrapper">
      <div className="withdrawPage__firstLayoutImageSection">
        <div>
          <CoinIcons
            intialValue={walletCoinDetails?.tokenSymbol}
            details={true}
            mainWrapper={{width: "40px", height: "40px"}}
          />
        </div>

        <div
          onClick={() => {
            dispatch(getWithdrawMainDetail({}));
            dispatch(getCoinNetworkSelection({}));
            dispatch({type: TOKENNETWORKFEE, payload: {}});
            dispatch({type: WALLETADDRESSCHECKER, payload: false});
            dispatch({type: WALLETADDRESSERROR, payload: ""});
            dispatch(getWalletModalWithdrawal({}));
          }}
          className="withdrawPage__firstLayoutImageSectionIconBody"
        >
          <MdOutlineClose className="withdrawPage__firstLayoutImageSectionIcon" />
        </div>
      </div>
      <div className="withdrawPage__SecondLayout">
        <h3>{`Withdraw ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}</h3>
        <p>{`You can withdraw ${walletCoinDetails?.name?.toLowerCase()}`}</p>
        <div className="withdrawPage__firstLayoutCoinSectionSelector">
          <CoinDresser edited={true} />
        </div>
      </div>

      <div className="withdrawPage__thirdLayout">
        <p>Select Network</p>
        <div className="withdrawPage__firstLayoutNetworkSectionSelector">
          <NetworkDresser />
        </div>
        <span className="withdrawPage__firstLayoutNetworkSectionSelectorDetails">
          Make sure you select the correct network to avoid transaction errors.
        </span>
      </div>

      <div className="withdrawPage__fivethLayout">
        <p>Wallet Addresss</p>
        <div className="withdrawPage__fivethLayoutInputBody">
          <input
            value={withDrawData.to || ""}
            ref={inputRef}
            placeholder="Input Wallet Address"
            name="to"
            onChange={handleChageSystem}
            autocomplete="off"
            disabled={coinNetworkSelection?.token && coinNetworkSelection?.network ? false : true}
          />
        </div>

        {walletAddressError ? (
          <span className="error">{walletAddressError}</span>
        ) : (
          <span>The maximum amount you can withdraw is $1xxxxxxx</span>
        )}
      </div>

      <div className="withdrawPage__fouthLayout">
        <p>Input Amount</p>
        <div className="withdrawPage__fouthLayoutInputBody">
          <CurrencyFormat
            value={withDrawData.coinAmount || ""}
            placeholder="Input Amount"
            name="coinAmount"
            ref={amountInput}
            autocomplete="off"
            onChange={handleChageSystem}
          />
          <div className="withdrawPage__fouthLayoutInputBodyFiller">
            <p
              onClick={() => {
                dispatch(
                  getWithdrawMainDetail({...withDrawData, coinAmount: formatBigNumber(walletCoinDetails?.tokenBalance)})
                );
                TokenNetworkFee(
                  walletCoinDetails?.tokenSymbol,
                  formatBigNumber(walletCoinDetails?.tokenBalance),
                  walletCoinDetails?.unitPrice
                );
                dispatch({type: WITHDRAWALERROR, payload: ""});
              }}
              className="withdrawPage__fouthLayoutInputBodyFillerMax"
            >
              MAX
            </p>
            <p className="withdrawPage__fouthLayoutInputBodyFillerCoin">
              {walletCoinDetails?.tokenSymbol?.toUpperCase()}
            </p>
          </div>
        </div>
        {withdrawError ? (
          <span className="error">{withdrawError}</span>
        ) : (
          <span>
            {`Avaliable Balance`}{" "}
            {Number(formatBigNumber(walletCoinDetails?.tokenBalance ? walletCoinDetails?.tokenBalance : 0)).toFixed(6)}
            {/* <CurrencyFormat
              value={walletCoinDetails?.tokenBalance ? walletCoinDetails?.tokenBalance : 0}
              displayType={"text"}
              decimalScale={6}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}
            /> */}
          </span>
        )}
      </div>

      <div className="withdrawPage__eighthLayout">
        <div className="withdrawPage__eighthLayoutFirstLayout">
          <div className="withdrawPage__eighthLayoutFirstLayoutIconBody">
            <FiInfo className="withdrawPage__eighthLayoutFirstLayoutIcon" />
          </div>
          {/* <p>24h  withdrawal limit</p> */}
          <p>minimum withdrawal limit</p>
        </div>
        <div className="withdrawPage__eighthLayoutSecondLayout">
          <p>
            <CurrencyFormat
              value={`${Object.keys(tokenNetworkFee).length > 0 ? tokenNetworkFee.fee : 0.0}`}
              displayType={"text"}
              decimalScale={6}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}
            />
          </p>
        </div>
      </div>

      <div className="withdrawPage__sixthLayout">
        <div className="withdrawPage__sixthLayoutFirstLayout">
          <p>Receive Amount</p>
          <div className="withdrawPage__sixthLayoutSecondLayoutAmount">
            <p>
              {showSkeleton ? (
                <Skeleton animation="wave" variant="rectangular" width={100} />
              ) : (
                <CurrencyFormat
                  value={`${Object.keys(tokenNetworkFee).length > 0 ? tokenNetworkFee.amount : 0.0}`}
                  displayType={"text"}
                  decimalScale={6}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}
                />
              )}
            </p>
          </div>
        </div>

        <div className="withdrawPage__sixthLayoutSecondLayout">
          <p>Network Fee</p>
          <div className="withdrawPage__sixthLayoutSecondLayoutTotalAmount">
            {showSkeleton ? (
              <Skeleton animation="wave" variant="rectangular" width={100} />
            ) : (
              <p>
                {Object.keys(tokenNetworkFee).length > 0 && <span style={{color: "#03a66d"}}>+</span>}

                <CurrencyFormat
                  value={`${Object.keys(tokenNetworkFee).length > 0 ? tokenNetworkFee.fee : 0.0}`}
                  displayType={"text"}
                  decimalScale={6}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}
                />
                <span> Network Fee</span>
              </p>
            )}
          </div>
        </div>

        <div className="withdrawPage__sixthLayoutSecondLayoutTotalAmountBody">
          <p>Total Amount In Local Currency</p>
          <div className="withdrawPage__sixthLayoutSecondLayoutTotalAmountTotalAmount">
            {showSkeleton ? (
              <Skeleton animation="wave" variant="rectangular" width={100} />
            ) : (
              <p>
                <CurrencyFormat
                  value={`${Object.keys(tokenNetworkFee).length > 0 ? tokenNetworkFee.amountInLocalCurrency : 0.0}`}
                  displayType={"text"}
                  decimalScale={6}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
                />
              </p>
            )}
          </div>
        </div>

        {/* <div className="withdrawPage__sixthLayoutSecondLayoutTotalAmountBody">
          <p>Total Amount</p>
          <div className="withdrawPage__sixthLayoutSecondLayoutTotalAmountTotalAmount">
            {showSkeleton ? (
              <Skeleton animation="wave" variant="rectangular" width={100} />
            ) : (
              <p>
                <CurrencyFormat
                  value={`${Object.keys(tokenNetworkFee).length > 0 ? tokenNetworkFee.total : 0.0}`}
                  displayType={"text"}
                  decimalScale={6}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}
                />
              </p>
            )}
          </div>
        </div> */}
      </div>

      <div>
        <div className="withdrawPage__seventhLayout">
          <button
            disabled={
              tokenNetworkFee.fee > tokenNetworkFee.amount ||
              !walletAddressChecker ||
              walletAddressError ||
              !walletCoinDetails.tokenSymbol ||
              !networkData ||
              networkData === "Select Network" ||
              withdrawError ||
              !withDrawData.to ||
              !withDrawData.coinAmount ||
              !tokenNetworkFee.amount ||
              !tokenNetworkFee.fee ||
              walletCoinDetails?.tokenBalance < withDrawData.coinAmount
                ? true
                : false
            }
            onClick={handleSubmit}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(WithdrawSelectAnyModal);
