import React, {memo} from "react";
import NetworkError from "../../assets/images/opps 1.svg";
import "../../styles/404/404ImageStyles.scss";

const ImageSection = () => {
  return (
    <div className="oopImageSection">
      <p>404</p>
    </div>
  );
};

export default memo(ImageSection);
