import {RiAttachment2} from "react-icons/ri";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useGetUserType} from "../../../../hooks/useGetUserType";
import {SmallLoader} from "../../../Common/Loader";

const SendUploadMessageImage = ({setFormData, setFileName, loading, setLoading}) => {
  // const dispatch = useDispatch();
  const {id} = useParams();
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  // const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  const userType = useGetUserType(response ?? {});
  const profileData = () => {
    if (userType === "user") {
      return response?.profileData?.userData;
    } else if (userType === "trader") {
      return response?.profileData?.traderData;
    }
  };

  const handleFileChange = async (e) => {
    let image = e.target.files[0];
    let formdata = new FormData();
    formdata.append(
      "image",
      image,
      `${response?.chatID}__${
        userType === "user" ? profileData()?.username : userType === "trader" && profileData()?.username
      }__${userType}__${image?.name}`
    );
    setFileName(image?.name);
    setFormData(formdata);
  };

  return (
    <div className="__upload__wrapper">
      <input
        type={"file"}
        //disabled={statusResponse?.status === "completed" || statusResponse?.status === "cancelled"}
        className="__file__input"
        accept="image/jpg, image/jpeg, image/png"
        onChange={handleFileChange}
      />
      {loading ? <SmallLoader /> : <RiAttachment2 size={18} className="__attach__icon" color="#8b96a7" />}
    </div>
  );
};

export default SendUploadMessageImage;
