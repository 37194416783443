import TransactionService from "../../services/transactionHistory";
import {
  FILTER_DEPOSIT_WITHDRAW_BY_COIN,
  FILTER_DEPOSIT_WITHDRAW_BY_DATE,
  FILTER_DEPOSIT_WITHDRAW_BY_STATUS,
  FILTER_DEPOSIT_WITHDRAW_BY_TYPE,
  FILTER_DEPOSIT_WITHDRAW_MULTIPLE,
  GET_DEPOSIT_NEXT,
  GET_DEPOSIT_PREV,
  GET_DEPOSIT_WITHDRAW_REFRESH,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS,
} from "../types";

export const getDepositWithdrawTransactionAction = (args) => (dispatch) => {
  return TransactionService.getDepositWithdraw(args).then((data) => {
    if (data.message === "success") {
      dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS, payload: data.transactions});
    }
  });
};

export const getDepositWithdrawOnNextAction = (args) => (dispatch) => {
  dispatch({type: GET_DEPOSIT_NEXT, payload: true});
};
export const getDepositWithdrawOnPrevAction = (args) => (dispatch) => {
  dispatch({type: GET_DEPOSIT_PREV, payload: true});
};
export const filterDepositWithdrawByCoinAction = (args) => (dispatch) => {
  dispatch({type: FILTER_DEPOSIT_WITHDRAW_BY_COIN, payload: args});
};
export const filterDepositWithdrawByTypeAction = (args) => (dispatch) => {
  dispatch({type: FILTER_DEPOSIT_WITHDRAW_BY_TYPE, payload: args});
};
export const filterDepositWithdrawMultipleAction = (args) => (dispatch) => {
  dispatch({type: FILTER_DEPOSIT_WITHDRAW_MULTIPLE, payload: args});
};
export const filterDepositWithdrawByStatusAction = (args) => (dispatch) => {
  dispatch({type: FILTER_DEPOSIT_WITHDRAW_BY_STATUS, payload: args});
};
export const filterDepositWithdrawByDateAction = (args) => (dispatch) => {
  dispatch({type: FILTER_DEPOSIT_WITHDRAW_BY_DATE, payload: args});
};
export const refreshDepositWithdrawAction = (args) => (dispatch) => {
  dispatch({type: GET_DEPOSIT_WITHDRAW_REFRESH, payload: args});
};
