import React, {memo, useState} from "react";
import "../../styles/walletStyles/walletOtp.scss";
// import {Button} from "@mui/material";
// import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import OtpInput from "react-otp-input";
import WalletService from "../../services/walletService";
// import {DETAILSOFWITHDRAWAL, WALLETOTPERROR, WITHDRAWALDETAILS} from "../../store/types";
import {
  getDetailsOfWithdrawal,
  getModalChecker,
  getWalletOtpError,
  getWithdrawalDetails,
} from "../../store/actions/walletAction";
import {useTimer} from "react-timer-hook";
import "../../styles/new/optStyles.scss";

const WalletOTP = () => {
  const dispatch = useDispatch();
  const [otp, setOtp] = useState("");
  const userData = useSelector((state) => state.generalReducer.userData);
  const withDrawalDetails = useSelector((state) => state.walletReducer.withDrawalDetails);
  const [error, setError] = useState("");
  const [timeChecker, setTimeChecker] = useState(false);
  const otpError = useSelector((state) => state.walletReducer.walletOtpError);
  const [hold, setHold] = useState(false);

  const handleChange = (otp) => {
    dispatch(getWalletOtpError(""));
    setOtp(otp);
    if (otp.length < 6) {
      setError("opt not complete");
    } else {
      setError("");
    }
  };

  const goToHome = async () => {
    setHold(true);

    const withdrawalData =
      userData?.data?.userData?.authPreference === "sms"
        ? {...withDrawalDetails, phoneCode: otp.toString(), authType: "sms"}
        : userData?.data?.userData?.authPreference === "google auth"
        ? {...withDrawalDetails, authCode: otp.toString(), authType: "google auth"}
        : {...withDrawalDetails, emailCode: otp.toString(), authType: "email"};

    delete withDrawalDetails?.total;
    delete withDrawalDetails?.network;
    delete withDrawalDetails?.amountInLocalCurrency;
    delete withDrawalDetails?.cryptoNetwork;

    dispatch(getModalChecker({num: 0}));

    dispatch(getWithdrawalDetails(withdrawalData));

    await WalletService.SendWithdrawal(withdrawalData).then((data) => {
      dispatch(getDetailsOfWithdrawal(data?.data));
      dispatch(getWalletOtpError(""));
      dispatch(getModalChecker({num: 3}));
      setHold(false);
    });

    setHold(false);
    setOtp("");
  };

  const ResendOtp = () => {
    dispatch(getModalChecker({num: 0}));
    WalletService.SendOtp().then((data) => {
      dispatch(getModalChecker({num: 2}));
    });
  };

  const timer = new Date();
  timer.setSeconds(timer.getSeconds() + 60);
  const {seconds, minutes} = useTimer({
    expiryTimestamp: timer,
    autoStart: true,
    onExpire: () => {
      setTimeChecker(true);
    },
  });

  const formatPhoneNumber = (data) => {
    const stringFormat = data ? `${data?.slice(0, 6)}***${data?.slice(-3, data?.length)}` : "";
    return stringFormat;
  };

  return (
    <div className="form-contents">
      <div className="form-contentsHeader">
        <p className="title">Security Verification</p>
        {userData?.data?.userData?.authPreference === "google auth" ? (
          <>
            <p style={{fontSize: "16px", fontWeight: "500"}} className="tag">
              Google authenticator verification
            </p>
            <p className="tagTell" style={{fontSize: "14px", fontWeight: "400", lineHeight: "30px"}}>
              Type in the code from your google authenticator app
            </p>
          </>
        ) : userData?.data?.userData?.authPreference === "sms" ? (
          <div className="form-contentsHeader__Content">
            <p className="tag">Please verify your phone number</p>
            <p className="tagTell">{`We sent a code to ${formatPhoneNumber(userData?.data?.userData?.phoneNumber)}`}</p>
          </div>
        ) : (
          <div className="form-contentsHeader__Content">
            <p className="tag">Please verify your email Address</p>
            <p className="tagTell">{`We sent a code to ${formatPhoneNumber(userData?.data?.userData?.email)}`}</p>
          </div>
        )}
      </div>
      <div
        style={{...otpContainer, margin: `${userData?.data?.userData?.authPreference === "google auth" && "20px 0px"}`}}
      >
        {/* <OtpInput
          value={otp || ""}
          onChange={handleChange}
          inputStyle={style}
          numInputs={6}
          separator={<span>&nbsp;&nbsp;&nbsp;</span>}
          placeholder="000000"
          isInputNum={true}
        /> */}

        <OtpInput
          value={otp}
          onChange={handleChange}
          className="optInputField"
          numInputs={6}
          placeholder="000000"
          isInputNum={true}
          containerStyle="optInputContainer"
          hasErrored={error || otpError ? true : false}
          errorStyle={{
            border: "1px solid #F04438",
          }}
        />

        {otpError && <p style={{color: "red", textAlign: "left", fontSize: "12px"}}>{otpError}</p>}
        {error && <p style={{color: "red", textAlign: "left", fontSize: "12px"}}>{error}</p>}
      </div>

      {userData?.data?.userData?.authPreference !== "google auth" && (
        <div className="form-contentsResendCode">
          <p className="form-contentsResendCodeContent">Didn’t get a code?</p>
          {!timeChecker ? (
            <p className="form-contentsResendCodeContent">{`${minutes} : ${seconds}`}</p>
          ) : (
            <p className="form-contentsResendCodeLink" onClick={ResendOtp}>
              Click to resend.
            </p>
          )}
        </div>
      )}

      <div className="form-contentsButtonBody" style={{marginTop: "25px"}}>
        <button
          onClick={() => {
            dispatch(getModalChecker({}));
            dispatch(getWalletOtpError(""));
          }}
          variant="contained"
          style={cancelButton}
          color="primary"
        >
          Cancel
        </button>
        <button
          variant="contained"
          className="signUp"
          color="primary"
          onClick={goToHome}
          disabled={otp.length === 6 && !hold ? false : true}
        >
          Submit
        </button>
      </div>
    </div>
  );
};

const otpContainer = {
  // display: "flex",
  // flexDirection: "column",
  // gap: "1rem",
  width: "100%",
};

const cancelButton = {
  width: "48%",
  height: "40px",
  background: "#fff",
  color: "#344054",
  fontSize: "14px",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  cursor: "pointer",
  border: "0.49375px solid #D0D5DD",
  outline: "none",

  "&:hover": {
    backgroundColor: "#E0E0E0",
    boxShadow: "none",
  },
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  borderRadius: "8px",
  outline: "none",
  paddingLeft: "12px",
  marginTop: "15px",
  border: "1px solid #D6BBFB",
  textAlign: "left",
  boxShadow: "0px 0.305933px 0.611867px rgba(16, 24, 40, 0.05)",
  color: "#7F56D9",
};

export default memo(WalletOTP);
