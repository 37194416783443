import {GET_SELECTED_COIN, GET_SELECTED_PAYMENT, GET_SELECTED_COUNTRY, GET_SELECTED_GEO_LOCATION} from "../types";

export const getSelectedCoinAction = (param) => (dispatch) => {
  dispatch({
    type: GET_SELECTED_COIN,
    payload: param,
  });
};

export const getSelectedPaymentAction = (param) => (dispatch) => {
  dispatch({
    type: GET_SELECTED_PAYMENT,
    payload: param,
  });
};

export const getSelectedCountryAction = (param) => (dispatch) => {
  dispatch({
    type: GET_SELECTED_COUNTRY,
    payload: param,
  });
};

export const getSelectedGeoLocationAction = (param) => (dispatch) => {
  dispatch({
    type: GET_SELECTED_GEO_LOCATION,
    payload: param,
  });
};
