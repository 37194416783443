import React from "react";
import {AiFillInfoCircle} from "react-icons/ai";
import {useHistory} from "react-router-dom";
// import "../../styles/walletStyles/notVerifiedPage.scss";
import "../../../styles/walletStyles/notVerifiedPage.scss";

const NotVerificationPage = ({setVerified}) => {
  const history = useHistory();

  return (
    <div className="verifiedWrapper" style={{width: "100%"}}>
      <div className="verification__firstLayout">
        <div className="verification__firstLayoutIconBody">
          <AiFillInfoCircle className="verification__firstLayoutIcon" />
        </div>
      </div>
      <div className="verification__secondLayout">
        <h3>Verify your identity</h3>
        <p>Complete your identity verification to send asset. It will just take few seconds</p>
      </div>
      <div className="verification__thirdLayout">
        <button
          onClick={() => {
            setVerified();
            history.push("/app/settings");
          }}
        >
          Verify Now
        </button>
      </div>
    </div>
  );
};

export default NotVerificationPage;
