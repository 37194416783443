import {
  CONVERT_BANK_ACC_CHECKER,
  CONVERT_BANK_DETAILS,
  CONVERT_BANK_DROPDOWN,
  CONVERT_BANK_LIST,
  CONVERT_CONFIRM_ACC_DETAILS,
  CONVERT_CONVERTION_NORMAL,
  CONVERT_CONVERTION_RATE,
  CONVERT_DROPDOWN_CONTENT,
  CONVERT_DROPDOWN_STATE,
  CONVERT_INSUFFICIENT_FUNDS,
  CONVERT_LEVEL,
  CONVERT_LOADER,
  CONVERT_MIN_LOADER,
  CONVERT_MODAL_CHECKER,
  CONVERT_TOKEN_ERROR,
  CONVERT_TOKEN_INPUT,
  CONVERT_TOKEN_MAX_MIN_ERROR,
  CONVERT_TRANSFER_DETAILS,
} from "../types";

export const getConvertDropdownState = (param) => (dispatch) => {
  dispatch({type: CONVERT_DROPDOWN_STATE, payload: param});
};

export const getConvertDropdownContent = (param) => (dispatch) => {
  dispatch({type: CONVERT_DROPDOWN_CONTENT, payload: param});
};

export const getConvertError = (param) => (dispatch) => {
  dispatch({type: CONVERT_TOKEN_ERROR, payload: param});
};

export const getConvertBankDropdown = (param) => (dispatch) => {
  dispatch({type: CONVERT_BANK_DROPDOWN, payload: param});
};

export const getConvertBankAccChecker = (param) => (dispatch) => {
  dispatch({type: CONVERT_BANK_ACC_CHECKER, payload: param});
};

export const getConvertLevel = (param) => (dispatch) => {
  dispatch({type: CONVERT_LEVEL, payload: param});
};

export const getConvertLoader = (param) => (dispatch) => {
  dispatch({type: CONVERT_LOADER, payload: param});
};

export const getConvertConvertionRate = (param) => (dispatch) => {
  dispatch({type: CONVERT_CONVERTION_RATE, payload: param});
};

export const getConvertBankList = (param) => (dispatch) => {
  dispatch({type: CONVERT_BANK_LIST, payload: param});
};

export const getConvertConvertionNormal = (param) => (dispatch) => {
  dispatch({type: CONVERT_CONVERTION_NORMAL, payload: param});
};

export const getConvertTokenInput = (param) => (dispatch) => {
  dispatch({type: CONVERT_TOKEN_INPUT, payload: param});
};

export const getConvertTokenMaxMinError = (param) => (dispatch) => {
  dispatch({type: CONVERT_TOKEN_MAX_MIN_ERROR, payload: param});
};

export const getConvertMinLoader = (param) => (dispatch) => {
  dispatch({type: CONVERT_MIN_LOADER, payload: param});
};

export const getConvertBankDetails = (param) => (dispatch) => {
  dispatch({type: CONVERT_BANK_DETAILS, payload: param});
};

export const getConvertConfirmAccDetails = (param) => (dispatch) => {
  dispatch({type: CONVERT_CONFIRM_ACC_DETAILS, payload: param});
};

export const getConvertTransferDetails = (param) => (dispatch) => {
  dispatch({type: CONVERT_TRANSFER_DETAILS, payload: param});
};

export const getConvertModalChecker = (param) => (dispatch) => {
  dispatch({type: CONVERT_MODAL_CHECKER, payload: param});
};

export const getConvertInsufficientFunds = (param) => (dispatch) => {
  dispatch({type: CONVERT_INSUFFICIENT_FUNDS, payload: param});
};
