import store from "../store";
import {getGoogleAuthProcessModals} from "../store/actions/settingAction";
import APIs from "./api";

const SettingsService = {
  ChangeCountryCurrency: (payload) => {
    return APIs.post(`/api/v1/profile/update-default-currency`, payload)
      .then((res) => {
        if (res.data.message === "success") {
          return res.data.message;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getReferralScheme: (payload) => {
    return APIs.post(`/api/v1/profile/claim-bonus`, payload)
      .then((res) => {
        if (res.data.message === "success") {
          return res.data.numberOfTrades;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  UserVerification: (param) => {
    return APIs.post("/api/v1/profile/user-verification", param).then((data) => {
      return data;
    });
  },

  GoogleAuthGetQRCode: () => {
    return APIs.get(`/api/v1/auth/activate-google-auth`)
      .then((res) => {
        if (res.data.message === "success") {
          return res.data.qrCodeUrl;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  GoogleAuthPostCode: (payload) => {
    return APIs.post(`/api/v1/auth/complete-auth-setup`, payload)
      .then((res) => {
        if (res.data.message === "success") {
          return res.data.message;
        }
      })
      .catch((err) => {
        store.dispatch(getGoogleAuthProcessModals({num: 1}));
        throw err;
      });
  },

  AuthPreference: (payload) => {
    return APIs.post(`/api/v1/auth/update-auth-preference`, payload).then((res) => {
      if (res.data.message === "success") {
        return res.data.message;
      }
    });
  },

  ClaimBonus: (payload) => {
    return APIs.post(`/api/v1/profile/claim-bonus`, payload)
      .then((data) => {
        return data.data.message;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  PhoneOtp: (data) => {
    return APIs.post(`/api/v1/user/verify-phone-number`, data).then((data) => {
      return data.data;
    });
  },

  UpdatePhone: (payload) => {
    return APIs.post(`/api/v1/profile/change-phone-number`, payload).then((data) => {
      return data.data;
    });
  },

  blockedUser: (payload) => {
    return APIs.get(`/api/v1/profile/get-blocked-users`, payload).then((data) => {
      return data.data;
    });
  },
};

export default SettingsService;
