import React, {useState} from "react";
import {BsPlus} from "react-icons/bs";
import {HiOutlineMinusSm} from "react-icons/hi";
import "./about-oyola-unique-section.scss";

const AboutOyolaUniqueSection = ({header, body, description, id}) => {
  const [toggleShow, setToggleShow] = useState("");

  const HandleShowDropDown = (_id) => {
    if (!toggleShow) {
      setToggleShow(_id);
    } else {
      setToggleShow("");
    }
  };

  return (
    <div className="UniqueWrapper" style={{height: `${!toggleShow ? "34px" : "unset"}`, transition: "all 2s"}}>
      <div className="Header" onClick={() => HandleShowDropDown(id)}>
        <p className="HeaderText">{header}</p>

        {!toggleShow ? (
          <div className="iconBody">
            <BsPlus size={16} className="icon" />
          </div>
        ) : (
          <div className="iconBody">
            <HiOutlineMinusSm size={16} className="icon" />
          </div>
        )}
      </div>

      {toggleShow && (
        <div className="Body">
          <p className="BodyText">{body}</p>
          {description?.map((item, key) => (
            <div key={key}>
              {item?.details === "text" && (
                <div>
                  <p className="BodyTextState">{item?.text}</p>
                </div>
              )}
              {item?.details === "array" && (
                <div>
                  {item?.questionArray?.map((dev, key) => (
                    <div key={key}>
                      <p className="BodyText">{dev?.text}</p>
                    </div>
                  ))}
                </div>
              )}
              {item?.details === "subSection" && (
                <div>
                  <p className="title">{item?.title}</p>
                  <p className="paragraph">{item?.paragraph}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AboutOyolaUniqueSection;
