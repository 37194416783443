import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import TradeOrderService from "../../../../services/tradeOrdersService";
import {
  ALL_TRADE_ORDERS,
  FILTERED_TRADE_ORDERS,
  IS_LOADING,
  PAGINATION_INDEX,
  PAGINATION_TOTAL,
} from "../../../../store/types";
import {Loader} from "../../../Common/Loader";
import AllTradeOrdersDesktopTable from "./desktopTable";
import AllTradeMobileTable from "./mobileTable";

const AllTradeOrders = ({data, loading}) => {
  const dispatch = useDispatch();
  // const allTradeOrders = useSelector((state) => state.tradeOrdersReducer.allTradeOrders);
  // const filteredTradeOrders = useSelector((state) => state.tradeOrdersReducer.filteredTradeOrders);
  // const paginationIndex = useSelector((state) => state.tradeOrdersReducer.paginationIndex);

  const getAllTradeOrders = () => {
    dispatch({type: IS_LOADING, payload: true});
    dispatch({type: FILTERED_TRADE_ORDERS, payload: []});
    dispatch({type: PAGINATION_INDEX, payload: 0});

    TradeOrderService.GetAllTradeOrder("all").then((data) => {
      const parentArray = [];
      const total_arrays_value = data.length / 10;

      for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
        parentArray.push(data.splice(0, 10));
      }

      dispatch({type: PAGINATION_TOTAL, payload: Math.ceil(total_arrays_value)});
      dispatch({type: ALL_TRADE_ORDERS, payload: parentArray});
      dispatch({type: IS_LOADING, payload: false});
    });
  };

  useEffect(() => {
    getAllTradeOrders();
  }, []);

  // const {response, loading} = useSelector();

  const {width} = useWindowDimensions();
  return width <= 700 ? (
    <AllTradeMobileTable data={data} loading={loading} />
  ) : (
    <AllTradeOrdersDesktopTable data={data} loading={loading} />
  );
};

export default AllTradeOrders;
