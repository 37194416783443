import moment from "moment";
import {
  GET_BUY_SELL_TRANSACTION_SUCCESS,
  GET_BUY_SELL_TRANSACTION_ERROR,
  GET_BUY_SELL_TRANSACTION_INITIAL,
  GET_BUY_SELL_PREV,
  GET_BUY_SELL_NEXT,
  FILTER_BUY_SELL_BY_COIN,
  FILTER_BUY_SELL_BY_TYPE,
  FILTER_BUY_SELL_BY_STATUS,
  FILTER_BUY_SELL_BY_DATE,
  FILTER_BUY_SELL_BY_MULTIPLE,
  GET_BUY_SELL_REFRESH,
  FILTER_BUY_SELL_DEFAULT,
  SELECT_SKIP,
  SELECT_FSKIP,
  SELECT_LIMIT,
  GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE,
  BUY_SELL_CHECK_FOR_EXTRA_DATA,
  BUY_SELL_CHECK_FOR_EMPTY,
  BUY_SELL_TRADE_DETAILS,
  GET_BUY_SELL_TRANSACTION_INITIAL_FUTURE,
} from "../types";

const initialState = {
  response: null,
  response2: null,
  response3: [],
  error: null,
  loading: false,
  skip: 0,
  fskip: 0,
  limit: 10,
  checkForEmpty: null,
  checkForExtraData: false,
  tradeDetials: {},
};

const buySellTransactionReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_BUY_SELL_TRANSACTION_SUCCESS: {
      return {
        ...state,
        response: payload,
        response2: payload,
        loading: false,
      };
    }

    case BUY_SELL_TRADE_DETAILS: {
      return {
        ...state,
        tradeDetials: payload,
      };
    }

    case BUY_SELL_CHECK_FOR_EXTRA_DATA: {
      return {
        ...state,
        checkForExtraData: payload,
      };
    }

    case BUY_SELL_CHECK_FOR_EMPTY: {
      return {
        ...state,
        checkForEmpty: payload,
      };
    }

    case GET_BUY_SELL_TRANSACTION_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }

    case GET_BUY_SELL_TRANSACTION_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_BUY_SELL_TRANSACTION_INITIAL_FUTURE: {
      return {
        ...state,
        loading: payload,
      };
    }

    case GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE: {
      return {
        ...state,
        loading: payload,
      };
    }

    case SELECT_SKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case SELECT_FSKIP: {
      return {
        ...state,
        skip: payload,
      };
    }

    case GET_BUY_SELL_REFRESH: {
      return {
        ...state,
        skip: 0,
        fskip: 0,
        limit: 10,
      };
    }

    case SELECT_LIMIT: {
      return {
        ...state,
        limit: payload,
      };
    }

    case FILTER_BUY_SELL_BY_COIN: {
      if (payload === null) {
        return {
          ...state,
          response: state.response2,
        };
      } else {
        let dd = state.response2.filter((item) => item.tokenSymbol === payload.token);
        return {
          ...state,
          response: dd,
        };
      }
    }
    case FILTER_BUY_SELL_BY_TYPE: {
      // if (payload === null) {
      //   return {
      //     ...state,
      //     response: state.response2,
      //   };
      // } else {
      //   let dd = state.response2.filter((item) => item.type === payload.type);
      //   return {
      //     ...state,
      //     response: dd,
      //   };
      // }
    }
    case FILTER_BUY_SELL_BY_STATUS: {
      if (payload === null) {
        return {
          ...state,
          response: state.response2,
        };
      } else {
        let dd = state.response2.filter((item) => item.status === payload.status);
        return {
          ...state,
          response: dd,
        };
      }
    }
    case FILTER_BUY_SELL_BY_DATE: {
      if (payload === null) {
        return {
          ...state,
          response: state.response2,
        };
      } else {
        let dd = state.response2.filter((item) => {
          return (
            moment(item.timeStamp).format("YYYY-M-D") >= moment(payload[0]?.startDate).format("YYYY-M-D") &&
            moment(item.timeStamp).format("YYYY-M-D") <= moment(payload[0]?.endDate).format("YYYY-M-D")
          );
        });
        return {
          ...state,
          response: dd,
        };
      }
    }
    case FILTER_BUY_SELL_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    case FILTER_BUY_SELL_BY_MULTIPLE: {
      const {type, status} = payload;
      let setMultipleShadowArrayData = [];
      if (type.length > 0 && status.length === 0) {
        type.forEach((str) => {
          const dataF = state.response2.filter((item) => item.type === str);
          setMultipleShadowArrayData.push(...dataF);
        });
      } else if (type.length > 0 && status.length > 0) {
        var tempList = [];
        type.forEach((str) => {
          const dataF = state.response2.filter((item) => item.type === str);
          setMultipleShadowArrayData.push(...dataF);
        });
        status.forEach((str) => {
          const dataF = tempList.filter((item) => item.status === str);
          tempList.push(...dataF);
        });
        setMultipleShadowArrayData = tempList;
      } else if (type.length === 0 && status.length > 0) {
        status.forEach((str) => {
          const dataF = state.response2.filter((item) => item.status === str);
          setMultipleShadowArrayData.push(...dataF);
        });
      }
      return {
        ...state,
        response: setMultipleShadowArrayData,
      };
    }
    default: {
      return state;
    }
  }
};

export default buySellTransactionReducer;
