import React, {memo} from "react";
import "../../styles/oop/oopTextStyles.scss";

const TextSection = () => {
  return (
    <div className="textOopSection">
      <p className="title">Something went wrong...</p>
      <p className="details">We had some trouble loading this page. Please refresh the page to try again</p>
    </div>
  );
};

export default memo(TextSection);
