import "../../styles/landing-page-styles/discover-oyola-benefit.scss";
import discover from "../../assets/images/discover.jpg";

const DiscoverOyolaBenefit = () => {
  return (
    <main className="homepageContainerOyola">
      <div className="innerContainer">
        <div className="oyolaWalletStyles">
          <div className="containerRight">
            <h1 className="title">Discover the benefit of Oyola</h1>
            <p className="tag">
              Experience the benefits of Oyola's secure, fast, and easy-to-use platform, and enjoy the freedom of
              trading cryptocurrency with confidence.
            </p>
          </div>

          <div className="containerLeft">
            <img src={discover} alt="discover the benefit of oyola" className="imageContainer" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default DiscoverOyolaBenefit;
