import {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {Link, useHistory} from "react-router-dom";
import {getUserType} from "../../../../hooks/getUserType";
import {BNB, BTC, ETH, NOTFOUND, USDT} from "../../../../hooks/imagePath";
import TransactionService from "../../../../services/transactionHistory";
import {
  getBuySellOnNextAction,
  getBuySellOnPrevAction,
  getBuySellTransactionAction,
} from "../../../../store/actions/buySellTransactionHistory";
import {
  BUY_SELL_CHECK_FOR_EMPTY,
  BUY_SELL_CHECK_FOR_EXTRA_DATA,
  BUY_SELL_TRADE_DETAILS,
  GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE,
  GET_BUY_SELL_TRANSACTION_SUCCESS,
  SELECT_SKIP,
} from "../../../../store/types";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {Loader} from "../../../Common/Loader";
import {TablePaginationComponent} from "../../../Common/pagination";

const TransactionDesktopBuySellTable = ({data, loading}) => {
  const {push} = useHistory();
  const dispatch = useDispatch();

  const {userData} = useSelector((state) => state.generalReducer);
  const {skip, fskip, limit, checkForExtraData, checkForEmpty, tradeDetials} = useSelector(
    (state) => state.buySellTransactionReducer
  );

  useEffect(() => {
    dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {type: null, tokenSymbol: null, status: null}});

    dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: null});
    dispatch({type: SELECT_SKIP, payload: 0});
    dispatch(getBuySellTransactionAction({skip: fskip, limit: limit, tokenSymbol: null, type: null, status: null}));
  }, []);

  const onNext = () => {
    const skipDetails = (skip + 1) * limit;
    if (checkForEmpty?.length !== 0) {
      TransactionService.getBuySell({
        skip: skipDetails,
        limit: limit,
        tokenSymbol: tradeDetials?.tokenSymbol,
        type: tradeDetials?.type,
        status: tradeDetials?.status,
      }).then((data) => {
        dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: []});

        if (data?.message === "success") {
          if (data?.trades?.length > 0) {
            dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data?.trades});
            dispatch({type: SELECT_SKIP, payload: skip + 1});
            dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: data?.trades});

            if (data?.trades?.length === limit) {
              dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: true});
              const futureSkipDetails = skipDetails + limit;

              TransactionService.getBuySellFuture({
                skip: futureSkipDetails,
                limit: limit,
                tokenSymbol: tradeDetials?.tokenSymbol,
                type: tradeDetials?.type,
                status: tradeDetials?.status,
              }).then((data) => {
                if (data?.trades?.length === 0) {
                  dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
                  dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
                } else {
                  dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
                }
              });
            }
          } else {
            dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
          }
        }
      });
    }
  };

  const onPrev = () => {
    dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: null});
    const skipDetails = (skip - 1) * limit;
    if (data?.length > 0 && skip + 1 > 1) {
      TransactionService.getBuySell({
        skip: skipDetails,
        limit: limit,
        tokenSymbol: tradeDetials?.tokenSymbol,
        type: tradeDetials?.type,
        status: tradeDetials?.status,
      }).then((data) => {
        dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: []});
        if (data?.message === "success") {
          dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data?.trades});
          dispatch({type: SELECT_SKIP, payload: skip - 1});
        }
      });
    }
  };

  return (
    <>
      <div className="table__wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="tablew">
              <thead className="newTable__thead">
                <tr className="newTable__tr">
                  <th className="newTable__thh">Coin Type</th>
                  <th className="newTable__thh">Type</th>
                  <th className="newTable__thh">Method</th>
                  <th className="newTable__thh">Unit Price</th>
                  <th className="newTable__thh">Amount</th>
                  <th className="newTable__thh">Counter Party</th>
                  <th className="newTable__thh">Country</th>
                  <th className="newTable__thh">Status</th>
                  <th className="newTable__thh">View More</th>
                </tr>
              </thead>
              <tbody className="newTable__tbody">
                {data &&
                  data?.length > 0 &&
                  data.map((item, i) => {
                    return (
                      <tr className="myTable" style={{height: "40px"}} key={i}>
                        <td className="tableItemm">
                          <div className="transactionTableArea__coinWrapper">
                            <img
                              src={
                                item?.tokenSymbol === "USDT"
                                  ? USDT
                                  : item?.tokenSymbol === "BTC"
                                  ? BTC
                                  : item?.tokenSymbol === "ETH"
                                  ? ETH
                                  : BNB
                              }
                              alt="usdt icon"
                              className="transactionTableArea__coinImage"
                            />
                            <span className="transactionTableArea__coin">{item?.tokenSymbol}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__typeWrapper">
                            <span className="transactionTableArea__type">{item?.type}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">{item?.paymentMethod}</span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">
                              <CurrencyFormat
                                value={item?.unitPrice}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                suffix={` ${item?.currency}`}
                              />
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__amountWrappers">
                            <span className="transactionTableArea__amount">
                              <CurrencyFormat
                                value={item?.value}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                suffix={` ${item?.currency}`}
                              />
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            {getUserType(item, userData) === "trader" ? (
                              <Link
                                to={"profile/" + item?.profileData?.userData?.username}
                                target={"_blank"}
                                className="transactionTableArea__wallet __countParty"
                              >
                                @{item?.profileData?.userData?.username}
                              </Link>
                            ) : (
                              <Link
                                to={"profile/" + item?.profileData?.traderData?.username}
                                target={"_blank"}
                                className="transactionTableArea__wallet __countParty"
                              >
                                @{item?.profileData?.traderData?.username}
                              </Link>
                            )}
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">{item?.country}</span>
                          </div>
                        </td>

                        <td className="tableItemm">
                          <div className="transactionTableArea__statusWrappers">
                            <span
                              className={`transactionTableArea__status ${
                                item?.status === "cancelled"
                                  ? "__cancelled"
                                  : item?.status === "pending"
                                  ? "__pending"
                                  : "__complete"
                              }`}
                            >
                              {capitalizeFirstLetter(item?.status)}
                            </span>
                          </div>
                        </td>
                        <td className="tableItemm">
                          <div className="transactionTableArea__viewMoreWrappers">
                            <span
                              className="transactionTableArea__viewMore"
                              onClick={() => {
                                // setModalData(value);
                                push(`/app/trade/${item?.trade_id}`);
                              }}
                            >
                              view trade
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </>
        )}
        {!loading && data && data?.length === 0 && (
          <div className="__notFound__wrapperr">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )}

        {data?.length > 0 && (
          <TablePaginationComponent
            onNext={onNext}
            onPrev={onPrev}
            skip={skip}
            lengthChecker={checkForEmpty}
            dataCheck={data}
            limit={limit}
            loading={checkForExtraData}
          />
        )}
      </div>
    </>
  );
};

export default TransactionDesktopBuySellTable;
