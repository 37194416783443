import React, {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import notFound from "../../assets/icons/notfound.svg";
import {CoinIcons} from "../Common/coinSwitcher";
import {CountryIcons} from "../Common/CountryIcons";
import "./newtable.scss";
import {TradeActionContext} from "../Home/Profile";
import {CURRENT_TOTAL_VALUE} from "../../store/types";
import {capitalizeFirstLetter} from "../../utils/capitalizeFirstLetter";

const AllTradeOrdersDesktopTable = () => {
  const {tradeActive} = useSelector((state) => state.tradeReducer);
  const activeTrade = tradeActive ?? [];

  return (
    <div className="table__wrapper new_wrapper_table-profile">
      <table className="tablew">
        {TableHeader()}

        {activeTrade && activeTrade.length > 0 ? (
          <TableRow data={activeTrade} />
        ) : (
          <tbody className="newTable__tbody" style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
            <div style={{textAlign: "center", marginTop: "50px"}}>
              <img src={notFound} alt="not found" style={{width: "100px", height: "auto"}} />
              <p style={{fontSize: "12px"}}>No Reviews</p>
            </div>
          </tbody>
        )}
      </table>
    </div>
  );
};

export default AllTradeOrdersDesktopTable;

function TableHeader() {
  return (
    <thead className="newTable__thead">
      <tr className="newTable__tr">
        <th className="newTable__th">Trader ID</th>
        <th className="newTable__th">Payment Method</th>
        <th className="newTable__th">Coin</th>
        <th className="newTable__th">Trade Type</th>
        <th className="newTable__th">Trade Location</th>
        <th className="newTable__th">Available Size</th>
        <th className="newTable__th">Unit Price</th>
        <th className="newTable__th"></th>
      </tr>
    </thead>
  );
}

const TableRow = (props) => {
  const dispatch = useDispatch();

  const traderID = props.data.traderID;
  const {setModalAction, setModalID} = useContext(TradeActionContext);

  const userData = useSelector((state) => state.generalReducer.userData);

  return (
    <React.Fragment>
      {props?.data?.slice(0, 5)?.map((item, key) => (
        <tbody className="newTable__tbody" key={key}>
          <tr className="myTable" style={{height: "40px"}}>
            <td className="tableItem">
              <div className="__seller__wrapper">
                <div className="__seller__Initials">ID</div>
                <div className="__seller__details">
                  <div className="__sellerName">{item?.traderID?.slice(0, 10) ?? "...."}...</div>
                </div>
              </div>
            </td>
            <td className="tableItem">
              <div className="__market__paymentMethod">{item?.paymentMethod}</div>
            </td>

            <td className="tableItem">
              <div className="__market__coin">
                <div style={{marginRight: "5px"}}>
                  <CoinIcons intialValue={item?.tokenSymbol} />
                </div>
                <div className="">
                  <p className="__tokenSymbol">{item?.tokenSymbol}</p>
                </div>
              </div>
            </td>
            <td className="tableItem">
              <div className="__market__paymentMethod" style={{fontSize: "14px"}}>
                {item?.type?.slice(0, 1)?.toUpperCase() + "" + item?.type?.slice(1)}
              </div>
            </td>
            <td className="tableItem">
              <div className="__market__coin">
                <div style={{marginRight: "5px"}}>
                  <CountryIcons intialValue={item?.country} />
                </div>
                <div className="">
                  <p className="__tokenSymbol">{item?.country}</p>
                </div>
              </div>
            </td>
            <td className="tableItem">
              <div className="__market__limit">
                <span>
                  <CurrencyFormat
                    value={item?.minAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={` ${item?.currency}`}
                  />
                </span>
                {" - "}
                <span>
                  <CurrencyFormat
                    value={item?.maxAmount}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={` ${item?.currency}`}
                  />
                </span>
              </div>
            </td>
            <td className="tableItem">
              <div className="__market__unitprice">
                <CurrencyFormat
                  value={item?.maxAmount}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  className="__theUnitPrice"
                  suffix={` ${item?.currency}`}
                />
              </div>
            </td>
            <td className="tableItem">
              <div
                className="__theStatusText __inactive"
                style={{
                  background: "#7F56D9",
                  fontSize: "14px",
                  padding: "6px 3rem 6px 2rem",
                  color: "white",
                  borderRadius: "7px",
                  cursor: "pointer",
                  width: "100px",
                  textAlign: "center",
                  textTransform: "capitalize",
                }}
                onClick={() => {
                  setModalAction(true);
                  setModalID({
                    id: item?._id,
                    traderID: item?.traderID,
                  });

                  const presentToken = userData?.data?.userData?.tokens?.find(
                    (data) =>
                      data?.tokenSymbol?.toLowerCase() === `${capitalizeFirstLetter(item?.tokenSymbol)}`.toLowerCase()
                  );

                  dispatch({type: CURRENT_TOTAL_VALUE, payload: presentToken?.balance});
                }}
              >
                {item?.type}
                &nbsp;
                {item?.tokenSymbol?.toLowerCase()}
              </div>
            </td>
          </tr>
        </tbody>
      ))}
    </React.Fragment>
  );
};
