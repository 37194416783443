import beginYourJourney from "../../assets/images/begin.svg";
import "../../styles/landing-page-styles/begin.scss";
import "../../styles/landing-page-styles/button.scss";

const BeginNew = () => {
  const token = localStorage.getItem("isLoggedIn");

  return (
    <main className="homepageContainerBegin">
      <div className="innerContainer">
        <div className="beginStyle">
          <div className="containerRight">
            <h1 className="title">Begin your crypto journey with Oyola</h1>
            <p className="tag">Buy your first crypto and build your portfolio with Oyola.</p>
            <div className="signUpContainer">
              <button
                style={{cursor: "pointer"}}
                className="baseButton primaryButtonLarge buttonAdditionalStyle"
                onClick={() => {
                  token === null || token === undefined
                    ? (window.location = "/signup")
                    : (window.location = "/app/overview");
                }}
              >
                Get Started
              </button>
            </div>
          </div>

          <div className="containerLeft">
            <img src={beginYourJourney} alt="phone wallet" className="imageContainer" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default BeginNew;
