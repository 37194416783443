import React, {memo, useState} from "react";
import "../../styles/login.scss";
import {useDispatch, useSelector} from "react-redux";
import {loader, loginError, storeLoginDetailsAction} from "../../store/actions/auth";
import {NewFormInput} from "../Common/NewFormInput";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/auth";
import {EMAIL_REGEX} from "../../utils/Validators";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {toast} from "react-toastify";
import {HOLD_USER_DETAILS} from "../../store/types";
import axios from "axios";
import OyolaPuzzleSecurityComponent from "./OyolaPuzzleSecurityComponent";

const LoginForm = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [validEmail, setValidEmail] = useState(false);
  const [hidden, setHidden] = useState(false);

  const [validPassword, setValidPassword] = useState(false);

  const [showEmailError, setShowEmailError] = useState(false);
  const [showPasswordError, setShowPasswordError] = useState(false);

  const [hold, setHold] = useState({});

  const {storeLoginDetailsState, loginErrorsState, holdUserDetails} = useSelector((state) => state.authReducer);

  const goToPassword = () => {
    dispatch(loginError(""));
    history.push("/forgot-password");
  };

  const goToRegister = () => {
    dispatch(loginError(""));
    history.push("/signup");
  };

  const validateCredentials = (e) => {
    if (holdUserDetails.hasOwnProperty("email")) {
      setValidEmail(EMAIL_REGEX.test(holdUserDetails?.email));
    }

    if (holdUserDetails.hasOwnProperty("password")) {
      setValidPassword(holdUserDetails?.password?.length >= 6);
    }

    setShowEmailError(false);
    setShowPasswordError(false);
    dispatch(loginError(""));
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "email": {
        setValidEmail(EMAIL_REGEX.test(e.target.value));
        dispatch({type: HOLD_USER_DETAILS, payload: {...holdUserDetails, email: e.target.value}});
        setHold({...hold, email: e.target.value});
        break;
      }

      case "password": {
        const getValue = e.target.value;
        setValidPassword(getValue.length >= 2);
        dispatch({type: HOLD_USER_DETAILS, payload: {...holdUserDetails, password: e.target.value}});
        setHold({...hold, password: e.target.value});
        break;
      }

      default: {
        break;
      }
    }
  };

  const captchaConfig = {
    config: {
      captchaId: "560ab7647f914668afe8162e641a7df1",
      language: "eng",
      product: "bind",
      protocol: "https://",
    },
    handler: (captchaObj) => {
      window.captchaObj = captchaObj;
      captchaObj
        .appendTo("#captcha")
        .onReady(function () {})
        .onNextReady(function () {})
        .onBoxShow(function () {})
        .onError(function (e) {})
        .onSuccess(() => {
          const payload = {
            email: captchaObj?.accountDetails?.email,
            username: "",
            isLogin: true,
            password: captchaObj?.accountDetails?.password,
            checkLoginDetails: true,
            isValid: true,
          };

          setShowPasswordError(false);

          dispatch(loader(1));

          AuthService.userDataForgotPassword({email: payload.email}).then((data) => {
            if (data.message === "success") {
              AuthService.validateLogin(payload).then((res) => {
                if (res.data.message === "success") {
                  if (data?.userDetails?.authPreference === "google auth") {
                    dispatch(loader(3));
                    dispatch(storeLoginDetailsAction({...payload, authPreference: "google auth"}));
                    dispatch(loginError(""));
                  } else {
                    AuthService.verifyEmailSecurely(payload)
                      .then((data) => {
                        if (data.data.message === "success") {
                          dispatch(loader(2));
                          dispatch(storeLoginDetailsAction(payload));
                          dispatch(loginError(""));
                        }
                      })
                      .catch(() => {
                        toast("Something went wrong");
                        dispatch(loader(0));
                        return;
                      });
                  }
                }
              });
            } else {
              dispatch(loader(0));
            }
          });
          if (captchaConfig.config.product === "bind") {
            Geevalidate();
          }
        });
    },
  };

  const Geelogin = () => {
    if (captchaConfig.config.product === "bind") {
      if (window.captchaObj) {
        window.captchaObj.accountDetails = {...holdUserDetails};
        window.captchaObj.showCaptcha();
      } else {
        console.log("Please wait for the verification initialization to complete");
        return false;
      }
    } else {
      Geevalidate();
    }
  };

  const Geevalidate = () => {
    var result = window.captchaObj.getValidate();
    if (!result) {
      return;
    }

    axios({
      method: "get",
      url: "/login",
      params: Object.assign(result, {
        captcha_id: "560ab7647f914668afe8162e641a7df1",
      }),
    }).then((res) => {
      if (res.data.result) {
      }
    });
  };

  const login = () => {
    if (validEmail) {
      setShowEmailError(false);
      if (validPassword === true || storeLoginDetailsState.hasOwnProperty("password")) {
        Geelogin();
      } else {
        setShowPasswordError(true);
      }
    } else {
      dispatch(loginError(""));
      setShowEmailError(true);
    }
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="form-contents">
          <p className="title">Sign in to your account</p>
          <p className="tag">Welcome back!! Please enter your details</p>

          <div style={{margin: "30px 0 10px", width: "100%"}}>
            <div style={{...errorStyle, textAlign: "center"}}>{loginErrorsState}</div>
            <NewFormInput
              data-testid="email"
              id="email"
              label={"Email"}
              type={"email"}
              value={holdUserDetails?.email || ""}
              onChange={validateCredentials}
              displayError={showEmailError || loginErrorsState}
            />

            <div data-testid="emailError" style={{...errorStyle, visibility: showEmailError ? "visible" : "hidden"}}>
              Please enter your email
            </div>

            <NewFormInput
              data-testid="password"
              id="password"
              margin={"1.5rem"}
              label={"Password"}
              type={hidden ? "text" : "password"}
              displayError={showPasswordError || loginErrorsState}
              value={holdUserDetails?.password || ""}
              onChange={validateCredentials}
              icon={
                hidden ? (
                  <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                ) : (
                  <AiOutlineEye fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                )
              }
            />

            <div
              data-testid="passwordError"
              style={{...errorStyle, visibility: showPasswordError === true ? "visible" : "hidden"}}
            >
              Please enter your password
            </div>
          </div>

          <div id="captcha" className={` ${captchaConfig.config.product === "bind" ? "hideHeight" : null}`}>
            <OyolaPuzzleSecurityComponent captcha-config={captchaConfig} />
          </div>

          <button data-testid="sign" className="buttonStyle" onClick={login}>
            Sign in
          </button>
          <div className="footerLogin">
            <p data-testid="forgot" className="forgot" onClick={goToPassword}>
              Forgot Password
            </p>
            <div className="innerContainer">
              <p data-testid="account" className="already">
                Don't have an account?
              </p>
              <p data-testid="signup" className="signup" onClick={goToRegister}>
                Sign up
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const errorStyle = {
  color: "#F04438",
  textAlign: "left",
  marginTop: "1rem",
  marginBottom: "1rem",
  width: "100%",
  fontSize: "1.4rem",
  fontWeight: 500,
};
export default memo(LoginForm);
