import APIs from "./api";

const NotificationService = {
  NotificationsSystem: async (skip, limit, type) => {
    return APIs.get(`/api/v1/notification/get-notifications/${skip}/${limit}/${type}`)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  AllNotifications: async (skip, limit) => {
    return APIs.get(`/api/v1/notification/get-notifications/${skip}/${limit}`)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  UnReadNotification: async () => {
    return APIs.get(`/api/v1/notification/get-unread-notifications`).then((data) => {
      return data.data;
    });
  },

  markAllAsReadNotification: async () => {
    return APIs.post(`/api/v1/notification/mark-as-read-all`).then((data) => {
      return data;
    });
  },

  markAsRead: async (param) => {
    return APIs.post("/api/v1/notification/mark-as-read", param).then((data) => {
      return data;
    });
  },
};

export default NotificationService;
