import {useState, CSSProperties} from "react";
import SyncLoader from "react-spinners/SyncLoader";

const override = {
  display: "block",
  margin: "0 auto",
  borderColor: "red",
};

function SyncSpinnerLoader() {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#7F56D9");

  return (
    <SyncLoader
      color={color}
      loading={loading}
      cssOverride={override}
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
}

export default SyncSpinnerLoader;
