import OtpInput from "react-otp-input";
import "../../styles/new/optStyles.scss";

const OptInputField = (props) => {
  return (
    <div style={{marginTop: props.marginTop, marginBottom: props.marginBottom}} className="basic-opt-input-container">
      <div style={{height: "70px", margin: "0rem 0rem 0rem"}}>
        <p className="title" style={props.titleStyle}>
          {props.title}
        </p>
        {props.isGoogleOn ? (
          <p className="tag-otp-input" style={props.tagStyle}>{`Open the google authenticator app to generate code`}</p>
        ) : (
          <p className="tag-otp-input" style={props.tagStyle}>{`We sent a code to ${props.tag}`}</p>
        )}

        <p
          className="tag-otp-input"
          style={{margin: "0rem 0rem 0rem", lineHeight: "normal", color: "#f04438", ...props.newErrorStyle}}
        >
          {props.error}
        </p>
      </div>
      <OtpInput
        value={props.value}
        onChange={props.onChange}
        className="optInputFieldPhone"
        containerStyle="optInputContainer"
        // inputStyle="opt-input-fields"
        numInputs={6}
        placeholder="000000"
        isInputNum={true}
        hasErrored={props.hasErrored}
        // hasErrored={loginDetailDataSecond ? true : false}
        errorStyle={{
          border: "1px solid #F04438",
        }}
      />
    </div>
  );
};

export default OptInputField;
