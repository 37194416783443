import {toast} from "react-toastify";
import store from "../store";
import {
  CANCEL_TRADE_ERROR,
  CANCEL_TRADE_INITIAL,
  CHANGE_CREATE_TRADE_INITIAL,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_INITIAL,
  CREATE_TRADE_ERROR,
  DISPUTE_TRADE_ERROR,
  DISPUTE_TRADE_INITIAL,
  GET_TRADE_BY_ID_ERROR,
  GET_TRADE_INITIAL,
  GET_TRADE_STATUS_ERROR,
  GET_TRADE_STATUS_INITIAL,
  RATE_TRADE_ERROR,
  RATE_TRADE_INITIAL,
  RELEASE_TOKEN_ERROR,
  RELEASE_TOKEN_INITIAL,
  TRADE_REVIEW_ERROR,
  TRADE_REVIEW_INIITIAL,
} from "../store/types";
import API from "./api";

const createTradeService = {
  createBuySellTrade: (data) => {
    store.dispatch({type: CHANGE_CREATE_TRADE_INITIAL, payload: true});
    return API.post("/api/v1/trade/post-trade", data)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: CREATE_TRADE_ERROR, payload: error.message});
        toast.error(error.message);
      });
  },

  getBuySellTradeByID: (data) => {
    store.dispatch({type: GET_TRADE_INITIAL, payload: true});
    return API.get(`/api/v1/trade/get-trade-data/${data?.id}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: GET_TRADE_BY_ID_ERROR, payload: error});
        throw error;
      });
  },

  getBuySellTradeByIDAdvanced: (data) => {
    return API.get(`/api/v1/trade/get-trade-data/${data?.id}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: GET_TRADE_BY_ID_ERROR, payload: error});
        throw error;
      });
  },

  getTradeStatusByID: (data) => {
    store.dispatch({type: GET_TRADE_STATUS_INITIAL, payload: true});
    return API.get(`/api/v1/trade/get-trade-status/${data?.id}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: GET_TRADE_STATUS_ERROR, payload: error});

        throw error;
      });
  },

  getTradeStatusByIDAdvanced: (data) => {
    return API.get(`/api/v1/trade/get-trade-status/${data?.id}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: GET_TRADE_STATUS_ERROR, payload: error});

        throw error;
      });
  },

  confirmTradePaymentByID: (data) => {
    store.dispatch({type: CONFIRM_PAYMENT_INITIAL, payload: true});
    return API.post(`api/v1/trade/confirm-trade-payment`, data)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: CONFIRM_PAYMENT_ERROR, payload: error});
        throw error;
      });
  },

  checkTradeReviewByID: async (tradeid) => {
    store.dispatch({type: TRADE_REVIEW_INIITIAL});
    try {
      const data = await API.get(`api/v1/trade/check-if-reviewed/${tradeid}`);
      return data && data?.data;
    } catch (error) {
      store.dispatch({type: TRADE_REVIEW_ERROR, payload: error});
      // throw error;
      toast(error.message);
    }
  },

  getTraderReviewDetails: async (tradeid) => {
    try {
      const data = await API.get(`/api/v1/metrics/get-reviews/${tradeid}`);
      return data && data?.data;
    } catch (error) {
      toast("error");
      throw error;
    }
  },

  cancelTradeByID: (data) => {
    store.dispatch({type: CANCEL_TRADE_INITIAL, payload: true});
    return API.post(`api/v1/trade/cancel-trade`, data)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: CANCEL_TRADE_ERROR, payload: error});
        throw error;
      });
  },

  releaseTokenByID: (data) => {
    store.dispatch({type: RELEASE_TOKEN_INITIAL, payload: true});
    return API.post(`api/v1/trade/release-token`, data)
      .then((data) => {
        if (data && data.data.message === "success") {
          return data.data;
        }
      })
      .catch((error) => {
        store.dispatch({type: RELEASE_TOKEN_ERROR, payload: error});
      });
  },

  disputeTradeByID: (data) => {
    store.dispatch({type: DISPUTE_TRADE_INITIAL, payload: true});
    return API.post(`api/v1/trade/dispute-trade`, data)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: DISPUTE_TRADE_ERROR, payload: error});
        throw error;
      });
  },

  rateTradeByID: (data) => {
    store.dispatch({type: RATE_TRADE_INITIAL, payload: true});
    return API.post(`/api/v1/trade/rate-trade`, data)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        store.dispatch({type: RATE_TRADE_ERROR, payload: error});
        throw error;
      });
  },

  tradePhoneAuth: () => {
    // store.dispatch({type: RATE_TRADE_INITIAL, payload: true});
    return API.post(`/api/v1/trade/secure-auth`)
      .then(({data}) => {
        return data;
      })
      .catch((error) => {
        // store.dispatch({type: RATE_TRADE_ERROR, payload: error});
        throw error;
      });
  },

  GetLastTimeSeen: (username) => {
    return API.get(`/api/v1/open/time-last-seen/${username}`)
      .then(({data}) => {
        return data;
      })
      .catch((error) => {
        throw error;
      });
  },
};
export default createTradeService;
