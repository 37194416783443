import "../../styles/walletWithdrawHIstory.scss";
import {RiEditBoxLine, RiFileCopyFill} from "react-icons/ri";
import CopyToClipboard from "react-copy-to-clipboard";
import {memo} from "react";
import {MdOutlineClose} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {getModalChecker} from "../../store/actions/walletAction";
import moment from "moment";
import {toast} from "react-toastify";

const WalletWithdrawHIstory = ({setOpen}) => {
  const dispatch = useDispatch();
  const detailsOfWithdrawal = useSelector((state) => state.walletReducer.detailsOfWithdrawal);

  return (
    <div className="history__Container">
      <div className="__modal__content__top">
        <span className="__title__text">Trade Details</span>
        <div className="__modal__content__topIconBody">
          <MdOutlineClose
            size={16}
            onClick={() => dispatch(getModalChecker({}))}
            className="__modal__content__topIcon"
          />
        </div>
      </div>
      <div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">Status</span>
          <div className="__bottonContent__value __status__complete">Complete</div>
        </div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">Date</span>
          <div className="__bottonContent__value">{moment(detailsOfWithdrawal?.timeStamp).format("LLL")}</div>
        </div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">Coin</span>
          <div className="__bottonContent__value">{detailsOfWithdrawal?.tokenSymbol}</div>
        </div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">Withdraw Amount</span>
          <div className="__bottonContent__value">{detailsOfWithdrawal?.amount}</div>
        </div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">NetWork</span>
          <div className="__bottonContent__value">{detailsOfWithdrawal?.chain}</div>
        </div>
        <div className="history__bottomContent">
          <span className="__bottonContent__key">Wallet Addresss</span>

          <CopyToClipboard
            text={`${detailsOfWithdrawal?.wallet}`}
            onCopy={() => toast("copied to clipboard", {position: "top-right"})}
          >
            <div style={{display: "flex", alignItems: "center"}}>
              <p className="__bottonContent__value">{detailsOfWithdrawal?.wallet}</p>
              <RiFileCopyFill size={14} style={{marginLeft: "10px"}} />
            </div>
          </CopyToClipboard>
        </div>

        {detailsOfWithdrawal?.status !== "awaiting" && (
          <div className="history__bottomContent">
            <span className="__bottonContent__key">Tx-ID</span>
            <a
              target="_blank"
              style={{color: "black"}}
              href={
                // detailsOfWithdrawal?.txID !== null
                //   ? `https://etherscan.io/tx/${detailsOfWithdrawal?.txID}`
                //   :
                detailsOfWithdrawal?.tokenSymbol?.toUpperCase() === "BTC"
                  ? `https://explorer.btc.com/btc/transaction/${detailsOfWithdrawal?.txID}`
                  : detailsOfWithdrawal?.tokenSymbol?.toUpperCase() === "ETH"
                  ? `https://etherscan.io/tx/${detailsOfWithdrawal?.txID}`
                  : detailsOfWithdrawal?.tokenSymbol?.toUpperCase() === "BNB"
                  ? `https://bscscan.com/tx/${detailsOfWithdrawal?.txID}`
                  : detailsOfWithdrawal?.tokenSymbol?.toUpperCase() === "USDT" &&
                    `https://tronscan.org/#/transaction/${detailsOfWithdrawal?.txID}`
              }
              rel="noreferrer"
            >
              <div style={{display: "flex", alignItems: "center"}}>
                <div className="__bottonContent__value">{detailsOfWithdrawal?.txID}</div>
                <RiEditBoxLine size={14} style={{marginLeft: "10px"}} />
              </div>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(WalletWithdrawHIstory);
