import React, {memo, useEffect, useState} from "react";
import HomeMobileTopBar from "../components/Common/HomeMobileTopBar";
import HomeTopBar from "../components/Common/HomeTopBar";
import LargeSideBar from "../components/Common/LargeSideBar";
import MarketPlace from "../components/Home/MarketPlace";
import Notification from "../components/Home/Notification";
import Overview from "../components/Home/Overview";
import Profile from "../components/Home/Profile";
import Settings from "../components/Home/Settings";
import MyOrders from "../components/Home/MyOrders";
import Wallet from "../components/Home/Wallet";
import Transaction from "../components/Home/Transaction";
import {Switch, Route} from "react-router-dom";
import CreateTrade from "../components/Home/CreateTrade";
import PostTrade from "../components/Home/PostTrade";
import GeneralService from "../services/general";
import "react-toastify/dist/ReactToastify.css";
import {useDispatch, useSelector} from "react-redux";
import {getCountry, getLocation, getPaymentMethods, getTokenDetails, getUser} from "../store/actions/general";
import EditTrade from "../components/Home/EditTrade";
import "../styles/home.scss";
import {getUseGoogleAuth, getUsePhoneNumber} from "../store/actions/settingAction";
import TradeOrders from "../components/Home/TradeOrders";
import UserTrade from "../components/Home/UserTrade";
import AllReviews from "../components/AllReviews/allReviews";
import WalletService from "../services/walletService";
import {CURRENT_VALUE_BANLANCE_STATE, PENDING_AND_DISPUTED_TRADE} from "../store/types";
import moment from "moment";
import NotFound from "../components/404";
import {Helmet} from "react-helmet";
import UserTradeService from "../services/getusertrades";
import Convert from "../components/Home/Convert";

const Home = () => {
  const userlocalBanlanceDetails = JSON.parse(localStorage.getItem("userDataBalance"));
  const universalDate = moment(userlocalBanlanceDetails?.date);
  const date = moment().add(0, "days");
  const dateTobePassed = moment();

  const token = localStorage.getItem("token");

  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);

  const getConstants = async () => {
    let constants = await Promise.all([
      GeneralService.getUserData(),
      GeneralService.geolocation(),
      GeneralService.getPaymentMethods(),
      GeneralService.getTokenServices(),
      GeneralService.getCountries(),
      WalletService.UserTotalBalanceChecker(),
    ]).then((data) => {
      return data;
    });

    constants[4]?.data?.countriesData?.unshift({name: "Worldwide", countryName: "Worldwide"});

    dispatch(getUser(constants[0] ?? {}));
    dispatch(getLocation(constants[1]));
    dispatch(getPaymentMethods(constants[2]));
    dispatch(getTokenDetails(constants[3].tokens));
    dispatch(getCountry(constants[4]));

    dispatch({type: CURRENT_VALUE_BANLANCE_STATE, payload: constants[5]?.currentUserBalances});

    if (!userlocalBanlanceDetails?.userbanlanceDetails || date?.diff(universalDate, "day") > 0) {
      if (constants[5]?.currentUserBalances >= 0) {
        localStorage.setItem(
          "userDataBalance",
          JSON.stringify({...userlocalBanlanceDetails, date: dateTobePassed, userbanlanceDetails: constants[5]})
        );
      }
    }

    dispatch(
      getUsePhoneNumber(
        constants[0]?.data?.userData?.authPreference === "sms"
          ? {name: "sms", checker: true}
          : {name: "sms", checker: false}
      )
    );

    dispatch(
      getUseGoogleAuth(
        constants[0]?.data?.userData?.authPreference === "google auth"
          ? {name: "google auth", checker: true}
          : {name: "google auth", checker: false}
      )
    );
  };

  const fetchPendingAndDisputedTrade = async () => {
    UserTradeService.PendingAndDisputedUserTradeService()
      .then((data) => {
        console.log(data, "noticesd misss");
        dispatch({type: PENDING_AND_DISPUTED_TRADE, payload: data});
      })
      .catch((err) => {
        console.log(err, "sedning data");
      });
  };

  useEffect(() => {
    if (token === undefined || token === null || token === "" || token === "undefined") {
      localStorage.clear();
      window.location = "/";
    }

    fetchPendingAndDisputedTrade();

    getConstants();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      fetchPendingAndDisputedTrade();
    }, 2000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const notificationNumChecker = useSelector((state) => state.notificationReducer.notificationNumChecker);

  return (
    <>
      <Helmet>
        {notificationNumChecker?.unReadCount > 0 ? (
          <link rel="icon" href={`${process.env.PUBLIC_URL + "/logo-red192.png"}`} type="image/x-icon" />
        ) : (
          <link rel="icon" href={`${process.env.PUBLIC_URL + "/logo192.png"}`} type="image/x-icon" />
        )}
      </Helmet>

      <div className="home-container">
        <LargeSideBar isOpen={isOpen} setIsOpen={setIsOpen} />
        <HomeMobileTopBar />

        <div className="main-content">
          <HomeTopBar isOpen={isOpen} />

          <div className="pages">
            <div className="contents">
              <Switch>
                {/* <Route exact path="/">
                  <Redirect to="/app" />
                </Route> */}
                <Route exact path="/app/overview">
                  <Overview />
                </Route>
                <Route path="/app/trade-orders">
                  <TradeOrders />
                </Route>
                <Route path="/app/user-trades">
                  <UserTrade />
                </Route>
                <Route path="/app/marketplace/:country?/:token?/:type?/:paymentMethod?">
                  <MarketPlace />
                </Route>
                <Route path="/app/wallet">
                  <Wallet />
                </Route>
                <Route path="/app/all-reviews/:username?">
                  <AllReviews />
                </Route>
                <Route path="/app/my-orders">
                  <MyOrders />
                </Route>
                <Route path="/app/notification">
                  <Notification />
                </Route>
                <Route path="/app/transaction">
                  <Transaction />
                </Route>
                <Route path="/app/profile/:username">
                  <Profile />
                </Route>
                <Route path="/app/settings">
                  <Settings />
                </Route>
                <Route path="/app/trade/:id?">
                  <CreateTrade />
                </Route>
                <Route path="/app/post-trade/:transactionType/:coinType/:countryName">
                  <PostTrade />
                </Route>
                <Route path="/app/edit-trade/:tradeId">
                  <EditTrade />
                </Route>
                <Route path="/app/sell-crypto">
                  <Convert />
                </Route>

                <Route path={"*"} component={NotFound} />
              </Switch>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(Home);
