import {
  GET_SELECTED_COIN,
  GET_SELECTED_PAYMENT,
  GET_SELECTED_COUNTRY,
  GET_SELECTED_GEO_LOCATION,
  MARKET_DATA,
  LANDING_LOADER,
} from "../types";

const initialState = {
  getSelectedCoinData: {},
  getSelectedPaymentData: [],
  getSelectedCountryData: [],
  getSelecteGeoLocationData: {},
  marketData: [],
  landingLoader: true,
};

const LandingReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case MARKET_DATA: {
      return {
        ...state,
        marketData: payload,
      };
    }

    case GET_SELECTED_COIN: {
      return {
        ...state,
        getSelectedCoinData: payload,
      };
    }

    case GET_SELECTED_PAYMENT: {
      return {
        ...state,
        getSelectedPaymentData: payload,
      };
    }

    case GET_SELECTED_COUNTRY: {
      return {
        ...state,
        getSelectedCountryData: payload,
      };
    }
    case GET_SELECTED_GEO_LOCATION: {
      return {
        ...state,
        getSelecteGeoLocationData: payload,
      };
    }

    case LANDING_LOADER: {
      return {
        ...state,
        landingLoader: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default LandingReducer;
