import React, {memo, useCallback, useEffect} from "react";
import {createContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getMarketPlaceBuyAndSellAction} from "../../store/actions/market";
import {CHANGE_MARKET_PLACE_STATE, GET_MARKET_PLACE_BUY_SELL, GET_MARKET_PLACE_INITIAL} from "../../store/types";
import "../../styles/marketPlace.scss";
import {MarketTable} from "../MarketPlace/marketTable";
import {MarketPlaceTopArea} from "../MarketPlace/topArea";

export const MarketPlaceContext = createContext();
const MarketPlace = () => {
  const dispatch = useDispatch();
  const params = useParams();

  const {limit, skip, fskip} = useSelector((state) => state.marketReducer);

  const fectchData = useCallback(() => {
    dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});

    dispatch({
      type: CHANGE_MARKET_PLACE_STATE,
      payload: {
        ...params,
        country:
          params?.country === undefined || params?.country === null || params?.country === ""
            ? "Worldwide"
            : params?.country,
        paymentMethod:
          params?.paymentMethod === "null" || params?.paymentMethod === undefined || params?.paymentMethod === ""
            ? null
            : params?.paymentMethod,
      },
    });

    dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});

    dispatch(
      getMarketPlaceBuyAndSellAction({
        ...params,
        paymentMethod:
          params?.paymentMethod === "null" || params?.paymentMethod === undefined || params?.paymentMethod === ""
            ? null
            : params?.paymentMethod,
        skip: fskip,
        limit: limit,
      })
    );
  }, [dispatch, params?.country, params?.paymentMethod, params?.token, params?.type, params?.amount]);

  useEffect(() => {
    fectchData();
  }, [fectchData]);

  return (
    <div style={{paddingBottom: "1px", margin: "0 auto", width: "100%"}}>
      <MarketPlaceTopArea />
      <MarketTable />
    </div>
  );
};

export default memo(MarketPlace);
