//

import APIs from "./api";

const BalanceService = {
  localBalance: async () => {
    return APIs.get("/api/v1/profile/get-user-token-balance").then((data) => {
      return data.data;
    });
  },
};

export default BalanceService;
