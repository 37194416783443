import React from "react";
import {BiTimeFive} from "react-icons/bi";
// import {TbCurrencyNaira} from "react-icons/tb";
import "./style.css";

const CareerFeatures = ({careerFeaturesArray, header, details, border}) => {
  return (
    <div className="career" style={{borderBottom: `1px solid var(${border})`}}>
      <div className="careerFeature__firstLayoutBody">
        <h4>{header}</h4>
        <p>{details}</p>
      </div>
      <div className="careerFeature__secondLayoutBody">
        {careerFeaturesArray?.map((item, key) => (
          <div key={key} className="careerFeature__secondLayout">
            <div className="careerFeature__secondLayoutUpLayout">
              <div className="careerFeature__secondLayoutUpLayoutHeader">
                <h5>{item.cardTitle}</h5>
                <p>{item.cardDetails}</p>
              </div>
              <div className="careerFeature__secondLayoutUpLayoutButton">
                <button>Apply</button>
              </div>
            </div>
            <div className="careerFeature__secondLayoutLowerLayout">
              <div className="careerFeature__secondLayoutUpLayoutFeatures">
                <div className="careerFeature__secondLayoutUpLayoutFeaturesTime">
                  <div className="careerFeature__secondLayoutUpLayoutFeaturesTimeIconbody">
                    <BiTimeFive className="careerFeature__secondLayoutUpLayoutFeaturesTimeIcon" />
                  </div>
                  <div className="careerFeature__secondLayoutUpLayoutFeaturesTimeText">
                    <p>{item.time}</p>
                  </div>
                </div>
                <div className="careerFeature__secondLayoutUpLayoutFeaturesMoney">
                  {/* <div className="careerFeature__secondLayoutUpLayoutFeaturesMoneyIconbody">
                    <TbCurrencyNaira className="careerFeature__secondLayoutUpLayoutFeaturesMoneyIcon" />
                  </div> */}
                  <div className="careerFeature__secondLayoutUpLayoutFeaturesMoneyText">
                    {/* <p>{`${item.firstRange} - ${item.secondRange}`}</p> */}
                  </div>
                </div>
              </div>
              <div className="careerFeature__secondLayoutLowerLayoutResidency">
                <p>{`${item.state}, ${item.country}`}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export {CareerFeatures};
