import React, {memo} from "react";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import Success from "../../assets/images/success-oyola.gif";
import GeneralService from "../../services/general";
import WalletService from "../../services/walletService";
import {getTokenDetails, getUser} from "../../store/actions/general";
import {getUserCoinLocalValue} from "../../store/actions/overviewAction";
import {getModalChecker, getWalletBalance} from "../../store/actions/walletAction";
import "../../styles/walletStyles/confirmationModal.scss";

const Confirmation = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const getConstants = async (walletchecker) => {
    let constants = await Promise.all([
      GeneralService.getUserData(),
      GeneralService.getTokenServices(),
      WalletService.UserTotalBalance(),
    ]).then((data) => {
      return data;
    });

    const WalletServices = async () => {
      const [userData, tokens] = constants;

      const tokenData = [...tokens.tokens];

      for (let item of tokenData) {
        userData.data.userData.tokens.forEach((data) => {
          if (item?.tokenSymbol?.toLowerCase() === data?.tokenSymbol?.toLowerCase()) {
            item["tokenBalance"] = data.balance;
          }
        });

        for (let x in constants[0].data.userData) {
          if (x === "walletAddressBTC" && item?.tokenSymbol?.toLowerCase() === "btc") {
            item["tokenId"] = constants[0].data.userData[x];
          }

          if (
            x === "walletAddressETH" &&
            (item?.tokenSymbol?.toLowerCase() === "eth" || item?.tokenSymbol?.toLowerCase() === "bnb")
          ) {
            item["tokenId"] = constants[0].data.userData[x];
          }

          if (x === "walletAddressTRON" && item?.tokenSymbol?.toLowerCase() === "usdt") {
            item["tokenId"] = constants[0].data.userData[x];
          }
        }

        for (let x in constants[2]?.tokensValueData[0]) {
          if (item.tokenSymbol?.toUpperCase() === x.slice(0, -5)?.toUpperCase()) {
            item["quantityInBTC"] = constants[2]?.tokensValueData[0][x] / constants[2]?.unitBTC;
            item["quantityInLocalCurrency"] = constants[2]?.tokensValueData[0][x];
          }
        }

        const unitSystem = await WalletService.GetLocalCurrencyValue(
          item.tokenSymbol,
          constants[0]?.data?.userData?.defaultCurrency.currency
        );

        const index = tokenData.findIndex((data) => item.tokenSymbol === data.tokenSymbol);

        tokenData[index] = {...tokenData[index], unitPrice: unitSystem.currencyValue};
      }

      return tokenData;
    };

    const handle = await WalletServices();

    const coinValueArray = [];
    for (let x in constants[2]?.tokensValueData[0]) {
      coinValueArray.push({value: constants[2]?.tokensValueData[0][x], coin: x.slice(0, -5)?.toUpperCase()});
    }

    const filteredCoinValue = coinValueArray.filter((item) => item.value !== null ?? item.value !== 0);

    if (filteredCoinValue.length !== 0) {
      dispatch(getUserCoinLocalValue({checker: 1, value: coinValueArray}));
    } else {
      dispatch(getUserCoinLocalValue({checker: 0, value: coinValueArray}));
    }

    dispatch(getUser(constants[0]));
    dispatch(getTokenDetails(handle));
    dispatch(getWalletBalance(constants[2]));

    if (walletchecker === "walletBack") {
      dispatch(getModalChecker({}));
    }

    if (walletchecker === "toHistory") {
      history.push("/app/transaction");
      dispatch(getModalChecker({num: 4}));
    }
  };

  const HandleViewWallet = () => {
    dispatch(getModalChecker({num: 0}));
    getConstants("walletBack");
  };

  const HandleViewWalletHistory = () => {
    dispatch(getModalChecker({num: 0}));
    getConstants("toHistory");
  };

  return (
    <div className="comfirmationWrapper">
      <div className="comfirmation__firstLayout">
        <div className="comfirmation__firstLayoutImageBody">
          <img src={Success} alt={"success"} className="comfirmation__firstLayoutImage" />
        </div>
      </div>
      <div className="comfirmation__secondLayout">
        <h3>Withdrawal Request Submitted</h3>
        <p>
          Your request for withdrawal has been confirmed. You can check the progress of transaction on the withdrawal
          history page
        </p>
      </div>
      <div className="comfirmation__thirdLayout">
        <button onClick={HandleViewWallet} className="comfirmation__thirdLayoutWallet">
          View wallet
        </button>
        <button onClick={HandleViewWalletHistory} className="comfirmation__thirdLayoutHistory">
          View history
        </button>
      </div>
    </div>
  );
};

export default memo(Confirmation);
