import {MenuItem, MenuList} from "@mui/material";
import React, {forwardRef, useState} from "react";
import {BsCheckLg} from "react-icons/bs";

const MarketSearchableDropDownItem = forwardRef(
  (
    {
      open,
      closeMenu,
      List,
      HandleSelect,
      handleClose,
      DetailsIcons,
      name,
      nameAcr,
      iconsLead,
      search,
      query,
      searchDetails,
    },
    ref
  ) => {
    return (
      <>
        {/* <div style={{width: "100%"}}> */}
        {open && (
          <div ref={ref}>
            <div id="basic-menu" className="dropdownItem" onClick={closeMenu}>
              {query !== "" && query !== null
                ? searchDetails?.map((item, index) => (
                    <div
                      className="ItemAlign"
                      key={index}
                      onClick={() => {
                        HandleSelect(item);
                      }}
                    >
                      <div className="textContainer">
                        {iconsLead && (
                          <div className="iconWrapper">
                            <DetailsIcons intialValue={item[name]} />
                          </div>
                        )}
                        <p className={"text"}>{item[name]}</p>
                      </div>
                      {item[name] === search.name && (
                        <div className="dropDownIconBody">
                          <BsCheckLg className="dropDownIcon" />
                        </div>
                      )}
                    </div>
                  ))
                : List?.length !== 0
                ? List?.map((item, index) => {
                    return (
                      <div
                        className="ItemAlign"
                        key={index}
                        onClick={() => {
                          HandleSelect(item);
                        }}
                      >
                        <div className="textContainer">
                          {iconsLead && (
                            <div className="iconWrapper">
                              <DetailsIcons intialValue={item[name]} />
                            </div>
                          )}
                          <p className={"text"}>{item[name]}</p>
                        </div>
                        {item[name] === search.name && (
                          <div className="dropDownIconBody">
                            <BsCheckLg className="dropDownIcon" />
                          </div>
                        )}
                      </div>
                    );
                  })
                : List?.length === 0 && (
                    <div
                      className="ItemAlign"
                      onClick={() => {
                        handleClose("BTC");
                      }}
                    >
                      <p className="text">No available coin</p>
                    </div>
                  )}
            </div>
          </div>
        )}
        {/* </div> */}
      </>
    );
  }
);

export default MarketSearchableDropDownItem;
