import {memo, useEffect, useState} from "react";

import {useDispatch, useSelector} from "react-redux";
import {useCallback} from "react";
import {getAllTradesActive} from "../../store/actions/trade";
import {Skeleton} from "@mui/material";
import "../../styles/profile.scss";
import {ProfileService} from "../../services/profileService";
import AllTradeOrdersDesktopTable from "./DesktopTable";
import useWindowDimensions from "../../hooks/useWindowDimension";
import AllTradeMobileTable from "./MobileTable";
import {toast} from "react-toastify";

const Orders = ({username}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {tradeActive} = useSelector((state) => state.tradeReducer);

  const {width} = useWindowDimensions();

  const fetchTradeOrderData = () => {
    setLoading(true);
    ProfileService.miniMarketplace(username)
      .then((data) => {
        if (data.data.message === "success") {
          dispatch(getAllTradesActive(data?.data?.marketTrades));
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        // toast("Something went wrong");
      });
  };

  useEffect(() => {
    if (tradeActive.length === 0) {
      fetchTradeOrderData();
    }
  }, [username]);

  return (
    <div className="personal-info-container">
      <p className="textStyle">Orders</p>

      <div className="card-container">
        <div className="card-inner-container">
          <p className="text">@{username}'s Open Orders</p>
          {loading ? (
            <Skeleton animation="wave" variant="rectangular" width={"100%"} height={60} />
          ) : width <= 700 ? (
            <AllTradeMobileTable /> //  <AllTradeMobileTable />
          ) : (
            <AllTradeOrdersDesktopTable />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(Orders);
