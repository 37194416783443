import React, {forwardRef, memo} from "react";
import {BsCheckLg} from "react-icons/bs";

const DropdownItem = forwardRef(
  ({showDropDown, list, select, HandleClickCoin, HandleShowDropDownReturn, iconLead, CoinIcons}, ref) => {
    return (
      <>
        {showDropDown && (
          <div className="dropDownBody" ref={ref}>
            {list?.map((item, key) => (
              <div
                key={key}
                className={`dropDownContainer ${select === item.title && "containerColor"}`}
                onClick={() => {
                  HandleClickCoin(item);
                  HandleShowDropDownReturn();
                }}
              >
                <div className="dropDownHeader">
                  {iconLead && (
                    <div className="dropDownHeaderIconBody">
                      <CoinIcons intialValue={item.title} />
                    </div>
                  )}
                  <p>{item.title}</p>
                </div>
                {item.title === select && (
                  <div className="dropDownIconBody">
                    <BsCheckLg className="dropDownIcon" />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </>
    );
  }
);

export default memo(DropdownItem);
