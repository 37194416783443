import APIs from "./api";

const ConvertServices = {
  /**
   *  Get conversion rates
   *
   * This endpoint returns the rates, amount in NGN, minAmount and maxAmount convertable
   * @param {*} amount
   * @param {*} tokenSymbol
   * @returns
   */
  getConversionRates: async (amount, tokenSymbol) => {
    return APIs.get(`api/v1/banking/convert?amount=${amount}&tokenSymbol=${tokenSymbol}`)
      .then((data) => {
        if (data?.data?.message === "success") {
          return data?.data?.data;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  /**
   *
   * Get Bank list
   *
   *
   * Call endpoint to get list of Nigerian banks.
   *
   * Take note of the "code" property, this will be sent for transfer as "account_bank"
   * @returns
   */
  getBankList: async () => {
    return APIs.get(`/api/v1/banking/get-banks-list`)
      .then((data) => {
        if (data?.data?.message === "success") {
          return data?.data?.banks;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  /**
   *
   * Validate Bank Details
   *
   *
   * Add a trader buy / sell order to their profile page.
   * This endpoint validates the bank details
   *
   *
   * You can pass payload to the function
   *
   * @param {object} payload {
   *  @property {string} account_bank
   *
   *  @property {string} account_number
   * }
   *
   */
  validateBankDetails: async (payload) => {
    return APIs.post(`/api/v1/banking/validate`, payload)
      .then((data) => {
        if (data?.data?.message === "success") {
          return data.data;
        }
      })
      .catch((err) => {
        throw err;
      });
  },

  /**
   *
   * Bank Transfer
   *
   * Call this endpoint to finalize convert process.
   *
   * @param {*} payload
   *
   * You can declare these below to the payload object when passing the payload
   * @property {string} account_bank
   * @property {string} account_number
   * @property {number} amount
   * @property {string} tokenSymbol
   */
  bankTransfer: async (payload) => {
    return APIs.post(`/api/v1/banking/transfer`, payload)
      .then((data) => {
        return data;
      })
      .catch((err) => {
        throw err;
      });
  },
};

export default ConvertServices;
