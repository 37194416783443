import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccordionTrade from "./AccordionTrade";
import "../../styles/Notification/notificationStyle.scss";
import NotificationService from "../../services/notification";
import {TRADEPAGES, TRADESKIP} from "../../store/types";
import {getTradeNotification} from "../../store/actions/notification";
import {NotificationPaginationComponent} from "../Common/notificationPagination";

const NotificationTrades = (props) => {
  const dispatch = useDispatch();
  const notificationDataRedux = useSelector((state) => state.notificationReducer.tradeNotification);
  const {tradeSkip, notificationLimit, tradePages} = useSelector((state) => state.notificationReducer);
  const notyData = notificationDataRedux ?? [];

  const IncreamentPage = () => {
    const skip = (tradeSkip + 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "trade").then((data) => {
      if (data?.data?.notifications.length > 0) {
        dispatch({type: TRADEPAGES, payload: tradePages + 1});
        dispatch(getTradeNotification(data?.data?.notifications));
        dispatch({type: TRADESKIP, payload: tradeSkip + 1});
      }
    });
  };

  const DecreamentPage = () => {
    const skip = (tradeSkip - 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "trade").then((data) => {
      if (data?.data?.notifications.length > 0 && tradePages > 1) {
        dispatch({type: TRADEPAGES, payload: tradePages - 1});
        dispatch(getTradeNotification(data?.data?.notifications));
        dispatch({type: TRADESKIP, payload: tradeSkip - 1});
      }
    });
  };

  const filterUnReadMessages = notyData.length > 0 ? notyData.filter((item) => item.unRead === true) : [];

  return (
    <React.Fragment>
      <div className="ReadUnReadWrapper">
        <p className="titleWrapper">Unread messages</p>
        <p className="lengthWrapper">{filterUnReadMessages.length}</p>
      </div>
      <div className="messageWrapper">
        <AccordionTrade filterTrade={notyData.length > 0 ? notyData : []} />

        {notificationDataRedux.length > 0 && (
          <NotificationPaginationComponent onNext={IncreamentPage} onPrev={DecreamentPage} skip={tradePages} />
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(NotificationTrades);
