import React, {memo} from "react";
import CurrencyFormat from "react-currency-format";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import AuthService from "../../services/auth";
import WalletService from "../../services/walletService";
import {
  getCoinNetworkSelection,
  getModalChecker,
  getWalletModalWithdrawal,
  getWithdrawMainDetail,
} from "../../store/actions/walletAction";
import {TOKENNETWORKFEE, WALLETADDRESSCHECKER, WALLETADDRESSERROR, WITHDRAWALERROR} from "../../store/types";
import "../../styles/walletStyles/crossCheckPage.scss";

const CrossCheckPage = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);
  const withDrawalDetails = useSelector((state) => state.walletReducer.withDrawalDetails);
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);

  const ReturnBack = () => {
    dispatch(getModalChecker({}));
    dispatch(getWalletModalWithdrawal({name: "multiple", checker: true}));
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    dispatch(getModalChecker({num: 0}));

    if (userData?.data?.userData?.isVerified === false) {
      dispatch(getModalChecker({num: 1}));

      dispatch(getWithdrawMainDetail({}));
      dispatch(getCoinNetworkSelection({}));
      dispatch({type: WALLETADDRESSCHECKER, payload: false});
      dispatch({type: WITHDRAWALERROR, payload: ""});
      dispatch({type: WALLETADDRESSERROR, payload: ""});
      dispatch({type: TOKENNETWORKFEE, payload: {}});
      return;
    }

    if (userData?.data?.userData?.authPreference === "google auth") {
      dispatch(getModalChecker({num: 2}));
    } else if (userData?.data?.userData?.authPreference === "sms") {
      WalletService.SendOtp().then((data) => {
        dispatch(getModalChecker({num: 2}));
      });
    } else {
      AuthService.verifyEmailSecurely({email: userData?.data?.userData?.email, isLogin: false}).then((data) => {
        dispatch(getModalChecker({num: 2}));
      });
    }

    dispatch(getWithdrawMainDetail({}));
    dispatch(getCoinNetworkSelection({}));
    dispatch({type: WALLETADDRESSCHECKER, payload: false});
    dispatch({type: WALLETADDRESSERROR, payload: ""});
    dispatch({type: TOKENNETWORKFEE, payload: {}});
  };

  return (
    <div className="crossCheckWrapper">
      <div className="crossCheckWrapper__header">
        <h3>Withdrawal Confirmation</h3>
      </div>
      <div className="crossCheckWrapper__amountDetails">
        <p className="crossCheckWrapper__amountDetailsHeader"> Receive amount</p>
        <p className="crossCheckWrapper__amountDetailsDetails">
          <CurrencyFormat
            value={`${Object.keys(withDrawalDetails).length > 0 ? withDrawalDetails.amount : 0}`}
            displayType={"text"}
            decimalScale={6}
            fixedDecimalScale
            // prefix={"$"}
            suffix={` ${withDrawalDetails?.tokenSymbol?.toUpperCase()}`}
          />
        </p>
      </div>
      <div className="crossCheckWrapper__addressDetails">
        <div className="crossCheckWrapper__addressDetailsfirstLayout">
          <p className="crossCheckWrapper__addressDetailsfirstLayoutHeader">Address</p>
          <p className="crossCheckWrapper__addressDetailsfirstLayoutDetails">{withDrawalDetails?.to}</p>
        </div>
        <div className="crossCheckWrapper__addressDetailsSecondLayout">
          <p className="crossCheckWrapper__addressDetailsSecondLayoutHeader">Network</p>
          <p className="crossCheckWrapper__addressDetailsSecondLayoutDetails">{withDrawalDetails?.cryptoNetwork}</p>
        </div>
      </div>
      <div className="crossCheckWrapper__coinDetails">
        <div className="crossCheckWrapper__coinDetailsFirstLayout">
          <p className="crossCheckWrapper__coinDetailsFirstLayoutHeader">Coin Type</p>
          <p className="crossCheckWrapper__coinDetailsFirstLayoutDetails">
            {withDrawalDetails?.tokenSymbol?.toUpperCase()}
          </p>
        </div>

        <div className="crossCheckWrapper__coinDetailsThirdLayout">
          <p className="crossCheckWrapper__coinDetailsThirdLayoutHeader">Network fee</p>
          <p className="crossCheckWrapper__coinDetailsThirdLayoutDetails">
            <span style={{color: "#03a66d"}}>+</span>
            <CurrencyFormat
              value={`${Object.keys(withDrawalDetails).length > 0 ? withDrawalDetails.network : 0}`}
              displayType={"text"}
              decimalScale={6}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${withDrawalDetails?.tokenSymbol?.toUpperCase()}`}
            />
          </p>
        </div>

        <div className="crossCheckWrapper__coinDetailsSecondLayout">
          <p className="crossCheckWrapper__coinDetailsSecondLayoutHeader">Total Amount In Local Currency</p>
          <p className="crossCheckWrapper__coinDetailsSecondLayoutDetails">
            <CurrencyFormat
              value={`${Object.keys(withDrawalDetails).length > 0 ? withDrawalDetails.amountInLocalCurrency : 0}`}
              displayType={"text"}
              decimalScale={6}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
            />
          </p>
        </div>

        <div className="crossCheckWrapper__coinDetailsSecondLayout">
          <p className="crossCheckWrapper__coinDetailsSecondLayoutHeader">Total Debit</p>
          <p className="crossCheckWrapper__coinDetailsSecondLayoutDetails">
            <CurrencyFormat
              value={`${Object.keys(withDrawalDetails).length > 0 ? withDrawalDetails.total : 0}`}
              displayType={"text"}
              decimalScale={6}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${withDrawalDetails?.tokenSymbol?.toUpperCase()}`}
            />
          </p>
        </div>
      </div>
      <div className="crossCheckWrapper__withdrawalDetials">
        <p>Make sure that the address is correct and on the same network. Transactions cannot be Reversed.</p>
      </div>
      <div className="crossCheckWrapper__buttons">
        <button onClick={ReturnBack} className="crossCheckWrapper__cancelButtons">
          Cancel
        </button>
        <button onClick={HandleSubmit} className="crossCheckWrapper__confirmButtons">
          Confirm
        </button>
      </div>
    </div>
  );
};

export default memo(CrossCheckPage);
