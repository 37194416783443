import {useState, useEffect, useContext} from "react";
import {BiRefresh} from "react-icons/bi";
import {FiSearch} from "react-icons/fi";
import {BNB, BTC, ETH, USDT} from "../../../../utils/imagePath";
import {TransactionStaticDropDown} from "../../transactionStaticDropdown";
import "../../../../styles/landing-page-styles/marketplace.scss";
import useWindowDimensions from "../../../../utils/useWindowDimension";
import {CountryIcons} from "../../CountryIcons";

import SearchableDropDown from "../../SearchableDropDown/SearchableDropDown";
import {MarketPlaceContext} from "../../../../screens/marketplace";
import CurrencyFormat from "react-currency-format";
import {useHistory, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {IconDropDown} from "../../IconDropdown";
import {LANDING_LOADER, MARKET_DATA} from "../../../../store/types";
import LandingServices from "../../../../services/landingServices";

const TheWholeFilterArea = () => {
  const {getSelectedPaymentData, getSelectedCountryData, getSelecteGeoLocationData} = useSelector(
    (state) => state.LandingReducer
  );

  const dispatch = useDispatch();
  const params = useParams();
  const history = useHistory();
  const {width} = useWindowDimensions();
  const [token, setToken] = useState(params?.token);
  const [thePaymentMethod, setThePaymentMethod] = useState(
    params?.paymentMethod === "null" ? null : params?.paymentMethod
  );
  const [amount, setAmount] = useState(params?.amount?.split(",").join(""));
  const {toggleSwitch, setToggleSwitch, countryStateValue, setCountryStateValue} = useContext(MarketPlaceContext);

  useEffect(() => {
    setCountryStateValue({
      ...getSelecteGeoLocationData,
      name: getSelecteGeoLocationData?.countryName,
      nameAcr: getSelecteGeoLocationData?.alpha3,
    });
  }, [getSelecteGeoLocationData]);

  const handleBuyButton = () => {
    if (width < 700) {
      setToggleSwitch("buy");
      fetchData({
        country: countryStateValue?.countryName,
        type: "buy",
        token: token,
        paymentMethod: thePaymentMethod,
        amount: amount,
        skip: 0,
        limit: 10,
      });
    } else {
      setToggleSwitch("buy");

      fetchData({
        country: countryStateValue?.countryName,
        type: "buy",
        token: token,
        paymentMethod: thePaymentMethod,
        amount: amount,
        skip: 0,
        limit: 10,
      });
    }
  };

  const handleSellButton = () => {
    if (width < 700) {
      setToggleSwitch("sell");
      fetchData({
        country: countryStateValue?.name,
        type: "sell",
        token: token,
        paymentMethod: thePaymentMethod,
        amount: amount,
        skip: 0,
        limit: 10,
      });
    } else {
      setToggleSwitch("sell");

      fetchData({
        country: countryStateValue?.name,
        type: "sell",
        token: token,
        paymentMethod: thePaymentMethod,
        amount: amount,
        skip: 0,
        limit: 10,
      });
    }
  };

  const handleRefreshButton = () => {
    fetchData({
      country: countryStateValue?.name,
      type: toggleSwitch,
      token: token,
      paymentMethod: thePaymentMethod,
      amount: amount,
      skip: 0,
      limit: 10,
    });
  };

  const fetchData = async (queryData) => {
    if (Object.keys(queryData).length > 0) {
      dispatch({type: LANDING_LOADER, payload: true});

      await LandingServices.getMarketPlaceBuyAndSell({
        ...queryData,
        skip: 0,
        limit: 10,
      })
        .then((data) => {
          dispatch({type: MARKET_DATA, payload: data?.marketTrades});
          dispatch({type: LANDING_LOADER, payload: false});
        })
        .catch((err) => {
          dispatch({type: LANDING_LOADER, payload: false});
          throw err;
        });
    }
  };

  return (
    <>
      <div className="__marketPlaceSwitchWrapper">
        <div className="__theSwitch">
          <div
            className={`__marketBuy ${toggleSwitch === "buy" && "buy"}`}
            onClick={() => {
              handleBuyButton();
              // history.push(`/marketplace/Nigeria/${token}/buy/${thePaymentMethod}/${amount}`);
            }}
          >
            Buy
          </div>
          <div
            className={`__marketSell ${toggleSwitch === "sell" && "sell"}`}
            onClick={() => {
              handleSellButton();
              // history.push(`/marketplace/Nigeria/${token}/sell/${thePaymentMethod}/${amount}`);
            }}
          >
            Sell
          </div>
        </div>

        {width <= 700 && (
          <div
            className="__mobile__filterSpace"
            onClick={() => {
              fetchData({
                country: countryStateValue?.countryName,
                type: toggleSwitch,
                token: token,
                paymentMethod: thePaymentMethod,
                skip: 0,
                limit: 10,
              });
            }}
          >
            <div className="__refresh" style={{display: "flex", alignItems: "center"}}>
              <span style={{fontSize: "16px"}}>Refresh</span> <BiRefresh size={24} />
            </div>
            {/* <div className={styles.__filter}>
              <FiFilter size={22} onClick={() => setMobileFilterModal(true)} />
            </div> */}
          </div>
        )}
      </div>

      {width >= 700 ? (
        <div className="__filterAreaWrapper">
          <div className="__selectcoinwrapper">
            <p className="__label">Coin Type</p>
            <IconDropDown
              options={[
                {
                  attr: "token",
                  value: "BTC",
                  label: (
                    <span style={{display: "flex", alignItems: "center"}}>
                      <img src={BTC} width="25" alt="btc" style={{marginRight: "8px"}} />
                      <p>BTC</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "ETH",
                  label: (
                    <span style={{display: "flex", alignItems: "center"}}>
                      <img src={ETH} width="25" alt="btc" style={{marginRight: "8px"}} />
                      <p>ETH</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "USDT",
                  label: (
                    <span style={{display: "flex", alignItems: "center"}}>
                      <img src={USDT} width="25" alt="btc" style={{marginRight: "8px"}} />
                      <p>USDT</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "BNB",
                  label: (
                    <span style={{display: "flex", alignItems: "center"}}>
                      <img src={BNB} width="25" alt="btc" style={{marginRight: "8px"}} />
                      <p>BNB</p>
                    </span>
                  ),
                },
              ]}
              initialValue={{
                attr: "token",
                value: token,
                label: (
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img
                      src={
                        token === "BTC" ? BTC : token === "ETH" ? ETH : token === "USDT" ? USDT : token === "BNB" && BNB
                      }
                      width="25"
                      alt="btc"
                      style={{marginRight: "8px"}}
                    />
                    <p>{token}</p>
                  </span>
                ),
              }}
              dropdownOnChangeFunction={(item) => {
                setToken(item.token);
              }}
              name={"Coin"}
              useLabel={true}
              style={{margin: 0, width: "100%"}}
            />
          </div>
          <div className="__selectableWrapper">
            <p className="__label">Amount</p>
            <div className="___theamountandcurrency">
              <CurrencyFormat
                value={amount ?? ""}
                decimalScale={2}
                fixedDecimalScale
                className="__selectable__input"
                placeholder="20000"
                onChange={(e) => {
                  if (e.target.value) {
                    setAmount(e.target.value);
                  } else {
                    setAmount(null);
                  }
                }}
              />
              <div className="flex" style={{display: "flex", alignItems: "center"}}>
                <p style={{fontSize: "1.4rem"}}>{countryStateValue?.currencyCode}</p>
              </div>
            </div>
          </div>

          <div className="__selectableWrapper" style={{width: "235px"}}>
            <p className="__label">Payment Method</p>

            <TransactionStaticDropDown
              options={getSelectedPaymentData?.paymentMethod}
              initialValue={{
                attr: "paymentMethod",
                value: thePaymentMethod === null ? "select method" : thePaymentMethod,
                name: thePaymentMethod === null ? "select method" : thePaymentMethod,
              }}
              dropdownOnChangeFunction={(item) => {
                setThePaymentMethod(item.paymentMethod);
              }}
              name={"paymentMethod"}
              useLabel={true}
              style={{margin: 0}}
            />
          </div>
          <div className="__selectableWrapper" style={{width: "205px"}}>
            <p className="__label">Select Country</p>
            <div>
              <SearchableDropDown
                name={"countryName"}
                iconsLead={true}
                nameAcr={"alpha3"}
                DetailsIcons={CountryIcons}
                search={countryStateValue && countryStateValue}
                setSearch={setCountryStateValue}
                placeholder={"Please Search for a country"}
                HandleSelect={(val) => {
                  setCountryStateValue({
                    ...val,
                    name: val.countryName,
                    nameAcr: val.alpha3,
                    currencyCode: val.currencyCode,
                  });
                }}
                apiList={getSelectedCountryData}
                style={{
                  margin: 0,
                  borderRadius: "20px",
                  border: "1px solid #eff0f6",
                  boxShadow: "none",
                  padding: "0 15px",
                  width: "100%",
                }}
              />
            </div>
          </div>

          <div className="__updateButtonWrapper">
            <div className="__marketFilterUpdateButton" onClick={() => handleRefreshButton()}>
              <FiSearch />
              <span>Update</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="__selectcoinwrapper" style={{marginTop: "30px", padding: "0 10px"}}>
          <IconDropDown
            options={[
              {
                attr: "token",
                value: "BTC",
                label: (
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img src={BTC} width="25" alt="btc" style={{marginRight: "10px"}} />
                    <p>BTC</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "ETH",
                label: (
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img src={ETH} width="25" alt="btc" style={{marginRight: "10px"}} />
                    <p>ETH</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "USDT",
                label: (
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img src={USDT} width="25" alt="btc" style={{marginRight: "10px"}} />
                    <p>USDT</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "BNB",
                label: (
                  <span style={{display: "flex", alignItems: "center"}}>
                    <img src={BNB} width="25" alt="btc" style={{marginRight: "10px"}} />
                    <p>BNB</p>
                  </span>
                ),
              },
            ]}
            // initialValue={{attr: "token", value: "BTC", label: "BTC"}}

            initialValue={{
              attr: "token",
              value: token,
              label: (
                <span style={{display: "flex", alignItems: "center"}}>
                  <img
                    src={
                      token === "BTC" ? BTC : token === "ETH" ? ETH : token === "USDT" ? USDT : token === "BNB" && BNB
                    }
                    width="25"
                    alt="btc"
                    style={{marginRight: "8px"}}
                  />
                  <p>{token}</p>
                </span>
              ),
            }}
            dropdownOnChangeFunction={(item) => {
              setToken(item.token);

              fetchData({
                country: countryStateValue?.countryName,
                type: toggleSwitch,
                token: item.token,
                paymentMethod: thePaymentMethod,
                skip: 0,
                limit: 10,
              });
            }}
            name={"Coin"}
            style={{margin: 0}}
          />
        </div>
      )}
      {/* <WhiteMobileModal2 open={mobileFilterModal} setOpen={setMobileFilterModal}>
        <MobileFilterModal setOpen={setMobileFilterModal} />
      </WhiteMobileModal2> */}

      {/* <BottomSheet
        ref={sheetRef}
        open={mobileFilterModal}
        onDismiss={() => setMobileFilterModal(false)}
        // defaultSnap={({maxHeight}) => maxHeight * 0.6}
        // snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
        header={
          <h3
            style={{
              textAlign: "center",
              fontSize: "23px",
              fontWeight: 600,
              padding: "10px 0 10px 0",
            }}
          >
            Filter
          </h3>
        }
        footer={
          <div className={styles.__button__wrapper}>
            <button className={styles.__cancel__button} onClick={() => setMobileFilterModal(false)}>
              Cancel
            </button>

            <button
              className={styles.__buy__buttonn}
              onClick={() => {
                // onUpdate();
                // setMobileFilterModal(false);
              }}
            >
              Confirm
            </button>
          </div>
        }
      >
        <MobileFilterModal
          countryStateValue={countryStateValue}
          setCountryStateValue={setCountryStateValue}
          amount={amount}
          setAmount={setAmount}
          setThePaymentMethod={setThePaymentMethod}
        />
      </BottomSheet> */}
    </>
  );
};
export {TheWholeFilterArea};
