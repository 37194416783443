import API from "./api";
import store from "../store";
import {CHECK_OUT_DATA_INITIAL, GET_MARKET_PLACE_INITIAL} from "../store/types";

const MarketService = {
  getMarketPlaceBuyAndSell: (args) => {
    if (args.amount) {
      store.dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});

      return API.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}/${args.amount}`
      )
        .then(({data}) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      store.dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});

      return API.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}`
      )
        .then(({data}) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    }
  },

  getMarketPlaceBuyAndSellFuture: (args) => {
    if (args.amount) {
      // store.dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});

      return API.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}/${args.amount}`
      )
        .then(({data}) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    } else {
      // store.dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});

      return API.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}`
      )
        .then(({data}) => {
          return data;
        })
        .catch((error) => {
          throw error;
        });
    }
  },
  // getMarketCheckOutData: async (args) => {
  //   return API.get(`/api/v1/trade/get-checkout-data/${args?.id}`)
  //     .then((data) => {
  //       return data.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // },
  getMarketModalData: (args) => {
    store.dispatch({type: CHECK_OUT_DATA_INITIAL, payload: true});
    return Promise.all([getMarketCheckOutData({id: args.id}), getMarketTradersData({id: args.traderID})])
      .then((data) => {
        let theData = {
          checkOutData: data[0],
          traderData: data[1],
        };
        return theData;
      })
      .catch((err) => {
        console.log(err, "messages");
        // throw err;
      });
  },
  // getUnitPrice: (args) => {
  //   API.get(`/api/v1/open/get-token-price/${args.tokenSymbol}/${args.currency}`).then((data) => {
  //     return data.currencyValue;
  //   });
  // },
  // getMarketTradersData: async (args) => {
  //   return API.get(`/api/v1/open/get-user-trade-data/${args?.id}`)
  //     .then((data) => {
  //       store.dispatch({type: REMOVE_SPINNER, payload: false});
  //       return data.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // },

  UserTotalBalance: () => {
    return API.get(`/api/v1/profile/get-user-token-balance`)
      .then((res) => {
        if (res?.data?.message === "success") {
          return res?.data;
        }
      })
      .catch((err) => {
        throw err;
      });
  },
};

const getUnitPrice = (args) => {
  return API.get(`/api/v1/open/get-token-price/${args.tokenSymbol}/${args.currency}`).then(({data}) => {
    return data.currencyValue;
  });
};

const getMarketCheckOutData = async (args) => {
  return API.get(`/api/v1/trade/get-checkout-data/${args?.id}`)
    .then(({data}) => {
      return {
        unitPrice: data.unitPrice,
        ...data.orderData,
      };
    })
    .catch((error) => {
      throw error;
    });
};

const getMarketTradersData = async (args) => {
  return API.get(`/api/v1/open/get-user-trade-data/${args?.id}`)
    .then(({data}) => {
      return data;
    })
    .catch((err) => {
      throw err;
    });
};

export default MarketService;
