import React, {memo, useEffect, useState} from "react";
import QRCode from "qrcode";
import GoogleOTP from "./googleOTP";
import "../../styles/settings/googleVerification.scss";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getGoogleAuthProcessModals} from "../../store/actions/settingAction";
import SettingsService from "../../services/settingsServices";

const GoogleAuthVerification = () => {
  const dispatch = useDispatch();
  const googleAuthQRCode = useSelector((state) => state.settingsReducer.googleAuthQRCode);
  const [otp, setOtp] = useState("");

  const handleChange = (data) => {
    setOtp(data);
  };

  const CancelOtpProcess = () => {
    dispatch(getGoogleAuthProcessModals({}));
  };

  const ContinueGoogleOtpProcess = () => {
    dispatch(getGoogleAuthProcessModals({num: 0}));
    SettingsService.GoogleAuthPostCode({code: otp}).then((data) => {
      if (data === "success") {
        dispatch(getGoogleAuthProcessModals({num: 2}));
      }
    });
  };

  return (
    <div className="googleVerificationWrapper">
      <div className="googleVerificationWrapper__Header">
        <h4>Set up Google Authenticator</h4>
        <p>
          Protect your account and transactions. Please scan this QR code with your Google Authenticator App and enter
          the verification code below.
        </p>
      </div>
      <div className="googleVerificationWrapper__QRcode">
        <img src={googleAuthQRCode} alt="qrCode" />
      </div>
      <div className="googleVerificationWrapper__Otp">
        <p>Verification code</p>
        <div className="googleVerificationWrapper__OtpDetails">
          <GoogleOTP handleChange={handleChange} otp={otp} />
        </div>
      </div>
      <div className="googleVerificationWrapper__DownLayout">
        <div className="googleVerificationWrapper__DownLayoutTextSection">
          <p>{`New to Google Authentication? `} </p>
          <Link> Read our article.</Link>
        </div>
        <div className="googleVerificationWrapper__DownLayoutButtonSection">
          <button onClick={CancelOtpProcess} className="googleVerificationWrapper__DownLayoutButtonSectionCancel">
            Cancel
          </button>
          <button
            onClick={ContinueGoogleOtpProcess}
            className="googleVerificationWrapper__DownLayoutButtonSectionConfirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default memo(GoogleAuthVerification);
