import {useEffect} from "react";
import {useState} from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import CurrencyFormat from "react-currency-format";
import {RiFileCopyFill} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {BNB, BTC, ETH, NOTFOUND, USDT} from "../../../../hooks/imagePath";
import TransactionService from "../../../../services/transactionHistory";
import {
  filterDepositWithdrawByCoinAction,
  filterDepositWithdrawByStatusAction,
  filterDepositWithdrawByTypeAction,
  getDepositWithdrawOnNextAction,
  getDepositWithdrawOnPrevAction,
  getDepositWithdrawTransactionAction,
} from "../../../../store/actions/depositWithdrawTransactionHistory";
import {
  DEPOSITE_SKIP,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS,
} from "../../../../store/types";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {trimId} from "../../../../utils/trimID";
import {Loader} from "../../../Common/Loader";
import {OurModal2} from "../../../Common/modal";
import {TablePaginationComponent} from "../../../Common/pagination";
import {TransactionTableStaticDropDown} from "../../../Common/transactionTableStaticDropdown";
import ViewMoreModal from "../moreModal";

const TransactionDesktopDepositWithdrawTable = ({data, loading}) => {
  const [moreModal, setMoreModal] = useState(false);
  const [modalData, setModalData] = useState();
  const dispatch = useDispatch();

  const [checkForEmpty, setCheckForEmpty] = useState();

  const {skip, fskip, limit, transactionWithdrawalStated} = useSelector(
    (state) => state.depositWithdrawaltransactionReducer
  );

  useEffect(() => {
    dispatch({type: DEPOSITE_SKIP, payload: 0});
    dispatch(
      getDepositWithdrawTransactionAction({
        skip: fskip,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      })
    );
  }, [transactionWithdrawalStated]);

  const onNext = () => {
    const skipDetails = (skip + 1) * 10;

    if (checkForEmpty?.length !== 0) {
      TransactionService.getDepositWithdraw({
        skip: skipDetails,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      }).then((data) => {
        if (data.message === "success") {
          if (data?.transactions?.length !== 0) {
            dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS, payload: data.transactions});
            dispatch({type: DEPOSITE_SKIP, payload: skip + 1});
            setCheckForEmpty(data?.trades);
          } else {
            setCheckForEmpty([]);
            dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL_STATE, payload: false});
          }
        }
      });
    }
  };

  const onPrev = () => {
    setCheckForEmpty();

    const skipDetails = (skip - 1) * 10;

    if (data?.length > 0 && skip + 1 > 1) {
      TransactionService.getDepositWithdraw({
        skip: skipDetails,
        limit: limit,
        type: transactionWithdrawalStated?.type,
        tokenSymbol: transactionWithdrawalStated?.tokenSymbol,
        status: transactionWithdrawalStated?.status,
      }).then((data) => {
        if (data.message === "success") {
          dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS, payload: data.transactions});
          dispatch({type: DEPOSITE_SKIP, payload: skip - 1});
        }
      });
    }
  };

  return (
    <>
      <div className="table__wrapper">
        {loading ? (
          <Loader />
        ) : (
          <>
            <table className="tablew">
              <thead className="newTable__thead">
                <tr className="newTable__tr">
                  <th className="newTable__thh" style={{width: "60px"}}>
                    Coin Type
                  </th>
                  <th className="newTable__thh">Type</th>
                  <th className="newTable__thh">Wallet</th>
                  <th className="newTable__thh">Amount</th>
                  <th className="newTable__thh">TX ID</th>
                  <th className="newTable__thh">Status</th>
                  <th className="newTable__thh">View More</th>
                </tr>
              </thead>

              <tbody className="newTable__tbody">
                {data &&
                  data?.length > 0 &&
                  data.map((item, i) => {
                    return (
                      <tr className="myTable" style={{height: "40px"}} key={i}>
                        <td className="tableItem">
                          <div className="transactionTableArea__coinWrapper">
                            <img
                              src={
                                item?.tokenSymbol === "USDT"
                                  ? USDT
                                  : item?.tokenSymbol === "BTC"
                                  ? BTC
                                  : item?.tokenSymbol === "ETH"
                                  ? ETH
                                  : BNB
                              }
                              alt="usdt icon"
                              className="transactionTableArea__coinImage"
                            />
                            <span className="transactionTableArea__coin">{item?.tokenSymbol}</span>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__typeWrapper">
                            <span className="transactionTableArea__type">{item?.type}</span>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__walletWrappers">
                            <span className="transactionTableArea__wallet">{item?.tokenSymbol} Wallet</span>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__currencyWrappers">
                            <span className="transactionTableArea__wallet">
                              <CurrencyFormat
                                value={item?.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={3}
                                fixedDecimalScale
                                suffix={` ${item?.tokenSymbol}`}
                              />
                            </span>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__amountWrappers">
                            <CopyToClipboard text={item?.txID} onCopy={() => toast.success("copied successfully")}>
                              <span className="transactionTableArea__amount" style={{display: "flex"}}>
                                <span style={{color: "gray", marginRight: "10px"}}>{trimId(item?.txID)}</span>
                                <RiFileCopyFill />
                              </span>
                            </CopyToClipboard>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__statusWrappers">
                            <span
                              className={`transactionTableArea__status ${
                                item?.status?.toLowerCase() === "cancelled"
                                  ? "__cancelled"
                                  : item?.status?.toLowerCase() === "pending"
                                  ? "__pending"
                                  : "__complete"
                              }`}
                              style={{
                                color: `${
                                  item?.status?.toLowerCase() === "failed"
                                    ? "#F04438"
                                    : item?.status?.toLowerCase() === "pending"
                                    ? "#F2B339"
                                    : "#027a48"
                                }`,
                              }}
                            >
                              {capitalizeFirstLetter(item?.status)}
                            </span>
                          </div>
                        </td>
                        <td className="tableItem">
                          <div className="transactionTableArea__viewMoreWrappers">
                            <span
                              className="transactionTableArea__viewMore"
                              onClick={() => {
                                setModalData(item);
                                setMoreModal(true);
                              }}
                            >
                              view more
                            </span>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
            {!loading && (data === null || data === undefined || data?.length <= 0) && (
              <div className="__notFound__wrapperr" style={{height: "30%"}}>
                <div className="__theNotFound">
                  <img src={NOTFOUND} alt="not found" />
                  <span>No Data</span>
                </div>
              </div>
            )}

            {data?.length > 0 && (
              <TablePaginationComponent
                onNext={onNext}
                onPrev={onPrev}
                skip={skip}
                lengthChecker={checkForEmpty}
                dataCheck={data}
              />
            )}
          </>
        )}
      </div>

      <OurModal2 open={moreModal} setOpen={setMoreModal}>
        <ViewMoreModal setOpen={setMoreModal} data={modalData} />
      </OurModal2>
    </>
  );
};

export default TransactionDesktopDepositWithdrawTable;
