import {memo, useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import PostTradeComponent from "./PostTradeComponents";
import {POST_TRADE_BUY_ERROR, POST_TRADE_SELL_ERROR, SKELETON_LOADER, TOKENMARGIN} from "../../store/types";
import PostTradeService from "../../services/postTradeService";
import {
  getBuyData,
  getCoinData,
  getCountryData,
  getSellData,
  getTotalCurrencyData,
} from "../../store/actions/postTradeAction";
import {Loader} from "../Common/Loader";

function PostTradePage() {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  const geoLocation = useSelector((state) => state.generalReducer.locationData);
  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const params = useParams();

  const {transactionType, coinType, countryName} = params;

  const myDispatchMethod = async () => {
    const findOurToken = tokenDetails?.find((item) => item?.title?.toLowerCase() === coinType?.toLowerCase());

    let countryChecker;

    if (countryData?.data?.countriesData?.length !== 0) {
      countryChecker = countryData?.data?.countriesData?.find(
        (item) => item?.countryName?.toLowerCase() === countryName?.toLowerCase()
      );
    }

    if (countryChecker === undefined) {
      countryChecker = geoLocation?.data?.geoData;
    }

    dispatch(getBuyData(transactionType !== "buy" && transactionType !== "sell" ? "buy" : transactionType));

    dispatch(getSellData(transactionType !== "buy" && transactionType !== "sell" ? "buy" : transactionType));

    dispatch(
      getCountryData({
        ...countryChecker,
        name: countryChecker?.countryName,
        nameAcr: countryChecker?.alpha3,
      })
    );

    dispatch(getCoinData(findOurToken === undefined ? "btc" : coinType));
  };

  const margin = useSelector((state) => state.postTradeReducer.tokenMarginData);
  const allCurrencyDetails = useSelector((state) => state.postTradeReducer.tokenLocalCurrencyData);
  const isLoading = useSelector((state) => state.postTradeReducer.isLoading);
  const sellData = useSelector((state) => state.postTradeReducer.sellData);
  const buyData = useSelector((state) => state.postTradeReducer.buyData);
  const postTradeDetailedForm = useSelector((state) => state.postTradeReducer.postTradeDetailedForm);

  const getLocalCurrency = () => {
    if (countryData?.data?.countriesData?.length > 0) {
      let countryChecker;

      if (countryData?.data?.countriesData?.length !== 0) {
        countryChecker = countryData?.data?.countriesData?.find(
          (item) => item?.countryName?.toLowerCase() === countryName?.toLowerCase()
        );
      }

      if (countryChecker === undefined) {
        countryChecker = geoLocation?.data?.geoData;
      }

      dispatch({type: SKELETON_LOADER, payload: true});

      if (
        coinType !== "" &&
        countryChecker?.currencyCode !== "" &&
        countryChecker?.currencyCode !== undefined &&
        typeof margin === "number" &&
        !isNaN(margin)
      ) {
        PostTradeService.TokenLocalCurrency({
          tokenSymbol: coinType,
          currency: countryChecker?.currencyCode,
          margin: 0,
        }).then((data) => {
          dispatch(getTotalCurrencyData(data));

          if (Object.keys(postTradeDetailedForm).length > 0) {
            const newMinValue = postTradeDetailedForm?.minValue?.match(/\d/g);
            const minAmounts = newMinValue?.join("");

            const newMaxValue = postTradeDetailedForm?.maxValue?.match(/\d/g);
            const maxAmounts = newMaxValue?.join("");

            if (buyData.checker === true) {
              if (Number(maxAmounts) < Number(minAmounts)) {
                dispatch({type: POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
              } else {
                dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
              }
            }

            if (sellData.checker === true) {
              if (data.marginCoinBalance < Number(maxAmounts)) {
                dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
              } else {
                dispatch({type: POST_TRADE_SELL_ERROR, payload: ""});
              }

              if (Number(maxAmounts) < Number(minAmounts)) {
                dispatch({type: POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
              } else {
                dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
              }
            }
          }

          setLoading(false);
        });
      }
    }
  };

  // useEffect(() => {
  //   myDispatchMethod();
  //   getLocalCurrency();
  // }, [countryData]);

  useEffect(() => {
    setLoading(true);
    dispatch({type: TOKENMARGIN, payload: 0});
    myDispatchMethod();
    getLocalCurrency();
  }, [countryData]);

  return (
    <>
      {loading && <Loader />}
      {Object.keys(allCurrencyDetails).length === 0 && loading && <Loader />}
      {isLoading && <Loader />}
      {Object.keys(allCurrencyDetails).length > 0 && !isLoading && !loading && <PostTradeComponent />}
    </>
  );
}

export default memo(PostTradePage);
