import "./more-features.scss";
import smile from "../../../assets/icons/customer-support.svg";
import instant from "../../../assets/icons/instant-transactions.svg";
import bonus from "../../../assets/icons/secure-transactions.svg";
import "../../../styles/landing-page-styles/button.scss";
import Card from "../card.component";

const MoreFeatures = () => {
  const token = localStorage.getItem("isLoggedIn");

  return (
    <>
      <main className="homepageContainer">
        <div className="innerContainer">
          <div className="moreFeatures">
            <div className="titleContainer">
              <h1 className="title">Register on Oyola in minutes</h1>
              <p className="description">
                Get started with Oyola by signing up and verifying your account to start trading.
              </p>
            </div>

            <div className="signUpContainer">
              <button
                style={{cursor: "pointer"}}
                className="baseButton primaryButtonLarge buttonAdditionalStyle"
                onClick={() => {
                  token === null || token === undefined
                    ? (window.location = "/signup")
                    : (window.location = "/app/overview");
                }}
              >
                Get Started
              </button>
            </div>
          </div>
        </div>
      </main>

      <main className="homepageContainer">
        <div className="innerContainerFullWidth">
          <div className="moreFeatures">
            <div className="titleContainer">
              <h1 className="title">Get more value from your portfolio</h1>
              <p className="description">
                Perform transactions with trust, enjoy instant transactions and earn rewards.
              </p>
            </div>
            <div className="horizontalContainer">
              <Card
                icon={smile}
                title="24/7 customer support"
                text="Get help when you need it with our dedicated customer service and support team."
              />
              <Card
                icon={instant}
                title="Instant Transactions"
                text="Receive and send crypto or fiat within minutes. No delays, no worries."
              />
              <Card
                icon={bonus}
                title="Secure transactions"
                text="The Oyola escrow service ensures that you can make secure transactions without worries."
              />
            </div>
          </div>
        </div>
      </main>
    </>
  );
};

export default MoreFeatures;
