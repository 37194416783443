import ReviewRow from "./Comment";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import "../../styles/button.scss";
import EmptyStateProfile from "./emptyStateProfile";

const AllComments = () => {
  const history = useHistory();

  const {profileHeaderOtherDataReducer, saveOtherUsernameState} = useSelector((state) => state.profileReducer);

  const listOfReviewsState = useSelector((state) => state.profileReducer.listOfReviewsState);
  const filteredObjects = listOfReviewsState.filter(
    (filter) => filter.reviewerUsername !== profileHeaderOtherDataReducer?.username
  );

  if (listOfReviewsState?.length === 0) {
    return <EmptyStateProfile />;
  }

  const handleButton = () => {
    history.push(`/app/all-reviews/${saveOtherUsernameState.username}`);
  };

  if (listOfReviewsState?.length > 0) {
    return (
      <div className="otherProfileReviews">
        {filteredObjects.slice(0, 3).map((item, index) => (
          <>
            <ReviewRow
              key={Math.random()}
              username={item.reviewerUsername}
              userFeeback={item.text}
              isVerified={item.isTrust === true ? true : false}
              transactionId={item.date_added}
            />
          </>
        ))}
        <div className="buttonContainer">
          <button className=" baseButton primaryButton buttonStyle" onClick={handleButton}>
            Show more
          </button>
        </div>
      </div>
    );
  }
};

export default AllComments;
