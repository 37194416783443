import {useSelector} from "react-redux";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import UserTradeDesktopBuySellTable from "./desktopTable";
import DisputedMobileTable from "./mobileTable";

const DisputeTradeOrders = () => {
  const {width} = useWindowDimensions();
  const {disputed, loading} = useSelector((state) => state.getUserTradeReducer);

  return width <= 700 ? (
    <DisputedMobileTable data={disputed} loading={loading} />
  ) : (
    <UserTradeDesktopBuySellTable data={disputed} loading={loading} />
  );
};

export default DisputeTradeOrders;
