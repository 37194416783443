import React, {useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
  getDefaultCurrenctCountry,
  getPhoneSearchableDetails,
  getUpdatePhoneNumber,
} from "../../store/actions/settingAction";

import CountrySearchable from "./countrySearchable";
import UserImageButton from "./uploadImageButton";

import "../../styles/button.scss";
import "../../styles/new/settings.scss";
import {Skeleton} from "@mui/material";
import SettingsService from "../../services/settingsServices";
import {LIST_OF_BLOCK_USERS} from "../../store/types";

const UserUpload = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);
  const geoLocation = useSelector((state) => state.generalReducer.locationData);
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const defaultCurrenctCountry = useSelector((state) => state.settingsReducer.defaultCurrenctCountry);
  const isLoading = useSelector((state) => state.postTradeReducer.isLoading);

  const GetUserCurrentDetails = useCallback(
    (countryData, geoLocation, userData) => {
      if (
        Object.keys(countryData).length > 0 &&
        Object.keys(geoLocation).length > 0 &&
        Object.keys(userData).length > 0
      ) {
        const findDefaultCurrency = countryData?.data?.countriesData?.find(
          (item) =>
            item?.countryName?.toLowerCase() === userData?.data?.userData?.defaultCurrency?.country?.toLowerCase()
        );

        if (!userData?.data?.userData?.phoneNumber) {
          dispatch(getUpdatePhoneNumber(""));
        } else {
          dispatch(getUpdatePhoneNumber(userData?.data?.userData?.phoneNumber));
        }

        dispatch(getPhoneSearchableDetails(geoLocation?.data?.geoData));
        dispatch(getDefaultCurrenctCountry({...findDefaultCurrency, name: findDefaultCurrency?.countryName}));

        SettingsService.blockedUser({username: userData?.data?.userData?.username}).then((data) => {
          dispatch({type: LIST_OF_BLOCK_USERS, payload: data?.blockedUsers});
        });
      }
    },
    [dispatch]
  );

  useEffect(() => {
    GetUserCurrentDetails(countryData, geoLocation, userData);
  }, [GetUserCurrentDetails, countryData, geoLocation, userData]);

  return (
    <React.Fragment>
      <h1 className="settingsTitle">Settings</h1>
      <div className="first-row">
        <article className="left-container">
          <div className="profile-container">
            <UserImageButton />
          </div>
        </article>
        <>
          {Object.keys(defaultCurrenctCountry).length === 0 && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={300}
              height={45}
              // sx={{background: "linear-gradient(to right, #f4f4f4 50% , rgba(246, 246, 246, 0.05) 50%)"}}
              style={{background: "rgba(248,248,248,70)"}}
            />
          )}
          {isLoading && (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={300}
              height={45}
              style={{background: "rgba(248,248,248,70)"}}
            />
          )}

          {Object.keys(defaultCurrenctCountry).length > 0 && !isLoading && (
            <div className="right-container_Main">
              <div className="searchable-container">
                <CountrySearchable />
              </div>
            </div>
          )}
        </>
      </div>
    </React.Fragment>
  );
};

export default UserUpload;
