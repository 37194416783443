import "./card-list.scss";

const CardList = ({icon, title, subTitle, trailing}) => {
  return (
    <div className="cardList">
      <div className="iconContainer">
        <img src={icon} alt="logo" className="icon" />
        {trailing === true && <p className="tag">{"Coming Soon"}</p>}
      </div>
      <p className="title">{title}</p>
      <p className="subtitle">{subTitle}</p>
    </div>
  );
};

export default CardList;
