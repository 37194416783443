import {Helmet} from "react-helmet";

import NavigationBar from "./nav-bar/nav-bar.component";
import Footer from "./footer/footer.component";

import "../../styles/layout.scss";

const Layout = ({children}) => {
  const notificationNumChecker = localStorage.getItem("notification_increase");

  let notificationNum = parseInt(notificationNumChecker);
  return (
    <>
      <Helmet>
        <title>Buy, Spend or Hold Crypto on Oyola</title>
        <meta name="description" content="Buy, Spend or Hold Crypto on Oyola" />
        <script id="ze-snippet" src="https://embed.tawk.to/64afb67494cf5d49dc6349df/1h5761eiu" />

        {notificationNum > 0 ? (
          <link rel="icon" href={`${process.env.PUBLIC_URL + "/logo-red192.png"}`} type="image/x-icon" />
        ) : (
          <link rel="icon" href={`${process.env.PUBLIC_URL + "/logo192.png"}`} type="image/x-icon" />
        )}
      </Helmet>
      <NavigationBar />
      {children}
      <Footer />
    </>
  );
};

export default Layout;
