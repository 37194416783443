import ProtectedRoute from "./screens/ProtectedRoute";
import Home from "./screens/Home";
import Login from "./screens/Login";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "react-spring-bottom-sheet/dist/style.css";
import "react-toastify/dist/ReactToastify.css";
import {useEffect} from "react";
import Register from "./screens/Register";
import ForgotPassword from "./screens/ForgotPassword";
import Oops from "./components/oops";
import NotFound from "./components/404";
import "./App.scss";
import {ToastContainer} from "react-toastify";
import LandingPage from "./screens/LandingPage";
import Features from "./screens/Features";
import About from "./screens/about-us";
import Careers from "./screens/careers";
import Faq from "./screens/faq";
import marketplace from "./screens/marketplace";
import oyolafees from "./screens/oyolafees";
import privacy from "./screens/privacy";
import support from "./screens/support";
import terms from "./screens/terms";
import videos from "./screens/videos";
import LandingServices from "./services/landingServices";
import {useDispatch} from "react-redux";
import {
  getSelectedCoinAction,
  getSelectedCountryAction,
  getSelectedGeoLocationAction,
  getSelectedPaymentAction,
} from "./store/actions/LandingAction";
import {useSelector} from "react-redux";
import ScrollToTop from "./utils/ScrollToTop";
import RiskDisclosure from "./screens/RiskDisclosure";
import AntiMoneyLaundering from "./screens/AntiMoneyLaundering";
import DownloadPage from "./screens/DownloadPage";
import MobileDownload from "./screens/MobileDownload";

function App() {
  const notificationNumChecker = localStorage.getItem("notification_increase");

  let notificationNum = parseInt(notificationNumChecker);

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.authReducer.isLoggedIn);

  const getCoins = async () => {
    try {
      const resp = await LandingServices.getCoinsData();
      if (resp.data.message === "success") {
        dispatch(getSelectedCoinAction(resp?.data?.tokens));
      }
    } catch (err) {}
  };

  const getPayment = async () => {
    try {
      const resp = await LandingServices.getPaymentData();
      if (resp?.data?.message === "success") {
        dispatch(getSelectedPaymentAction(resp?.data?.paymentsData));
      }
    } catch (err) {}
  };

  const getCountry = async () => {
    try {
      const resp = await LandingServices.getCountryData();
      if (resp?.data?.message === "success") {
        resp?.data?.countriesData?.unshift({name: "Worldwide", countryName: "Worldwide"});
        dispatch(getSelectedCountryAction(resp?.data?.countriesData));
      }
    } catch (err) {}
  };

  const getGeoLocation = async () => {
    try {
      const resp = await LandingServices.getGeoLocationData();
      if (resp?.data?.message === "success") {
        dispatch(getSelectedGeoLocationAction(resp?.data?.geoData));
      }
    } catch (err) {}
  };

  useEffect(() => {
    AOS.init();
    AOS.refresh();

    if (window.location.pathname === "/" || window.location.pathname?.startsWith("/marketplace")) {
      getCoins();
      getPayment();
      getCountry();
      getGeoLocation();
    }

    if (isLoggedIn && (window.location.pathname === "/login" || window.location.pathname === "/signup")) {
      window.history.back();
    }
  }, []);

  return (
    <>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route path="/about-us" component={About} />
            <Route path="/careers" component={Careers} />
            <Route path="/faq" component={Faq} />
            <Route path="/marketplace/:country?/:token?/:type?/:paymentMethod?/:amount?" component={marketplace} />
            <Route path="/oyolafees" component={oyolafees} />
            <Route path="/privacy" component={privacy} />
            <Route path="/support" component={support} />
            <Route path="/terms" component={terms} />
            <Route path="/videos" component={videos} />
            <Route path="/anti-money-laundering" component={AntiMoneyLaundering} />
            <Route path="/risk-disclosure" component={RiskDisclosure} />
            <Route exact path="/login" component={Login} />
            <Route path="/features" component={Features} />
            <Route path="/signup" component={Register} />
            <Route path="/download" component={DownloadPage} />
            <Route path="/mobile-download" component={MobileDownload} />
            <Route path="/oop" component={Oops} />
            <Route path="/404" component={NotFound} />
            <Route path="/forgot-password" component={ForgotPassword} />
            <ProtectedRoute path="/app" component={Home} />
            <Route path="/app" component={Home} />
            <Route path="/app/asset" component={Home} />
            <Route path="/app/trade-orders" component={Home} />
            <Route path="/app/user-trades" component={Home} />
            <Route path="/app/marketplace/:country?/:token?/:type?/:paymentMethod?" component={Home} />
            <Route path="/app/wallet" component={Home} />
            <Route path="/app/notification" component={Home} />
            <Route path="/app/transaction" component={Home} />
            <Route path="/app/profile" component={Home} />
            <Route path="/app/settings" component={Home} />
            <Route path="/app/trade/:id?" component={Home} />
            <Route path="/app/sell-crypto" component={Home} />
            <Route path="/app/post-trade/:transactionType/:coinType/:countryName" component={Home} />
            <Route path="/app/edit-trade/:tradeId" component={Home} />
            <Route path={"*"} component={NotFound} />
          </Switch>
        </div>
      </Router>
      <ToastContainer position="top-right" autoClose={2500} hideProgressBar={true} newestOnTop={false} closeOnClick />
    </>
  );
}

export default App;
