import React from "react";

class OyolaPuzzleSecurityComponent extends React.Component {
  componentDidMount() {
    window.initGeetest4(this.props["captcha-config"].config, this.props["captcha-config"].handler);
  }
  render() {
    return <div className="captcha"></div>;
  }
}

export default OyolaPuzzleSecurityComponent;
