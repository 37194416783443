import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {confirmTradeByIDAction} from "../../../store/actions/createTradeAction";
import {getTradeStatusByIDAction} from "../../../store/actions/getTradeStatus";
import {CONFIRM_PAYMENT_DEFAULT} from "../../../store/types";
import "../../../styles/createTrade/confirmModal.scss";
import {Loader} from "../../Common/Loader";
const ConfirmBuyModal = ({setOpenModal, setStep}) => {
  const dispatch = useDispatch();
  const {id} = useParams();

  const [checkValue, setCheckValue] = useState(false);
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const {response: confirmResponse, loading} = useSelector((state) => state.confirmTraderReducer);
  const userType = useGetUserType(response ?? {});

  const something = () => {
    if (userType === "user" && response?.type === "sell") {
      setStep(1);
    } else if (userType === "user" && response?.type === "buy") {
      dispatch(confirmTradeByIDAction({trade_id: id}));
    } else if (userType === "trader" && response?.type === "buy") {
      setStep(1);
    } else if (userType === "trader" && response?.type === "sell") {
      dispatch(confirmTradeByIDAction({trade_id: id}));
    }
  };

  useEffect(() => {
    if (confirmResponse?.message === "success") {
      dispatch(getTradeStatusByIDAction({id}));
      setOpenModal(false);
    }
    return () => {
      dispatch({type: CONFIRM_PAYMENT_DEFAULT, payload: ""});
    };
  }, [confirmResponse, setOpenModal, dispatch, id]);

  return (
    <div className="__createTrade__buy__modal__wrapper">
      {loading ? (
        <Loader style={{height: "280px"}} />
      ) : (
        <>
          <div className="__warning__holder">
            <span className="__confirm__payment">Confirm Payment</span>
          </div>

          <p className="__are__you__sure">Are you sure you have received payment?</p>

          <span className="__click_to__accept">Click on box to accept</span>
          <div className="check__wrapper">
            <input type={"checkbox"} onChange={(e) => setCheckValue(e.target.checked)} />
            <p className="__terms">
              I agree i have transferred the correct funds to the seller’s Oyola veified payment method as shown above.
            </p>
          </div>
          <div className="__modal__button__wrapper">
            <button className="__cancel" onClick={() => setOpenModal(false)}>
              Cancel
            </button>
            <button className={`__confirm ${checkValue && "accept"}`} disabled={!checkValue} onClick={something}>
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export {ConfirmBuyModal};
