import React, {memo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {settingVerifyModal} from "../../store/actions/settingAction";

import iconBack from "../../assets/icons/icon-back.svg";
import iconCancel from "../../assets/icons/icon-cancel.svg";
// import OtpInput from "react-otp-input";
import SettingsService from "../../services/settingsServices";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
import GeneralService from "../../services/general";
import {getUser} from "../../store/actions/general";
import OptInputField from "./optInputField";
import {Loader4} from "../Common/Loader";
import {toast} from "react-toastify";

const SecondPhoneNumberModal = () => {
  // toggle to next modal
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetailsForUpdatingPhoneNumber = useSelector(
    (state) => state.settingsReducer.userDetailsForUpdatingPhoneNumber
  );

  const [phoneOtp, setPhoneOtp] = useState("");
  const [emailOtp, setEmailOtp] = useState("");

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
  }

  function goBackButton() {
    dispatch(settingVerifyModal(3));
  }

  const handleEmailChange = (otp) => {
    setEmailOtp(otp);
  };

  const handlePhoneChange = (otp) => {
    setPhoneOtp(otp);
  };

  function nextButton() {
    const updateDetails = userData?.data?.userData?.authPreference === "sms" && {
      phoneNumber: userDetailsForUpdatingPhoneNumber?.phoneNumber,
      phoneCode: phoneOtp,
      emailCode: emailOtp,
      authType: "sms",
    };

    setIsLoading(true);
    SettingsService.UpdatePhone(updateDetails)
      .then((data) => {
        if (data === "success") {
          dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
          dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
          GeneralService.getUserData().then((data) => {
            dispatch(getUser(data ?? {}));
            dispatch(settingVerifyModal(null));
          });
          setIsLoading(false);
          toast.info("Phone number has been added successfully", {theme: "colored"});
        } else {
          toast.error(data, {theme: "colored"});
          setIsLoading(false);
        }
      })
      .catch((error) => {
        toast.error(error, {theme: "colored"});
        setIsLoading(false);
      });
  }

  const formatPhoneNumber = (data) => {
    const stringFormat = data ? `${data?.slice(0, 6)}***${data?.slice(-3, data?.length)}` : "";
    return stringFormat;
  };

  return (
    <div>
      {userData?.data?.userData?.authPreference === "sms" && (
        <main className="modal-container" data-aos="zoom-in-down" style={{height: "480px"}}>
          <div className="body">
            <div className="top-section-modal-body" style={{marginTop: "2rem", marginBottom: "2rem"}}>
              <div className="cancel-container">
                <img src={iconBack} alt="back-icon" onClick={goBackButton} />
                <img
                  src={iconCancel}
                  alt="back-cancel"
                  onClick={() => {
                    dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                    dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                    cancelButton();
                  }}
                />
              </div>
            </div>

            <div className="second-container">
              <div className="TT">
                <OptInputField
                  value={phoneOtp}
                  onChange={handlePhoneChange}
                  tag={formatPhoneNumber(userDetailsForUpdatingPhoneNumber?.phoneNumber)}
                  title="Please Verify your Phone Number"
                  marginTop="3rem"
                />

                <OptInputField
                  value={emailOtp}
                  onChange={handleEmailChange}
                  tag={formatPhoneNumber(userDetailsForUpdatingPhoneNumber?.email)}
                  title="Please Verify your Email Number"
                  marginTop="6rem"
                />
              </div>
            </div>
          </div>

          <div className="footer-modal" style={{margin: "0rem 2.5rem 3rem"}}>
            <div className="button-container">
              <button style={{...buttonStyle, ...cancelButtonStyle}} className="base-button" onClick={cancelButton}>
                Cancel
              </button>
              <button
                className="base-button"
                style={buttonStyle}
                onClick={nextButton}
                disabled={phoneOtp.length >= 6 && emailOtp.length >= 6 ? false : true}
              >
                {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Confirm</p>}
              </button>
            </div>
          </div>
        </main>
      )}
    </div>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  width: "100%",
  height: "40px",
};

const cancelButtonStyle = {
  background: "transparent",
  border: "1px solid gray",
  color: "black",
};

export default memo(SecondPhoneNumberModal);
