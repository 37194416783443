import React, {memo} from "react";
import OverviewMainLowerSection from "./overviewMainLowerSection";
import OverviewMainTopSection from "./overviewMainTopSection";
import "../../styles/overviewStyles/overviewMainSection.scss";

const OverviewMainSection = () => {
  return (
    <div className="overview-MainTopSection">
      <div className="overview-MainTopSectionTopLayout">
        <OverviewMainTopSection />
      </div>
      <div className="overview-MainTopSectionLowerLayout">
        <OverviewMainLowerSection />
      </div>
    </div>
  );
};

export default memo(OverviewMainSection);
