import React, {memo, useState} from "react";

import {useDispatch, useSelector} from "react-redux";

import {getUpdatePhoneNumber, settingVerifyModal} from "../../store/actions/settingAction";

import iconBack from "../../assets/icons/icon-back.svg";
import iconCancel from "../../assets/icons/icon-cancel.svg";
// import OtpInput from "react-otp-input";
// import SettingsService from "../../services/settingsServices";
import {UPDATED_PHONE_NUMBER_DETAILS, USER_DETAILS_FOR_UPDATING_PHONE_NUMBER} from "../../store/types";
// import GeneralService from "../../services/general";
// import {getUser} from "../../store/actions/general";
import OptInputField from "./optInputField";
import {Loader4} from "../Common/Loader";
import {toast} from "react-toastify";
import SettingsService from "../../services/settingsServices";
import GeneralService from "../../services/general";
import {getUser} from "../../store/actions/general";

const GoogleAuthChangeSecondModal = () => {
  // toggle to next modal
  const [isLoading, setIsLoading] = useState(false);
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetailsForUpdatingPhoneNumber = useSelector(
    (state) => state.settingsReducer.userDetailsForUpdatingPhoneNumber
  );

  const [errorMessage, setErrorMessage] = useState("");
  const errorUpdatingPhoneNumber = useSelector((state) => state.settingsReducer.errorUpdatingPhoneNumber);

  const [googleAuthOtp, setGoogleAuthOtp] = useState("");

  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
  }

  function goBackButton() {
    dispatch(settingVerifyModal(10));
  }

  const handleGoogleChange = (otp) => {
    setGoogleAuthOtp(otp);
  };

  function nextButton() {
    setIsLoading(true);

    const updateDetails = userData?.data?.userData?.authPreference === "google auth" && {
      phoneNumber: userDetailsForUpdatingPhoneNumber?.phoneNumber,
      authCode: googleAuthOtp,
      authType: "google auth",
    };

    SettingsService.UpdatePhone(updateDetails)
      .then((data) => {
        if (data.message === "success") {
          GeneralService.getUserData().then((res) => {
            dispatch(getUser(res ?? {}));

            if (!res?.data?.userData?.phoneNumber) {
              dispatch(getUpdatePhoneNumber(""));
            } else {
              dispatch(getUpdatePhoneNumber(res?.data?.userData?.phoneNumber));
            }

            toast("Phone number has been changed", {theme: "colored"});
            dispatch(settingVerifyModal(null));
            dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
            dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
            setIsLoading(false);
          });

          setIsLoading(false);
        } else {
          setErrorMessage(data.message);
          toast.error(data, {theme: "colored"});
          setIsLoading(false);
        }
      })
      .catch(() => {
        toast.error("Something went wrong");
        setIsLoading(false);
      });
  }

  const formatPhoneNumber = (data) => {
    const stringFormat = data ? `${data?.slice(0, 6)}***${data?.slice(-3, data?.length)}` : "";
    return stringFormat;
  };

  return (
    <main className="modal-container" data-aos="zoom-in-down" style={{height: "340px", width: "400px"}}>
      <div className="body">
        <div className="top-section-modal-body" style={{marginTop: "2rem", marginBottom: "2rem"}}>
          <div className="cancel-container">
            <img src={iconBack} alt="back-icon" onClick={goBackButton} />
            <img
              src={iconCancel}
              alt="back-cancel"
              onClick={() => {
                dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: ""});
                dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: {}});
                cancelButton();
              }}
            />
          </div>
        </div>

        <div className="second-container">
          <div className="TT">
            <p style={{fontSize: "1.8rem", color: "#101828", marginBottom: "1.5rem", fontWeight: "600"}}>
              Security Verification
            </p>
            <OptInputField
              titleStyle={{marginBottom: "0.5rem"}}
              tagStyle={{margin: "0rem 0rem 1rem"}}
              tagDescStyle={{margin: "0rem"}}
              value={googleAuthOtp}
              onChange={handleGoogleChange}
              isGoogleOn={true}
              title="Please Verify your Google Auth Code"
              marginTop="1rem"
              marginBottom="1.5rem"
              error={errorMessage ? errorMessage : errorUpdatingPhoneNumber ? errorUpdatingPhoneNumber : ""}
            />
          </div>
        </div>
      </div>

      <div className="footer-modal" style={{margin: "0rem 2.5rem 3rem"}}>
        <div className="button-container">
          <button style={{...buttonStyle, ...cancelButtonStyle}} className="base-button" onClick={cancelButton}>
            Cancel
          </button>
          <button
            className="base-button"
            style={buttonStyle}
            onClick={nextButton}
            disabled={googleAuthOtp.length >= 6 ? false : true}
          >
            {isLoading ? <Loader4 /> : <p style={{color: "white"}}>Confirm</p>}
          </button>
        </div>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  width: "100%",
  height: "40px",
};

const cancelButtonStyle = {
  background: "transparent",
  border: "1px solid gray",
  color: "black",
};

export default memo(GoogleAuthChangeSecondModal);
