import APIs from "./api";

const UploadImageService = {
  upload: async (data) => {
    const result = APIs.post("/api/v1/upload/profile-image-upload", data).then((data) => {
      return data;
    });
    return result;
  },
};

export default UploadImageService;
