import React from "react";
import {memo} from "react";
import "../../styles/walletStyles/walletStyle.scss";
import WalletCoinSection from "./walletCoinSection";
import WalletTopSections from "./WalletTopSections";
import WalletTab from "./walletTab";
import WalletTable_Header from "./walletTable_Header";
import AmountScalling from "./AmountScalling";
import BarChart from "./BarChart";

const WalletComponents = () => {
  return (
    <>
      <div className="walletWrapper">
        <div className="walletWrapper__header">
          <div className="wallet__firstLayoutWrapperDetailsHeader">
            <h3>Wallet Balance</h3>
          </div>

          <div className="walletWrapper__headerWallet">
            <WalletTab />
          </div>
        </div>

        <div className="walletWrapper__bodies">
          <div className="walletWrapper__bodiesFirstSection">
            <div className="walletWrapper__bodiesFirstSection__Section">
              <div className="walletWrapper__bodiesFirstSection__SectionTop">
                <WalletTopSections />
              </div>
              <div className="walletWrapper__bodiesFirstSection__SectionBottom">
                <div className="walletWrapper__bodiesFirstSection__SectionBottomAmount">
                  <AmountScalling />
                </div>
                <div className="walletWrapper__bodiesFirstSection__SectionBottomChart">
                  <BarChart />
                </div>
              </div>
            </div>

            <div className="wallet__secondLayoutWrapper">
              <WalletTable_Header />

              <WalletCoinSection />
            </div>
          </div>

          {/* <div className="wallet__extension">
            <WalletTransactionHistory />

            <WalletAds />
          </div> */}
        </div>
      </div>
    </>
  );
};

export default memo(WalletComponents);
