import {useContext} from "react";
import {TransactionContext} from "../Home/Transaction";
import {TransactionBuySellTable} from "./buySellTransactionTable";
import {TransactionDepositWithdrawTable} from "./depositWithdrawTransactionTable";
import {TransactionConvertTableSection} from "./convertTableSection";

const TransactionTableArea = () => {
  const {transactionType} = useContext(TransactionContext);
  return transactionType === "buysell" ? (
    <TransactionBuySellTable />
  ) : transactionType === "depo" ? (
    <TransactionDepositWithdrawTable />
  ) : (
    <TransactionConvertTableSection />
  );
};

export default TransactionTableArea;
