import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import BalanceService from "../../services/balance";
import GeneralService from "../../services/general";
import SettingsService from "../../services/settingsServices";
import {getAllTokenBalance} from "../../store/actions/balance";
import {getUser} from "../../store/actions/general";
import {getDefaultCurrenctCountry} from "../../store/actions/settingAction";
import {IS_LOADING} from "../../store/types";
import {CountryIcons} from "../Common/CountryIcons";
import SearchableCountryDropDown from "../Common/SearchablePhoneDropdown/CountrySearchDropDown";

const CountrySearchable = () => {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const defaultCurrenctCountry = useSelector((state) => state.settingsReducer.defaultCurrenctCountry);
  const [countryStateValue, setCountryStateValue] = useState({...defaultCurrenctCountry});

  const HandleClickSearch = (item) => {
    dispatch({type: IS_LOADING, payload: true});
    setCountryStateValue({
      ...item,
      name: item.countryName,
    });

    dispatch(
      getDefaultCurrenctCountry({
        ...item,
        name: item.countryName,
      })
    );

    SettingsService.ChangeCountryCurrency({country: item.countryName, currency: item.currencyCode}).then(
      async (data) => {
        if (data === "success") {
          const constants = await Promise.all([GeneralService.getUserData(), BalanceService.localBalance()]).then(
            (res) => {
              return res;
            }
          );
          await dispatch(getUser(constants[0]));
          dispatch(getAllTokenBalance(constants[1]));
          dispatch({type: IS_LOADING, payload: false});
        }
      }
    );
  };

  return (
    <div style={{width: "100%"}}>
      <SearchableCountryDropDown
        title={`For online trade, you need to specify the country`}
        iconsLead={true}
        name={"countryName"}
        nameAcr={"currencyCode"}
        code={"currencyCode"}
        DetailsIcons={CountryIcons}
        search={countryStateValue}
        setSearch={setCountryStateValue}
        placeholder={"Please Search for a country"}
        HandleSelect={HandleClickSearch}
        apiList={countryData?.data?.countriesData}
        // DropdownWidth={DropdownWidth}
        // decoratorWidthWrapper={decoratorWidthWrapper}
      />
    </div>
  );
};

export default memo(CountrySearchable);
