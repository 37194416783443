import {memo, useState} from "react";
import LinkButton from "../linkButton";
import playCircle from "../../../assets/icons/play-circle.svg";
import faqs from "../../../assets/icons/faqs.svg";
import blog from "../../../assets/icons/blog.svg";
// import helpSupport from "../../../assets/icons/helpSupport.svg";
import cancelButton from "../../../assets/icons/icon-cancel.svg";
import "./mobile-side-menu.scss";

const MobileSideMenu = ({setHide}) => {
  const [show, setShow] = useState(false);

  return (
    <div className={"mobileSideMenuContainer"} style={{padding: 0}}>
      <div className={"cancelContainer"}>
        <img
          src={cancelButton}
          alt="cancel-button"
          width={30}
          onClick={() => {
            setHide(false);
          }}
          style={{marginTop: "2rem", marginRight: "2rem"}}
        />
      </div>
      <div style={styleButtonContainer}>
        <LinkButton title="Sign In" link="/login" style={signStyle} />
        <LinkButton title="Sign up" link="/signup" style={{width: "90%", height: "auto", padding: "12px 0px"}} />
      </div>

      {data.map((list, index) => (
        <a
          key={index}
          href={list.path}
          className={"listMenuIcon"}
          without={0}
          rel="noreferrer"
          style={{marginLeft: "2rem"}}
        >
          <div style={firstDropDownStyle}>
            {list.icon}
            {list.title}
          </div>
          <p className="tag">{list.tag}</p>
        </a>
      ))}

      <div className={"listMenuIcon"} style={{marginLeft: "2rem"}}>
        <div style={firstDropDownStyle} onClick={() => setShow(!show)}>
          Learn
        </div>
        {show &&
          innerdata.map((list, index) => (
            <a key={index} href={list.path} className={"listMenuIcon"} without={0} rel="noreferrer">
              <div style={firstDropDownStyle}>
                {list.icon}
                {list.title}
              </div>
              <p className="tag">{list.tag}</p>
            </a>
          ))}
      </div>
      <a href="/about-us" className={"listMenuIcon"} without={0} rel="noreferrer" style={{marginLeft: "2rem"}}>
        <div style={firstDropDownStyle}>About</div>
      </a>
    </div>
  );
};

const styleButtonContainer = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "2rem",
  marginBottom: "2rem",
};

const signStyle = {
  width: "100%",
  height: "auto",
  padding: "12px 0px",
  background: "transparent",
  color: "#1E2329",
};

const firstDropDownStyle = {display: "flex", gap: "1rem", marginBottom: "0.5rem"};

const data = [
  {
    title: "Features",
    path: "#buycrypto",
  },
  {
    title: "Trade",
    path: "#sellcrypto",
  },
];

const innerdata = [
  {
    icon: <img src={playCircle} alt="" />,
    title: "Video tutorials",
    tag: "Get up and running on new features and techniques.",
    path: "/videos",
  },
  {
    icon: <img src={faqs} alt="" width="20px" height="auto" />,
    title: "Frequently asked questions",
    tag: "All the boring stuff that you (hopefully won’t) need.",
    path: "/faq",
  },
  {
    icon: <img src={blog} alt="" width="20px" height="auto" />,
    title: "Blog",
    tag: "Get up and running on new features and techniques.",
    path: "https://medium.com/@oyola",
  },
  {
    icon: <img src={blog} alt="" width="20px" height="auto" />,
    title: "Help and support",
    tag: "Learn, fix a problem, and get answers to your questions.",
    path: "/support",
  },
];

export default memo(MobileSideMenu);
