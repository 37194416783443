import React, {memo} from "react";
import PieChart from "./pieChart";
import "../../styles/overviewStyles/overviewMainTopSection.scss";
import {useSelector} from "react-redux";
import CurrencyFormat from "react-currency-format";

const OverviewMainTopSection = () => {
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);

  return (
    <div className="OverviewMainTopSection">
      <div className="OverviewMainTopSectionFirstLayout">
        <div className="OverviewMainTopSectionFirstLayoutDetails">
          <div className="OverviewMainTopSectionFirstLayoutDetailsContent">
            <p>Estimated Balance</p>
          </div>
          <div className="OverviewMainTopSectionFirstLayoutDetailsTransactions">
            <p className="OverviewMainTopSectionFirstLayoutDetailsTransactionsOne">
              <CurrencyFormat
                value={`${userWalletBalance ? userWalletBalance?.totalValue : 0}`}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale
                // prefix={"$"}
                suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
              />
            </p>
            <p className="OverviewMainTopSectionFirstLayoutDetailsTransactionsTwo">
              <CurrencyFormat
                value={`${userWalletBalance ? userWalletBalance?.userValueInBTC : 0}`}
                displayType={"text"}
                decimalScale={6}
                fixedDecimalScale
                // prefix={"$"}
                suffix={` BTC`}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="OverviewMainTopSectionSecondLayout">
        <PieChart />
      </div>
    </div>
  );
};

export default memo(OverviewMainTopSection);
