import {useEffect} from "react";
import {isIOS} from "react-device-detect";

const MobileDownload = () => {
  useEffect(() => {
    let platform = isIOS
      ? "https://apps.apple.com/ng/app/oyola/id6470178672?platform=iphone"
      : "https://play.google.com/store/apps/details?id=com.myoyolamobile.app";

    window.location.href = platform;
  }, []);
};

export default MobileDownload;
