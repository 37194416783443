import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import "../../../styles/pagination.scss";
import {Loader5} from "../Loader";

const TablePaginationComponent = ({onPrev, onNext, skip, lengthChecker, dataCheck, limit, loading}) => {
  const {width} = useWindowDimensions();
  return width <= 650 ? (
    <div className="__mobile__pagination">
      <div
        className="__left"
        onClick={() => onPrev()}
        style={{color: `${dataCheck?.length > 0 && skip < 1 ? "rgba(52, 64, 84, 0.2)" : "#344054"}`}}
      >
        <AiOutlineArrowLeft size={20} />
      </div>
      <span className="__current">Page {skip + 1}</span>

      {loading ? (
        <div
          className="__right"
          style={{
            color: `${
              lengthChecker?.length < limit || lengthChecker?.length === 0 ? "rgba(52, 64, 84, 0.2)" : "#344054"
            }`,
          }}
        >
          <Loader5 />
        </div>
      ) : (
        <div
          className="__right"
          onClick={() => onNext()}
          style={{
            color: `${
              lengthChecker?.length < limit || lengthChecker?.length === 0 ? "rgba(52, 64, 84, 0.2)" : "#344054"
            }`,
          }}
        >
          <AiOutlineArrowRight size={20} />
        </div>
      )}
    </div>
  ) : (
    <div className="newTable__paginationWrapper" style={{padding: "0 3.7% 0 10px"}}>
      <div className="newTable__paginationNumber">Page {skip + 1}</div>
      <div className="newTable__paginationNavigation">
        <button
          className="newTable__paginationNavigation__button"
          onClick={() => onPrev()}
          disabled={dataCheck?.length >= 0 && skip < 1 ? true : false}
        >
          Previous
        </button>

        {loading ? (
          <button className="newTable__paginationNavigation__button" disabled={loading === true ? true : false}>
            <Loader5 />
          </button>
        ) : (
          <button
            className="newTable__paginationNavigation__button"
            onClick={() => onNext()}
            disabled={lengthChecker?.length < limit || lengthChecker?.length === 0 ? true : false}
          >
            Next
          </button>
        )}
      </div>
    </div>
  );
};

export {TablePaginationComponent};
