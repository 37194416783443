import {
  CHECK_OUT_DATA_INITIAL,
  DEFAULT_CHECKOUT_STATE,
  GET_CHECK_OUT_DATA,
  GET_CHECK_OUT_ERROR,
  ON_CHANGE_CURRENCY,
  ON_CHANGE_TOKEN,
  ON_CHANGE_TOKEN_MAX,
} from "../types";

const initialState = {
  response: null,
  loading: false,
  error: null,
  currencyPrice: "",
  tokenPrice: "",
  unitPrice: 0,
  // fees: 0,
  // totalIncludingFees: 0,
};

const checkOutDataReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CHECK_OUT_DATA_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case GET_CHECK_OUT_DATA: {
      return {
        ...state,
        loading: false,
        response: payload,
        unitPrice: payload?.checkOutData?.unitPrice ?? 0,
      };
    }
    case GET_CHECK_OUT_ERROR: {
      return {
        ...state,
        loading: false,
        error: payload,
      };
    }
    case ON_CHANGE_CURRENCY: {
      const getPayload = payload.split(",").join("");

      const price = Number(getPayload) / Number(state?.unitPrice ?? 0);
      // const price = Number(payload) / Number(98876877.773);

      return {
        ...state,
        currencyPrice: payload,
        tokenPrice: price,
      };
    }

    case ON_CHANGE_TOKEN: {
      return {
        ...state,
        tokenPrice: payload,
        currencyPrice: Number(payload?.split(",").join("")) * Number(state?.unitPrice ?? 0),
      };
    }

    case ON_CHANGE_TOKEN_MAX: {
      return {
        ...state,
        tokenPrice: Number(payload?.split(",").join("")),
        currencyPrice: Number(payload?.split(",").join("")) * Number(state?.unitPrice ?? 0),
      };
    }

    case DEFAULT_CHECKOUT_STATE: {
      return {
        ...state,
        ...initialState,
      };
    }

    default: {
      return state;
    }
  }
};

export default checkOutDataReducer;
