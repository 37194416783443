import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {disputeTradeByIDAction} from "../../../store/actions/createTradeAction";
import {Loader} from "../../Common/Loader";

const DisputeModal = ({setOpenModal}) => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const [checkValue, setCheckValue] = useState(false);
  const [reason, setReason] = useState();
  const [checkOption, setCheckOption] = useState({unresponsive: false, paymentIssue: false, other: false});
  const handleDispute = () => {
    setOpenModal(false);
    dispatch(
      disputeTradeByIDAction({
        trade_id: id,
        reason: reason,
      })
    );
  };
  const userType = useGetUserType(response ?? {});

  return (
    <div className="__createTrade__sell__modal__wrapper">
      {false ? (
        <Loader style={{height: "280px"}} />
      ) : (
        <>
          <div className="__warning__holder">
            <h5>
              Select Reason for Dispute{" "}
              {(userType === "user" && response?.type === "buy") || (userType === "trader" && response?.type === "sell")
                ? "Buyer"
                : ((userType === "user" && response?.type === "sell") ||
                    (userType === "trader" && response?.type === "buy")) &&
                  "Seller"}
            </h5>
          </div>

          <p className="__are__you__sure __dis">
            The reason for dispute is visible to both parties. Unfounded dispute can result to blocking of your account
          </p>

          <span className="__click_to__accept __dis">What is the reason for Dispute?</span>
          {((userType === "user" && response?.type === "buy") ||
            (userType === "trader" && response?.type === "sell")) && (
            <>
              <div className="check__wrapper">
                <input
                  checked={checkOption.unresponsive}
                  type={"checkbox"}
                  onChange={(e) => {
                    setCheckOption((prev) => ({unresponsive: e.target.checked, other: false, paymentIssue: false}));
                    setReason("Unresponsive Vendor: you have paid, but the Seller is unresponsive and inactive.");
                  }}
                />
                <p className="__terms">
                  Unresponsive Vendor: you have paid, but the Seller is unresponsive and inactive.
                </p>
              </div>
              <div className="check__wrapper">
                <input
                  checked={checkOption.paymentIssue}
                  type={"checkbox"}
                  onChange={(e) => {
                    setCheckOption({unresponsive: false, other: false, paymentIssue: e.target.checked});
                    setReason(
                      "Payment issue: you have made the payment, but the Seller claims there are issues with the payment and refuses to release the Digital Assets."
                    );
                  }}
                />
                <p className="__terms">
                  Payment issue: you have made the payment, but the Seller claims there are issues with the payment and
                  refuses to release the Digital Assets.
                </p>
              </div>
              <div className="check__wrapper">
                <input
                  type={"checkbox"}
                  checked={checkOption.other}
                  onChange={(e) => {
                    setCheckOption((prev) => ({unresponsive: false, other: e.target.checked, paymentIssue: false}));
                  }}
                />
                <p className="__terms">
                  Other: an open option where you can describe what issue gave rise to the dispute. The Seller will be
                  able to view your description.
                </p>
              </div>
            </>
          )}
          {((userType === "user" && response?.type === "sell") ||
            (userType === "trader" && response?.type === "buy")) && (
            <>
              <div className="check__wrapper">
                <input
                  checked={checkOption.unresponsive}
                  type={"checkbox"}
                  onChange={(e) => {
                    setCheckOption((prev) => ({unresponsive: e.target.checked, other: false, paymentIssue: false}));
                    setReason(
                      "Unresponsive Buyer: The Buyer has marked the transaction as fully paid but is unresponsive and inactive."
                    );
                  }}
                />
                <p className="__terms">
                  Unresponsive Buyer: The Buyer has marked the transaction as fully paid but is unresponsive and
                  inactive.
                </p>
              </div>
              <div className="check__wrapper">
                <input
                  checked={checkOption.paymentIssue}
                  type={"checkbox"}
                  onChange={(e) => {
                    setCheckOption({unresponsive: false, other: false, paymentIssue: e.target.checked});
                    setReason(
                      "Payment issue: the Buyer is active and has attempted to pay, but there are issues with the payment."
                    );
                  }}
                />
                <p className="__terms">
                  Payment issue: the Buyer is active and has attempted to pay, but there are issues with the payment.
                </p>
              </div>
              <div className="check__wrapper">
                <input
                  type={"checkbox"}
                  checked={checkOption.other}
                  onChange={(e) =>
                    setCheckOption((prev) => ({unresponsive: false, other: e.target.checked, paymentIssue: false}))
                  }
                />
                <p className="__terms">
                  Other: an open option where you can describe what issue gave rise to the dispute. The Buyer will be
                  able to view your description.
                </p>
              </div>
            </>
          )}
          {checkOption.other && (
            <div className="check__wrapper __dis" style={{alignItems: "center"}}>
              {/* <p className="__terms __dis">Description (Compulsory)</p> */}
              <div className="__textareawrapper">
                <textarea
                  rows={5}
                  onChange={(e) => {
                    setCheckValue(e.target.value);
                    setReason(e.target.value);
                  }}
                  placeholder="Please tell us the reason for dispute"
                />
                <span>{checkValue ? checkValue.length : 0}/120</span>
              </div>
              {checkValue && checkValue.length > 120 && (
                <span className="__warning__text">not more than 120 characters</span>
              )}
            </div>
          )}
          <div className="__modal__button__wrapper">
            <button className="__cancel" onClick={() => setOpenModal(false)}>
              Cancel
            </button>
            <button
              className={`__confirm ${
                ((checkOption.other && checkValue.length <= 120 && checkValue.length > 1) ||
                  checkOption.paymentIssue ||
                  checkOption.unresponsive) &&
                "accept"
              }`}
              disabled={
                !(
                  (checkOption.other && checkValue.length <= 120 && checkValue.length > 0) ||
                  checkOption.paymentIssue ||
                  checkOption.unresponsive
                )
              }
              onClick={handleDispute}
            >
              Confirm
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export {DisputeModal};
