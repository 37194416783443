import React, {useCallback, useEffect} from "react";
// import "../../styles/button.scss";
import "../../styles/new/settings.scss";
import {AiOutlineStop} from "react-icons/ai";
import {MdArrowForwardIos} from "react-icons/md";
import {useDispatch, useSelector} from "react-redux";
import {listOfBlockedUsers} from "../../store/actions/profile";
import AuthService from "../../services/auth";
import ModalInfo from "./modalInfo";
import {settingVerifyModal} from "../../store/actions/settingAction";
import {toast} from "react-toastify";

const ListBlockUsers = () => {
  const dispatch = useDispatch();

  // Check userdata & locationData in redux store
  const listOfBlockUsers = useSelector((state) => state.settingsReducer.listOfBlockUsers);
  const {userData} = useSelector((state) => state.generalReducer);
  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  // const profileDataFromRedux = useSelector((state) => state.profileReducer.listBlockedUsers);

  const fetchProfileData = useCallback(() => {
    const payload = {
      username,
    };

    if (username !== "") {
      AuthService.profileData(payload)
        .then((data) => {
          dispatch(listOfBlockedUsers(data.userData));
        })
        .catch(() => {
          toast.error("Something went wrong");
        });
    }
  }, [username, dispatch]);

  useEffect(() => {
    fetchProfileData();
  }, [userData, fetchProfileData]);

  function nextButton() {
    dispatch(settingVerifyModal(5));
  }

  return (
    <section className="list-block-container">
      <div className="left-container">
        <h1 className="title">Blocked Marketplace merchants</h1>
        <p className="tag" style={{fontSize: "1.4rem", color: "#667085"}}>
          View blocked profiles
        </p>
      </div>

      <div className="right-container" onClick={nextButton}>
        <div className="inner-container">
          <div className="icons-container">
            <AiOutlineStop fontSize={"1.8rem"} />

            <span className="icon-title-container">
              <p style={{fontSize: "1.4rem"}}>Blocked</p>
              <p style={{fontSize: "1.4rem"}}>{listOfBlockUsers?.length}</p>
            </span>
          </div>

          <span>
            <MdArrowForwardIos fontSize={"2.0rem"} />
          </span>
        </div>
      </div>
    </section>
  );
};

export default ListBlockUsers;
