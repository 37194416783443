import {LOCAL_TOKEN_BALANCE, SIGNLE_LOCAL_TOKEN_BALANCE} from "../types";

const initialState = {
  balance: [],
  singleBalance: 0,
};

const balanceReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case LOCAL_TOKEN_BALANCE: {
      return {
        ...state,
        balance: payload,
      };
    }
    case SIGNLE_LOCAL_TOKEN_BALANCE: {
      return {
        ...state,
        singleBalance: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default balanceReducer;
