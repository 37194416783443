import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import GeneralService from "../../services/general";
import SettingsService from "../../services/settingsServices";
import {getUser} from "../../store/actions/general";
import {getSettingLoaderSkeleton, getUseGoogleAuth, getUsePhoneNumber} from "../../store/actions/settingAction";
// import {USEGOOGLEAUTH} from "../../store/types";
import CustomCheckbox from "../Common/CustomCheckbox";

const UseGoogleAuth = () => {
  const dispatch = useDispatch();
  const userData = useSelector((state) => state.generalReducer.userData);
  const userDetails = userData?.data?.userData;

  const useGoogleAuth = useSelector((state) => state.settingsReducer.useGoogleAuth);

  const handleChangeBuy = (e) => {
    const {checked} = e.target;

    if (userDetails?.googleAuthSetup) {
      dispatch(getSettingLoaderSkeleton(true));

      if (checked === false) {
        dispatch(getUseGoogleAuth({name: "google auth", checker: checked}));
        dispatch(getUsePhoneNumber({name: "sms", checker: true}));

        SettingsService.AuthPreference({type: "sms"}).then((data) => {
          if (data === "success") {
            GeneralService.getUserData().then((data) => {
              dispatch(getUser(data));
              dispatch(getSettingLoaderSkeleton(false));
            });
          }
        });

        return;
      }

      if (checked === true) {
        dispatch(getUseGoogleAuth({name: "google auth", checker: checked}));
        dispatch(getUsePhoneNumber({name: "sms", checker: false}));

        SettingsService.AuthPreference({type: "google auth"}).then((data) => {
          if (data === "success") {
            GeneralService.getUserData().then((data) => {
              dispatch(getUser(data));
              dispatch(getSettingLoaderSkeleton(false));
            });
          }
        });

        return;
      }
    }
  };

  return (
    <div>
      <CustomCheckbox
        handleChange={handleChangeBuy}
        state={userDetails?.googleAuthSetup ? useGoogleAuth : {name: "google auth", checker: false}}
      />
    </div>
  );
};

export default memo(UseGoogleAuth);
