import React, {createContext, useState} from "react";
import TradeOrderTop from "../TradeOrders/topArea";
import TradeOrderTable from "../TradeOrders/tradeOrderTable";

export const TradeOrderContext = createContext();
const TradeOrders = () => {
  const [tradeOrder, setTradeOrder] = useState("active");

  return (
    <React.Fragment>
      <TradeOrderContext.Provider value={{tradeOrder, setTradeOrder}}>
        <TradeOrderTop />
        <TradeOrderTable />
      </TradeOrderContext.Provider>
    </React.Fragment>
  );
};

export default TradeOrders;
