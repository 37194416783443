import React, {memo} from "react";
import ForgotForm from "../components/Forgot/ForgotForm";
import ForgotOTP from "../components/Forgot/ForgotOTP";
import {Loader} from "../components/Common/Loader";
import {useSelector} from "react-redux";

import ForgotOTPPhoneNumber from "../components/Forgot/ForgotOTPPhoneNumber";
import GoogleOTP from "../components/Forgot/GoogleOTP";
import {Navbar} from "../components/Common/nav-bar/nav-bar.component";

const ForgotPassword = () => {
  const showOTP = useSelector((state) => state.authReducer.forgotPasswordloadingState);

  return (
    <React.Fragment>
      <>
        <Navbar />
        {showOTP === 0 && <ForgotForm />}
      </>

      {showOTP === 1 && (
        <>
          {/* <Navbar /> */}
          <Loader />
        </>
      )}

      {showOTP === 2 && <ForgotOTP />}
      {showOTP === 3 && <ForgotOTPPhoneNumber />}
      {showOTP === 4 && <GoogleOTP />}
    </React.Fragment>
  );
};

export default memo(ForgotPassword);
