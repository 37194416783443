import React from "react";
import {FaRegPlayCircle} from "react-icons/fa";
import "./faq.videos.scss";
import thumbnail from "../../../assets/images/video-player.svg";

const FaqVideos = ({videoItem, handleVideo}) => {
  return (
    <div className="faqVideosWrapper">
      <div className="faqVideosWrapperFrame" onClick={() => handleVideo(videoItem.videoLink)}>
        {videoItem.videoLink.includes("https") ? (
          <iframe
            width="560"
            height="315"
            src={`${videoItem.videoLink}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
            className="faqVideosWrapperFrameItem"
          ></iframe>
        ) : (
          <img src={thumbnail} className="faqVideosWrapperFrameItem" alt="thumbnail" />
        )}
        <div className="faqVideosWrapperFrameBorder"></div>
      </div>
      <div className="faqVideosWrapperBody">
        <div className="faqVideosWrapperBodyDetails">
          <p className="faqVideosWrapperBodyDetailsHeader">{videoItem.title}</p>
          <p className="faqVideosWrapperBodyDetailsBody">{videoItem.description}</p>
        </div>
        <div className="faqVideosWrapperBodyVideos" onClick={() => handleVideo(videoItem.videoLink)}>
          <div className="faqVideosWrapperBodyVideosIconBody">
            <FaRegPlayCircle className="faqVideosWrapperBodyVideosIcon" />
          </div>
          <div className="faqVideosWrapperBodyVideosDetials">
            <p className="faqVideosWrapperBodyVideosDetialsContent">Watch video</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FaqVideos;
