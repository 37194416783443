import TheTradeOrderTable from "./table";

const TradeOrderTable = () => {
  return (
    <div style={{paddingBottom: "10px"}}>
      <TheTradeOrderTable />
    </div>
  );
};

export default TradeOrderTable;
