import React, {useRef, useState} from "react";

import {BsCaretDownFill} from "react-icons/bs";
import {useDispatch, useSelector} from "react-redux";
import SyncSpinnerLoader from "../Common/spinnerLoader";

import {
  getConvertBankAccChecker,
  getConvertBankDetails,
  getConvertBankDropdown,
  getConvertConfirmAccDetails,
  getConvertConvertionRate,
  getConvertLevel,
  getConvertMinLoader,
  getConvertTransferDetails,
} from "../../store/actions/convertAction";

import {toast} from "react-toastify";
import ConvertServices from "../../services/Convert";

import convertSearch from "../../assets/icons/convertSearch.png";
import useOnClickOutside from "../../hooks/useOnClickOutside";

import "../../styles/convert/convert_bank.scss";
import {Loader4} from "../Common/Loader";

const ConvertBank = () => {
  const dropRef = useRef();

  const dispatch = useDispatch();
  const [searchedData, setSearchedData] = useState();
  const [query, setQuery] = useState("");

  const [loading, setLoading] = useState(false);

  useOnClickOutside(dropRef, () => dispatch(getConvertBankDropdown(false)));

  const {
    convertBankDropdown,
    convertBackAccChecker,
    convertBankList,
    convertBankDetails,
    convertMinLoader,
    convertConfirmAccDetails,
    convertDropdownContent,
    convertTokenInput,
    convertConvertionNormal,
  } = useSelector((state) => state.convertReducer);

  const [error, setError] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const constant = await ConvertServices?.getConversionRates(
        convertConvertionNormal?.finalAmount,
        convertDropdownContent?.tokenSymbol
      );

      await dispatch(getConvertConvertionRate(constant));
      dispatch(getConvertLevel(2));
      setLoading(true);
    } catch (err) {
      setLoading(true);
      toast(err?.message);
    }
  };

  const handleChange = (e) => {
    const value = e.target.value;
    setError("");
    if (value.length <= 10) {
      dispatch(getConvertBankAccChecker(value));

      dispatch(getConvertConfirmAccDetails({}));

      if (value?.length > 9) {
        dispatch(getConvertMinLoader(true));
        ConvertServices.validateBankDetails({account_bank: convertBankDetails?.code, account_number: value})
          .then((data) => {
            dispatch(getConvertConfirmAccDetails(data?.response));
            dispatch(getConvertMinLoader(false));
          })
          .catch((err) => {
            setError("Invalid account number");
            dispatch(getConvertConfirmAccDetails({}));
            toast(err.message);
            dispatch(getConvertMinLoader(false));
          });
      }
    }
  };

  const handleNext = () => {
    const payload = {
      tokenSymbol: convertDropdownContent?.tokenSymbol,
      amount: convertTokenInput,
      account_number: convertConfirmAccDetails?.account_number,
      account_bank: convertBankDetails?.code,
    };

    dispatch(getConvertTransferDetails(payload));

    fetchData();
  };

  const handleSearchForBank = (e) => {
    const {value} = e.target;
    setQuery(value);
    const searchForBank = convertBankList?.filter((item) => item?.name?.toLowerCase().includes(value?.toLowerCase()));

    setSearchedData(searchForBank);
  };

  const handleSelectBanks = (item) => {
    dispatch(getConvertBankDetails(item));
    dispatch(getConvertBankDropdown(!convertBankDropdown));
  };

  return (
    <div className="convertBank">
      <div className="ConvertComponentCardHeader">
        <p className="ConvertComponentCardHeaderTitleStyle">Set payment method</p>
      </div>
      <div className="convertBank_content">
        <p className="convertBank_content_explain">
          Please ensure that the receiving bank is accessible and can be contacted for confirmation.
        </p>

        <div className="convertBank_content_form">
          <div className="convertBank_content_form_bankname">
            <p className="convertBank_content_form_bankname_name">Bank Name</p>
            <div
              className="convertBank_content_form_bankname_bank"
              onClick={() => dispatch(getConvertBankDropdown(!convertBankDropdown))}
            >
              <p className="convertBank_content_form_bankname_bank_title">
                {convertBankDetails?.name ? convertBankDetails?.name : "Select a Bank"}
              </p>
              <div className="convertBank_content_form_bankname_bank_iconbody">
                <BsCaretDownFill className="convertBank_content_form_bankname_bank_icon" />
              </div>
            </div>
            {convertBankDropdown && (
              <div className="convert_form_details_body_wrapper_container_icon_dropdown" ref={dropRef}>
                <div className="convert_form_details_body_wrapper_container_icon_dropdown_contatiner">
                  {/* <p className="convert_form_details_body_wrapper_container_icon_dropdown_title">Se</p> */}
                  {/* <p>search</p> */}
                  <img src={convertSearch} className="convertSearchIconStyle" alt="search icon" />
                  <input
                    placeholder="Search"
                    // value={query | ""}
                    onChange={handleSearchForBank}
                    className="convert_form_details_body_wrapper_container_icon_dropdown_title_input"
                  />
                </div>

                <div className="convert_form_details_body_wrapper_container_icon_dropdown_content_container">
                  {query !== "" && query !== null
                    ? searchedData?.map((item, key) => (
                        <>
                          <div
                            key={key}
                            onClick={() => handleSelectBanks(item)}
                            className="convert_form_details_body_wrapper_container_icon_dropdown_content"
                          >
                            <p className="convert_form_details_body_wrapper_container_icon_dropdown_content_token">
                              {item?.name}
                            </p>
                          </div>
                        </>
                      ))
                    : convertBankList?.map((item, key) => (
                        <>
                          <div
                            key={key}
                            onClick={() => handleSelectBanks(item)}
                            className="convert_form_details_body_wrapper_container_icon_dropdown_content"
                          >
                            <p className="convert_form_details_body_wrapper_container_icon_dropdown_content_token">
                              {item?.name}
                            </p>
                          </div>
                        </>
                      ))}
                </div>
              </div>
            )}
          </div>
          <div className="convertBank_content_form_accnumber">
            <p className="convertBank_content_form_accnumber_title">Bank Account Number</p>
            <div className="convertBank_content_form_accnumber_content_form">
              <div className="convertBank_content_form_accnumber_content">
                <input
                  className="convertBank_content_form_accnumber_content_input"
                  value={convertBackAccChecker || ""}
                  onChange={handleChange}
                  maxLength={10}
                />
              </div>

              {convertMinLoader ? (
                <div className="convertBank_content_form_accnumber_loader">
                  <SyncSpinnerLoader />
                </div>
              ) : (
                Object.keys(convertConfirmAccDetails).length > 0 && (
                  <p className="convertBank_content_form_accnumber_texted">{convertConfirmAccDetails?.account_name}</p>
                )
              )}
            </div>
            {error ? <p className="convertBank_content_form_accnumber_texted_error">{error}</p> : null}
          </div>
          <div className="convertBank_content_form_button">
            <button
              className="convertBank_content_form_button_one button_one"
              onClick={() => dispatch(getConvertLevel(0))}
            >
              Back
            </button>
            <button
              className="convertBank_content_form_button_one button_two"
              onClick={handleNext}
              disabled={
                Object.keys(convertConfirmAccDetails).length === 0 || Object.keys(convertBankDetails).length === 0
                  ? loading === false
                  : loading === true
              }
            >
              {loading ? <Loader4 /> : "Confirm"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConvertBank;
