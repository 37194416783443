import {FormInput} from "../Common/FormInput";
import iconCancel from "../../assets/icons/icon-cancel.svg";
import React, {memo, useState} from "react";
import {NAME_REGEX} from "../../utils/Validators";
import {useDispatch, useSelector} from "react-redux";
import {
  modalInformationDetails,
  settingVerifyModal,
  validAddressFirstNameAction,
  validAddressLastNameAction,
  validAddressMiddleNameAction,
} from "../../store/actions/settingAction";
import {DisplayNameError} from "../../utils/displayErrors";

const PersonalInformationModal = () => {
  // toggle to next modal

  const dispatch = useDispatch();

  const userInfo = useSelector((state) => state.generalReducer.userData);
  const modalInfo = useSelector((state) => state.settingsReducer.settingsModalInfo);
  const {validAddressFirstName, validAddressLastName, validAddressMiddleName} = useSelector(
    (state) => state.settingsReducer
  );

  const [firstNameFocus, setFirstNameFocus] = useState(false);
  const [lastNameFocus, setLastNameFocus] = useState(false);
  const [middleNameFocus, setMiddleNameFocus] = useState(false);

  // const [returnUser, setReturnUser] = useState(false);

  const {firstName, lastName, middleName} = modalInfo ?? "";

  const validateCredentials = (e) => {
    // setReturnUser(true);
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "firstName": {
        // dispatch({type: VALIDFIRSTNAME, payload: NAME_REGEX.test(e.target.value)});
        dispatch(validAddressFirstNameAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, firstName: e.target.value}));
        // setComeBack(NAME_REGEX.test(e.target.value));
        break;
      }

      case "lastName": {
        dispatch(validAddressLastNameAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, lastName: e.target.value}));
        break;
      }

      case "middleName": {
        dispatch(validAddressMiddleNameAction(NAME_REGEX.test(e.target.value)));
        dispatch(modalInformationDetails({...modalInfo, middleName: e.target.value}));

        break;
      }

      default: {
        break;
      }
    }
  };

  function cancelButton() {
    dispatch(settingVerifyModal(null));
    dispatch(modalInformationDetails({}));
  }

  function nextButton() {
    dispatch(settingVerifyModal(1));
  }

  return (
    <main className="modal-container" data-aos="zoom-in-down" data-aos-easing="linear" data-aos-duration="100">
      <div className="body">
        <div className="top-section-modal-body">
          <div className="cancel-container" style={{justifyContent: "flex-end"}}>
            <img src={iconCancel} alt="back-cancel" onClick={cancelButton} />
          </div>
          <p className="title">Personal Information</p>
          <p className="tag">Please make sure information provided here matches your valid ID.</p>
          <p className="tag-detail">Identity Information</p>
        </div>

        <div className="first-container">
          <div>
            <FormInput
              id="firstName"
              label={"First Name"}
              place={"Enter first name"}
              type={"text"}
              className="input-style"
              value={firstName || ""}
              handler={validateCredentials}
              onFocus={() => setFirstNameFocus(true)}
              onBlur={() => setFirstNameFocus(false)}
            />
            <DisplayNameError
              emailFocus={firstNameFocus}
              email={modalInfo.firstName}
              validEmail={validAddressFirstName}
              message="Enter a valid first "
            />
          </div>

          <div>
            <FormInput
              className="input-style"
              id="lastName"
              label={"Last Name"}
              place={"Enter last name"}
              type={"text"}
              value={lastName || ""}
              handler={validateCredentials}
              onFocus={() => setLastNameFocus(true)}
              onBlur={() => setLastNameFocus(false)}
            />
            <DisplayNameError
              emailFocus={lastNameFocus}
              email={modalInfo.lastName}
              validEmail={validAddressLastName}
              message="Enter a valid last name"
            />
          </div>
        </div>
        <div className="second-container">
          <div className="TT">
            <FormInput
              id="middleName"
              value={middleName || ""}
              label={"Other name"}
              place={"Enter other name"}
              type={"text"}
              handler={validateCredentials}
              onFocus={() => setMiddleNameFocus(true)}
              onBlur={() => setMiddleNameFocus(false)}
            />
            <DisplayNameError
              emailFocus={middleNameFocus}
              email={modalInfo.middleName}
              validEmail={validAddressMiddleName}
              message="Enter a valid other name"
            />
          </div>
        </div>
      </div>

      <div className="footer-modal">
        <div className="button-container">
          <button style={cancelStyle} className="base-button" onClick={cancelButton}>
            Cancel
          </button>
          {userInfo?.data?.userData.hasOwnProperty("firstName") && userInfo?.data?.userData?.firstName !== null ? (
            <button
              className="base-button"
              style={{
                ...buttonStyle,
                width: "210px",
              }}
              onClick={nextButton}
              disabled={!validAddressFirstName || !validAddressLastName ? true : false}
            >
              Continue
            </button>
          ) : (
            <button
              className="base-button"
              style={{
                ...buttonStyle,
                width: "210px",
              }}
              onClick={nextButton}
              disabled={!validAddressFirstName || !validAddressLastName ? true : false}
            >
              Continue
            </button>
          )}
        </div>
        <div>
          <p className="title">
            This information is used for personal verification only and its is kept confidental by Oyola.
          </p>
        </div>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
};
const cancelStyle = {
  ...buttonStyle,
  background: "transparent",
  border: "1px solid gray",
  color: "black",
  width: "210px",
};

export default memo(PersonalInformationModal);
