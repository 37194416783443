import {memo} from "react";
import Tab from "./Tabs";
import "../../styles/profile.scss";
import {useDispatch} from "react-redux";
import {saveOtherUserNameAction} from "../../store/actions/profile";

const Reviews = ({username}) => {
  const dispatch = useDispatch();
  dispatch(saveOtherUserNameAction({username}));

  return (
    <div className="personal-info-container">
      <p className="textStyle">Reviews</p>

      <div className="card-container">
        <div className="card-inner-container">
          <p className="text">Trade reviews</p>
          <Tab username={username} />
        </div>
      </div>
    </div>
  );
};

export default memo(Reviews);
