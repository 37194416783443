import React, {memo} from "react";
import NetworkError from "../../assets/images/opps 1.svg";
import "../../styles/oop/oopImageStyles.scss";

const ImageSection = () => {
  return (
    <div className="oopImageSection">
      <img src={NetworkError} alt={"all"} />
    </div>
  );
};

export default memo(ImageSection);
