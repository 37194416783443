import React from "react";
import {memo} from "react";
import {AiOutlineInfoCircle} from "react-icons/ai";
import "../../styles/DeleteCard.scss";

const DeleteComponent = ({onclick, closedelete}) => {
  return (
    <div className="deleteCardWrapper">
      <div className="delete__firstLayout">
        <div className="delete__firstLayoutIconBodyContainer">
          <div className="delete__firstLayoutIconBody">
            <AiOutlineInfoCircle className="delete__firstLayoutIcon" />
          </div>
        </div>
      </div>
      <div className="delete__secondLayout">
        <h3>Do you want to Delete this Trade</h3>
        <p>Are you sure you want to block this account?</p>
      </div>
      <div className="delete__thirdLayout">
        <button className="delete__thirdLayoutCancel" onClick={() => closedelete(false)}>
          Cancel Delete
        </button>
        <button className="delete__thirdLayoutDelete" onClick={onclick}>
          Confirm Delete
        </button>
      </div>
    </div>
  );
};

export default memo(DeleteComponent);
