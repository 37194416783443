import React from "react";
import {BNB, BTC, ETH, NOTFOUND, USDT} from "../../../../hooks/imagePath";
import CurrencyFormat from "react-currency-format";
import {TablePaginationComponent} from "../../../Common/pagination";
import {Link, useHistory} from "react-router-dom";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import moment from "moment";

const data = [
  {
    tokenSymbol: "USDT",
    amount: "12",
    currency: "NGN",
    rate: 12000,
    price: "889.23",
    timeStampt: "2023-11-30  12:08:12",
    status: "Completed",
  },
  {
    tokenSymbol: "USDT",
    amount: "12",
    currency: "NGN",
    rate: 12000,
    price: "889.23",
    timeStampt: "2023-11-30  12:08:12",
    status: "Pending",
  },
  {
    tokenSymbol: "USDT",
    amount: "12",
    currency: "NGN",
    rate: 12000,
    price: "889.23",
    timeStampt: "2023-11-30  12:08:12",
    status: "Completed",
  },
  {
    tokenSymbol: "USDT",
    amount: "12",
    currency: "NGN",
    rate: 12000,
    price: "889.23",
    timeStampt: "2023-11-30  12:08:12",
    status: "Pending",
  },
];

const ConvertDesktopTable = () => {
  const {push} = useHistory();

  return (
    <>
      <div className="table__wrapper">
        {/* {loading ? (
          <Loader />
        ) : ( */}
        <>
          <table className="tablew">
            <thead className="newTable__thead">
              <tr className="newTable__tr">
                <th className="newTable__thh">Coin Type</th>
                <th className="newTable__thh">Total</th>
                <th className="newTable__thh">Price</th>
                <th className="newTable__thh">Receive</th>
                <th className="newTable__thh">Date</th>
                <th className="newTable__thh">Status</th>
                <th className="newTable__thh">View More</th>
              </tr>
            </thead>

            <tbody className="newTable__tbody">
              {data &&
                data?.length > 0 &&
                data.map((item, i) => {
                  return (
                    <tr className="myTable" style={{height: "40px"}} key={i}>
                      <td className="tableItemm">
                        <div className="transactionTableArea__coinWrapper">
                          <img
                            src={
                              item?.tokenSymbol === "USDT"
                                ? USDT
                                : item?.tokenSymbol === "BTC"
                                ? BTC
                                : item?.tokenSymbol === "ETH"
                                ? ETH
                                : BNB
                            }
                            alt="usdt icon"
                            className="transactionTableArea__coinImage"
                          />
                          <span className="transactionTableArea__coin">{item?.tokenSymbol}</span>
                        </div>
                      </td>
                      <td className="tableItemm">
                        <div className="transactionTableArea__typeWrapper">
                          <span className="transactionTableArea__type">{`${item?.amount} ${item?.tokenSymbol}`}</span>
                        </div>
                      </td>
                      <td className="tableItemm">
                        <div className="transactionTableArea__walletWrappers">
                          <span className="transactionTableArea__wallet">
                            <CurrencyFormat
                              value={item?.price}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={` ${item?.tokenSymbol}/${item?.currency}`}
                            />
                          </span>
                        </div>
                      </td>
                      <td className="tableItemm">
                        <div className="transactionTableArea__walletWrappers">
                          <span className="transactionTableArea__wallet">
                            <CurrencyFormat
                              value={item?.rate}
                              displayType={"text"}
                              thousandSeparator={true}
                              decimalScale={2}
                              fixedDecimalScale
                              suffix={` ${item?.currency}`}
                            />
                          </span>
                        </div>
                      </td>

                      <td className="tableItemm">
                        <div className="transactionTableArea__walletWrappers">
                          <span className="transactionTableArea__wallet">
                            {moment(item?.timeStampt).format("yyyy-MM-DD LT")}
                          </span>
                        </div>
                      </td>

                      <td className="tableItemm">
                        <div className="transactionTableArea__statusWrappers">
                          <span
                            className={`transactionTableArea__status ${
                              item?.status === "cancelled"
                                ? "__cancelled"
                                : item?.status === "pending"
                                ? "__pending"
                                : "__complete"
                            }`}
                          >
                            {capitalizeFirstLetter(item?.status)}
                          </span>
                        </div>
                      </td>
                      <td className="tableItemm">
                        <div className="transactionTableArea__viewMoreWrappers">
                          <span
                            className="transactionTableArea__viewMore"
                            onClick={() => {
                              // setModalData(value);
                            }}
                          >
                            view trade
                          </span>
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </>
        {/* )} */}
        {/* {!loading && data && data?.length === 0 && (
          <div className="__notFound__wrapperr">
            <div className="__theNotFound">
              <img src={NOTFOUND} alt="not found" />
              <span>No Data</span>
            </div>
          </div>
        )} */}

        {/* {data?.length > 0 && (
          <TablePaginationComponent
            onNext={onNext}
            onPrev={onPrev}
            skip={skip}
            lengthChecker={checkForEmpty}
            dataCheck={data}
            limit={limit}
            loading={checkForExtraData}
          />
        )} */}
      </div>
    </>
  );
};

export default ConvertDesktopTable;
