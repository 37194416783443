import React from "react";
import "./youtube.wrapper.scss";

const YoutubeWrapper = ({youtubeLink}) => {
  return (
    <div className="youtubeWrapper" data-aos="zoom-in">
      <div className="youtubeWrapperContainer">
        <iframe
          //   width="560"
          //   height="315"
          src={youtubeLink}
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
          className="youtubeWrapperContainerVideo"
        ></iframe>
      </div>
    </div>
  );
};

export default YoutubeWrapper;
