import React, {memo, useContext, useEffect, useState} from "react";
import "../../../styles/createTradeOptverification.scss";
import {Button} from "@mui/material";
import OtpInput from "react-otp-input";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {releaseTokenByIDAction} from "../../../store/actions/createTradeAction";
import {Loader, Loader4} from "../../Common/Loader";
import {useTimer} from "react-timer-hook";
import {CreateTradeContext} from "../../Home/CreateTrade";
import {RELEASE_TOKEN_DEFAULT} from "../../../store/types";
import AuthService from "../../../services/auth";
import createTradeService from "../../../services/createTrade";
import {useGetUserType} from "../../../hooks/useGetUserType";

const VerificationOTP = ({setOpenModal, setStep}) => {
  const {phoneEmailOtp, setPhoneEmailOtp} = useContext(CreateTradeContext);
  const {userData} = useSelector((state) => state.generalReducer);
  const dispatch = useDispatch();
  const {id} = useParams();
  const [phoneOtp, setPhoneOtp] = useState("");
  const [timeChecker, setTimeChecker] = useState(false);
  const [loadingBar, setLoadingBar] = useState(false);
  const {response: tradeResponse} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(tradeResponse ?? {});
  const {response, loading, error} = useSelector((state) => state.releaseTokenReducer);
  const timer = new Date();
  timer.setSeconds(timer.getSeconds() + 120);
  const {seconds, minutes, pause, restart} = useTimer({
    expiryTimestamp: timer,
    autoStart: true,
    onExpire: () => {
      setTimeChecker(true);
      pause();
    },
  });

  const handleSubmit = (otp) => {
    if (phoneEmailOtp === "email") {
      dispatch(releaseTokenByIDAction({phoneCode: null, emailCode: phoneOtp, authType: "email", trade_id: id}));
    } else if (phoneEmailOtp === "phone") {
      dispatch(releaseTokenByIDAction({phoneCode: phoneOtp, emailCode: null, authType: "phone", trade_id: id}));
    } else if (phoneEmailOtp === "google auth") {
      dispatch(releaseTokenByIDAction({authCode: phoneOtp, authType: "google auth", trade_id: id}));
    }
  };

  const something = () => {
    if (userType === "user" && tradeResponse?.type === "sell") {
      setLoadingBar(true);
      createTradeService
        .tradePhoneAuth()
        .then((res) => {
          if (res.message === "success") {
            setLoadingBar(false);
            setPhoneEmailOtp("phone");
            restart();
          }
        })
        .catch(({data}) => {
          if (data.message === "User need a verified phone number") {
            AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false}).then(
              (err) => {
                if (err) {
                  setLoadingBar(false);
                  setPhoneEmailOtp("email");
                  restart();
                }
              }
            );
          } else {
            setLoadingBar(false);
          }
        });
    } else if (userType === "user" && tradeResponse?.type === "buy") {
      setOpenModal(false);
    } else if (userType === "trader" && tradeResponse?.type === "buy") {
      setLoadingBar(true);
      createTradeService
        .tradePhoneAuth()
        .then((res) => {
          if (res.message === "success") {
            setLoadingBar(false);
            setPhoneEmailOtp("phone");
            restart();
          }
        })
        .catch(({data}) => {
          if (data.message === "User need a verified phone number") {
            AuthService.verifyEmailSecurely({email: userData && userData?.data?.userData?.email, isLogin: false}).then(
              (err) => {
                if (err) {
                  setLoadingBar(false);
                  setPhoneEmailOtp("phone");
                  restart();
                }
              }
            );
          } else {
            setLoadingBar(false);
          }
        });
    } else if (userType === "trader" && tradeResponse?.type === "sell") {
      setOpenModal(false);
    }
  };

  useEffect(() => {
    if (response?.message === "success") {
      setStep(2);
    }
  }, [response]);
  useEffect(() => {
    return () => {
      dispatch({type: RELEASE_TOKEN_DEFAULT, payload: ""});
    };
  }, []);

  return (
    <div className="__createTradeotp-container">
      {loadingBar ? (
        <Loader style={{height: "280px", width: "410px"}} />
      ) : (
        <div className="login-content">
          <div className="form-contents">
            <div>
              <div className="form-contentsHeader __center">
                <p className="tag">
                  Please verify from{" "}
                  {phoneEmailOtp === "email" ? "Email" : phoneEmailOtp === "phone" ? "Phone" : "Google Auth"}
                </p>
                <p className="tagTell">
                  We sent a code to{" "}
                  {phoneEmailOtp === "email"
                    ? `${userData?.data?.userData?.email}`
                    : phoneEmailOtp === "phone"
                    ? `${userData?.data?.userData?.phoneNumber.slice(
                        0,
                        6
                      )}***${userData?.data?.userData?.phoneNumber.slice(
                        -3,
                        userData?.data?.userData?.phoneNumber?.length
                      )}`
                    : "Google Auth"}
                </p>
              </div>
              <div style={otpContainer}>
                <OtpInput
                  value={phoneOtp}
                  onChange={(otp) => {
                    if (error !== null) {
                      dispatch({type: RELEASE_TOKEN_DEFAULT, payload: ""});
                    }
                    setPhoneOtp(otp);
                  }}
                  className="theInputs"
                  numInputs={6}
                  separator={<span>&nbsp;&nbsp;&nbsp;</span>}
                  placeholder="000000"
                  isInputNum={true}
                  // hasErrored={loginDetailDataSecond !== "" || undefined ? false : true}
                  // errorStyle={{border: error === true ? "1px solid red" : "1px solid #D0D5DD"}}
                />
              </div>
              {(error || error?.message) && <div className="__theerror">{error?.message ? error?.message : error}</div>}
              <div className="form-contentsResendCode __textcenter">
                {phoneEmailOtp !== "google auth" && (
                  <>
                    <p className="form-contentsResendCodeContent">Didn’t get a code?</p>
                    {!timeChecker && phoneEmailOtp !== "google auth" ? (
                      <p className="form-contentsResendCodeContent">{`${minutes} : ${seconds}`}</p>
                    ) : (
                      <p className="form-contentsResendCodeLink" onClick={() => something()}>
                        Click to resend.
                      </p>
                    )}
                  </>
                )}
              </div>
            </div>

            <div className="form-contentsButtonBody">
              <Button
                variant="contained"
                sx={cancelButton}
                color="primary"
                onClick={() => {
                  setStep(0);
                  setOpenModal(false);
                }}
              >
                Cancel
              </Button>

              <Button
                variant="contained"
                sx={signUp}
                color="primary"
                onClick={handleSubmit}
                disabled={phoneOtp.length === 6 ? false : true}
              >
                {loading ? <Loader4 /> : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const otpContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  paddingTop: "2rem",
};

const logo = {
  width: "50px",
  height: "50px",
};

const cancelButton = {
  width: "48%",
  height: "40px",
  background: "#fff",
  color: "#344054",
  fontSize: "1.3rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  cursor: "pointer",
  border: "0.49375px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#E0E0E0",
    boxShadow: "none",
  },
};

const signUp = {
  width: "48%",
  background: "#5d3cb2",
  height: "40px",
  color: "#fff",
  fontSize: "1.3rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  cursor: "pointer",

  "&:hover": {
    backgroundColor: "#5d3cb2",
    boxShadow: "none",
  },
  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
    cursor: "not-allowed",
  },
};

export default memo(VerificationOTP);
