import React, {useState} from "react";
import {FiCopy} from "react-icons/fi";
import QRCode from "qrcode";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import "../../styles/walletStyles/walletDepositSingleModal.scss";
import NetworkDresser from "./NetworkDresser";
import {CoinIcons} from "../Common/coinSwitcher";
import CoinDresser from "./CoinDresser";
import useWindowDimensions from "../../hooks/useWindowDimension";
import {useDispatch, useSelector} from "react-redux";
import {getCoinNetworkSelection, getWalletModalDeposit} from "../../store/actions/walletAction";

const DepositeSelectAnyModal = () => {
  const dispatch = useDispatch();
  const coinNetworkSelection = useSelector((state) => state.walletReducer.coinNetworkSelection);
  const walletCoinDetails = useSelector((state) => state.walletReducer.walletCoinDetials);
  const [qrCode, setQrCode] = useState();
  const [showBarcode, setShowBarcode] = useState(false);
  const {width} = useWindowDimensions();

  const getQRCode = (item) => {
    QRCode.toDataURL(`${item}`)
      .then((url) => {
        setQrCode(url);
      })
      .catch((err) => {});
  };

  return (
    <div className="depositPage__Wrapper" style={width > 760 && showBarcode ? ShowBarCode : HideBarCode}>
      <div
        className="depositePage__firstLayout"
        style={width > 760 && showBarcode ? whenShowBarCodeFirstLayout : whenHideBarCodeFirstLayout}
      >
        <div className="depositePage__firstLayoutImageSection">
          <CoinIcons
            intialValue={walletCoinDetails?.tokenSymbol}
            details={true}
            mainWrapper={{width: "40px", height: "40px"}}
          />
        </div>
        <div className="depositePage__SecondLayout">
          <h3>{`Deposit ${walletCoinDetails?.tokenSymbol?.toUpperCase()}`}</h3>
          <p>{`You can deposit ${walletCoinDetails?.name?.toLowerCase()}`}</p>
          <div className="depositePage__firstLayoutCoinSectionSelector">
            <CoinDresser setShowBarcode={setShowBarcode} showBarCode={true} />
          </div>
        </div>
        <div className="depositePage__thirdLayout">
          <p>Select Network</p>
          <div className="depositePage__firstLayoutNetworkSectionSelector">
            <NetworkDresser />
          </div>
          <span className="depositePage__firstLayoutNetworkSectionSelectorDetails">
            Make sure you select the correct network to avoid transaction errors.
          </span>
        </div>
        {coinNetworkSelection?.token && coinNetworkSelection?.network && (
          <div>
            <div className="depositePage__fourthLayout">
              <p className="depositePage__fourthLayoutParagraph">Wallet Addresss</p>
              <div className="walletModal__downloadBarcodeDetails">
                <p>{walletCoinDetails?.tokenId}</p>
                <div className="walletModal__downloadBarcodeDetailsCopyBody">
                  <CopyToClipboard
                    text={walletCoinDetails?.tokenId}
                    onCopy={() => toast("copied to clipboard", {position: "top-right"})}
                  >
                    <FiCopy className="walletModal__downloadBarcodeDetailsCopy" />
                  </CopyToClipboard>
                </div>
              </div>
              <span className="depositePage__fourthLayoutDetails">
                Copy your wallet address to complete the deposit.
              </span>
            </div>
            <div className="depositePage__fivethLayout">
              <button
                onClick={() => {
                  getQRCode(walletCoinDetails?.tokenId);
                  setShowBarcode(!showBarcode);
                }}
              >
                {!showBarcode ? "Show Qrcode" : "Hide Qrcode"}
              </button>
            </div>
          </div>
        )}
        <div>
          <div className="depositePage__sixthLayout">
            <button
              onClick={() => {
                dispatch(getCoinNetworkSelection({}));
                setShowBarcode(false);
                dispatch(getWalletModalDeposit({}));
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
      {showBarcode && (
        <div className="depositePage__qRCodeWrapper">
          <img src={qrCode} alt="qrCode" />
        </div>
      )}
    </div>
  );
};

const ShowBarCode = {width: "800px"};

const HideBarCode = {width: "430px"};

const whenHideBarCodeFirstLayout = {width: "100%"};

const whenShowBarCodeFirstLayout = {width: "62%", paddingRight: "25px"};

export default DepositeSelectAnyModal;
