import {ALL_TRADE_ORDER, IS_LOADING_TRADE} from "../types";

const initialState = {
  tradeActive: [],
  isLoading: 0,
};

const tradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ALL_TRADE_ORDER: {
      return {
        ...state,
        tradeActive: payload,
      };
    }
    case IS_LOADING_TRADE: {
      return {
        ...state,
        isLoading: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default tradeReducer;
