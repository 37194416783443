import React, {memo, useCallback, useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {FiMessageSquare} from "react-icons/fi";
import MoreDetails from "./MoreDetails";
import notFound from "../../assets/icons/notfound.svg";
import NotificationService from "../../services/notification";
import "../../styles/notification.scss";
import moment from "moment";
import {useDispatch, useSelector} from "react-redux";
import {getTradeNotification} from "../../store/actions/notification";
import {toast} from "react-toastify";
// import {Pagination} from "./Pagination";

const AccordionTrade = (props) => {
  return props.filterTrade.length > 0 ? (
    props.filterTrade.map((item, index) => <TableN key={index} item={item} refresh={props.refresh} />)
  ) : (
    <>
      <div style={{textAlign: "center", margin: "80px 0"}}>
        <img src={notFound} alt="not found" style={{width: "100px", height: "auto"}} />
        <p style={{fontSize: "12px"}}>No Notification</p>
      </div>
      {/* <Pagination skip={props.skip} onPrev={props.onPrev} onNext={props.onNext} /> */}
    </>
  );
};

const TableN = ({item}) => {
  const dispatch = useDispatch();
  const notificationDataRedux = useSelector((state) => state.notificationReducer.tradeNotification);
  const [show, setShow] = useState(true);

  const markAsRead = useCallback((param) => {
    // eslint-disable-next-line array-callback-return
    notificationDataRedux?.map((state) => {
      if (state._id === param._id) {
        item.unRead = false;
      }
    });

    dispatch(getTradeNotification(notificationDataRedux));

    NotificationService.markAsRead(param)
      .then((data) => {
        if (data.data.message === "success") {
        }
      })
      .then(() => {})
      .catch(() => {
        // toast("Something went wrong");
      });
  }, []);

  return (
    <main
      style={tableRowStyle}
      onClick={() => {
        setShow(!show);
        const payload = {
          _id: item._id,
        };
        markAsRead(payload);
      }}
    >
      <div className="accordion-container">
        <span className="iconContainer">
          <FiMessageSquare stroke={item.unRead === true ? "#34D07F" : "#101828"} fontSize="1.1rem" />
          <p
            style={{
              ...titleStyle,
              marginLeft: "10px",
              color: `${item.unRead === true ? "#101828" : "#667085"}`,
              fontWeight: item.unRead === true ? 600 : 300,
            }}
          >
            {item?.title}
          </p>
        </span>
        <div className="timeContainer" style={{gap: "10px"}}>
          <p style={{...textStyle, color: "#101828", fontWeight: item.unRead === true ? 400 : 300}}>
            {moment(item.timeStamp).format("DD-MM-YYYY ")}
            <span style={{margin: "0 5px"}} />
            {moment(item.timeStamp).format("h:mm:ss")}
          </p>
          {show ? (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <IoIosArrowDown
                fill="#667085"
                fontSize="1.5rem"
                style={{margin: "5px 0px"}}
                className="iconPositionContainer"
              />
            </div>
          ) : (
            <div style={{display: "flex", alignItems: "center", justifyContent: "center"}}>
              <IoIosArrowUp fill="#667085" fontSize="1.5rem" className="iconPositionContainer" />
            </div>
          )}
        </div>
      </div>

      {show ? (
        <p style={{...titleStyle, fontWeight: item.unRead === true ? 300 : 200, marginTop: "10px"}}>{item.text}</p>
      ) : (
        <MoreDetails details={item} />
      )}
    </main>
  );
};

const tableRowStyle = {
  display: "flex",
  flexDirection: "column",
  padding: "2.5rem 2rem",
  borderBottom: "1px solid #EAECF0",
};

const textStyle = {
  fontFamily: "Inter, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#667085",
};

const titleStyle = {
  fontFamily: "Inter, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#101828",
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
};

export default memo(AccordionTrade);
