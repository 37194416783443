import React, {memo, useState} from "react";

import OtpInput from "react-otp-input";
// import {useHistory} from "react-router-dom";
// import {useDispatch} from "react-redux";
// import {goToHome} from "../../store/actions/auth";

const GoogleOTP = ({handleChange, otp}) => {
  return (
    <div style={{display: "flex", alignItems: "center"}}>
      <OtpInput
        value={otp || ""}
        inputStyle={{...style, marginTop: "10px"}}
        numInputs={6}
        separator={<span>&nbsp;&nbsp;&nbsp;</span>}
        placeholder="000000"
        isInputNum={true}
        onChange={handleChange}
      />
    </div>
  );
};

const style = {
  width: "50px",
  height: "50px",
  background: "#fff",
  fontSize: "34px",
  borderRadius: "8px",
  outline: "none",
  paddingLeft: "12px",
  marginTop: "20px",
  border: "1px solid #D6BBFB",
  textAlign: "left",
  boxShadow: "0px 0.305933px 0.611867px rgba(16, 24, 40, 0.05)",
  color: "#7F56D9",
};

export default memo(GoogleOTP);
