import "../../styles/landing-page-styles/button.scss";
const LinkButton = ({title, onClick, style, link, changeStyle}) => {
  return (
    <a
      href={link}
      className={`baseButton primaryButton ${changeStyle}`}
      onClick={onClick}
      style={style}
      without={0}
      rel="noreferrer"
    >
      {title}
    </a>
  );
};

export default LinkButton;
