import {memo} from "react";
import {IoIosArrowForward} from "react-icons/io";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {getDetailsOfWithdrawal, getModalChecker} from "../../store/actions/walletAction";

const MoreDetails = ({details}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <div style={{...moreDetailContainer, marginTop: "10px"}}>
      <p style={textStyle}>{details.text}</p>
      {details.notificationID && (
        <p
          style={titleStyle}
          onClick={() => {
            if (details.type === "trade") {
              history.push(`/app/trade/${details.notificationID}`);
              return;
            }

            if (details.type === "withdraw" || details.type === "deposit") {
              dispatch(getDetailsOfWithdrawal(details));
              history.push("/app/transaction");
              dispatch(getModalChecker({num: 4}));
              return;
            }
          }}
        >
          View More
          <IoIosArrowForward />
        </p>
      )}
    </div>
  );
};

const moreDetailContainer = {
  height: "auto",
  justifyContent: "space-between",
  width: "100%",
  transition: "width 2s",
  // trans
};

const textStyle = {
  fontFamily: "Inter, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  color: "#667085",
};

const titleStyle = {
  fontFamily: "Inter, sans-serif",
  fontStyle: "normal",
  fontWeight: "400",
  fontSize: "14px",
  lineHeight: "20px",
  display: "flex",
  gap: "0.5rem",
  alignItems: "center",
  marginTop: "1rem",
  color: "#53389E",
  cursor: "pointer",
};

export default memo(MoreDetails);
