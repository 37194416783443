import {
  DEFAULT_CURRENCY_COUNTRY,
  PHONE_SEARCHABLE_DETAILS,
  UPDATED_PHONE_NUMBER,
  SETTINGS_VERIFY_MODAL,
  SETTINGS_MODAL_INFORMATION_DETAILS,
  SETTINGS_MODAL_MORE_INFORMATION_DETAILS,
  VALID_ADDRESS_FIRST_NAME,
  VALID_ADDRESS_LAST_NAME,
  VALID_ADDRESS_MIDDLE_NAME,
  VALID_ADDRESS_RESIDENCE,
  VALID_ADDRESS_CITY,
  VALID_ADDRESS_STATE,
  VALID_ADDRESS_COUNTRY,
  STORE_VERIFY_LINK_URL,
  GET_ADDRESS_COUNTRY_SELECTED,
  GET_SELECTED_COUNTRY_SETTINGS,
  GOOGLEAUTHQRCODE,
  GOOGLEAUTHPROCESSMODALS,
  USEGOOGLEAUTH,
  USEPHONENUMBER,
  SETTING_LOADER_SKELETON,
  CLAIM_LOADER_SKELETON,
  UPDATED_PHONE_NUMBER_DETAILS,
  USER_DETAILS_FOR_UPDATING_PHONE_NUMBER,
  SWITCH_TO_GOOGLE_CHANGE_NUMBER,
  SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO,
  STORE_REJECTED_ADDRESS_MODAL,
} from "../types";

export const getPhoneSearchableDetails = (param) => (dispatch) => {
  dispatch({
    type: PHONE_SEARCHABLE_DETAILS,
    payload: param,
  });
};

export const getUpdatePhoneNumber = (param) => (dispatch) => {
  dispatch({
    type: UPDATED_PHONE_NUMBER,
    payload: param,
  });
};

export const getDefaultCurrenctCountry = (param) => (dispatch) => {
  dispatch({
    type: DEFAULT_CURRENCY_COUNTRY,
    payload: param,
  });
};

export const settingVerifyModal = (param) => (dispatch) => {
  dispatch({type: SETTINGS_VERIFY_MODAL, payload: param});
};

export const validAddressFirstNameAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_FIRST_NAME, payload: param});
};
export const validAddressLastNameAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_LAST_NAME, payload: param});
};
export const validAddressMiddleNameAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_MIDDLE_NAME, payload: param});
};

export const validAddressResidenceAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_RESIDENCE, payload: param});
};

export const validAddressCityAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_CITY, payload: param});
};
export const validAddressStateAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_STATE, payload: param});
};
export const validAddressCountryAction = (param) => (dispatch) => {
  dispatch({type: VALID_ADDRESS_COUNTRY, payload: param});
};

export const getAddressCountryAction = (param) => (dispatch) => {
  dispatch({type: GET_ADDRESS_COUNTRY_SELECTED, payload: param});
};
export const getSelectedCountryAction = (param) => (dispatch) => {
  dispatch({type: GET_SELECTED_COUNTRY_SETTINGS, payload: param});
};

export const modalInformationDetails = (param) => (dispatch) => {
  dispatch({type: SETTINGS_MODAL_INFORMATION_DETAILS, payload: param});
};

export const modalMoreInformationDetails = (param) => (dispatch) => {
  dispatch({type: SETTINGS_MODAL_MORE_INFORMATION_DETAILS, payload: param});
};

export const storeVerifyLinkURL = (param) => (dispatch) => {
  dispatch({type: STORE_VERIFY_LINK_URL, payload: param});
};

export const getGoogleAuthQRCode = (param) => (dispatch) => {
  dispatch({type: GOOGLEAUTHQRCODE, payload: param});
};

export const getGoogleAuthProcessModals = (param) => (dispatch) => {
  dispatch({type: GOOGLEAUTHPROCESSMODALS, payload: param});
};

export const getUseGoogleAuth = (param) => (dispatch) => {
  dispatch({type: USEGOOGLEAUTH, payload: param});
};

export const getUsePhoneNumber = (param) => (dispatch) => {
  dispatch({type: USEPHONENUMBER, payload: param});
};

export const getSettingLoaderSkeleton = (param) => (dispatch) => {
  dispatch({type: SETTING_LOADER_SKELETON, payload: param});
};

export const getClaimLoaderSkeleton = (param) => (dispatch) => {
  dispatch({type: CLAIM_LOADER_SKELETON, payload: param});
};

export const updatedPhoneNumberDetailsAction = (param) => (dispatch) => {
  dispatch({type: UPDATED_PHONE_NUMBER_DETAILS, payload: param});
};
export const userDetailsForUpdatingPhoneNumberAction = (param) => (dispatch) => {
  dispatch({type: USER_DETAILS_FOR_UPDATING_PHONE_NUMBER, payload: param});
};
export const switchToGoogleChangeNumber = (param) => (dispatch) => {
  dispatch({type: SWITCH_TO_GOOGLE_CHANGE_NUMBER, payload: param});
};
export const switchToGoogleChangeNumberTwo = (param) => (dispatch) => {
  dispatch({type: SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO, payload: param});
};

export const storeRejectedAddressModalAction = (param) => (dispatch) => {
  dispatch({type: STORE_REJECTED_ADDRESS_MODAL, payload: param});
};
