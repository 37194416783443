import React, {memo, useRef, useState} from "react";
import {HiOutlineMinus, HiOutlinePlus} from "react-icons/hi";
import CurrencyFormat from "react-currency-format";
import "../../styles/postTradeStyles/postTradeThirdSection.scss";
import {useDispatch, useSelector} from "react-redux";
import {POST_TRADE_BUY_ERROR, POST_TRADE_SELL_ERROR, SKELETON_LOADER, TOKENMARGIN} from "../../store/types";
import {useCallback} from "react";
import PostTradeService from "../../services/postTradeService";
import {getTotalCurrencyData} from "../../store/actions/postTradeAction";
import {Skeleton} from "@mui/material";

const PostTradeThirdSection = () => {
  const dispatch = useDispatch();
  const timeout = useRef();

  const postTradeDetailedForm = useSelector((state) => state.postTradeReducer.postTradeDetailedForm);
  const allCurrencyDetails = useSelector((state) => state.postTradeReducer.tokenLocalCurrencyData);
  const coinData = useSelector((state) => state.postTradeReducer.coinData);
  const countryDataState = useSelector((state) => state.postTradeReducer.countryData);
  const showSkeleton = useSelector((state) => state.postTradeReducer.skeletonLoader);
  const [margin, setMargin] = useState(0.0);
  const sellData = useSelector((state) => state.postTradeReducer.sellData);
  const buyData = useSelector((state) => state.postTradeReducer.buyData);

  const handleChangeMargin = (e) => {
    clearTimeout(timeout.current);

    if (!e.target.value) {
      setMargin(parseInt(0));
      dispatch({type: TOKENMARGIN, payload: 0});

      timeout.current = setTimeout(() => {
        getLocalCurrency(parseInt(0));
      }, 1000);

      return;
    }

    if (e.target.value <= -5) {
      setMargin(parseInt(-5));
      dispatch({type: TOKENMARGIN, payload: parseInt(-5)});

      timeout.current = setTimeout(() => {
        getLocalCurrency(parseInt(-5));
      }, 1000);
      return;
    }

    timeout.current = setTimeout(() => {
      getLocalCurrency(parseFloat(e.target.value));
    }, 1000);
    setMargin(parseFloat(e.target.value));
    dispatch({type: TOKENMARGIN, payload: parseFloat(e.target.value)});
  };

  // const handleBlurMargin = (e) => {
  //   getLocalCurrency(parseInt(e.target.value));
  // };

  const incrementMagin = () => {
    setMargin((prev) => prev + 1);
    dispatch({type: TOKENMARGIN, payload: margin + 1});
    getLocalCurrency(margin + 1);
  };

  const decrementMargin = () => {
    setMargin((prev) => (prev <= -5 ? prev : prev - 1));
    if (margin <= -5) {
      dispatch({type: TOKENMARGIN, payload: -5});
      getLocalCurrency(-5);
    } else {
      dispatch({type: TOKENMARGIN, payload: margin - 1});
      getLocalCurrency(margin - 1);
    }
  };

  const getLocalCurrency = (marginItem) => {
    dispatch({type: SKELETON_LOADER, payload: true});
    if (
      coinData !== "" &&
      countryDataState?.currencyCode !== "" &&
      countryDataState?.currencyCode !== undefined &&
      typeof marginItem === "number" &&
      !isNaN(marginItem)
    ) {
      PostTradeService.TokenLocalCurrency({
        tokenSymbol: coinData,
        currency: countryDataState?.currencyCode,
        margin: marginItem,
      }).then((data) => {
        dispatch(getTotalCurrencyData(data));

        if (Object.keys(postTradeDetailedForm).length > 0) {
          const newMinValue = postTradeDetailedForm?.minValue?.match(/\d/g);
          const minAmounts = newMinValue?.join("");

          const newMaxValue = postTradeDetailedForm?.maxValue?.match(/\d/g);
          const maxAmounts = newMaxValue?.join("");

          if (buyData.checker === true) {
            if (Number(maxAmounts) < Number(minAmounts)) {
              dispatch({type: POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
            } else {
              dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
            }
          }

          if (sellData.checker === true) {
            if (data.marginCoinBalance < Number(maxAmounts)) {
              dispatch({type: POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
            } else {
              dispatch({type: POST_TRADE_SELL_ERROR, payload: ""});
            }

            if (Number(maxAmounts) < Number(minAmounts)) {
              dispatch({type: POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
            } else {
              dispatch({type: POST_TRADE_BUY_ERROR, payload: ""});
            }
          }
        }

        dispatch({type: SKELETON_LOADER, payload: false});
      });
    }
  };

  return (
    <div className="postTrade__thirdLayout">
      <div className="postTrade__thirdLayoutOne">
        <div className="postTrade__thirdLayoutOneCurrency">
          <div className="postTrade__thirdLayoutOneCurrencyCountry">
            <p>Currency</p>
            <input type="text" disabled value={`${countryDataState.currencyCode}`} readOnly={true} />
          </div>
          <div className="postTrade__thirdLayoutOneCurrencyMargin">
            <div className="postTrade__thirdLayoutOneCurrencyMarginLabel">
              <p>Margin</p>
            </div>
            <div className="postTrade__thirdLayoutOneCurrencyMarginInput">
              <label>Trade Margin Percentage</label>
              <div className="postTrade__thirdLayoutOneCurrencyMarginInputField">
                <div className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInput">
                  <div
                    className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIconBody"
                    onClick={decrementMargin}
                  >
                    <HiOutlineMinus className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIcon" />
                  </div>
                  <CurrencyFormat
                    name={"margin"}
                    value={margin || 0.0}
                    inputmode="numeric"
                    onChange={handleChangeMargin}
                    autocomplete="off"
                  />
                  <div
                    className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIconBody"
                    onClick={incrementMagin}
                  >
                    <HiOutlinePlus className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIcon" />
                  </div>
                </div>
                <div className="postTrade__thirdLayoutOneCurrencyMarginInputFieldText">
                  <p>%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="postTrade__thirdLayoutTwo">
        <div className="postTrade__thirdLayoutTwoFirstLayout">
          <div className="postTrade__thirdLayoutTwoFirstLayoutPrice">
            <p>
              Current <span>{`${coinData?.toUpperCase()}`}</span> Market Price
            </p>
          </div>
          <div className="postTrade__thirdLayoutTwoFirstLayoutPriceAmount">
            <p>
              {showSkeleton ? (
                <div>
                  <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                </div>
              ) : (
                <CurrencyFormat
                  value={`${Object.keys(allCurrencyDetails).length > 0 ? allCurrencyDetails.currency : 0}`}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${countryDataState.currencyCode}`}
                />
              )}
            </p>
          </div>
          <div className="postTrade__thirdLayoutTwoFirstLayoutPriceDetails">
            <p>Trade Margin Percentage</p>
          </div>
        </div>

        <div className="postTrade__thirdLayoutTwoSecondLayout">
          <div className="postTrade__thirdLayoutTwoSecondLayoutPrice">
            <p>
              {showSkeleton ? (
                <div>
                  <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                </div>
              ) : (
                <CurrencyFormat
                  value={`${Object.keys(allCurrencyDetails).length > 0 ? allCurrencyDetails.margin : 0}`}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${countryDataState.currencyCode}`}
                />
              )}
            </p>
          </div>

          <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetails">
            <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMargin">
              <p>Profit Margin</p>
            </div>
            <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmount">
              <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmountBody">
                <p style={{color: `${allCurrencyDetails.sign <= 0 ? "#fda29b" : ""}`}}>
                  {allCurrencyDetails.sign > 0 && "+"}
                  <CurrencyFormat
                    value={`${Object.keys(allCurrencyDetails).length > 0 ? allCurrencyDetails.difference : 0}`}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={` ${countryDataState.currencyCode}`}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(PostTradeThirdSection);
