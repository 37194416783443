import React, {createContext, useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {FILTER_BUY_SELL_DEFAULT, TRADE_DETAILS} from "../../store/types";
import "../../styles/transaction.scss";
import TransactionTableArea from "../TransactionHistory/tableArea";
import TransactionHistoryTop from "../TransactionHistory/topArea";

export const TransactionContext = createContext();
const Transaction = () => {
  const dispatch = useDispatch();
  const [transactionType, setTransactionType] = useState("buysell");

  useEffect(() => {
    return () => {
      dispatch({type: FILTER_BUY_SELL_DEFAULT});
    };
  }, []);

  return (
    <TransactionContext.Provider value={{transactionType, setTransactionType}}>
      <div style={{paddingBottom: "1px", margin: "0 auto", width: "100%"}}>
        <TransactionHistoryTop />
        <TransactionTableArea />
      </div>
    </TransactionContext.Provider>
  );
};

export default Transaction;
