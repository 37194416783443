import React, {useState} from "react";
import {useMemo} from "react";
import CurrencyFormat from "react-currency-format";
import {FiMoreHorizontal} from "react-icons/fi";
import {useDispatch, useSelector} from "react-redux";
import {
  getCoinNetworkSelection,
  getWalletModalDeposit,
  getWalletModalWithdrawal,
  getWithdrawMainDetail,
} from "../../store/actions/walletAction";
import {
  TOKENNETWORKFEE,
  WALLETADDRESSCHECKER,
  WALLETADDRESSERROR,
  WALLETCOINDETAILS,
  WALLETNETWORKDATA,
  WITHDRAWALERROR,
} from "../../store/types";
import "../../styles/walletStyles/walletCoinSection.scss";
import {CoinIcons} from "../Common/coinSwitcher";
import SectionModal from "../Common/sectionModal";
import DepositePageModal from "./walletDepositSingleModal";
import WalletDropDown from "./walletDropDown";
import WalletModalWrapper from "./walletModalWrapper";
import WithdrawPageModal from "./walletWithdrawalSingleModal";
import {formatBigNumber} from "../../utils/bigNumberFormatter";

const WalletCoinSection = () => {
  const dispatch = useDispatch();

  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);
  const setModalWithdrawal = useSelector((state) => state.walletReducer.setModalWithdrawal);
  const setModalDeposit = useSelector((state) => state.walletReducer.setModalDeposit);
  const [universalModal, setUniversalModal] = useState(false);

  return (
    <>
      <div className="wallet__secondLayout">
        {tokenDetails?.length === 0 && typeof tokenDetails[0]?.unitPrice === "undefined" ? (
          <div>loading</div>
        ) : (
          tokenDetails?.map((item, id) => (
            <div key={id} className="wallet__secondLayoutArrayBody">
              <div className="wallet__secondLayoutArrayBodyHeader Coin">
                <div className="wallet__secondLayoutArrayBodyHeaderCoinBody">
                  <CoinIcons
                    details={true}
                    intialValue={item?.tokenSymbol}
                    mainWrapper={{width: "40px", height: "40px"}}
                  />
                </div>
                <div className="wallet__secondLayoutArrayBodyHeaderDetails">
                  <h3>{item?.tokenSymbol?.toUpperCase()}</h3>
                  <p>{item?.name}</p>
                </div>
              </div>
              <div className="wallet__secondLayoutArrayBodyContent Similar">
                <p>
                  <CurrencyFormat
                    value={item.unitPrice}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    // prefix={"$"}
                    suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
                  />
                </p>
              </div>
              <div className="wallet__secondLayoutArrayBodyContent">
                <p>
                  {Number(formatBigNumber(item?.tokenBalance ? item?.tokenBalance : 0)).toFixed(6)}
                  {/* <CurrencyFormat
                    value={5.749023900408767e-7}
                    displayType={"text"}
                    decimalScale={6}
                    fixedDecimalScale
                    // prefix={"$"}
                    suffix={` ${item?.tokenSymbol?.toUpperCase()}`}
                  /> */}
                </p>
              </div>
              <div className="wallet__secondLayoutArrayBodyContent_Fiat">
                <p className="top">
                  <CurrencyFormat
                    value={item?.quantityInBTC ? formatBigNumber(item?.quantityInBTC) : 0}
                    displayType={"text"}
                    decimalScale={6}
                    fixedDecimalScale
                    // prefix={"$"}
                    suffix={` BTC`}
                  />
                </p>
                <p className="bottom">
                  <CurrencyFormat
                    value={item?.quantityInLocalCurrency ? formatBigNumber(item?.quantityInLocalCurrency) : 0}
                    displayType={"text"}
                    decimalScale={2}
                    fixedDecimalScale
                    thousandSeparator
                    // prefix={"$"}
                    suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
                  />
                </p>
              </div>
              <div
                className="wallet__thirdLayoutDetails"
                onClick={() => {
                  dispatch({type: WALLETCOINDETAILS, payload: item});
                  dispatch(getCoinNetworkSelection({token: item.tokenSymbol}));
                  dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});
                  dispatch({type: WALLETADDRESSCHECKER, payload: false});
                  dispatch({type: WITHDRAWALERROR, payload: ""});
                  dispatch({type: WALLETADDRESSERROR, payload: ""});
                  dispatch({type: TOKENNETWORKFEE, payload: {}});
                  setUniversalModal(true);
                }}
              >
                <div className="wallet__thirdLayoutDetailsIconBody">
                  <FiMoreHorizontal className="wallet__thirdLayoutDetailsIcon" />
                </div>
              </div>
              <div className="wallet__secondLayoutArrayBodyButtons Action">
                <div className="wallet__secondLayoutArrayBodyButtonOne">
                  <button
                    onClick={() => {
                      dispatch({type: WALLETCOINDETAILS, payload: item});
                      dispatch(getCoinNetworkSelection({token: item.tokenSymbol}));
                      dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});
                      dispatch(getWalletModalDeposit({name: "single", checker: true}));
                    }}
                  >{`Deposit`}</button>
                </div>

                <div className="wallet__secondLayoutArrayBodyButtonTwo">
                  <button
                    onClick={() => {
                      dispatch(getWithdrawMainDetail({}));
                      dispatch({type: WALLETCOINDETAILS, payload: item});
                      dispatch(getCoinNetworkSelection({token: item.tokenSymbol}));
                      dispatch({type: WALLETNETWORKDATA, payload: "Select Network"});
                      dispatch({type: WALLETADDRESSCHECKER, payload: false});
                      dispatch({type: WITHDRAWALERROR, payload: ""});
                      dispatch({type: WALLETADDRESSERROR, payload: ""});
                      dispatch({type: TOKENNETWORKFEE, payload: {}});
                      dispatch(getWalletModalWithdrawal({name: "single", checker: true}));
                    }}
                  >{`Withdraw`}</button>
                </div>
              </div>
            </div>
          ))
        )}

        <SectionModal open={setModalWithdrawal.name === "single" ? setModalWithdrawal.checker : false}>
          <WithdrawPageModal />
        </SectionModal>

        <SectionModal open={setModalDeposit.name === "single" ? setModalDeposit.checker : false}>
          <DepositePageModal />
        </SectionModal>
      </div>

      {universalModal && (
        <WalletModalWrapper>
          <WalletDropDown
            setWalletModal={setUniversalModal}
            setWithdrawModal={setModalWithdrawal}
            setModal={setModalDeposit}
          />
        </WalletModalWrapper>
      )}
    </>
  );
};

export default WalletCoinSection;
