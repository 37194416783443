import Layout from "../components/LandingComponents/layout";
import "../styles/landing-page-styles/oyolafees.scss";

const Oyolafees = () => {
  return (
    <Layout>
      <div className="__oyolafeeswrapper">
        <div className="__titleWraper">
          <p className="__oyola">Oyola Fees</p>
          <h4 className="__howcan">Everything you can do with Oyola.</h4>
        </div>

        <div className="__faqcontainer">
          <h4>Buying and selling cryptocurrency</h4>
          <p className="__faqdesc">
            With Oyola, you can buy and sell cryptocurrenvies for free. Exchange USDT, BNB, BTC, ETH without hassles.
            There are no limits to you can buy and sell on Oyola.
          </p>
        </div>

        <div className="__thebuttons">
          <div className="__butButtonWrapper">
            <div className="__buy">Buy</div>
            <div className="__buy">Sell</div>
          </div>
          <div className="__butButtonWrapper">
            <div className="__buy __nofees">No Fees</div>
            <div className="__buy __nofees">No Fees</div>
          </div>
        </div>

        <div className="__faqcontainer" style={{marginTop: "30px"}}>
          <h4>Withdrawing cryptocurrency</h4>
          <p className="__faqdesc">
            With Oyola, you can withdraw to your wallet directly for a little token. Fees are charged based on the
            amount you want to withdraw. Withdrawal on Oyola is fast, easy, and seamless.
          </p>
        </div>

        <div className="__theFeatures">
          <div className="__butButtonHeader">
            <div className="__item">Withdrawing Bitcoin (BTC)</div>
            <div className="__details">
              {`0 <= 0.002945 BTC fees $1.15 USD 
0.002945 <= 0.005888 fees $2.5 USD 
0.005888+ fees $5 USD`}
            </div>
          </div>
          <div className="__butButtonHeader">
            <div className="__item"> Withdrawing Tether (USDT)</div>
            <div className="__details">
              {`0 <= 50. Fees $1 USD 
50 <= 100) fees $2 USD 
100+ fees $5 USD`}
            </div>
          </div>
          <div className="__butButtonHeader">
            <div className="__item">Withdrawing Ethereum (ETH)</div>
            <div className="__details">
              {`0 = 0.039321 fees $1.2 USDD 
0.039321 <= 0.07865 fees $1.9 USD
.07865+ fees $7.5 USD

`}
            </div>
          </div>
          <div className="__butButtonHeader">
            <div className="__item">Withdrawing Binance (BNB)</div>
            <div className="__details">
              {`0 <= 0.17136 fees $0.5 USD 
0.17136 <= 0.34266 $0.9 USD
0.34266+ fees $5 USD
`}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Oyolafees;
