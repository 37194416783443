import {
  USER_ORDER,
  TOKENSINGLELOCALCURRENCY,
  PROFITMARGIN,
  ORDERSTATUS,
  EDITTRADEDISABLE,
  EDIT_POST_TRADE_SELL_ERROR,
  EDIT_POST_TRADE_BUY_ERROR,
  EDIT_POST_TRADE_DETAILED_FORM,
} from "../types";

const initialState = {
  userOrders: {},
  tokenLocalCurrencyDatas: {},
  profitMargin: 0,
  orderStatus: {},
  editTradeDisable: true,
  editPostTradeBuyError: "",
  editPostTradeSellError: "",
  editPostTradeDetailedForm: {},
};

const editTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case USER_ORDER: {
      return {
        ...state,
        userOrders: payload,
      };
    }

    case TOKENSINGLELOCALCURRENCY: {
      return {
        ...state,
        tokenLocalCurrencyDatas: payload,
      };
    }

    case EDIT_POST_TRADE_SELL_ERROR: {
      return {
        ...state,
        editPostTradeSellError: payload,
      };
    }

    case EDIT_POST_TRADE_BUY_ERROR: {
      return {
        ...state,
        editPostTradeBuyError: payload,
      };
    }

    case EDIT_POST_TRADE_DETAILED_FORM: {
      return {
        ...state,
        editPostTradeDetailedForm: payload,
      };
    }

    case PROFITMARGIN: {
      return {
        ...state,
        profitMargin: payload,
      };
    }

    case ORDERSTATUS: {
      return {
        ...state,
        orderStatus: payload,
      };
    }

    case EDITTRADEDISABLE: {
      return {
        ...state,
        editTradeDisable: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default editTradeReducer;
