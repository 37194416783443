import React, {memo} from "react";
import AddressModal from "./addressModal";
import PersonalInformationModal from "./personalInformationModal";
import SuccessfullyModal from "./successfullyModal";
import PhoneNumberModal from "./phoneNumberModal";
import ListOfBlockedUsersModal from "./listOfBlockedUsersModal";
import {useSelector} from "react-redux";
import {Loader} from "../Common/Loader";
import GoogleAuthVerification from "./googleAuthVerification";
import GoogleConfirmationModal from "./googleConfirmationModal";
import VerifyGoogleAuthPhoneNumberModal from "./verifyGoogleAuthPhoneNumberModal";
import GoogleAuthChangeFirstModal from "./googleAuthChangeFirstModal";
import GoogleAuthChangeSecondModal from "./googleAuthChangeSecondModal";
import SecondphoneNumberModal from "./secondphoneNumberModal";
import SmsFirstModal from "./sms-first-modal.component";
import SmsSecondModal from "./sms-second-modal.component";
import SectionModalCentered from "../Common/sectionModalCentered";
import FirstTimePhoneNumberModal from "./FirstTimePhoneNumberModal";
import FirstTimeUserModal from "./FirstTimeUserModal";

const ModalInfo = () => {
  const showOTP = useSelector((state) => state.settingsReducer.settingsVerifyModalState);

  switch (showOTP) {
    case 0:
      return (
        <SectionModalCentered open={true}>
          <PersonalInformationModal />
        </SectionModalCentered>
      );

    case 1:
      return (
        <SectionModalCentered open={true}>
          <AddressModal />
        </SectionModalCentered>
      );

    case 2:
      return (
        <SectionModalCentered open={true}>
          <SuccessfullyModal />
        </SectionModalCentered>
      );

    case 3:
      return (
        <SectionModalCentered open={true}>
          <PhoneNumberModal />
        </SectionModalCentered>
      );

    case 4:
      return (
        <SectionModalCentered open={true}>
          <SecondphoneNumberModal />
        </SectionModalCentered>
      );

    case 5:
      return (
        <SectionModalCentered open={true}>
          <ListOfBlockedUsersModal />
        </SectionModalCentered>
      );

    case 6:
      return <Loader />;

    case 7:
      return <GoogleAuthVerification />;

    case 8:
      return <GoogleConfirmationModal />;

    case 9:
      return (
        <SectionModalCentered open={true}>
          <VerifyGoogleAuthPhoneNumberModal />
        </SectionModalCentered>
      );

    case 10:
      return (
        <SectionModalCentered open={true}>
          <GoogleAuthChangeFirstModal />;
        </SectionModalCentered>
      );

    case 11:
      return (
        <SectionModalCentered open={true}>
          <GoogleAuthChangeSecondModal />
        </SectionModalCentered>
      );

    case 12:
      return (
        <SectionModalCentered open={true}>
          <SmsFirstModal />
        </SectionModalCentered>
      );

    case 13:
      return (
        <SectionModalCentered open={true}>
          <SmsSecondModal />
        </SectionModalCentered>
      );

    case 14:
      return (
        <SectionModalCentered open={true}>
          <Loader />
        </SectionModalCentered>
      );

    case 15:
      return (
        <SectionModalCentered open={true}>
          <FirstTimeUserModal />
        </SectionModalCentered>
      );

    case 16:
      return (
        <SectionModalCentered open={true}>
          <FirstTimePhoneNumberModal />
        </SectionModalCentered>
      );

    default:
      return "";
  }
};

export default memo(ModalInfo);
