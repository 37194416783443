import {TheWholeFilterArea} from "./filterArea/theFilterArea";
import "../../../styles/landing-page-styles/marketplace.scss";
const MarketPlaceTopArea = () => {
  return (
    <>
      <div className="__marketPlaceTitleWrapper">
        <div>
          <h3 className="__marketPlace__name">MarketPlace</h3>
          <p className="__marketPlace__paragraph">Here you can buy or Sell Oyola on our marketplace</p>
        </div>
      </div>

      <TheWholeFilterArea />
    </>
  );
};

export {MarketPlaceTopArea};
