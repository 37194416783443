import phoneWallet from "../../assets/images/earn2.jpg";
import "../../styles/landing-page-styles/earn-rewards.scss";
import "../../styles/landing-page-styles/button.scss";
const EarnRewards = () => {
  return (
    <main className="homepageContainer">
      <div className="innerContainer">
        <div className="earnRewardsStyle">
          <div className="containerLeft">
            <img src={phoneWallet} alt="phone wallet" className="imageContainer" />
          </div>

          <div className="containerRight">
            <h1 className="title">Earn rewards</h1>
            <p className="tag">
              Earn cool cash in minutes. Earn up to $10 for you and your friends in rewards by referring your friends to
              the platform. Earn stable profits by keeping your money over a particular period of time.
            </p>
            <div className="signUpContainer">
              <button
                style={{cursor: "pointer"}}
                className="baseButton primaryButtonLarge buttonAdditionalStyle"
                onClick={() => {
                  window.location = "/features";
                }}
              >
                Earn Now
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EarnRewards;
