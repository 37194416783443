import {
  CREATE_TRADE_DEFAULT,
  DEFAULT_CHECKOUT_STATE,
  ON_CHANGE_CURRENCY,
  ON_CHANGE_TOKEN,
  ON_CHANGE_TOKEN_MAX,
  SET_UNIT_PRICE,
} from "../types";

export const setCreateTradeDefaultAction = (args) => (dispatch) => {
  return dispatch({type: CREATE_TRADE_DEFAULT, payload: args});
};

export const modalOnChangeCurrencyPriceAction = (args) => (dispatch) => {
  return dispatch({type: ON_CHANGE_CURRENCY, payload: args});
};

export const modalOnChangeTokenPriceAction = (args) => (dispatch) => {
  return dispatch({type: ON_CHANGE_TOKEN, payload: args});
};

export const modalOnChangeTokenPriceMaxAction = (args) => (dispatch) => {
  return dispatch({type: ON_CHANGE_TOKEN_MAX, payload: args});
};

export const checkoutDefaultStateAction = () => (dispatch) => {
  return dispatch({type: DEFAULT_CHECKOUT_STATE, payload: null});
};
