import {
  CANCEL_TRADE,
  CANCEL_TRADE_DEFAULT,
  CANCEL_TRADE_ERROR,
  CANCEL_TRADE_INITIAL,
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_INITIAL,
  CANCEL_TRADE_INITIAL_CLOSE,
} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const cancelTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CANCEL_TRADE: {
      return {
        ...state,
        response: payload,
      };
    }

    case CANCEL_TRADE_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    case CANCEL_TRADE_INITIAL_CLOSE: {
      return {
        ...state,
        loading: false,
      };
    }

    case CANCEL_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    case CANCEL_TRADE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }
    default: {
      return state;
    }
  }
};

export default cancelTradeReducer;
