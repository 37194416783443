import "../../styles/landing-page-styles/oyola-wallet.scss";
import assetsControlImage from "../../assets/images/assets-control.jpg";

const OyolaWallet = () => {
  return (
    <main className="homepageContainer">
      <div className="innerContainer">
        <div className="oyolaWalletStyles">
          <div className="containerRight">
            <h1 className="title">Oyola Wallet</h1>
            <p className="tag">
              The Oyola wallet allows you to keep your funds secure and safe, without any fees. You can easily make
              transactions directly from your Oyola wallet to other Oyola wallets at no additional cost.
            </p>
          </div>

          <div className="containerLeft">
            <img src={assetsControlImage} alt="control over your assets" className="imageContainer" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default OyolaWallet;
