import APIs from "./api";

const LandingServices = {
  getCoinsData: async () => {
    return APIs.get("/api/v1/open/get-tokens")
      .then((res) => {
        if (res.data.message === "success") {
          return res;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },
  getPaymentData: async () => {
    return APIs.get("/api/v1/open/get-payment-data")
      .then((res) => {
        if (res.data.message === "success") {
          return res;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getCountryData: async () => {
    return APIs.get("/api/v1/open/get-countries-data")
      .then((res) => {
        if (res.data.message === "success") {
          return res;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getGeoLocationData: async () => {
    return APIs.get("/api/v1/open/get-ip-location")
      .then((res) => {
        if (res.data.message === "success") {
          return res;
        }
      })
      .catch((err) => {
        throw new Error(err);
      });
  },

  getMarketPlaceBuyAndSell: (args) => {
    if (args?.amount) {
      return APIs.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}/${args.amount}`
      )
        .then(({data}) => {
          if (data?.message === "success") {
            return data;
          }
        })
        .catch((error) => {
          throw error;
        });
    } else {
      return APIs.get(
        `/api/v1/open/marketplace/${args?.country}/${args.token}/${args.type}/${args?.paymentMethod}/${args.skip}/${args?.limit}`
      )
        .then(({data}) => {
          if (data?.message === "success") {
            return data;
          }
        })
        .catch((error) => {
          throw error;
        });
    }
  },
};

export default LandingServices;
