import {useCallback, useEffect, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {allReviewsPageAction} from "../../store/actions/profile";
import AuthService from "../../services/auth";
import {Skeleton, Stack} from "@mui/material";
import AllComments from "./allComments";
import PositiveComments from "./positiveComments";
import NegativeComments from "./negativeComments";

import {useParams} from "react-router-dom";
import {
  PAGINATION_ARRAY_LIST,
  PAGINATION_ARRAY_LIST_NEGATIVE,
  PAGINATION_ARRAY_LIST_POSITIVE,
  PROFILE_PAGINATION_TOTAL,
  PROFILE_PAGINATION_TOTAL_NEGATIVE,
  PROFILE_PAGINATION_TOTAL_POSITIVE,
} from "../../store/types";
import {toast} from "react-toastify";

const AllReviews = () => {
  const otherUsername = useParams();

  const dispatch = useDispatch();
  const {allReviewPageState, saveOtherUsernameState} = useSelector((state) => state.profileReducer);

  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [isLoading, setIsLoading] = useState(false);

  const {profileHeaderOtherDataReducer} = useSelector((state) => state.profileReducer);

  const fetchProfileData = useCallback(() => {
    setIsLoading(true);

    if (profileHeaderOtherDataReducer.username !== "") {
      AuthService.profileData(saveOtherUsernameState ? saveOtherUsernameState : otherUsername)
        .then((data) => {
          // if (data.messsage === "success") {
          dispatch(allReviewsPageAction(data?.userData?.reviews));
          setIsLoading(false);
          // }
        })
        .catch(() => {
          // toast("Something went wrong");
          setIsLoading(false);
        });
    }
  }, [dispatch, profileHeaderOtherDataReducer, otherUsername, saveOtherUsernameState]);

  useMemo(() => {
    fetchProfileData();
  }, [fetchProfileData]);

  const paginateReviews = () => {
    const filteredObjects = allReviewPageState.filter(
      (filter) => filter.reviewerUsername !== profileHeaderOtherDataReducer?.username
    );

    const filteredPositive = allReviewPageState.filter(
      (positive) => positive.isTrust === true && positive.reviewerUsername !== profileHeaderOtherDataReducer?.username
    );

    const filteredNegative = allReviewPageState.filter(
      (negative) => negative.isTrust === false && negative.reviewerUsername !== profileHeaderOtherDataReducer.username
    );

    if (filteredObjects) {
      const parentArray = [];
      const total_arrays_value = filteredObjects.length / 5;

      for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
        parentArray.push(filteredObjects.splice(0, 5));
      }

      dispatch({type: PROFILE_PAGINATION_TOTAL, payload: Math.ceil(total_arrays_value)});
      dispatch({type: PAGINATION_ARRAY_LIST, payload: parentArray});
    }

    if (filteredPositive) {
      const parentArray = [];
      const total_arrays_value = filteredPositive.length / 5;

      for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
        parentArray.push(filteredPositive.splice(0, 5));
      }

      dispatch({type: PROFILE_PAGINATION_TOTAL_POSITIVE, payload: Math.ceil(total_arrays_value)});
      dispatch({type: PAGINATION_ARRAY_LIST_POSITIVE, payload: parentArray});
    }

    if (filteredNegative) {
      const parentArray = [];
      const total_arrays_value = filteredNegative.length / 5;

      for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
        parentArray.push(filteredNegative.splice(0, 5));
      }

      dispatch({type: PROFILE_PAGINATION_TOTAL_NEGATIVE, payload: Math.ceil(total_arrays_value)});
      dispatch({type: PAGINATION_ARRAY_LIST_NEGATIVE, payload: parentArray});
    }
  };

  useEffect(() => {
    paginateReviews();
  }, [allReviewPageState]);

  return (
    <main className="feedbackTab">
      <section className="feedbackTab__blocTabs">
        <div>
          <button
            className={toggleState === 1 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(1)}
          >
            All
          </button>
          <button
            className={toggleState === 2 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(2)}
          >
            Positive
          </button>
          <button
            className={toggleState === 3 ? "feedbackTab__Tabs feedbackTab__activeTabs" : "feedbackTab__Tabs"}
            onClick={() => toggleTab(3)}
          >
            Negative
          </button>
        </div>
      </section>

      <section className="feedbackTab__contentTabs">
        <div className={toggleState === 1 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <AllComments />
          )}
        </div>

        <div className={toggleState === 2 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <PositiveComments />
          )}
        </div>

        <div className={toggleState === 3 ? "feedbackTab__content feedbackTab__activeContent" : "feedbackTab__content"}>
          {isLoading ? (
            <Stack spacing={3}>
              <Skeleton animation="wave" variant="rectangular" width={"90%"} height={60} />
            </Stack>
          ) : (
            <NegativeComments />
          )}
        </div>
      </section>

      {/* <button onClick={prevPage}>prev</button>
      <button onClick={nextPage}>next</button> */}
    </main>
  );
};

export default AllReviews;
