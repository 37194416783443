import {useEffect} from "react";
import CurrencyFormat from "react-currency-format";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams, Link} from "react-router-dom";
import {Avatar} from "../../../hooks/imagePath";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {getChatMessagesAction} from "../../../store/actions/chatMessage";
import "../../../styles/createTrade/chatScreen.scss";
import MessagesArea from "./messagesArea";
import SendMessageArea from "./sendMessage";
import {FaCheckCircle} from "react-icons/fa";
import {BsFillCircleFill, BsFillTelephoneFill} from "react-icons/bs";
import {getTimeDifference} from "../../../utils/getTimeDiff";

const CreateTradeChat = ({setClose, style}) => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {id} = useParams();
  const {response} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(response ?? {});
  const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  const getLastTimeSeen = useSelector((state) => state.createTradeReducer.getLastTimeSeen);

  const profileData = () => {
    if (userType === "user") {
      return response?.profileData?.traderData;
    } else if (userType === "trader") {
      return response?.profileData?.userData;
    }
  };

  let profileImage = profileData()?.profileImageUrl
    ? `https://oyola.s3.amazonaws.com/${profileData()?.profileImageUrl}`
    : Avatar;

  useEffect(() => {
    dispatch(getChatMessagesAction(id));
  }, []);

  return (
    <div className="__chat__area__wrapper" style={style}>
      <div className="__chat__area__top">
        {/* <div className="__mobile__chat__icon" onClick={() => setClose(false)}>
          <GrFormClose size={22} />
        </div> */}

        <div className="__chat__user__details">
          <div className="__chat__user__icon">
            <img className="__chat__user__image" src={profileImage} alt="chat__user" />
            <div className="__chat__user__symbol">
              <BsFillCircleFill
                className="icon"
                style={{
                  color: `${
                    getTimeDifference(getLastTimeSeen) < 5
                      ? "#59C14C"
                      : getTimeDifference(getLastTimeSeen) > 5 && getTimeDifference(getLastTimeSeen) < 30
                      ? "#F8E3B2"
                      : "#DBDBDB"
                  }`,
                }}
              />
            </div>
          </div>

          <div className="__chat__user__details">
            <Link className="__chat__user__name" target={"_blank"} to={`/app/profile/${profileData()?.username}`}>
              {profileData()?.fullname ? `${profileData()?.fullname} ` : `@${profileData()?.username}`}
            </Link>
            <div className="__chat__user__trades__completion">
              <div className="__chat__user__trades">
                <span className="__chat__user__trades_title">Trades:</span>
                <span className="__chat__user__trades_value">
                  {userType === "user" ? profileData()?.traderTrades : profileData()?.userTrades},
                </span>
              </div>
              <div className="__chat__user__trades">
                {/* <span className="__chat__user__trades_title">Completion Rate:</span> */}
                <span className="__chat__user__trades_value">
                  {userType === "user" ? (
                    profileData()?.traderTrades === 0 ? (
                      0
                    ) : (
                      <CurrencyFormat
                        value={Math.round((profileData()?.traderTradesCompleted / profileData()?.traderTrades) * 100)}
                        displayType={"text"}
                        thousandSeparator={true}
                        fixedDecimalScale={false}

                        // prefix={"$"}
                        // suffix={` ${tradeResponse?.tokenSymbol}`}
                      />
                    )
                  ) : profileData()?.userTrades === 0 ? (
                    0
                  ) : (
                    <CurrencyFormat
                      value={Math.round((profileData()?.userTradesCompleted / profileData()?.userTrades) * 100)}
                      displayType={"text"}
                      thousandSeparator={true}
                      // decimalScale={3}
                      fixedDecimalScale
                      // prefix={"$"}
                      // suffix={` ${tradeResponse?.tokenSymbol}`}
                    />
                  )}
                  %
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="the__v__and__m">
          <div className="__chat__user__verified">
            <div className="__chat__user__verified_stated">
              <span
                className="__verified__text"
                style={{
                  color: `${
                    userType === "user" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : userType === "trader" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : "#667085"
                  }`,
                }}
              >
                {userType === "user" && profileData()?.isVerified
                  ? "Verified"
                  : userType === "trader" && profileData()?.isVerified
                  ? "Verified"
                  : "Unverified"}
              </span>

              {userType === "user" && profileData()?.isVerified ? (
                <FaCheckCircle
                  color={`${
                    userType === "user" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : userType === "trader" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : "gray"
                  }`}
                  className="__verified__image"
                />
              ) : userType === "trader" && profileData()?.isVerified ? (
                <FaCheckCircle
                  color={`${
                    userType === "user" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : userType === "trader" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : "gray"
                  }`}
                  className="__verified__image"
                />
              ) : (
                <FaCheckCircle
                  color={`${
                    userType === "user" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : userType === "trader" && profileData()?.isVerified
                      ? "#BCA5EC"
                      : "gray"
                  }`}
                  className="__verified__image"
                />
              )}
            </div>
            <div className="__chat__user__verified_stated_call">
              <div className="__chat__user__verified_stated_call_container">
                <div className="__chat__user__verified_stated_call_container_icon_body">
                  <BsFillTelephoneFill className="__chat__user__verified_stated_call_container_icon" />
                </div>
                <p className="__chat__user__verified_stated_call_container_text">Call</p>
              </div>
            </div>
          </div>
          {statusResponse?.moderatorData && statusResponse?.disputed === true && (
            <div className="flex moderatedWrapper">
              <span>Moderated</span>
              <div
                className={`circle ${
                  !(statusResponse?.status === "completed" || statusResponse?.status === "cancelled") && "__green"
                }`}
              ></div>
            </div>
          )}
        </div>
      </div>
      <div className="__chat__area__bottom">
        <MessagesArea />
      </div>
      <SendMessageArea />
    </div>
  );
};

export default CreateTradeChat;
