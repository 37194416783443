import ChatService from "../../services/chat";
import {GET_MESSAGES, GET_MESSAGES_WITHOUT_LOADING} from "../types";

export const getChatMessagesAction = (args) => (dispatch) => {
  return ChatService.getMessages(args).then((data) => {
    dispatch({type: GET_MESSAGES, payload: data});
  });
};
export const getChatMessagesNoLoaderAction = (args) => (dispatch) => {
  return ChatService.getMessages2(args).then((data) => {
    dispatch({type: GET_MESSAGES_WITHOUT_LOADING, payload: data});
  });
};
