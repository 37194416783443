import React, {memo, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {forgotPasswordLoader, loginDetailsSecond} from "../../store/actions/auth";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/auth";
import {toast} from "react-toastify";
import {NewFormInput} from "../Common/NewFormInput";
import "../../styles/login.scss";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import "../../styles/new/optStyles.scss";
import LoginOTPInput from "../Common/loginOtpInput";

const ForgotOTPPhoneNumber = () => {
  const [hidden, setHidden] = useState(false);
  const [hiddenCfm, setHiddenCfm] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const [password, setPassword] = useState("");

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);

  const [emailOtp, setEmailOtp] = useState("");
  const [phoneOtp, setPhoneOtp] = useState("");

  const [error, setError] = useState(false);

  const [showEmptyPasswordError, setShowEmptyPasswordError] = useState(false);
  const [showErrorOtp, setShowErrorOtp] = useState(false);
  const [showErrorOtpPhone, setShowErrorOtpPhone] = useState(false);
  const [match, setMatch] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  useMemo(() => showEmptyPasswordError, [showEmptyPasswordError]);
  useMemo(() => showErrorOtp, [showErrorOtp]);
  useMemo(() => match, [match]);
  useMemo(() => passwordLength, [passwordLength]);
  useMemo(() => showErrorOtpPhone, [showErrorOtpPhone]);

  const loginDetails = useSelector((state) => state.authReducer.loginDetails);
  const loginDetailDataSecond = useSelector((state) => state.authReducer.loginDetailsSecond);

  const handleEmailChange = (emailOtp) => {
    setEmailOtp(emailOtp);
  };
  const handlePhoneChange = (phoneOtp) => {
    setPhoneOtp(phoneOtp);
  };

  const goBack = () => {
    dispatch(forgotPasswordLoader(0));
    loginDetailDataSecond("");
  };

  const validateCredentials = (e) => {
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "password": {
        setPassword(e.target.value);
        // setValidPassword(PWD_REGEX.test(e.target.value));
        break;
      }

      case "confirm_password": {
        setConfirmPassword(e.target.value);
        setValidConfirmPassword(password === e.target.value);
        break;
      }

      default: {
        break;
      }
    }
  };

  const goToHome = () => {
    const payload = {
      emailCode: emailOtp,
      phoneCode: phoneOtp,
      authType: "sms",
      password: password,
      email: loginDetails.email,
    };

    if (password.length !== 0 && confirmPassword.length !== 0) {
      setShowEmptyPasswordError(false);
      if (password.length > 6 && confirmPassword.length > 6) {
        setPasswordLength(false);
        if (validConfirmPassword) {
          setMatch(false);
          if (emailOtp.length === 6) {
            setShowErrorOtp(false);
            if (phoneOtp.length === 6) {
              setShowErrorOtpPhone(false);

              dispatch(forgotPasswordLoader(1));

              AuthService.changePassword(payload).then((data) => {
                if (data?.data?.message === "success") {
                  toast("Your password has been changed successfully");
                  history.push("/login");
                  dispatch(forgotPasswordLoader(0));
                } else {
                  dispatch(forgotPasswordLoader(3));
                  setError(true);
                  dispatch(loginDetailsSecond("Invalid code or code expired"));
                }
              });
            } else {
              setShowErrorOtpPhone(true);
            }
          } else {
            setShowErrorOtp(true);
          }
        } else {
          setMatch(true);
        }
      } else {
        setPasswordLength(true);
      }
    } else {
      setShowEmptyPasswordError(true);
    }
  };

  return (
    <>
      <div className="login-container" style={{height: "100vh"}}>
        <div className="login-content">
          <div className="form-contents">
            <p className="title">Set new password</p>
            <p className="tag">Please type in the code received in your email & phone number</p>

            <NewFormInput
              id="password"
              margin={"30px"}
              label={"New Password"}
              type={hidden ? "text" : "password"}
              value={password}
              onChange={validateCredentials}
              width="100%"
              icon={
                hidden ? (
                  <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                ) : (
                  <AiOutlineEye fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                )
              }
            />

            <NewFormInput
              id="confirm_password"
              margin={"10px"}
              label={"Confirm new password"}
              type={hiddenCfm ? "text" : "password"}
              value={confirmPassword}
              onChange={validateCredentials}
              width="100%"
              icon={
                hiddenCfm ? (
                  <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHiddenCfm(!hiddenCfm)} />
                ) : (
                  <AiOutlineEye fontSize={"2rem"} onClick={() => setHiddenCfm(!hiddenCfm)} />
                )
              }
            />
            {/* {showError === false ? (
              <p></p>
            ) : (
              <p style={{textAlign: "left", marginTop: "10px", width: "100%", color: "#CF304A"}}>
                Password and confirm password does not match
              </p>
            )} */}

            {showEmptyPasswordError && match === false && passwordLength === false ? (
              <span style={errorStyle}>
                <p>Please enter your password</p>
              </span>
            ) : null}

            {match && showEmptyPasswordError === false && passwordLength === false ? (
              <span style={errorStyle}>
                <p>Passwords do not match</p>
              </span>
            ) : null}

            {passwordLength && match === false && showEmptyPasswordError === false ? (
              <span style={errorStyle}>
                <p>Password must be greater than 6 characters</p>
              </span>
            ) : null}

            <div style={{width: "100%", marginTop: "1rem"}}>
              <p style={{fontSize: "1.2rem"}}>Email otp code</p>
              {/* || showErrorOtpPhone === true */}
              {showErrorOtp ? (
                <div style={{...errorStyle, marginTop: "1rem"}}>
                  <p>Enter code that you received via email</p>
                </div>
              ) : null}
              {!error === true ? <p style={errorStyle}>{loginDetailDataSecond}</p> : null}
              <LoginOTPInput value={emailOtp} onChange={handleEmailChange} error={loginDetailDataSecond} />
            </div>

            <div style={{width: "100%"}}>
              <p style={{fontSize: "1.2rem"}}>Phone number otp code</p>
              {/* || showErrorOtp === false */}
              {showErrorOtpPhone ? (
                <div style={{...errorStyle, marginTop: "1rem"}}>
                  <p>Enter code that you received via phone</p>
                </div>
              ) : null}
              {!error === true ? <p style={errorStyle}>{loginDetailDataSecond}</p> : null}

              <LoginOTPInput value={phoneOtp} onChange={handlePhoneChange} error={loginDetailDataSecond} />
            </div>

            <button className="buttonStyle" style={signUp} onClick={goToHome}>
              Set new password
            </button>
            <button className="buttonStyle" style={goBackStyle} onClick={goBack}>
              back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const errorStyle = {
  color: "#F04438",
  textAlign: "left",
  marginTop: "1rem",
  marginBottom: "1rem",
  width: "100%",
  fontSize: "1.2rem",
  fontWeight: 600,
};

const signUp = {
  width: "100%",
  color: "#fff",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
};

const goBackStyle = {
  width: "100%",
  background: "#fff",
  color: "black",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
};

export default memo(ForgotOTPPhoneNumber);
