import {
  LOGIN,
  LOADING_STATE,
  REGISTER_LOADING_STATE,
  FORGOT_PASSWORD_LOADING_STATE,
  LOGIN_ERRORS,
  ACTIVE_INPUT,
  LOGIN_DETAILS,
  LOGIN_DETAILS_SECOND,
  ALLOWED_ACCESS,
  LOGOUT,
  REGISTER_ERROR_DETAILS_FOR_EMAIL,
  REGISTER_ERROR_FOR_USERNAME,
  CLEAR_USER,
  LOGIN_ERROR_ACTION,
  PASSWORD_DETECT_ERROR_STATE,
  STORE_LOGIN_DETAILS_ACTION,
  STORE_LOGIN_GOOGLE_OTP_ACTION,
  STORE_LOGIN_OTP_ACTION,
} from "../types";

export const goToHome = (param) => (dispatch) => {
  dispatch({type: LOGIN, payload: {user: {}, token: "", isLoggedIn: true}});
  param.push("/");
};
export const loginAction = (data) => (dispatch) => {
  dispatch({type: LOGIN, payload: {token: data, isLoggedIn: true}});
};

export const loader = (param) => (dispatch) => {
  dispatch({type: LOADING_STATE, payload: param});
};

export const registerLoader = (param) => (dispatch) => {
  dispatch({type: REGISTER_LOADING_STATE, payload: param});
};

export const forgotPasswordLoader = (param) => (dispatch) => {
  dispatch({type: FORGOT_PASSWORD_LOADING_STATE, payload: param});
};

export const loginError = (param) => (dispatch) => {
  dispatch({type: LOGIN_ERRORS, payload: param});
};

export const otpInput = (param) => (dispatch) => {
  dispatch({type: ACTIVE_INPUT, payload: param});
};

export const loginDetails = (param) => (dispatch) => {
  dispatch({type: LOGIN_DETAILS, payload: param});
};

export const displayUsernameNotAvailableAction = (param) => (dispatch) => {
  dispatch({type: REGISTER_ERROR_FOR_USERNAME, payload: param});
};
export const registerErrorDetailsForEmail = (param) => (dispatch) => {
  dispatch({type: REGISTER_ERROR_DETAILS_FOR_EMAIL, payload: param});
};

export const loginDetailsSecond = (param) => (dispatch) => {
  dispatch({type: LOGIN_DETAILS_SECOND, payload: param});
};

export const allowAccess = (param) => (dispatch) => {
  dispatch({type: ALLOWED_ACCESS, payload: param});
};

export const logout = () => (dispatch) => {
  localStorage.clear();
  dispatch({type: LOGOUT, payload: false});
  dispatch({type: CLEAR_USER, payload: false});
};

export const loginErrorAction = () => (dispatch) => {
  dispatch({type: LOGIN_ERROR_ACTION, payload: dispatch});
};

export const passwordDetectErrorStateAction = (param) => (dispatch) => {
  dispatch({type: PASSWORD_DETECT_ERROR_STATE, payload: param});
};

export const storeLoginDetailsAction = (param) => (dispatch) => {
  dispatch({type: STORE_LOGIN_DETAILS_ACTION, payload: param});
};

export const storeLoginGoogleOtpAction = (param) => (dispatch) => {
  dispatch({type: STORE_LOGIN_GOOGLE_OTP_ACTION, payload: param});
};
export const storeLoginOtpAction = (param) => (dispatch) => {
  dispatch({type: STORE_LOGIN_OTP_ACTION, payload: param});
};
