import React from "react";
import WalletPage from "../Wallet";

const Wallet = () => {
  return (
    <React.Fragment>
      <div style={overview}>
        <WalletPage />
      </div>
    </React.Fragment>
  );
};

const overview = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export default Wallet;
