import {
  PROFILE_DATA,
  LIST_OF_BLOCKED_USERS,
  LIST_OF_REVIEWS,
  ALL_PAGE_REVIEWS,
  BLOCK_A_USER_PROFILE,
  UNBLOCK_A_USER_PROFILE,
  BLOCK_A_USER_MODAL,
  PROFILE_HEADER_OTHER_DATA,
  CHECK_IF_A_USER_IS_BLOCKED,
  ALL_REVIEW_PAGE_ACTION,
  SAVE_OTHER_USERNAME_ACTION,
} from "../types";

export const getProfileData = (param) => (dispatch) => {
  dispatch({type: PROFILE_DATA, payload: param});
};

export const listOfBlockedUsers = (param) => (dispatch) => {
  dispatch({type: LIST_OF_BLOCKED_USERS, payload: param});
};

export const listOfReviews = (param) => (dispatch) => {
  dispatch({type: LIST_OF_REVIEWS, payload: param});
};

export const listOfPaginationData = (param) => (dispatch) => {
  dispatch({type: ALL_PAGE_REVIEWS, payload: param});
};

export const blockAUser = (param) => (dispatch) => {
  dispatch({type: BLOCK_A_USER_PROFILE, payload: param});
};

export const unblockAUser = (param) => (dispatch) => {
  dispatch({type: UNBLOCK_A_USER_PROFILE, payload: param});
};
export const checkIfAUserIsBlocked = (param) => (dispatch) => {
  dispatch({type: CHECK_IF_A_USER_IS_BLOCKED, payload: param});
};

export const blockAUserModal = (param) => (dispatch) => {
  dispatch({type: BLOCK_A_USER_MODAL, payload: param});
  // blockAUserModal
};

export const profileHeaderOtherAction = (param) => (dispatch) => {
  dispatch({type: PROFILE_HEADER_OTHER_DATA, payload: param});
};

// export const profileHeaderOtherAction = (param) => (dispatch) => {
//   dispatch({type: PROFILE_HEADER_OTHER_DATA, payload: param});
// };

export const allReviewsPageAction = (param) => (dispatch) => {
  dispatch({type: ALL_REVIEW_PAGE_ACTION, payload: param});
};

export const saveOtherUserNameAction = (param) => (dispatch) => {
  dispatch({type: SAVE_OTHER_USERNAME_ACTION, payload: param});
};
