import React, {memo} from "react";
import ProfileImage from "../Common/ProfileImage";
import {useHistory, useParams} from "react-router-dom";
import PersonalInfo from "./PersonalInfo";
import SmallNumber from "small-number";
import {useSelector} from "react-redux";
import "../../styles/profile.scss";
import moment from "moment";
import {CountryIcons} from "../Common/CountryIcons";

const ProfileHeader = () => {
  const location = useParams();

  const userData = useSelector((state) => state.generalReducer.userData);
  const data = userData?.data?.userData ?? "";
  const trusts = Number(data?.trusts) ?? 0;
  const distrust = Number(data?.distrusts) ?? 0;

  return (
    <React.Fragment>
      <section className="profile-header-container" style={{border: "none"}}>
        <div className="profile-left-container">
          <div className="box-one">
            <ProfileImage />
            <div style={{marginLeft: "10px"}}>
              <p className="textStyle" style={{margin: "0 0 8px"}}>
                @{location.username}
              </p>
              <p className="textStyle" style={{fontWeight: 400, fontSize: "14px", margin: "0 0 8px"}}>
                Joined {moment(data?.timeStamp).format("MMMM YYYY")}
              </p>
              <p
                className="textStyle"
                style={{
                  fontWeight: 400,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                  margin: "0",
                }}
              >
                <CountryIcons intialValue={data?.userCountry} />
                {data?.userCountry}
              </p>
            </div>
          </div>

          <div className="box-two">
            <div className="box-child">
              <p style={{fontSize: "1.2rem"}}>Review </p>

              <div style={{display: "flex", marginTop: "10px"}}>
                <p style={{fontWeight: "600", fontSize: "1.2rem"}}>
                  {trusts > 0 || distrust > 0 ? Number(trusts / (trusts + distrust)).toFixed(3) * 100 : 0} %
                </p>
                <p style={{marginLeft: "5px", fontSize: "1.2rem"}}>({trusts + distrust})</p>
              </div>
            </div>

            <div className="box-child">
              <p>&nbsp;</p>
              <div style={{display: "flex", marginTop: "10px"}}>
                <p style={{fontSize: "1.2rem"}}>Positive</p>
                <p className="positive-child">{SmallNumber(Number(trusts))}</p>
              </div>
            </div>

            <div className="box-child">
              <p>&nbsp;</p>
              <div style={{display: "flex", marginTop: "10px"}}>
                <p style={{fontSize: "1.2rem"}}>Negative</p>
                <p className="negative-child">{SmallNumber(Number(distrust))}</p>
              </div>
            </div>
          </div>
        </div>

        <ButtonEdit />
      </section>

      <PersonalInfo />
    </React.Fragment>
  );
};

const ButtonEdit = () => {
  const history = useHistory();
  return (
    <button
      className="buttonStyle topRightButtonEditStyle"
      onClick={() => {
        history.push("/app/settings");
      }}
    >
      Edit Profile
    </button>
  );
};

export default memo(ProfileHeader);
