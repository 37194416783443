import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import Slide1 from "../../assets/icons/REFER.png";
import Slide2 from "../../assets/icons/blue.png";
import Slide3 from "../../assets/icons/black.png";
import "../../styles/overviewStyles/overviewAds.scss";

const OverviewAds = () => {
  const [index, setIndex] = useState(0);

  const timeoutRef = useRef(null);
  const timeoutRefTech = useRef(null);
  // const timeoutRef = useRef(null);

  let timeIntervals = 5000;

  const Slider = useCallback(() => {
    setIndex((prevIndex) => (prevIndex === SliderArray.length - 1 ? 0 : prevIndex + 1));
  }, [index]);

  const SliderReducer = useCallback(() => {
    setIndex((prevIndex) => (prevIndex === 0 ? SliderArray.length - 1 : prevIndex - 1));
  }, [index]);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(Slider, timeIntervals);

    return () => {
      resetTimeout();
    };
  }, [index]);

  return (
    <div className="overView__extensionSecondLayout">
      {SliderArray?.map((item, key) => (
        <div key={key}>
          {index === key && (
            <div className="overView__extensionSecondLayoutAds">
              <img src={item.status} alt="slider" />
            </div>
          )}
        </div>
      ))}
      <div className="overView__extensionSecondLayoutAdsIcons">
        {SliderArray?.map((item, key) => (
          <div
            key={key}
            onClick={() => setIndex(key)}
            className={`overView__extensionSecondLayoutAdsIconsBox ${
              key === index && "overView__extensionSecondLayoutAdsIconsBoxSuccess"
            }`}
          ></div>
        ))}
      </div>
    </div>
  );
};

const SliderArray = [{status: Slide1}, {status: Slide2}, {status: Slide3}];

export default memo(OverviewAds);
