import React, {createContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {Loader3} from "../Common/Loader";
import Orders from "../Profile/Orders";
import ProfileHeader from "../Profile/ProfileHeader";
import ProfileHeaderOther from "../Profile/ProfileHeaderOther";
import Reviews from "../Profile/Reviews";
import {TradeActionModalProfile} from "../Profile/tradeActionModal";

export const TradeActionContext = createContext();

const Profile = () => {
  const [modalAction, setModalAction] = useState(false);
  const [modalID, setModalID] = useState();
  const otherUsername = useParams();
  const {userData} = useSelector((state) => state.generalReducer);

  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const ProfileDecider = () => {
    if (username === otherUsername.username) {
      return (
        <>
          <ProfileHeader />
          <Reviews username={username} />
        </>
      );
    } else {
      return (
        <>
          <ProfileHeaderOther username={otherUsername.username} />
          <Reviews username={otherUsername.username} />
          <Orders username={otherUsername.username} />
        </>
      );
    }
  };

  return (
    <TradeActionContext.Provider value={{modalAction, setModalAction, modalID, setModalID}}>
      <React.Fragment>
        {!userData?.data?.userData ? (
          <div style={{display: "flex", width: "100%", justifyContent: "center", height: "80vh", alignItems: "center"}}>
            <Loader3 />
          </div>
        ) : (
          ProfileDecider()
        )}
        <TradeActionModalProfile />
      </React.Fragment>
    </TradeActionContext.Provider>
  );
};

export default Profile;
