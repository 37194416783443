import {useState, useCallback, useRef} from "react";
import {IoMdArrowDropdown, IoMdArrowDropup} from "react-icons/io";
import {BiCheck} from "react-icons/bi";
import "../../styles/staticdropdown.scss";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const TransactionTableStaticDropDown = ({name, options, onChangeFunction}) => {
  const [showDropdown, setShowDropDown] = useState(false);
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  const ref = useRef();
  const [value, setValue] = useState();

  const handleClick = () => {
    if (showSelectCondition === false) {
      setShowDropDown(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setShowDropDown(false);
      setShowSelectCondition(false);
    }
  };

  useOnClickOutside(
    ref,
    useCallback(() => setShowDropDown(false), [])
  );

  return (
    <>
      <div className="newTable__th">
        <div className="staticDropDown__wrapper" onClick={handleClick}>
          <span className="__ff">
            {name}: {value && value.label}
          </span>
          {showDropdown ? (
            <IoMdArrowDropdown size={16} className="staticDropDown__icon" />
          ) : (
            <IoMdArrowDropup size={16} className="staticDropDown__icon" />
          )}
        </div>
        {showDropdown && (
          <div className="staticDropDown__content__wrapper" ref={ref} data-aos="zoom-in" data-aos-duration="300">
            {options &&
              options.map((item, i) => {
                return (
                  <div
                    onClick={() => {
                      onChangeFunction({[item.attr]: item.value});
                      setValue(item);
                      handleClick();
                    }}
                    className="staticDropDown__contentOptions"
                    key={i}
                  >
                    {item.label} {value && value.value === item.value && <BiCheck size={22} />}
                  </div>
                );
              })}
            <div className="staticDropDown__clearDiv__wrapper">
              <span
                className="staticDropDown__clearDiv__text"
                onClick={() => {
                  setValue(null);
                  onChangeFunction(null);
                  handleClick();
                }}
              >
                clear
              </span>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export {TransactionTableStaticDropDown};
