import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import AccordionTransfer from "./AccordionTransfer";
// import {Pagination} from "./Pagination";
import "../../styles/Notification/notificationStyle.scss";
import {NotificationPaginationComponent} from "../Common/notificationPagination";
import {TRANSFERPAGES, TRANSFERSKIP} from "../../store/types";
import {getTransferNotification} from "../../store/actions/notification";
import NotificationService from "../../services/notification";

const NotificationTransfers = () => {
  const dispatch = useDispatch();
  const notificationDataRedux = useSelector((state) => state.notificationReducer.transfersNotification);
  const {transferSkip, notificationLimit, transferPages} = useSelector((state) => state.notificationReducer);
  const notyData = notificationDataRedux ?? [];

  const IncreamentPage = () => {
    const skip = (transferSkip + 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "withdraw").then((data) => {
      if (data?.data?.notifications.length > 0) {
        dispatch({type: TRANSFERPAGES, payload: transferPages + 1});
        dispatch(getTransferNotification(data?.data?.notifications));
        dispatch({type: TRANSFERSKIP, payload: transferSkip + 1});
      }
    });
  };

  const DecreamentPage = () => {
    const skip = (transferSkip - 1) * 10;
    NotificationService.NotificationsSystem(skip, notificationLimit, "withdraw").then((data) => {
      if (data?.data?.notifications.length > 0 && transferPages > 1) {
        dispatch({type: TRANSFERPAGES, payload: transferPages - 1});
        dispatch(getTransferNotification(data?.data?.notifications));
        dispatch({type: TRANSFERSKIP, payload: transferSkip - 1});
      }
    });
  };

  const filterUnReadMessages = notyData.length > 0 ? notyData.filter((item) => item.unRead === true) : [];

  return (
    <React.Fragment>
      <div className="ReadUnReadWrapper">
        <p className="titleWrapper">Unread messages</p>
        <p className="lengthWrapper">{filterUnReadMessages.length}</p>
      </div>
      <div className="messageWrapper">
        <AccordionTransfer filterTransfers={notyData.length > 0 ? notyData : []} />

        {notificationDataRedux.length > 0 && (
          <NotificationPaginationComponent onNext={IncreamentPage} onPrev={DecreamentPage} skip={transferPages} />
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(NotificationTransfers);
