import React from "react";
import {CoinIcons} from "../../Common/coinSwitcher";
import "./coin-modal.scss";

const ShowCoinModal = React.forwardRef(({data, setData}, ref) => {
  return (
    <div className="navDropContainer" ref={ref}>
      {data?.map(({tokenSymbol}, index) => (
        <li key={index} className="listMenuIcon" onClick={() => setData(tokenSymbol)}>
          <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
            <CoinIcons intialValue={tokenSymbol} />
            <p style={{color: "rgba(0, 0, 0, 0.7)"}}>{tokenSymbol}</p>
          </div>
        </li>
      ))}
    </div>
  );
});

export default ShowCoinModal;
