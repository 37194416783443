import {useContext, useRef, useState} from "react";
import {BiRefresh} from "react-icons/bi";
import {FiChevronDown, FiFilter} from "react-icons/fi";
import {BNB, BTC, ETH, USDT} from "../../hooks/imagePath";
import useWindowDimensions from "../../hooks/useWindowDimension";
import DataRangeComponent from "../Common/dateRange";
import {MultipleFilterDropdownBuySell} from "../Common/multipleFilterDropDown";
import {TransactionStaticDropDown} from "../Common/transactionStaticDropdown";
import FilterMobileComponent from "./mobileFilterModal";
import {TransactionContext} from "../Home/Transaction";
import {useDispatch, useSelector} from "react-redux";
import {
  filterBuySellByCoinAction,
  filterBuySellByDateAction,
  filterBuySellByMultipleAction,
  filterBuySellByStatusAction,
  filterBuySellByTypeAction,
  getBuySellTransactionAction,
  refreshBuySellAction,
} from "../../store/actions/buySellTransactionHistory";
import {
  filterDepositWithdrawByCoinAction,
  filterDepositWithdrawByDateAction,
  filterDepositWithdrawByStatusAction,
  filterDepositWithdrawByTypeAction,
  filterDepositWithdrawMultipleAction,
  getDepositWithdrawTransactionAction,
  refreshDepositWithdrawAction,
} from "../../store/actions/depositWithdrawTransactionHistory";
import {useEffect} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import {type} from "@testing-library/user-event/dist/type";
import SectionModalCentered from "../Common/sectionModalCentered";
import WalletWithdrawHIstory from "../Wallet/walletWithdrawHIstory";

import {TransactionTableStaticDropDown} from "../Common/transactionTableStaticDropdown";
import {
  BUY_SELL_CHECK_FOR_EMPTY,
  BUY_SELL_CHECK_FOR_EXTRA_DATA,
  BUY_SELL_TRADE_DETAILS,
  DEPOSITE_SKIP,
  GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE,
  GET_BUY_SELL_TRANSACTION_INITIAL_FUTURE,
  GET_BUY_SELL_TRANSACTION_SUCCESS,
  TRADE_DETAILS,
  TRANSACTION_WITHDRAWALSTATED,
} from "../../store/types";
import TransactionService from "../../services/transactionHistory";
const TransactionHistoryTop = () => {
  const multipleDropDownRef = useRef();
  const sheetRef = useRef();
  const dispatch = useDispatch();
  const {width} = useWindowDimensions();
  const [filterModal, setFilterModal] = useState(false);
  const [mobileStatus, setMobileStatus] = useState({attr: "status", value: "pending", label: "Pending"});
  const [mobileType, setMobileType] = useState({attr: "type", value: "BTC", label: "BTC"});

  const {setTransactionType, transactionType} = useContext(TransactionContext);
  const modalChecker = useSelector((state) => state.walletReducer.modalChecker);
  const {skip, fskip, limit} = useSelector((state) => state.depositWithdrawaltransactionReducer);

  const {
    skip: dskip,
    fskip: dfskip,
    limit: dlimit,
    checkForExtraData,
    checkForEmpty,
    tradeDetials,
  } = useSelector((state) => state.buySellTransactionReducer);

  // useEffect(() => {
  //   if (transactionType === "buysell") {
  //     dispatch(getBuySellTransactionAction({skip: dfskip, limit: dlimit}));
  //   } else {
  //     dispatch(getDepositWithdrawTransactionAction({skip: fskip, limit}));
  //   }
  // }, [fskip, limit, dfskip, dlimit]);

  useEffect(() => {
    setTransactionType("depo");

    dispatch(filterDepositWithdrawByCoinAction({token: "null"}));

    dispatch(filterDepositWithdrawByTypeAction({type: "null"}));

    dispatch(filterDepositWithdrawByStatusAction({status: "null"}));
  }, []);

  const handleDateChange = (val) => {
    if (transactionType === "buysell") {
      dispatch(filterBuySellByDateAction(val));
    } else {
      dispatch(filterDepositWithdrawByDateAction(val));
    }
  };

  const handleCoinTypeChange = (val) => {
    if (transactionType === "buysell") {
      // dispatch(filterBuySellByCoinAction(val));
      if (val === null) {
        dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, tokenSymbol: val}});
        fetchAllData({...tradeDetials, tokenSymbol: val});
        return;
      }

      dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, tokenSymbol: val?.token}});
      fetchAllData({...tradeDetials, tokenSymbol: val?.token});
    } else {
      if (val === null) {
        dispatch(filterDepositWithdrawByCoinAction({token: null}));
      } else {
        dispatch(filterDepositWithdrawByCoinAction(val));
      }
    }
  };

  const onRefresh = () => {
    if (transactionType === "buysell") {
      // dispatch(refreshBuySellAction());
      dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials}});
      fetchAllData({...tradeDetials});
    } else {
      dispatch(refreshDepositWithdrawAction());
    }
  };

  const handleTypeChange = (val) => {
    if (transactionType === "buysell") {
      if (val === null) {
        dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, type: val}});
        fetchAllData({...tradeDetials, type: val});
        return;
      }

      dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, ...val}});
      fetchAllData({...tradeDetials, ...val});
    } else {
      if (val === null) {
        dispatch(filterDepositWithdrawByTypeAction({type: null}));
      } else {
        dispatch(filterDepositWithdrawByTypeAction(val));
      }
    }
  };

  const handleStatusChange = (val) => {
    if (transactionType === "buysell") {
      if (val === null) {
        dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, status: val}});
        fetchAllData({...tradeDetials, status: val});
        return;
      }

      dispatch({type: BUY_SELL_TRADE_DETAILS, payload: {...tradeDetials, ...val}});
      fetchAllData({...tradeDetials, ...val});
    } else {
      if (val === null) {
        dispatch(filterDepositWithdrawByStatusAction({status: null}));
      } else {
        dispatch(filterDepositWithdrawByStatusAction(val));
      }
    }
  };

  const handleMultiple = (val) => {
    if (transactionType === "buysell") {
      dispatch(filterBuySellByMultipleAction(val));
    } else {
      dispatch(filterDepositWithdrawMultipleAction(val));
    }
  };

  const fetchAllData = (details) => {
    dispatch({
      type: TRADE_DETAILS,
      payload: {type: details?.type, tokenSymbol: details?.tokenSymbol, status: details?.status},
    });

    // TransactionService.getBuySell({
    //   skip: dfskip,
    //   limit: dlimit,
    //   tokenSymbol: details?.tokenSymbol,
    //   type: details?.type,
    //   status: details?.status,
    // }).then((data) => {
    //   dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: []});
    //   if (data?.trades?.length > 0) {
    //     dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data?.trades});
    //     dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: data?.trades});

    //     if (data?.trades?.length === limit) {
    //       dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: true});
    //       const futureSkipDetails = dfskip + dlimit;
    //       TransactionService.getBuySellFuture({
    //         skip: futureSkipDetails,
    //         limit: dlimit,
    //         tokenSymbol: details?.tokenSymbol,
    //         type: details?.type,
    //         status: details?.status,
    //       }).then((data) => {
    //         if (data?.trades?.length === 0) {
    //           dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
    //           dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
    //         } else {
    //           dispatch({type: BUY_SELL_CHECK_FOR_EXTRA_DATA, payload: false});
    //         }
    //       });
    //     }
    //   } else {
    //     dispatch({type: BUY_SELL_CHECK_FOR_EMPTY, payload: []});
    //     dispatch({type: GET_BUY_SELL_TRANSACTION_INITIAL_FORCE_STATE, payload: false});
    //   }
    // });
  };

  return (
    <>
      <div className="transaction__wrapper">
        <div className="transaction__titleWrapper">
          <div className="transaction__titleTitle">
            <h3 className="transaction__title">Transaction History</h3>
            <p className="transaction__titleDesc">View your deposits and withdrawals</p>
          </div>
        </div>

        <div className="filterArea__Wrapper">
          <div className="filter__type">
            <div
              className={`filterArea__buySellWrapper ${
                transactionType === "buysell" && "filterArea__buySellWrapper__change"
              }`}
              onClick={() => {
                handleTypeChange(null);
                setTransactionType("buysell");
              }}
            >
              <span>Trades</span>
            </div>
            <div
              className={`filterArea__dpWrapper ${transactionType === "depo" && "filterArea__buySellWrapper__change"}`}
              onClick={() => {
                dispatch({
                  type: TRANSACTION_WITHDRAWALSTATED,
                  payload: {type: "null", tokenSymbol: "null", status: "null"},
                });

                dispatch({type: DEPOSITE_SKIP, payload: 0});

                setTransactionType("depo");
              }}
            >
              <span>Transfers</span>
            </div>
          </div>

          {width <= 650 ? (
            <div className="__mobile__innnerFilter">
              <div className="__token__Select">
                <TransactionStaticDropDown
                  dropdownOnChangeFunction={handleCoinTypeChange}
                  options={[
                    {
                      attr: "token",
                      value: "BTC",
                      label: (
                        <span className="flex items__center ">
                          <img src={BTC} width="25" alt="btc" className="mr-1" />
                          <p>BTC</p>
                        </span>
                      ),
                    },
                    {
                      attr: "token",
                      value: "ETH",
                      label: (
                        <span className="flex items__center ">
                          <img src={ETH} width="25" alt="btc" className="mr-1" />
                          <p>ETH</p>
                        </span>
                      ),
                    },
                    {
                      attr: "token",
                      value: "USDT",
                      label: (
                        <span className="flex align__center ">
                          <img src={USDT} width="25" alt="btc" className="mr-1" />
                          <p>USDT</p>
                        </span>
                      ),
                    },
                    {
                      attr: "token",
                      value: "BNB",
                      label: (
                        <span className="flex align__center ">
                          <img src={BNB} width="25" alt="btc" className="mr-1" />
                          <p>BNB</p>
                        </span>
                      ),
                    },
                  ]}
                  initialValue={{attr: "coin", value: "All", label: "All"}}
                />
              </div>

              <div className="__more__filters">
                <div className="__refresh">
                  <BiRefresh size={26} onClick={onRefresh} />
                </div>
                <div className="__filter">
                  <FiFilter size={22} onClick={() => setFilterModal(true)} />
                </div>
              </div>
            </div>
          ) : (
            <div className="__date__filter">
              <DataRangeComponent onClickOkay={handleDateChange} />

              <div className="filterArea__Option">
                <MultipleFilterDropdownBuySell onApply={handleMultiple} ref={multipleDropDownRef} />
              </div>
            </div>
          )}
        </div>
        {width >= 650 && (
          <div className="__mainfilterWrapper">
            <div className="__mainfilter__content">
              <div className="__thefilter__item">
                {transactionType === "buysell" ? (
                  <TransactionTableStaticDropDown
                    options={[
                      {
                        value: "buy",
                        attr: "type",
                        label: <span className="__tokenfileterstyle">Buy</span>,
                      },
                      {
                        value: "sell",
                        attr: "type",
                        label: <span className="__tokenfileterstyle">Sell</span>,
                      },
                    ]}
                    name={"Type"}
                    onChangeFunction={handleTypeChange}
                  />
                ) : (
                  <TransactionTableStaticDropDown
                    options={[
                      {
                        value: "deposit",
                        attr: "type",
                        label: <span className="__tokenfileterstyle">Deposit</span>,
                      },
                      {
                        value: "withdraw",
                        attr: "type",
                        label: <span className="__tokenfileterstyle">Withdraw</span>,
                      },
                      {
                        value: "convert",
                        attr: "type",
                        label: <span className="__tokenfileterstyle">Convert</span>,
                      },
                    ]}
                    name={"Type"}
                    // onChangeFunction={handleTypeChange}
                    onChangeFunction={handleTypeChange}
                  />
                )}
              </div>

              <div className="__thefilter__item">
                <TransactionTableStaticDropDown
                  options={[
                    {
                      value: "BTC",
                      attr: "token",
                      label: (
                        <span className="__tokenfileterstyle">
                          <img src={BTC} width="30" alt="btc" className="mr-1" />
                          BTC
                        </span>
                      ),
                    },
                    {
                      value: "BNB",
                      attr: "token",
                      label: (
                        <span className="__tokenfileterstyle">
                          <img src={BNB} width="30" alt="btc" className="mr-1" />
                          BNB
                        </span>
                      ),
                    },
                    {
                      value: "ETH",
                      attr: "token",
                      label: (
                        <span className="__tokenfileterstyle">
                          <img src={ETH} width="30" alt="btc" className="mr-1" />
                          ETH
                        </span>
                      ),
                    },
                    {
                      value: "USDT",
                      attr: "token",
                      label: (
                        <span className="__tokenfileterstyle">
                          <img src={USDT} width="30" alt="btc" className="mr-1" />
                          USDT
                        </span>
                      ),
                    },
                  ]}
                  name={"Coin Type"}
                  onChangeFunction={handleCoinTypeChange}
                />
              </div>
              <div className="__thefilter__item">
                {transactionType === "buysell" ? (
                  <TransactionTableStaticDropDown
                    options={[
                      {
                        value: "completed",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Completed
                          </span>
                        ),
                      },
                      {
                        value: "cancelled",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#B42318",
                              backgroundColor: "#FEF3F2",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Cancelled
                          </span>
                        ),
                      },
                      {
                        value: "pending",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ),
                      },
                    ]}
                    name={"Status"}
                    onChangeFunction={handleStatusChange}
                  />
                ) : (
                  <TransactionTableStaticDropDown
                    options={[
                      {
                        value: "completed",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Completed
                          </span>
                        ),
                      },
                      {
                        value: "cancelled",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#B42318",
                              backgroundColor: "#FEF3F2",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Cancelled
                          </span>
                        ),
                      },
                      {
                        value: "pending",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Pending
                          </span>
                        ),
                      },
                      {
                        value: "aborted",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Aborted
                          </span>
                        ),
                      },
                      {
                        value: "reverted",
                        attr: "status",
                        label: (
                          <span
                            className="__tokenfileterstyle"
                            style={{
                              fontSize: 12,
                              color: "#027A48",
                              backgroundColor: "#ECFDF3",
                              padding: "3px 8px",
                              borderRadius: "4px",
                            }}
                          >
                            Reverted
                          </span>
                        ),
                      },
                    ]}
                    name={"Status"}
                    onChangeFunction={handleStatusChange}
                  />
                )}
              </div>
            </div>
          </div>
        )}
      </div>
      {/* <WhiteMobileModal2 open={filterModal} setOpen={setFilterModal}></WhiteMobileModal2> */}

      <BottomSheet
        ref={sheetRef}
        open={filterModal}
        onDismiss={() => setFilterModal(false)}
        snapPoints={({maxHeight}) => [maxHeight * 0.67]}
        header={
          <h3
            style={{
              textAlign: "center",
              fontSize: "23px",
              fontWeight: 600,

              padding: "10px 0 10px 0",
            }}
          >
            Filter
          </h3>
        }
        footer={
          <div className="__button__wrapper">
            <button className="__cancel__button" onClick={() => setFilterModal(false)}>
              Cancel
            </button>

            <button
              className="__buy__buttonn"
              onClick={() => {
                // onUpdate();
                setFilterModal(false);
              }}
            >
              Confirm
            </button>
          </div>
        }
      >
        <FilterMobileComponent status={mobileStatus} setStatus={setMobileStatus} setType={setMobileType} type={type} />
      </BottomSheet>

      {/* <SectionModalCentered open={modalChecker.num === 4 ? true : false}>
        <WalletWithdrawHIstory />
      </SectionModalCentered> */}
    </>
  );
};

export default TransactionHistoryTop;
