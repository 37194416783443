export const USERNAME_REGEX = /^[A-z][A-z0-9-_]{4,23}$/;
export const PASSWORD_LOGIN_REGEX = /^[A-z][A-z0-9-_]{8,24}$/;
export const EMAIL_REGEX = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
export const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%]).{8,24}$/;
export const LOGIN_PWD_REGEX = /^.{8,24}$/;
export const DIGITS_CODE = /\b\d{1}\b/g;
export const PHONE_NUMBER = /^[\\+]?[(]?[0-9]{3}[)]?[-\s\\.]?[0-9]{3}[-\s\\.]?[0-9]{4,6}$/im;
export const NAME_REGEX =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð0-9 ,.'-]+$/;
export const FIRST_REGEX = /^(?=.{1,50}$)[a-z]+(?:['_.\s][a-z]+)*$/gim;
export const ADDRESS_REGEX = /^[a-zA-Z0-9\s,'-]*$/;
