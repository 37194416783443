import {USER_COIN_LOCAL_VALUE} from "../types";

const initialState = {
  userCoinLocalValue: {},
};

const overviewReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case USER_COIN_LOCAL_VALUE: {
      return {
        ...state,
        userCoinLocalValue: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default overviewReducer;
