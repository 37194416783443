import {
  WALLETBALANCE,
  TOKENNETWORKFEE,
  WALLETOTPERROR,
  MODALCHECKER,
  WITHDRAWALDETAILS,
  DETAILSOFWITHDRAWAL,
  COIN_NETWORK_SELECTION,
  TRANSACTIONHISTORY,
  SETWALLETWITHDRAWALMODAL,
  SETWALLETDEPOSITEMODAL,
  WITHDRAWALMAINDETAILS,
  SCALLING_VALUES,
} from "../types";

export const getWalletBalance = (param) => (dispatch) => {
  dispatch({
    type: WALLETBALANCE,
    payload: param,
  });
};

export const getNetworkFee = (param) => (dispatch) => {
  dispatch({
    type: TOKENNETWORKFEE,
    payload: param,
  });
};

export const getWalletOtpError = (param) => (dispatch) => {
  dispatch({
    type: WALLETOTPERROR,
    payload: param,
  });
};

export const getModalChecker = (param) => (dispatch) => {
  dispatch({
    type: MODALCHECKER,
    payload: param,
  });
};

export const getWithdrawalDetails = (param) => (dispatch) => {
  dispatch({
    type: WITHDRAWALDETAILS,
    payload: param,
  });
};

export const getDetailsOfWithdrawal = (param) => (dispatch) => {
  dispatch({
    type: DETAILSOFWITHDRAWAL,
    payload: param,
  });
};

export const getCoinNetworkSelection = (param) => (dispatch) => {
  dispatch({
    type: COIN_NETWORK_SELECTION,
    payload: param,
  });
};

export const getTransactionHistory = (param) => (dispatch) => {
  dispatch({
    type: TRANSACTIONHISTORY,
    payload: param,
  });
};

export const getWalletModalWithdrawal = (param) => (dispatch) => {
  dispatch({
    type: SETWALLETWITHDRAWALMODAL,
    payload: param,
  });
};

export const getWalletModalDeposit = (param) => (dispatch) => {
  dispatch({
    type: SETWALLETDEPOSITEMODAL,
    payload: param,
  });
};

export const getWithdrawMainDetail = (param) => (dispatch) => {
  dispatch({
    type: WITHDRAWALMAINDETAILS,
    payload: param,
  });
};

export const getScallingValue = (param) => (dispatch) => {
  dispatch({
    type: SCALLING_VALUES,
    payload: param,
  });
};
