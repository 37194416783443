import React, {memo} from "react";
import {useCallback} from "react";
import ListItem from "./ListItem";
import {MdVerified} from "react-icons/md";
import {useSelector, useDispatch} from "react-redux";

import AuthService from "../../services/auth";

import {loginDetails} from "../../store/actions/auth";
import {useEffect} from "react";
import {Skeleton} from "@mui/material";

import "../../styles/profile.scss";
import {toast} from "react-toastify";
const PersonInfo = () => {
  const dispatch = useDispatch();

  // Check userdata & locationData in redux store
  const {userData} = useSelector((state) => state.generalReducer);
  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const profileDataFromRedux = useSelector((state) => state.authReducer.loginDetails);

  const fetchProfileData = useCallback(() => {
    const payload = {
      username,
    };

    if (username !== "") {
      AuthService.profileData(payload)
        .then((data) => {
          dispatch(loginDetails(data.userData));
        })
        .catch(() => {
          toast("Something went wrong");
        });
    }
  }, [username, dispatch]);

  useEffect(() => {
    fetchProfileData();
  }, [userData, fetchProfileData]);

  return (
    <div className="personal-info-boxs-container">
      {!profileDataFromRedux.hasOwnProperty("phoneNumberVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Phone Number"
          verifyStatus={profileDataFromRedux?.phoneNumberVerified === true ? "verified" : "unverified"}
          trailingIcon={
            profileDataFromRedux?.phoneNumberVerified === true ? (
              <MdVerified fill={"green"} />
            ) : (
              <MdVerified fill={"gray"} />
            )
          }
        />
      )}

      {!profileDataFromRedux.hasOwnProperty("email") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Email"
          verifyStatus={profileDataFromRedux?.hasOwnProperty("email") ? "verified" : "unverified"}
          trailingIcon={
            profileDataFromRedux?.hasOwnProperty("email") ? <MdVerified fill={"green"} /> : <MdVerified fill={"gray"} />
          }
        />
      )}

      {!profileDataFromRedux.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="ID"
          verifyStatus={profileDataFromRedux.isVerified === true ? "verified" : "unverified"}
          trailingIcon={
            profileDataFromRedux.isVerified === true ? <MdVerified fill={"green"} /> : <MdVerified fill={"gray"} />
          }
        />
      )}

      {!profileDataFromRedux.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem title="Number of Trade" verifyStatus={`${profileDataFromRedux.numberOfTrades}`} />
      )}

      {!profileDataFromRedux.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Completed Trades"
          verifyStatus={`${profileDataFromRedux.numberOfCompletedTrades} / ${profileDataFromRedux.numberOfTrades}`}
        />
      )}

      {!profileDataFromRedux.hasOwnProperty("isVerified") ? (
        <Skeleton
          animation="wave"
          variant="rectangular"
          width={"200px"}
          height={"70px"}
          style={{background: "white", marginTop: "1rem"}}
        />
      ) : (
        <ListItem
          title="Trade Percentage"
          verifyStatus={
            profileDataFromRedux?.numberOfTrades === 0
              ? `${0} %`
              : Math.round(
                  (profileDataFromRedux?.numberOfCompletedTrades / profileDataFromRedux?.numberOfTrades) * 100
                ) + " %"
          }
        />
      )}
    </div>
  );
};

export default memo(PersonInfo);
