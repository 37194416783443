import ReviewRow from "../Profile/Comment";
import {useDispatch, useSelector} from "react-redux";
import EmptyStateOtherProfileView from "./emptyState";
import {DomPaginationState} from "../Common/DOM_Pagination_State";
import {PAGE_NUM} from "../../store/types";

const AllComments = () => {
  const dispatch = useDispatch();
  const {paginationArrayList, pageNum, profilePaginationTotal} = useSelector((state) => state.profileReducer);

  const allReviewPageState = useSelector((state) => state.profileReducer.allReviewPageState);

  const IncreamentPage = () => {
    if (pageNum + 1 < profilePaginationTotal) {
      dispatch({type: PAGE_NUM, payload: pageNum + 1});
    }
  };

  const DecreamentPage = () => {
    if (pageNum > 0) {
      dispatch({type: PAGE_NUM, payload: pageNum - 1});
    }
  };

  if (paginationArrayList?.length === 0) {
    return <EmptyStateOtherProfileView />;
  }

  if (allReviewPageState?.length > 0) {
    return (
      <>
        {paginationArrayList[pageNum].map((item, index) => (
          <>
            <ReviewRow
              key={index}
              username={item.reviewerUsername}
              userFeeback={item.text}
              isVerified={item.isTrust === true ? true : false}
              transactionId={item.date_added}
            />
          </>
        ))}

        <DomPaginationState onNext={IncreamentPage} onPrev={DecreamentPage} skip={pageNum + 1} />
      </>
    );
  }
};

export default AllComments;
