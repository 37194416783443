import {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getCoinNetworkSelection} from "../../store/actions/walletAction";
import {WALLETNETWORKDATA} from "../../store/types";
import Dropdown from "../Common/Dropdown";

const NetworkDress = () => {
  const dispatch = useDispatch();
  const networkData = useSelector((state) => state.walletReducer.networkData);
  const walletCoinDetails = useSelector((state) => state.walletReducer.walletCoinDetials);
  const coinNetworkSelection = useSelector((state) => state.walletReducer.coinNetworkSelection);

  const HandleClickNetwork = (item) => {
    dispatch(getCoinNetworkSelection({...coinNetworkSelection, network: item.title}));

    dispatch({type: WALLETNETWORKDATA, payload: item?.title});
  };

  return (
    <div>
      <Dropdown
        list={walletCoinDetails.networks}
        select={networkData}
        HandleClickCoin={HandleClickNetwork}
        removeMargin={true}
      />
    </div>
  );
};

export default memo(NetworkDress);
