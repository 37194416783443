import moment from "moment";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CurrencyFormat from "react-currency-format";
import {RiFileCopyFill} from "react-icons/ri";
import {useSelector} from "react-redux";
import {toast} from "react-toastify";
import useWindowDimensions from "../../../hooks/useWindowDimension";

const OrderInfo = () => {
  const {width} = useWindowDimensions();
  const {response} = useSelector((state) => state.getTradeByIdReducer);

  return (
    <div className="__order__info">
      <span className="__order__info">Order Info</span>
      <div className="__order__info__wrapper">
        <div className="__order__info__amount">
          <span className="__order__info__title">Amount:</span>
          <p className="__order__info__value blue">
            <CurrencyFormat
              value={response?.value}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${response?.currency}`}
            />
          </p>
        </div>
        <div className="__order__info__amount">
          <span className="__order__info__title">Coin Type:</span>
          <p className="__order__info__value">{response?.tokenSymbol?.toUpperCase()}</p>
        </div>
        <div className="__order__info__amount">
          <span className="__order__info__title">Unit Price :</span>
          <p className="__order__info__value">
            {" "}
            <CurrencyFormat
              value={response?.unitPrice}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${response?.currency}`}
            />
          </p>
        </div>
        <div className="__order__info__amount">
          <span className="__order__info__title">Quantity:</span>
          <p className="__order__info__value">
            {" "}
            <CurrencyFormat
              value={response?.amount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={5}
              fixedDecimalScale
              // prefix={"$"}
              suffix={` ${response?.tokenSymbol}`}
            />
          </p>
        </div>
        {width <= 730 && (
          <>
            <div
              className="__order__info__amount"
              style={{
                borderTop: "1px solid #EAECF0",
              }}
            >
              <span className="__order__info__title">Order ID:</span>
              <p className="__order__info__value">{response?.orderID}</p>
            </div>
            <div
              className="__order__info__amount"
              style={{
                borderBottom: "1px solid #EAECF0",
              }}
            >
              <span className="__order__info__title">Time Created:</span>
              <p className="__order__info__value">{moment(response?.timeStamp).format("LLL")}</p>
            </div>
          </>
        )}
        <div className="__order__info__amount">
          <span className="__order__info__title">Payment Method:</span>
          <p className="__order__info__value">{response?.paymentMethod}</p>
        </div>
        <div className="__order__info__amount">
          <span className="__order__info__title">Trade ID:</span>
          <p className="__order__info__value">{response?.trade_id}</p>
        </div>
        <div className="__order__info__amount">
          <span className="__order__info__title">Time:</span>
          <p className="__order__info__value">{moment(response?.timeStamp).format("LLL")}</p>
        </div>
        <div className="__otherpayment">
          <div className="__References">References</div>
          <CopyToClipboard text={response?._id} onCopy={() => toast("Reference copied successfully")}>
            <div className="__txId">
              {response?._id} <RiFileCopyFill size={14} style={{marginLeft: "10px"}} />{" "}
            </div>
          </CopyToClipboard>
          <div className="flex" style={{marginTop: "10px", alignItems: "center"}}>
            <div className="__References __country">Country</div>
            <div className="__txId __country" style={{marginTop: 0}}>
              {response?.country}
            </div>
          </div>
          <div className="flex" style={{marginTop: "10px", alignItems: "center"}}>
            <div className="__References __country">TagLine</div>
            <div className="__txId __country" style={{marginTop: 0, fontSize: "13px"}}>
              {response?.tagLine}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderInfo;
