import React, {memo, useMemo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {forgotPasswordLoader} from "../../store/actions/auth";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/auth";
import {PWD_REGEX} from "../../utils/Validators";
import {toast} from "react-toastify";
import {NewFormInput} from "../Common/NewFormInput";
import "../../styles/login.scss";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {FORGOT_GOOGLE_AUTH} from "../../store/types";
import store from "../../store";
import LoginOTPInput from "../Common/loginOtpInput";

const GoogleOTP = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [validPassword, setValidpPassword] = useState(false);

  const [confirmPassword, setConfirmPassword] = useState("");
  const [validConfirmPassword, setValidConfirmPassword] = useState(false);
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);

  const [hidden, setHidden] = useState(false);
  const [hiddenCfm, setHiddenCfm] = useState(false);

  const loginDetails = useSelector((state) => state.authReducer.loginDetails);
  // const loginErrors = useSelector((state) => state.authReducer.loginErrors);
  const useforgotGoogleAuth = useSelector((state) => state.authReducer.forgotGoogleAuth);

  const [showEmptyPasswordError, setShowEmptyPasswordError] = useState(false);
  const [showErrorOtp, setShowErrorOtp] = useState(false);
  const [match, setMatch] = useState(false);
  const [passwordLength, setPasswordLength] = useState(false);

  useMemo(() => showEmptyPasswordError, [showEmptyPasswordError]);
  useMemo(() => showErrorOtp, [showErrorOtp]);
  useMemo(() => match, [match]);

  const handleChange = (otp) => {
    setOtp(otp);
  };

  const goBack = () => {
    dispatch(forgotPasswordLoader(0));
  };

  const validateCredentials = (e) => {
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "password": {
        setValidpPassword(PWD_REGEX.test(e.target.value));
        setPassword(e.target.value);
        break;
      }
      case "confirm_password": {
        setValidConfirmPassword(password === e.target.value);
        setConfirmPassword(e.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const goToHome = async () => {
    const payload = {
      password: password,
      authType: "google auth",
      authCode: otp,
      email: loginDetails.email,
    };

    const giveme = payload;

    if (password.length !== 0 && confirmPassword.length !== 0) {
      setShowEmptyPasswordError(false);
      if (password.length > 6 && confirmPassword.length > 6) {
        setPasswordLength(false);
        if (validConfirmPassword) {
          setMatch(false);
          if (otp.length >= 6) {
            setShowErrorOtp(false);
            dispatch(forgotPasswordLoader(1));

            try {
              const res = await AuthService.changePassword(giveme);
              if (res.data.message === "success") {
                // dispatch(loginDetailsSecond(""));
                toast("Your password has been changed successfully");
                history.push("/login");
                dispatch(forgotPasswordLoader(0));
              }
            } catch {
              store.dispatch({type: FORGOT_GOOGLE_AUTH, payload: "Invalid code or malik code expired"});
              setError(true);
              dispatch(forgotPasswordLoader(4));
            }
            // AuthService.changePassword(payload).then((data) => {
            //   if (data?.data?.message === "success") {
            //     // dispatch(loginDetailsSecond(""));
            //     toast("Your password has been changed successfully");
            //     history.push("/");
            //     dispatch(forgotPasswordLoader(0));
            //   } else {
            //     dispatch({type: FORGOT_GOOGLE_AUTH, payload: "Invalid code or code expired"});
            //     setError(true);
            //     dispatch(forgotPasswordLoader(2));
            //     // dispatch(loginDetailsSecond("Invalid code or code expired"));
            //   }
            // })
          } else {
            setShowErrorOtp(true);
          }
        } else {
          setMatch(true);
        }
      } else {
        setPasswordLength(true);
      }
    } else {
      setShowEmptyPasswordError(true);
    }
  };

  return (
    <>
      <div className="login-container" style={{height: "100vh"}}>
        <div className="login-content">
          <div className="form-contents">
            <p className="title">Set new password</p>
            <p className="tag">Type in the code from your google authenticator app</p>

            <NewFormInput
              id="password"
              margin={"30px"}
              label={"New Password"}
              type={hidden ? "text" : "password"}
              value={password}
              onChange={validateCredentials}
              width="100%"
              displayError={showEmptyPasswordError || match}
              icon={
                hidden ? (
                  <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                ) : (
                  <AiOutlineEye fontSize={"2rem"} onClick={() => setHidden(!hidden)} />
                )
              }
            />

            <NewFormInput
              id="confirm_password"
              margin={"30px"}
              label={"Confirm Password"}
              value={confirmPassword}
              onChange={validateCredentials}
              type={hiddenCfm ? "text" : "password"}
              width="100%"
              displayError={showEmptyPasswordError || match}
              icon={
                hiddenCfm ? (
                  <AiOutlineEyeInvisible fontSize={"2rem"} onClick={() => setHiddenCfm(!hiddenCfm)} />
                ) : (
                  <AiOutlineEye fontSize={"2rem"} onClick={() => setHiddenCfm(!hiddenCfm)} />
                )
              }
            />

            {showEmptyPasswordError && match === false && passwordLength === false ? (
              <span style={errorStyle}>
                <p>Please enter your password</p>
              </span>
            ) : null}

            {match && showEmptyPasswordError === false && passwordLength === false ? (
              <span style={errorStyle}>
                <p>Passwords do not match</p>
              </span>
            ) : null}

            {passwordLength && match === false && showEmptyPasswordError === false ? (
              <span style={errorStyle}>
                <p>Password must be at least 8 characters with 1 upper case letter and 1 special character.</p>
              </span>
            ) : null}

            {!error === true ? <p style={{...errorStyle, marginTop: "2rem"}}>{`${useforgotGoogleAuth}`}</p> : null}

            {showErrorOtp ? <p style={{...errorStyle, marginBottom: "1rem", marginTop: "0rem"}}>Enter Otp</p> : null}

            <LoginOTPInput value={otp} onChange={handleChange} error={useforgotGoogleAuth} />

            <button className="buttonStyle" style={signUp} onClick={goToHome}>
              Set new password
            </button>
            <button className="buttonStyle" style={goBackStyle} color="primary" onClick={goBack}>
              back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const errorStyle = {
  color: "#F04438",
  textAlign: "left",
  marginTop: "1rem",
  marginBottom: "1rem",
  width: "100%",
  fontSize: "1.2rem",
  fontWeight: 600,
};

const signUp = {
  fontSize: "1.4rem",
  marginTop: "15px",
};

const goBackStyle = {
  width: "100%",
  background: "#fff",
  color: "black",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
};

export default memo(GoogleOTP);
