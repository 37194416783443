import React, {memo, useRef, useState} from "react";
import {IoIosArrowUp, IoIosArrowDown} from "react-icons/io";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import "../../../styles/dropdown.scss";
import DropdownItem from "./DropdownItem";

const Dropdown = ({CoinIcons, list, iconLead, select, HandleClickCoin, removeMargin}) => {
  const ref = useRef();
  const [showDropDownConditional, setShowDropDownConditional] = useState(false);
  const [showDropDown, setShowDropDown] = useState(false);

  const HandleShowDropDown = () => {
    if (showDropDownConditional === false) {
      setShowDropDown(true);
      setShowDropDownConditional(true);
      return;
    }

    setShowDropDown(false);
    setShowDropDownConditional(false);
  };

  const HandleShowDropDownReturn = () => {
    setShowDropDown(false);
  };

  useOnClickOutside(ref, () => setShowDropDown(false));

  return (
    <div className="dropDownWrapper">
      <div className="checkBoxBody" style={{marginBottom: `${removeMargin && "3px"}`}} onClick={HandleShowDropDown}>
        <div className="checkboxHeader">
          {iconLead && (
            <div className="checkboxHeaderIconBody">
              <CoinIcons intialValue={select} />
            </div>
          )}
          <p>{select}</p>
        </div>

        <div>
          {showDropDown ? (
            <div className="searchIconBody">
              <IoIosArrowUp className="searchIcon" />
            </div>
          ) : (
            <div className="searchIconBody">
              <IoIosArrowDown className="searchIcon" />
            </div>
          )}
        </div>
      </div>

      <div style={{width: "100%"}}>
        <DropdownItem
          ref={ref}
          list={list}
          showDropDown={showDropDown}
          HandleClickCoin={HandleClickCoin}
          HandleShowDropDownReturn={HandleShowDropDownReturn}
          CoinIcons={CoinIcons}
          iconLead={iconLead}
          select={select}
        />
      </div>
    </div>
  );
};

export default memo(Dropdown);
