import iconCancel from "../../assets/icons/icon-cancel.svg";
import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalInformationDetails, settingVerifyModal} from "../../store/actions/settingAction";
import {MdVerified} from "react-icons/md";
import avatarProfile from "../../assets/icons/avatar-profile-photo.svg";
import notFound from "../../assets/icons/notfound.svg";
import {useHistory} from "react-router-dom";
// import {useHistory} from "react-router-dom";

const ListOfBlockedUsersModal = () => {
  // toggle to next modal
  const listOfBlockUsers = useSelector((state) => state.settingsReducer.listOfBlockUsers);
  const dispatch = useDispatch();

  function cancelButton() {
    dispatch(settingVerifyModal(null));
    dispatch(modalInformationDetails({}));
  }

  return (
    <main className="modal-container" data-aos="zoom-in-down">
      <div className="body">
        <div className="top-section-modal-body">
          <div className="cancel-container">
            <p>Blocked users</p>
            <img src={iconCancel} alt="back-cancel" onClick={cancelButton} />
          </div>
        </div>

        <div className="second-container">
          <div className="TT">
            <div className="mini-list-block">
              {listOfBlockUsers.length > 0 ? (
                <List blockList={listOfBlockUsers} />
              ) : (
                <div style={{textAlign: "center", margin: "80px auto", width: "100%"}}>
                  <img src={notFound} alt="not found" style={{width: "100px", height: "auto"}} />
                  <p style={{fontSize: "12px"}}>No Notification</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

const List = (blockList) => {
  const history = useHistory();
  const dispatch = useDispatch();
  function cancelButtonMe(username) {
    history.push(`/app/profile/${username}`);
    dispatch(settingVerifyModal(null));
  }

  return blockList?.blockList?.map((item, key) => (
    <div key={key} className="third-column">
      <div className="one-third">
        <img src={avatarProfile} alt="back-cancel" className="img" />
        <div>
          <div>
            <p className="title" style={{textTransform: "capitalize"}}>
              {item?.blockedUser}
            </p>
          </div>
          <div style={{display: "flex", alignItems: "center"}}>
            <p className="tag">Verified merchant</p>
            <MdVerified fontSize={14} style={{marginLeft: 3}} fill="#00A478" />
          </div>
        </div>
      </div>
      <button className="button" onClick={() => cancelButtonMe(item.blockedUser)}>
        View Profile
      </button>
    </div>
  ));
};

export default memo(ListOfBlockedUsersModal);
