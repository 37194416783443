import React, {memo} from "react";
import "../../styles/404/404TextStyles.scss";

const TextSection = () => {
  return (
    <div className="textOopSection">
      <p className="title">Oops, page not found</p>
      <p className="details">We can’t find the page that you’re looking for</p>
    </div>
  );
};

export default memo(TextSection);
