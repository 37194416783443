import {
  PHONE_SEARCHABLE_DETAILS,
  UPDATED_PHONE_NUMBER,
  DEFAULT_CURRENCY_COUNTRY,
  SETTINGS_VERIFY_MODAL,
  SETTINGS_MODAL_INFORMATION_DETAILS,
  SETTINGS_MODAL_MORE_INFORMATION_DETAILS,
  VALID_ADDRESS_FIRST_NAME,
  VALID_ADDRESS_LAST_NAME,
  VALID_ADDRESS_MIDDLE_NAME,
  VALID_ADDRESS_RESIDENCE,
  VALID_ADDRESS_CITY,
  VALID_ADDRESS_STATE,
  VALID_ADDRESS_COUNTRY,
  STORE_VERIFY_LINK_URL,
  GET_ADDRESS_COUNTRY_SELECTED,
  GOOGLEAUTHQRCODE,
  GOOGLEAUTHPROCESSMODALS,
  USEGOOGLEAUTH,
  USEPHONENUMBER,
  SETTING_LOADER_SKELETON,
  CLAIM_LOADER_SKELETON,
  UPDATED_PHONE_NUMBER_DETAILS,
  USER_DETAILS_FOR_UPDATING_PHONE_NUMBER,
  LIST_OF_BLOCK_USERS,
  REFERRAL_CLAIM,
  SWITCH_TO_GOOGLE_CHANGE_NUMBER,
  SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO,
  ERROR_UPDATING_PHONE_NUMBER,
  STORE_REJECTED_ADDRESS_MODAL,
  REFERRAL_TRANSACTION,
  GET_SELECTED_COUNTRY_SETTINGS,
} from "../types";

const initialState = {
  phoneSearchableDetails: {},
  upDatedPhoneNumber: "",
  defaultCurrenctCountry: {},
  settingsVerifyModalState: null,
  settingsModalInfo: {},
  settingsModalMoreInfo: {},
  validAddressFirstName: false,
  validAddressLastName: false,
  validAddressMiddleName: false,
  validAddressResidence: false,
  validAddressCity: false,
  validAddressState: false,
  validAddressCountry: false,
  storeLinkUrl: "",
  getCountrySelected: "",
  googleAuthQRCode: "",
  googleAuthProcessModals: {},
  useGoogleAuth: {},
  usePhoneNumber: {},
  settingLoaderSkeleton: false,
  claim_loader_skeleton: false,
  upDatedPhoneNumberDetails: "",
  userDetailsForUpdatingPhoneNumber: {},
  listOfBlockUsers: [],
  referralClaim: "",
  switchToGoogleChangeNumber: null,
  switchToGoogleChangeNumberTwo: null,
  errorUpdatingPhoneNumber: "",
  storeRejectedAddressModalState: {},
  referralTransaction: null,
  getSelectedCountrySettingsData: "",
};

const settingsReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case ERROR_UPDATING_PHONE_NUMBER: {
      return {
        ...state,
        errorUpdatingPhoneNumber: payload,
      };
    }

    case PHONE_SEARCHABLE_DETAILS: {
      return {
        ...state,
        phoneSearchableDetails: payload,
      };
    }

    case UPDATED_PHONE_NUMBER: {
      return {
        ...state,
        upDatedPhoneNumber: payload,
      };
    }

    case DEFAULT_CURRENCY_COUNTRY: {
      return {
        ...state,
        defaultCurrenctCountry: payload,
      };
    }
    case SETTINGS_VERIFY_MODAL: {
      return {
        ...state,
        settingsVerifyModalState: payload,
      };
    }
    case SETTINGS_MODAL_INFORMATION_DETAILS: {
      return {
        ...state,
        settingsModalInfo: payload,
      };
    }

    case SETTINGS_MODAL_MORE_INFORMATION_DETAILS: {
      return {
        ...state,
        settingsModalMoreInfo: payload,
      };
    }

    case VALID_ADDRESS_FIRST_NAME: {
      return {
        ...state,
        validAddressFirstName: payload,
      };
    }

    case VALID_ADDRESS_LAST_NAME: {
      return {
        ...state,
        validAddressLastName: payload,
      };
    }

    case VALID_ADDRESS_MIDDLE_NAME: {
      return {
        ...state,
        validAddressMiddleName: payload,
      };
    }
    case VALID_ADDRESS_RESIDENCE: {
      return {
        ...state,
        validAddressResidence: payload,
      };
    }
    case VALID_ADDRESS_CITY: {
      return {
        ...state,
        validAddressCity: payload,
      };
    }
    case VALID_ADDRESS_STATE: {
      return {
        ...state,
        validAddressState: payload,
      };
    }
    case VALID_ADDRESS_COUNTRY: {
      return {
        ...state,
        validAddressCountry: payload,
      };
    }
    case STORE_VERIFY_LINK_URL: {
      return {
        ...state,
        storeLinkUrl: payload,
      };
    }
    case GET_ADDRESS_COUNTRY_SELECTED: {
      return {
        ...state,
        getCountrySelected: payload,
      };
    }
    case GET_SELECTED_COUNTRY_SETTINGS: {
      return {
        ...state,
        getSelectedCountrySettingsData: payload,
      };
    }

    case GOOGLEAUTHQRCODE: {
      return {
        ...state,
        googleAuthQRCode: payload,
      };
    }

    case GOOGLEAUTHPROCESSMODALS: {
      return {
        ...state,
        googleAuthProcessModals: payload,
      };
    }

    case USEPHONENUMBER: {
      return {
        ...state,
        usePhoneNumber: payload,
      };
    }

    case USEGOOGLEAUTH: {
      return {
        ...state,
        useGoogleAuth: payload,
      };
    }

    case SETTING_LOADER_SKELETON: {
      return {
        ...state,
        settingLoaderSkeleton: payload,
      };
    }

    case CLAIM_LOADER_SKELETON: {
      return {
        ...state,
        claim_loader_skeleton: payload,
      };
    }

    case UPDATED_PHONE_NUMBER_DETAILS: {
      return {
        ...state,
        upDatedPhoneNumberDetails: payload,
      };
    }

    case USER_DETAILS_FOR_UPDATING_PHONE_NUMBER: {
      return {
        ...state,
        userDetailsForUpdatingPhoneNumber: payload,
      };
    }

    case LIST_OF_BLOCK_USERS: {
      return {
        ...state,
        listOfBlockUsers: payload,
      };
    }

    case REFERRAL_CLAIM: {
      return {
        ...state,
        referralClaim: payload,
      };
    }
    case SWITCH_TO_GOOGLE_CHANGE_NUMBER: {
      return {
        ...state,
        switchToGoogleChangeNumber: payload,
      };
    }
    case SWITCH_TO_GOOGLE_CHANGE_NUMBER_TWO: {
      return {
        ...state,
        switchToGoogleChangeNumberTwo: payload,
      };
    }
    case STORE_REJECTED_ADDRESS_MODAL: {
      return {
        ...state,
        storeRejectedAddressModalState: payload,
      };
    }

    case REFERRAL_TRANSACTION: {
      return {
        ...state,
        referralTransaction: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default settingsReducer;
