import {Fragment} from "react";
import "../styles/landing-page-styles/terms.scss";
import "../styles/landing-page-styles/button.scss";
import Layout from "../components/LandingComponents/layout";

const RiskDisclosure = () => {
  return (
    <Layout>
      <Fragment>
        <main className="featuresContainer">
          <div className="innerContainer">
            <div className="titleContainer">
              <h1 className="title">Risk Management</h1>

              <div className="cardContainer">
                <div>
                  <p className="title" style={{textAlign: "left"}}>
                    There are several risks associated with cryptocurrencies and cryptocurrencies trading. By accessing
                    and using the Services, you hereby represent and warrant that you have read the following
                    cryptocurrencies Risk Disclosures.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Risk</h1>
                  <p className="subtitle">
                    You must evaluate your particular financial circumstances to determine whether or not trading
                    cryptocurrencies is appropriate for you. You should not invest funds in cryptocurrencies that you
                    cannot afford to lose. The trading of cryptocurrencies can result in substantial losses, including
                    most if not all of your investment. For that reason, you should not use funds that are earmarked for
                    special purposes, such as retirement funds, debt repayment funds, amounts needed for emergency
                    expenses, tuition or household expenses or funds otherwise required by your lifestyle, to trade
                    cryptocurrencies.
                    <br />
                    <br /> Oyola is committed to consumer protection and combating fraud. Cryptocurrencies and digital
                    assets are commonly targeted by hackers and criminals who commit fraud, especially through social
                    media platforms. Oyola and its employees will never contact you through social media platforms. If
                    you believe you are a victim of fraud, please contact us at fraud@oyola.io.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Unique Features of cryptocurrencies</h1>
                  <p className="subtitle">
                    Cryptocurrencies are digital instruments that are intended to function as a store of value or a
                    medium of exchange. Although cryptocurrencies are often exchangeable for various fiat currencies,
                    unlike fiat currencies, cryptocurrencies are not backed by any government or central bank and do not
                    constitute legal tender. Cryptocurrencies have no intrinsic value and there is no investment
                    underlying cryptocurrencies. The price of cryptocurrencies is based on the agreement of the parties
                    to a transaction, which may or may not be based on the market value of the cryptocurrency at the
                    time of the transaction.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Insurance</h1>
                  <p className="subtitle">
                    Accounts holding cryptocurrencies are not protected by SIPC coverage. Cryptocurrencies are also not
                    covered by the FDIC, which covers fiat currency held in member banks. Existing insurance products
                    are inadequate to cover potential losses if an exchange fails and/or digital wallets are hacked.
                  </p>
                </div>
                <div className="listContainer">
                  <h1 className="bigTitle"> Price Volatility</h1>
                  <p className="subtitle">
                    Cryptocurrencies derive their value from the markets in which they trade, and the markets for
                    cryptocurrencies are global. The price of cryptocurrencies is based on the perceived value of the
                    cryptocurrency and subject to changes in sentiment, which make these products highly volatile and
                    unpredictable. The fluctuations of cryptocurrency prices are much greater than the price
                    fluctuations of fiat currencies, which are also risky to trade. Certain cryptocurrencies have
                    experienced daily price volatility of more than 20%, including sudden drops in price. If
                    participants in a given cryptocurrency market change their view about the value of a given
                    cryptocurrency versus fiat currency, the price of the cryptocurrency can decline precipitously. It
                    may be difficult to liquidate a position in cryptocurrencies at all or, if possible, such
                    liquidation may occur at a significant loss. It is possible that the market for a given
                    cryptocurrency can collapse altogether.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Market manipulation, Valuation and Liquidity</h1>
                  <p className="subtitle">
                    Cryptocurrencies can be traded through privately negotiated transactions and through numerous
                    cryptocurrency exchanges and intermediaries around the world, each with its own pricing mechanism
                    and/or order book. Generally accepted auditing methods for cryptocurrencies do not exist and
                    cryptocurrency platforms do not have consistent methods for auditing their holdings and some do not
                    have audits at all. The lack of generally accepted auditing methods and a centralized pricing source
                    pose a variety of valuation challenges. In addition, the dispersed liquidity may pose challenges for
                    market participants trying to exit a position, particularly during periods of stress. Cases of
                    market manipulation have been reported on multiple occasions.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Cybersecurity</h1>
                  <p className="subtitle">
                    The cybersecurity risks of cryptocurrencies and related “wallets” or spot exchanges include hacking
                    vulnerabilities, cybersecurity attacks and a risk that publicly distributed ledgers may not be
                    immutable. A cybersecurity event could result in a substantial, immediate and irreversible loss for
                    market participants that trade cryptocurrencies. Cryptocurrency transactions may be irreversible,
                    and, accordingly, losses due to a cybersecurity event may not be recoverable. Even a minor
                    cybersecurity event in a cryptocurrency is likely to result in downward price pressure on that
                    product and potentially other cryptocurrencies. In addition, the trading of cryptocurrencies may be
                    adversely affected by network connectivity issues and dissemination of inaccurate data. You may
                    incur losses due to software or hardware failures and system failures.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Opaque Spot Market</h1>
                  <p className="subtitle">
                    Not only are the cryptocurrency markets volatile, but they are subject to fraud and other trading
                    aberrations. Cryptocurrency balances are generally maintained as an address on the blockchain and
                    are accessed through private keys, which may be held by a market participant or a custodian.
                    Although cryptocurrency transactions are typically publicly available on a blockchain or distributed
                    ledger, the public address does not identify the controller, owner or holder of the private key.
                    Unlike bank and brokerage accounts, cryptocurrency exchanges and custodians that hold
                    cryptocurrencies do not always identify the owner.
                    <br />
                    The opaque underlying or spot market poses asset verification challenges for market participants,
                    regulators and auditors and gives rise to an increased risk of manipulation and fraud, including the
                    potential for Ponzi schemes, bucket shops and pump and dump schemes, which may undermine market
                    confidence in a cryptocurrencies and negatively impact its price.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Cryptocurrency Exchanges, Intermediaries and Custodians</h1>
                  <p className="subtitle">
                    Cryptocurrency exchanges, as well as other intermediaries, custodians and vendors used to facilitate
                    cryptocurrencies transactions, are relatively new and largely unregulated in most jurisdictions. The
                    opaque underlying spot market and lack of regulatory oversight creates a risk that a cryptocurrency
                    exchange may not hold sufficient cryptocurrencies or other funds to satisfy its obligations and that
                    such deficiency may not be easily identified or discovered.
                    <br />
                    <br />
                    In addition to a higher level of operational risk than regulated futures or securities exchanges,
                    cryptocurrency exchanges can experience volatile market movements, flash crashes, fraud, various
                    forms of market manipulation, theft, transaction processing delays and other cybersecurity risks.
                    Trading in cryptocurrencies may be halted by the various trading venues due to unusual trading
                    activity, outages or other problems with a cryptocurrency platform. If Oyola experiences such
                    technical difficulties, those difficulties could prevent you from accessing the cryptocurrency in
                    your Oyola Crypto account. Oyola may not have sufficient financial coverage through bonds, insurance
                    or other products to repay your losses.
                    <br />
                    <br />
                    Some cryptocurrency transactions shall be deemed to be made when recorded on a public ledger, which
                    is not necessarily the date or time that the customer initiates the transaction. Before you engage
                    in trading cryptocurrencies, you must become familiar with the platform on which the relevant
                    cryptocurrency trades. Generally, there is limited information about the various cryptocurrency
                    platforms and because these platforms are complex and technically difficult for the average person
                    to understand, you will need to put forth substantial effort to obtain the information necessary to
                    understand the risks you are undertaking. You should understand the functions, operations and uses
                    as well as the history for the platforms on which you invest. As described above, some platforms are
                    subject to a variety of serious attacks, which may result in the loss of your cryptocurrency.
                    <br />
                    <br />
                    It may be difficult or even impossible to identify and/or locate the issuer of cryptocurrencies, the
                    trading platform, wallet provider or intermediary, especially in a cross-border situation where it
                    may also be difficult to determine which laws may be applicable. Thus, if a holder has a claim it
                    might be difficult to sue the issuer or the wallet provider and enforce a title.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Regulatory Landscape</h1>
                  <p className="subtitle">
                    The majority of cryptocurrencies and the selling of products or services in relation to
                    cryptocurrencies are unregulated. In these cases you will not benefit from the rights and
                    protections available to consumers for regulated financial services, such as complaints or recourse
                    mechanisms. <br /> <br />
                    Cryptocurrencies currently face an uncertain regulatory landscape in many jurisdictions. In
                    addition, many cryptocurrencies derivatives are regulated by the provisions of national and
                    supra-national (i.e. EU) securities legislation; moreover, some state securities regulators have
                    cautioned that many initial coin offerings are likely to fall within the definition of a security
                    and subject to their respective securities laws. One or more jurisdictions may, in the future, adopt
                    laws, regulations or directives that affect cryptocurrencies networks and their users. Such laws,
                    regulations or directives may impact the price of cryptocurrencies and their acceptance by users,
                    merchants and service providers.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Technology</h1>
                  <p className="subtitle">
                    The relatively new and rapidly evolving technology underlying cryptocurrencies introduces unique
                    risks. For example, a unique private key is required to access, use or transfer a cryptocurrency on
                    a blockchain or distributed ledger. The loss, theft or destruction of a private key may result in an
                    irreversible loss. Similarly, transactions in virtual currency may be irreversible, and,
                    accordingly, losses due to fraudulent or accidental transactions may not be recoverable. <br />
                    <br />
                    Changes in the technology of a given cryptocurrency platform or changes resulting from cybersecurity
                    attacks include but are not limited to a “fork,” which can have a negative impact on the value of a
                    particular cryptocurrency and can result in the loss or cancellation of a cryptocurrency position or
                    a sudden loss of value. The ability to participate in forks could also have implications for
                    investors. For example, a market participant holding a cryptocurrency position through a
                    cryptocurrency exchange may be adversely impacted if the exchange does not allow its customers to
                    participate in a fork that creates a new product.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Transaction Fees</h1>
                  <p className="subtitle">
                    Many cryptocurrencies allow market participants to offer miners (i.e., parties that process
                    transactions and record them on a blockchain or distributed ledger) the ability to earn a fee. While
                    not mandatory, a fee is generally necessary to ensure that a transaction is promptly recorded on a
                    blockchain or distributed ledger. The amounts of these fees are subject to market forces, and it is
                    possible that the fees could increase substantially during a period of stress. In addition,
                    cryptocurrency exchanges, wallet providers and other custodians may charge relatively high fees as
                    compared to custodians in many other financial markets.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Risk of partial or total loss of the invested amount</h1>
                  <p className="subtitle">
                    Investments in cryptocurrencies are not regulated in most countries and therefore you are unlikely
                    to be protected if something goes wrong. Also, the risks associated with the investment may not be
                    clearly stated in the documentation published by the issuer of the cryptocurrencies.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Risk of insufficient information disclosure</h1>
                  <p className="subtitle">
                    Information regarding any specific сrypto-asset may be missing, inaccurate, incomplete and unclear
                    with respect to the project and its risks. Documents may be highly technical and require
                    sophisticated knowledge to understand the characteristics of the сrypto-asset and/or the project.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Project risk</h1>
                  <p className="subtitle">
                    In many projects, the value and stability of the сrypto-asset largely depends on the skill and
                    diligence of the project team behind the сrypto-asset or the ICO. The project underlying an ICO
                    might not be realised, which would ultimately make the сrypto- asset worthless.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle"> Misleading information</h1>
                  <p className="subtitle">
                    Some cryptocurrencies and related products are aggressively advertised to the public, using
                    marketing material and other information that may be unclear, incomplete, inaccurate or even
                    purposefully misleading. For instance, advertisements via social media may be very short, with a
                    focus on the potential gains but not the high risks involved. You should also beware of social media
                    ‘influencers’ who typically have a financial incentive to market certain cryptocurrencies and
                    related products and services and therefore may be biased in the communications they issue.
                  </p>
                </div>

                <div className="listContainer">
                  <h1 className="bigTitle">Fraud and malicious activities</h1>
                  <p className="subtitle">
                    Numerous fake cryptocurrencies and scams exist and you should be aware that their sole purpose is to
                    deprive you of your money using different techniques, for example phishing.
                    <br />
                    <br />
                    Oyola makes no warranties or representations, express or implied, on products and services offered
                    through the platform. It accepts no liability for any damages or losses, however, caused in
                    connection with the use of related services.
                    <br />
                    <br />
                    As a business, we do not give tips, recommendations, or provide any advisory services. We have not
                    authorized anyone to trade on behalf of others. If you find anyone claiming to be part of Oyola and
                    offering such services, please inform us here: fraud@oyola.io.
                    <br />
                    <br />
                    Third-party information provided for product features, communications, and communications emanating
                    from social media communities, market prices, data, and other information available through any of
                    our are meant for informational purposes only and are not intended as an offer or solicitation for
                    the purchase or sale of any financial instrument or as an official confirmation of any transaction.
                    The information provided is not warranted as to completeness or accuracy and is subject to change
                    without notice.
                    <br />
                    <br />
                    All logos and trademarks belong to their respective legal owners.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </main>
      </Fragment>
    </Layout>
  );
};

export default RiskDisclosure;
