import {
  CHANGE_MARKET_PLACE_DEFAULT,
  CHANGE_MARKET_PLACE_STATE,
  PAGE_EMPTY_CHECKER,
  CHECK_FOR_EXTRA_DATA,
} from "../types";

const initialState = {
  country: "",
  token: "",
  type: "",
  paymentMethod: "",
  checkForEmpty: null,
  checkForExtraData: false,
};

const marketStateReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case CHANGE_MARKET_PLACE_STATE: {
      return {
        ...state,
        ...payload,
      };
    }
    case CHANGE_MARKET_PLACE_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    case PAGE_EMPTY_CHECKER: {
      return {
        ...state,
        checkForEmpty: payload,
      };
    }

    case CHECK_FOR_EXTRA_DATA: {
      return {
        ...state,
        checkForExtraData: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default marketStateReducer;
