import {Skeleton, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import NotificationService from "../../services/notification";
import AllNotifications from "../Notification/AllNotifications";
import NotificationMessages from "../Notification/NotificationMessages";
import NotificationTrades from "../Notification/NotificationTrades";
import NotificationTransfers from "../Notification/NotificationTransfers";
import {
  getAllNotifications,
  getMessageNotification,
  getTradeNotification,
  getTransferNotification,
} from "../../store/actions/notification";
import {useCallback} from "react";
import {useSelector} from "react-redux";
import "../../styles/Notification/notificationStyle.scss";

const Notification = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const notificationDataRedux = useSelector((state) => state.notificationReducer.allNotification);

  const {allSkip, messageSkip, tradeSkip, transferSkip, notificationLimit} = useSelector(
    (state) => state.notificationReducer
  );

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    const constants = await Promise.all([
      NotificationService.AllNotifications(allSkip, notificationLimit),
      NotificationService.NotificationsSystem(messageSkip, notificationLimit, "message"),
      NotificationService.NotificationsSystem(tradeSkip, notificationLimit, "trade"),
      NotificationService.NotificationsSystem(transferSkip, notificationLimit, "transfers"),
    ]).then((data) => {
      return data;
    });

    dispatch(getAllNotifications(constants[0]?.data?.notifications));
    dispatch(getMessageNotification(constants[1]?.data?.notifications));
    dispatch(getTradeNotification(constants[2]?.data?.notifications));
    dispatch(getTransferNotification(constants[3]?.data?.notifications));
    setLoading(false);
  }, [dispatch]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  const [tab, setTab] = useState(0);

  const switchTabs = (data) => {
    switch (data) {
      case "allnotifications": {
        setTab(0);
        break;
      }

      case "messages": {
        setTab(1);
        break;
      }

      case "trades": {
        setTab(2);
        break;
      }

      case "transfers": {
        setTab(3);
        break;
      }

      default: {
        setTab(0);
      }
    }
  };

  return (
    <React.Fragment>
      <div className="NotificationWrapper">
        <main>
          <h3>Notification</h3>
          <p>View all notifications</p>
        </main>
        <div className="notification-container">
          <div className="tabs-container">
            <button
              className={`button all ${tab === 0 && "selected"}`}
              onClick={() => {
                switchTabs("allnotifications");
              }}
            >
              All Notifications
            </button>
            <button
              className={`button message ${tab === 1 && "selected messageSelected"}`}
              onClick={() => {
                switchTabs("messages");
              }}
            >
              Messages
            </button>
            <button
              className={`button trade ${tab === 2 && "selected tradeSelected"}`}
              onClick={() => {
                switchTabs("trades");
              }}
            >
              Trades
            </button>
            <button
              className={`button transfer ${tab === 3 && "selected transferSelected"}`}
              onClick={() => {
                switchTabs("transfers");
              }}
            >
              Transfer
            </button>
          </div>

          <div className="main-content">
            {loading && (
              <Stack spacing={3}>
                <Skeleton
                  animation="wave"
                  variant="rectangular"
                  width={"99%"}
                  height={160}
                  style={{margin: "20px auto"}}
                />
              </Stack>
            )}
            {!loading && (
              <div>
                {tab === 0 && <AllNotifications />}
                {tab === 1 && <NotificationMessages />}
                {tab === 2 && <NotificationTrades />}
                {tab === 3 && <NotificationTransfers />}
              </div>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Notification;
