import {combineReducers} from "redux";
import authReducer from "./reducers/auth";
import generalReducer from "./reducers/general";
import buySellTransactionReducer from "./reducers/buySellTransactionHistory";
import depositWithdrawaltransactionReducer from "./reducers/depositWithdrawTransactionHistory";
import marketReducer from "./reducers/market";
import checkOutDataReducer from "./reducers/checkoutData";
import createTradeReducer from "./reducers/createTradeReducer";
import getTradeStatusbyIdReducer from "./reducers/getTradeStatus";
import getTradeByIdReducer from "./reducers/getTradeByID";
import tradeReducer from "./reducers/trade";
import balanceReducer from "./reducers/balance";
import postTradeReducer from "./reducers/postTradeReducer";
import editTradeReducer from "./reducers/editPostTradeReducer";
import notificationReducer from "./reducers/notification";
import walletReducer from "./reducers/walletReducer";
import overviewReducer from "./reducers/overviewReducer";
import settingsReducer from "./reducers/settingsReducers";
import tradeOrdersReducer from "./reducers/tradeOrderReducers";
import profileReducer from "./reducers/profile";
import chatMessageReducer from "./reducers/chatMessageReducer";
import cancelTradeReducer from "./reducers/cancelTrade";
import confirmTraderReducer from "./reducers/confirmTrade";
import releaseTokenReducer from "./reducers/releaseToken";
import rateTradeReducer from "./reducers/rateTrade";
import disputeTradeReducer from "./reducers/disputeTrade";
import marketStateReducer from "./reducers/marketState";
import getUserTradeReducer from "./reducers/getUserTrades";
import tradeReviewReducer from "./reducers/tradeReviewReducer";
import domPaginationReducer from "./reducers/domPaginationReducer";
import LandingReducer from "./reducers/LandingReducer";
import convertReducer from "./reducers/convertReducer";

export default combineReducers({
  authReducer,
  buySellTransactionReducer,
  generalReducer,
  depositWithdrawaltransactionReducer,
  marketReducer,
  checkOutDataReducer,
  createTradeReducer,
  getTradeByIdReducer,
  getTradeStatusbyIdReducer,
  tradeReducer,
  balanceReducer,
  postTradeReducer,
  editTradeReducer,
  notificationReducer,
  walletReducer,
  overviewReducer,
  tradeOrdersReducer,
  profileReducer,
  chatMessageReducer,
  settingsReducer,
  cancelTradeReducer,
  confirmTraderReducer,
  releaseTokenReducer,
  rateTradeReducer,
  disputeTradeReducer,
  marketStateReducer,
  getUserTradeReducer,
  tradeReviewReducer,
  domPaginationReducer,
  LandingReducer,
  convertReducer,
});
