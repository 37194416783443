import "../../../../styles/moreModal.scss";
import {GrFormClose} from "react-icons/gr";
import {RiExternalLinkLine, RiFileCopyFill} from "react-icons/ri";
import CopyToClipboard from "react-copy-to-clipboard";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import moment from "moment";
import {trimId} from "../../../../utils/trimID";
import {toast} from "react-toastify";
import CurrencyFormat from "react-currency-format";
const ViewMoreModal = ({setOpen, data}) => {
  return (
    <>
      {data?.type === "convert" ? (
        <div className="__moreModal__Container">
          <div className="__modal__content__top">
            <span className="__title__text">{capitalizeFirstLetter(data?.type)} Details</span>
            <div>
              <GrFormClose size={16} onClick={() => setOpen(false)} style={{cursor: "pointer"}} />
            </div>
          </div>
          <div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Status</span>
              <div className="__bottonContent__value __status__complete">{data?.status}</div>
            </div>

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Pay with</span>
              <div className="__bottonContent__value">{data?.tokenSymbol}</div>
            </div>

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Date</span>
              <div className="__bottonContent__value">{moment(data?.timeStamp).format("LLL")}</div>
            </div>

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">{capitalizeFirstLetter(data?.type)} Amount</span>
              <div className="__bottonContent__value">{`${data.amount} ${data?.tokenSymbol}`}</div>
            </div>

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">NetWork</span>
              <div className="__bottonContent__value">{data?.chain?.toUpperCase()}</div>
            </div>

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Receiver bank number</span>
              <div className="__bottonContent__value">{data?.convert?.account_number}</div>
            </div>

            {data?.convert?.full_name && (
              <div className="__moremodal__bottomContent">
                <span className="__bottonContent__key">Receiver name</span>
                <div className="__bottonContent__value">{data?.convert?.full_name}</div>
              </div>
            )}

            {data?.convert?.bank_name && (
              <div className="__moremodal__bottomContent">
                <span className="__bottonContent__key">Receiver bank name</span>
                <div className="__bottonContent__value">{data?.convert?.bank_name}</div>
              </div>
            )}

            {data?.convert?.value && (
              <div className="__moremodal__bottomContent">
                <span className="__bottonContent__key">Receiver Amount</span>
                <div className="__bottonContent__value">
                  <CurrencyFormat
                    value={data?.convert?.value}
                    displayType="text"
                    thousandSeparator={true}
                    decimalScale={2}
                    suffix={` NGN`}
                  />
                </div>
              </div>
            )}

            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Tx-ID</span>

              <CopyToClipboard text={data?.txID} onCopy={() => toast("Id Copied", {position: "top-right"})}>
                <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                  <div className="__bottonContent__value">{trimId(data?.txID)}</div>
                  <RiFileCopyFill size={14} style={{marginLeft: "10px"}} />
                </div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      ) : (
        <div className="__moreModal__Container">
          <div className="__modal__content__top">
            <span className="__title__text">{capitalizeFirstLetter(data?.type)} Details</span>
            <div>
              <GrFormClose size={16} onClick={() => setOpen(false)} style={{cursor: "pointer"}} />
            </div>
          </div>
          <div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Status</span>
              <div className="__bottonContent__value __status__complete">{data?.status}</div>
            </div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Date</span>
              <div className="__bottonContent__value">{moment(data?.timeStamp).format("LLL")}</div>
            </div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Coin</span>
              <div className="__bottonContent__value">{data?.tokenSymbol}</div>
            </div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">{capitalizeFirstLetter(data?.type)} Amount</span>
              <div className="__bottonContent__value">{data.amount}</div>
            </div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">NetWork</span>
              <div className="__bottonContent__value">{data?.chain?.toUpperCase()}</div>
            </div>
            <div className="__moremodal__bottomContent">
              <span className="__bottonContent__key">Wallet Addresss</span>

              <CopyToClipboard text={data?.wallet} onCopy={() => toast("Id Copied", {position: "top-right"})}>
                <div style={{display: "flex", alignItems: "center", cursor: "pointer"}}>
                  <div className="__bottonContent__value">{trimId(data?.wallet)}</div>
                  <RiFileCopyFill size={14} style={{marginLeft: "10px"}} />
                </div>
              </CopyToClipboard>
            </div>

            {data?.status !== "awaiting" && data?.txID !== null && (
              <div className="__moremodal__bottomContent">
                <span className="__bottonContent__key">Tx-ID</span>
                <a
                  target="_blank"
                  style={{color: "black"}}
                  href={
                    data?.tokenSymbol?.toUpperCase() === "ETH"
                      ? `https://etherscan.io/tx/${data?.txID}`
                      : data?.tokenSymbol?.toUpperCase() === "BTC"
                      ? `https://explorer.btc.com/btc/transaction/${data?.txID}`
                      : data?.tokenSymbol?.toUpperCase() === "USDT"
                      ? `https://tronscan.org/#/transaction/${data?.txID}`
                      : data?.tokenSymbol?.toUpperCase() === "BNB" && ` https://bscscan.com/tx/${data?.txID}`
                  }
                  rel="noreferrer"
                >
                  <div style={{display: "flex", alignItems: "center"}}>
                    <div className="__bottonContent__value">{trimId(data?.txID)}</div>
                    <RiExternalLinkLine size={14} style={{marginLeft: "10px"}} />
                  </div>
                </a>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default ViewMoreModal;
