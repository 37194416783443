import React, {memo} from "react";
import CurrencyFormat from "react-currency-format";
import {useSelector} from "react-redux";
import "../../styles/walletStyles/AmountScalling.scss";

const AmountScalling = () => {
  const ScallingValue = useSelector((state) => state.walletReducer.ScallingValue);
  memo(() => ScallingValue, [ScallingValue]);

  return (
    <div className="amountScallingWrapper">
      <div className="amountScallingContent">
        <h3>Today’s PNL</h3>
        <p
          className="amountScallingContentAmount"
          // style={{color: `${userWalletBalance.currentUserBalanceNumChecker <= 0 ? "#fda29b" : ""}`}}
        >
          {/* {userWalletBalance.currentUserBalanceNumChecker > 0 && "+"} */}
          <CurrencyFormat
            value={`${ScallingValue ? ScallingValue?.curentUserBalancesDifferences : 0}`}
            displayType={"text"}
            decimalScale={6}
            fixedDecimalScale
            // prefix={"$"}
            suffix={` ${ScallingValue ? ScallingValue?.currency : "no currency"}`}
          />
        </p>

        <p
          className="amountScallingContentIncreament"
          style={{color: `${ScallingValue?.currentUserBalanceNumChecker <= 0 ? "#fda29b" : ""}`}}
        >
          {ScallingValue?.currentUserBalanceNumChecker > 0 && "+"}
          <CurrencyFormat
            value={`${ScallingValue ? ScallingValue?.currentUserBalancesPercentage : 0}`}
            displayType={"text"}
            decimalScale={6}
            fixedDecimalScale
            // prefix={"$"}
            suffix={`%`}
          />
        </p>
      </div>
    </div>
  );
};

export default memo(AmountScalling);
