import "../../styles/convert/ConvertParentPage.scss";

import bankConvertIcon from "../../assets/icons/bankConvertIcon.svg";
import ConvertCardComponent from "./ConvertCardComponent";
import {useDispatch, useSelector} from "react-redux";
import {getConvertLevel} from "../../store/actions/convertAction";
import ConvertConfirm from "./convertConfirm";
import ConvertBank from "./convert_bank";

const ConvertParentPage = () => {
  const dispatch = useDispatch();

  const {convertLevel} = useSelector((state) => state.convertReducer);

  const handlePrevious = () => {
    dispatch(getConvertLevel(1));
  };

  return (
    <div className="ConvertParent">
      {/* <div className="topComponent">
        <p className="titleStyle">Convert Crypto</p>
      </div> */}
      {/* section */}

      <div className="convertMainComponent">
        {convertLevel !== 2 ? (
          <div className="leftSide">
            <div>
              <p className="convertLeftSideTitle">Sell your Crypto instantly </p>
              <p className="convertLeftSideSubtitle">Exchange your BTC, USDT For fiat currencies</p>
            </div>
            <div>
              <p className="convertLeftSideFooterTitle">Accepted payment method </p>
              <div className="convertFooterComponent">
                <img src={bankConvertIcon} alt="bank" />
                <p className="convertLeftSideFooterSubtitle">Bank Transfer</p>
              </div>
            </div>
          </div>
        ) : null}

        <div className="rightSide">
          {convertLevel === 0 ? (
            <ConvertCardComponent />
          ) : convertLevel === 1 ? (
            <ConvertBank />
          ) : (
            convertLevel === 2 && <ConvertConfirm />
          )}
        </div>
      </div>
    </div>
  );
};

export default ConvertParentPage;
