import "../../styles/countryWrapper.css";
import {countries} from "country-flags-svg";
import {GiWorld} from "react-icons/gi";

const CountryIcons = ({intialValue, mainStyle}) => {
  if (intialValue === "Sao Tome and Principe") {
    return (
      <img
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/ST.svg`}
        alt=""
        className="imagesCountry"
        style={{...mainStyle}}
      />
    );
  }

  if (intialValue === "United Kingdom") {
    return (
      <img
        src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/GB.svg`}
        alt=""
        className="imagesCountry"
        style={{...mainStyle}}
      />
    );
  }

  const flagUrl = countries.find((item) => item?.name?.toLowerCase() === intialValue?.toLowerCase());

  if (flagUrl === undefined) {
    switch (intialValue) {
      case "Antarctica":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/AQ.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "British Indian Ocean Territory":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/IO.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Bouvet Island":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/BV.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Cocos (Keeling) Islands":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/CC.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Congo, Republic of the":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/CG.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Congo, the Democratic Republic of the":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/CD.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Cote d'Ivoire":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/CI.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Curacao":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/CW.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "East Timor":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/TL.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Falkland Islands (Malvinas)":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/FK.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "French Southern Territories":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/TF.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Heard Island and McDonald Islands":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/HM.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Macau":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/MO.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Macedonia":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/MK.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Micronesia, Federated States of":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/FM.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Moldova, Republic of":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/MD.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Myanmar (Burma)":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/MM.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Palestinian Territory, Occupied":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/PS.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Reunion":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/RE.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Saint Barthelemy":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/BL.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Saint Helena":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/SH.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Saint Martin (French part)":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/MF.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Vatican City":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/VA.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Virgin Islands, British":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/VG.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      case "Virgin Islands, U.S.":
        return (
          <img
            src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/VI.svg`}
            alt=""
            className="imagesCountry"
            style={{...mainStyle}}
          />
        );

      default:
        return <GiWorld style={{fontSize: "20px"}} />;
    }
  }

  return <img src={flagUrl?.flag} alt="" className="imagesCountry" style={{...mainStyle}} />;
};

export {CountryIcons};
