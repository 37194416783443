import React, {memo, useRef, useState} from "react";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import useOnClickOutside from "../../../hooks/useOnClickOutside";
import "../../../styles/searchablePhoneDropDown.scss";
import SearchablePhoneDropDownItem from "./SearchablePhoneDropDownItem";

const SearchablePhoneDropDown = ({
  setSearch,
  search,
  apiList,
  HandleSelect,
  placeholder,
  DetailsIcons,
  name,
  nameAcr,
  code,
  iconsLead,
  decoratorWidthWrapper,
  DropdownWidth,
}) => {
  const ref = useRef();
  const [openSearch, setOpenSearch] = useState(false);
  const [dataCode, setDataCode] = useState([]);
  const [searchDetails, setSearchDetails] = useState();
  const [query, setQuery] = useState("");
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  const openMenu = (e) => {
    if (showSelectCondition === false) {
      setOpenSearch(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpenSearch(false);
      setShowSelectCondition(false);
    }

    setQuery("");
    setDataCode(apiList);
  };

  const closeMenu = () => {
    setOpenSearch(false);
  };

  useOnClickOutside(ref, () => setOpenSearch(false));
  return (
    <React.Fragment>
      <div className="searchDropDownWrapper__phoneSystem" style={{width: decoratorWidthWrapper}}>
        <div className="searchDropDownWrapperSectionDecorator" onClick={openMenu}>
          <div className="searchDropDownWrapperSectionDecoratorDetails">
            <p>
              {Object.keys(search).length > 0
                ? search?.areaCode[0] !== "+"
                  ? `+${search?.areaCode}`
                  : search?.areaCode
                : ""}
            </p>
          </div>

          <div className="searchAbleIconDecorator">
            {openSearch ? (
              <div className="searchIconBody">
                <IoIosArrowUp className="searchIcon" />
              </div>
            ) : (
              <div className="searchIconBody">
                <IoIosArrowDown className="searchIcon" />
              </div>
            )}
          </div>
        </div>

        <div style={{width: "100%"}}>
          <SearchablePhoneDropDownItem
            closeMenu={closeMenu}
            ref={ref}
            HandleSelect={HandleSelect}
            List={dataCode}
            query={query}
            setQuery={setQuery}
            searchDetails={searchDetails}
            DetailsIcons={DetailsIcons}
            name={name}
            nameAcr={nameAcr}
            code={code}
            iconsLead={iconsLead}
            search={search}
            setSearch={setSearch}
            setSearchDetails={setSearchDetails}
            openSearch={openSearch}
            placeholder={placeholder}
            DropdownWidth={DropdownWidth}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default memo(SearchablePhoneDropDown);
