import {
  LOGIN,
  LOGOUT,
  LOADING_STATE,
  REGISTER_LOADING_STATE,
  FORGOT_PASSWORD_LOADING_STATE,
  LOGIN_ERRORS,
  ACTIVE_INPUT,
  LOGIN_DETAILS,
  LOGIN_DETAILS_SECOND,
  ALLOWED_ACCESS,
  REGISTER_ERROR_DETAILS_FOR_EMAIL,
  REGISTER_ERROR_FOR_USERNAME,
  PASSWORD_DETECT_ERROR_STATE,
  AUTH_LOADER,
  STORE_LOGIN_DETAILS_ACTION,
  STORE_LOGIN_GOOGLE_OTP_ACTION,
  STORE_LOGIN_OTP_ACTION,
  LOGIN_ERROR_ACTION,
  HOLD_USER_DETAILS,
  OTP_FOR_GOOGLE_AUTH,
  FORGOT_GOOGLE_AUTH,
  HOLD_USER_DETAILS_REGISTER,
} from "../types";

const initialState = {
  token: localStorage.getItem("token") || "",
  isLoggedIn: false || localStorage.getItem("isLoggedIn"),
  notifier: localStorage.getItem("notification_increase") || "",
  loadingState: 0,
  forgotPasswordloadingState: 0,
  registerLoadingState: 0,
  otpState: 1,
  loginDetails: "",
  loginDetailsSecond: "",
  registerErrorDetailForEmail: "",
  displayUsernameNotAvailable: "",
  loginErrorState: "",
  passwordDetectErrorState: "",
  authLoader: false,
  storeLoginDetailsState: {},
  storeLoginGoogleOtpState: "",
  storeLoginOtpState: "",
  holdUserDetails: {},
  holdUserDetailsRegister: {},
  otpForGoogleAuth: "",
  forgotGoogleAuth: "",
};

const authReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case OTP_FOR_GOOGLE_AUTH: {
      return {
        ...state,
        otpForGoogleAuth: payload,
      };
    }

    case LOGIN: {
      return {
        ...state,
        token: payload.token,
        isLoggedIn: true,
      };
    }

    case AUTH_LOADER: {
      return {
        ...state,
        token: payload.token,
        authLoader: true,
      };
    }

    case LOGOUT: {
      return {
        ...state,
        user: {},
        token: "",
        isLoggedIn: payload,
      };
    }

    case LOADING_STATE: {
      return {
        ...state,
        loadingState: payload,
      };
    }

    case FORGOT_PASSWORD_LOADING_STATE: {
      return {
        ...state,
        forgotPasswordloadingState: payload,
      };
    }

    case REGISTER_LOADING_STATE: {
      return {
        ...state,
        registerLoadingState: payload,
      };
    }

    case LOGIN_ERRORS: {
      return {
        ...state,
        loginErrorsState: payload,
      };
    }

    case ACTIVE_INPUT: {
      return {
        ...state,
        otpState: payload,
      };
    }

    case LOGIN_DETAILS: {
      return {
        ...state,
        loginDetails: payload,
      };
    }

    case REGISTER_ERROR_DETAILS_FOR_EMAIL: {
      return {
        ...state,
        registerErrorDetailForEmail: payload,
      };
    }

    case REGISTER_ERROR_FOR_USERNAME: {
      return {
        ...state,
        displayUsernameNotAvailable: payload,
      };
    }

    case LOGIN_DETAILS_SECOND: {
      return {
        ...state,
        loginDetailsSecond: payload,
      };
    }

    case ALLOWED_ACCESS: {
      return {
        ...state,
        isLoggedIn: payload,
      };
    }

    case PASSWORD_DETECT_ERROR_STATE: {
      return {
        ...state,
        passwordDetectErrorState: payload,
      };
    }
    case STORE_LOGIN_DETAILS_ACTION: {
      return {
        ...state,
        storeLoginDetailsState: payload,
      };
    }
    case STORE_LOGIN_GOOGLE_OTP_ACTION: {
      return {
        ...state,
        storeLoginGoogleOtpState: payload,
      };
    }
    case STORE_LOGIN_OTP_ACTION: {
      return {
        ...state,
        storeLoginOtpState: payload,
      };
    }
    case LOGIN_ERROR_ACTION: {
      return {
        ...state,
        loginErrorState: payload,
      };
    }
    case HOLD_USER_DETAILS: {
      return {
        ...state,
        holdUserDetails: payload,
      };
    }
    case HOLD_USER_DETAILS_REGISTER: {
      return {
        ...state,
        holdUserDetailsRegister: payload,
      };
    }
    case FORGOT_GOOGLE_AUTH: {
      return {
        ...state,
        forgotGoogleAuth: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default authReducer;
