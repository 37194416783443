import React from "react";
import EditTradePage from "../editTrade";

const EditTrade = () => {
  return (
    <div>
      <EditTradePage />
    </div>
  );
};

export default EditTrade;
