import React, {createContext} from "react";

export const TradeOrderContext = createContext();
const MyOrders = () => {
  return (
    <React.Fragment>
      <button className="base-button" style={buttonStyle}>
        Create an order
      </button>
      {/* <TradeOrderContext.Provider value={{tradeOrder, setTradeOrder}}>
        <TradeOrderTop />
        <TradeOrderTable />
      </TradeOrderContext.Provider> */}
    </React.Fragment>
  );
};

const buttonStyle = {
  padding: "1rem",
  borderRadius: "8px",
};

export default MyOrders;
