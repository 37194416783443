import store from "../store";
import {GET_USER_DATA_INITIAL} from "../store/types";
import APIs from "./api";

const GeneralService = {
  getUserData: async (param) => {
    store.dispatch({type: GET_USER_DATA_INITIAL});
    let userData = await APIs.get("/api/v1/profile/get-user-data").then((data) => {
      if (data?.data?.message === "success") {
        return data ?? {};
      } else if (data?.data?.message === "jwt validation error") {
        localStorage.clear();
        window.location = "/";
      } else if (data?.data?.message === "Account is blocked!") {
        localStorage.clear();
        window.location = "/";
      } else if (data?.data?.message === "jwt expired") {
        localStorage.clear();
        window.location = "/";
      }
    });
    return userData;
  },

  getTokenServices: () => {
    return APIs.get("/api/v1/open/get-tokens")
      .then((data) => {
        if (data.data.message === "success") {
          data.data.tokens.tokens.forEach((item) => {
            item["title"] = item.tokenSymbol;
            item.networks.forEach((item) => {
              item["title"] = item.name;
            });
          });

          return {tokens: data.data.tokens.tokens};
        } else if (data?.data?.message === "jwt validation error") {
          localStorage.clear();
          window.location = "/";
        } else if (data?.data?.message === "Account is blocked!") {
          localStorage.clear();
          window.location = "/";
        } else if (data?.data?.message === "jwt expired") {
          localStorage.clear();
          window.location = "/";
        }
      })
      .catch((error) => {
        throw error;
      });
  },

  geolocation: async () => {
    let locationData = await APIs.get("/api/v1/open/get-ip-location").then((data) => {
      return data;
    });
    return locationData;
  },

  getCountries: async () => {
    let countryData = await APIs.get("/api/v1/open/get-countries-data").then((data) => {
      if (data.data.message === "success") {
        data.data.countriesData.forEach((item) => {
          item["name"] = item.countryName;
        });
        return data;
      } else if (data?.data?.message === "jwt validation error") {
        localStorage.clear();
        window.location = "/";
      } else if (data?.data?.message === "Account is blocked!") {
        localStorage.clear();
        window.location = "/";
      } else if (data?.data?.message === "jwt expired") {
        localStorage.clear();
        window.location = "/";
      }
    });
    return countryData;
  },

  getPaymentMethods: async () => {
    let paymentData = await APIs.get("/api/v1/open/get-payment-data").then((data) => {
      return data;
    });
    return paymentData;
  },
};

export default GeneralService;
