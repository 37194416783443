import {useSelector} from "react-redux";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import "../../../styles/table.scss";
import TransactionDesktopDepositWithdrawTable from "./desktopTable";
import TransactionMobileDepositWithdrawTable from "./mobileTable";
const TransactionDepositWithdrawTable = () => {
  const {width} = useWindowDimensions();
  const {response, loading} = useSelector((state) => state.depositWithdrawaltransactionReducer);
  return width <= 600 ? (
    <TransactionMobileDepositWithdrawTable data={response} loading={loading} />
  ) : (
    <TransactionDesktopDepositWithdrawTable data={response} loading={loading} />
  );
};

export {TransactionDepositWithdrawTable};
