import {Button, Skeleton} from "@mui/material";
import {memo, useEffect, useRef, useState} from "react";
import CurrencyFormat from "react-currency-format";
import DeleteComponent from "./DeleteCard";
import "../../styles/editTradeStyles/editTradeFourthSection.scss";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {
  EDITTRADEDISABLE,
  EDIT_POST_TRADE_BUY_ERROR,
  EDIT_POST_TRADE_DETAILED_FORM,
  EDIT_POST_TRADE_SELL_ERROR,
  IS_LOADING,
} from "../../store/types";
import EditPostTradeService from "../../services/editPostTradeService";
import SectionModalCentered from "../Common/sectionModalCentered";
import PostTradeService from "../../services/postTradeService";

const EditTradeFourthSection = () => {
  const checkFeesRef = useRef();
  const dispatch = useDispatch();
  const history = useHistory();
  const singleUserOrder = useSelector((state) => state.editTradeReducer.userOrders);
  const allCurrencyDetails = useSelector((state) => state.editTradeReducer.tokenLocalCurrencyDatas);
  const profitMargin = useSelector((state) => state.editTradeReducer.profitMargin);
  const orderStatus = useSelector((state) => state.editTradeReducer.orderStatus);
  const editTradeDisable = useSelector((state) => state.editTradeReducer.editTradeDisable);
  const detailedForm = useSelector((state) => state.editTradeReducer.editPostTradeDetailedForm);
  const buyError = useSelector((state) => state.editTradeReducer.editPostTradeBuyError);
  const sellError = useSelector((state) => state.editTradeReducer.editPostTradeSellError);

  const [disabled, setDisabled] = useState(true);
  const [deleteOrder, setDeteleOrder] = useState(false);

  const [feesPrice, setFeesPrice] = useState(0);
  const [totalPrices, setTotalPrices] = useState(0);

  const [feesChecker, setFeesChecker] = useState(false);

  useEffect(() => {
    dispatch({
      type: EDIT_POST_TRADE_DETAILED_FORM,
      payload: {
        maxValue: singleUserOrder.maxAmount,
        minValue: singleUserOrder.minAmount,
        tagLine: singleUserOrder.tagLine,
        description: singleUserOrder.description,
      },
    });
  }, []);

  const [descriptionError, setDiscriptionError] = useState("");
  const [tagLineError, setTagLineError] = useState("");

  const handleChageSystem = (e) => {
    dispatch({type: EDITTRADEDISABLE, payload: false});

    let {name, value} = e.target;

    let str = value
      .split(" ")
      .filter(
        (el) =>
          !el.startsWith("https://") &&
          !el.startsWith("http://") &&
          !el.startsWith("www.") &&
          !el.endsWith(".com") &&
          !el.endsWith("</script>") &&
          !el.startsWith("<script>")
      );

    value = str.join(" ");

    dispatch({
      type: EDIT_POST_TRADE_DETAILED_FORM,
      payload: {...detailedForm, [name]: value},
    });

    const newValues = value?.split(",");

    const ourValue = newValues?.join("");

    let minAmounts = 0;

    if (typeof detailedForm?.minValue === "string") {
      const newMinValue = detailedForm?.minValue?.match(/\d/g);
      minAmounts = newMinValue?.join("");
    }

    let maxAmounts = 0;

    if (typeof detailedForm?.maxValue === "string") {
      const newMaxValue = detailedForm?.maxValue?.match(/\d/g);
      maxAmounts = newMaxValue?.join("");
    }

    if (name === "maxValue") {
      if (singleUserOrder?.type === "buy") {
        if (
          Number(ourValue) <
          Number(typeof detailedForm?.minValue !== "string" ? singleUserOrder?.minAmount : minAmounts)
        ) {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
        } else {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
        }
      }

      if (singleUserOrder?.type === "sell") {
        clearTimeout(checkFeesRef.current);

        setFeesChecker(true);

        checkFeesRef.current = setTimeout(async () => {
          try {
            const res = await PostTradeService.getTradeFees(Number(ourValue));

            setFeesPrice(res.fees);
            setTotalPrices(Number(ourValue) + res.fees);

            if (
              allCurrencyDetails.marginCoinBalance < Number(ourValue) ||
              allCurrencyDetails.marginCoinBalance < Number(Number(ourValue) + res.fees)
            ) {
              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
            } else {
              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: ""});
            }

            if (
              Number(ourValue) <
              Number(typeof detailedForm?.minValue !== "string" ? singleUserOrder?.minAmount : minAmounts)
            ) {
              dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
            } else {
              dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
            }

            setFeesChecker(false);
          } catch (error) {
            setFeesChecker(false);
          }
        }, 300);
      }
    }

    if (name === "minValue") {
      if (singleUserOrder?.type === "buy") {
        if (
          Number(ourValue) >
          Number(typeof detailedForm?.maxValue !== "string" ? singleUserOrder?.maxAmount : maxAmounts)
        ) {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
        } else {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
        }
      }

      if (singleUserOrder?.type === "sell") {
        if (
          allCurrencyDetails.marginCoinBalance <
            Number(typeof detailedForm?.maxValue !== "string" ? singleUserOrder?.maxAmount : maxAmounts) ||
          allCurrencyDetails.marginCoinBalance < Number(totalPrices)
        ) {
          dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
        } else {
          dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: ""});
        }

        if (
          Number(ourValue) >
          Number(typeof detailedForm?.maxValue !== "string" ? singleUserOrder?.maxAmount : maxAmounts)
        ) {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: "maximum limit must be greater than minimum limit"});
        } else {
          dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
        }
      }
    }

    if (name === "tagLine") {
      if (value.length < 20) {
        setTagLineError("length of words must be greater than 10");
      } else {
        setTagLineError("");
      }
    }

    if (name === "description") {
      if (value.length < 50) {
        setDiscriptionError("length of words must be greater than 50");
      } else {
        setDiscriptionError("");
      }
    }
  };

  const editPostTradeData = (data, navigate) => {
    dispatch({type: IS_LOADING, payload: true});
    EditPostTradeService.editPostTrade(data, navigate);
  };

  const deletePostTradeData = (id, navigate) => {
    dispatch({type: IS_LOADING, payload: true});
    EditPostTradeService.deletePostTrade(id, navigate);
  };

  const HandleSubmit = (e) => {
    e.preventDefault();

    detailedForm["profitMargin"] = profitMargin;
    detailedForm["status"] = orderStatus?.name;

    if (detailedForm.minValue && typeof detailedForm?.minValue === "string") {
      const newMinValue = detailedForm.minValue.match(/\d/g);
      detailedForm["minAmount"] = newMinValue.join("");
      detailedForm.minAmount = Number(detailedForm.minAmount);
    } else {
      detailedForm["minAmount"] = singleUserOrder?.minAmount;
    }

    if (detailedForm.minValue === "" && typeof detailedForm?.minValue === "string") {
      detailedForm.minAmount = 0;
    }

    if (detailedForm?.maxValue && typeof detailedForm?.maxValue === "string") {
      const newMaxValue = detailedForm.maxValue.match(/\d/g);
      detailedForm["maxAmount"] = newMaxValue.join("");
      detailedForm.maxAmount = Number(detailedForm.maxAmount);
    } else {
      detailedForm["maxAmount"] = singleUserOrder?.maxAmount;
    }

    if (detailedForm?.maxValue === "" && typeof detailedForm?.maxValue === "string") {
      detailedForm.maxAmount = 0;
    }

    delete detailedForm.minValue;
    delete detailedForm.maxValue;

    if (
      singleUserOrder.type === "sell" &&
      (allCurrencyDetails.marginCoinBalance < detailedForm.maxAmount ||
        allCurrencyDetails.marginCoinBalance < detailedForm.minAmount)
    ) {
      return dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: "Insufficient banlance"});
    } else {
      const newDetails = {...singleUserOrder, ...detailedForm};

      delete newDetails.country;
      delete newDetails.currency;
      delete newDetails.tokenSymbol;
      delete newDetails.type;

      editPostTradeData(newDetails, history);
    }
  };

  const HandleDeleteTrade = (e) => {
    e.preventDefault();
    deletePostTradeData(singleUserOrder._id, history);
  };

  return (
    <div>
      <div className="postTrade__fourthLayout">
        <div className="postTrade__fourthLayoutContainer">
          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Min. Transaction Limit</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label>This is the minimum transaction limit</label>
              <div className={`postTrade__fourthLayoutOneInputContainer`}>
                <CurrencyFormat
                  name="minValue"
                  value={detailedForm?.minValue ? detailedForm?.minValue : singleUserOrder.minAmount || ""}
                  thousandSeparator={true}
                  onChange={handleChageSystem}
                  autocomplete="off"
                  placeholder={"5.00"}
                />
                <div className="postTrade__fourthLayoutOneInputContainerLabel">
                  <p>{`${singleUserOrder?.currency}`}</p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="postTrade__fourthLayoutOne"
            style={{alignItems: `${singleUserOrder?.type === "sell" ? "flex-start" : ""}`}}
          >
            <div
              className="postTrade__fourthLayoutOneTitle"
              style={{marginTop: `${singleUserOrder?.type === "sell" ? "30px" : ""}`}}
            >
              <p>Max. Transaction Limit</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label>Your maximum transaction</label>
              <div className={`postTrade__fourthLayoutOneInputContainertwo`}>
                <CurrencyFormat
                  value={detailedForm?.maxValue ? detailedForm?.maxValue : singleUserOrder.maxAmount || ""}
                  placeholder="1,000.00"
                  name="maxValue"
                  thousandSeparator={true}
                  autocomplete="off"
                  onChange={handleChageSystem}
                />
                <div className="postTrade__fourthLayoutOneInputContainerLabeltwo">
                  <div className="postTrade__fourthLayoutOneInputContainerLabelContenttwo">
                    <p>{`${singleUserOrder?.currency}`}</p>
                  </div>
                </div>
              </div>
              <div className="postTrade__fourthLayoutOneTotal">
                {singleUserOrder?.type === "sell" && (
                  <p
                    className="postTrade__fourthLayoutOneTotalMax"
                    onClick={async () => {
                      clearTimeout(checkFeesRef.current);

                      setFeesChecker(true);

                      checkFeesRef.current = setTimeout(async () => {
                        try {
                          const stateValue =
                            Object.keys(allCurrencyDetails).length > 0
                              ? allCurrencyDetails?.marginCoinBalance?.toString().split(".")[0]
                              : 0;

                          const res = await PostTradeService.getTradeFees(Number(stateValue));
                          // const fees = (0.5 * Number(stateValue)) / 100;
                          setFeesPrice(res.fees);
                          setTotalPrices(stateValue);

                          const currentValue = Number(stateValue) - res.fees;

                          dispatch({
                            type: EDIT_POST_TRADE_DETAILED_FORM,
                            payload: {
                              ...detailedForm,
                              maxValue: currentValue?.toString(),
                            },
                          });

                          let minAmounts;

                          if (typeof detailedForm?.minValue === "string") {
                            const newMinValue = detailedForm?.minValue?.match(/\d/g);
                            minAmounts = newMinValue?.join("");
                          }

                          let maxAmounts;

                          if (typeof detailedForm?.maxValue === "string") {
                            const newMaxValue = detailedForm?.maxValue?.match(/\d/g);
                            maxAmounts = newMaxValue?.join("");
                          }

                          if (singleUserOrder?.type === "sell") {
                            if (allCurrencyDetails.marginCoinBalance < Number(stateValue)) {
                              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
                            } else {
                              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: ""});
                            }

                            if (
                              Number(stateValue) <
                              Number(
                                maxAmounts !== undefined || maxAmounts !== null
                                  ? minAmounts
                                  : singleUserOrder?.maxAmount
                              )
                            ) {
                              dispatch({
                                type: EDIT_POST_TRADE_BUY_ERROR,
                                payload: "maximum limit must be greater than minimum limit",
                              });
                            } else {
                              dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
                            }
                          }

                          setFeesChecker(false);
                        } catch (error) {
                          setFeesChecker(false);
                        }
                      }, 300);
                    }}
                  >
                    Max
                  </p>
                )}

                {singleUserOrder?.type === "buy" && buyError && <span className="error">{buyError}</span>}

                {singleUserOrder?.type === "sell" &&
                  (sellError ? (
                    <span className="error">{sellError}</span>
                  ) : buyError ? (
                    <span className="error">{buyError}</span>
                  ) : (
                    <span>
                      Avaliable Balance:{""}
                      <CurrencyFormat
                        value={`${
                          Object.keys(allCurrencyDetails).length > 0 ? allCurrencyDetails.marginCoinBalance : 0
                        }`}
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={2}
                        fixedDecimalScale
                        suffix={` ${singleUserOrder?.currency}`}
                      />
                    </span>
                  ))}
              </div>

              {singleUserOrder?.type === "sell" && (
                <>
                  <div className="postTrade__fourthLayoutOneTotal">
                    <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Fees Percentage</p>

                    <span>0.5%</span>
                  </div>

                  <div className="postTrade__fourthLayoutOneTotal">
                    <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Fees Selling Prices</p>

                    {feesChecker ? (
                      <div>
                        <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                      </div>
                    ) : (
                      <span>
                        <CurrencyFormat
                          value={`${feesPrice > 0 ? feesPrice : 0}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${singleUserOrder?.currency}`}
                        />
                      </span>
                    )}
                  </div>

                  <div className="postTrade__fourthLayoutOneTotal">
                    <p className="postTrade__fourthLayoutOneTotalMaxSellPrice">Total Selling Max Price</p>

                    {feesChecker ? (
                      <div>
                        <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                      </div>
                    ) : (
                      <span>
                        <CurrencyFormat
                          value={`${totalPrices > 0 ? totalPrices : 0}`}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          suffix={` ${singleUserOrder?.currency}`}
                        />
                      </span>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="postTrade__fourthLayoutContainer">
          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Payment Method</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <div className="postTrade__firstLayoutSelectCryptoBodyContentDresser">
                <div className="postTrade__firstLayoutSelectCryptoBodyContentDresserContent">
                  <p>{singleUserOrder?.paymentMethod}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="postTrade__fourthLayoutOne">
            <div className="postTrade__fourthLayoutOneTitle">
              <p>Tagline</p>
            </div>
            <div className="postTrade__fourthLayoutOneInput">
              <label className="postTrade__fourthLayoutOneInputContainerthreeLabel">Write a sales tagline</label>
              <div className="postTrade__fourthLayoutOneInputContainerthree">
                <input
                  type="text"
                  name="tagLine"
                  value={detailedForm?.tagLine ? detailedForm?.tagLine : singleUserOrder?.tagLine || ""}
                  onChange={handleChageSystem}
                  autocomplete="off"
                  minLength={10}
                  maxLength={50}
                />
              </div>
              {tagLineError ? (
                <span className={`postTrade__fourthLayoutOneInputContainerthreeCharacter error`}>{tagLineError}</span>
              ) : (
                <span className="postTrade__fourthLayoutOneInputContainerthreeCharacter">minimum character is 10</span>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="postTrade__fivethLayout">
        <div className="postTrade__fivethLayoutOne">
          <div className="postTrade__fivethLayoutOneInput">
            <label>Description</label>
            <textarea
              maxLength={500}
              minLength={50}
              name="description"
              value={detailedForm?.description ? detailedForm?.description : singleUserOrder?.description || ""}
              onChange={handleChageSystem}
              autocomplete="off"
              placeholder="Enter a description..."
            ></textarea>
            {descriptionError ? <p className="error">{descriptionError}</p> : <p>minimum character is 50</p>}
            <p>You can write more information about your order here.</p>
          </div>
        </div>
        <div className="postTrade__fivethLayoutTwo">
          <div className="postTrade__fivethLayoutTwoBody">
            <div className="postTrade__fivethLayoutTwoBodyOne">
              <button className="postTrade__fivethLayoutTwoBodyOneCancel">Cancel</button>
              <button
                disabled={
                  Object.keys(singleUserOrder).length < 12 ||
                  editTradeDisable ||
                  !detailedForm?.description ||
                  !detailedForm?.tagLine ||
                  !detailedForm?.maxValue ||
                  !detailedForm?.minValue ||
                  buyError ||
                  feesChecker ||
                  tagLineError ||
                  descriptionError ||
                  profitMargin === null ||
                  profitMargin === undefined
                    ? true
                    : singleUserOrder?.type === "sell"
                    ? sellError
                      ? true
                      : false
                    : false
                }
                onClick={HandleSubmit}
                className="postTrade__fivethLayoutTwoBodyOneConfirm"
              >
                Save Changes
              </button>
            </div>
            <div className="postTrade__fivethLayoutTwoBodyTwo">
              <button onClick={() => setDeteleOrder(true)}>Delete</button>
            </div>
          </div>
        </div>
      </div>

      <SectionModalCentered open={deleteOrder}>
        <DeleteComponent onclick={HandleDeleteTrade} closedelete={setDeteleOrder} />
      </SectionModalCentered>
    </div>
  );
};

export default memo(EditTradeFourthSection);
