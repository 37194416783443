import React from "react";
import {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {PAYMENTDATA} from "../../store/types";
import SearchableDropDown from "../Common/SearchableDropDown/SearchableDropDown";

const SelectPayment = () => {
  const dispatch = useDispatch();
  const paymentMethod = useSelector((state) => state.generalReducer.paymentMethod);
  const [paymentStateValue, setPaymentStateValue] = useState({});

  const HandleClickSearch = (item) => {
    setPaymentStateValue({...item});
    dispatch({type: PAYMENTDATA, payload: item});
  };

  return (
    <div>
      <SearchableDropDown
        title={`For online trade, you need to specify the country`}
        name={"name"}
        search={paymentStateValue}
        placeholder={"Payment Method"}
        HandleSelect={HandleClickSearch}
        apiList={paymentMethod?.data?.paymentsData?.paymentMethod}
        setSearch={setPaymentStateValue}
      />
    </div>
  );
};

export default SelectPayment;
