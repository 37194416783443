import {useContext, useState} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {BNB, BTC, ETH, USDT} from "../../hooks/imagePath";
import {
  getBuySellDisputeTransactionAction,
  getBuySellPendingTransactionAction,
  getBuySellTransactionAction,
} from "../../store/actions/buySellTransactionHistory";
import {filterUserTradeByCoinAction, filterUserTradeByTypeAction} from "../../store/actions/getUserTrade";
import {
  FILTER_BUY_SELL_DEFAULT,
  GET_COMPLETED_TRADE,
  GET_DISPUTED_TRADE,
  GET_LOADER,
  GET_PENDING_TRADE,
  TRADE_CHECK_FOR_EMPTY,
  TRADE_CHECK_FOR_EXTRA_DATA,
  TRADE_DETAILS,
  TRADE_SKIP,
} from "../../store/types";
import "../../styles/tradeOrders/tradeOrderTop.scss";
import {TransactionStaticDropDown} from "../Common/transactionStaticDropdown";
import {TransactionTableStaticDropDown} from "../Common/transactionTableStaticDropdown";
import {TheUserTradeContext} from "../Home/UserTrade";
import UserTradeService from "../../services/getusertrades";

const TradeOrderTop = () => {
  const dispatch = useDispatch();
  const {pending2, disputed2, fSkip, limit, tradeDetials} = useSelector((state) => state.getUserTradeReducer);
  const {setTradeOrder, tradeOrder} = useContext(TheUserTradeContext);

  const getAllTrade = (data) => {
    dispatch({type: GET_LOADER, payload: true});
    dispatch({type: TRADE_DETAILS, payload: {type: null, tokenSymbol: null, status: null}});
    dispatch({type: TRADE_SKIP, payload: 0});
    dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: null});
    dispatch({type: GET_COMPLETED_TRADE, payload: []});
    UserTradeService.AllKindOfStutusUserTradeService(fSkip, limit, null, null, null).then((data) => {
      dispatch({type: GET_COMPLETED_TRADE, payload: data?.trades});
      dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.trades});
      dispatch({type: GET_LOADER, payload: false});

      if (data?.trades?.length === limit) {
        dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: true});
        const futureSkipDetails = fSkip + limit;

        UserTradeService.AllKindOfStutusUserTradeService(futureSkipDetails, limit, null, null, null).then((data) => {
          if (data?.trades?.length === 0) {
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          } else {
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          }
        });
      }
    });
  };

  const getAllPendingTrade = (data) => {
    dispatch({type: GET_LOADER, payload: true});
    dispatch({type: TRADE_DETAILS, payload: {type: null, tokenSymbol: null, status: data}});
    dispatch({type: TRADE_SKIP, payload: 0});
    dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: null});
    dispatch({type: GET_PENDING_TRADE, payload: []});
    UserTradeService.OnlyPendingTradeService(fSkip, limit).then((data) => {
      dispatch({type: GET_PENDING_TRADE, payload: data?.pendingTrades});
      dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.pendingTrades});
      dispatch({type: GET_LOADER, payload: false});

      if (data?.pendingTrades?.length === limit) {
        dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: true});

        const futureSkipDetails = fSkip + limit;
        UserTradeService.OnlyPendingTradeService(futureSkipDetails, limit).then((item) => {
          if (item?.pendingTrades.length === 0) {
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          } else {
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          }
        });
      }
    });
  };

  const getAllDesputedTrade = (data) => {
    dispatch({type: GET_LOADER, payload: true});
    dispatch({type: TRADE_DETAILS, payload: {type: null, tokenSymbol: null, status: data}});
    dispatch({type: TRADE_SKIP, payload: 0});
    dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: null});
    dispatch({type: GET_DISPUTED_TRADE, payload: []});
    UserTradeService.AllKindOfStutusUserTradeService(fSkip, limit, null, null, data).then((data) => {
      dispatch({type: GET_DISPUTED_TRADE, payload: data?.trades});
      dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.trades});
      dispatch({type: GET_LOADER, payload: false});

      if (data?.trades?.length === limit) {
        dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: true});

        const futureSkipDetails = fSkip + limit;
        UserTradeService.AllKindOfStutusUserTradeService(futureSkipDetails, limit, null, null, data).then((item) => {
          if (item?.trades.length === 0) {
            dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          } else {
            dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
          }
        });
      }
    });
  };

  const fetchAllData = (details) => {
    dispatch({
      type: TRADE_DETAILS,
      payload: {type: details?.type, tokenSymbol: details?.tokenSymbol, status: details?.status},
    });

    dispatch({type: GET_LOADER, payload: true});

    UserTradeService.AllKindOfStutusUserTradeService(
      fSkip,
      limit,
      details?.tokenSymbol,
      details?.type,
      details?.status
    ).then((data) => {
      dispatch({type: GET_COMPLETED_TRADE, payload: data?.trades});
      dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: data?.trades});
      dispatch({type: GET_LOADER, payload: false});

      const futureSkipDetails = fSkip + limit;
      UserTradeService.AllKindOfStutusUserTradeService(
        futureSkipDetails,
        limit,
        details?.tokenSymbol,
        details?.type,
        details?.status
      ).then((data) => {
        if (data.trades.length === 0) {
          dispatch({type: TRADE_CHECK_FOR_EMPTY, payload: []});
          dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
        } else {
          dispatch({type: TRADE_CHECK_FOR_EXTRA_DATA, payload: false});
        }
      });
    });
  };

  return (
    <div className="__trade__order__topWrapper">
      <div className="tradeorder__titleWrapper">
        <div className="tradeorder__titleTitle">
          <h3 className="tradeorder__title">Trades</h3>
          <p className="tradeorder__titleDesc">View all Trades</p>
        </div>
      </div>

      <div className="__tradeorder__button__wrapper">
        <div
          className={`__tradeorder__button __ls ${tradeOrder === "pending" && "__active"}`}
          onClick={() => {
            setTradeOrder("pending");
            getAllPendingTrade("pending");
            // dispatch({type: FILTER_BUY_SELL_DEFAULT});
            // dispatch(getBuySellPendingTransactionAction({skip: 0, fskip: 0, limit: 10}));
          }}
        >
          Pending <span className="__tradeorder__count">{pending2 && pending2}</span>
        </div>
        <div
          className={`__tradeorder__button __md ${tradeOrder === "disputed" && "__active"}`}
          onClick={() => {
            setTradeOrder("disputed");
            getAllDesputedTrade("disputed");
            // dispatch({type: FILTER_BUY_SELL_DEFAULT});
            // dispatch(getBuySellDisputeTransactionAction({skip: 0, fskip: 0, limit: 10}));
          }}
        >
          Disputed <span className="__tradeorder__count">{disputed2 && disputed2}</span>
        </div>
        <div
          className={`__tradeorder__button __rs ${tradeOrder === "all" && "__active"}`}
          onClick={() => {
            setTradeOrder("all");
            getAllTrade("completed");
            // dispatch({type: FILTER_BUY_SELL_DEFAULT});
            // dispatch(getBuySellTransactionAction({skip: 0, fskip: 0, limit: 10}));
          }}
        >
          All Trades
        </div>
      </div>
      <div className="__thefil">
        <div className="__ggs">
          <TransactionTableStaticDropDown
            options={[
              {
                value: "BTC",
                attr: "token",
                label: (
                  <span className="__tokenfileterstyle">
                    <img src={BTC} width="30" alt="btc" className="mr-1" />
                    BTC
                  </span>
                ),
              },
              {
                value: "BNB",
                attr: "token",
                label: (
                  <span className="__tokenfileterstyle">
                    <img src={BNB} width="30" alt="btc" className="mr-1" />
                    BNB
                  </span>
                ),
              },
              {
                value: "ETH",
                attr: "token",
                label: (
                  <span className="__tokenfileterstyle">
                    <img src={ETH} width="30" alt="btc" className="mr-1" />
                    ETH
                  </span>
                ),
              },
              {
                value: "USDT",
                attr: "token",
                label: (
                  <span className="__tokenfileterstyle">
                    <img src={USDT} width="30" alt="btc" className="mr-1" />
                    USDT
                  </span>
                ),
              },
            ]}
            name={"Coin Type"}
            onChangeFunction={(c) => {
              if (c !== null) {
                const coinDetails = {...tradeDetials, tokenSymbol: c.token};
                fetchAllData(coinDetails);
              } else {
                const coinDetails = {...tradeDetials, tokenSymbol: c};
                fetchAllData(coinDetails);
              }
            }}
          />
        </div>
        <div className="__ggs">
          <TransactionTableStaticDropDown
            options={[
              {
                value: "buy",
                attr: "type",
                label: <span className="__tokenfileterstyle">Buy</span>,
              },
              {
                value: "sell",
                attr: "type",
                label: <span className="__tokenfileterstyle">Sell</span>,
              },
            ]}
            name={"Type"}
            onChangeFunction={(v) => {
              if (v !== null) {
                const typeDetails = {...tradeDetials, type: v.type};
                fetchAllData(typeDetails);
              } else {
                const typeDetails = {...tradeDetials, type: v};
                fetchAllData(typeDetails);
              }
            }}
          />
        </div>

        {tradeOrder === "all" && (
          <div className="__ggs">
            <TransactionTableStaticDropDown
              options={[
                {
                  value: "pending",
                  attr: "status",
                  label: <span className="__tokenfileterstyle">Pending</span>,
                },
                {
                  value: "disputed",
                  attr: "status",
                  label: <span className="__tokenfileterstyle">Disputed</span>,
                },
                {
                  value: "paid",
                  attr: "status",
                  label: <span className="__tokenfileterstyle">Paid</span>,
                },
                {
                  value: "completed",
                  attr: "status",
                  label: <span className="__tokenfileterstyle">Completed</span>,
                },
              ]}
              name={"Status"}
              onChangeFunction={(v) => {
                if (v !== null) {
                  const statusDetails = {...tradeDetials, status: v.status};
                  fetchAllData(statusDetails);
                } else {
                  const statusDetails = {...tradeDetials, status: v};
                  fetchAllData(statusDetails);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default TradeOrderTop;
