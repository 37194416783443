import {maxHeight} from "@mui/system";
import {useRef} from "react";
import {useEffect} from "react";
import {useContext} from "react";
import {useDispatch, useSelector} from "react-redux";
import {BottomSheet} from "react-spring-bottom-sheet";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {checkoutDefaultStateAction} from "../../../store/actions/checkoutData";
import {getMarketCheckOutDataAction} from "../../../store/actions/market";
import "../../../styles/tradeAction.scss";
import {capitalizeFirstLetter} from "../../../utils/capitalizeFirstLetter";
import {OurModal2} from "../../Common/modal";
import {MarketPlaceContext} from "../../Home/MarketPlace";
import {ActionModalContext} from "../marketTable";
import DeskTopActionModal from "./desktop";
import ModalActionModal from "./mobile";
import {CURRENT_TOTAL_VALUE} from "../../../store/types";
import CanNotCallModal from "./desktop/canNotCallModal";
import CanNotCallMobileModal from "./mobile/canNotCallMobileModal";

const TradeActionModal = ({}) => {
  const {width} = useWindowDimensions();
  const sheetRef = useRef();
  const dispatch = useDispatch();
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  const {modalID, modalAction, setModalAction} = useContext(ActionModalContext);

  useEffect(() => {
    if (!modalID?.notAllow) {
      if (modalID?.id) {
        dispatch(getMarketCheckOutDataAction({id: modalID?.id, traderID: modalID?.traderID}));
      }
    }

    return () => {
      dispatch(checkoutDefaultStateAction());
    };
  }, [modalID]);

  return width <= 600 ? (
    <>
      {modalID?.notAllow === true ? (
        <BottomSheet
          ref={sheetRef}
          open={modalAction}
          onDismiss={() => {
            setModalAction(false);
            dispatch({type: CURRENT_TOTAL_VALUE, payload: null});
          }}
          snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight * 0.85]}
          header={
            <h3
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
                padding: "5px 0 5px 0",
              }}
            >
              {`${marketPlaceData?.type?.toUpperCase()}`}
            </h3>
          }
        >
          <CanNotCallMobileModal />
        </BottomSheet>
      ) : (
        <BottomSheet
          ref={sheetRef}
          open={modalAction}
          onDismiss={() => {
            setModalAction(false);
            dispatch({type: CURRENT_TOTAL_VALUE, payload: null});
          }}
          snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight * 0.85]}
          header={
            <h3
              style={{
                textAlign: "center",
                fontSize: "18px",
                fontWeight: 600,
                padding: "5px 0 5px 0",
              }}
            >
              {`${marketPlaceData?.type?.toUpperCase()}`}
            </h3>
          }
        >
          <ModalActionModal />
        </BottomSheet>
      )}
    </>
  ) : (
    <>
      {modalID?.notAllow === true ? (
        <OurModal2 open={modalAction} setOpen={setModalAction}>
          <CanNotCallModal />
        </OurModal2>
      ) : (
        <OurModal2 open={modalAction} setOpen={setModalAction}>
          <DeskTopActionModal />
        </OurModal2>
      )}
    </>
  );
};

export {TradeActionModal};
