import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {forgotPasswordLoader, loginDetails, loginError} from "../../store/actions/auth";
import {useHistory} from "react-router-dom";
import AuthService from "../../services/auth";
import {EMAIL_REGEX} from "../../utils/Validators";
import {NewFormInput} from "../Common/NewFormInput";
import "../../styles/login.scss";
import {toast} from "react-toastify";
import ShowErrorMessageLogin from "../Common/ShowErrorMessageLogin";

const ForgotForm = () => {
  const [email, setEmail] = useState("");
  const [validEmail, setValidEmail] = useState(false);
  const useloginErrors = useSelector((state) => state.authReducer.loginErrorsState);

  const dispatch = useDispatch();
  const history = useHistory();

  const validateCredentials = (e) => {
    switch (e.nativeEvent.srcElement.attributes.id.value) {
      case "email": {
        setValidEmail(EMAIL_REGEX.test(e.target.value));
        setEmail(e.target.value);
        break;
      }
      default: {
        break;
      }
    }
  };

  const goToOtp = () => {
    const payload = {
      email: email,
      username: "",
      isLogin: false,
    };

    if (validEmail) {
      dispatch(loginError(""));
      dispatch(forgotPasswordLoader(1));
      AuthService.verifyEmailSecurely(payload)
        .then((data) => {
          if (data.data.message === "success") {
            AuthService.userDataForgotPassword(payload).then((phone) => {
              if (phone?.userDetails?.authPreference === "google auth") {
                dispatch(loginDetails({...phone?.userDetails, ...payload}));
                dispatch(forgotPasswordLoader(4));
              } else {
                if (phone.message === "success") {
                  if (phone.userDetails.hasOwnProperty("phoneNumber")) {
                    const payload = {
                      phoneNumber: phone.userDetails.phoneNumber,
                      email: phone.userDetails.email,
                    };
                    verifyPhone(payload);
                  } else {
                    dispatch(forgotPasswordLoader(2));
                    dispatch(loginDetails({email: email, emailCode: data}));
                    dispatch(loginError(""));
                  }
                }
              }
            });
          } else {
            dispatch(forgotPasswordLoader(0));
            dispatch(loginError(data.data.message));
          }
        })
        .catch(() => {
          setTimeout(() => {
            dispatch(forgotPasswordLoader(0));
          }, 1000);
        });
    } else {
      dispatch(loginError("Please enter your email"));
    }
  };

  const verifyPhone = (payload) => {
    AuthService.verifyPhoneNumber(payload)
      .then((data) => {
        if (data.message === "success") {
          dispatch(forgotPasswordLoader(3));
          dispatch(loginDetails({email: email, emailCode: data}));
          dispatch(loginError(""));
        } else {
          dispatch(forgotPasswordLoader(0));
          dispatch(loginError(data.message));
        }
      })
      .catch((error) => {
        // toast("Something went wrong");
      });
  };

  return (
    <div className="login-container">
      <div className="login-content">
        <div className="forgot-form-contents">
          <p className="title">Reset your password</p>
          <div style={{width: "100%"}}>
            <NewFormInput
              data-testid="email"
              id="email"
              margin={"30px"}
              label={"Email"}
              type={"email"}
              displayError={useloginErrors}
              value={email}
              onChange={validateCredentials}
            />
          </div>

          <ShowErrorMessageLogin id="useloginErrors" value={useloginErrors} />

          <button data-testid="request btn" className="buttonStyle" style={signUp} onClick={goToOtp}>
            Request new password
          </button>

          <button
            data-testid="back btn"
            className="buttonStyle"
            style={googleSignUp}
            onClick={() => {
              history.push("/");
              dispatch(loginDetails(""));
              dispatch(loginError(""));
            }}
          >
            Back to Login
          </button>
        </div>
      </div>
    </div>
  );
};

const signUp = {
  width: "100%",
  color: "#fff",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",

  "&:hover": {
    backgroundColor: "#5d3cb2",
    boxShadow: "none",
  },
  "&:disabled": {
    border: "1px solid rgb(93, 60, 178, 0.4)",
    backgroundColor: "rgb(93, 60, 178, 0.4)",
    color: "white",
  },
};

const googleSignUp = {
  width: "100%",
  background: "#fff",
  color: "#344054",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #d0d5dd",

  "&:hover": {
    backgroundColor: "#fff",
    boxShadow: "none",
  },
};

export default memo(ForgotForm);
