import {useDispatch, useSelector} from "react-redux";
import React, {useContext} from "react";
import notFound from "../../assets/icons/notfound.svg";
import CurrencyFormat from "react-currency-format";
import {CountryIcons} from "../Common/CountryIcons";
import {CoinIcons} from "../Common/coinSwitcher";
import {TradeActionContext} from "../Home/Profile";
import "./newtable.scss";
import {capitalizeFirstLetter} from "../../utils/capitalizeFirstLetter";
import {CURRENT_TOTAL_VALUE} from "../../store/types";

const AllTradeMobileTable = () => {
  const {tradeActive} = useSelector((state) => state.tradeReducer);
  const activeTrade = tradeActive ?? [{}];
  const short = () => {
    if (activeTrade.length > 0 && activeTrade.length > 3) {
      return activeTrade?.slice(0, 5);
    } else {
      return activeTrade;
    }
  };

  return short() && short().length > 0 ? (
    <React.Fragment>
      <div className="__alltradeorder__mobile__table">
        {short().map((item, index) => {
          return <MobileRow key={index} data={item} />;
        })}
      </div>
      <span
        style={{
          margin: "20px 0",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        {/* <p
          style={{
            background: "#7F56D9",
            fontSize: "12px",
            padding: "8px",
            color: "white",
            borderRadius: "7px",
            cursor: "pointer",
          }}
          onClick={() => {
            history.push("/home/trade");
          }}
        >
          view more
        </p> */}
      </span>
    </React.Fragment>
  ) : (
    // <MobileRow />
    <div style={{textAlign: "center", marginTop: "50px"}}>
      <img src={notFound} alt="not found" style={{width: "100px", height: "auto"}} />
      <p style={{fontSize: "12px"}}>No Reviews</p>
    </div>
  );
};

const MobileRow = (props) => {
  const dispatch = useDispatch();
  const traderID = props.data.traderID;
  const {setModalAction, setModalID} = useContext(TradeActionContext);

  const userData = useSelector((state) => state.generalReducer.userData);

  return (
    <div className="__alltradeorder__content">
      <div className="__alltradeorder__top">
        <div className="__thecoinWrapper">
          <div className="__thecoinImage">
            <CoinIcons intialValue={props?.data?.tokenSymbol} />
          </div>
          <div className="__coinNames">
            <p>{props?.data?.tokenSymbol}</p>
          </div>
        </div>

        <div className="__allTradeStatus __active">{props?.data?.status}</div>
      </div>

      <div className="__tradeOrder__details">
        <div className="__unitTitle">
          <span className="__unitText">Trade ID</span>
          <span className="__unitValue">{traderID}</span>
        </div>
        <div className="__unitTitle">
          <span className="__unitText">Unit</span>
          <span className="__unitValue">
            <CurrencyFormat
              value={props?.data?.minAmount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              suffix={` ${"NGN"}`}
            />
          </span>
        </div>
        <div className="__unitTitle">
          <span className="__unitText">Limit</span>
          <span className="__unitValue">
            <CurrencyFormat
              value={props?.data?.minAmount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              prefix={`${"NGN"} `}
            />
            {" - "}
            <CurrencyFormat
              value={props?.data?.maxAmount}
              displayType={"text"}
              thousandSeparator={true}
              decimalScale={2}
              fixedDecimalScale
              suffix={` ${"NGN"}`}
            />
          </span>
        </div>
      </div>

      <div className="__alltradeOrder__bottom">
        <div className="__tradePayment__country">
          <div className="__thePayment__method">{props?.data.paymentMethod}</div>
          <div className="__theLoaction">
            <div className="__location__icon">
              <CountryIcons intialValue={props?.data?.country} />
            </div>
            <span className="__location__name">{props?.data.country}</span>
          </div>
        </div>

        <span
          className="__theAction"
          onClick={() => {
            setModalAction(true);
            setModalID({
              id: props?.data?._id,
              traderID: props?.data?.traderID,
            });

            const presentToken = userData?.data?.userData?.tokens?.find(
              (data) =>
                data?.tokenSymbol?.toLowerCase() === `${capitalizeFirstLetter(props?.data?.tokenSymbol)}`.toLowerCase()
            );

            dispatch({type: CURRENT_TOTAL_VALUE, payload: presentToken?.balance});
          }}
        >
          {props?.data?.type}
          &nbsp;
          {props?.data?.tokenSymbol?.toLowerCase()}
        </span>
      </div>
    </div>
  );
};

export default AllTradeMobileTable;
