import React, {memo} from "react";
import {useDispatch, useSelector} from "react-redux";
import {settingVerifyModal} from "../../store/actions/settingAction";
import success from "../../assets/images/basic-verification.svg";
import GeneralService from "../../services/general";
import {getUser} from "../../store/actions/general";

const SuccessfullyModal = () => {
  // toggle to next modal

  const dispatch = useDispatch();
  const {storeLinkUrl} = useSelector((state) => state.settingsReducer);

  function cancelButton() {
    dispatch(settingVerifyModal(14));
    GeneralService.getUserData().then((data) => {
      dispatch(getUser(data ?? {}));
      dispatch(settingVerifyModal(null));
    });
  }

  return (
    <main
      className="modal-container"
      data-aos="zoom-in-down"
      data-aos-easing="linear"
      data-aos-duration="100"
      style={{width: "350px", height: "340px"}}
    >
      <div className="basic-verification-container">
        <img src={success} alt="" width="60" height={"auto"} />
        <p className="title">Basic verification</p>
        <p className="tag">Complete</p>
        <p className="text">Continue verifiication to enjoy higher limits of transaction</p>
      </div>

      <div style={{display: "flex", flexDirection: "column", padding: "2rem 0"}}>
        <a
          className="base-button"
          style={{
            ...buttonStyle,
          }}
          target="_blank"
          href={storeLinkUrl ?? ""}
          rel="noreferrer"
          onClick={cancelButton}
        >
          Continue
        </a>
        <button style={{...buttonStyle, ...cancelStyle}} className="base-button" onClick={cancelButton}>
          Skip for now
        </button>
      </div>
    </main>
  );
};

const buttonStyle = {
  padding: "10px 40px",
  borderRadius: "7.67695px",
  margin: "0.5rem 4rem 0.2rem",
  textAlign: "center",
};

const cancelStyle = {
  background: "transparent",
  border: "none",
  color: "#101828",
  boxShadow: "none",
  fontWeight: "400",
};
export default memo(SuccessfullyModal);
