import "../../styles/landing-page-styles/card.scss";

const Card = ({title, text, icon}) => {
  return (
    <ul className="ulListContainer">
      <li className="item">
        <img src={icon} alt="check" width={50} className="icon" />
        <p className="title">{title}</p>
        <p className="text">{text}</p>
      </li>
    </ul>
  );
};

export default Card;
