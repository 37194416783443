import {AiOutlineArrowLeft, AiOutlineArrowRight} from "react-icons/ai";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import "../../../styles/notificationPagination.scss";

const NotificationPaginationComponent = ({onPrev, onNext, skip}) => {
  const {width} = useWindowDimensions();
  return width <= 650 ? (
    <div className="__mobile__pagination">
      <div className="__left" onClick={() => onPrev()}>
        <AiOutlineArrowLeft size={20} />
      </div>
      <span className="__current">Page {skip}</span>
      <div className="__right" onClick={() => onNext()}>
        <AiOutlineArrowRight size={20} />
      </div>
    </div>
  ) : (
    <div className="notification__paginationWrapper">
      <div className="newTable__paginationNumber">Page {skip}</div>
      <div className="newTable__paginationNavigation">
        <button className="newTable__paginationNavigation__button" onClick={() => onPrev()}>
          previous
        </button>
        <button className="newTable__paginationNavigation__button" onClick={() => onNext()}>
          next
        </button>
      </div>
    </div>
  );
};

export {NotificationPaginationComponent};
