import {useEffect, useState, memo} from "react";
import {BiDislike, BiLike} from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {RATE_TRADE_DEFAULT} from "../../../store/types";
import ReviewComments from "./reviewComment";

const ReviewAndWallet = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const [review, setReview] = useState();
  const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  const {response, error} = useSelector((state) => state.rateTradeReducer);
  const {response: reviewRes, error: reviewError, reviewResponse} = useSelector((state) => state.tradeReviewReducer);
  const {response: tradeResponse} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(tradeResponse ?? {});
  const {userData} = useSelector((state) => state.generalReducer);

  useEffect(() => {
    return () => {
      dispatch({type: RATE_TRADE_DEFAULT, payload: ""});
    };
  }, []);

  return (
    <>
      <div className="__review__wallet">
        <div className="__review__lhs">
          <p className="__goto__wallet">Go to wallet to view your balance</p>
          <button className="__view__wallet" onClick={() => push("/app/wallet")}>
            View Wallet
          </button>
        </div>

        {(statusResponse?.status === "completed" || statusResponse?.status === "cancelled") &&
          ((statusResponse?.status === "cancelled" && reviewError === "not reviewed") ||
            (statusResponse?.status === "completed" && reviewError === "not reviewed") ||
            (userType === "user" && tradeResponse?.type === "buy" && reviewError === "not reviewed") ||
            (userType === "user" && tradeResponse?.type === "sell" && reviewError === "not reviewed") ||
            (userType === "trader" && tradeResponse?.type === "buy" && reviewError === "not reviewed") ||
            (userType === "trader" && tradeResponse?.type === "sell" && reviewError === "not reviewed")) && (
            <div className="__review__rhs">
              <div className="__review__r">
                <p className="__experience">So tell us how was your trading experience</p>
                <span className={`__reviewRes ${response && "__cor"}`}>{response && "Review posted successfully"}</span>
                <span className={`__reviewRes ${error && "__err"}`}>{error && "Error posting Review"}</span>
                <div className="__review__wrapper">
                  <button className={`__review ${review === "pos" && "__pos"}`} onClick={() => setReview("pos")}>
                    Positive <BiLike size={18} style={{marginLeft: "10px"}} />
                  </button>
                  <button className={`__review ${review === "neg" && "__neg"}`} onClick={() => setReview("neg")}>
                    Negative <BiDislike size={19} style={{marginLeft: "10px"}} />
                  </button>
                </div>
              </div>
              <ReviewComments review={review} setReview={setReview} />
            </div>
          )}

        {(statusResponse?.status === "completed" || statusResponse?.status === "cancelled") &&
          reviewResponse?.length > 0 &&
          reviewRes?.message === "reviewed" && (
            <>
              {reviewResponse?.map((item, key) => (
                <>
                  {item?.reviewerID === userData?.data?.userData?._id && (
                    <div key={key} className="__review__rhs">
                      <div className="__review__r">
                        <div className="__review__wrapper">
                          {item?.isTrust ? (
                            <button className={`__review __pos`} style={{cursor: "unset"}}>
                              Positive <BiLike size={18} style={{marginLeft: "10px"}} />
                            </button>
                          ) : (
                            <button className={`__review __neg`} style={{cursor: "unset"}}>
                              Negative <BiDislike size={19} style={{marginLeft: "10px"}} />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))}
            </>
          )}
      </div>
    </>
  );
};

export default memo(ReviewAndWallet);
