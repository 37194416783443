export const trimId = (str) => {
  const strSplit = str?.split("");
  const strSplitLength = strSplit?.length - 1;
  return `${strSplit[0]}${strSplit[1]}${strSplit[2]}${strSplit[3]}***${strSplit[strSplitLength - 2]}${
    strSplit[strSplitLength - 1]
  }${strSplit[strSplitLength]}`;
};

export const textSlicer = (data) => {
  const newText = data ? `${data.slice(0, 10)}...` : "";
  return newText;
};
export const textSlicer20 = (data, amount) => {
  const newText = data ? `${data.slice(0, amount)}...` : "";
  return newText;
};
