import React, {memo} from "react";
import buyIcon from "../../../assets/icons/buy-button-icon.svg";
import sellIcon from "../../../assets/icons/sell-button-icon.svg";
import {Link} from "react-router-dom";
import "./trade-drop-down.scss";
import {useSelector} from "react-redux";

const TradeDropDown = React.forwardRef(({setShowTrade}, ref) => {
  const {getSelecteGeoLocationData} = useSelector((state) => state.LandingReducer);

  const data = [
    {
      icon: <img src={buyIcon} alt="buy crypto" width={23} height={"auto"} />,
      title: "Buy Crypto",
      tag: "See the price of the crypto",
      path: "buy",
    },
    {
      icon: <img src={sellIcon} alt="sell crypto" width={23} height={"auto"} />,
      title: "Sell Crypto",
      tag: "See the price of the crypto",
      path: "sell",
    },
  ];

  return (
    <div className="navDropContainer" style={{width: "17%", left: "60%", padding: 0, height: "auto"}} ref={ref}>
      {data.map((list, index) => (
        <ul key={index} className="listMenuIcon">
          <Link
            to={`/marketplace/${getSelecteGeoLocationData?.countryName}/BTC/${list.path}/${null}`}
            onClick={() => setShowTrade(false)}
          >
            <div style={{display: "flex", gap: "1.2rem", alignItems: "center"}}>
              {list.icon}
              {list.title}
            </div>
            <p className="tag">{list.tag}</p>
          </Link>
        </ul>
      ))}
    </div>
  );
});

export default memo(TradeDropDown);
