import createTradeService from "../../services/createTrade";
import {GET_TRADE_STATUS_SUCCESS} from "../types";

export const getTradeStatusByIDAction = (args) => (dispatch) => {
  return createTradeService.getTradeStatusByID(args).then((data) => {
    dispatch({type: GET_TRADE_STATUS_SUCCESS, payload: data});
  });
};

export const getTradeStatusByIDActionAdvanced = (args) => (dispatch) => {
  return createTradeService.getTradeStatusByIDAdvanced(args).then((data) => {
    dispatch({type: GET_TRADE_STATUS_SUCCESS, payload: data});
  });
};
