import {useEffect, useState} from "react";
import {useMemo} from "react";
import {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {ActionModalContext} from "..";
import {Avatar, NOTFOUND} from "../../../../hooks/imagePath";
import MarketService from "../../../../services/market";
import {
  getMarketPlaceBuyAndSellAction,
  marketPlaceOnNextAction,
  marketPlaceOnPrevAction,
} from "../../../../store/actions/market";
import {
  CHANGE_MARKET_PLACE_STATE,
  CHECK_FOR_EXTRA_DATA,
  CURRENT_TOTAL_VALUE,
  GET_MARKET_PLACE_BUY_SELL,
  GET_MARKET_PLACE_INITIAL,
  GET_MARKET_PLACE_INITIAL_STATE,
  MARKET_SKIP_SYSTEM,
  PAGE_EMPTY_CHECKER,
} from "../../../../store/types";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../utils/getTimeDiff";
import {textSlicer20} from "../../../../utils/trimID";
import {Loader} from "../../../Common/Loader";
import {TablePaginationComponent} from "../../../Common/pagination";
import BalanceService from "../../../../services/balance";

const DeskTopTable = ({data, loading}) => {
  const {countryData} = useSelector((state) => state.generalReducer);

  const userData = useSelector((state) => state.generalReducer.userData);
  useMemo(() => data, [data]);
  const params = useParams();

  const toke = useMemo(() => params?.token, [params?.token]);

  const dispatch = useDispatch();
  // const {marketPlaceData} = useContext(MarketPlaceContext);
  const {setModalAction, setModalID} = useContext(ActionModalContext);
  const {limit, skip, fskip} = useSelector((state) => state.marketReducer);
  const marketPlaceData = useSelector((state) => state.marketStateReducer);
  // const checkForEmpty = useSelector((state) => state.marketStateReducer);

  // useEffect(() => {
  //   dispatch({type: GET_MARKET_PLACE_INITIAL, payload: true});
  //   if (Object.keys(countryData).length > 0) {
  //     dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});
  //     dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: skip}));
  //   }
  // }, [marketPlaceData?.country, marketPlaceData?.paymentMethod, marketPlaceData?.token, countryData]);

  const onNext = () => {
    const skipDetails = (skip + 1) * limit;

    if (marketPlaceData?.checkForEmpty?.length !== 0) {
      MarketService.getMarketPlaceBuyAndSell({...marketPlaceData, skip: skipDetails, limit: limit}).then((data) => {
        dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});
        if (data.message === "success") {
          if (data?.marketTrades?.length > 0) {
            dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: data?.marketTrades});
            dispatch({type: MARKET_SKIP_SYSTEM, payload: skip + 1});

            dispatch({type: PAGE_EMPTY_CHECKER, payload: data.marketTrades});

            dispatch({type: GET_MARKET_PLACE_INITIAL_STATE, payload: false});

            if (data.marketTrades.length === limit) {
              dispatch({type: CHECK_FOR_EXTRA_DATA, payload: true});

              const futureSkipDetails = skipDetails + limit;
              MarketService.getMarketPlaceBuyAndSellFuture({
                ...marketPlaceData,
                skip: futureSkipDetails,
                limit: limit,
              }).then((data) => {
                if (data.marketTrades.length === 0) {
                  dispatch({type: PAGE_EMPTY_CHECKER, payload: []});
                  dispatch({type: CHECK_FOR_EXTRA_DATA, payload: false});
                } else {
                  dispatch({type: CHECK_FOR_EXTRA_DATA, payload: false});
                }
              });
            }
          } else {
            dispatch({type: PAGE_EMPTY_CHECKER, payload: []});
            dispatch({type: GET_MARKET_PLACE_INITIAL_STATE, payload: false});
          }
        }
      });
    }
  };

  const onPrev = () => {
    dispatch({type: PAGE_EMPTY_CHECKER, payload: null});

    const skipDetails = (skip - 1) * limit;

    if (data?.length > 0 && skip + 1 > 1) {
      MarketService.getMarketPlaceBuyAndSell({...marketPlaceData, skip: skipDetails, limit: limit}).then((data) => {
        if (data.message === "success") {
          dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: data.marketTrades});
          dispatch({type: MARKET_SKIP_SYSTEM, payload: skip - 1});
          dispatch({type: GET_MARKET_PLACE_INITIAL_STATE, payload: false});
        }
      });
    }
  };

  return (
    <div className="table__wrapper">
      {loading ? (
        <Loader />
      ) : (
        <table className="tablew">
          <thead className="newTable__thead">
            <tr className="newTable__tr">
              <th className="newTable__th">Seller</th>
              {/* <th className="newTable__th">Coin</th> */}
              <th className="newTable__th">Ratings</th>
              <th className="newTable__th">Limit Order</th>
              <th className="newTable__th">Unit Price</th>
              <th className="newTable__thh __min__100">Country</th>
              <th className="newTable__th">PaymentMethod</th>
              <th className="newTable__th"></th>
            </tr>
          </thead>

          <tbody className="newTable__tbody">
            {data &&
              data.length > 0 &&
              data.map((item, i) => {
                return (
                  <tr className="myTable" style={{height: "40px"}} key={i}>
                    <td className="tableItem">
                      <div className="__seller__wrapper">
                        <a
                          href={`/app/profile/${item?.username}`}
                          rel="noreferrer"
                          target="_blank"
                          className="market_link"
                          style={{cursor: "pointer"}}
                        >
                          <div style={{backgroundImage: `url(${Avatar})`}} className="__seller__image" alt="the_avatar">
                            <div
                              className={`__seller__notifier ${
                                getTimeDifference(item?.tradeData?.timeLastSeen) < 5
                                  ? "online"
                                  : getTimeDifference(item?.tradeData?.timeLastSeen) > 5 &&
                                    getTimeDifference(item?.tradeData?.timeLastSeen) < 30
                                  ? "away"
                                  : "offline"
                              }`}
                            ></div>
                          </div>
                        </a>

                        <div className="__seller__details">
                          <a
                            href={`/app/profile/${item?.username}`}
                            rel="noreferrer"
                            target="_blank"
                            className="username_link"
                            style={{cursor: "pointer"}}
                          >
                            <div className="__sellerName" style={{textTransform: "capitalize"}}>
                              {item?.username}
                            </div>
                          </a>

                          <div className="__sellerDetails">
                            {item?.tradeData?.totalNumberOfTrades} Orders,{" "}
                            {item?.tradeData?.totalNumberOfTrades === 0 ? (
                              0
                            ) : (
                              <CurrencyFormat
                                value={
                                  (item?.tradeData?.numberCompletedTrades / item?.tradeData?.totalNumberOfTrades) * 100
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                decimalScale={2}
                                fixedDecimalScale
                                // prefix={"$"}
                                // suffix={` ${tradeResponse?.tokenSymbol}`}
                              />
                            )}
                            %
                          </div>
                        </div>
                      </div>
                    </td>
                    {/* <td className="tableItem">
                      <div className="__market__coin">
                        <div
                          className="__tokenSymbolImage"
                          style={{
                            backgroundImage: `url(${
                              item?.tokenSymbol === "BTC"
                                ? BTC
                                : item?.tokenSymbol === "USDT"
                                ? USDT
                                : item?.tokenSymbol === "ETH"
                                ? ETH
                                : BNB
                            })`,
                          }}
                        />
                        <div className="">
                          <p className="__tokenSymbol">{item.tokenSymbol}</p>
                          <p className="__tokenSymbolName">
                            {item?.tokenSymbol === "BTC"
                              ? "Bitcoin"
                              : item?.tokenSymbol === "USDT"
                              ? "Tron"
                              : item?.tokenSymbol === "ETH"
                              ? "Ethereum"
                              : "Binance"}
                          </p>
                        </div>
                      </div>
                    </td> */}
                    <td className="tableItem">
                      <div className="__market__ratings">
                        <div className="__ratings__like">
                          <span className="__rating">{item?.tradeData?.trusts}</span>
                          <AiOutlineLike size={14} className="__icon" />
                        </div>
                        <div className="__ratings__dislike">
                          <span className="__rating">{item?.tradeData?.distrusts}</span>
                          <AiOutlineDislike size={14} className="__icon" />
                        </div>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__limit">
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.minAmount) ?? 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            // prefix={"$"}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                        {" - "}
                        <span>
                          <CurrencyFormat
                            value={Math.abs(item?.maxAmount) ?? 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={2}
                            fixedDecimalScale
                            style={{color: "#000", fontWeight: "500"}}
                            // prefix={"$"}
                            suffix={` ${item?.currency}`}
                          />
                        </span>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__unitprice">
                        <CurrencyFormat
                          value={Math.abs(item.unitPrice) ?? 0}
                          displayType={"text"}
                          thousandSeparator={true}
                          decimalScale={2}
                          fixedDecimalScale
                          className="__theUnitPrice"
                          // prefix={"$"}
                          suffix={` ${item?.currency}`}
                        />
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__paymentMethod">{item?.country}</div>
                    </td>

                    <td className="tableItem">
                      <div className="__market__paymentMethod">{item?.paymentMethod}</div>
                      <span style={{fontSize: "12px", color: "#a09e9eb7", paddingLeft: "10px"}}>
                        {textSlicer20(item?.tagLine, 20)}
                      </span>
                    </td>

                    <td className="tableItem">
                      <div className="transactionTableArea__viewMoreWrappers">
                        <span
                          className="transactionTableArea__viewMore"
                          onClick={() => {
                            setModalAction(true);

                            if (item?.traderID === userData?.data?.userData?._id) {
                              setModalID({
                                notAllow: true,
                              });

                              return;
                            }

                            setModalID({
                              id: item?._id,
                              traderID: item.traderID,
                            });

                            const presentToken = userData?.data?.userData?.tokens?.find(
                              (data) =>
                                data?.tokenSymbol?.toLowerCase() ===
                                `${capitalizeFirstLetter(item?.tokenSymbol)}`.toLowerCase()
                            );

                            dispatch({type: CURRENT_TOTAL_VALUE, payload: presentToken?.balance});
                          }}
                        >
                          {capitalizeFirstLetter(marketPlaceData.type)} {capitalizeFirstLetter(item?.tokenSymbol)}
                        </span>
                      </div>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      )}

      {!loading && data && data.length === 0 && (
        <div className="__notFound__wrapperr">
          <div className="__theNotFound">
            <img src={NOTFOUND} alt="not found" />
            <span>No Data</span>
          </div>
        </div>
      )}

      {data && data?.length !== 0 && data !== null && data !== undefined && (
        <TablePaginationComponent
          onNext={onNext}
          onPrev={onPrev}
          skip={skip}
          lengthChecker={marketPlaceData?.checkForEmpty}
          dataCheck={data}
          limit={limit}
          loading={marketPlaceData?.checkForExtraData}
        />
      )}
    </div>
  );
};

export {DeskTopTable};
