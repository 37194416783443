import {Link} from "react-router-dom";
import "../../styles/landing-page-styles/button.scss";
const ReactLinkButton = ({title, onClick, style, link}) => {
  return (
    <Link to={link} className="baseButton primaryButton" onClick={onClick} style={style}>
      {title}
    </Link>
  );
};

export default ReactLinkButton;
