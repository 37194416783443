import TransactionService from "../../services/transactionHistory";
import {
  FILTER_BUY_SELL_BY_COIN,
  FILTER_BUY_SELL_BY_DATE,
  FILTER_BUY_SELL_BY_MULTIPLE,
  FILTER_BUY_SELL_BY_STATUS,
  FILTER_BUY_SELL_BY_TYPE,
  GET_BUY_SELL_NEXT,
  GET_BUY_SELL_PREV,
  GET_BUY_SELL_REFRESH,
  GET_BUY_SELL_TRANSACTION_SUCCESS,
} from "../types";

export const getBuySellTransactionAction = (args) => (dispatch) => {
  return TransactionService.getBuySell(args).then((data) => {
    if (data.message === "success") {
      dispatch({type: GET_BUY_SELL_TRANSACTION_SUCCESS, payload: data.trades});
    }
  });
};

export const getBuySellPendingTransactionAction = (args) => (dispatch) => {
  return TransactionService.getBuySell(args).then((data) => {
    if (data.message === "success") {
      dispatch({
        type: GET_BUY_SELL_TRANSACTION_SUCCESS,
        payload: data.trades?.filter((item) => item.status === "pending"),
      });
    }
  });
};
export const getBuySellDisputeTransactionAction = (args) => (dispatch) => {
  return TransactionService.getBuySell(args).then((data) => {
    if (data.message === "success") {
      dispatch({
        type: GET_BUY_SELL_TRANSACTION_SUCCESS,
        payload: data.trades?.filter((item) => item.status === "disputed"),
      });
    }
  });
};
export const getBuySellOnNextAction = (args) => (dispatch) => {
  dispatch({type: GET_BUY_SELL_NEXT, payload: true});
};

export const filterBuySellByCoinAction = (args) => (dispatch) => {
  dispatch({type: FILTER_BUY_SELL_BY_COIN, payload: args});
};
export const filterBuySellByTypeAction = (args) => (dispatch) => {
  dispatch({type: FILTER_BUY_SELL_BY_TYPE, payload: args});
};
export const filterBuySellByStatusAction = (args) => (dispatch) => {
  dispatch({type: FILTER_BUY_SELL_BY_STATUS, payload: args});
};
export const filterBuySellByDateAction = (args) => (dispatch) => {
  dispatch({type: FILTER_BUY_SELL_BY_DATE, payload: args});
};
export const filterBuySellByMultipleAction = (args) => (dispatch) => {
  dispatch({type: FILTER_BUY_SELL_BY_MULTIPLE, payload: args});
};
export const getBuySellOnPrevAction = () => (dispatch) => {
  dispatch({type: GET_BUY_SELL_PREV, payload: true});
};
export const refreshBuySellAction = () => (dispatch) => {
  dispatch({type: GET_BUY_SELL_REFRESH, payload: true});
};
