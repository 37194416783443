import React, {memo, useState} from "react";
import {HiOutlineMinus, HiOutlinePlus} from "react-icons/hi";
import CurrencyFormat from "react-currency-format";
import "../../styles/editTradeStyles/editTradeThirdSection.scss";
import {useDispatch, useSelector} from "react-redux";
import {
  EDITTRADEDISABLE,
  EDIT_POST_TRADE_BUY_ERROR,
  EDIT_POST_TRADE_SELL_ERROR,
  PROFITMARGIN,
  SKELETON_LOADER,
} from "../../store/types";
import {useCallback} from "react";
import EditPostTradeService from "../../services/editPostTradeService";
import {getSingleUserCurrencyDetails} from "../../store/actions/editPostTradeAction";
import {Skeleton} from "@mui/material";

const EditTradeThirdSection = () => {
  const dispatch = useDispatch();
  const singleUserOrder = useSelector((state) => state.editTradeReducer.userOrders);
  const allCurrencyDetails = useSelector((state) => state.editTradeReducer.tokenLocalCurrencyDatas);
  const showSkeleton = useSelector((state) => state.postTradeReducer.skeletonLoader);
  const detailedForm = useSelector((state) => state.editTradeReducer.editPostTradeDetailedForm);

  const [margin, setMargin] = useState(singleUserOrder.profitMargin);

  const handleChangeMargin = (e) => {
    dispatch({type: EDITTRADEDISABLE, payload: false});

    if (!e.target.value) {
      setMargin(parseInt(0));
      dispatch({type: PROFITMARGIN, payload: 0});
      return;
    }

    if (e.target.value <= -5) {
      setMargin(parseInt(-5));
      dispatch({type: PROFITMARGIN, payload: parseInt(-5)});
      return;
    }

    setMargin(parseInt(e.target.value));
    dispatch({type: PROFITMARGIN, payload: parseInt(e.target.value)});
  };

  const handleBlurMargin = (e) => {
    getLocalCurrency(parseInt(e.target.value));
  };

  const incrementMagin = () => {
    dispatch({type: EDITTRADEDISABLE, payload: false});

    setMargin((prev) => prev + 1);
    dispatch({type: PROFITMARGIN, payload: margin + 1});
    getLocalCurrency(margin + 1);
  };

  const decrementMargin = () => {
    dispatch({type: EDITTRADEDISABLE, payload: false});

    setMargin((prev) => (prev <= -5 ? prev : prev - 1));
    if (margin <= -5) {
      dispatch({type: PROFITMARGIN, payload: -5});
      getLocalCurrency(-5);
    } else {
      dispatch({type: PROFITMARGIN, payload: margin - 1});
      getLocalCurrency(margin - 1);
    }
  };

  const getLocalCurrency = (marginItem) => {
    dispatch({type: SKELETON_LOADER, payload: true});
    if (
      singleUserOrder?.tokenSymbol !== "" &&
      singleUserOrder?.currency !== "" &&
      singleUserOrder?.currency !== undefined &&
      typeof marginItem === "number" &&
      !isNaN(marginItem)
    ) {
      EditPostTradeService.SinglePostTradeMargin({
        tokenSymbol: singleUserOrder?.tokenSymbol,
        currency: singleUserOrder?.currency,
        margin: marginItem,
      }).then((data) => {
        dispatch(getSingleUserCurrencyDetails(data));

        if (Object.keys(singleUserOrder).length > 0) {
          let minAmounts;

          if (typeof detailedForm?.minValue === "string") {
            const newMinValue = detailedForm?.minValue?.match(/\d/g);
            minAmounts = newMinValue?.join("");
          } else {
            minAmounts = detailedForm?.minValue;
          }

          let maxAmounts;

          if (typeof detailedForm?.maxValue === "string") {
            const newMaxValue = detailedForm?.maxValue?.match(/\d/g);
            maxAmounts = newMaxValue?.join("");
          } else {
            maxAmounts = detailedForm?.maxValue;
          }

          if (singleUserOrder?.type === "buy") {
            if (
              Number(
                typeof maxAmounts !== "undefined" || maxAmounts !== undefined || maxAmounts !== null
                  ? maxAmounts
                  : singleUserOrder?.maxAmount
              ) <
              Number(
                typeof minAmounts !== "undefined" || maxAmounts !== undefined || maxAmounts !== null
                  ? minAmounts
                  : singleUserOrder?.maxAmount
              )
            ) {
              dispatch({
                type: EDIT_POST_TRADE_BUY_ERROR,
                payload: "maximum limit must be greater than minimum limit",
              });
            } else {
              dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
            }
          }

          if (singleUserOrder?.type === "sell") {
            if (
              allCurrencyDetails.marginCoinBalance <
              Number(
                typeof maxAmounts !== "undefined" || maxAmounts !== undefined || maxAmounts !== null
                  ? maxAmounts
                  : singleUserOrder?.maxAmount
              )
            ) {
              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: "Insufficient fund"});
            } else {
              dispatch({type: EDIT_POST_TRADE_SELL_ERROR, payload: ""});
            }

            if (
              Number(maxAmounts !== undefined || maxAmounts !== null ? maxAmounts : singleUserOrder?.maxAmount) <
              Number(minAmounts !== undefined || minAmounts !== null ? minAmounts : singleUserOrder?.minAmount)
            ) {
              dispatch({
                type: EDIT_POST_TRADE_BUY_ERROR,
                payload: "maximum limit must be greater than minimum limit",
              });
            } else {
              dispatch({type: EDIT_POST_TRADE_BUY_ERROR, payload: ""});
            }
          }
        }
      });
    }
  };

  return (
    <div className="postTrade__thirdLayout">
      <div className="postTrade__thirdLayoutOne">
        <div className="postTrade__thirdLayoutOneCurrency">
          <div className="postTrade__thirdLayoutOneCurrencyCountry">
            <p>Currency</p>
            <input type="text" disabled value={singleUserOrder?.currency} readOnly={true} />
          </div>
          <div className="postTrade__thirdLayoutOneCurrencyMargin">
            <div className="postTrade__thirdLayoutOneCurrencyMarginLabel">
              <p>Margin</p>
            </div>
            <div className="postTrade__thirdLayoutOneCurrencyMarginInput">
              <label>Trade Margin Percentage</label>
              <div className="postTrade__thirdLayoutOneCurrencyMarginInputField">
                <div className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInput">
                  <div
                    className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIconBody"
                    onClick={decrementMargin}
                  >
                    <HiOutlineMinus className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIcon" />
                  </div>
                  <CurrencyFormat
                    name={"margin"}
                    value={margin || 0}
                    onBlur={handleBlurMargin}
                    onChange={handleChangeMargin}
                    autocomplete="off"
                  />
                  <div
                    className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIconBody"
                    onClick={incrementMagin}
                  >
                    <HiOutlinePlus className="postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIcon" />
                  </div>
                </div>
                <div className="postTrade__thirdLayoutOneCurrencyMarginInputFieldText">
                  <p>%</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="postTrade__thirdLayoutTwo">
        <div className="postTrade__thirdLayoutTwoFirstLayout">
          <div className="postTrade__thirdLayoutTwoFirstLayoutPrice">
            <p>
              Current <span>{`${singleUserOrder.tokenSymbol}`}</span> Market Price
            </p>
          </div>
          <div className="postTrade__thirdLayoutTwoFirstLayoutPriceAmount">
            <p>
              {showSkeleton ? (
                <div>
                  <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                </div>
              ) : (
                <CurrencyFormat
                  value={`${allCurrencyDetails ? allCurrencyDetails.currency : 0}`}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${singleUserOrder?.currency}`}
                />
              )}
            </p>
          </div>
          <div className="postTrade__thirdLayoutTwoFirstLayoutPriceDetails">
            <p>Trade Margin Percentage</p>
          </div>
        </div>

        <div className="postTrade__thirdLayoutTwoSecondLayout">
          <div className="postTrade__thirdLayoutTwoSecondLayoutPrice">
            <p>
              {showSkeleton ? (
                <div>
                  <Skeleton animation="wave" variant="rectangular" width={100} sx={{borderRadius: "8px"}} />
                </div>
              ) : (
                <CurrencyFormat
                  value={`${allCurrencyDetails ? allCurrencyDetails.margin : 0}`}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${singleUserOrder?.currency}`}
                />
              )}
            </p>
          </div>
          <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetails">
            <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMargin">
              <p>Profit Margin</p>
            </div>
            <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmount">
              <div className="postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmountBody">
                <p style={{color: `${allCurrencyDetails.sign <= 0 ? "#fda29b" : ""}`}}>
                  {allCurrencyDetails.sign > 0 && "+"}
                  <CurrencyFormat
                    value={`${allCurrencyDetails ? allCurrencyDetails.difference : 0}`}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    suffix={` ${singleUserOrder?.currency}`}
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EditTradeThirdSection);
