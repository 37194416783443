import React, {memo} from "react";
import {useSelector} from "react-redux";
import CustomCheckbox from "../Common/CustomCheckbox";

const SellDresser = () => {
  const singleUserOrder = useSelector((state) => state.editTradeReducer.userOrders);

  return (
    <div>
      <CustomCheckbox edited={true} state={singleUserOrder.type === "sell" ? {checker: true} : {checker: false}} />
    </div>
  );
};

export default memo(SellDresser);
