import {Skeleton} from "@mui/material";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {useMemo} from "react";
import CurrencyFormat from "react-currency-format";
import {IoWalletOutline} from "react-icons/io5";
import {useDispatch, useSelector} from "react-redux";
import BalanceService from "../../services/balance";
import {getAllTokenBalance} from "../../store/actions/balance";
import ArrowDown from "../../assets/icons/arrow-drop-down.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import {formatBigNumber} from "../../utils/bigNumberFormatter";

const Balance = () => {
  const [open, setOpen] = useState(false);
  const theref = useRef();
  const [showSelectCondition, setShowSelectCondition] = useState(false);
  const dispatch = useDispatch();
  useOnClickOutside(theref, () => setOpen(!open));

  const handleClick = () => {
    if (showSelectCondition === false) {
      setOpen(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpen(false);
      setShowSelectCondition(false);
    }
  };

  const balanceData = useSelector((state) => state.balanceReducer.singleBalance);
  const balanceUserData = useSelector((state) => state.generalReducer.userData);

  useMemo(() => balanceUserData, [balanceUserData]);

  const fetchProfileData = useCallback(() => {
    BalanceService.localBalance()
      .then((data) => {
        dispatch(getAllTokenBalance(data));
      })
      .catch(() => {
        // toast("Something went wrong");
      });
  }, [dispatch]);

  useEffect(() => {
    fetchProfileData();
  }, [dispatch, fetchProfileData]);

  useEffect(() => {
    let interval = setInterval(() => {
      fetchProfileData();
    }, 30000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  menuItemData?.forEach((item) => {
    if (Object.keys(balanceUserData).length > 0) {
      item["defaultCurrency"] = balanceUserData?.data?.userData?.defaultCurrency?.currency;
      balanceUserData.data.userData.tokens.forEach((jerry) => {
        if (jerry?.tokenSymbol?.toLowerCase() === item?.coinName?.toLowerCase()) {
          item["tokenBalance"] = formatBigNumber(jerry?.balance);
        }
      });
    }

    if (balanceData !== 0) {
      for (let x in balanceData?.tokensValueData[0]) {
        if (item.coinName?.toLowerCase() === x.slice(0, -5)?.toLowerCase()) {
          item["localBalance"] = balanceData?.tokensValueData[0][x];
        }
      }
    }
  });

  return !balanceData.hasOwnProperty("totalValue") ? (
    <div className="__balance__crptoMennuWrapper">
      <div className="__balance__cryptoWrapper">
        <Skeleton
          animation="wave"
          variant="rectangular"
          style={{backgroundColor: "white", width: "200px", height: "40px", borderRadius: "15px"}}
        />
      </div>
    </div>
  ) : (
    <React.Fragment>
      <div className="__balance__crptoMennuWrapper">
        <p className="__balance__cryptoWrapper" onClick={() => handleClick()}>
          {/* {!balanceData.hasOwnProperty("totalValue") ? (
            <Skeleton
              animation="wave"
              variant="rectangular"
              width={200}
              height={20}
              style={{backgroundColor: "white", height: "40"}}
            />
          ) : ( */}
          <>
            <IoWalletOutline style={{marginRight: "7px"}} />
            <CurrencyFormat
              displayType="text"
              style={{border: "none", background: "transparent"}}
              value={balanceData.hasOwnProperty("totalValue") ? balanceData.totalValue : 0}
              thousandSeparator={true}
              decimalScale={2}
              suffix={balanceData.hasOwnProperty("currency") ? ` ${balanceData.currency}` : "USD"}
            />{" "}
            <img src={ArrowDown} alt="trailing-icon" style={{marginLeft: "10px"}} />
          </>
          {/* )} */}
        </p>
        {open && (
          <div className="__balance__crypto__dropdown" data-aos="zoom-in" style={{...menuStyle}} ref={theref}>
            {menuItemData.map((item, index) => {
              return (
                <Link to="/app/wallet" onClick={() => setOpen(false)} key={index}>
                  <div className="__the__buycrypto__link">
                    <img src={require(`../../assets/icons/${item?.image}.svg`)} alt={item?.image} style={imageStyle} />
                    <div style={{display: "flex", justifyContent: "space-between", width: "100%"}}>
                      <div>
                        <p style={{fontSize: "12px", width: "100%", color: "#344054", fontWeight: 500}}>
                          {item?.coinName}
                        </p>
                        <p style={{fontSize: "12px", width: "100%", color: "#344054", fontWeight: 300}}>
                          {item.coinFullName}
                        </p>
                      </div>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                        <p style={{fontSize: "12px", color: "#344054", fontWeight: 500}}>
                          <CurrencyFormat
                            displayType="text"
                            style={{border: "none", background: "transparent"}}
                            value={item?.hasOwnProperty("tokenBalance") ? item?.tokenBalance : 0}
                            thousandSeparator={true}
                            decimalScale={2}
                            suffix={` ${item?.coinName || "BTC"}`}
                          />
                        </p>
                        <p style={{fontSize: "11px", color: "#344054", fontWeight: 400}}>
                          <CurrencyFormat
                            displayType="text"
                            style={{border: "none", background: "transparent"}}
                            value={item?.localBalance ? item?.localBalance : 0}
                            thousandSeparator={true}
                            decimalScale={2}
                            suffix={` ${item?.defaultCurrency || "USD"}`}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

const menuStyle = {
  width: "300px",
  borderBottom: "1px solid #F2F4F7",
  marginTop: "10px",
  boxShadow: 0,
  elevation: 0,
};

const imageStyle = {
  width: "35px",
  height: "35px",
  marginRight: "10px",
};

const menuItemData = [
  {
    id: 0,
    coinName: "BNB",
    coinFullName: "Binance Coin",
    image: "bnb",
    localcrypto: "0.30021281",
    localMoney: "bnbValue",
  },
  {
    id: 1,
    coinName: "BTC",
    coinFullName: "Bitcoin",
    image: "btc",
    localcrypto: "0.30021281",
    localMoney: "btcValue",
  },
  {
    id: 2,
    coinName: "ETH",
    coinFullName: "Etherium",
    image: "eth",
    localcrypto: "0.30021281",
    localMoney: "ethValue",
  },
  {
    id: 3,
    coinName: "USDT",
    coinFullName: "Tether",
    image: "usdt",
    localcrypto: "0.30021281",
    localMoney: "usdtValue",
  },
];

export default memo(Balance);
