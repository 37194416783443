import {
  CONFIRM_PAYMENT,
  CONFIRM_PAYMENT_ERROR,
  CONFIRM_PAYMENT_INITIAL,
  DISPUTE_TRADE,
  DISPUTE_TRADE_ERROR,
  DISPUTE_TRADE_INITIAL,
} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const disputeTradeReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case DISPUTE_TRADE: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case DISPUTE_TRADE_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case DISPUTE_TRADE_ERROR: {
      return {
        ...state,
        error: payload,
        loading: false,
      };
    }
    default: {
      return state;
    }
  }
};

export default disputeTradeReducer;
