import React from "react";
import {memo} from "react";
import {Loader} from "../Common/Loader";
import {useSelector} from "react-redux";
import SectionModalCentered from "../Common/sectionModalCentered";
import ConvertOTP from "./convertOtp";
import ConvertConfirmation from "./convertConfirmationModal";
import ConvertVerificationPage from "./ConvertNotVerifiedPage";

const ConvertModalSection = () => {
  const modalChecker = useSelector((state) => state.convertReducer.convertModalChecker);

  switch (modalChecker.num) {
    case 0:
      return (
        <SectionModalCentered open={modalChecker.num === 0 ? true : false}>
          <Loader />
        </SectionModalCentered>
      );

    case 1:
      return (
        <SectionModalCentered open={modalChecker.num === 1 ? true : false}>
          <ConvertOTP />
        </SectionModalCentered>
      );
    case 2:
      return (
        <SectionModalCentered open={modalChecker.num === 2 ? true : false}>
          <ConvertConfirmation />
        </SectionModalCentered>
      );
    case 3:
      return (
        <SectionModalCentered open={modalChecker.num === 3 ? true : false}>
          <ConvertVerificationPage />
        </SectionModalCentered>
      );

    default:
      return "";
  }
};

export default memo(ConvertModalSection);
