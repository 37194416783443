import React, {Component} from "react";
import {CKEditor} from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

// import Context from "@ckeditor/ckeditor5-core/src/context";
// // import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
// import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
// import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
// import Essentials from "@ckeditor/ckeditor5-essentials/src/essentials";
// import Paragraph from "@ckeditor/ckeditor5-paragraph/src/paragraph";

// import Image from "@ckeditor/ckeditor5-image/src/image";
// import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
// import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
// import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
// import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
// import LinkImage from "@ckeditor/ckeditor5-link/src/linkimage";

// import Image from "@ckeditor/ckeditor5-image/src/image";
// import ImageResizeEditing from "@ckeditor/ckeditor5-image/src/imageresize/imageresizeediting";
// import ImageResizeHandles from "@ckeditor/ckeditor5-image/src/imageresize/imageresizehandles";

const Editor = () => {
  function getbase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(file);
    });
  }

  const uploadAdapter = (loader) => {
    return {
      upload: () => {
        return new Promise((resolve, reject) => {
          const body = new FormData();
          loader.file.then((file) => {
            body.append("uploadImg", file);

            let promise = getbase64(file);

            promise.then((data) => {
              return data;
              //   DashBoardServices.uploadImage({file: data})
              //     .then((data) => {
              //       setImageState((imageState) => [
              //         ...imageState,
              //         {
              //           url: data?.data?.secure_url,
              //           cloudinary_id: data?.data?.public_id,
              //         },
              //       ]);
              //       return data?.data?.secure_url;
              //     })
              //     .then((res) => {
              //       dispatch(getDocsLoader(false));
              //       resolve({default: res});
              //     })
              //     .catch((err) => {
              //       reject(err);
              //     });
            });
          });
        });
      },
    };
  };

  function uploadPlugin(editor) {
    editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
      return uploadAdapter(loader);
    };
  }

  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        config={{
          extraPlugins: [uploadPlugin],
          //   plugins: [Paragraph, Bold, Italic, Essentials],
          //   plugins: [Image, ImageToolbar, ImageCaption, ImageStyle, ImageResize, LinkImage],
          //   plugins: [Image, ImageResizeEditing, ImageResizeHandles],
          //   toolbar: ["bold", "italic"],

          //   image: {
          //     toolbar: [
          //       "imageStyle:block",
          //       "imageStyle:side",
          //       "|",
          //       "toggleImageCaption",
          //       "imageTextAlternative",
          //       "|",
          //       "linkImage",
          //     ],
          //   },
        }}
        // data="<p>Enter a description..</p>"
        onReady={(editor) => {
          // You can store the "editor" and use when it is needed.
          if (editor.model.schema.isRegistered("image")) {
            editor.model.schema.extend("image", {
              allowAttributes: "blockIndent",
            });
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
        }}
        onBlur={(event, editor) => {}}
        onFocus={(event, editor) => {}}
      />
    </div>
  );
};

export default Editor;
