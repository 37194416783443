import {useEffect, useState} from "react";
import CurrencyFormat from "react-currency-format";
import {BiDislike, BiLike} from "react-icons/bi";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {Checked} from "../../../hooks/imagePath";
import {checkTradeReviewAction} from "../../../store/actions/createTradeAction";
import {RATE_TRADE_DEFAULT} from "../../../store/types";
import "../../../styles/createTrade/successModal.scss";
import ReviewComments from "../detailArea/reviewComment";

const SuccessModal = ({setOpenModal, nextStep}) => {
  const history = useHistory();
  const {id} = useParams();
  const dispatch = useDispatch();
  const [review, setReview] = useState();
  const {response, error} = useSelector((state) => state.rateTradeReducer);
  const {response: tradeResponse} = useSelector((state) => state.getTradeByIdReducer);
  const {response: reviewRes, error: reviewError} = useSelector((state) => state.tradeReviewReducer);

  useEffect(() => {
    return () => {
      dispatch({type: RATE_TRADE_DEFAULT, payload: ""});
    };
  }, []);

  return (
    <div className="__createtrade__successModal">
      <div className="__info__holder">
        <img src={Checked} alt="" className="__info__image" />
      </div>

      <div className="__info__amount">
        <span className="__amount">
          <CurrencyFormat
            value={tradeResponse?.amount}
            displayType={"text"}
            thousandSeparator={true}
            decimalScale={5}
            fixedDecimalScale
            // prefix={"$"}
            suffix={` ${tradeResponse?.tokenSymbol}`}
          />
        </span>
        <span className="__success__text">succcessfully sold </span>

        <button
          className="__done__button"
          onClick={() => {
            dispatch(checkTradeReviewAction(id));
            setOpenModal(false);
          }}
        >
          Done
        </button>

        <span onClick={() => history.push("/app/wallet")} className="__check__wallet" style={{cursor: "pointer"}}>
          Check wallet
        </span>
        <span className="__how__review"> How was your trading experience?</span>
        <span className={`__reviewRes ${response && "__cor"}`}>{response && "Review posted successfully"}</span>
        <span className={`__reviewRes ${error && "__err"}`}>{error && "Error posting Review"}</span>

        {reviewError === "not reviewed" && (
          <div className="__the__button__wrapper">
            <button className={`__review__ ${review === "pos" && "__pos"}`} onClick={() => setReview("pos")}>
              Positive <BiLike size={16} style={{marginLeft: "10px"}} />
            </button>
            <button className={`__review__ ${review === "neg" && "__neg"}`} onClick={() => setReview("neg")}>
              Negative <BiDislike size={19} style={{marginLeft: "10px"}} />
            </button>
          </div>
        )}

        {review && <ReviewComments review={review} setReview={setReview} />}
      </div>
    </div>
  );
};

export {SuccessModal};
