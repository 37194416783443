import CurrencyFormat from "react-currency-format";
import {NGN} from "../../../../hooks/imagePath";
import NotFound from "../../../../assets/images/notfound.svg";
import "../../../../styles/table.scss";
import {CoinIcons} from "../../../Common/coinSwitcher";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {Loader} from "../../../Common/Loader";
import {DOMTablePaginationComponent} from "../../../Common/DOM_Pagination";
import {CoinName} from "../../../Common/coinNameSwitcher";
import {CountryIcons} from "../../../Common/CountryIcons";
import SmallNumber from "small-number";
import CopyToClipboard from "react-copy-to-clipboard";
import {toast} from "react-toastify";
import {FiCopy} from "react-icons/fi";
import {useEffect, useState} from "react";
import {DOMTablePaginationComponent2} from "../../../Common/DOM_Pagination/index2";
import {CHANGE_PAGINATION} from "../../../../store/types";

const AllTradeOrdersDesktopTable = ({loading, data}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {pageSize, currentPage} = useSelector((state) => state.domPaginationReducer);

  useEffect(() => {
    dispatch({type: CHANGE_PAGINATION, payload: 1});

    return () => {
      dispatch({type: CHANGE_PAGINATION, payload: 1});
    };
  }, [data]);
  //Get current page
  const indexOfLastPost = currentPage * pageSize;
  const indexOfFirstPost = indexOfLastPost - pageSize;
  const currentPosts = data?.slice(indexOfFirstPost, indexOfLastPost);

  const textSlicer = (data) => {
    const newText = data ? `${data.slice(0, 10)}...` : "";
    return newText;
  };
  return (
    <div className="table__wrapper">
      {loading ? (
        <Loader />
      ) : (
        <>
          <table className="tablew">
            <thead className="newTable__thead">
              <tr className="newTable__tr">
                <th className="newTable__th">Type</th>
                <th className="newTable__th">PaymentMethod</th>
                <th className="newTable__th__coinLook">Coin</th>
                <th className="newTable__th__countryLook">Trade Location</th>
                <th className="newTable__th">Available Size</th>
                <th className="newTable__th">Closing Time</th>
                <th className="newTable__th">Status</th>
              </tr>
            </thead>

            <tbody className="newTable__tbody">
              {data &&
                data?.length > 0 &&
                currentPosts?.map((item, key) => (
                  <tr className="myTable" style={{height: "40px"}} key={item._id}>
                    <td className="tableItem">
                      <div className="__seller__wrapper">
                        <div className="__seller__details">
                          <div className="__sellerName">{item?.type?.toUpperCase()}</div>
                        </div>

                        <div className="__seller__Initials">
                          <CopyToClipboard
                            text={item?.traderID}
                            onCopy={() => toast("id copied", {position: "top-right"})}
                          >
                            <FiCopy className="walletModal__downloadBarcodeDetailsCopy" />
                          </CopyToClipboard>
                        </div>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__paymentMethod">{item.paymentMethod}</div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__coin">
                        <div className="__tokenSymbolImage">
                          <CoinIcons intialValue={item.tokenSymbol} />
                        </div>
                        <div className="">
                          <p className="__tokenSymbol">{item.tokenSymbol}</p>
                          {/* <p className="__tokenSymbolName">
                        <CoinName intialValue={item.tokenSymbol} />
                      </p> */}
                        </div>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__coin">
                        <div className="__tokenSymbolImage">
                          <CountryIcons intialValue={item?.country} />
                        </div>
                        <div className="">
                          <p className="__tokenSymbol">{item?.country}</p>
                        </div>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__limit">
                        <span>{SmallNumber(Math.round(item?.minAmount ?? 0))}</span>
                        {" - "}
                        <span>{SmallNumber(Math.round(item?.maxAmount ?? 0))}</span>
                        <span>{` ${item.currency}`}</span>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__market__unitprice">
                        <p className="__theUnitPrice">{item.closingTime} minutes</p>
                      </div>
                    </td>
                    <td className="tableItem">
                      <div className="__tradeorder__status__wrapper">
                        <span
                          className="__theStatusText __inactive"
                          style={{
                            width: "70px",
                            color: `${item.status === "active" && "#00A478"}`,
                          }}
                        >
                          {item.status}
                        </span>
                        <span className="__theStatusText" onClick={() => history.push(`/app/edit-trade/${item._id}`)}>
                          Edit
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>

          {(data?.length === 0 || data === undefined) && loading === false && (
            <div className="not_found_item">
              <img src={NotFound} alt="not found" />
            </div>
          )}
        </>
      )}

      <DOMTablePaginationComponent2 datalength={data?.length} />
    </div>
  );
};

export default AllTradeOrdersDesktopTable;
