import React, {memo} from "react";
import PostTradePage from "../PostTrade";

const PostTrade = () => {
  return (
    <React.Fragment>
      <div>
        <PostTradePage />
      </div>
    </React.Fragment>
  );
};

export default memo(PostTrade);
