import store from "../store";
import {GET_BUY_SELL_TRANSACTION_INITIAL, GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL} from "../store/types";
import APIs from "./api";

const TransactionService = {
  getBuySell: ({skip, limit, tokenSymbol, type, status}) => {
    store.dispatch({type: GET_BUY_SELL_TRANSACTION_INITIAL, payload: true});
    return APIs.get(`/api/v1/metrics/get-all-trades/${skip}/${limit}/${tokenSymbol}/${type}/${status}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getBuySellFuture: ({skip, limit, tokenSymbol, type, status}) => {
    return APIs.get(`/api/v1/metrics/get-all-trades/${skip}/${limit}/${tokenSymbol}/${type}/${status}`)
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  getDepositWithdraw: ({skip, limit, type, tokenSymbol, status}) => {
    store.dispatch({type: GET_DEPOSIT_WITHDRAW_TRANSACTION_INITIAL, payload: true});

    return APIs.get(
      `/api/v1/metrics/get-all-transactions?skip=${skip}&limit=${limit}&type=${type}&tokenSymbol=${tokenSymbol}&status=${status}`
    )
      .then((data) => {
        return data.data;
      })
      .catch((error) => {
        throw error;
      });
  },

  // getOrderDetailsById: async (_id) => {
  //   return API.post("/api/v1/order/get-order-details/", _id)
  //     .then((data) => {
  //       store.dispatch({type: REMOVE_SPINNER, payload: false});
  //       return data.data;
  //     })
  //     .catch((error) => {
  //       throw error;
  //     });
  // },
};

export default TransactionService;
