import React from "react";
import "../styles/landing-page-styles/career.scss";
import {FiSearch} from "react-icons/fi";
import Layout from "../components/LandingComponents/layout";
import {CareerFeatures} from "../components/LandingComponents/careerFeatures";
const Careers = () => {
  return (
    <Layout>
      <div className="careerWrapper">
        <div className="HeroSectionWrapper">
          <div className="HeroSection">
            <div className="HeroSectionContent">
              <div className="HeroSectionHeader">
                <h3 className="HeroSectionHeaderH3">Advance your career with Oyola</h3>
              </div>
              <div className="HeroSectionBody">
                <p className="HeroSectionBodyP">
                  Build a rewarding career in the exciting world of cryptocurrencies and blockchain technology. Join our
                  team and revolutionize the world of finance with cryptocurrency.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="valueWrapper">
          <p className="careerTitleStyleNew">Welcome to our career page</p>
          <p className="careerTagStyleNew">
            We are always on the lookout for talented individuals to join our team and help us achieve our mission of
            helping billions around the world trade crypto directly on the Oyola marketplace without the need for a
            traditional bank account.
          </p>
          <p className="careerTagStyleNew2">
            At Oyola, we believe in providing our employees with a positive and inclusive work environment where they
            can grow both personally and professionally. We value transparency, hard-work and a strong user-centric work
            ethic.
          </p>
          <p className="careerTagStyleNew3">
            If you think you would be a good fit for our team, please take a look at our current job openings below.
          </p>
        </div>

        <div className="JoinTeamWrapper" style={{padding: `20px 0px`}}>
          <div className="JoinTeamWrapperContainer">
            <div className="JoinTeamWrapperContainerHeader">
              <p className="JoinTeamWrapperContainerHeaderContent">Join our team</p>
            </div>

            <div className="JoinTeamWrapperContainerSearchWrap">
              <div className="JoinTeamWrapperContainerSearch">
                <div className="JoinTeamWrapperContainerSearchInput">
                  <div className="JoinTeamWrapperContainerSearchInputIconBody">
                    <FiSearch className="JoinTeamWrapperContainerSearchInputIcon" />
                  </div>
                  <input className="JoinTeamWrapperContainerSearchInputField" type="text" placeholder="search" />
                </div>
                <div className="JoinTeamWrapperContainerSearchButton">
                  <button className="JoinTeamWrapperContainerSearchButtonItem">Search</button>
                </div>
              </div>
            </div>
            <div className="JoinTeamWrapperContainerArray">
              <div className="JoinTeamWrapperContainerArrayWrapper">
                <section className="Career__design">
                  <CareerFeatures
                    header="Design"
                    details="Open positions in our design team."
                    careerFeaturesArray={DesignFeature}
                    border="--container--primary--color"
                  />
                </section>
                <section className="Career__softwareDev">
                  <CareerFeatures
                    header="Software Development"
                    details="Open positions in our software team."
                    careerFeaturesArray={SoftwareFeature}
                    border="--container--primary--color"
                  />
                </section>
                <section className="Career__customer">
                  <CareerFeatures
                    header="Customer Success"
                    details="Open positions in our CX team."
                    careerFeaturesArray={CustomerFeature}
                  />
                </section>

                {/* <div className="JoinTeamWrapperContainerArrayWrapperNotfound">
            <p className="JoinTeamWrapperContainerArrayWrapperNotfoundText">No available job at this time</p>
            <p className="JoinTeamWrapperContainerArrayWrapperNotfoundTextContent">
              Follow us on our social media platforms to get new update
            </p>
          </div> */}
                {/* 
                {joinOurTeamArray?.map((item, key) => (
                  <div key={key} className="JoinTeamWrapperContainerArrayWrapperContainer">
                    <div className="JoinTeamWrapperContainerArrayWrapperContainerDetails">
                      <div className="JoinTeamWrapperContainerArrayWrapperContainerDetailsHeader">
                        <p className="JoinTeamWrapperContainerArrayWrapperContainerDetailsHeaderItem">{item.title}</p>
                      </div>
                      <div className="JoinTeamWrapperContainerArrayWrapperContainerDetailsBody">
                        <p className="JoinTeamWrapperContainerArrayWrapperContainerDetailsBodyContainer">{`${item.job} - ${item.duration}`}</p>
                      </div>
                    </div>
                    <div className="JoinTeamWrapperContainerArrayWrapperContainerIconWrapper">
                      <div className="JoinTeamWrapperContainerArrayWrapperContainerIconWrapperBody">
                        <RiArrowRightSLine className="JoinTeamWrapperContainerArrayWrapperContainerIconWrapperDetails" />
                      </div>
                    </div>
                  </div>
                ))} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const valueArray = [
  {
    title: "Transparency",
    content: "We are open-minded and we are not afraid to speak up when the need arises.",
  },
  {
    title: "Hard work",
    content:
      "We put in the work and are results-driven. We ensure to achieve all set tasks and are passionate about what we do.",
  },
  {
    title: "User-centric",
    content:
      "We are concerned about our customers and their satisfaction. We build and create every feature with the user in mind.",
  },
];

// const joinOurTeamArray = [
//   {
//     title: "Head of Engineering",
//     job: "Remote",
//     duration: "Fulltime",
//   },
//   {
//     title: "Head of Engineering",
//     job: "Remote",
//     duration: "Fulltime",
//   },
//   {
//     title: "Head of Engineering",
//     job: "Remote",
//     duration: "Fulltime",
//   },
// ];

const DesignFeature = [
  {
    cardTitle: "Product Designer",
    cardDetails: "We’re looking for a Senior Product Designer to join our team.",
    time: "Full-time",
    // firstRange: "",
    // secondRange: "",

    state: "Abuja",
    country: "Nigeria",
  },
  {
    cardTitle: "Creative Designer",
    cardDetails: "We’re looking for a Mid-level Creative Designer to join our team.",
    time: "Full-time",
    // firstRange: "150,000",
    // secondRange: "200,000",
    state: "Abuja",
    country: "Nigeria",
  },
];

const SoftwareFeature = [
  {
    cardTitle: "Chief of Engineering",
    cardDetails: "We’re looking for a Chief of Engineering to join our team.",
    time: "Full-time",
    // firstRange: "150,000",
    // secondRange: "200,000",
    state: "Abuja",
    country: "Nigeria",
  },
  {
    cardTitle: "Software Developer",
    cardDetails: "We’re looking for a Software Developer to join our team.",
    time: "Full-time",
    // firstRange: "150,000",
    // secondRange: "200,000",
    state: "Abuja",
    country: "Nigeria",
  },
  {
    cardTitle: "Back End Developer",
    cardDetails: "We’re looking for a Back End Developer to join our team.",
    time: "Full-time",
    // firstRange: "150,000",
    // secondRange: "200,000",
    state: "Abuja",
    country: "Nigeria",
  },
];

const CustomerFeature = [
  {
    cardTitle: "Customer Success Manager",
    cardDetails: "We’re looking for a Customer Success Manager to join our team.",
    time: "Full-time",
    // firstRange: "150,000",
    // secondRange: "200,000",
    state: "Abuja",
    country: "Nigeria",
  },
];

export default Careers;
