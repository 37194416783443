import ReviewRow from "./Comment";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import "../../styles/button.scss";
import EmptyStateProfile from "./emptyStateProfile";

const NegativeComments = () => {
  const history = useHistory();
  const listOfReviewsState = useSelector((state) => state.profileReducer.listOfReviewsState);
  const {profileHeaderOtherDataReducer, saveOtherUsernameState} = useSelector((state) => state.profileReducer);

  if (listOfReviewsState?.length === 0) {
    return <EmptyStateProfile />;
  }

  const handleButton = () => {
    history.push(`/app/all-reviews/${saveOtherUsernameState.username}`);
  };

  const filteredNegative = listOfReviewsState.filter(
    (negative) => negative.isTrust === false && negative.reviewerUsername !== profileHeaderOtherDataReducer.username
  );

  if (listOfReviewsState?.length > 0) {
    return (
      <div className="otherProfileReviews">
        {filteredNegative.slice(0, 3).map((item) => (
          <ReviewRow
            key={Math.random()}
            avatar={"avatar"}
            username={item.reviewerUsername}
            userFeeback={item.text}
            isVerified={item.isTrust}
            transactionId={item.date_added}
          />
        ))}

        <div className="buttonContainer">
          <button className=" baseButton primaryButton buttonStyle" onClick={handleButton}>
            Show more
          </button>
        </div>
      </div>
    );
  }
};

export default NegativeComments;
