import {useEffect, useRef, useState} from "react";
import {useContext} from "react";
import CurrencyFormat from "react-currency-format";
import {RiErrorWarningLine} from "react-icons/ri";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {Avatar} from "../../../../hooks/imagePath";
import ReactTimeAgo from "react-time-ago";
import {useGetTokenBalance} from "../../../../hooks/useGetTokenBalance";
import useWindowDimensions from "../../../../hooks/useWindowDimension";
import {
  modalOnChangeCurrencyPriceAction,
  modalOnChangeTokenPriceAction,
  modalOnChangeTokenPriceMaxAction,
  setCreateTradeDefaultAction,
} from "../../../../store/actions/checkoutData";
import {createBuySellTradeAction} from "../../../../store/actions/createTradeAction";
import {capitalizeFirstLetter} from "../../../../utils/capitalizeFirstLetter";
import {getTimeDifference} from "../../../../utils/getTimeDiff";
import {Loader, Loader4} from "../../../Common/Loader";
import {AiOutlineDislike, AiOutlineLike} from "react-icons/ai";
import {BiInfoCircle} from "react-icons/bi";
import {TradeActionContext} from "../../../Home/Profile";
import PostTradeService from "../../../../services/postTradeService";
import {Skeleton} from "@mui/material";

const ModalActionModal = () => {
  const checkFeesRef = useRef();
  const {width} = useWindowDimensions();
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {setModalAction} = useContext(TradeActionContext);

  const currentTotalValue = useSelector((state) => state.marketReducer?.currentTotalValue);

  const [feesPrice, setFeesPrice] = useState(0);
  const [totalCoinPrices, setTotalPrices] = useState(0);

  const [feesChecker, setFeesChecker] = useState(false);

  const userData = useSelector((state) => state.generalReducer.userData);

  const {
    response: createResponse,
    error,
    loading: createTradeLoading,
  } = useSelector((state) => state.createTradeReducer);

  const {
    unitPrice,
    loading,
    response,
    currencyPrice,
    tokenPrice,
    error: checkOutError,
  } = useSelector((state) => state.checkOutDataReducer);

  const [tokenBalance] = useGetTokenBalance(response?.checkOutData?.tokenSymbol?.toUpperCase());

  const handleChangeCurrency = (e) => {
    e.persist();
    dispatch(modalOnChangeCurrencyPriceAction(e.target.value));

    if (response?.checkOutData?.type?.toLowerCase() === "buy") {
      clearTimeout(checkFeesRef.current);

      setFeesChecker(true);

      checkFeesRef.current = setTimeout(async () => {
        try {
          const getPayload = e.target.value.split(",").join("");

          const price = Number(getPayload) / Number(unitPrice ?? 0);

          const res = await PostTradeService.getTradeFees(Number(price));

          setFeesPrice(res.fees);
          setTotalPrices(Number(price) + res.fees);

          setFeesChecker(false);
        } catch (err) {
          setFeesChecker(false);
        }
      }, 300);
    }
  };

  const handleChangeToken = (e) => {
    e.persist();
    dispatch(modalOnChangeTokenPriceAction(e.target.value));

    if (response?.checkOutData?.type?.toLowerCase() === "buy") {
      clearTimeout(checkFeesRef.current);

      setFeesChecker(true);

      checkFeesRef.current = setTimeout(async () => {
        try {
          const getPayload = e.target.value.split(",").join("");

          const res = await PostTradeService.getTradeFees(Number(getPayload));

          setFeesPrice(res.fees);
          setTotalPrices(Number(getPayload) + res.fees);

          setFeesChecker(false);
        } catch (err) {
          setFeesChecker(false);
        }
      }, 300);
    }
  };

  const onAction = async () => {
    dispatch(createBuySellTradeAction({orderID: response?.checkOutData?._id, amount: tokenPrice}));
  };

  useEffect(() => {
    if (createResponse) {
      push(`/app/trade/${createResponse.trade_id}`);
    }
  }, [createResponse]);

  useEffect(() => {
    return () => {
      dispatch(setCreateTradeDefaultAction());
    };
  }, []);

  return (
    <div className="__mobile__action__modal">
      {error || checkOutError ? (
        <div className="__innerWarning__wrapper">
          <div className="__the__inner">
            <RiErrorWarningLine color="#FBCB5C" className="__icon" />
            <p className="__the__p">
              {checkOutError && `${checkOutError}. try other available ads.`}{" "}
              {error && "The unit price for this ad is out of range. try other available ads."}
            </p>
            <button className="__warning__button" onClick={() => setModalAction(false)}>
              Ok
            </button>
          </div>
        </div>
      ) : loading ? (
        <Loader />
      ) : (
        <>
          <div className="__top__name" style={{paddingLeft: "0px"}}>
            <a
              href={`/app/profile/${response?.checkOutData?.username}`}
              rel="noreferrer"
              target="_blank"
              style={{cursor: "pointer"}}
            >
              <div className="__top__imageWrapper" style={{backgroundImage: `url(${Avatar})`}}>
                <div
                  className="__top__imageNotification"
                  style={{
                    backgroundColor:
                      getTimeDifference(response?.traderData?.timeLastSeen) < 5
                        ? "green"
                        : getTimeDifference(response?.traderData?.timeLastSeen) > 5 &&
                          getTimeDifference(response?.traderData?.timeLastSeen) < 15
                        ? "yellow"
                        : "#DBDBDB",
                  }}
                ></div>
              </div>
            </a>

            <div>
              <div className="flex items__center">
                <a
                  href={`/app/profile/${response?.checkOutData?.username}`}
                  rel="noreferrer"
                  target="_blank"
                  style={{cursor: "pointer"}}
                >
                  <div style={{marginRight: "10px"}}>
                    <span className="__the__name">{response?.checkOutData?.username}</span>
                    <p className="__timeAgo">
                      {getTimeDifference(response?.traderData?.timeLastSeen) < 5 ? (
                        "online"
                      ) : (
                        <ReactTimeAgo date={response?.traderData?.timeLastSeen} style={{fontSize: "11px"}} />
                      )}
                    </p>
                  </div>
                </a>

                <div className="__order__rating">
                  <p className="__top__orders">
                    {response?.traderData?.totalNumberOfTrades} Orders,
                    {response?.traderData?.totalNumberOfTrades === 0 ? (
                      0
                    ) : (
                      <CurrencyFormat
                        value={
                          (response?.traderData?.numberCompletedTrades / response?.traderData?.totalNumberOfTrades) *
                          100
                        }
                        displayType={"text"}
                        thousandSeparator={true}
                        decimalScale={3}
                        fixedDecimalScale
                      />
                    )}
                    %
                  </p>
                  <div className="flex">
                    <div className="__ratings__like">
                      <span className="__rating">{response?.traderData?.trusts}</span>
                      <AiOutlineLike size={12} className="__icon" />
                    </div>
                    <div className="__ratings__dislike">
                      <span className="__rating">{response?.traderData?.distrusts}</span>
                      <AiOutlineDislike size={12} className="__icon" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="__modal__content">
            <div className="__modal__content__detail">
              <p className="__mTitle">Unit Price:</p>
              <p className="__munit__price">
                <CurrencyFormat
                  value={response?.checkOutData?.unitPrice ?? 0}
                  // value={Math.abs(data?.unitPrice)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={4}
                  fixedDecimalScale
                  // prefix={"$"}
                  suffix={` ${response?.checkOutData?.currency}`}
                />
              </p>
            </div>
          </div>
          <div className="__modal__content">
            <div className="__modal__content__detail">
              <p className="__mTitle">Limit</p>
              <p className="__title__value">
                <CurrencyFormat
                  value={Math.abs(response?.checkOutData?.minAmount)}
                  // value={Math.abs(data?.unitPrice)}
                  displayType={"text"}
                  thousandSeparator={true}
                  decimalScale={2}
                  fixedDecimalScale
                  style={{marginRight: width <= 600 && "10px"}}
                  // prefix={"$"}
                  suffix={` ${response?.checkOutData?.currency}`}
                />{" "}
                {
                  <CurrencyFormat
                    value={Math.abs(response?.checkOutData?.maxAmount)}
                    // value={Math.abs(data?.unitPrice)}
                    displayType={"text"}
                    thousandSeparator={true}
                    decimalScale={2}
                    fixedDecimalScale
                    // prefix={"$"}
                    suffix={` ${response?.checkOutData?.currency}`}
                  />
                }
              </p>
            </div>
          </div>
          <div className="__modal__bottom__right">
            <div>
              {response?.checkOutData?.type?.toLowerCase() === "sell" ? (
                <>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I Want To Pay</label>
                    <div>
                      <div
                        className={`__txnInputWrapper ${
                          parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                          parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount)
                            ? "__redborder"
                            : ""
                        }`}
                      >
                        <CurrencyFormat
                          value={currencyPrice}
                          decimalScale={2}
                          placeholder="currency amount"
                          onChange={handleChangeCurrency}
                          className="__txnInput"
                          name="currency"
                        />
                        <span className="__theCurrency">{response?.checkOutData?.currency?.toUpperCase()}</span>
                      </div>
                      <span className="__warning__text">
                        {parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount)
                          ? `Max Amount ${Math.abs(response?.checkOutData?.maxAmount)}`
                          : parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) &&
                            `Min Amount ${Math.abs(response?.checkOutData?.minAmount)}`}
                      </span>
                    </div>
                  </div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I Will Receive</label>
                    <div className="__txnInputWrapper">
                      <CurrencyFormat
                        value={tokenPrice}
                        onChange={handleChangeToken}
                        className="__txnInput"
                        placeholder="token amount"
                        decimalScale={7}
                        name="token"
                      />
                      <span className="__theCurrency">{response?.checkOutData?.tokenSymbol?.toUpperCase()}</span>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I Want To Sell</label>
                    <div>
                      <div className={`__txnInputWrapper `}>
                        <CurrencyFormat
                          // value={data?.unitPrice}
                          value={tokenPrice}
                          // decimalScale={3}
                          onChange={handleChangeToken}
                          decimalScale={7}
                          placeholder="token amount"
                          className="__txnInput"
                          name="token"
                        />

                        <div className="__theCryptoWrapper">
                          <span
                            className="__SystemLater"
                            onClick={() => {
                              if (response?.checkOutData?.type?.toLowerCase() === "buy") {
                                clearTimeout(checkFeesRef.current);

                                setFeesChecker(true);
                                checkFeesRef.current = setTimeout(async () => {
                                  try {
                                    const res = await PostTradeService.getTradeFees(Number(currentTotalValue));

                                    setFeesPrice(res.fees);
                                    setTotalPrices(Number(currentTotalValue));

                                    const valueReached = Number(currentTotalValue) - res.fees;

                                    dispatch(modalOnChangeTokenPriceMaxAction(valueReached.toString()));
                                    setFeesChecker(false);
                                  } catch (error) {
                                    setFeesChecker(false);
                                  }
                                }, 300);
                              }
                            }}
                          >
                            MAX
                          </span>

                          <span className="__theCurrency">{response?.checkOutData?.tokenSymbol?.toUpperCase()}</span>
                        </div>
                      </div>
                      <div className="__tokencheck" style={{marginTop: "5px"}}>
                        <span className="__warning">
                          {(tokenPrice > tokenBalance || Number(totalCoinPrices) > Number(tokenBalance)) &&
                            "insufficient"}
                        </span>
                        <span className="__info">
                          Available Bal{" "}
                          <CurrencyFormat
                            // value={data?.unitPrice}
                            value={tokenBalance}
                            displayType={"text"}
                            thousandSeparator={true}
                            decimalScale={7}
                            fixedDecimalScale
                            suffix={` ${response?.checkOutData?.tokenSymbol}`}
                          />
                        </span>
                      </div>

                      <div className="__tokencheck" style={{marginTop: "5px"}}>
                        <span className="__warning"></span>
                        {feesChecker ? (
                          <div>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={200}
                              height={15.5}
                              sx={{borderRadius: "8px"}}
                            />
                          </div>
                        ) : (
                          <span className="__info">
                            Token Fees:{" "}
                            <CurrencyFormat
                              // value={data?.unitPrice}
                              value={feesPrice || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              autocomplete="off"
                              decimalScale={7}
                              fixedDecimalScale
                              suffix={` ${response?.checkOutData?.tokenSymbol}`}
                            />
                          </span>
                        )}
                      </div>

                      <div className="__tokencheck" style={{marginTop: "5px"}}>
                        <span className="__warning"></span>
                        {feesChecker ? (
                          <div>
                            <Skeleton
                              animation="wave"
                              variant="rectangular"
                              width={200}
                              height={15.5}
                              sx={{borderRadius: "8px"}}
                            />
                          </div>
                        ) : (
                          <span className="__info">
                            Total Token:{" "}
                            <CurrencyFormat
                              // value={data?.unitPrice}
                              value={totalCoinPrices || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              autocomplete="off"
                              decimalScale={7}
                              fixedDecimalScale
                              suffix={` ${response?.checkOutData?.tokenSymbol}`}
                            />
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="__right__input__wrapper">
                    <label className="__txnInputLabel">I Will Receive</label>
                    <div
                      className={`__txnInputWrapper ${
                        parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                        parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount)
                          ? "__redborder"
                          : ""
                      }`}
                    >
                      <CurrencyFormat
                        value={currencyPrice}
                        onChange={handleChangeCurrency}
                        className="__txnInput"
                        placeholder="currency amount"
                        decimalScale={2}
                        name="currency"
                        //disabled={true}
                      />
                      <span className="__theCurrency">{response?.checkOutData?.currency?.toUpperCase()}</span>
                    </div>
                    <span className="__warning__text">
                      {parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount)
                        ? `Max Amount ${Math.abs(response?.checkOutData?.maxAmount)}`
                        : parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) &&
                          `Min Amount ${Math.abs(response?.checkOutData?.minAmount)}`}
                    </span>
                  </div>
                </>
              )}
            </div>

            {capitalizeFirstLetter(response?.checkOutData?.type)?.toLowerCase() === "buy" &&
              userData?.data?.userData?.isVerified === false && (
                <div className="verify_indicator">
                  <div className="verify_indicator_icon_body">
                    <BiInfoCircle className="verify_indicator_icon" />
                  </div>

                  <p className="verify_indicator_text">Verify account to sell crypto</p>
                </div>
              )}

            <div className="__action__buttonWrapper">
              <button className="__cancel__button" onClick={() => setModalAction(false)}>
                Cancel
              </button>

              {userData?.data?.userData?.isVerified === false ? (
                <button
                  className="__buy__button"
                  onClick={() => {
                    push("/app/settings");
                  }}
                  style={{background: "#21B0EA"}}
                >
                  Verify
                </button>
              ) : (
                <button
                  className="__buy__button"
                  disabled={
                    parseFloat(currencyPrice) > Math.abs(response?.checkOutData?.maxAmount) ||
                    parseFloat(currencyPrice) < Math.abs(response?.checkOutData?.minAmount) ||
                    tokenPrice === 0 ||
                    feesChecker ||
                    !currencyPrice ||
                    createTradeLoading === true
                      ? true
                      : false || tokenPrice > tokenBalance || Number(totalCoinPrices) > Number(tokenBalance)
                  }
                  onClick={() => onAction()}
                >
                  {createTradeLoading ? (
                    <Loader4 />
                  ) : (
                    `${capitalizeFirstLetter(
                      response?.checkOutData?.type?.toLowerCase() === "sell" ? "Buy" : "Sell"
                    )} ${response?.checkOutData?.tokenSymbol}`
                  )}
                </button>
              )}
            </div>
          </div>
          <div className="__modal__content">
            <div className="__modal__content__detail">
              <p className="__mTitle">Payment Time Limit</p>
              <p className="__title__value">{response?.checkOutData?.closingTime} Mins</p>
            </div>
          </div>

          <div className="__modal__content">
            <div className="__modal__content__detail">
              <p className="__mTitle">Payment Method</p>
              <p className="__value__paymentMethod">{response?.checkOutData?.paymentMethod}</p>
            </div>
          </div>

          <div className="__tcWrapper">
            <span>Terms and Conditions</span>
          </div>
          <div className="__theTandC">
            <p className="__content">{response?.checkOutData?.description}</p>
          </div>
        </>
      )}
    </div>
  );
};

export default ModalActionModal;
