const DisplayUsernameError = ({usernameFocus = false, username = "", validUsername = false}) => {
  return (
    usernameFocus &&
    username &&
    !validUsername && (
      <p style={{color: usernameFocus && username && !validUsername ? "red" : "", marginTop: "10px"}}>
        5 to 10 characters. Must begin with a letter.
        <br />
        Letters, numbers, underscores, and are hyphens allowed.
      </p>
    )
  );
};

const DisplayEmailError = ({emailFocus = false, email = "", validEmail = false}) => {
  return (
    emailFocus &&
    email &&
    !validEmail && (
      <p
        style={{
          color: emailFocus && email && !validEmail ? "red" : "",
          textAlign: "left",
          width: "100%",
          marginTop: "10px",
        }}
      >
        Enter a valid email address
      </p>
    )
  );
};

const DisplayPasswordError = ({passwordFocus = false, validPassword = false}) => {
  return (
    passwordFocus &&
    !validPassword && (
      <p style={{color: passwordFocus && !validPassword ? "red" : "", width: "100%"}}>
        8 to 24 characters.
        <br />
        Must include uppercase and lowercase
        <br />
        letters, a number and a special character.
        <br />
        Allowed special characters: <span aria-label="exclamation mark">!</span>
        <span aria-label="at symbol">@</span> <span aria-label="hashtag">#</span>
        <span aria-label="dollar sign">$</span> <span aria-label="percent">%</span>
      </p>
    )
  );
};

const DisplayConfirmPasswordError = ({confirmPasswordFocus = false, validConfirmPassword = false}) => {
  return (
    confirmPasswordFocus &&
    !validConfirmPassword && (
      <p style={{color: confirmPasswordFocus && !validConfirmPassword ? "red" : "", width: "100%", marginTop: "10px"}}>
        Must match the first password input field.
      </p>
    )
  );
};

const DisplayNameError = ({emailFocus = false, email = "", validEmail = false, message = ""}) => {
  return (
    emailFocus &&
    email &&
    !validEmail && (
      <p
        style={{
          color: emailFocus && email && !validEmail ? "red" : "",
          textAlign: "left",
          width: "100%",
          marginTop: "10px",
        }}
      >
        {message}
      </p>
    )
  );
};

export {DisplayUsernameError, DisplayEmailError, DisplayPasswordError, DisplayConfirmPasswordError, DisplayNameError};
