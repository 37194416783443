import React, {memo} from "react";
import LoginForm from "../components/Login/LoginForm";
import LoginOTP from "../components/Login/LoginOTP";
import LoginGoogleOTP from "../components/Login/LoginGoogleOTP";
import {Loader} from "../components/Common/Loader";
import {useSelector} from "react-redux";
import {Navbar} from "../components/Common/nav-bar/nav-bar.component";

const Login = () => {
  const showOTP = useSelector((state) => state.authReducer.loadingState);

  return (
    <React.Fragment>
      {showOTP === 0 && (
        <React.Fragment>
          <Navbar />
          <LoginForm />
        </React.Fragment>
      )}

      {showOTP === 1 && (
        <React.Fragment>
          <Navbar />
          <Loader />
        </React.Fragment>
      )}

      {showOTP === 2 && <LoginOTP />}
      {showOTP === 3 && <LoginGoogleOTP />}
    </React.Fragment>
  );
};

export default memo(Login);
