import "../../styles/landing-page-styles/zero-fees.scss";
import zeroFeesImage from "../../assets/images/zero-fees.jpg";
import "../../styles/landing-page-styles/button.scss";
import {useSelector} from "react-redux";
const ZeroFees = () => {
  const {getSelecteGeoLocationData} = useSelector((state) => state.LandingReducer);
  const token = localStorage.getItem("isLoggedIn");

  return (
    <main className="homepageContainer">
      <div className="innerContainerZeroFees">
        <div className="zeroFeesStyle">
          <div className="containerLeft">
            <img src={zeroFeesImage} alt="phone wallet" className="imageContainer" />
          </div>

          <div className="containerRight">
            <h1 className="title">Buy and sell at zero fees</h1>
            <p className="tag">
              Buy and sell cryptocurrencies in minutes with no added charges or fees. Buy and sell with confidence and
              no restrictions. Discover buyers and sellers across the globe at affordable rates.
            </p>
            <div className="signUpContainer">
              <button
                style={{cursor: "pointer"}}
                className="baseButton primaryButtonLarge buttonAdditionalStyle"
                onClick={() => {
                  getSelecteGeoLocationData &&
                    (token === null || token === undefined
                      ? (window.location = `/marketplace/${getSelecteGeoLocationData?.countryName}/BTC/buy/Bank%20Transfer`)
                      : (window.location = `/marketplace/${getSelecteGeoLocationData?.countryName}/BTC/buy/Bank%20Transfer`));
                }}
              >
                Trade crypto
              </button>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ZeroFees;
