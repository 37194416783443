import OrderInfo from "./orderInfo";
import ReviewAndWallet from "./reviewAndWallet";
import "../../../styles/createTrade/tradeDetail.scss";
import {useDispatch, useSelector} from "react-redux";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import {checkTradeReviewAction} from "../../../store/actions/createTradeAction";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {useState} from "react";
import useOnClickOutside from "../../../hooks/useOnClickOutside";

const CreateTradeDetailsArea = () => {
  const ref = useRef();
  const dispatch = useDispatch();
  const {id} = useParams();
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState(false);
  const {response: tradeIdResponse} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(tradeIdResponse ?? {});
  const {response: statusResponse} = useSelector((state) => state.getTradeStatusbyIdReducer);
  // const {response: reviewRes, error: reviewError} = useSelector((state) => state.tradeReviewReducer);

  useEffect(() => {
    dispatch(checkTradeReviewAction(id));
  }, []);

  const HandleShowTerm = () => {
    if (close) {
      setOpen(false);
      setClose(false);
    } else {
      setOpen(true);
      setClose(true);
    }
  };

  useOnClickOutside(ref, () => setOpen(false));

  return (
    <div className="__createTrade__detail__wraper">
      <div>
        {userType === "user" && (
          <div
            className={`__transaction__state ${
              statusResponse?.status === "pending"
                ? "__pending"
                : statusResponse?.status === "paid"
                ? "__paid"
                : statusResponse?.status === "completed"
                ? "__complete"
                : statusResponse?.status === "disputed"
                ? "__dispute"
                : statusResponse?.status === "cancelled"
                ? "__cancelled"
                : ""
            }`}
          >
            <div
              className={`__state__inner ${
                statusResponse?.status === "pending"
                  ? "__pending"
                  : statusResponse?.status === "paid"
                  ? "__paid"
                  : statusResponse?.status === "completed"
                  ? "__complete"
                  : statusResponse?.status === "disputed"
                  ? "__dispute"
                  : statusResponse?.status === "cancelled"
                  ? "__cancelled"
                  : ""
              }`}
            >
              {statusResponse?.status === "pending" && tradeIdResponse?.type === "buy"
                ? "Transfer funds to seller’s account"
                : statusResponse?.status === "pending" && tradeIdResponse?.type === "sell"
                ? "Pending"
                : statusResponse?.status === "paid" && tradeIdResponse?.type === "buy"
                ? "Waiting for seller to release asset"
                : statusResponse?.status === "paid" && tradeIdResponse?.type === "sell"
                ? "Buyer Has Made Payment"
                : statusResponse?.status === "completed"
                ? "Completed"
                : statusResponse?.status === "disputed"
                ? "Dispute in progress"
                : statusResponse?.status === "cancelled"
                ? "cancelled"
                : ""}
            </div>
          </div>
        )}
        {userType === "trader" && (
          <div
            className={`__transaction__state ${
              statusResponse?.status === "pending"
                ? "__pending"
                : statusResponse?.status === "paid"
                ? "__paid"
                : statusResponse?.status === "completed"
                ? "__complete"
                : statusResponse?.status === "disputed"
                ? "__dispute"
                : statusResponse?.status === "cancelled"
                ? "__cancelled"
                : ""
            }`}
          >
            <div
              className={`__state__inner ${
                statusResponse?.status === "pending"
                  ? "__pending"
                  : statusResponse?.status === "paid"
                  ? "__paid"
                  : statusResponse?.status === "completed"
                  ? "__complete"
                  : statusResponse?.status === "disputed"
                  ? "__dispute"
                  : statusResponse?.status === "cancelled"
                  ? "__cancelled"
                  : ""
              }`}
            >
              {statusResponse?.status === "pending" && tradeIdResponse?.type === "sell"
                ? "Transfer funds to seller’s account"
                : statusResponse?.status === "pending" && tradeIdResponse?.type === "buy"
                ? "Pending"
                : statusResponse?.status === "paid" && tradeIdResponse?.type === "sell"
                ? "Waiting for seller to release asset"
                : statusResponse?.status === "paid" && tradeIdResponse?.type === "buy"
                ? "Buyer Has Made Payment"
                : statusResponse?.status === "completed"
                ? "Completed"
                : statusResponse?.status === "disputed"
                ? "Dispute in progress"
                : statusResponse?.status === "cancelled"
                ? "cancelled"
                : ""}
            </div>
          </div>
        )}
        <OrderInfo />
      </div>
      {statusResponse?.status === "pending" && tradeIdResponse?.description && (
        <div
          ref={ref}
          className="__create__trade__paymentDetails"
          style={{height: `${open ? "280px" : "55px"}`, transition: "all .3s"}}
        >
          <div className="__create__trade__paymentDetails__Header" onClick={HandleShowTerm}>
            <div className="__payment__tandc">Terms and Conditions</div>

            <div className="searchAbleIconDecorator">
              {open ? (
                <div className="searchIconBody">
                  <IoIosArrowUp className="searchIcon" />
                </div>
              ) : (
                <div className="searchIconBody">
                  <IoIosArrowDown className="searchIcon" />
                </div>
              )}
            </div>
          </div>
          {open && (
            <div className="__the" style={{transition: "all .3s"}} ref={ref}>
              <p className="__tandc">{tradeIdResponse?.description}</p>
            </div>
          )}
        </div>
      )}
      {statusResponse?.status !== "pending" && <ReviewAndWallet />}
    </div>
  );
};

export default CreateTradeDetailsArea;
