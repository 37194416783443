import {useDispatch} from "react-redux";
import {
  getConvertConvertionNormal,
  getConvertInsufficientFunds,
  getConvertTokenInput,
} from "../../store/actions/convertAction";
import "../../styles/convert/ConvertDropDown.scss";
// import BtcIcon from "../../assets/icons/convertBitcoin.png";
// import convertDropdown from "../../assets/icons/convertDropdown.png";
import {CoinIcons} from "../Common/coinSwitcher";
// import UsdtIcon from "../../assets/icons/usdt.svg";

// will change border color to purple if is focus
const ConvertDropDown = ({tokenDetails, handleSelecctItem}) => {
  // if (!tokenDetails) {
  //   return <p>No Data</p>;
  // }
  const dispatch = useDispatch();

  return (
    <div className="ConvertMainDropDownContainer">
      <div className="ConvertDropDownContainer">
        <div className="ConvertDropDownTextContainerStyle">
          <p className="ConvertDropDownTextStyle">Select crypto</p>
        </div>
        {tokenDetails.map(
          (item, key) =>
            (item?.tokenSymbol?.toLowerCase() === "usdt" || item?.tokenSymbol?.toLowerCase() === "btc") && (
              <div
                key={key}
                className="ConvertDropButtonBody"
                onClick={() => {
                  handleSelecctItem(item);
                  dispatch(getConvertInsufficientFunds(""));
                  dispatch(getConvertConvertionNormal({finalAmount: 0}));
                  dispatch(getConvertTokenInput(""));
                }}
              >
                <div className="ConvertDropButtonBodyIconAndText">
                  <CoinIcons intialValue={item?.tokenSymbol} />
                  <p className="ConvertDropButtonBodyIconAndTextOne">{item?.tokenSymbol}</p>
                  <p className="ConvertDropButtonBodyIconAndTextTwo"> {item?.chain}</p>
                </div>
              </div>
            )
        )}
      </div>
    </div>
  );
};

export default ConvertDropDown;
