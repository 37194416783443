import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {TIMEDATA} from "../../store/types";
import Dropdown from "../Common/Dropdown";

const listDate = [
  {
    title: "15 Mins",
  },
  {
    title: "30 Mins",
  },
  {
    title: "45 Mins",
  },
  {
    title: "60 Mins",
  },
];

const TimeDresser = () => {
  const dispatch = useDispatch();
  const timeData = useSelector((state) => state.postTradeReducer.timeData);

  const HandleClickTimer = (item) => {
    dispatch({type: TIMEDATA, payload: item.title});
  };

  return (
    <>
      <Dropdown list={listDate} select={timeData} HandleClickCoin={HandleClickTimer} />
    </>
  );
};

export default memo(TimeDresser);
