import React, {useEffect} from "react";
import ConvertServices from "../../services/Convert";

import {
  getConvertBankAccChecker,
  getConvertBankDetails,
  getConvertBankList,
  getConvertConfirmAccDetails,
  getConvertConvertionNormal,
  getConvertConvertionRate,
  getConvertDropdownContent,
  getConvertLevel,
  getConvertLoader,
  getConvertTokenInput,
} from "../../store/actions/convertAction";

import {useDispatch, useSelector} from "react-redux";
import {Loader} from "../Common/Loader";
import {toast} from "react-toastify";
import ConvertModalSection from "./ConvertModalSection";
import ConvertParentPage from "./ConvertParent";

const ConvertIndex = () => {
  const dispatch = useDispatch();

  const {convertLoader} = useSelector((state) => state.convertReducer);

  const userData = useSelector((state) => state.generalReducer.userData);

  const fetchData = async () => {
    const constant = await Promise.all([ConvertServices?.getConversionRates(1, "BTC"), ConvertServices?.getBankList()])
      .then((data) => {
        return data;
      })
      .catch((err) => {
        toast(err?.message);
      });

    if (constant?.length > 0) {
      userData?.data?.userData?.tokens?.map((item) => {
        if (item?.tokenSymbol?.toLowerCase() === "btc") {
          dispatch(getConvertDropdownContent(item));
        }
      });

      dispatch(getConvertConvertionRate(constant[0]));
      dispatch(getConvertBankList(constant[1]));
      dispatch(getConvertLoader(false));
    }
  };

  useEffect(() => {
    dispatch(getConvertConvertionRate({}));
    dispatch(getConvertTokenInput(0));
    dispatch(getConvertConvertionNormal({}));
    dispatch(getConvertBankDetails({}));
    dispatch(getConvertConfirmAccDetails({}));
    dispatch(getConvertBankAccChecker(""));
    dispatch(getConvertLevel(0));

    // clearing all the data
    dispatch(getConvertLoader(true));

    if (Object.keys(userData).length > 0) {
      fetchData();
    }
  }, [userData]);

  return (
    <>
      {convertLoader && <Loader />}
      {!convertLoader && (
        <>
          {/* <ConvertComponent /> */}
          <ConvertParentPage />

          <ConvertModalSection />
        </>
      )}
    </>
  );
};

export default ConvertIndex;
