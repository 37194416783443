import React, {memo, useState} from "react";
import "../../styles/login.scss";
import MobileNavBar from "../Common/MobileNavBar";
import {useHistory} from "react-router-dom";
import {loader} from "../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/auth";
import {toast} from "react-toastify";
import "../../styles/new/optStyles.scss";
import {HOLD_USER_DETAILS, OTP_FOR_GOOGLE_AUTH} from "../../store/types";
import store from "../../store";
import LoginOTPInput from "../Common/loginOtpInput";

const LoginGoogleOTP = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);

  const {storeLoginDetailsState} = useSelector((state) => state.authReducer);
  const otpForGoogleAuth = useSelector((state) => state.authReducer.otpForGoogleAuth);

  const handleChange = (otp) => {
    store.dispatch({type: OTP_FOR_GOOGLE_AUTH, payload: ""});
    setOtp(otp);
  };

  const goBack = () => {
    store.dispatch({type: OTP_FOR_GOOGLE_AUTH, payload: ""});
    dispatch(loader(0));
  };

  const googleOtpGoToHome = async () => {
    const googlePayload = {
      email: storeLoginDetailsState.email,
      password: storeLoginDetailsState.password,
      authCode: otp,
      authType: "google auth",
    };

    if (otp.length >= 6) {
      setShowError(false);
      dispatch(loader(1));

      if (storeLoginDetailsState?.authPreference === "google auth") {
        try {
          const res = await AuthService.login(googlePayload);

          if (res.data.message === "success") {
            dispatch({type: HOLD_USER_DETAILS, payload: {}});
            history.push("/app/overview");
          }
        } catch (err) {
          store.dispatch({type: OTP_FOR_GOOGLE_AUTH, payload: "Invalid google auth code"});

          toast("Invalid google auth code");
          dispatch(loader(3));
        }
      }
    } else {
      setShowError(true);
    }
  };

  return (
    <div className="login-container">
      <MobileNavBar />
      <div className="login-content">
        <div className="form-contents">
          <>
            <p className="title">Security verification</p>
            <p className="tag">Generate otp code from google authenticator app</p>
          </>

          <div style={otpContainer}>
            <div style={{margin: "3rem 0 0.5rem"}}>
              <p className="title" style={{fontSize: "1.6rem"}}>
                Google authenticator verification code
              </p>
            </div>
            <p className="tag" style={{marginTop: "0px", fontSize: "13px"}}></p>

            {otpForGoogleAuth ? (
              <p style={{color: "#F04438", textAlign: "left", marginTop: "0px", width: "100%"}}>{otpForGoogleAuth}</p>
            ) : null}

            <div>
              {showError === true ? (
                <p
                  style={{
                    color: "#F04438",
                    textAlign: "left",
                    marginTop: "0px",
                    width: "100%",
                    fontSize: "1.4rem",
                    fontWeight: "600",
                    marginBottom: "1.5rem",
                  }}
                >
                  Enter OTP
                </p>
              ) : null}
              <LoginOTPInput value={otp} onChange={handleChange} error={otpForGoogleAuth} />
            </div>
          </div>

          <button className="buttonStyle" onClick={googleOtpGoToHome} style={{marginTop: "2rem"}}>
            Confirm
          </button>
          <button className="buttonStyle" style={goBackStyle} onClick={goBack}>
            Go back
          </button>
        </div>
      </div>
    </div>
  );
};

const otpContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
};

const goBackStyle = {
  width: "100%",
  background: "#fff",
  color: "black",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
};

export default memo(LoginGoogleOTP);
