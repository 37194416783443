import React, {memo} from "react";
import OverviewFundAccount from "./overviewFundAccount";
import OverviewVerifyAccount from "./overviewVerifyAccount";
import "../../styles/overviewStyles/overviewMainLowerSection.scss";
import {useSelector} from "react-redux";

const OverviewMainLowerSection = () => {
  const userData = useSelector((state) => state.generalReducer.userData);

  return (
    <div className="OverviewMainLowerSection">
      <div className="OverviewMainLowerSectionFirstSection">
        <OverviewFundAccount />
      </div>
      {/* {!userData?.data?.userData?.isVerified && (
        <div className="OverviewMainLowerSectionSecondSection">
          <OverviewVerifyAccount />
        </div>
      )} */}
    </div>
  );
};

export default memo(OverviewMainLowerSection);
