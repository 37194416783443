import React, {useState} from "react";
import {GrFormClose} from "react-icons/gr";
import FaqVideos from "../components/LandingComponents/faq-videos/faq.videos.component";
import Layout from "../components/LandingComponents/layout";
import SectionModalCentered from "../components/LandingComponents/section-modal/sectionModalCentered";
import YoutubeWrapper from "../components/LandingComponents/youtube-wrapper/youtube-wrapper.component";
import "../styles/landing-page-styles/faq.scss";

const Videos = () => {
  const [modal, setModal] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState("");
  const [query, setQuery] = useState("");
  const [filteredArray, setFilteredArray] = useState([]);

  const openYoutubeVideo = (item) => {
    setYoutubeLink(item);
    setModal(true);
  };

  const HandleChangeLearn = (e) => {
    const {value} = e.target;

    if (videosLevel.length > 0) {
      setQuery(value);

      const filteredList = videosLevel?.filter((item) => item?.title?.toLowerCase()?.includes(value?.toLowerCase()));

      setFilteredArray(filteredList);
    }
  };

  const HandleClear = (e) => {
    setQuery("");
    setFilteredArray([]);
  };

  return (
    <Layout>
      <div className="faqWrapper" id="video-tutorials">
        <div className="faqWrapperHeader">
          <div className="faqWrapperHeaderWrapper">
            <p className="faqWrapperHeaderTitle">Learn about Oyola</p>
            <p className="faqWrapperHeaderText">
              Experience the benefits of Oyola's secure, fast, and easy-to-use platform, and enjoy the freedom of
              trading cryptocurrency with confidence.
            </p>
            {/* <div>
            <AboutTextDetails
              header={"Learn about Oyola"}
              bodyText={"Watch our short explainer videos to understand the world of crypto on Oyola"}
              headerStyle={{color: "#101828"}}
              bodyStyle={{color: "#667085"}}
            />
          </div> */}

            <div className="faqWrapperHeaderSearch">
              <input
                value={query}
                onChange={HandleChangeLearn}
                onBlur={HandleClear}
                placeholder="Search"
                type={"text"}
                className="faqWrapperHeaderSearchInput"
              />
              {/* <button onClick={HandleSearchSystem} className={styles.faqWrapperHeaderButton}>
              Search
            </button> */}
            </div>
          </div>
        </div>
        <div className="faqWrapperVideos">
          <div className="faqWrapperVideosWrapper">
            {query && query !== null && query !== undefined
              ? filteredArray.map((item, key) => (
                  <div key={key} className="faqWrapperVideosWrapperItem">
                    <FaqVideos handleVideo={openYoutubeVideo} videoItem={item} />
                  </div>
                ))
              : videosLevel.map((item, key) => (
                  <div key={key} className="faqWrapperVideosWrapperItem">
                    <FaqVideos handleVideo={openYoutubeVideo} videoItem={item} />
                  </div>
                ))}

            {videosLevel.length === 0 && (
              <div className="faqWrapperVideosWrapperNOtfound">
                {/* <Image src={NoFound} alt={""} width={100} height={200} className={styles.faqWrapperVideosWrapperImage} /> */}
                <h3 className="faqWrapperVideosWrapperHeaderContent">No material available</h3>
                <p className="faqWrapperVideosWrapperTextContent">
                  Follow us on our social media platforms to get new update
                </p>
              </div>
            )}
          </div>
        </div>

        <SectionModalCentered open={modal}>
          <div className="faqWrapperWrapper">
            <div
              className="faqWrapperWrapperIconBody"
              onClick={() => {
                setYoutubeLink("");
                setModal(false);
              }}
            >
              <GrFormClose className="faqWrapperWrapperIcon" />
            </div>
            <YoutubeWrapper youtubeLink={youtubeLink} />
          </div>
        </SectionModalCentered>
      </div>
    </Layout>
  );
};

const videosLevel = [
  {
    videoLink: "https://www.youtube.com/embed/YqTSYUZ-OjQ",
    title: "How to deposit cryptocurrency on Oyola",
    description:
      "Jump right in - get a quick guide on how to deposit your digital currencies on Oyola and start trading.",
  },
  {
    videoLink: "https://www.youtube.com/embed/11sx5Ot07hI",
    title: "How to sell cryptocurrency on Oyola",
    description:
      "Are you curious about how to sell your cryptocurrencies on Oyola? Watch our video guide on how to sell your crypto using Oyola.",
  },
  {
    videoLink: "https://www.youtube.com/embed/1JehMczuvS4",
    title: "How to withdraw cryptocurrency on Oyola",
    description: "Watch our quick guide on how to withdraw your cryptocurrency from Oyola to your chosen wallet.",
  },
  {
    videoLink: "https://www.youtube.com/embed/X2sG8kNpI_U",
    title: "How to post a Trade Order On Oyola",
    description:
      "To learn the process of posting a trade on the Oyola marketplace, refer to our short video guide that shows the steps involved.",
  },
  {
    videoLink: "",
    title: "How to trade crypto",
    description: "Jump right in — get an overview of the basics and get started onbuilding.",
  },
  {
    videoLink: "",
    title: "How to trade crypto",
    description: "Jump right in — get an overview of the basics and get started onbuilding.",
  },
];

export default Videos;
