import React, {memo, useState} from "react";
import {useCallback} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import PostTradeService from "../../services/postTradeService";
import {getCoinData, getTotalCurrencyData} from "../../store/actions/postTradeAction";
import {COINDATA, SKELETON_LOADER} from "../../store/types";
import {CoinIcons} from "../Common/coinSwitcher";
import Dropdown from "../Common/Dropdown";

const CoinDresser = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const buyData = useSelector((state) => state.postTradeReducer.buyData);
  const coinData = useSelector((state) => state.postTradeReducer.coinData);
  const tokenDetails = useSelector((state) => state.generalReducer.tokenDetails);
  const countryDataState = useSelector((state) => state.postTradeReducer.countryData);
  const margin = useSelector((state) => state.postTradeReducer.tokenMarginData);

  const HandleClickCoin = (item) => {
    dispatch({type: COINDATA, payload: item.title});
    history.push(
      `/app/post-trade/${buyData.checker === true ? "buy" : "sell"}/${item?.title?.toLowerCase()}/${
        countryDataState.countryName
      }`
    );
    getLocalCurrency(item?.title?.toUpperCase());
  };

  const getLocalCurrency = useCallback(
    (coin) => {
      dispatch({type: SKELETON_LOADER, payload: true});
      if (
        coin !== "" &&
        countryDataState?.currencyCode !== "" &&
        countryDataState?.currencyCode !== undefined &&
        typeof margin === "number" &&
        !isNaN(margin)
      ) {
        PostTradeService.TokenLocalCurrency({
          tokenSymbol: coin,
          currency: countryDataState?.currencyCode,
          margin: margin,
        }).then((data) => dispatch(getTotalCurrencyData(data)));
      }
    },
    [countryDataState?.currencyCode, dispatch, margin]
  );

  return (
    <div>
      <Dropdown
        HandleClickCoin={HandleClickCoin}
        CoinIcons={CoinIcons}
        list={tokenDetails}
        iconLead={true}
        select={coinData?.toUpperCase()}
      />
    </div>
  );
};

export default memo(CoinDresser);
