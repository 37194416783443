import React, {memo, useMemo, useState} from "react";
import "../../styles/login.scss";
import MobileNavBar from "../Common/MobileNavBar";
import {useHistory} from "react-router-dom";
import {loader, loginDetailsSecond, storeLoginOtpAction} from "../../store/actions/auth";
import {useDispatch, useSelector} from "react-redux";
import AuthService from "../../services/auth";
import {toast} from "react-toastify";
import {HOLD_USER_DETAILS} from "../../store/types";
import LoginOTPInput from "../Common/loginOtpInput";

const LoginOTP = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [otp, setOtp] = useState("");
  const [showError, setShowError] = useState(false);

  const [error, setError] = useState(false);
  const {storeLoginDetailsState, storeLoginOtpState} = useSelector((state) => state.authReducer);

  useMemo(() => error, [error]);
  useMemo(() => showError, [showError]);

  const handleChange = (otp) => {
    dispatch(storeLoginOtpAction(""));
    setOtp(otp);
  };

  const goBack = () => {
    dispatch(storeLoginOtpAction(""));
    dispatch(loader(0));
    dispatch(loginDetailsSecond(""));
  };

  const goToHome = () => {
    const emailPayload = {
      email: storeLoginDetailsState.email,
      password: storeLoginDetailsState.password,
      code: otp,
      authType: "email",
    };

    if (otp.length >= 6) {
      setShowError(false);
      dispatch(loader(1));
      AuthService.login(emailPayload)
        .then((data) => {
          if (data.data.message === "success") {
            history.push("/app/overview");
            dispatch(storeLoginOtpAction(""));
            dispatch({type: HOLD_USER_DETAILS, payload: {}});
          } else {
            setError(true);
            dispatch(storeLoginOtpAction("Invalid code or code expired"));
            dispatch(loader(2));
          }
        })
        .catch(() => {
          dispatch(storeLoginOtpAction("Invalid code or code expired"));
          toast("Something went wrong");
          dispatch(loader(2));
        });
    } else {
      setShowError(true);
    }
  };

  const emailFirstFive = storeLoginDetailsState?.hasOwnProperty("email")
    ? storeLoginDetailsState.email.slice(0, 5)
    : "";
  const emailLastNine = storeLoginDetailsState?.hasOwnProperty("email") ? storeLoginDetailsState.email.slice(-9) : "";

  return (
    <>
      <div className="login-container">
        <MobileNavBar />
        <div className="login-content">
          <div className="form-contents">
            <>
              <p className="title">Security verification</p>
              <p className="tag">Please type in the code received in your email</p>
            </>

            <div style={otpContainer}>
              <div style={{margin: "3rem 0 0.5rem"}}>
                <p className="title" style={{fontSize: "1.6rem"}}>
                  Email verification code
                </p>
              </div>

              <div>
                {showError ? (
                  <p
                    style={{
                      color: "#F04438",
                      textAlign: "left",
                      marginTop: "0px",
                      width: "100%",
                      fontSize: "1.4rem",
                      fontWeight: 600,
                    }}
                  >
                    Enter OTP
                  </p>
                ) : null}

                {!error === true ? (
                  <p
                    style={{
                      color: "#F04438",
                      textAlign: "left",
                      marginTop: "0px",
                      width: "100%",
                      fontSize: "1.4rem",
                      fontWeight: 600,
                    }}
                  >
                    {storeLoginOtpState}
                  </p>
                ) : null}

                <LoginOTPInput value={otp} onChange={handleChange} error={storeLoginOtpState} />
              </div>

              <p className="tag" style={{marginTop: "0px", fontSize: "13px"}}>
                We sent a code to {`${emailFirstFive}****${emailLastNine}`}
              </p>
            </div>

            <button className="buttonStyle" onClick={goToHome} style={{marginTop: "2rem"}}>
              Confirm
            </button>
            <button className="buttonStyle" style={goBackStyle} onClick={goBack}>
              Go back
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

const otpContainer = {
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
  width: "100%",
};

const goBackStyle = {
  width: "100%",
  background: "#fff",
  color: "black",
  fontSize: "1.4rem",
  borderRadius: "7.31px",
  boxShadow: "none",
  textTransform: "capitalize",
  marginTop: "25px",
  border: "1px solid #D0D5DD",

  "&:hover": {
    backgroundColor: "#F9F9F9",
    boxShadow: "none",
  },
};

export default memo(LoginOTP);
