import React, {memo} from "react";
import DepostiteIcon from "../../assets/icons/deposite.svg";
import WithdrawalIcon from "../../assets/icons/withdrawal.svg";
import NotFoundlIcon from "../../assets/icons/notfound.svg";
import "../../styles/overviewStyles/overViewTractionHistory.scss";
import {useSelector} from "react-redux";
import moment from "moment";
import {useHistory} from "react-router-dom";

const OverViewTractionHistory = () => {
  const history = useHistory();
  const transactionHistory = useSelector((state) => state.walletReducer.transactionHistory);

  return (
    <div className="overView__extensionFirstLayout">
      <div className="overView__extensionFirstLayoutAsHeader">
        <p className="overView__extensionFirstLayoutAsHeaderOne">Recent Transactions</p>
        <button onClick={() => history.push("/app/transaction")} className="overView__extensionFirstLayoutAsHeaderTwo">
          View All
        </button>
      </div>

      <div className="overView__extensionFirstLayoutListWrapper">
        {transactionHistory?.length === 0 && (
          <div className="overView__extensionFirstLayoutListEmpty">
            <img src={NotFoundlIcon} alt={"not found"} />
            <p>You have no recent transactions</p>
          </div>
        )}

        {transactionHistory?.length !== 0 &&
          transactionHistory?.slice(0, 5)?.map((item, key) => (
            <div key={key} className="overView__extensionFirstLayoutList">
              <div className="overView__extensionFirstLayoutListSectionOne">
                <div className="overView__extensionFirstLayoutListSectionOneIconBody">
                  {item.type === "deposit" ? (
                    <img src={DepostiteIcon} alt={"icons"} />
                  ) : (
                    <img src={WithdrawalIcon} alt={"icons"} />
                  )}
                </div>
                <div className="overView__extensionFirstLayoutListSectionOneDetails">
                  <h3>{item?.type}</h3>
                  <div className="overView__extensionFirstLayoutListSectionOneDetailsDateTime">
                    <p className="overView__extensionFirstLayoutListSectionOneDetailsDate">
                      {moment(item?.timeStamp).format("YYYY-MM-DD mm:ss")}
                    </p>
                    {/* <p className="overView__extensionFirstLayoutListSectionOneDetailsTime">09:31</p> */}
                  </div>
                </div>
              </div>

              <div className="overView__extensionFirstLayoutListSectionTwo">
                <div className="overView__extensionFirstLayoutListSectionTwoDetials">
                  <p className="overView__extensionFirstLayoutListSectionTwoDetailsAmount">{`${
                    item?.type === "deposit" ? "+" : "-"
                  }${item?.amount}`}</p>
                  <p className="overView__extensionFirstLayoutListSectionTwoDetialsToken">{item?.tokenSymbol}</p>
                </div>

                {item?.status?.toLowerCase() === "failed" ? (
                  <div className="overView__extensionFirstLayoutListSectionTwoStories">
                    <p style={{color: "#F04438"}}>{item?.status}</p>
                  </div>
                ) : item?.status?.toLowerCase() === "pending" ? (
                  <div className="overView__extensionFirstLayoutListSectionTwoStories">
                    <p style={{color: "#F2B339"}}>{item?.status}</p>
                  </div>
                ) : (
                  <div className="overView__extensionFirstLayoutListSectionTwoStories">
                    <p>{item?.status}</p>
                  </div>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default memo(OverViewTractionHistory);
