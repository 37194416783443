import {useState, useRef, useEffect, useMemo} from "react";
import {BiRefresh} from "react-icons/bi";
import {FiFilter, FiSearch} from "react-icons/fi";
import {BNB, BTC, ETH, USDT} from "../../../hooks/imagePath";
import useWindowDimensions from "../../../hooks/useWindowDimension";
import {useContext} from "react";
import {BottomSheet} from "react-spring-bottom-sheet";
import MobileFilterModal from "./mobileFilterModal";
import {useSelector} from "react-redux";
import {CountryIcons} from "../../Common/CountryIcons";
import {useDispatch} from "react-redux";
import {getMarketPlaceBuyAndSellAction, marketPlaceDefaultPageAction} from "../../../store/actions/market";
import {useHistory} from "react-router-dom";
import {
  CHANGE_MARKET_PLACE_STATE,
  GET_MARKET_PLACE_BUY_SELL,
  MARKET_SKIP_SYSTEM,
  PAGE_EMPTY_CHECKER,
} from "../../../store/types";
import CurrencyFormat from "react-currency-format";
import {SlRefresh} from "react-icons/sl";
import MarketSearchableDropDown from "../../Common/MarketPlaceSearchableDropDown/MarketSearchableDropDown";
import {ReCreatedTransactionStaticDropDown} from "../../Common/ReCreatedtransactionStaticDropdown";

const TheWholeFilterArea = () => {
  const {width} = useWindowDimensions();
  const {push} = useHistory();
  const dispatch = useDispatch();
  const sheetRef = useRef();

  const inputRef = useRef();
  const amountInput = useRef();

  const timeout = useRef();

  const [mobileFilterModal, setMobileFilterModal] = useState(false);

  const {countryData, paymentMethod} = useSelector((state) => state.generalReducer);

  const marketPlaceData = useSelector((state) => state.marketStateReducer);

  useMemo(() => marketPlaceData, [marketPlaceData]);

  const {limit, fskip} = useSelector((state) => state.marketReducer);

  const thePaymentMethod = paymentMethod?.data?.paymentsData?.paymentMethod?.map((item) => ({
    attr: "paymentMethod",
    value: item.name,
    label: item.name,
  }));

  const [paymentMethodValue, setPaymentMethodValue] = useState({});

  const [tokenMethodValue, setTokenMethodValue] = useState({});

  const [subTokenMehtodValue, setSubTokenMethodValue] = useState({});

  const [countryStateValue, setCountryStateValue] = useState({});

  useEffect(() => {
    if (Object.keys(countryData).length > 0) {
      let dd = countryData?.data?.countriesData.find((item) => item.countryName === marketPlaceData.country);
      setCountryStateValue({...dd, name: dd?.countryName, nameAcr: dd?.alpha3});

      setTokenMethodValue({
        attr: "token",
        value: marketPlaceData?.token,
        label: (
          <span className="flex items__center ">
            <img
              src={
                marketPlaceData?.token === "BTC"
                  ? BTC
                  : marketPlaceData?.token === "USDT"
                  ? USDT
                  : marketPlaceData?.token === "BNB"
                  ? BNB
                  : marketPlaceData?.token === "ETH" && ETH
              }
              width="25"
              alt="token"
              className="mr-1"
            />

            <p>{marketPlaceData?.token}</p>
          </span>
        ),
      });

      setPaymentMethodValue({
        attr: "paymentMethod",
        value:
          marketPlaceData?.paymentMethod === null || marketPlaceData?.paymentMethod === ""
            ? "select method"
            : marketPlaceData?.paymentMethod,
        label:
          marketPlaceData?.paymentMethod === null || marketPlaceData?.paymentMethod === ""
            ? "select method"
            : marketPlaceData?.paymentMethod,
      });

      setSubTokenMethodValue({attr: "token", value: marketPlaceData.token, label: marketPlaceData.token});
    }
  }, [marketPlaceData, countryData]);

  const onUpdate = () => {
    dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: limit, skip: 0}));
    dispatch(marketPlaceDefaultPageAction());
  };

  return (
    <>
      <div className="__marketPlaceSwitchWrapper">
        <div className="__theSwitch">
          <div
            className={`__marketBuy ${marketPlaceData.type}`}
            onClick={() => {
              if (width <= 700) {
                dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});
                dispatch({type: PAGE_EMPTY_CHECKER, payload: null});

                dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "buy"}});

                // dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: fskip, type: "buy"}));

                push(
                  `/app/marketplace/${marketPlaceData?.country}/${marketPlaceData?.token}/buy/${marketPlaceData?.paymentMethod}`
                );
              } else {
                dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});
                dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "buy"}});

                // dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: 0, type: "buy"}));
                dispatch({type: PAGE_EMPTY_CHECKER, payload: null});
                push(
                  `/app/marketplace/${marketPlaceData?.country}/${marketPlaceData?.token}/buy/${marketPlaceData?.paymentMethod}`
                );
              }
            }}
          >
            Buy
          </div>
          <div
            className={`__marketSell ${marketPlaceData.type}`}
            onClick={() => {
              if (width <= 700) {
                dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});

                dispatch({type: PAGE_EMPTY_CHECKER, payload: null});

                dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "sell"}});

                // dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: 0, type: "sell"}));

                push(
                  `/app/marketplace/${marketPlaceData?.country}/${marketPlaceData?.token}/sell/${marketPlaceData?.paymentMethod}`
                );
              } else {
                dispatch({type: GET_MARKET_PLACE_BUY_SELL, payload: []});

                dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {...marketPlaceData, type: "sell"}});

                dispatch({type: PAGE_EMPTY_CHECKER, payload: null});
                // dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, limit, skip: fskip, type: "sell"}));
                push(
                  `/app/marketplace/${marketPlaceData?.country}/${marketPlaceData?.token}/sell/${marketPlaceData?.paymentMethod}`
                );
              }
            }}
          >
            Sell
          </div>
        </div>

        {width <= 700 && (
          <div className="__mobile__filterSpace">
            <div className="__refresh">
              <BiRefresh size={24} />
            </div>
            <div className="__filter">
              <FiFilter size={22} onClick={() => setMobileFilterModal(true)} />
            </div>
          </div>
        )}
      </div>

      {width >= 700 ? (
        <div className="__filterAreaWrapper">
          <div className="__selectcoinwrapper">
            <p className="__label">Coin Type</p>

            <ReCreatedTransactionStaticDropDown
              options={[
                {
                  attr: "token",
                  value: "BTC",
                  label: (
                    <span className="flex items__center ">
                      <img src={BTC} width="25" alt="btc" className="mr-1" />
                      <p>BTC</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "ETH",
                  label: (
                    <span className="flex items__center __theicons">
                      <img src={ETH} width="25" alt="btc" className="mr-1" />
                      <p>ETH</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "USDT",
                  label: (
                    <span className="flex items__center ">
                      <img src={USDT} width="25" alt="btc" className="mr-1" />
                      <p>USDT</p>
                    </span>
                  ),
                },
                {
                  attr: "token",
                  value: "BNB",
                  label: (
                    <span className="flex items__center ">
                      <img src={BNB} width="25" alt="btc" className="mr-1" />
                      <p>BNB</p>
                    </span>
                  ),
                },
              ]}
              value={tokenMethodValue}
              setContent={setTokenMethodValue}
              dropdownOnChangeFunction={(item) => {
                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {token: item.token}});
                push(
                  `/app/marketplace/${marketPlaceData?.country}/${item.token}/${marketPlaceData?.type}/${marketPlaceData?.paymentMethod}`
                );
              }}
              name={"Coin"}
              useLabel={true}
              style={{margin: 0, width: "100%"}}
            />
          </div>

          <div className="__selectableWrapper">
            <p className="__label">Amount</p>
            <div className="___theamountandcurrency">
              <CurrencyFormat
                decimalScale={2}
                fixedDecimalScale
                className={"__selectable__input"}
                placeholder="100"
                onChange={(e) => {
                  if (e.target.value) {
                    clearTimeout(timeout.current);
                    dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});
                    timeout.current = setTimeout(() => {
                      dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {amount: e.target.value}});
                      dispatch(
                        getMarketPlaceBuyAndSellAction({
                          ...marketPlaceData,
                          limit: limit,
                          skip: fskip,
                          amount: e.target.value,
                        })
                      );
                    }, 300);
                  } else {
                    delete marketPlaceData.amount;
                    dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {amount: null}});

                    dispatch(
                      getMarketPlaceBuyAndSellAction({
                        ...marketPlaceData,
                        limit: limit,
                        skip: fskip,
                      })
                    );
                  }
                }}
              />
              <div className="flex" style={{alignItems: "center"}}>
                <p style={{fontSize: "1.4rem"}}>{countryStateValue?.currencyCode}</p>
              </div>
            </div>
          </div>

          <div className="__selectableWrapper" style={{width: "235px"}}>
            <p className="__label">Payment Method</p>

            <ReCreatedTransactionStaticDropDown
              options={thePaymentMethod}
              value={paymentMethodValue}
              setContent={setPaymentMethodValue}
              dropdownOnChangeFunction={(item) => {
                dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {paymentMethod: item.paymentMethod}});

                push(
                  `/app/marketplace/${marketPlaceData?.country}/${marketPlaceData?.token}/${marketPlaceData?.type}/${item.paymentMethod}`
                );
              }}
              name={"paymentMethod"}
              useLabel={true}
              style={{margin: 0}}
            />
          </div>

          <div className="__selectableWrapper" style={{width: "300px"}}>
            <p className="__label">Select Country</p>
            <div>
              <MarketSearchableDropDown
                name={"countryName"}
                iconsLead={true}
                nameAcr={"alpha3"}
                DetailsIcons={CountryIcons}
                search={countryStateValue && countryStateValue}
                setSearch={setCountryStateValue}
                placeholder={"Please Search for a country"}
                HandleSelect={(val) => {
                  dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {country: val.name}});

                  push(
                    `/app/marketplace/${val.countryName}/${marketPlaceData.token}/${marketPlaceData?.type}/${marketPlaceData.paymentMethod}`
                  );

                  dispatch({type: MARKET_SKIP_SYSTEM, payload: 0});

                  dispatch(
                    getMarketPlaceBuyAndSellAction({...marketPlaceData, limit: limit, skip: fskip, country: val.name})
                  );

                  setCountryStateValue({
                    ...val,
                    name: val.countryName,
                    nameAcr: val.alpha3,
                    currencyCode: val.currencyCode,
                  });
                }}
                apiList={countryData?.data?.countriesData}
                style={{
                  // margin: 0,
                  borderRadius: "20px",
                  border: "1px solid #eff0f6",
                  boxShadow: "none",
                  padding: "0 15px",
                  width: "100%",
                }}
              />
            </div>
          </div>

          <div className="__updateButtonWrapper">
            <div className="__marketFilterUpdateButton" onClick={onUpdate}>
              <SlRefresh style={{fontSize: "18px"}} />
              <span>Refresh</span>
            </div>
          </div>
        </div>
      ) : (
        <div className="__selectcoinwrapper" style={{marginTop: "30px", padding: "0 10px"}}>
          <ReCreatedTransactionStaticDropDown
            options={[
              {
                attr: "token",
                value: "BTC",
                label: (
                  <span className="flex items__center ">
                    <img src={BTC} width="25" alt="btc" className="mr-1" />
                    <p>BTC</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "ETH",
                label: (
                  <span className="flex items__center ">
                    <img src={ETH} width="25" alt="btc" className="mr-1" />
                    <p>ETH</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "USDT",
                label: (
                  <span className="flex items__center ">
                    <img src={USDT} width="25" alt="btc" className="mr-1" />
                    <p>USDT</p>
                  </span>
                ),
              },
              {
                attr: "token",
                value: "BNB",
                label: (
                  <span className="flex items__center ">
                    <img src={BNB} width="25" alt="btc" className="mr-1" />
                    <p>BNB</p>
                  </span>
                ),
              },
            ]}
            value={subTokenMehtodValue}
            setContent={setSubTokenMethodValue}
            dropdownOnChangeFunction={(item) => {
              dispatch({type: CHANGE_MARKET_PLACE_STATE, payload: {token: item.token}});
              push(
                `/app/marketplace/${marketPlaceData?.country}/${item.token}/${marketPlaceData?.type}/${marketPlaceData?.paymentMethod}`
              );

              dispatch(getMarketPlaceBuyAndSellAction({...marketPlaceData, token: item.token, limit: limit, skip: 0}));
            }}
            name={"Coin"}
            style={{margin: 0}}
          />
        </div>
      )}

      {/* <WhiteMobileModal2 open={mobileFilterModal} setOpen={setMobileFilterModal}>
        <MobileFilterModal setOpen={setMobileFilterModal} />
      </WhiteMobileModal2> */}

      <BottomSheet
        ref={sheetRef}
        open={mobileFilterModal}
        onDismiss={() => setMobileFilterModal(false)}
        // defaultSnap={({maxHeight}) => maxHeight * 0.6}
        // snapPoints={({maxHeight}) => [maxHeight - maxHeight / 10, maxHeight / 4, maxHeight * 0.6]}
        header={
          <h3
            style={{
              textAlign: "center",
              fontSize: "23px",
              fontWeight: 600,
              padding: "10px 0 10px 0",
            }}
          >
            Filter
          </h3>
        }
        footer={
          <div className="__button__wrapper">
            <button className="__cancel__button" onClick={() => setMobileFilterModal(false)}>
              Cancel
            </button>

            <button
              className="__buy__buttonn"
              onClick={() => {
                onUpdate();
                setMobileFilterModal(false);
              }}
            >
              Confirm
            </button>
          </div>
        }
      >
        <MobileFilterModal countryStateValue={countryStateValue} setCountryStateValue={setCountryStateValue} />
      </BottomSheet>
    </>
  );
};

export {TheWholeFilterArea};
