import QRCode from "react-qr-code";

import beginYourJourney from "../../assets/images/download-page-image.svg";
import "../../styles/landing-page-styles/download-component-styles.scss";

// import qrscan from "../../assets/images/QRcode.svg";
import apple from "../../assets/images/appstore-black.svg";
// import apple from "../../assets/images/coming-soon-ios.svg";
import google from "../../assets/images/googleplay-black.svg";

// import {isAndroid, isIOS} from "react-device-detect";

const DownloadComponent = () => {
  // const getQRCodeContent = () => {
  //   if (isIOS) {
  //     return "https://ios-link.com";
  //   } else if (isAndroid) {
  //     return "https://android-link.com";
  //   }
  //   // You can add a default URL for other devices or use another method to determine the URL.
  // };

  // let platform = isAndroid ? "https://ios-link.com" : "https://android-link.com";
  // console.log(platform, "platforms");
  // play.google.com/store/apps/details?id=com.myoyolamobile.app

  return (
    <main className="homepageContainerDownload">
      <div className="innerContainer">
        <div className="beginStyle">
          <div className="containerRight">
            <h1 className="title">Trade Anywhere, anytime with the Oyola app.</h1>
            <p className="tag">Download App Now!</p>

            <div className="scanContainer">
              <div className="scanImageContainer">
                {/* <QRCode value={"play.google.com/store/apps/details?id=com.myoyolamobile.app"} className="imgStyle" /> */}
                {/* <QRCode value={"https://dashboard-oyola.onrender.com/mobile-download"} className="imgStyle" /> */}
                {process.env.REACT_APP_ENV === "PRODUCTION" ? (
                  <QRCode value={"https://oyola.io/mobile-download"} className="imgStyle" />
                ) : (
                  <QRCode value={"https://dashboard-oyola.onrender.com/mobile-download"} className="imgStyle" />
                )}
              </div>
              {/* <img src={qrscan} alt="apple" className="imgStyle" /> */}
              <div>
                <p className="titleSty">Scan to Download the App</p>
                {/* <p className="descSty">Android</p> */}
                <p className="descSty">iOS and Android</p>
              </div>
            </div>

            {/*  */}

            <div className="signUpContainer">
              <a
                href="https://apps.apple.com/ng/app/oyola/id6470178672?platform=iphone"
                target="_blank"
                rel="noreferrer"
                className="imageDownloadStyle"
              >
                <img src={apple} alt="apple" height={36} />
              </a>

              <a
                href="https://play.google.com/store/apps/details?id=com.myoyolamobile.app"
                target="_blank"
                rel="noreferrer"
                className="imageDownloadStyle"
              >
                <img src={google} alt="apple" height={36} />
              </a>
            </div>
          </div>

          <div className="containerLeft">
            <img src={beginYourJourney} alt="phone wallet" className="imageContainer" />
          </div>
        </div>
      </div>
    </main>
  );
};

export default DownloadComponent;
