import React, {memo} from "react";
import OverviewAds from "./overviewAds";
import OverViewTractionHistory from "./overViewTractionHistory";
import "../../styles/overviewStyles/overViewOtherSection.scss";

const OverViewOtherSection = () => {
  return (
    <div className="OverViewOtherSection">
      <div className="OverViewOtherSectionFirstSection">
        <OverViewTractionHistory />
      </div>
      <div className="OverViewOtherSectionSecondSection">
        <OverviewAds />
      </div>
    </div>
  );
};

export default memo(OverViewOtherSection);
