import {useEffect, useMemo} from "react";
import {useState} from "react";
import {memo} from "react";
import {useDispatch} from "react-redux";
import GeneralService from "../../services/general";
import WalletService from "../../services/walletService";
import {getTokenDetails, getUser} from "../../store/actions/general";
import {getModalChecker, getScallingValue, getWalletBalance, getWalletOtpError} from "../../store/actions/walletAction";
import {CURRENT_VALUE_BANLANCE_STATE} from "../../store/types";
import {Loader} from "../Common/Loader";
import WalletComponents from "./walletComponents";
import {formatBigNumber} from "../../utils/bigNumberFormatter";

const WalletPage = () => {
  const dispatch = useDispatch();

  const userbanlanceDetails = JSON.parse(localStorage.getItem("userDataBalance"));
  const [loading, setLoading] = useState(true);

  const LunchUserDetails = async () => {
    setLoading(true);

    if (userbanlanceDetails?.userbanlanceDetails?.message === "success") {
      dispatch(getModalChecker({}));
      dispatch(getWalletOtpError(""));

      const constants = await Promise.all([
        GeneralService.getUserData(),
        GeneralService.getTokenServices(),
        WalletService.UserTotalBalance(),
        WalletService.UserTotalBalanceScalling(
          userbanlanceDetails?.userbanlanceDetails?.currentUserBalances,
          userbanlanceDetails?.userbanlanceDetails?.currency,
          userbanlanceDetails?.userbanlanceDetails?.userValueInBTC
        ),
      ]).then((data) => {
        return data;
      });

      const WalletServices = async () => {
        const [userData, tokens] = constants;

        const tokenData = [...tokens.tokens];

        for (let item of tokenData) {
          userData.data.userData.tokens.forEach((data) => {
            if (item?.tokenSymbol?.toLowerCase() === data?.tokenSymbol?.toLowerCase()) {
              item["tokenBalance"] = formatBigNumber(data?.balance);
            }
          });

          for (let x in constants[0].data.userData) {
            if (x === "walletAddressBTC" && item?.tokenSymbol?.toLowerCase() === "btc") {
              item["tokenId"] = constants[0]?.data?.userData[x];
            }

            if (
              x === "walletAddressETH" &&
              (item?.tokenSymbol?.toLowerCase() === "eth" || item?.tokenSymbol?.toLowerCase() === "bnb")
            ) {
              item["tokenId"] = constants[0].data.userData[x];
            }

            if (x === "walletAddressTRON" && item?.tokenSymbol?.toLowerCase() === "usdt") {
              item["tokenId"] = constants[0].data.userData[x];
            }
          }

          for (let x in constants[2]?.tokensValueData[0]) {
            if (item.tokenSymbol?.toUpperCase() === x.slice(0, -5)?.toUpperCase()) {
              item["quantityInBTC"] =
                formatBigNumber(constants[2]?.tokensValueData[0][x]) / formatBigNumber(constants[2]?.unitBTC);
              item["quantityInLocalCurrency"] = formatBigNumber(constants[2]?.tokensValueData[0][x]);
            }
          }

          const unitSystem = await WalletService.GetLocalCurrencyValue(item.tokenSymbol, constants[2].currency);

          const index = tokenData.findIndex((data) => item.tokenSymbol === data.tokenSymbol);

          tokenData[index] = {...tokenData[index], unitPrice: formatBigNumber(unitSystem.currencyValue)};
        }

        return tokenData;
      };

      const handle = await WalletServices();

      dispatch(getUser(constants[0] ?? {}));
      dispatch(getTokenDetails(handle));
      dispatch(getWalletBalance(constants[2]));
      dispatch(getScallingValue(constants[3]));
      dispatch({type: CURRENT_VALUE_BANLANCE_STATE, payload: constants[2].currentUserBalances});
      setLoading(false);
    }
  };

  const LunchUserDetailsSection = async (userlocalBanlanceD) => {
    if (userlocalBanlanceD.userbanlanceDetails.message === "success") {
      const constants = await Promise.all([
        GeneralService.getUserData(),
        GeneralService.getTokenServices(),
        WalletService.UserTotalBalance(),
        WalletService.UserTotalBalanceScalling(
          userlocalBanlanceD?.userbanlanceDetails?.currentUserBalances,
          userlocalBanlanceD?.userbanlanceDetails?.currency,
          userlocalBanlanceD?.userbanlanceDetails?.userValueInBTC
        ),
      ]).then((data) => {
        return data;
      });

      const WalletServices = async () => {
        const [userData, tokens] = constants;

        const tokenData = [...tokens.tokens];

        for (let item of tokenData) {
          userData.data.userData.tokens.forEach((data) => {
            if (item?.tokenSymbol?.toLowerCase() === data?.tokenSymbol?.toLowerCase()) {
              item["tokenBalance"] = data?.balance;
            }
          });

          for (let x in constants[0].data.userData) {
            if (x === "walletAddressBTC" && item?.tokenSymbol?.toLowerCase() === "btc") {
              item["tokenId"] = constants[0].data.userData[x];
            }

            if (
              x === "walletAddressETH" &&
              (item?.tokenSymbol?.toLowerCase() === "eth" || item?.tokenSymbol?.toLowerCase() === "bnb")
            ) {
              item["tokenId"] = constants[0].data.userData[x];
            }

            if (x === "walletAddressTRON" && item?.tokenSymbol?.toLowerCase() === "usdt") {
              item["tokenId"] = constants[0].data.userData[x];
            }
          }

          for (let x in constants[2]?.tokensValueData[0]) {
            if (item.tokenSymbol?.toUpperCase() === x.slice(0, -5)?.toUpperCase()) {
              item["quantityInBTC"] = constants[2]?.tokensValueData[0][x] / constants[2]?.unitBTC;
              item["quantityInLocalCurrency"] = constants[2]?.tokensValueData[0][x];
            }
          }

          const unitSystem = await WalletService.GetLocalCurrencyValue(item.tokenSymbol, constants[2].currency);

          const index = tokenData.findIndex((data) => item.tokenSymbol === data.tokenSymbol);

          tokenData[index] = {...tokenData[index], unitPrice: unitSystem.currencyValue};
        }

        return tokenData;
      };

      const handle = await WalletServices();

      dispatch(getUser(constants[0] ?? {}));
      dispatch(getTokenDetails(handle));
      dispatch(getWalletBalance(constants[2]));
      dispatch(getScallingValue(constants[3]));
      dispatch({type: CURRENT_VALUE_BANLANCE_STATE, payload: constants[2].currentUserBalances});
      setLoading(false);
    }
  };

  useEffect(() => {
    LunchUserDetails();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      if (userbanlanceDetails?.userbanlanceDetails !== undefined) {
        LunchUserDetailsSection(userbanlanceDetails);
      } else {
        const userbanlanceDetailsD = JSON.parse(localStorage.getItem("userDataBalance"));

        LunchUserDetailsSection(userbanlanceDetailsD);
      }
    }, 20000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      {!loading && <WalletComponents />}
    </>
  );
};

export default memo(WalletPage);
