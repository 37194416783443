import React from "react";
import {memo} from "react";
import "../../styles/overviewStyles/overview.scss";
import OverviewMainSection from "./overviewMainSection";
import OverViewOtherSection from "./overViewOtherSection";

const OverviewComponent = () => {
  return (
    <div className="overView-Wrapper">
      <div className="OverviewMainTopSectionFirstLayoutHeader">
        <h3>Wallet Overview</h3>
      </div>
      <div className="overView-mainWrapper">
        <div className="overView-mainWrapperFirstLayout">
          <OverviewMainSection />
        </div>
        <div className="overView-mainWrapperSecondLayout">
          <OverViewOtherSection />
        </div>
      </div>
    </div>
  );
};

export default memo(OverviewComponent);
