import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getSelectedCountryAction} from "../../store/actions/settingAction";
import {CountryIcons} from "../Common/CountryIcons";
import SearchableCountryDropDown from "../Common/SearchablePhoneDropdown/CountrySearchDropDown";

const AddressCountry = () => {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const modalInfo = useSelector((state) => state.generalReducer.userData);

  // const defaultCurrenctCountry = useSelector((state) => state.settingsReducer.defaultCurrenctCountry);
  const [countryStateValue, setCountryStateValue] = useState({
    countryName: modalInfo?.data?.userData?.userCountry ?? "Please select a country",
  });
  // const [countryStateValue, setCountryStateValue] = useState({
  //   countryName: modalInfo?.data?.userData?.userCountry ?? "Please select a country",
  // });

  const HandleClickSearch = (item) => {
    setCountryStateValue({
      ...item,
      name: item.countryName,
    });

    // dispatch(getAddressCountryAction(item.countryName));
    dispatch(getSelectedCountryAction(item.countryName));
  };

  return (
    <div style={{width: "100%"}}>
      <SearchableCountryDropDown
        title={`For online trade, you need to specify the country`}
        iconsLead={true}
        name={"countryName"}
        nameAcr={"currencyCode"}
        code={"currencyCode"}
        DetailsIcons={CountryIcons}
        search={countryStateValue}
        setSearch={setCountryStateValue}
        placeholder={"Please Search for a country"}
        HandleSelect={HandleClickSearch}
        apiList={countryData?.data?.countriesData}
        stated={true}
        text={true}
      />
    </div>
  );
};

export default memo(AddressCountry);
