import React from "react";
import "./modal.scss";

const ShowCurrencyModal = React.forwardRef(({data, setData, close}, ref) => {
  return (
    <div className="navDropContainer" ref={ref}>
      {data?.map(({countryName, currencyCode}, index) => (
        <li
          key={index}
          className="listMenuIcon"
          onClick={() => {
            setData([countryName, currencyCode]);
            close(false);
          }}
        >
          <div style={{display: "flex", gap: "1rem", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{display: "flex", justifyContent: "space-between", width: "100%", fontSize: "300"}}>
              <p style={{color: "rgba(0, 0, 0, 0.7)"}}>{countryName}</p>
              <p style={{color: "rgba(0, 0, 0, 0.7)"}}>{currencyCode}</p>
            </div>
          </div>
        </li>
      ))}
    </div>
  );
});

export default ShowCurrencyModal;
