import {useRef, useState} from "react";
import CurrencyFormat from "react-currency-format";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";

import ShowPaymentMethodModal from "./modal/show-payment-method-modal.component";
import ShowCoinModal from "./modal/coin-modal.component";
import ShowCurrencyModal from "./modal/currency-modal.component";

import arrows from "../../assets/icons/arrow-drop-down.svg";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import "../../styles/landing-page-styles/homepage.scss";
import "../../styles/landing-page-styles/button.scss";
import {CoinIcons} from "../Common/coinSwitcher";

const TradeCard = ({type}) => {
  const {getSelectedCoinData, getSelectedPaymentData, getSelectedCountryData, getSelecteGeoLocationData} = useSelector(
    (state) => state.LandingReducer
  );

  const ref = useRef();
  const {push} = useHistory();
  useOnClickOutside(ref, () => setShowModalCoin(false));
  useOnClickOutside(ref, () => setShowModalPayment(false));
  useOnClickOutside(ref, () => setShowModalCurrency(false));

  const [inputValue, setInputValue] = useState("");
  const [showModalCoin, setShowModalCoin] = useState(false);
  const [showModalPayment, setShowModalPayment] = useState(false);
  const [showModalCurrency, setShowModalCurrency] = useState(false);
  const [getCoin, setGetCoin] = useState("");
  const [getPaymentMethod, setPaymentMethod] = useState("");
  const [getCurrencyData, setGetCurrencyData] = useState("");

  return (
    <>
      <div className="optionsContainer">
        <div className="overViewContainer">
          <p className="title">Coin Type</p>
          <div className="dropContainer" onClick={() => setShowModalCoin(!showModalCoin)}>
            <div style={{display: "flex", alignItems: "center", gap: "2rem", cursor: "pointer"}}>
              <CoinIcons intialValue={getCoin !== "" ? getCoin : "BTC"} />
              <p className="title" style={{color: "rgba(0, 0, 0, 0.7)"}}>
                {getCoin !== "" ? getCoin : "BTC"}
              </p>
            </div>
            <img src={arrows} alt="icons" />
            {showModalCoin && <ShowCoinModal data={getSelectedCoinData?.tokens} setData={setGetCoin} ref={ref} />}
          </div>
        </div>

        <div className="overViewContainer">
          <p className="title">I want to pay with</p>
          <div
            className="dropContainer"
            // style={{width: "70%", cursor: "pointer", height: "50px"}}
            onClick={() => setShowModalPayment(!showModalPayment)}
          >
            <p className="title" style={{color: "rgba(0, 0, 0, 0.7)"}}>
              {getPaymentMethod !== "" ? getPaymentMethod : "Bank Transfer"}
            </p>
            <img src={arrows} alt="icons" />
            {showModalPayment && (
              <ShowPaymentMethodModal
                data={getSelectedPaymentData?.paymentMethod}
                setData={setPaymentMethod}
                ref={ref}
              />
            )}
          </div>
        </div>

        <div className="overViewContainer">
          <p className="title">I want to pay</p>
          <div className="dropContainer">
            <CurrencyFormat
              placeholder="0.00"
              value={inputValue}
              thousandSeparator={true}
              className="inputField"
              style={{fontSize: "1.6rem"}}
              onChange={(e) => setInputValue(e.target.value)}
            />
            <div style={drop} onClick={() => setShowModalCurrency(!showModalCurrency)}>
              <p className="title" style={{color: "rgba(0, 0, 0, 0.7)"}}>
                {getCurrencyData !== "" ? getCurrencyData[1] : "USD"}
              </p>
              <img src={arrows} alt="icons" />
            </div>
            {showModalCurrency && (
              <ShowCurrencyModal
                data={getSelectedCountryData ?? ""}
                setData={setGetCurrencyData}
                ref={ref}
                close={setShowModalCurrency}
              />
            )}
          </div>
        </div>
      </div>

      <button
        style={{cursor: "pointer"}}
        className="baseButton primaryButtonLargeNew"
        onClick={() => {
          push(
            `/marketplace/${getCurrencyData !== "" ? getCurrencyData[0] : getSelecteGeoLocationData?.countryName}/${
              getCoin !== "" ? getCoin : "BTC"
            }/${type === 0 ? "buy" : "sell"}/${
              getPaymentMethod !== "" ? getPaymentMethod : "Bank Transfer"
            }/${inputValue}`
          );
        }}
      >
        Explore offers
      </button>
    </>
  );
};

const drop = {
  display: "flex",
  alignItems: "center",
  gap: "1rem",
  cursor: "pointer",
  height: "100%",
  borderLeft: "1.5px solid #EFF0F6",
  padding: "0 0.5rem",
  marginRight: "1rem",
};

export default TradeCard;
