import PostTradeButton from "./PostTradeButton";
import {memo} from "react";
import "../../styles/postTradeStyles/postTradeStryles.scss";
import PostTradeFirstSection from "./postTradeFirstSection";
import PostTradeSecondSection from "./postTradeSecondSection";
import PostTradeThirdSection from "./postTradeThirdSection";
import PostTradeFourthSection from "./postTradeFourthSection";
import {BiInfoCircle} from "react-icons/bi";
import {useSelector} from "react-redux";

const PostTradeComponent = () => {
  const userData = useSelector((state) => state.generalReducer.userData);

  const sellData = useSelector((state) => state.postTradeReducer.sellData);

  return (
    <>
      <div className="postTradeWrapper">
        <div className="ParentButton">
          <PostTradeButton title={"Post Trade"} />

          {userData?.data?.userData?.isVerified === false &&
            sellData?.name === "sell" &&
            sellData?.checker === true && (
              <div className="verify_indicator">
                <div className="verify_indicator_icon_body">
                  <BiInfoCircle className="verify_indicator_icon" />
                </div>

                <p className="verify_indicator_text">Verify account to sell crypto</p>
              </div>
            )}
        </div>

        <div className="postTrade__Container">
          <PostTradeFirstSection />
          <PostTradeSecondSection />
          <div className="postTrade__Increment">
            <PostTradeButton title={"More Information"} />
          </div>
          <div>
            <PostTradeThirdSection />

            <PostTradeFourthSection />
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(PostTradeComponent);
