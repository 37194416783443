import moment from "moment";
import {
  GET_DEPOSIT_WITHDRAW_TRANSACTION_SUCCESS,
  GET_DEPOSIT_WITHDRAW_TRANSACTION_ERROR,
  GET_MESSAGES,
  GET_MESSAGES_INITIAL,
  GET_MESSAGES_WITHOUT_LOADING,
} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
};

const chatMessageReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case GET_MESSAGES: {
      return {
        ...state,
        response: payload,
        loading: false,
      };
    }
    case GET_MESSAGES_WITHOUT_LOADING: {
      return {
        ...state,
        response: payload,
      };
    }
    case GET_MESSAGES_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    default: {
      return state;
    }
  }
};

export default chatMessageReducer;
