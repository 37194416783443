import React, {memo, useEffect, useState} from "react";
import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import PostTradeService from "../../services/postTradeService";
import {getTotalCurrencyData} from "../../store/actions/postTradeAction";
import {COUNTRYDATA, SKELETON_LOADER} from "../../store/types";
import {CountryIcons} from "../Common/CountryIcons";
import SearchableDropDown from "../Common/SearchableDropDown/SearchableDropDown";

const SelectCountryDetails = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const countryDataState = useSelector((state) => state.postTradeReducer.countryData);
  const buyData = useSelector((state) => state.postTradeReducer.buyData);
  const coinData = useSelector((state) => state.postTradeReducer.coinData);
  const margin = useSelector((state) => state.postTradeReducer.tokenMarginData);
  const userData = useSelector((state) => state.generalReducer.userData);

  const [countryStateValue, setCountryStateValue] = useState({});

  useEffect(() => {
    setCountryStateValue({...countryDataState});
  }, [countryDataState]);

  const HandleClickSearch = (item) => {
    if (item.countryName === "Worldwide") {
      const details = userData?.data?.userData?.defaultCurrency;

      details["currencyCode"] = details?.currency;
      details["currencyName"] = details?.country;

      setCountryStateValue({
        ...details,
        name: details.country,
        nameAcr: details.currency,
      });
      dispatch({type: COUNTRYDATA, payload: {...details, name: details.country, nameAcr: details.currency}});
      history.push(`/app/post-trade/${buyData.checker === true ? "buy" : "sell"}/${coinData}/${details.country}`);
      getLocalCurrency(details);

      return;
    }

    setCountryStateValue({
      ...item,
      name: item.countryName,
      nameAcr: item.alpha3,
    });
    dispatch({type: COUNTRYDATA, payload: {...item, name: item.countryName, nameAcr: item.alpha3}});
    history.push(`/app/post-trade/${buyData.checker === true ? "buy" : "sell"}/${coinData}/${item.countryName}`);
    getLocalCurrency(item);
  };

  const getLocalCurrency = useCallback(
    (countryItem) => {
      dispatch({type: SKELETON_LOADER, payload: true});
      if (
        coinData !== "" &&
        countryItem?.currencyCode !== "" &&
        countryItem?.currencyCode !== undefined &&
        typeof margin === "number" &&
        !isNaN(margin)
      ) {
        PostTradeService.TokenLocalCurrency({
          tokenSymbol: coinData,
          currency: countryItem?.currencyCode,
          margin: margin,
        }).then((data) => dispatch(getTotalCurrencyData(data)));
      }
    },
    [coinData, dispatch, margin]
  );

  return (
    <div style={{width: "100%"}}>
      <SearchableDropDown
        title={`For online trade, you need to specify the country`}
        iconsLead={true}
        name={"countryName"}
        nameAcr={"alpha3"}
        DetailsIcons={CountryIcons}
        search={countryStateValue && countryStateValue}
        setSearch={setCountryStateValue}
        placeholder={"Please Search for a country"}
        HandleSelect={HandleClickSearch}
        apiList={countryData?.data?.countriesData}
      />
    </div>
  );
};

export default memo(SelectCountryDetails);
