import React, {memo} from "react";
import "../../styles/walletStyles/walletTable_Header.scss";

const WalletTable_Header = () => {
  return (
    <div className="table_Wrapper_Main">
      <div className="table_Wrapper Coin">
        <p>Coin</p>
      </div>
      <div className="table_Wrapper Similar Price">
        <p>Price</p>
      </div>
      <div className="table_Wrapper Similar Available">
        <p>Available</p>
      </div>
      <div className="table_Wrapper Fiat">
        <p>BTC / Fiat Value</p>
      </div>
      <div className="table_Wrapper Action">
        <p>Action</p>
      </div>
    </div>
  );
};

export default memo(WalletTable_Header);
