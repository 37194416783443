import ReviewRow from "./Comment";
import EmptyStateProfile from "./emptyStateProfile";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import "../../styles/button.scss";

const PositiveComments = () => {
  const history = useHistory();

  const {profileHeaderOtherDataReducer, saveOtherUsernameState} = useSelector((state) => state.profileReducer);
  const listOfReviewsState = useSelector((state) => state.profileReducer.listOfReviewsState);

  if (listOfReviewsState?.length === 0) {
    return <EmptyStateProfile />;
  }

  const filteredPositive = listOfReviewsState.filter(
    (positive) => positive.isTrust === true && positive.reviewerUsername !== profileHeaderOtherDataReducer?.username
  );

  const handleButton = () => {
    history.push(`/app/all-reviews/${saveOtherUsernameState.username}`);
  };

  if (listOfReviewsState?.length > 0) {
    return (
      <div className="otherProfileReviews">
        {filteredPositive.slice(0, 3).map((item) => (
          <ReviewRow
            key={Math.random()}
            avatar={"avatar"}
            username={item.reviewerUsername}
            userFeeback={item.text}
            isVerified={item.isTrust}
            transactionId={item.date_added}
          />
        ))}
        <div className="buttonContainer">
          <button className=" baseButton primaryButton buttonStyle" onClick={handleButton}>
            Show more
          </button>
        </div>
      </div>
    );
  }
};

export default PositiveComments;
