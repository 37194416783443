import "../../styles/profile.scss";

const FormInput = ({title, verifyStatus, verifyStyle, trailingIcon}) => {
  return (
    <section className="list-item-container">
      <p className="titleStyle">{title}</p>
      <div className="leading-icon-trailing-container">
        <p className="leading-icon-trailing-title" style={verifyStyle}>
          {verifyStatus}
        </p>
        <span className="trailing-icon" style={{marginTop: "5px", marginLeft: "10px", fontSize: "1.3rem"}}>
          {trailingIcon}
        </span>
      </div>
    </section>
  );
};

export default FormInput;
