import React from "react";
import {Link} from "react-router-dom";
import {playCircle, blog, faqs, helpSupport} from "../../../utils/imagePath";
import "./learn-drop-down.scss";

const LearnDropDownMenu = React.forwardRef(({setShowLearn}, ref) => {
  const data = [
    {
      icon: <img src={playCircle} alt="video tutorials" width={23} />,
      title: "Video tutorials",
      tag: "Get up and running on new features and techniques.",
      path: "/videos",
    },
    {
      icon: <img src={faqs} alt="faqs" width={23} height="auto" />,
      title: "Frequently asked questions",
      tag: "All the boring stuff that you (hopefully won’t) need.",
      path: "/faq",
    },
    {
      icon: <img src={blog} alt="blog" width={23} height="auto" />,
      title: "Blog",
      tag: "Get up and running on new features and techniques.",
      path: "https://blog.oyola.io",
    },
    {
      icon: <img src={helpSupport} alt="help and support" width={23} height="auto" />,
      title: "Help and support",
      tag: "Learn, fix a problem, and get answers to your questions.",
      path: "/support",
    },
  ];

  return (
    <div
      className="navDropContainer"
      style={{width: "18%", left: "65%", padding: 0, height: "auto"}}
      ref={ref}
      onClick={() => setShowLearn(false)}
    >
      {data.map(({path, icon, title, tag}, index) =>
        path === "https://blog.oyola.io" ? (
          <a target="_blank" key={index} href={path} className="listMenuIcon" rel="noopener noreferrer">
            <div style={{display: "flex", gap: "1rem", alignItems: "center"}}>
              {icon}
              {title}
            </div>
            <p className="tag">{tag}</p>
          </a>
        ) : (
          <Link key={index} to={path} className="listMenuIcon" without={0} rel="noreferrer">
            <div style={{display: "flex", gap: "1rem", alignItems: "center"}} onClick={() => setShowLearn(false)}>
              {icon}
              {title}
            </div>
            <p className="tag">{tag}</p>
          </Link>
        )
      )}
    </div>
  );
});

export default LearnDropDownMenu;
