import React, {memo, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPhoneSearchableDetails} from "../../store/actions/settingAction";
import {CountryIcons} from "../Common/CountryIcons";
import SearchablePhoneDropDown from "../Common/SearchablePhoneDropdown/SearchablePhoneDropDown";

const PhoneSearchable = ({DropdownWidth, decoratorWidthWrapper}) => {
  const dispatch = useDispatch();
  const countryData = useSelector((state) => state.generalReducer.countryData);
  const phoneSearchableDetails = useSelector((state) => state.settingsReducer.phoneSearchableDetails);
  const [countryStateValue, setCountryStateValue] = useState({...phoneSearchableDetails});

  const HandleClickSearch = (item) => {
    setCountryStateValue({
      ...item,
    });

    dispatch(getPhoneSearchableDetails(item));
  };

  return (
    <div style={{width: "100%"}}>
      <SearchablePhoneDropDown
        iconsLead={true}
        name={"countryName"}
        nameAcr={"alpha3"}
        code={"areaCode"}
        DetailsIcons={CountryIcons}
        search={countryStateValue && countryStateValue}
        setSearch={setCountryStateValue}
        placeholder={"Please Search for a country"}
        HandleSelect={HandleClickSearch}
        apiList={countryData?.data?.countriesData}
        DropdownWidth={DropdownWidth}
        decoratorWidthWrapper={decoratorWidthWrapper}
      />
    </div>
  );
};

export default memo(PhoneSearchable);
