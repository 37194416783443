import {useRef, useState} from "react";
// import TradeDropdownMenu from "../trade-drop-down/trade-drop-down.component";
import LinkButton from "../linkButton";
// import LearnDropDownMenu from "../learn-drop-down/learn-drop-down.component";
import MobileSideMenu from "../mobile-side-menu/mobile-side-menu.component";

import useOnClickOutside from "../../../hooks/useOnClickOutside";

import {HiMenuAlt1} from "react-icons/hi";
import mobileLogo from "../../../assets/images/content.png";
import desktopLogo from "../../../assets/images/newlogo.png";

import styles from "./navbar.module.scss";

function Navbar() {
  const ref = useRef();

  useOnClickOutside(ref, () => setShowTrade(false));
  useOnClickOutside(ref, () => setShowLearn(false));
  useOnClickOutside(ref, () => setShowMobileSideMenu(false));

  const [showTrade, setShowTrade] = useState(false);
  const [showLearn, setShowLearn] = useState(false);
  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);

  return (
    <nav className={styles.navContainer}>
      <main className={styles.innerContainer}>
        <ul className={styles.innerContainerLeft}>
          <a href="/" className={styles.logoLink}>
            <img src={desktopLogo} alt="desktop logo" width={100} />
          </a>
          <a href="/" className={styles.mobileNav}>
            <img src={mobileLogo} alt="mobile logo" width={35} />
          </a>

          <button className={styles.mobileMenu} onClick={() => setShowMobileSideMenu(!showMobileSideMenu)}>
            <HiMenuAlt1 className="handburger" />
          </button>
          {showMobileSideMenu && <MobileSideMenu setHide={setShowMobileSideMenu} ref={ref} />}
        </ul>

        <ul className={styles.innerContainerRight}>
          {/* <div className={styles.navListMenuRight}>
            <a href="/features" className={styles.link}>
              Features
            </a>

            <button className={styles.linkIcon} onClick={() => setShowTrade(!showTrade)}>
              <p>Trade</p>
              <MdKeyboardArrowDown />
            </button>

            <button className={styles.linkIcon} onClick={() => setShowLearn(!showLearn)}>
              <p>Learn</p>
              <MdKeyboardArrowDown />
            </button>

            {showTrade && <TradeDropdownMenu ref={ref} />}
            {showLearn && <LearnDropDownMenu ref={ref} />}

            <a href="/about-us" className={styles.link}>
              About
            </a>
          </div> */}

          <div className={styles.navListLinkRight}>
            <LinkButton
              title="Sign In"
              link="/login"
              style={{
                width: "124px",
                height: "auto",
                padding: "8px 0px 10px 50px",
                background: "transparent",
                color: "#1E2329",
                fontSize: "1.4rem",
              }}
            />
            <LinkButton
              title="Download App"
              link="/download"
              style={{width: "144px", height: "auto", padding: "8px 20px", fontSize: "1.4rem"}}
            />
            {/* <LinkButton
              title="Sign up"
              link="/signup"
              style={{width: "124px", height: "auto", padding: "8px 20px", fontSize: "1.4rem"}}
            /> */}
          </div>
        </ul>
      </main>
    </nav>
  );
}

export {Navbar};
