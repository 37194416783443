import React from "react";
import {memo} from "react";
import {Loader} from "../Common/Loader";
import WalletOtp from "./WalletOtp";
import ConfirmationModal from "./confirmationModal";
import VerificationPage from "./notVerifiedPage";
import {useSelector} from "react-redux";
import SectionModalCentered from "../Common/sectionModalCentered";
import CrossCheckPage from "./crossCheckPage";

const OpeningModalSection = () => {
  const modalChecker = useSelector((state) => state.walletReducer.modalChecker);

  switch (modalChecker.num) {
    case 0:
      return (
        <SectionModalCentered open={modalChecker.num === 0 ? true : false}>
          <Loader />
        </SectionModalCentered>
      );
    case 1:
      return (
        <SectionModalCentered open={modalChecker.num === 1 ? true : false}>
          <VerificationPage />
        </SectionModalCentered>
      );
    case 2:
      return (
        <SectionModalCentered open={modalChecker.num === 2 ? true : false}>
          <WalletOtp />
        </SectionModalCentered>
      );
    case 3:
      return (
        <SectionModalCentered open={modalChecker.num === 3 ? true : false}>
          <ConfirmationModal />
        </SectionModalCentered>
      );

    case 5:
      return (
        <SectionModalCentered open={modalChecker.num === 5 ? true : false}>
          <CrossCheckPage />
        </SectionModalCentered>
      );

    default:
      return "";
  }
};

export default memo(OpeningModalSection);
