import React, {memo} from "react";
import {useDispatch} from "react-redux";
import Success from "../../assets/images/success-oyola.gif";
import GeneralService from "../../services/general";
import {getUser} from "../../store/actions/general";
import {getGoogleAuthProcessModals, getUseGoogleAuth, getUsePhoneNumber} from "../../store/actions/settingAction";
import "../../styles/settings/googleVerification.scss";
import "../../styles/settings/googleConfirmationModal.scss";

const GoogleConfirmationModal = () => {
  const dispatch = useDispatch();

  const ContinueGoogleOtpProcess = () => {
    dispatch(getGoogleAuthProcessModals({num: 0}));

    GeneralService.getUserData().then((data) => {
      dispatch(getUser(data ?? {}));

      dispatch(
        getUsePhoneNumber(
          data?.data?.userData?.authPreference === "sms" ? {name: "sms", checker: true} : {name: "sms", checker: false}
        )
      );

      dispatch(
        getUseGoogleAuth(
          data?.data?.userData?.authPreference === "google auth"
            ? {name: "google auth", checker: true}
            : {name: "google auth", checker: false}
        )
      );
    });

    dispatch(getGoogleAuthProcessModals({}));
  };

  return (
    <div className="googleComfirmationWrapper">
      <div className="googleComfirmationWrapper__ImageSection">
        <img src={Success} alt="confirmation" />
      </div>
      <div className="googleComfirmationWrapper__Details">
        <p>Authentication Completed</p>
      </div>
      <div className="googleComfirmationWrapper__Action">
        <button onClick={ContinueGoogleOtpProcess}>Ok</button>
      </div>
    </div>
  );
};

export default memo(GoogleConfirmationModal);
