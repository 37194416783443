import {Modal} from "@mui/material";
import {forwardRef, useRef} from "react";
import ReactDom from "react-dom";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useWindowDimensions from "../../hooks/useWindowDimension";
import "../../styles/modal.scss";
import {CURRENT_TOTAL_VALUE} from "../../store/types";
import {useDispatch} from "react-redux";

const OurModal = ({open, setOpen, children}) => {
  const {width} = useWindowDimensions();
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "max-content",
    backgroundColor: "white",
    borderRadius: width <= 730 ? "12px" : "5px",
    // overflowY: "scroll",
  };
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{overflowY: "scroll", height: "auto"}}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div style={{...style}}>{children}</div>
    </Modal>
  );
};

export default OurModal;
export const OurModal2 = ({children, open, setOpen, style}) => {
  const dispatch = useDispatch();
  const ref = useRef();
  useOnClickOutside(ref, () => {
    setOpen(false);
    dispatch({type: CURRENT_TOTAL_VALUE, payload: null});
  });
  const {width} = useWindowDimensions();
  return (
    open &&
    ReactDom.createPortal(
      <div className={`__ourmodal2Wraper ${width >= 700 && "__center"} `} style={style}>
        <div className="__innnermodal" ref={ref} style={{}}>
          {children}
        </div>
      </div>,
      document.getElementById("portal")
    )
  );
};
