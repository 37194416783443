import moment from "moment";
import {useEffect} from "react";
import ScrollToBottom from "react-scroll-to-bottom";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {useGetUserType} from "../../../hooks/useGetUserType";
import {IMAGE_BASE_URL} from "../../../services/api";
import {getChatMessagesNoLoaderAction} from "../../../store/actions/chatMessage";
import {SmallLoader} from "../../Common/Loader";
import ModalImage from "react-modal-image";

const MessagesArea = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  const {response, loading} = useSelector((state) => state.chatMessageReducer);
  const {response: tradeRes} = useSelector((state) => state.getTradeByIdReducer);
  const userType = useGetUserType(tradeRes ?? {});

  // useEffect(() => {
  //   chatRef.current?.scrollIntoView({behavior: "smooth"});
  // }, [response]);

  useEffect(() => {
    const interId = setInterval(() => {
      if (!loading) {
        dispatch(getChatMessagesNoLoaderAction(id));
      }
    }, 5000);

    return () => {
      clearInterval(interId);
    };
  }, []);

  return (
    <ScrollToBottom className="__messageChat__wrapper" scrollViewClassName="__scrollView">
      {loading ? (
        <div style={{width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
          <SmallLoader />
        </div>
      ) : (
        <>
          {response &&
            response[0]?.messages.map((item, i) => {
              if (item?.sentBy === "admin" && item?.senderID === "new trade") {
                return (
                  <div className="__dispute__message" key={item?._id}>
                    <div className="__dispute__wrapper">
                      <div className="__dispute__top">
                        <span className="__dispute__name">{`${tradeRes?.profileData?.traderData?.username}`}</span>
                        <span className="__dispute__time">{`${moment(item?.timeStamp).format("dddd")}, ${moment(
                          item?.timeStamp
                        ).format("LT")}`}</span>
                      </div>
                      <div
                        className={`__dispute__bottom ${
                          item.senderID === "released"
                            ? "__release"
                            : item.senderID === "cancelled"
                            ? "__cancelled"
                            : item.senderID === "paid" && "__paid"
                        }`}
                      >
                        <p
                          className={`__dispute_message`}
                          // style={{fontSize: `${item.senderID === "cancelled" && "13px"}`}}
                        >
                          {item?.text}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              } else if (item?.sentBy === "admin") {
                return (
                  <div className="__dispute__message" key={item?._id}>
                    <div className="__dispute__wrapper">
                      <div className="__dispute__top">
                        <span className="__dispute__name">{`${item?.username}`}</span>
                        <span className="__dispute__time">{`${moment(item?.timeStamp).format("dddd")}, ${moment(
                          item?.timeStamp
                        ).format("LT")}`}</span>
                      </div>
                      <div
                        className={`__dispute__bottom ${
                          item.senderID === "released"
                            ? "__release"
                            : item.senderID === "cancelled"
                            ? "__cancelled"
                            : item.senderID === "paid" && "__paid"
                        }`}
                      >
                        <p
                          className={`__dispute_message`}
                          // style={{fontSize: `${item.senderID === "cancelled" && "13px"}`}}
                        >
                          {item?.text}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div className={`__from__message ${userType === item?.sentBy && "__sender"}`} key={item?._id}>
                    <div
                      className="__from__message__detailWrapper"
                      style={{direction: userType === item?.sentBy ? "rtl" : "ltr"}}
                    >
                      <div className="flex __from__message__namedate">
                        <span className="__from__message__name">{`${item?.username}`}</span>
                        <span className="__from__message__date">{`${moment(item?.timeStamp).format("dddd")}, ${moment(
                          item?.timeStamp
                        ).format("LT")}`}</span>
                      </div>

                      {item?.isImage ? (
                        <div
                          className={`__theImageWrapper ${
                            userType !== item?.sentBy ? "__receiverImage" : "__senderImage"
                          }`}
                        >
                          <ModalImage
                            small={`${IMAGE_BASE_URL}${item?.imageUrl}`}
                            large={`${IMAGE_BASE_URL}${item?.imageUrl}`}
                            alt={item?.imageUrl}
                          />
                          {/* <img src={`${IMAGE_BASE_URL}${item?.imageUrl}`} className="__theImage" alt="the_image" /> */}
                        </div>
                      ) : (
                        <div
                          className={`__from__message__mes ${item.senderID === "moderator" && "__paid"}`}
                          style={{direction: userType === item?.sentBy && "ltr"}}
                        >
                          <span>{item.text}</span>
                        </div>
                      )}
                    </div>
                  </div>
                );
              }
            })}
        </>
      )}
    </ScrollToBottom>
  );
};

export default MessagesArea;
