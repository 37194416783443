import {useContext, useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {getAllTradeOrders2} from "../../store/actions/tradeOrderAction";
import "../../styles/tradeOrders/tradeOrderTop.scss";
import {TradeOrderContext} from "../Home/TradeOrders";

const TradeOrderTop = () => {
  const dispatch = useDispatch();
  const {push} = useHistory();
  const {setTradeOrder, tradeOrder} = useContext(TradeOrderContext);
  const locationDatas = useSelector((state) => state.generalReducer.locationData);

  // const getAllTradeOrders = (data) => {
  //   dispatch({type: IS_LOADING, payload: true});
  //   dispatch({type: FILTERED_TRADE_ORDERS, payload: []});
  //   dispatch({type: PAGINATION_INDEX, payload: 0});

  //   TradeOrderService.GetAllTradeOrder(data).then((data) => {
  //     const parentArray = [];
  //     const total_arrays_value = data.length / 10;

  //     for (let i = 0; i < Math.ceil(total_arrays_value); i++) {
  //       parentArray.push(data.splice(0, 10));
  //     }

  //     dispatch({type: PAGINATION_TOTAL, payload: Math.ceil(total_arrays_value)});
  //     dispatch({type: ALL_TRADE_ORDERS, payload: parentArray});
  //     dispatch({type: IS_LOADING, payload: false});
  //   });
  // };

  useEffect(() => {
    dispatch(getAllTradeOrders2(tradeOrder));
  }, [tradeOrder]);

  return (
    <div className="__trade__order__topWrapper">
      <div className="tradeorder__titleWrapper">
        <div className="tradeorder__titleTitle">
          <h3 className="tradeorder__title">My Orders</h3>
          <p className="tradeorder__titleDesc">View all Orders</p>
        </div>

        <div>
          <button
            onClick={() => push(`/app/post-trade/buy/btc/${locationDatas?.data?.geoData?.countryName}`)}
            className="__postTrade__button"
          >
            Post a Trade
          </button>
        </div>
      </div>

      <div className="__tradeorder__button__wrapper">
        <div
          className={`__tradeorder__button __ls ${tradeOrder === "active" && "__active"}`}
          onClick={() => setTradeOrder("active")}
        >
          Active
          {/* <span className="__tradeorder__count">{response && response.length}</span> */}
        </div>
        <div
          className={`__tradeorder__button __md ${tradeOrder === "inactive" && "__active"}`}
          onClick={() => setTradeOrder("inactive")}
        >
          Inactive
        </div>
        <div
          className={`__tradeorder__button __rs ${tradeOrder === "all" && "__active"}`}
          onClick={() => setTradeOrder("all")}
        >
          All Orders
        </div>
      </div>

      {/* <div className="__tradeorder__dropdown">
        <TransactionStaticDropDown
          dropdownOnChangeFunction={(val) => {
            getAllTradeOrders(val.type.toLowerCase());
          }}
          options={[
            {
              attr: "type",
              value: "all",
              label: "All",
            },
            {
              attr: "type",
              value: "active",
              label: "Active",
            },
            {
              attr: "type",
              value: "inactive",
              label: "InActive",
            },
          ]}
          initialValue={{attr: "type", value: "all", label: "All"}}
          useLabel={true}
          style={{borderRadius: "12px"}}
        />
      </div> */}
    </div>
  );
};

export default TradeOrderTop;
