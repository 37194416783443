import React, {memo} from "react";
import CurrencyFormat from "react-currency-format";
import "../../styles/walletStyles/walletTopSection.scss";
import {useSelector} from "react-redux";

const WalletTopSections = () => {
  const userWalletBalance = useSelector((state) => state.walletReducer.userWalletBalance);

  return (
    <div className="wallet__firstLayoutWrapper">
      <div className="wallet__firstLayout">
        <div className="wallet__firstLayoutHeader">
          <p className="wallet__firstLayoutHeaderParagraph">Estimated wallet balance</p>
          <div className="wallet__firstLayoutHeaderDetails">
            <p className="wallet__firstLayoutHeaderDetailsTwo">
              <CurrencyFormat
                value={`${userWalletBalance ? userWalletBalance?.userValueInBTC : 0}`}
                displayType={"text"}
                decimalScale={6}
                fixedDecimalScale
                // prefix={"$"}
                suffix={` BTC`}
              />
            </p>

            <p className="wallet__firstLayoutHeaderDetailsOne">
              <CurrencyFormat
                value={`${userWalletBalance ? userWalletBalance?.totalValue : 0}`}
                displayType={"text"}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale
                // prefix={"$"}
                suffix={` ${userWalletBalance ? userWalletBalance?.currency : "no currency"}`}
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(WalletTopSections);
