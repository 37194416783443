import {TRADE_REVIEW, TRADE_REVIEW_DEFAULT, TRADE_REVIEW_ERROR, TRADE_REVIEW_INIITIAL, REVIEW_RESPONSE} from "../types";

const initialState = {
  response: null,
  error: null,
  loading: false,
  reviewResponse: null,
};

const tradeReviewReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case TRADE_REVIEW: {
      return {
        ...state,
        response: payload,
      };
    }
    case TRADE_REVIEW_INIITIAL: {
      return {
        ...state,
        loading: true,
      };
    }
    case TRADE_REVIEW_ERROR: {
      return {
        ...state,
        error: payload,
      };
    }
    case TRADE_REVIEW_DEFAULT: {
      return {
        ...state,
        ...initialState,
      };
    }

    case REVIEW_RESPONSE: {
      return {
        ...state,
        reviewResponse: payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default tradeReviewReducer;
