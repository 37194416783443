import {
  USER_DATA,
  LOCATION_DATA,
  PAYMENT_METHOD,
  COUNTRY_DATA,
  CLEAR_USER,
  TOKEN_DETAILS,
  LOG_OUTSTATE,
  GET_USER_DATA_INITIAL,
  PENDING_AND_DISPUTED_TRADE,
} from "../types";

const initialState = {
  userData: {},
  locationData: {},
  paymentMethod: [],
  countryData: [],
  tokenDetails: [],
  loading: false,
  pendingAndDisputedTrade: {},
};

const generalReducer = (state = initialState, action) => {
  const {type, payload} = action;

  switch (type) {
    case USER_DATA: {
      return {
        ...state,
        userData: payload,
        loading: false,
      };
    }

    case PENDING_AND_DISPUTED_TRADE: {
      return {
        ...state,
        pendingAndDisputedTrade: payload,
      };
    }

    case GET_USER_DATA_INITIAL: {
      return {
        ...state,
        loading: true,
      };
    }

    case LOG_OUTSTATE: {
      return {
        ...state,
        ...initialState,
      };
    }

    case LOCATION_DATA: {
      return {
        ...state,
        locationData: payload,
      };
    }

    case PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: payload,
      };
    }

    case COUNTRY_DATA: {
      return {
        ...state,
        countryData: payload,
      };
    }

    case TOKEN_DETAILS: {
      return {
        ...state,
        tokenDetails: payload,
      };
    }

    case CLEAR_USER: {
      return {
        ...state,
        userData: {},
        locationData: {},
        paymentMethod: [],
        countryData: [],
        tokenDetails: [],
      };
    }

    default: {
      return state;
    }
  }
};

export default generalReducer;
