import {Avatar, Menu, MenuItem} from "@mui/material";
import React, {memo, useState} from "react";
import {useDispatch} from "react-redux";
import {useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {loader, logout} from "../../store/actions/auth";
import ProfileImage from "../Common/ProfileImage";

const ProfileAvatarMobile = () => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const {locationData, userData} = useSelector((state) => state.generalReducer);

  // Check userdata & locationData in redux store
  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const logOut = () => {
    history.push("/login");
    dispatch(logout());
    dispatch(loader(0));
  };

  const menuItemData = [
    {id: 0, page: "View Profile", path: `/app/profile/${username}`},
    {id: 1, page: "My Orders", path: "/app/trade-orders"},
    {id: 2, page: "Settings", path: "/app/settings"},
    {id: 3, page: "Wallet", path: "/app/wallet"},
    {id: 4, page: "Convert", path: "/app/convert"},
    {id: 5, page: "Post A Trade", path: `/app/post-trade/sell/btc/${locationData?.data?.geoData?.countryName}`},
    {id: 6, page: "Support", path: "/support"},
  ];

  const handleClose = (page, path) => {
    setOpen(false);
    setAnchorEl(null);
    switch (page) {
      case "View Profile": {
        history.push(path);
        break;
      }

      case "My Orders": {
        history.push(path);
        break;
      }

      case "Settings": {
        history.push(path);
        break;
      }

      case "Wallet": {
        history.push(path);
        break;
      }

      case "Convert": {
        history.push(path);
        break;
      }

      case "Post A Trade": {
        history.push(path);
        break;
      }

      case "Support": {
        history.push(path);
        break;
      }

      default: {
        break;
      }
    }
  };

  const openMenu = (e) => {
    setOpen(true);
    setAnchorEl(e.currentTarget);
  };

  return (
    <React.Fragment>
      <Menu
        id="basic-menu"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        style={profileContainerStyle}
        elevation={0.1}
      >
        {menuItemData.map((item) => {
          return (
            <MenuItem
              key={item.id}
              sx={menuStyle}
              onClick={() => {
                handleClose(item.page, item.path);
              }}
            >
              <p style={{fontSize: "14px"}}>
                {item.page} <br />
              </p>

              {item?.page === "Convert" && (
                // <div style={newConvertStyle}>
                <div style={newConvertStyle}>
                  <p style={textImage}>New</p>
                </div>
              )}
            </MenuItem>
          );
        })}
        <MenuItem
          sx={menuStyle}
          onClick={() => {
            handleClose("/");
          }}
        >
          <p style={{fontSize: "14px"}} onClick={() => logOut()}>
            Logout
          </p>
        </MenuItem>
      </Menu>
      <Avatar
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={openMenu}
        sx={{width: "30px", height: "30px"}}
      >
        <ProfileImage style={{width: 45, height: 45}} />
      </Avatar>
    </React.Fragment>
  );
};

const profileContainerStyle = {
  marginTop: "25px",
};
const menuStyle = {
  width: "200px",
  borderBottom: "1px solid #F2F4F7",
  marginTop: "10px",
};

const newConvertStyle = {
  marginLeft: "40%",
  width: "48px",
  height: "25.83px",
  color: "white",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRadius: "15.31px",
  fontSize: "16px",
  position: "relative",
  background: "#34D07F",
};

const textImage = {
  color: "white",
  fontSize: "12px",
  fontFamily: "Inter",
  fontStyle: "normal",
  fontWeight: "500",
  lineHeight: "20",
};

export default memo(ProfileAvatarMobile);
