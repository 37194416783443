import {Skeleton} from "@mui/material";
import React, {memo, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from "react-router-dom";
import {loader, logout} from "../../store/actions/auth";
import avatarProfile from "../../assets/icons/avatar-profile-photo.svg";
import useOnClickOutside from "../../hooks/useOnClickOutside";

const ProfileAvatar = () => {
  const [open, setOpen] = useState(false);
  const theref = useRef();
  const [showSelectCondition, setShowSelectCondition] = useState(false);

  useOnClickOutside(theref, () => setOpen(false));

  const handleClick = () => {
    if (showSelectCondition === false) {
      setOpen(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpen(false);
      setShowSelectCondition(false);
    }
  };

  const history = useHistory();
  const dispatch = useDispatch();
  const {locationData, userData} = useSelector((state) => state.generalReducer);
  const username = userData?.data?.userData?.hasOwnProperty("username") ? userData.data.userData.username : "";

  const menuItemData = [
    {id: 0, page: "View Profile", path: `/app/profile/${username}`},
    {id: 1, page: "Settings", path: "/app/settings"},
    {id: 2, page: "Wallet", path: "/app/wallet"},
    {id: 3, page: "Post A Trade", path: `/app/post-trade/sell/btc/${locationData?.data?.geoData?.countryName}`},
    {id: 4, page: "Support", path: ""},
  ];

  // Check userdata & locationData in redux store

  const profileImage =
    "data" in userData ?? ""
      ? userData?.data?.userData?.profileImageUrl
        ? `https://oyola.s3.amazonaws.com/${userData?.data?.userData?.profileImageUrl}`
        : avatarProfile
      : avatarProfile;

  const logOut = () => {
    history.push("/login");
    dispatch(logout());
    dispatch(loader(0));
  };

  return (
    <React.Fragment>
      <div className="__balance__crptoMennuWrapper" style={{padding: 0, background: "none"}}>
        {!("data" in userData) ? (
          <Skeleton
            animation="wave"
            variant="circular"
            className="__profile__image"
            width={40}
            height={40}
            style={{backgroundColor: "white"}}
          />
        ) : (
          <img
            className="__profile__image"
            src={profileImage}
            alt="the_image"
            onClick={() => handleClick()}
            style={{borderRadius: "50%", objectFit: "cover"}}
          />
        )}

        {open && (
          <div
            className="__balance__crypto__dropdown"
            ref={theref}
            data-aos="zoom-in"
            style={{width: "320%", left: "-200%"}}
            onClick={() => setOpen(false)}
          >
            {menuItemData.map((item, index) => {
              return (
                <div key={index}>
                  {item.page === "Support" ? (
                    <a
                      href="/support"
                      className="__the__buycrypto__link"
                      style={{fontSize: "14px", color: "inherit"}}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {item.page} <br />
                    </a>
                  ) : (
                    <p
                      className="__the__buycrypto__link"
                      style={{fontSize: "14px"}}
                      onClick={() => history.push(item.path)}
                      key={item.id}
                    >
                      {item.page} <br />
                    </p>
                  )}
                </div>
              );
            })}
            <p className="__the__buycrypto__link" style={{fontSize: "14px"}} onClick={() => logOut()}>
              Logout
            </p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(ProfileAvatar);
