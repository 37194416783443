import notFound from "../../assets/icons/notfound.svg";

const EmptyStateProfile = () => {
  return (
    <div style={{textAlign: "center", marginTop: "20px"}}>
      <img src={notFound} alt="not found" style={{width: "100px", height: "auto"}} />
      <p style={{fontSize: "12px"}}>No Reviews</p>
    </div>
  );
};

export default EmptyStateProfile;
