import React, {memo} from "react";
import {useSelector} from "react-redux";
import "../../styles/editTradeStyles/editTradeSecondSection.scss";
import {CountryIcons} from "../Common/CountryIcons";

const EditTradeSecondSection = () => {
  const singleUserOrder = useSelector((state) => state.editTradeReducer.userOrders);
  const countryDetails = useSelector((state) => state.editTradeReducer.checkCountryDetails);

  return (
    <div className="postTrade__secondLayout">
      <div className="postTrade__secondLayoutHeader">
        <h4>Location</h4>
      </div>
      <div className="postTrade__secondLayoutSearchCountry">
        <div className="postTrade__secondLayoutSearchCountryBody">
          <label>For online trade, you need to specify the country</label>
          <div className="postTrade__firstLayoutSelectCryptoBodyContentDresser">
            <div className="postTrade__firstLayoutSelectCryptoBodyContentDresserIconBody">
              <CountryIcons intialValue={singleUserOrder.country} />
            </div>
            <div className="postTrade__firstLayoutSelectCryptoBodyContentDresserContent">
              <p>{singleUserOrder.country}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="postTrade__secondLayoutTimer">
        {/* <div className="postTrade__secondLayoutTimerFree"></div> */}
        <div className="postTrade__secondLayoutTimerBodyMain">
          <div className="postTrade__secondLayoutTimerBody">
            <p>Time limit</p>
          </div>
          <div className="postTrade__secondLayoutTimerDropDown">
            <div className="postTrade__firstLayoutSelectCryptoBodyContentDresser">
              <div className="postTrade__firstLayoutSelectCryptoBodyContentDresserContent">
                <p>{`${singleUserOrder?.closingTime} Mins`}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(EditTradeSecondSection);
