import {
  // Menu, MenuItem,
  Skeleton,
} from "@mui/material";
import React, {memo, useCallback, useEffect, useRef, useState} from "react";
import {MdOutlineEmail} from "react-icons/md";
import notification from "../../assets/icons/notificationAppBar.svg";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import NotificationService from "../../services/notification";
import {getAllNotifications} from "../../store/actions/notification";
import moment from "moment";
import "../../styles/Notification/notificationIcon.scss";
import {ALL_NOTIFICATION_LIST, NOTIFICATIONNUMCHECKER} from "../../store/types";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import {BiChevronRight} from "react-icons/bi";
import {NOTFOUND} from "../../hooks/imagePath";
import {getDetailsOfWithdrawal, getModalChecker} from "../../store/actions/walletAction";
import {toast} from "react-toastify";
import sound from "../../assets/audio/cecellom.wav";

const NotificationIcon = () => {
  const [open, setOpen] = useState(false);
  const thref = useRef();
  const [showSelectCondition, setShowSelectCondition] = useState(false);
  useOnClickOutside(thref, () => setOpen(false));

  const handleClick = () => {
    if (showSelectCondition === false) {
      setOpen(true);
      setShowSelectCondition(true);
    } else if (showSelectCondition === true) {
      setOpen(false);
      setShowSelectCondition(false);
    }
  };

  const history = useHistory();

  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const playAudio = async (url) => {
    const audio = new Audio(url);
    audio.volume = 0.2;
    await audio.play();
  };

  const fetchNotifications = useCallback(async () => {
    setLoading(true);
    const notification_increase = localStorage.getItem("notification_increase");

    const constants = await Promise.all([
      NotificationService.AllNotifications(0, 5),
      NotificationService.UnReadNotification(),
    ]).then((data) => {
      return data;
    });

    if (notification_increase === null || notification_increase === undefined) {
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    } else if (constants[1]?.unReadCount === 0) {
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    } else if (constants[1]?.unReadCount > parseInt(notification_increase)) {
      playAudio(sound);
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    }

    dispatch({type: ALL_NOTIFICATION_LIST, payload: constants[0]?.data?.notifications});
    dispatch({type: NOTIFICATIONNUMCHECKER, payload: constants[1]});
    setLoading(false);

    setLoading(false);
  }, [dispatch]);

  const fetchNotificationsMain = useCallback(async () => {
    const notification_increase = localStorage.getItem("notification_increase");

    const constants = await Promise.all([
      NotificationService.AllNotifications(0, 5),
      NotificationService.UnReadNotification(),
    ]).then((data) => {
      return data;
    });

    console.log(constants, "resonseos now");

    if (notification_increase === null || notification_increase === undefined) {
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    } else if (constants[1]?.unReadCount === 0) {
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    } else if (constants[1]?.unReadCount > parseInt(notification_increase)) {
      playAudio(sound);
      localStorage.setItem("notification_increase", constants[1]?.unReadCount);
    }

    dispatch({type: ALL_NOTIFICATION_LIST, payload: constants[0]?.data?.notifications});
    dispatch({type: NOTIFICATIONNUMCHECKER, payload: constants[1]});
  }, [dispatch]);

  useEffect(() => {
    fetchNotifications();
  }, []);

  useEffect(() => {
    let interval = setInterval(() => {
      fetchNotificationsMain();
    }, 20000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const markAsAllRead = () => {
    setLoading(true);
    NotificationService.markAllAsReadNotification()
      .then((data) => {
        if (data?.data?.message === "success") {
          fetchNotificationsMain();

          // data?.data && dispatch(getAllNotifications(data?.data?.notifications));
        }
        return data?.data;
      })
      .then((res) => {
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        // toast("Something went wrong");
      });
  };

  const markAsRead = useCallback(
    (param) => {
      NotificationService.markAsRead(param)
        .then((data) => {
          if (data?.data?.message === "success") {
          }
        })
        .then(() => {
          fetchNotifications();
        })
        .catch((error) => {});
    },
    [fetchNotifications]
  );

  const notificationDataRedux = useSelector((state) => state.notificationReducer.allNotificationList);
  const notificationNumChecker = useSelector((state) => state.notificationReducer.notificationNumChecker);

  const notyData = notificationDataRedux ?? [];

  const ChangeToRead = (data) => {
    setOpen(false);

    markAsRead({_id: data._id});
    if (data?.type === "trade") {
      history.push(`/app/trade/${data?.notificationID}`);
      return;
    }

    if (data?.type === "withdraw") {
      dispatch(getDetailsOfWithdrawal(data));
      history.push("/app/transaction");
      dispatch(getModalChecker({num: 4}));
      return;
    }
  };

  return (
    <React.Fragment>
      <div
        className="__balance__crptoMennuWrapper notificationIconWrapper"
        style={{padding: "unset", background: "unset"}}
      >
        <div
          className="__balance__cryptoWrapper notificationIconWrapperIconBody"
          onClick={() => handleClick()}
          style={{marginRight: "unset"}}
        >
          <img src={notification} alt="icon" style={{width: "25px", objectFit: "cover"}} className="" />
          {notificationNumChecker?.latestNotifications?.length > 0 && (
            <div className="notificationIconWrapperIconBody__notifier">
              <p>{Object.keys(notificationNumChecker).length > 0 && notificationNumChecker?.unReadCount}</p>
            </div>
          )}
        </div>

        {open && (
          <div
            className="__balance__crypto__dropdown"
            style={{
              width: "630%",
              left: "-520%",
              padding: "0",
              borderRadius: "7px",
            }}
            ref={thref}
            data-aos="zoom-in"
          >
            <div className="__thenoty" style={{position: "relative", boxShadow: "0 0 7px rgba(#ccc, 0.3)"}}>
              <div className="top-bar-style">
                <div style={{fontSize: "12px", display: "flex", alignItems: "center"}}>
                  <p style={{fontSize: "18px", fontWeight: "500", marginRight: "10px"}}>
                    {notificationDataRedux?.unReadCount}
                  </p>
                  <p style={{fontSize: "12px", display: "flex", alignItems: "center"}}>
                    {Object.keys(notificationNumChecker)?.length > 0 && (
                      <span
                        style={{
                          marginRight: "5px",
                          padding: "2px",
                          backgroundColor: "#344054",
                          color: "#fff",
                          borderRadius: "50%",
                          fontSize: "10px",
                          fontWeight: "400",
                          width: "19px",
                          height: "19px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        {notificationNumChecker?.unReadCount}
                      </span>
                    )}
                    pending notifications
                  </p>
                </div>
                <p style={{fontSize: "12px", cursor: "pointer", color: "#53389E"}} onClick={markAsAllRead}>
                  Clear All
                </p>
                <div
                  className="__viewall"
                  onClick={() => {
                    history.push("/app/notification");
                    setOpen(false);
                  }}
                >
                  View All <BiChevronRight size={15} />
                </div>
              </div>
              <div className="__noty__holder">
                {loading ? (
                  Array.from(new Array(4))?.map((item) => (
                    <div style={{width: "100%"}}>
                      <Skeleton
                        animation="wave"
                        variant="rectangular"
                        width={"99%"}
                        height={"70px"}
                        style={{margin: "20px auto"}}
                      />
                    </div>
                  ))
                ) : notyData && notyData?.length > 0 ? (
                  notyData?.map((item) => {
                    return (
                      <div
                        className="__the__buycrypto__link__noty"
                        style={{padding: "10px"}}
                        onClick={() => ChangeToRead(item)}
                      >
                        <MdOutlineEmail
                          className="image-style"
                          style={{color: `${item?.unRead === true ? "#34d07f" : "#667085"}`}}
                        />
                        <div>
                          <p
                            style={{
                              fontSize: "12px",
                              fontWeight: `${item?.unRead === true ? "bold" : ""}`,
                              color: `${item?.unRead === true ? "#101828" : "#667085"}`,
                            }}
                          >
                            {item?.title} <br />
                          </p>
                          <p style={{fontSize: "11px", color: `${item?.unRead === true ? "#101828" : "#667085"}`}}>
                            {item?.text?.slice(0, 30)}..... <br />
                            {moment(item?.timeStamp).format("h:mm:ss a")}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="__no__notyWrapper">
                    <img src={NOTFOUND} className="__no_noty" alt="no_noty" />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default memo(NotificationIcon);
